import React, { Component, useState, useEffect } from "react";
import "../../../styles/_PropertyLeasingInfo.scss";
import apicall from "../../../utils/apicall";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import _ from "lodash";

export default function PropertyLeasingInfo(props) {
  const [leasingDetails, setleasingDetails] = useState({});
  const [leasing_info_applicable, setleasing_info_applicable] = useState(
    props.leasingInfo
  );

  useEffect(() => {
    getLeaseInfo();
  }, []);

  const getLeaseInfo = () => {
    apicall.getLeaseInfo(props.hoaId).then((res) => {
      if (!_.isEmpty(res.results)) setleasingDetails(res.results);
    });
  };

  const renderLeasingTable = (leasingDetails) => {
    if (!_.isEmpty(leasingDetails)) {
      let leasingInfo = "";
      if (leasing_info_applicable === true) leasingInfo = "Yes";
      else if (leasing_info_applicable === false) leasingInfo = "No";

      let at_lease_cap = "";
      if (leasingDetails.at_lease_cap === true) at_lease_cap = "Yes";
      else if (leasingDetails.at_lease_cap === false) at_lease_cap = "No";

      let owner_reside_before_lease = "";
      if (leasingDetails.owner_reside_before_lease === true)
        owner_reside_before_lease = "Yes";
      else if (leasingDetails.owner_reside_before_lease === false)
        owner_reside_before_lease = "No";

      let own_before_leasing_min = "";
      if (leasingDetails.own_before_leasing_min === true)
        own_before_leasing_min = "Yes";
      else if (leasingDetails.own_before_leasing_min === false)
        own_before_leasing_min = "No";

      let senior_living_comunity = "";
      if (leasingDetails.senior_living_comunity === true)
        senior_living_comunity = "Yes";
      else if (leasingDetails.senior_living_comunity === false)
        senior_living_comunity = "No";

      let lease_max_times_per_year = "";
      if (leasingDetails.lease_max_times_per_year === true)
        lease_max_times_per_year = "Yes";
      else if (leasingDetails.lease_max_times_per_year === false)
        lease_max_times_per_year = "No";

      let lease_max = "";
      if (leasingDetails.lease_max === true) lease_max = "Yes";
      else if (leasingDetails.lease_max === false) lease_max = "No";

      let lease_license_required = "";
      if (leasingDetails.lease_license_required === true)
        lease_license_required = "Yes";
      else if (leasingDetails.lease_license_required === false)
        lease_license_required = "No";

      let rental_fee_required = "";
      if (leasingDetails.rental_fee_required === true)
        rental_fee_required = "Yes";
      else if (leasingDetails.rental_fee_required === false)
        rental_fee_required = "No";

      let lease_approval_required = "";
      if (leasingDetails.lease_approval_required === true)
        lease_approval_required = "Yes";
      else if (leasingDetails.lease_approval_required === false)
        lease_approval_required = "No";

      let association_app_fee_required = "";
      if (leasingDetails.association_app_fee_required === true)
        association_app_fee_required = "Yes";
      else if (leasingDetails.association_app_fee_required === false)
        association_app_fee_required = "No";

      let copy_of_lease_required = "";
      if (leasingDetails.copy_of_lease_required === true)
        copy_of_lease_required = "Yes";
      else if (leasingDetails.copy_of_lease_required === false)
        copy_of_lease_required = "No";

      let tenant_approval_required = "";
      if (leasingDetails.tenant_approval_required === true)
        tenant_approval_required = "Yes";
      else if (leasingDetails.tenant_approval_required === false)
        tenant_approval_required = "No";

      let move_in_fee_required = "";
      if (leasingDetails.move_in_fee_required === true)
        move_in_fee_required = "Yes";
      else if (leasingDetails.move_in_fee_required === false)
        move_in_fee_required = "No";

      let pet_allowed = "";
      if (leasingDetails.pet_allowed === true) pet_allowed = "Yes";
      else if (leasingDetails.pet_allowed === false) pet_allowed = "No";

      let bg_check_required = "";
      if (leasingDetails.bg_check_required === true) bg_check_required = "Yes";
      else if (leasingDetails.bg_check_required === false)
        bg_check_required = "No";

      let tenant_application_required = "";
      if (leasingDetails.tenant_application_required === true)
        tenant_application_required = "Yes";
      else if (leasingDetails.tenant_application_required === false)
        tenant_application_required = "No";

      let association_move_in_checklist = "";
      if (leasingDetails.association_move_in_checklist === true)
        association_move_in_checklist = "Yes";
      else if (leasingDetails.association_move_in_checklist === false)
        association_move_in_checklist = "No";

      let own_before_leasing_min_months =
        leasingDetails.own_before_leasing_min_months;

      let lease_max_times_per_year_value =
        leasingDetails.lease_max_times_per_year_value;

      let lease_license_address = leasingDetails.lease_license_address;

      let rental_fee_amount = leasingDetails.rental_fee_amount;

      let association_app_fee = leasingDetails.association_app_fee;

      let lease_copy_submitted_at = leasingDetails.lease_copy_submitted_at;

      let max_time_for_tenant_approval =
        leasingDetails.max_time_for_tenant_approval;

      let move_in_fee_amount = leasingDetails.move_in_fee_amount;

      let pet_restrictions = leasingDetails.pet_restrictions;

      let bg_check_responsiblilty = leasingDetails.bg_check_responsiblilty;

      let thridsection = true;

      if (
        leasingDetails.at_lease_cap ||
        leasingDetails.owner_reside_before_lease ||
        leasingDetails.own_before_leasing_min ||
        leasingDetails.senior_living_comunity ||
        leasingDetails.lease_max_times_per_year ||
        leasingDetails.lease_max === true ||
        leasingDetails.at_lease_cap ||
        leasingDetails.owner_reside_before_lease ||
        leasingDetails.own_before_leasing_min ||
        leasingDetails.senior_living_comunity ||
        leasingDetails.lease_max_times_per_year ||
        leasingDetails.lease_max === null
      ) {
        thridsection = false;
      }

      return (
        <div>
          <div className="propertyDisplaycode">
            <div className="propertyinfo-leftSide">
              <div className="answerText">
                {"Is Property Leasing Permitted?"}
              </div>
              <div className="questionHeading">{leasingInfo}</div>
            </div>
          </div>
          {leasing_info_applicable && (
            <>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">{"Is there a Lease Cap?"}</div>
                  <div className="questionHeading">{at_lease_cap}</div>
                </div>
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Must the owner reside in the unit before leasing?"}
                  </div>
                  <div className="questionHeading">
                    {owner_reside_before_lease}
                  </div>
                </div>
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {
                      "Must the owner own the unit for a minimum number of months before leasing?"
                    }
                  </div>
                  <div className="questionHeading">
                    {own_before_leasing_min}
                  </div>
                </div>
                {own_before_leasing_min === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Number of months a unit must be owned before leasing"}
                    </div>
                    <div className="questionHeading">
                      {own_before_leasing_min_months + " months"}
                    </div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is this an adult living community?"}
                  </div>
                  <div className="questionHeading">
                    {senior_living_comunity}
                  </div>
                </div>
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {
                      "Is there a maximum number of times a unit can be leased in a 12-month period?"
                    }
                  </div>
                  <div className="questionHeading">
                    {lease_max_times_per_year}
                  </div>
                </div>
                {lease_max_times_per_year === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Number of times a unit may be leased in a year"}
                    </div>
                    <div className="questionHeading">
                      {lease_max_times_per_year_value}
                    </div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {
                      "Is there a maximum number of months the unit may be leased?"
                    }
                  </div>
                  <div className="questionHeading">{lease_max}</div>
                </div>
              </div>{" "}
            </>
          )}

          {leasing_info_applicable && thridsection && (
            <>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is a lease license required?"}
                  </div>
                  <div className="questionHeading">
                    {lease_license_required}
                  </div>
                </div>
                {lease_license_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Township,City or Municipality name?"}
                    </div>
                    <div className="questionHeading">
                      {lease_license_address}
                    </div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">{"Is there a rental fee?"}</div>
                  <div className="questionHeading">{rental_fee_required}</div>
                </div>
                {rental_fee_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"How much is the rental fee?"}
                    </div>
                    <div className="questionHeading">{rental_fee_amount}</div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is lease approval required by the association?"}
                  </div>
                  <div className="questionHeading">
                    {lease_approval_required}
                  </div>
                </div>
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is Association Application Fee Required?"}
                  </div>
                  <div className="questionHeading">
                    {association_app_fee_required}
                  </div>
                </div>
                {association_app_fee_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Association Application Fee"}
                    </div>
                    <div className="questionHeading">{association_app_fee}</div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {
                      "Does a copy of the lease need to be provided to the association?"
                    }
                  </div>
                  <div className="questionHeading">
                    {copy_of_lease_required}
                  </div>
                </div>
                {copy_of_lease_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Date a copy of the lease was provided"}
                    </div>
                    <div className="questionHeading">
                      {lease_copy_submitted_at}
                    </div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is a tenant approval required?"}
                  </div>
                  <div className="questionHeading">
                    {tenant_approval_required}
                  </div>
                </div>
                {tenant_approval_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Maximum length of time for board to approve a tenant?"}
                    </div>
                    <div className="questionHeading">
                      {max_time_for_tenant_approval}
                    </div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">{"Is there a move-in fee?"}</div>
                  <div className="questionHeading">{move_in_fee_required}</div>
                </div>
                {move_in_fee_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"How much is the move-in fee?"}
                    </div>
                    <div className="questionHeading">{move_in_fee_amount}</div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Are there any Pet Restrictions?"}
                  </div>
                  <div className="questionHeading">{pet_allowed}</div>
                </div>
                {pet_allowed === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">{"Pet Restrictions"}</div>
                    <div className="questionHeading">{pet_restrictions}</div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Background Check Required?"}
                  </div>
                  <div className="questionHeading">{bg_check_required}</div>
                </div>
                {bg_check_required === "Yes" && (
                  <div className="propertyinfo-rightSide">
                    <div className="answerText">
                      {"Background Check Responsibility"}
                    </div>
                    <div className="questionHeading">
                      {bg_check_responsiblilty}
                    </div>
                  </div>
                )}
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is a tenant application required?"}
                  </div>
                  <div className="questionHeading">
                    {tenant_application_required}
                  </div>
                </div>
              </div>
              <div className="propertyDisplaycode">
                <div className="propertyinfo-leftSide">
                  <div className="answerText">
                    {"Is there an association move-in checklist?"}
                  </div>
                  <div className="questionHeading">
                    {association_move_in_checklist}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    } else {
      return (
        <h6 className="noLeasingExist">
          {"Leasing Info Does Not Exist For This Property "}
        </h6>
      );
    }
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Association Details</span>
          </div>
        </ModalHeader>
        <ModalBody>{renderLeasingTable(leasingDetails)}</ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="association-modal"
        isOpen={props.modal}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
