import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import apicall from "../../utils/apicall";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import helpers from "../../utils/helpers";
import { toast } from "react-toastify";
import ReactPhoneInput from "react-phone-input-2";
import Select from "react-select";
import _ from "lodash";
import "../../styles/_userAnalystModal.scss";

class CreateAnalystModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createAnalystModal: false,
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role: "",
      mobile: "",
      id: "",
      status: "active",
      userRoles: {},
      entitySearch: {},
      entitySearchKeyword: "",
      entityName: "",
      zipcodes: "",
      entityOptions: [],
      vendorCodeOptions: [],
      munivendorCodeOptions: [],
      vendor_code: "",
      selectedDashboardScope: [],
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillMount() {
    this.fetchUserRoles();
    this.fetchUserEntity();
    this.fetchVendorCodes();
    this.fetchMunicipalVendorCodes();
  }

  toggleModalAnalyst = () => {
    this.state.toggle();
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });

      if (
        typeof newprops.analystArray !== "undefined" &&
        newprops.analystArray !== null &&
        typeof newprops.rowIndex !== "undefined" &&
        typeof newprops.analystArray[newprops.rowIndex] !== "undefined"
      ) {
        let arr = [];
        if (newprops.row.entity_names) {
          let x = newprops.row.entity_names.split(",");
          arr = x.map((o, i) => {
            return { label: o, value: o };
          });
        }
        let vendor_object = {};
        vendor_object["label"] = newprops.row.vendor_code;
        vendor_object["value"] = newprops.row.vendor_cod;

        let dashboard_scope_arr = [];
        if (newprops.row.dashboard_scope)
          dashboard_scope_arr = newprops.row.dashboard_scope.split(",");

        this.setState({
          // name: newprops.row.name,
          // email: newprops.row.email,
          // id: newprops.row.id,
          // mobile: newprops.row.mobile,
          // role: newprops.row.role,
          // status: newprops.row.status,
          // entity_names: arr,
          // zipcodes: newprops.row.zipcodes,
          name: newprops.row.name,
          email: newprops.row.email,
          id: newprops.row.id,
          mobile: newprops.row.mobile,
          role: newprops.row.role,
          status: newprops.row.status,
          entity_names: arr,
          zipcodes: newprops.row.zipcodes,
          vendor_code: vendor_object,
          selectedDashboardScope: dashboard_scope_arr,
        });
      }
    }
  }

  handlePhoneChange = (event) => {
    let targetName = event.target.name;
    let text = event.target.value;
    var cleaned = ("" + text).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      cleaned = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }
    // setrow(Object.assign({ ...row }, { [targetName]: cleaned }));
    this.setState({ mobile: cleaned });
    return;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  notify = (errorMsg) => toast(errorMsg);

  handleSubmit = (event) => {
    console.log("The zip code", this.state.zipcodes);
    let callUpdateAPI = typeof this.props.rowIndex !== "undefined";
    let entites;
    if (this.state.role === "guest") {
      entites = this.state.entity_names.map((x, i) => x.value);
      entites = entites.toString();
    } else {
      entites = "";
    }
    if (
      this.state.role !== "guest" &&
      this.state.role !== "guest_resicap" &&
      this.state.role !== "vendor_title" &&
      this.state.role !== "vendor_municipality" &&
      _.isEmpty(this.state.selectedDashboardScope)
    )
      this.notify("Please select the Dashboard Scope");
    else {
      if (!callUpdateAPI) {
        apicall
          .createAnalyst(
            this.state.name,
            this.state.email,
            this.state.password,
            this.state.password_confirmation,
            this.state.role,
            this.state.mobile,
            this.state.status,
            entites,
            this.state.zipcodes,
            this.state.selectedDashboardScope.toString(),
            this.state.vendor_code.valuedashboard_scope
          )
          .then((response) => {
            if (response !== null && response.hasOwnProperty("errors")) {
              let errorMsg = helpers.getErrorMsg(response.errors);
              if (errorMsg) this.notify(errorMsg);
            } else {
              this.notify("User Created Successfully");
              this.toggleModalAnalyst();
              this.state.getAnalysts();
            }
          });
      } else {
        var userData = {
          name: this.state.name,
          email: this.state.email,
          mobile: this.state.mobile,
          role: this.state.role,
          status: this.state.status,
          entity_names: entites,
          zipcodes: this.state.zipcodes,
          vendor_code: this.state.vendor_code.value,
          dashboard_scope: this.state.selectedDashboardScope.toString(),
        };

        if (this.state.password.length > 0) {
          userData["password"] = this.state.password;
          userData["password_confirmation"] = this.state.password_confirmation;
        }

        apicall.updateAnalyst(userData, this.state.id).then((response) => {
          if (response !== null && response.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(response.errors);
            if (errorMsg) this.notify(errorMsg);
          } else {
            this.notify("User Updated Successfully");
            this.toggleModalAnalyst();
            this.props.refreshUserList();
          }
        });
      }
    }
    if (this.props.updateData) {
      this.state.getAnalysts();
    }
    event.preventDefault();
  };

  handleRadioButton = (event) => {
    let value = event.target.value;
    this.setState({ status: value });
  };

  fetchUserRoles = () => {
    apicall.fetchUserRole().then((response) => {
      this.setState({ userRoles: response });
    });
  };

  fetchUserEntity = () => {
    apicall.fetchUserEntity().then((response) => {
      if (!_.isEmpty(response)) {
        let arr = response.map((x, i) => {
          return { label: x, value: x };
        });
        this.setState({ entityOptions: arr });
        // console.log(arr);
      }
    });
  };

  fetchVendorCodes = () => {
    apicall.fetchVendorCodes().then((response) => {
      if (!_.isEmpty(response)) {
        let arr = response.map((x, i) => {
          return { label: x, value: x };
        });
        this.setState({ vendorCodeOptions: arr });
      }
    });
  };

  fetchMunicipalVendorCodes = () => {
    apicall.fetchMunicipalVendorCodes().then((response) => {
      if (!_.isEmpty(response)) {
        let arr = response.map((x, i) => {
          return { label: x, value: x };
        });
        this.setState({ munivendorCodeOptions: arr });
      }
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let checkedValue = this.state.selectedDashboardScope;
    if (value === true) {
      checkedValue.push(name);
    } else {
      checkedValue = this.state.selectedDashboardScope.filter(
        (x) => x !== name
      );
    }

    this.setState({ selectedDashboardScope: checkedValue });
  };

  renderCheckBoxInput = (dataOne, dataTwo, dataThree) => {
    return (
      <div className="dashboard_scope_div">
        {!_.isEmpty(dataOne) && (
          <div className="dashboard_scope_checkbox_div">
            <input
              type="checkbox"
              name={dataOne}
              id={dataOne}
              onChange={(event) => {
                this.handleInputChange(event);
              }}
              checked={_.includes(this.state.selectedDashboardScope, dataOne)}
              className="checkbox_input"
            />
            <div className="checkbox_div_data"> {dataOne}</div>
          </div>
        )}
        {!_.isEmpty(dataTwo) && (
          <div className="dashboard_scope_checkbox_div">
            <input
              type="checkbox"
              name={dataTwo}
              id={dataTwo}
              onChange={(event) => {
                this.handleInputChange(event);
              }}
              checked={_.includes(this.state.selectedDashboardScope, dataTwo)}
              className="checkbox_input"
            />
            <div className="checkbox_div_data"> {dataTwo}</div>
          </div>
        )}
        {!_.isEmpty(dataThree) && (
          <div className="dashboard_scope_checkbox_div">
            <input
              type="checkbox"
              name={dataThree}
              id={dataThree}
              onChange={(event) => {
                this.handleInputChange(event);
              }}
              checked={_.includes(this.state.selectedDashboardScope, dataThree)}
              className="checkbox_input"
            />
            <div className="checkbox_div_data"> {dataThree}</div>
          </div>
        )}
      </div>
    );
  };

  renderModal = () => {
    var headerText = "";
    let callUpdateAPI = typeof this.props.rowIndex !== "undefined";
    if (!callUpdateAPI) {
      headerText = "Create User";
    } else {
      headerText = "Update User";
    }

    let typeActions = [];
    typeActions.push(<option value="">Select Type</option>);
    for (let role in this.state.userRoles) {
      typeActions.push(
        <option key={role} value={role}>
          {this.state.userRoles[role]}
        </option>
      );
    }
    let x = {
      label: this.state.entitySearch,
      value: this.state.entitySearch,
    };
    return (
      <div>
        <ModalHeader toggle={this.toggleModalAnalyst}>
          <div className="">
            <span className="heading">{headerText}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
            <FormGroup>
              <Label for="">Name *</Label>
              <TextValidator
                type="text"
                name="name"
                id="name"
                placeholder="Enter name"
                onChange={this.handleChange}
                validators={["required", "maxStringLength: 50"]}
                errorMessages={[
                  "Name is required",
                  "Should not be exceed 50 characters",
                ]}
                value={this.state.name}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Email *</Label>
              <TextValidator
                type="email"
                name="email"
                id="email"
                placeholder="Enter email"
                onChange={this.handleChange}
                validators={["required", "isEmail"]}
                errorMessages={["Email is required", "Email is not valid"]}
                value={this.state.email}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Mobile No</Label>
              {/* <ReactPhoneInput
                defaultCountry={"us"}
                value={this.state.mobile || ""}
                inputExtraProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: true,
                }}
                onChange={(value) => {
                  this.setState({
                    mobile: value,
                  });
                }}
              /> */}
              <TextValidator
                type="text"
                name="mobile"
                id="mobile"
                onChange={this.handlePhoneChange}
                value={this.state.mobile || ""}
                maxLength="14"
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Password</Label>
              <TextValidator
                type="password"
                name="password"
                id="password"
                placeholder="Enter password"
                onChange={this.handleChange}
                validators={[]}
                value={this.state.password}
                errorMessages={[]}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Confirm Password</Label>
              <TextValidator
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                placeholder="Confirm password"
                onChange={this.handleChange}
                validators={[]}
                value={this.state.password_confirmation}
                errorMessages={[]}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Role</Label>
              <Input
                type="select"
                name="role"
                id="role"
                onChange={this.handleChange}
                value={this.state.role}
                required
              >
                {typeActions}
              </Input>
              {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
            </FormGroup>
            {this.state.role !== "guest" &&
              this.state.role !== "guest_resicap" &&
              this.state.role !== "vendor_title" &&
              this.state.role !== "vendor_municipality" && (
                <>
                  {" "}
                  <FormGroup>
                    <Label>Dashboard Scope</Label>
                    {this.renderCheckBoxInput("Deed", "Title", "Municipal")}
                  </FormGroup>
                  <FormGroup>
                    {this.renderCheckBoxInput(
                      "Violation",
                      "Lien",
                      "Municipality"
                    )}
                  </FormGroup>
                  <FormGroup>
                    {this.renderCheckBoxInput("Association")}
                  </FormGroup>
                </>
              )}

            {this.state.role === "guest" && (
              <>
                {" "}
                <FormGroup>
                  <Label for="">Entity</Label>
                  <Select
                    isMulti
                    name="colors"
                    options={this.state.entityOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    id="entity_names"
                    value={this.state.entity_names}
                    onChange={(data) => {
                      console.log(data);
                      this.setState({ entity_names: data });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="">Zip Code</Label>

                  <Input
                    type="textarea"
                    name="zipCode"
                    id="zipcodes"
                    placeholder="Enter Zip"
                    onChange={this.handleChange}
                    value={this.state.zipcodes}
                  />
                </FormGroup>
              </>
            )}
            {this.state.role === "vendor_title" && (
              <FormGroup>
                <Label for="">Vendor Code</Label>

                <Select
                  name="vendor_code"
                  options={this.state.vendorCodeOptions}
                  id="vendor_code"
                  value={this.state.vendor_code}
                  onChange={(data) => {
                    this.setState({ vendor_code: data });
                  }}
                />
              </FormGroup>
            )}

            {this.state.role === "vendor_municipality" && (
              <FormGroup>
                <Label for="">Vendor Code</Label>
                <Select
                  name="vendor_code"
                  options={this.state.munivendorCodeOptions}
                  id="vendor_code"
                  value={this.state.vendor_code}
                  onChange={(data) => {
                    this.setState({ vendor_code: data });
                  }}
                />
              </FormGroup>
            )}

            <Label for="">User Status</Label>
            <FormGroup style={{ display: "flex" }}>
              <CustomInput
                id="active"
                type="radio"
                name="active"
                checked={this.state.status === "active" ? "checked" : ""}
                onClick={this.handleRadioButton}
                value="active"
              />
              <Label style={{ marginRight: "2%" }}>Active</Label>
              <CustomInput
                id="inactive"
                type="radio"
                name="inactive"
                checked={this.state.status === "inactive" ? "checked" : ""}
                onClick={this.handleRadioButton}
                value="inactive"
              />
              <Label style={{ marginRight: "2%" }}>Inactive</Label>
            </FormGroup>
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="createAnalystModal"
          isOpen={this.state.modal}
          toggle={this.toggleModalAnalyst}
        >
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}

export default CreateAnalystModal;
