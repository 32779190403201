import React, { Component } from "react";
import GeneralComponent from "../general_info/GeneralComponent";
import apicall from "../../utils/apicall";
import PropertyInfo from "../general_info/PropertyInfo";
import "../../styles/_generalInformation.scss";
import config from "../../utils/config.js";
export default class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyInfo: null,
      propertyid: 2,
      activeTab: 1,
      actionArray: [],
      userRole: "",
    };
  }

  componentWillMount() {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        this.setState({ userRole: role });
      })
      .catch((e) => console.log(e));
  }

  componentDidMount() {
    this.getPropertyInfo(this.state.propertyid);
    this.getActions(this.state.propertyid);
  }

  getPropertyInfo = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      this.setState({
        propertyInfo: response,
      });
    });
  };

  getActions = (propertyID) => {
    apicall.listActions(propertyID).then((response) => {
      this.setState({
        actionArray: response,
      });
    });
  };

  render() {
    return (
      <div className="email-access-general-info">
        <div className="page-header">Property General Information</div>
        <PropertyInfo
          propertyInfo={this.state.propertyInfo}
          actionArray={this.state.actionArray}
          className="property-data"
          hideLink={true}
        />
        <GeneralComponent
          propertyID={this.state.propertyid}
          className="general-info"
          actionArray={this.state.actionArray}
          userRole={this.state.userRole}
        />
      </div>
    );
  }
}
