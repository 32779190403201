import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import apicall from "../../utils/apicall";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import { toast } from "react-toastify";
import AsyncSelect from "../helper_components/HoaAsyncSelect";
class AreaAnalystModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      analysts: [],
      row: props.row,
      isLoading: false,
      test: {},
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  toggleModal = () => {
    this.state.toggle();
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      if (newprops.open && newprops.row.id) {
        var row = newprops.row;
        delete newprops.row["created_at"];
        delete newprops.row["updated_at"];
        for (let key in row) {
          if (key.includes("_id")) {
            let option = {};
            let emailkey = key.replace("_id", "_email");
            option["value"] = row[key];
            option["label"] = row[emailkey];
            if (option) newprops.row[key] = option;
          } else if (key.includes("_email")) {
            delete newprops.row[key];
          }
        }
      }
    }
    this.setState({
      ...this.state,
      ...newprops,
    });
  }

  handleAnalyst = (valuekey, valueObj) => {
    let emailkey = valuekey.replace("id", "email");
    this.setState({
      row: Object.assign(this.state.row, { [valuekey]: valueObj }),
      row: Object.assign(this.state.row, { [emailkey]: valueObj.label }),
    });
  };

  handleChange = (event) => {
    this.setState({
      row: Object.assign(this.state.row, {
        [event.target.id]: event.target.value,
      }),
    });
  };

  handleKeyDown = (event, value) => {
    var numberRegex = /^[0-9]+$/;
    if (numberRegex.test(event.key)) event.preventDefault();
  };

  notify = (errorMsg) => toast(errorMsg);

  getSelectionValue = (obj) => {
    if (obj === Object(obj)) return obj.value;
    else return obj;
  };

  handleSubmit = (event) => {
    var row = this.state.row;

    for (let analyst in row) {
      row[analyst] = this.getSelectionValue(row[analyst]);
    }

    this.setState({ isLoading: true });
    if (row.id) {
      apicall.updateAreaAnalyst(row).then((response) => {
        if (response) {
          this.notify("Updated successfully.");
          this.toggleModal();
          this.setState({ isLoading: false });
        }
      });
    } else {
      apicall.createAreaAnalyst(row).then((response) => {
        if (response) {
          this.notify("Added successfully.");
          this.toggleModal();
          this.setState({ isLoading: false });
        }
      });
    }
    event.preventDefault();
  };

  renderModal = () => {
    ValidatorForm.addValidationRule("isZipcodeMatch", (value) => {
      var zipCodePattern = /^\d{3}$/;
      return zipCodePattern.test(value);
    });
    return (
      <div>
        {/* {this.state.isLoading && <Loader />} */}
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">
              {this.state.row.id ? "Update area analyst" : "Add area analyst"}
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
            <FormGroup>
              <Label for="">State *</Label>
              <TextValidator
                type="text"
                name="state"
                id="state"
                placeholder="State"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                validators={["required", "isString", "maxStringLength: 50"]}
                errorMessages={[
                  "State is required",
                  "invalid",
                  "Should not exceed 50 characters",
                ]}
                value={this.state.row.state}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Zip *</Label>
              <TextValidator
                type="text"
                name="zip"
                id="zip"
                placeholder="XXX"
                onChange={this.handleChange}
                validators={["required", "isZipcodeMatch"]}
                errorMessages={["Zip is required", "Invalid Zip Code"]}
                value={this.state.row.zip}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Deed Coordinator</Label>
              <AsyncSelect
                id="deed_coordinator_1_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                // valueShown={this.state.row.deed_coordinator_1_id}
                valueShown={{
                  value: this.state.row.deed_coordinator_1_id,
                  label: this.state.row.deed_coordinator_1_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Lien Coordinator</Label>
              <AsyncSelect
                id="lien_coordinator_1_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                // valueShown={this.state.row.lien_coordinator_1_id}
                valueShown={{
                  value: this.state.row.lien_coordinator_1_id,
                  label: this.state.row.lien_coordinator_1_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Municipal Coordinator</Label>
              <AsyncSelect
                id="muni_coordinator_1_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                // valueShown={this.state.row.muni_coordinator_1_id}
                valueShown={{
                  value: this.state.row.muni_coordinator_1_id,
                  label: this.state.row.muni_coordinator_1_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Municipality Coordinator</Label>
              <AsyncSelect
                id="municipality_coordinator_1_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                // valueShown={this.state.row.municipality_coordinator_1_id}
                valueShown={{
                  value: this.state.row.municipality_coordinator_1_id,
                  label: this.state.row.municipality_coordinator_1_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Title Coordinator</Label>
              <AsyncSelect
                id="title_coordinator_1_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                // valueShown={this.state.row.title_coordinator_1_id}
                valueShown={{
                  value: this.state.row.title_coordinator_1_id,
                  label: this.state.row.title_coordinator_1_email,
                }}
                getEmail={true}
              />
            </FormGroup>

            <FormGroup>
              <Label for="">Violations Coordinator-1</Label>
              <AsyncSelect
                id="violation_coordinator_1_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                // valueShown={this.state.row.violation_coordinator_1_id}
                valueShown={{
                  value: this.state.row.violation_coordinator_1_id,
                  label: this.state.row.violation_coordinator_1_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Violations Coordinator-2</Label>
              <AsyncSelect
                id="violation_coordinator_2_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={{
                  value: this.state.row.violation_coordinator_2_id,
                  label: this.state.row.violation_coordinator_2_email,
                }}
                // valueShown={this.state.row.violation_coordinator_2_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Violations Coordinator-3</Label>
              <AsyncSelect
                id="violation_coordinator_3_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={{
                  value: this.state.row.violation_coordinator_3_id,
                  label: this.state.row.violation_coordinator_3_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Violations Coordinator-4</Label>
              <AsyncSelect
                id="violation_coordinator_4_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={{
                  value: this.state.row.violation_coordinator_4_id,
                  label: this.state.row.violation_coordinator_4_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Controller</Label>
              <AsyncSelect
                id="area_controller_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={{
                  value: this.state.row.area_controller_id,
                  label: this.state.row.area_controller_email,
                }}
                getEmail={true}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="">Senior Analyst</Label>
              <AsyncSelect
                id="area_senior_analyst_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_senior_analyst_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Association Analyst</Label>
              <AsyncSelect
                id="area_association_analyst_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_association_analyst_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Area Controller</Label>
              <AsyncSelect
                id="area_controller_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_controller_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Area Coordinator</Label>
              <AsyncSelect
                id="area_coordinator_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_coordinator_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Sales Analyst</Label>
              <AsyncSelect
                id="area_leasing_sales_analyst_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_leasing_sales_analyst_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">On-Boarding Analyst</Label>
              <AsyncSelect
                id="area_onboarding_analyst_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_onboarding_analyst_id}
                getEmail={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Payment Analyst</Label>
              <AsyncSelect
                id="area_payment_analyst_id"
                callapi={apicall.searchAnalysts}
                placeholder="Search Analyst..."
                handleOnChange={this.handleAnalyst}
                valueShown={this.state.row.area_payment_analyst_id}
                getEmail={true}
              />
            </FormGroup> */}

            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="area-analyst-form-modal"
          isOpen={this.state.open}
          toggle={this.toggleModal}
        >
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}

export default AreaAnalystModal;
