import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import Axios from "axios";

var municipalVerificationapi = {
  updateMunicipalVerificationAction: async (
    data,
    municipalVerificationId,
    propertyID
  ) => {
    let url = `${config.baseUrl}/api/v1/municipality_verifications/${municipalVerificationId}?property_id=${propertyID}`;
    let res = "";
    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if(key === "muni_verification_notes") {
          if(data[key] === "" || !data[key]) {
            data[key] = null
            continue
          }
        }
        if (data[key] === null) {
          data[key] = "";
        }
        form.append("municipality_verification[" + key + "]", data[key]);
      }
    }

    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while lien Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  readMunicipalVerificationAction: async function (
    municipalVerificationId,
    propertyID
  ) {
    let url = `${config.baseUrl}/api/v1/municipality_verifications/${municipalVerificationId}?property_id=${propertyID}`;

    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(municipalVerificationapi);
