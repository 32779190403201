import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import "../../../styles/propertyupload.css";
import "../../../styles/content.css";
import LoaderComponent from "../../helper_components/LoaderComponent";
import ImportPropertyModal from "../../helper_components/ImportModal";
import TitleOrderList from "./TitleOrderList";
import apicall from "../../../utils/apicall";
import "../../../styles/_titleorder.scss";

export default function TitleOrder() {
  const [pageNo, setpageNo] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [orderList, setorderList] = useState([]);
  const [importModal, setimportModal] = useState(false);
  const [total, settotal] = useState();
  const [vendors, setvendors] = useState([]);
  const [perPage, setperPage] = useState(10);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  const toggleImport = () => {
    setimportModal(!importModal);
  };

  useEffect(() => {
    getVendorOrderList();
  }, [form_inputs.sort]);

  const getVendorOrderList = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall.listVendorOrder(form_inputs, pageNo, perPage).then((response) => {
      setorderList(response.results);
      setisLoading(false);
      settotal(response.total);
      setpageNo(pageNo);
      setperPage(perPage);
    });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderComponent />}
      <section
        className="content title-order"
        style={{ marginTop: "0px", marginLeft: "0%" }}
      >
        <header
          className="content-title property-upload"
          style={{ justifyContent: "normal" }}
        >
          <Button
            onClick={toggleImport}
            className="createBtn"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            Create Title Order
          </Button>
        </header>

        <TitleOrderList
          orderList={orderList}
          getVendorOrderList={getVendorOrderList}
          pageNo={pageNo}
          perPage={perPage}
          total={total}
          toggle={toggleImport}
          vendors={vendors}
          sortColumns={sortColumns}
        />
      </section>

      {importModal && (
        <ImportPropertyModal
          uploadType="title-order"
          toggleParentModal={toggleImport}
          className=""
          title="Title Order"
          open={importModal}
          uploaded={false}
        />
      )}
    </React.Fragment>
  );
}
