import React from "react";
import FormComp from "../crud/FormComp";
import { FormGroup, Label, Col, Input } from "reactstrap";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent";
import { toast } from "react-toastify";
import helpers from "../../utils/helpers";

export default class DocumentsModal extends FormComp {
  constructor(props) {
    super(props);
    this.state = {
      row: {},
      isLoading: false,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  formElements = () => {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <FormGroup>
            <Label for="">Document File Upload</Label>
            <Input
              type="file"
              name="file"
              id={"file"}
              onChange={this.handleChange}
              accept=".xlsx, .xls,"
              required
            />
          </FormGroup>
        </div>
      );
    }
  };

  notify = (msg) => toast(msg);
  handleSubmit = (event) => {
    this.setState({ isLoading: true });

    console.log("The state is", this.state.row);
    apicall.paymentChecksentDocument(this.state.row).then((response) => {
      if (response !== null && response.hasOwnProperty("errors")) {
        this.notify(response.status);
        this.setState({
          isLoading: false,
        });
      } else {
        this.notify("Document Uploaded Successfully.");
        this.setState({
          row: response,
          isLoading: false,
        });
        this.props.toggle();
        //this.props.fetchDocuments();
      }
    });
  };
}
