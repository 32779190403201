import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import create from "../../images/create.png";
import filter from "../../images/filter.png";
import ActionModal from "../ActionModal";
import "../../styles/action.css";
import actionsapi from "../../utils/api/actionsapi";
import paymentsapi from "../../utils/api/paymentsapi";
import paginationFactory from "react-bootstrap-table2-paginator";
//import AskSearchQuestionsModal from "../properties/AskSearchQuestionsModal";
import LienModal from "../lien/LienModal";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import InspectionModal from "../inspection/InspectionModal";
import ViolationModal from "../violations/ViolationModal";
import PaymentModal from "../payments/paymentModal";
import Loader from "../helper_components/LoaderComponent";
import config from "../../utils/config";
import AssociationInformationModal from "../hoas/FormComponent";
import _ from "lodash";
import helpers from "../../utils/helpers";
import AssociationVerificationModal from "../AssociationVerification/AssociationVerificationModal";
import DeedVerification from "../deed_verification/NewDeedFile";
import MunicipalReviewAction from "../orders/municipality_order/MunicipalityReviewAction";
import TitleReviewAction from "../orders/title_order/TitleReviewAction";
import TitleClearanceAction from "../orders/title_order/TitleClearance";
import MunicipalRequirementAction from "../Municipal/MunicipalRequirementAction";
import MunicipalVerification from "../MunicipalVerification/MunicipalVerification";
import CodeViolationModal from "../CodeViolation/CodeViolationModal";
import LienActionModal from "../LienActions/LienActionModal";

export default class PropertyActionListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionModal: false,
      searchModal: false,
      updateModal: false,
      inspectionModal: false,
      violationModal: false,
      lienModal: false,
      paymentModal: false,
      rowIndex: 0,
      propertyID: 0,
      actionArray: [],
      titleActionArray: [],
      row: {},
      action: {},
      isLoading: true,
      associationinformationModal: false,
      associationVerficationModal: false,
      association_verification_info_id: null,
      openCodeViolationModal: false,
      openLienCreationModal: false,
      paymentHoasOffice: null,
      registrationCategory: null,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillMount() {
    this.getActions(this.state.propertyID);
    this.PaymentHoas(this.state.propertyID);
    setTimeout(() => {
      this.getTitleActions(this.state.propertyID);
    }, 3000);
  }

  toggleModal = () => {
    setTimeout(() => {
    this.getActions(this.state.propertyID);
    },1000);
    setTimeout(() => {
      this.getTitleActions(this.state.propertyID);
    }, 2000);
  };

  getActions = (propertyID) => {
    actionsapi
      .listActions(propertyID)
      .then((response) => {
        if (!_.isEmpty(response.results)) {
          let data = response.results.filter(
            (x) => x.actionable_type !== "Pir"
          );
          this.setState({
            actionArray: data,
            isLoading: false,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  getTitleActions = (propertyID) => {
    actionsapi
      .listTitleActions(propertyID)
      .then((response) => {
        this.setState({
          titleActionArray: response.results,
          isLoading: false,
        });
      })
      .catch((e) => console.log(e));
  };

  PaymentHoas = (propertyID) =>{ 
    paymentsapi
    .fetchPaymentHoas(propertyID)
    .then((response) => {
      this.setState({
        paymentHoasOffice: response.Office
      });
    })
  }
  getActionType = (cell) => {
    // if (config["actionTypes"].hasOwnProperty(cell)) {
    //   return config.actionTypes[cell];
    // } else {
    //   return cell;
    // }
    if (cell === "MunicipalityRegistration") return "Registration Action";
    else {
      let lowercase = cell.trim();
      if(lowercase && lowercase === "FY Assessment") return lowercase;
      let regEx = /\W+|(?=[A-Z])|_/g;
      let result = lowercase.split(regEx).join(" ");
      return result;
    }
  };

  descriptionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.actionable_type.trim().toLowerCase() === "titlereview") {
      return "";
    }else if (!_.isEmpty(row.title_documents)) {
      let category = !_.isEmpty(row.title_documents[0].category)
        ? row.title_documents[0].category
        : "";
      let type = !_.isEmpty(row.title_documents[0].doc_type)
        ? row.title_documents[0].doc_type
        : "";
      let case_number = !_.isEmpty(row.title_documents[0].case_number)
        ? row.title_documents[0].case_number
        : "";

      return `${category} - ${type} - ${case_number} `;
    } else return cell;
  };

  actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
      //&&
      //cell.trim().toLowerCase() !== "associationverification"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              if (cell === "MunicipalityRegistration"){
                 this.setState({
                  registrationCategory: row.description
                 })
              }
              if (cell.trim().toLowerCase() !== "associationinformation") {
                if(cell.trim() === "General Payment" || cell.trim() === "Account Review" || cell.trim() === "FY Assessment") {
                  this.setState({
                    ["paymentModal"]: !this.state["paymentModal"],
                    actionID: row.actionable_id,
                    action: row,
                    row: row,
                  });
                } else if(cell.trim() === "CodeViolation"){
                  this.setState({
                    openCodeViolationModal: !this.state.openCodeViolationModal,
                    actionID: row.actionable_id,
                    action: row,
                    row: row,
                  });
                }else if(cell.trim() === "Lien"){
                  this.setState({
                    openLienCreationModal: !this.state.openLienCreationModal,
                    actionID: row.actionable_id,
                    action: row,
                    row: row,
                  });
                }else {
                  this.setState({
                    [cell.trim().toLowerCase() + "Modal"]: !this.state[
                      cell.trim().toLowerCase() + "Modal"
                    ],
                    actionID: row.actionable_id,
                    action: row,
                    row: row,
                  });
                }
              }
            }}
          >
            {this.getActionType(cell)}
          </div>
        </React.Fragment>
      );
    }
    let cellName = cell.trim().toLowerCase();
    let tabNo = cellName === "documentinforeport" ? 5 : null;
    tabNo = cellName === "pir" ? 1 : tabNo;
    let hoaID = _.has(row, "hoa_id") ? row.hoa_id : 0;
    if (tabNo !== null) {
      return (
        <Link
          className="link"
          to={
            "/property-manage/" +
            tabNo +
            "/" +
            this.state.propertyID +
            "/" +
            hoaID
          }
        >
          {this.getActionType(cell)}
        </Link>
      );
    }
    return cell;
  };

  toggleActionAnalyst = () => {
    this.setState({
      actionModal: !this.state.actionModal,
    });
  };

  dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  amountPayableFormatter = (cell) => {
    return !_.isEmpty(cell) ? "$" + parseFloat(cell).toFixed(2) : "";
  };

  haoMunicipalNameFormatter = (cell, row) => {
    if (row.actionable_type === "CodeViolation"){
      if (!_.isEmpty(row.associable)) return row.associable.name;
    }
    if (row.actionable_type === "Lien") {
      if (_.isEmpty(row.office_id)) return row.office_name
    } 
    if(row.hoa){
      return row.hoa.name;
    } else if (this.state.paymentHoasOffice) {
      let displayName = this.state.paymentHoasOffice.map((data) => {
        if (data.id === row.office_id) {
          return data.name
        }
      })
      if (displayName[0] !== undefined) return displayName
      else return row.municipal_name;
    } else if (!this.state.paymentHoasOffic) {
      return row.municipal_name;
    } else return cell;
  };

  descFormatter = (cell) => (
    <div style={{ "white-space": "pre-wrap" }}>{cell}</div>
  );

  notesFormatter = (cell, row) => {
    let notes = row.notes;
    if (notes && notes.length > 0) {
      let note;
      if (notes.length == 1) {
        let displayNotes = notes[0];
        if (row.actionable_type === "General Payment" || row.actionable_type === "Account Review" || row.actionable_type === "FY Assessment") {
          note = displayNotes["comment"]
        } else {
          note = displayNotes["description"]
        }
        return (
          <>
           {this.descFormatter(note)}
          </>)
      } else {
        if (notes.length > 1) {
          let recentNotes = notes[0]
          for (let i = 1; i < notes.length; i++) {
              if(recentNotes.created_at < notes[i].created_at){
                 recentNotes = notes[i];
              }
          }
          if (row.actionable_type === "General Payment" || row.actionable_type === "Account Review" || row.actionable_type === "FY Assessment") {
            note = recentNotes["comment"]
          } else {
            note = recentNotes["description"]
          }
          return (
            <>
            {this.descFormatter(note)}
            </>)
        }
      }
    }
  };

  editFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.actionable_type !== "Account Review" && row.actionable_type !== "General Payment" && row.actionable_type !== "FY Assessment"  ) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <FaEdit
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => {
              this.setState({
                actionModal: !this.state.actionModal,
                updateModal: true,
                actionID: row.actionable_id,
                action: row,
              });
            }}
          />
        </div>
      );
    }
  };

  newActionCreated = (actionProperty) => {
    this.setState({
      [actionProperty.actionable_type.trim().toLowerCase() + "Modal"]: !this
        .state[actionProperty.actionable_type.trim().toLowerCase() + "Modal"],
      actionID: actionProperty.actionable_id,
      action: actionProperty,
    });
  };

  actionEdit = () => {
    if (true) {
      return {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.editFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 200, class: "table-actions" },
      };
    }
  };

  rowStyle = (row, rowIndex) => {
    if (
      row.actionable_type === "Payment" &&
      config["paymentHide"].includes(this.props.userRole)
    ) {
      return { display: "none" };
    }
    if (
      row.actionable_type === "Violation" &&
      config["ViolationRead"].includes(this.props.userRole)
    ) {
      return { display: "none" };
    }
    if (
      row.actionable_type === "Lien" &&
      config["LiensHide"].includes(this.props.userRole)
    ) {
      return { display: "none" };
    }
  };

  render() {
    let actions;
    if (_.isEmpty(this.state.titleActionArray))
      actions = this.state.actionArray;
    else if (_.isEmpty(this.state.actionArray))
      actions = this.state.titleActionArray;
    else actions = this.state.actionArray.concat(this.state.titleActionArray);
    let newActions = actions.map((value) => {
      let goodThroughValue = "";
      let actionValue = value
      if (actionValue.actionable_type === "Payment") {
        if (actionValue.description === "General Payment") {
          actionValue.actionable_type = "General Payment";
          actionValue.description = actionValue.payment_charge_type;
        } else if (actionValue.description === "Account Review") {
          actionValue.actionable_type = "Account Review"
          actionValue.description = null;
        } else {
          actionValue.actionable_type = "FY Assessment"
          if(actionValue.payment_charge_type && actionValue.payment_charge_type.includes("FY")){
            if(actionValue.payment_settlement_status &&  actionValue.payment_settlement_status === "cleared") {
              goodThroughValue = "- Good Through - "+ this.dateFormatter(actionValue.end_date)
            }
            actionValue.description = actionValue.payment_charge_type + goodThroughValue
          }
        }
      }
      if(actionValue.actionable_type === "TitleLien") {
        actionValue.actionable_type = "Lien";
        if(actionValue.title_review_order_and_lien_documents){
          actionValue.description = actionValue.title_review_order_and_lien_documents[0].doc_type;
        } else {
          actionValue.description = "";
        }
      }
      return actionValue;
    })

    const columns = [
      {
        dataField: "actionable_type",
        text: "Action Type",
        formatter: this.actionTypeFormatter,
        headerClasses: "header-cell-alignment",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
      },
      {
        dataField: "description",
        text: "Description",
        headerClasses: "header-cell-alignment",
        formatter: this.descriptionTypeFormatter,
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%" };
        },
      },
      {
        dataField: "hoa.name",
        text: "Association / Municipality",
        headerClasses: "header-cell-alignment",
        formatter: this.haoMunicipalNameFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: "15%" };
        },
        sort: true,
      },
      {
        dataField: "creation_date",
        text: "Creation Date/Age",
        formatter: this.dateFormatter,
        headerClasses: "header-cell-alignment",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
      },
      {
        dataField: "last_updated",
        text: "Last Updated",
        headerClasses: "header-cell-alignment",
        formatter: this.dateFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
        sort: true,
      },
      {
        dataField: "due_date",
        text: "Due Date",
        formatter: this.dateFormatter,
        headerClasses: "header-cell-alignment",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
      },
      {
        dataField: "current_status",
        text: "Current Status",
        headerClasses: "header-cell-alignment",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
      },
      {
        dataField: "total_payment_amount",
        text: "Amount Payable",
        formatter: this.amountPayableFormatter,
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
        style: { textAlign: "right" },
      },
      {
        dataField: "action_notes",
        text: "Notes/Actions/Next Steps",
        classes: "notes-width",
        formatter: this.notesFormatter,
        headerClasses: "header-cell-alignment",
      },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.editFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 200, class: "table-actions" },
        headerClasses: "header-cell-alignment",
      },
    ];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="create">
            {config["createAction"].includes(this.props.userRole) ? (
              <div className="creating">
                <button
                  type="btn"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      actionModal: !this.state.actionModal,
                      updateModal: false,
                    })
                  }
                >
                  <img className="create-img" src={create} alt="" hspace="10" />
                  Create Action
                </button>
              </div>
            ) : null}

            <div className="filtering">
              <button type="btn" className="btn btn-secondary">
                <img className="filter-img" src={filter} alt="" hspace="10" />
                Filter By Action Type
              </button>
            </div>
          </div>
          {this.state.actionModal && (
            <ActionModal
              modal={this.state.actionModal}
              toggle={this.toggleActionAnalyst}
              propertyID={this.state.propertyID}
              updateModal={this.state.updateModal}
              actionID={this.state.actionID}
              getActions={this.getActions}
              action={this.state.action}
              newActionCreated={this.newActionCreated}
            />
          )}
          {newActions && (
            <div style={{ width: "94%" }}>
              <BootstrapTable
                keyField="id"
                data={newActions}
                columns={columns}
                Pagination={paginationFactory}
                rowStyle={this.rowStyle}
              />
            </div>
          )}
          {/* {this.state.associationverificationModal && (
            <AskSearchQuestionsModal
              toggle={() => {
                this.setState({
                  associationverificationModal: !this.state
                    .associationverificationModal,
                });
              }}
              row={this.state.row}
              modal={this.state.associationverificationModal}
              propertyID={this.state.propertyID}
            />
          )} */}
          {this.state.lienModal && (
            <LienModal
              toggle={() => {
                this.setState({
                  lienModal: !this.state.lienModal,
                });
              }}
              title="Lien"
              open={this.state.lienModal}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              action={this.state.action}
              userRole={this.props.userRole}
              refershActions={this.toggleModal}
            />
          )}
          {this.state.inspectionModal && (
            <InspectionModal
              toggle={() => {
                this.setState({
                  inspectionModal: !this.state.inspectionModal,
                });
              }}
              title="Inspection"
              open={this.state.inspectionModal}
              row={this.state.row}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
            />
          )}
          {this.state.violationModal && (
            <ViolationModal
              toggle={() => {
                this.setState({
                  violationModal: !this.state.violationModal,
                });
              }}
              title="Violation"
              open={this.state.violationModal}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              refershActions={this.toggleModal}
            />
          )}
          {this.state.paymentModal && (
            <PaymentModal
              toggle={() => {
                this.setState({
                  paymentModal: !this.state.paymentModal,
                });
              }}
              title="Payment"
              open={this.state.paymentModal}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              userRole={this.props.userRole}
              refershActions={this.toggleModal}
              propertyInfo={this.props.propertyInfo}
              actionHoaId={this.state.action.hoa_id}
            />
          )}
          {this.state.associationinformationModal && (
            <AssociationInformationModal
              toggle={() => {
                this.setState({
                  associationinformationModal: !this.state
                    .associationinformationModal,
                });
              }}
              title="HOA"
              open={this.state.associationinformationModal}
              row={_.has(this.state.row, "hoa") && this.state.row.hoa}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
            />
          )}
          {this.state.associationverificationModal && (
            <AssociationVerificationModal
              toggle={() => {
                this.setState({
                  associationverificationModal: !this.state
                    .associationverificationModal,
                });
              }}
              title="HOA"
              open={this.state.associationverificationModal}
              // row={this.state.row}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              avId={this.state.row.association_verification_info_id}
              refershActions={this.toggleModal}
            />
          )}

          {this.state.deedverificationModal && (
            <DeedVerification
              actionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.deedverificationModal}
              toggle={() => {
                this.setState({
                  deedverificationModal: !this.state.deedverificationModal,
                });
              }}
              fetchDocuments={this.toggleModal}
              propertyInfo={this.props.propertyInfo}
              refershActions={this.toggleModal}
            />
          )}
          {this.state.municipalreviewModal && (
            <MunicipalReviewAction
              actionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.municipalreviewModal}
              toggle={() => {
                this.setState({
                  municipalreviewModal: !this.state.municipalreviewModal,
                });
              }}
              fetchDocuments={this.toggleModal}
            />
          )}
          {this.state.titleclearanceModal && (
            <TitleClearanceAction
              titleActionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.titleclearanceModal}
              toggle={() => {
                this.setState({
                  titleclearanceModal: !this.state.titleclearanceModal,
                });
              }}
              title="Title Clearance Action"
              refershActions={this.toggleModal}
            />
          )}
          {this.state.titlereviewModal && (
            <TitleReviewAction
              actionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.titlereviewModal}
              toggle={() => {
                this.setState({
                  titlereviewModal: !this.state.titlereviewModal,
                });
              }}
              formdata={this.state.row}
              title="Title Review Action"
              refershActions={this.toggleModal}
            />
          )}
          {this.state.municipalityregistrationModal && (
            <MunicipalRequirementAction
              actionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.municipalityregistrationModal}
              toggle={() => {
                this.setState({
                  municipalityregistrationModal: !this.state
                    .municipalityregistrationModal,
                });
              }}
              formdata={this.state.row}
              title="Municipality Registration Action"
              fetchDocuments={this.toggleModal}
              refershActions={this.toggleModal}
              actionTab="propertyAction"
              category={this.state.registrationCategory}
            />
          )}

          {this.state.municipalityverificationModal && (
            <MunicipalVerification
              actionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.municipalityverificationModal}
              toggle={() => {
                this.setState({
                  municipalityverificationModal: !this.state
                    .municipalityverificationModal,
                });
              }}
              formdata={this.state.row}
              title="Municipality Verification Action"
              refershActions={this.toggleModal}
            />
          )}
          {this.state.openCodeViolationModal && (
            <CodeViolationModal
              toggle={() => {
                this.setState({
                  openCodeViolationModal: !this.state.openCodeViolationModal,
                });
              }}
              className=""
              title="CodeViolation"
              open={this.state.openCodeViolationModal}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              row={this.state.row}
              refershActions={this.toggleModal}
            />
          )}
          {this.state.openLienCreationModal && (
               <LienActionModal
               toggle={() => {
                this.setState({
                  openLienCreationModal: !this.state.openLienCreationModal,
                });
              }}
               className=""
               title="Lien Action "
               open={this.state.openLienCreationModal}
               propertyID={this.props.propertyID}
               actionable_id={this.state.actionID}
               row={this.state.row}
              refershActions={this.toggleModal}
             />
          )}
        </React.Fragment>
      );
    }
  }
}
