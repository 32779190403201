import React, { useState, useEffect } from "react";
import "../../../styles/content.css";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import PropertySearchTable from "./PropertySearchTable";
import { toast } from "react-toastify";
import _ from "lodash";
import config from "../../../utils/config";
import Loader from "../../helper_components/LoaderComponent";

export default function PropertySearchComponent(props) {
  const [properties, setproperties] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [perpage, setperpage] = useState(10);
  const [total, settotal] = useState();
  const searchText = props.match.params.searchText;
  const user_role = props.match.params.user_role;
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    searchProperties();
  }, [form_inputs.sort]);

  const notify = (message) => toast(message);

  const handleSubmit = (event, pageNo, perPage) => {
    config.localforage.setItem("search-text", searchText);
    config.localforage.setItem("page-number", pageNo);
    config.localforage.setItem("per-page", perPage);
    setisLoading(true);
    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperpage(perPage);
    }
    apicall
      .searchProperties(searchText, form_inputs, pageNo, perPage)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("error")) {
          let errorMsg = helpers.getErrorMsg(response.error);
          if (errorMsg) notify("Search Failed: No Records Found: " + errorMsg);
        } else {
          if (typeof response !== "undefined" && response.length === 0) {
            notify("No Records Found: ");
          } else {
            if (_.isString) {
              config.localforage.setItem("searchResult", response);
            }
            setproperties(response.results);
            settotal(response.total);
            setisLoading(false);
          }
        }
      });
    event.preventDefault();
  };

  const searchProperties = (pageNo, perPage) => {
    setisLoading(true);
    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperpage(perPage);
    }
    apicall
      .searchProperties(searchText, form_inputs, pageNo, perPage)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("error")) {
          let errorMsg = helpers.getErrorMsg(response.error);
          if (errorMsg) notify("Search Failed: No Records Found: " + errorMsg);
        } else {
          if (typeof response !== "undefined" && response.length === 0) {
            notify("No Records Found: ");
          } else {
            if (_.isString) {
              config.localforage.setItem("searchResult", response);
            }
            setproperties(response.results);
            settotal(response.total);
            setisLoading(false);
          }
        }
      });
  };

  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };
  return (
    <React.Fragment>
      <section className="content">
        {props.guestSearch ? (
          <></>
        ) : (
          <>
            {isLoading && <Loader />}
            <header className="content-title">
              <h2 className="page-heading">
                <strong>Property Search</strong>
              </h2>
            </header>
          </>
        )}
        <div className="search-result">
          <PropertySearchTable
            properties={properties}
            handleSubmit={handleSubmit}
            pageNo={pageNo}
            page={perpage}
            totalCount={total}
            propertySearch={true}
            sortColumns={sortColumns}
          />
        </div>
      </section>
    </React.Fragment>
  );
}
