import React, { Component, Fragment } from "react";
import Loader from "../helper_components/LoaderComponent";
import "../../styles/_amenities.scss";
import DocumentsModal from "../documents/DocumentsModal";
import DocumentsTab from "../documents/DocumentsTab";
import _ from "lodash";
import apicall from "../../utils/apicall";
import amenitiesApi from "../../utils/api/amenitiesapi";
import helpers from "../../utils/helpers";
import config from "../../utils/config";
import { Button, CustomInput } from "reactstrap";
import AccessCodeModal from "./access_codes/AccessCodeModal";
import { FaEdit, FaLink, FaTrashAlt } from "react-icons/fa";

class Amenities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      isLoading: true,
      openAddDocumentModal: false,
      fileUpload: false,
      amenities: null,
      propertyid: props.propertyid,
      hoaId: props.hoaId,
      openModal: false,
      accessFor: "",
      accessCodes: [],
    };
  }

  componentWillMount() {
    this.getAmenities(this.props.hoaId);
    if (config["generalInfoRead"].includes(this.props.userRole)) {
      this.setState({
        isDisabled: true,
      });
    }
    this.getAccessCodes();
  }

  getAmenities = (hoaId) => {
    amenitiesApi.getAmenities(hoaId).then((response) => {
      if (response) {
        this.setState({ amenities: response, isLoading: false });
      }
      // if (response !== null && response.hasOwnProperty("errors")) {
      //   let errorMsg = helpers.getErrorMsg(response.errors);
      //   if (errorMsg) helpers.notify(errorMsg);
      // } else {
      //   console.log(response);
      //   this.setState({
      //     amenities: response,
      //     isLoading: false,
      //   });
      // }
    });
  };

  getAccessCodes = (event, pageNo, perPage) => {
    apicall
      .getAcessCodes(this.props.hoaId, pageNo, perPage)
      .then((response) => {
        this.setState({
          accessCodes: response.results,
          isLoader: false,
        });
      });
  };

  deleteAmentiesDoc = (documentId, hoaId, amenitiesId) => {
    amenitiesApi
      .deleteAmentiesDoc(hoaId, amenitiesId, documentId)
      .then((resp) => {
        this.getAmenities(hoaId);
      });
  };

  toggleDocument = () => {
    this.setState({ openAddDocumentModal: !this.state.openAddDocumentModal });
  };

  handleRadio = (key, value) => {
    this.setState({
      amenities: Object.assign({ ...this.state.amenities }, { [key]: value }),
    });
  };

  handleInput = (key,value) => {
    this.setState({
      amenities: Object.assign({ ...this.state.amenities }, { [key]: value }),
    });
  }

  handleChange = (e) => {
    if (e.target.name === "parking_fee") {
      this.start = e.target.selectionStart;
      let val = e.target.value;
      val = val.replace(/([^0-9.]+)/, "");
      val = val.replace(/^(0|\.)/, "");
      const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
      const value = match[1] + match[2];
      e.target.value = value;
      this.setState({
        amenities: Object.assign(
          { ...this.state.amenities },
          { [e.target.name]: value }
        ),
      });

      if (val.length > 0) {
        e.target.value = Number(value).toFixed(2);
        e.target.setSelectionRange(this.start, this.start);
        this.setState({
          amenities: Object.assign(
            { ...this.state.amenities },
            { [e.target.name]: Number(value).toFixed(2) }
          ),
        });
      }
    }
  };

  uploadDocDiv = () => {
    return (
      <div className="amenities-file-upload">
        <div className="file-upload-label">Upload Tenant Application</div>
        <div className="no-file">
          <Button
            onClick={() => this.setState({ openAddDocumentModal: true })}
            className="file-btn"
          >
            File Upload
          </Button>
          &nbsp;&nbsp;
          {_.isEmpty(this.state.amenities.documents) ? (
            <h6 className="noDocument">No File Uploaded</h6>
          ) : (
            <></>
          )}
        </div>
        {/* {this.state.openAddDocumentModal && (
          <DocumentsModal
            toggle={this.toggleDocument}
            title="Document"
            open={this.state.openAddDocumentModal}
            pageNo={1}
            hideDocumentName={true}
            isAmenties={true}
            propertyId={this.props.propertyid}
            amenitiesId={this.state.amenities.id}
            fetchDocuments={this.getAmenities}
            formdata={this.state.amenities}
          />
        )} */}
      </div>
    );
  };

  checkAccessCode = (accessCode, nextQues) => {
    let result;
    if (nextQues === "cp_access_controlled") {
      let value = accessCode.filter((x) => x.access_for === "pool");
      if (!_.isEmpty(value)) {
        if (value[0]["access_for"] === "pool") result = false;
      } else result = true;
    } else if (nextQues === "cfc_access_controlled") {
      let value = accessCode.filter((x) => x.access_for === "fitness");
      if (!_.isEmpty(value)) {
        if (value[0]["access_for"] === "fitness") result = false;
      } else result = true;
    } else if (nextQues === "tc_access_controlled") {
      let value = accessCode.filter((x) => x.access_for === "tennis");
      if (!_.isEmpty(value)) {
        if (value[0]["access_for"] === "tennis") result = false;
      } else result = true;
    } else if (nextQues === "cch_access_controlled") {
      let value = accessCode.filter((x) => x.access_for === "club_house");
      if (!_.isEmpty(value)) {
        if (value[0]["access_for"] === "club_house") result = false;
      } else result = true;
    }
    console.log("the result is", result);
    return result;
  };

  listAccessCodeData = (accessCode, nextQues) => {
    let result;
    if (nextQues === "cp_access_controlled") {
      result = accessCode.filter((x) => x.access_for === "pool");
    } else if (nextQues === "cfc_access_controlled") {
      result = accessCode.filter((x) => x.access_for === "fitness");
    } else if (nextQues === "tc_access_controlled") {
      result = accessCode.filter((x) => x.access_for === "tennis");
    } else if (nextQues === "cch_access_controlled") {
      result = accessCode.filter((x) => x.access_for === "club_house");
    }
    console.log("the result is", result);
    return result;
  };

  listQueries = () => {
    console.log("The data is", this.state.accessCodes);
    let amenities = this.state.amenities;
    const amenitiesObj = config.amenitiesObj;
    let amenitiesDiv = _.map(amenities, (val, amenity) => {
      let nextQues = amenitiesObj[amenity];
      if (
        !_.isEmpty(config.amenitiesMap[amenity]) &&
        _.has(amenitiesObj, amenity)
      ) {
        return (
          <div className="amenity">
            <div className="amenity-ques-left">
              <label className="text">{config.amenitiesMap[amenity]}</label>
              <div className="radio">
                <CustomInput
                  type="radio"
                  //  className="radio-button"
                  id={`${amenity}-yes`}
                  name={amenity}
                  onClick={() => this.handleRadio(amenity, true)}
                  checked={val === true ? "checked" : ""}
                  disabled={this.state.isDisabled}
                />
                <label>Yes</label>
                <CustomInput
                  id={`${amenity}-no`}
                  type="radio"
                  //className="radio-button"
                  name={amenity}
                  checked={val === false ? "checked" : ""}
                  onClick={() => this.handleRadio(amenity, false)}
                  disabled={this.state.isDisabled}
                />
                <label>No</label>
              </div>
            </div>
            {val === true && nextQues === "mailbox_access" ? (
              <>
                <div
                  className="amenity-ques-right"
                  style={{
                    paddingRight: _.isEmpty(this.state.amenities.documents)
                      ? "25%"
                      : "16%",
                    width: "22%",
                  }}
                >
                  <label className="mailbox_label">
                    {config.amenitiesMap[nextQues]}
                  </label>
                  &nbsp;&nbsp;
                  <div className="mailbox_radio" style={{ marginLeft: "30px" }}>
                    <CustomInput
                      type="radio"
                      //className="radio-button"
                      id={`${nextQues}-yes`}
                      name={nextQues}
                      onClick={() => this.handleRadio(nextQues, true)}
                      checked={amenities[nextQues] === true ? "checked" : ""}
                      disabled={this.state.isDisabled}
                    />
                    <label style={{ position: "relative", right: "10px" }}>
                      Association
                    </label>
                    <CustomInput
                      id={`${nextQues}-no`}
                      type="radio"
                      //className="radio-button"
                      name={nextQues}
                      checked={amenities[nextQues] === false ? "checked" : ""}
                      onClick={() => this.handleRadio(nextQues, false)}
                      disabled={this.state.isDisabled}
                    />
                    <label
                      style={{
                        position: "relative",
                        right: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Post Office
                    </label>
                    <CustomInput
                      className="move-in-fee-textbox"
                      type="number"
                      pattern="\d{1,5}"
                      // placeholder={this.state.assigned_mailbox_number}
                      name="assigned_mailbox_number"
                      onChange={(e) => {this.handleInput("assigned_mailbox_number", e.target.value )}}
                      value={this.state.amenities.assigned_mailbox_number}
                      style={{ width: "150px" }}
                      placeholder="Mailbox Number"
                    />
                  </div>
                </div>
              </>
            ) : null}

            {val === true && nextQues === "parking_fee" && (
              <div className="input-fields">
                <label> {config.amenitiesMap[nextQues]}</label>

                <CustomInput
                  className="move-in-fee-textbox"
                  type="text"
                  pattern="\d{0,7}(\.\d{1,2})?$"
                  //placeholder={this.state.feePlaceHolder}
                  name="parking_fee"
                  onChange={this.handleChange}
                  value={this.state.amenities.parking_fee}
                  style={{ width: "15%" }}
                />
              </div>
            )}

            {val === true &&
            nextQues !== "upload_doc" &&
            nextQues !== "mailbox_access" &&
            nextQues !== "parking_fee" ? (
              <>
                <div className="amenity-ques-right">
                  <label className="text">
                    {config.amenitiesMap[nextQues]}
                  </label>
                  <div className="radio">
                    <CustomInput
                      type="radio"
                      //className="radio-button"
                      id={`${nextQues}-yes`}
                      name={nextQues}
                      onClick={() => this.handleRadio(nextQues, true)}
                      checked={amenities[nextQues] === true ? "checked" : ""}
                      disabled={this.state.isDisabled}
                    />
                    <label>Yes</label>
                    <CustomInput
                      id={`${nextQues}-no`}
                      type="radio"
                      //className="radio-button"
                      name={nextQues}
                      checked={amenities[nextQues] === false ? "checked" : ""}
                      onClick={() => this.handleRadio(nextQues, false)}
                      disabled={this.state.isDisabled}
                    />
                    <label>No</label>
                  </div>
                </div>
              </>
            ) : (
              <div
                className={
                  _.isEmpty(this.state.amenities.documents)
                    ? "amenities-file-upload-noDocument"
                    : "amenity-ques-right-file"
                }
              >
                {val === true &&
                  nextQues === "upload_doc" &&
                  this.uploadDocDiv()}
              </div>
            )}
            <div style={{ width: "10%" }}>
              {" "}
              {amenities[nextQues] === true &&
                (nextQues === "cp_access_controlled" ||
                  nextQues === "cfc_access_controlled" ||
                  nextQues === "tc_access_controlled" ||
                  nextQues === "cch_access_controlled") && (
                  <div>
                    {this.checkAccessCode(this.state.accessCodes, nextQues) ? (
                      <Button
                        onClick={() => {
                          console.log("the config value", config);
                          this.setState({
                            openModal: true,
                            accessFor: config.amenities_accesscode[nextQues],
                            isUpdate: false,
                            rows: this.listAccessCodeData(
                              this.state.accessCodes,
                              nextQues
                            ),
                          });
                        }}
                        style={{
                          marginTop: "0px",
                          marginBottom: "10px",
                          float: "right",
                        }}
                      >
                        Create
                      </Button>
                    ) : (
                      <FaEdit
                        style={{ float: "right" }}
                        size="1.5em"
                        onClick={() => {
                          this.setState({
                            rows: this.listAccessCodeData(
                              this.state.accessCodes,
                              nextQues
                            ),
                            openModal: true,
                            isUpdate: true,
                            accessFor: config.amenities_accesscode[nextQues],
                          });
                        }}
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
        );
      }
    });

    return amenitiesDiv;
  };

  updateAmenities = () => {
    amenitiesApi
      .updateAmenities(
        this.state.hoaId,
        this.state.amenities,
        this.state.amenities.id
      )
      .then((response) => {
        if (response) {
          helpers.notify("Amenities Updated Successfully.");
          this.setState({ amenities: response });
        }
        // console.log(response);
        // if (response !== null && response.hasOwnProperty("errors")) {
        //   let errorMsg = helpers.getErrorMsg(response.errors);
        //   if (errorMsg) helpers.notify(errorMsg);
        // } else {
        //   helpers.notify("Update Successful");
        //   this.setState({ amenities: response });
        // }
      });
  };

  displayDocumentTable = () => {
    return (
      <div className="amenities-document-modal">
        {this.state.openAddDocumentModal && (
          <DocumentsModal
            toggle={this.toggleDocument}
            title="Document"
            open={this.state.openAddDocumentModal}
            pageNo={1}
            hideDocumentName={true}
            isAmenties={true}
            hoaId={this.props.hoaId}
            amenitiesId={this.state.amenities.id}
            fetchDocuments={this.getAmenities}
            formdata={this.state.amenities}
          />
        )}
        {this.state.amenities.documents !== null && (
          <DocumentsTab
            documents={this.state.amenities.documents}
            isAmenity={true}
            theme={"general-info-tab"}
            deleteDocument={this.deleteAmentiesDoc}
            hoaId={this.props.hoaId}
            amenitiesId={this.state.amenities.id}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {this.state.amenities && this.listQueries()}

        <div style={{ marginLeft: "50%", marginBottom: "2rem" }}>
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={() => this.updateAmenities()}
          >
            Submit
          </button>
        </div>
        {this.state.amenities && this.displayDocumentTable()}
        {this.state.openModal && (
          <AccessCodeModal
            toggle={() => this.setState({ openModal: false })}
            modal={true}
            isUpdate={this.state.isUpdate}
            accessFor={this.state.accessFor}
            propertyid={this.state.propertyid}
            refreshList={this.getAccessCodes}
            // separateAccessCodes={separateAccessCodes}
            // separateCommunity={separateCommunity}
            accessCodesObject={this.state.rows}
            type={"four"}
            hoaId={this.props.hoaId}
            isAmenties={true}
            handleSubmit={this.updateAmenities}
          />
        )}
      </Fragment>
    );
  }
}

export default Amenities;
