import React, { Component } from "react";
import "../../../styles/navbar.scss";
import "../../../styles/content.css";
import apicall from "../../../utils/apicall";
import { toast } from "react-toastify";
import TabsComponent from "../../helper_components/TabsComponent";
import "../../../styles/_tab.scss";
import AccessCodeTab from "./AccessCodeTab";
import _ from "lodash";
//import { CustomInput } from 'reactstrap'

export default class AccessCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCodes: [],
      accessCodeModalOpen: false,
      accessCodeUpdate: false,
      accessForObject: {},
      modalType: "",
      propertyid: this.props.propertyid,
      accessCodesObject: {},
      isLoader: true,
      hoaId: this.props.hoaId,
    };
  }

  componentWillMount() {
    this.getAccessFor(this.state.hoaId);
    this.getAccessCodes();
  }

  getAccessCodes = (event, pageNo, perPage) => {
    apicall
      .getAcessCodes(this.state.hoaId, pageNo, perPage)
      .then((response) => {
        this.setState({
          accessCodes: response.results,
          isLoader: false,
        });
      });
  };

  toggleCreateModal = () => {
    this.setState({
      accessCodeModalOpen: !this.state.accessCodeModalOpen,
    });
  };

  toggleUpdateModal = (accessCodesObject = {}) => {
    this.setState({
      accessCodeUpdate: !this.state.accessCodeUpdate,
      accessCodesObject: accessCodesObject,
    });
  };

  notify = (msg) => toast(msg);

  getAccessFor = (hoaId) => {
    apicall.getAccessFor(hoaId).then((resp) => {
      this.setState({
        accessForObject: resp,
      });
    });
  };

  render() {
    const accessForObject = this.state.accessForObject;
    console.log("accessForObject==>", accessForObject);
    let listofTabs = _.values(accessForObject);

    let accessForKeys = Object.keys(accessForObject);
    let tabsComponentsList = accessForKeys.map((accessFor, i) => (
      <AccessCodeTab
        accessFor={accessFor}
        key={i}
        accessForTxt={accessForObject[accessFor]}
        propertyid={this.state.propertyid}
        propertyInfo={this.props.propertyInfo}
        getPropertyInfo={this.props.getPropertyInfo}
        refreshList={this.getAccessCodes}
        accessCodes={this.state.accessCodes}
        hoaId={this.state.hoaId}
      />
    ));

    return (
      <>
        <TabsComponent
          theme="tab"
          components={tabsComponentsList}
          listofTabs={listofTabs}
          generateTabURL={false}
        />
      </>
    );
  }
}
