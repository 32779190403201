import React, { useState, useEffect } from "react";
import config from "../../utils/config";
import TabsComponent from "../helper_components/TabsComponent";
import "../../styles/_general.scss";
import MuniOrderHistory from "../orders/municipality_order/MuniOrderHistory";
import TitleOrderHistory from "../orders/title_order/TitleOrderHistory";

export default function TitleMunicipalOrderTabs(props) {
  const [activeTab, setactiveTab] = useState(1);
  const [propertyID, setpropertyID] = useState(props.propertyID);
  let listofTabs = "";
  if (config["LeaseHide"].includes(props.userRole)) {
    listofTabs = ["Title Order History", "Muni Order History"];
  } else {
    listofTabs = ["Title Order History", "Muni Order History"];
  }
  let tabsComponentsList = new Array(listofTabs.length).fill(null);
  tabsComponentsList[0] = <TitleOrderHistory clientId={props.clientId} />;
  tabsComponentsList[1] = (
    <MuniOrderHistory clientId={props.clientId} propertyID={propertyID} />
  );

  return (
    <React.Fragment>
      <div className="general-component" style={{ width: "95%" }}>
        <TabsComponent
          theme="tab"
          components={tabsComponentsList}
          listofTabs={listofTabs}
          generateTabURL={false}
          activeTab={activeTab}
          theme={"general-info-tab"}
        />
      </div>
    </React.Fragment>
  );
}
