import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_municipalModal.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import _ from "lodash";
import AddOfficeModal from "./AddOfficeModal";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import ConfirmBox from "../helper_components/ConfirmBox";
import Select from "react-select";
import MunicipalRequirementModal from "./MunicipalRequirementModal";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import config from "../../utils/config";
import NotesHistory from "../CodeViolation/NotesHistory"

export default function MunicipalModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : {});
  const [openOfficeModal, setopenOfficeModal] = useState(false);
  const [
    openMunicipalRequirementModal,
    setopenMunicipalRequirementModal,
  ] = useState(false);
  const [states, set_States] = useState(props.states ? props.states : []);
  const [counties, setcounties] = useState();
  const [officeData, setofficeData] = useState({});
  const [deleteModal, setdeleteModal] = useState(false);
  const [notesInput, setNotesInput] = useState("");
  const [typeOptions, settypeOptions] = useState({
    "City / Township": "City / Township",
    "Village / Burrough": "Village / Burrough",
  });
  const [isDisabled, setisDisabled] = useState(
    props.formdata
      ? props.formdata.municipality_requirements
        ? false
        : true
      : true
  );

  const [userRole, setuserRole] = useState("");

  useEffect(() => {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        setuserRole(role);
      })
      .catch((e) => console.log(e));
  }, []);

  const toggleModal = () => {
    setopenOfficeModal(!openOfficeModal);
  };

  const toggleMunicipalRequirementModal = () => {
    setopenMunicipalRequirementModal(!openMunicipalRequirementModal);
  };

  const handleNotesChange = (event) => {
    setNotesInput(event.target.value);
    let targetName = event.target.name;
    setform(Object.assign({ ...formdata }, { [targetName]: event.target.value }));
  };

  useEffect(() => {
    filterCounties();
  }, [formdata.state]);

  const filterCounties = () => {
    apicall.filterStates(formdata).then((response) => {
      if (response) {
        let countiesArray = [];
        if (response.counties) {
          countiesArray = response.counties.map((x) => {
            return { value: x.county, label: x.county };
          });
          setcounties(countiesArray);
        }
      }
    });
  };

  useEffect(() => {
    if (props.isDashboardMuni) readMunicipal(props.muniID);
  }, []);

  const readMunicipal = (id) => {
    apicall.readMunicipal(id).then((response) => {
      if (response) {
        setform(response);
        if (!_.isEmpty(response.municipality_requirements))
          setisDisabled(false);
      }
    });
  };

  const deleteMunicipalOffice = (officeData) => {
    apicall
      .deleteMunicipalOffice(formdata.id, officeData.id)
      .then((response) => {
        if (response) {
          helpers.notify(response.message);
          readMunicipal(response.id);
        }
      });
  };

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;

    if (isZip && value.length > 5) {
      let res = helpers.formatZip(value);
      setform(Object.assign({ ...formdata }, { [targetID]: res }));
    } else {
      setform(Object.assign({ ...formdata }, { [targetID]: value }));
    }
  };

  const notify = (errorMsg) => toast(errorMsg);

  const handleSubmit = (event) => {
    if (validateForm) {
      if (userRole !== "admin" && _.isEmpty(formdata.muni_type)) {
        helpers.notify("Please select the municipal type");
      } else {
        if (formdata.id) {
          const data = { municipality: formdata };
          apicall
            .updatemunicipality(data, formdata.id)
            .then((response) => {
              helpers.notify("Municipality updated Successfully");
              // setTimeout(() => {
              //   //props.toggle();
              // }, 600);
              if (response) {
                setNotesInput("");
                setform(response);
                if (!_.isEmpty(response.municipality_requirements))
                  setisDisabled(false);
              }
            })
            .catch((e) => {
              let errorMsg = helpers.getErrorMsg(e.response.data.errors);
              if (errorMsg) notify(errorMsg);
            });
        } else {
          const data = { municipality: formdata };
          apicall
            .addmunicipality(data)
            .then((response) => {
              helpers.notify("Municipality Created Successfully");
              // setTimeout(() => {
              //   // props.toggle();
              // }, 600);
              if (response) {
                setNotesInput("");
                setform(response);
                if (!_.isEmpty(response.municipality_requirements))
                  setisDisabled(false);
              }
            })
            .catch((e) => {
              let errorMsg = helpers.getErrorMsg(e.response.data.errors);
              if (errorMsg) notify(errorMsg);
            });
        }
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (/^[a-zA-Z]+$/.test(formdata.state)) {
    }
    return isValid;
  };

  const stateOptions = [];
  let stateDetails;
  if (!_.isEmpty(states)) {
    stateDetails = states;
  }

  stateOptions.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );

  if (!_.isEmpty(stateDetails)) {
    for (let type of stateDetails) {
      stateOptions.push(
        <option key={type.label} value={type.value}>
          {type.value}
        </option>
      );
    }
  }

  const countyOptions = [];
  let countyDetails;
  if (!_.isEmpty(counties)) {
    countyDetails = counties;
  }

  countyOptions.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );

  if (!_.isEmpty(countyDetails)) {
    for (let type of countyDetails) {
      countyOptions.push(
        <option key={type.label} value={type.value}>
          {type.value}
        </option>
      );
    }
  }

  const typeSelect = [];
  typeSelect.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  for (let type in typeOptions) {
    typeSelect.push(
      <option key={type} value={type}>
        {typeOptions[type]}
      </option>
    );
  }

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setofficeData(row);
            setopenOfficeModal(true);
          }}
        />
        <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setofficeData(row);
            setdeleteModal(true);
          }}
        />
      </div>
    );
  };

  const periodicityCapitalize = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(cell)) {
      return helpers.capitalizeFirstLetter(cell);
    }
  };

  const emailFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(row.title_point_of_contacts)) {
      return row.title_point_of_contacts[0].email;
    } else return "";
  };

  const phoneFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(row.title_point_of_contacts)) {
      return row.title_point_of_contacts[0].phone;
    } else return "";
  };

  let actions = formdata.offices;

  const columns = [
    {
      dataField: "name",
      text: "Office",

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "title_point_of_contacts",
      text: "Email",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: emailFormatter,
    },
    {
      dataField: "title_point_of_contacts",
      text: "Phone Number",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: phoneFormatter,
    },
    {
      dataField: "payee_co_name",
      text: "C/o Name",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "address",
      text: "Address",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "city",
      text: "City",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "state",
      text: "State",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "zip",
      text: "Zip",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: buttonFormatter,
      headerAttrs: { width: 200 },
      attrs: { width: 200, class: "table-actions" },
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
  ];

  let municipality_requirements = formdata.municipality_requirements;

  const requirementsColumns = [
    {
      dataField: "requirement_type",
      text: "Requirement Type",

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "required",
      text: "Required",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "registration_method",
      text: "Registration Method",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "periodicity",
      text: "Periodicity",
      headerClasses: "header-cell-alignment",
      formatter: periodicityCapitalize,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
    {
      dataField: "registration_amount",
      text: "Registration Amount",
      headerClasses: "header-cell-alignment",

      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
    },
  ];
  const rowStyle = (row, rowIndex) => {
    if (rowIndex % 2 !== 0) {
      return { backgroundColor: "lightgray" };
    }
  };
  const formElements = () => {
    return (
      <>
        <FormGroup row>
          <Label for="" sm={1}>
            State
          </Label>
          <Col sm={2}>
            <Input
              type="select"
              id="state"
              name="state"
              value={formdata.state}
              onChange={handleChange}
            >
              {stateOptions}
            </Input>
            {/* <span className="fa fa-caret-down customDropdownIcon-codeviolation"></span> */}
          </Col>

          <Label for="" sm={2}>
            County
          </Label>
          <Col sm={7}>
            <Select
              options={counties ? counties : []}
              isMulti={true}
              onChange={(value) => {
                console.log("The county is", formdata.county);
                if (!_.isEmpty(value)) {
                  value = value.map((x) => {
                    return x.value;
                  });
                  setform({ ...formdata, county: value.toString() });
                } else {
                  setform({ ...formdata, county: "" });
                }
              }}
              id={"county"}
              name="county"
              placeholder="Select County..."
              className="states-select"
              defaultValue={helpers.populateObjectArray(formdata.county)}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="" sm={1}>
            Type
          </Label>
          <Col sm={2}>
            <Input
              type="select"
              id={"muni_type"}
              name="muni_type"
              value={formdata.muni_type}
              onChange={handleChange}
            >
              {typeSelect}
            </Input>
            {/* <span className="fa fa-caret-down customDropdownIcon-codeviolation"></span> */}
          </Col>

          <Label for="" sm={2}>
            Municipality
          </Label>
          <Col sm={7}>
            <TextValidator
              type="text"
              name="name"
              id="name"
              placeholder="Municipal Name"
              onChange={handleChange}
              value={formdata.name}
            />
          </Col>
        </FormGroup>

        <div className="municipal-buttons">
          <div>
            {" "}
            <Button
              onClick={() => {
                setofficeData({});
                setopenOfficeModal(true);
              }}
              className="municipal-addoffice-button"
            >
              Add Office
            </Button>
          </div>
        </div>
        <div className="municipal-office-table">
          {actions && (
            <BootstrapTable
              keyField="id"
              data={actions}
              columns={columns}
              rowStyle={rowStyle}
            />
          )}
        </div>
        <div className="municipal-buttons">
          <div className="municipal-requirement-button">
            <Button
              onClick={() => {
                setofficeData({});
                setopenMunicipalRequirementModal(true);
              }}
              className="municipal-addoffice-button"
              disabled={isDisabled}
            >
              Municipality Requirement
            </Button>
          </div>
        </div>
        <div className="municipal-office-table">
          {municipality_requirements && (
            <BootstrapTable
              keyField="id"
              data={municipality_requirements}
              columns={requirementsColumns}
              rowStyle={rowStyle}
            />
          )}
        </div>
        <FormGroup row>
          <Label for="" style={{ position: "relative", left: "40px" }}>Notes</Label>
          <Col sm={11} style={{ position: "relative", left: "60px" }}>
            <Input
              type="textarea"
              name="municipality_notes"
              id="municipality_notes"
              placeholder="Notes"
              onChange={handleNotesChange}
              value={notesInput}
            />
          </Col>
        </FormGroup>
        <FormGroup>
        <div className="municipal-submit">
          <Button
            className="municipal-submit-button"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
        </FormGroup>
      </>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <Modal
          isOpen={props.open}
          toggle={props.toggle}
          className="municipal-modal"
        >
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className="">
                {" "}
                {`${formdata.id ? "Edit " : "Add "}Municipal `}
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              {formElements()}
            </ValidatorForm>
            <div style={{ position: "relative", top: "60px" }}>
              <NotesHistory notes={formdata.notes ? formdata.notes : []}  municipalModal= {true} />
            </div>
          </ModalBody>
        </Modal>
        {openOfficeModal && (
          <AddOfficeModal
            toggle={toggleModal}
            title="Office Details"
            open={openOfficeModal}
            formdata={formdata}
            officeData={officeData}
            readMunicipal={readMunicipal}
          />
        )}

        {openMunicipalRequirementModal && (
          <MunicipalRequirementModal
            toggle={toggleMunicipalRequirementModal}
            open={openMunicipalRequirementModal}
            title="Municipal Requirement Details"
            municipality_requirements={formdata.municipality_requirements}
            formdata={formdata}
            readMunicipal={readMunicipal}
          />
        )}
        {deleteModal && (
          <ConfirmBox
            onYesClick={() => {
              deleteMunicipalOffice(officeData);
              setdeleteModal(false);
            }}
            onNoClick={() => setdeleteModal(false)}
            heading={
              <span>
                Are you sure you want to delete the Office Details:{" "}
                {officeData.name}?
              </span>
            }
          >
            <div>
              <p>All Office Details related will be deleted.</p>
            </div>
          </ConfirmBox>
        )}
      </div>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}
