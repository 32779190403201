import React, { useState, useEffect } from "react";
import "../../styles/_newDashboard.scss";
import Loader from "../helper_components/LoaderComponent";
import _ from "lodash";

export default function TitlePendingStatsDashboard(props) {
  const [loading, set_loading] = useState(false);
  const [toggleModal, settoggleModal] = useState(false);
  const [municipalTierOne, setmunicipalTierOne] = useState("");
  const [municipalTierTwo, setmunicipalTierTwo] = useState("");
  const [municipalTierThree, setmunicipalTierThree] = useState("");
  const [pendingDashboardFilter, setpendingDashboardFilter] = useState();
  const [generalPaymentFields, setgeneralPaymentFields] = useState();
  const [generalPaymentRightFields, setgeneralPaymentRightFields] = useState();
  const [muni_registration, setmuni_registration] = useState();
  const [muni_verification, setmuni_verification] = useState();

  useEffect(() => {
    getDashboardPendingDetails();
  }, [
    props.dashboardValue,
    props.pendingDashboardFilter,
    props.generalPaymentRightFields,
    props.generalPaymentRightFields,
  ]);

  const getDashboardPendingDetails = () => {
    if (!_.isEmpty(props.dashboardValue.muni_registration)) {
      setmuni_registration(props.dashboardValue.muni_registration);
    }
    if (!_.isEmpty(props.dashboardValue.muni_verifications)) {
      setmuni_verification(props.dashboardValue.muni_verifications);
    }
    if (!_.isEmpty(props.pendingDashboardFilter)) {
      setpendingDashboardFilter(props.pendingDashboardFilter);
    }
    if (!_.isEmpty(props.generalPaymentFields)) {
      setgeneralPaymentFields(props.generalPaymentFields);
    }
    if (!_.isEmpty(props.generalPaymentRightFields)) {
      setgeneralPaymentRightFields(props.generalPaymentRightFields);
    }
    set_loading(false);
    settoggleModal(true);
  };

  const generateDashboardTierOne = (name, filterValue, tierCount) => {
    let total_count;
    let left_count;
    if (_.isEmpty(tierCount)) {
      total_count = 0;
      left_count = 0;
    } else {
      total_count = tierCount.total ? tierCount.total : tierCount.total_count;
      left_count = tierCount.left_count;
    }

    return (
      <div
        style={{
          backgroundColor: municipalTierOne === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          setmunicipalTierOne(name);
          setmunicipalTierTwo("");
          setmunicipalTierThree("");
          props.filterTierOne(filterValue);
          props.filterTierTwo("");
          props.filterTierThree("");
        }}
      >
        <div className="card-firstRow">{total_count}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{left_count}</div>
          <div className="secondRowData">{"0"}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateDashboardTierTwo = (name, filterValue, tierCount) => {
    return (
      <div
        style={{
          backgroundColor: municipalTierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          setmunicipalTierTwo(name);
          setmunicipalTierThree("");
          props.filterTierTwo(filterValue);
          props.filterTierThree("");
        }}
      >
        <div className="card-firstRow">
          {tierCount.total_count ? tierCount.total_count : "0"}
        </div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">
            {tierCount.left_count ? tierCount.left_count : "0"}
          </div>
          <div className="secondRowData">{"0"}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generatePaymentTierTwoDashboard = (
    name,
    tierLeftCount,
    tierRightCount
  ) => {
    return (
      <div
        style={{
          backgroundColor: municipalTierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          setmunicipalTierTwo(name);
          setmunicipalTierThree("");
          props.filterTierTwo(name);
          props.filterTierThree("");
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div
            className="firstRowData"
            // onClick={() => {
            //   props.filterPaymentRightSideValue("");
            // }}
          >
            {tierLeftCount}
          </div>
          <div
            className="secondRowData"
            // onClick={() => {
            //   props.filterPaymentRightSideValue("right");
            // }}
          >
            {tierRightCount}
          </div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateDashboardTierThree = (name, key, count) => {
    return (
      <div
        style={{
          backgroundColor: municipalTierThree === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          setmunicipalTierThree(name);
          props.filterTierThree(key);
        }}
      >
        <div className="card-firstRow">{}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{count}</div>
          <div className="secondRowData">{"0"}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      {toggleModal && (
        <>
          <div className="flex-container" style={{ width: "95%" }}>
            {generateDashboardTierOne(
              "Municipality Verification",
              "muni_verifications",
              muni_verification
            )}
            {generateDashboardTierOne(
              "Municipality Registration",
              "muni_registrations",
              muni_registration
            )}
            {!_.isEmpty(pendingDashboardFilter) &&
              !_.isEmpty(pendingDashboardFilter.general_payment) && (
                <>
                  {generateDashboardTierOne(
                    "General Payment",
                    "general_payment",
                    pendingDashboardFilter.general_payment
                  )}
                </>
              )}
          </div>

          {municipalTierOne === "Municipality Registration" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(muni_registration.county) && (
                <>
                  {generateDashboardTierTwo(
                    "County",
                    "county",
                    muni_registration.county
                  )}
                </>
              )}
              {!_.isEmpty(muni_registration.city) && (
                <>
                  {generateDashboardTierTwo(
                    "City /Township",
                    "city",
                    muni_registration.city
                  )}
                </>
              )}
              {!_.isEmpty(muni_registration.village) && (
                <>
                  {generateDashboardTierTwo(
                    "Village / Burrough",
                    "village",
                    muni_registration.village
                  )}
                </>
              )}
            </div>
          )}
          {municipalTierOne === "Municipality Verification" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(muni_verification.county) && (
                <>
                  {" "}
                  {generateDashboardTierTwo(
                    "County",
                    "county",
                    muni_verification.county
                  )}
                </>
              )}
              {!_.isEmpty(muni_verification.city) && (
                <>
                  {" "}
                  {generateDashboardTierTwo(
                    "City /Township",
                    "city",
                    muni_verification.city
                  )}
                </>
              )}
              {!_.isEmpty(muni_verification.village) && (
                <>
                  {generateDashboardTierTwo(
                    "Village / Burrough",
                    "village",
                    muni_verification.village
                  )}
                </>
              )}
            </div>
          )}

          {municipalTierOne === "General Payment" &&
            !_.isEmpty(generalPaymentFields) &&
            !_.isEmpty(generalPaymentRightFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generatePaymentTierTwoDashboard(
                  "New Payment",
                  generalPaymentFields.new_payment,
                  generalPaymentRightFields.new_payment
                )}
                {generatePaymentTierTwoDashboard(
                  "Invoice Received",
                  generalPaymentFields.invoice_received,
                  generalPaymentRightFields.invoice_received
                )}
                {generatePaymentTierTwoDashboard(
                  "In-Review",
                  generalPaymentFields.in_review,
                  generalPaymentRightFields.in_review
                )}
                {generatePaymentTierTwoDashboard(
                  "Awaiting Approval",
                  generalPaymentFields.awaiting_approval,
                  generalPaymentRightFields.awaiting_approval
                )}
                {generatePaymentTierTwoDashboard(
                  "Approved",
                  generalPaymentFields.approved,
                  generalPaymentRightFields.approved
                )}
                {generatePaymentTierTwoDashboard(
                  "Rejected",
                  generalPaymentFields.rejected,
                  generalPaymentRightFields.rejected
                )}
                {generatePaymentTierTwoDashboard(
                  "Check Sent",
                  generalPaymentFields.check_sent,
                  generalPaymentRightFields.check_sent
                )}
              </div>
            )}

          {municipalTierOne === "Municipality Registration" &&
            municipalTierTwo === "County" && (
              <div className="flex-container" style={{ width: "95%" }}>
                {!_.isEmpty(muni_registration.left_fields) && (
                  <>
                    {generateDashboardTierThree(
                      "Business License",
                      "business_license",
                      muni_registration.left_fields.business_license
                    )}
                    {generateDashboardTierThree(
                      "Landlord Registration",
                      "landlord_registration",
                      muni_registration.left_fields.landlord_registration
                    )}
                    {generateDashboardTierThree(
                      "VPR",
                      "vacant_property_registration",
                      muni_registration.left_fields.vacant_property_registration
                    )}
                    {generateDashboardTierThree(
                      "Certificate of Occupancy",
                      "certificate_of_occupancy",
                      muni_registration.left_fields.cerificate_of_occupancy
                    )}
                    {generateDashboardTierThree(
                      "Rental Registration",
                      "rental_registration",
                      muni_registration.left_fields.rental_registration
                    )}
                    {generateDashboardTierThree(
                      "Crime Free Housing",
                      "crime_free_housing",
                      muni_registration.left_fields.crime_free_housing
                    )}
                    {generateDashboardTierThree(
                      "Rental Taxes",
                      "rental_taxes",
                      muni_registration.left_fields.rental_taxes
                    )}
                    {generateDashboardTierThree(
                      "Property Deregistration",
                      "property_deregistration",
                      0
                    )}
                  </>
                )}
              </div>
            )}
          {municipalTierOne === "Municipality Registration" &&
            municipalTierTwo === "City /Township" && (
              <div className="flex-container" style={{ width: "95%" }}>
                {!_.isEmpty(muni_registration.left_fields) && (
                  <>
                    {generateDashboardTierThree(
                      "Business License",
                      "business_license",
                      muni_registration.left_fields.business_license
                    )}
                    {generateDashboardTierThree(
                      "Landlord Registration",
                      "landlord_registration",
                      muni_registration.left_fields.landlord_registration
                    )}
                    {generateDashboardTierThree(
                      "VPR",
                      "vacant_property_registration",
                      muni_registration.left_fields.vacant_property_registration
                    )}
                    {generateDashboardTierThree(
                      "Certificate of Occupancy",
                      "certificate_of_occupancy",
                      muni_registration.left_fields.cerificate_of_occupancy
                    )}
                    {generateDashboardTierThree(
                      "Rental Registration",
                      "rental_registration",
                      muni_registration.left_fields.rental_registration
                    )}
                    {generateDashboardTierThree(
                      "Crime Free Housing",
                      "crime_free_housing",
                      muni_registration.left_fields.crime_free_housing
                    )}
                    {generateDashboardTierThree(
                      "Rental Taxes",
                      "rental_taxes",
                      muni_registration.left_fields.rental_taxes
                    )}
                    {generateDashboardTierThree(
                      "Property Deregistration",
                      "property_deregistration",
                      0
                    )}
                  </>
                )}
              </div>
            )}
          {municipalTierOne === "Municipality Registration" &&
            municipalTierTwo === "Village / Burrough" && (
              <div className="flex-container" style={{ width: "95%" }}>
                {!_.isEmpty(muni_registration.left_fields) && (
                  <>
                    {generateDashboardTierThree(
                      "Business License",
                      "business_license",
                      muni_registration.left_fields.business_license
                    )}
                    {generateDashboardTierThree(
                      "Landlord Registration",
                      "landlord_registration",
                      muni_registration.left_fields.landlord_registration
                    )}
                    {generateDashboardTierThree(
                      "VPR",
                      "vacant_property_registration",
                      muni_registration.left_fields.vacant_property_registration
                    )}
                    {generateDashboardTierThree(
                      "Certificate of Occupancy",
                      "certificate_of_occupancy",
                      muni_registration.left_fields.cerificate_of_occupancy
                    )}
                    {generateDashboardTierThree(
                      "Rental Registration",
                      "rental_registration",
                      muni_registration.left_fields.rental_registration
                    )}
                    {generateDashboardTierThree(
                      "Crime Free Housing",
                      "crime_free_housing",
                      muni_registration.left_fields.crime_free_housing
                    )}
                    {generateDashboardTierThree(
                      "Rental Taxes",
                      "rental_taxes",
                      muni_registration.left_fields.rental_taxes
                    )}
                    {generateDashboardTierThree(
                      "Property Deregistration",
                      "property_deregistration",
                      0
                    )}
                  </>
                )}
              </div>
            )}

          {municipalTierOne === "Municipality Verification" &&
            municipalTierTwo === "County" && (
              <div className="flex-container" style={{ width: "95%" }}>
                {!_.isEmpty(muni_verification.left_fields) && (
                  <>
                    {generateDashboardTierThree(
                      "Reg Required ",
                      "required",
                      muni_verification.left_fields.required
                    )}
                    {generateDashboardTierThree(
                      "Reg Method",
                      "registration_method",
                      muni_verification.left_fields.registration_method
                    )}
                    {generateDashboardTierThree(
                      "Payment Method",
                      "payment_method",
                      muni_verification.left_fields.payment_method
                    )}
                    {generateDashboardTierThree(
                      "Periodicity",
                      "periodicity",
                      muni_verification.left_fields.periodicity
                    )}
                    {generateDashboardTierThree(
                      "Reg Amount",
                      "registration_amount",
                      muni_verification.left_fields.registration_amount
                    )}
                    {generateDashboardTierThree(
                      "Website",
                      "muni_req_website",
                      muni_verification.left_fields.muni_req_website
                    )}
                    {generateDashboardTierThree(
                      "Username",
                      "username",
                      muni_verification.left_fields.username
                    )}
                    {generateDashboardTierThree(
                      "Password",
                      "password",
                      muni_verification.left_fields.password
                    )}
                    {generateDashboardTierThree(
                      "Inspection",
                      "inspection",
                      muni_verification.left_fields.inspection
                    )}
                  </>
                )}
              </div>
            )}
          {municipalTierOne === "Municipality Verification" &&
            municipalTierTwo === "City /Township" && (
              <div className="flex-container" style={{ width: "95%" }}>
                {!_.isEmpty(muni_verification.left_fields) && (
                  <>
                    {generateDashboardTierThree(
                      "Reg Required",
                      "required",
                      muni_verification.left_fields.required
                    )}

                    {generateDashboardTierThree(
                      "Payment Method",
                      "payment_method",
                      muni_verification.left_fields.payment_method
                    )}
                    {generateDashboardTierThree(
                      "Periodicity",
                      "periodicity",
                      muni_verification.left_fields.periodicity
                    )}
                    {generateDashboardTierThree(
                      "Reg Amount",
                      "registration_amount",
                      muni_verification.left_fields.registration_amount
                    )}
                    {generateDashboardTierThree(
                      "Website",
                      "muni_req_website",
                      muni_verification.left_fields.muni_req_website
                    )}
                    {generateDashboardTierThree(
                      "Username",
                      "username",
                      muni_verification.left_fields.username
                    )}
                    {generateDashboardTierThree(
                      "Password",
                      "password",
                      muni_verification.left_fields.password
                    )}
                    {generateDashboardTierThree(
                      "Inspection",
                      "inspection",
                      muni_verification.left_fields.inspection
                    )}
                  </>
                )}
              </div>
            )}
          {municipalTierOne === "Municipality Verification" &&
            municipalTierTwo === "Village / Burrough" && (
              <div className="flex-container" style={{ width: "95%" }}>
                {!_.isEmpty(muni_verification.left_fields) && (
                  <>
                    {generateDashboardTierThree(
                      "Reg Required",
                      "required",
                      muni_verification.left_fields.required
                    )}
                    {generateDashboardTierThree(
                      "Reg Method",
                      "registration_method",
                      muni_verification.left_fields.registration_method
                    )}
                    {generateDashboardTierThree(
                      "Payment Method",
                      "payment_method",
                      muni_verification.left_fields.payment_method
                    )}
                    {generateDashboardTierThree(
                      "Periodicity",
                      "periodicity",
                      muni_verification.left_fields.periodicity
                    )}
                    {generateDashboardTierThree(
                      "Reg Amount",
                      "registration_amount",
                      muni_verification.left_fields.registration_amount
                    )}
                    {generateDashboardTierThree(
                      "Website",
                      "muni_req_website",
                      muni_verification.left_fields.muni_req_website
                    )}
                    {generateDashboardTierThree(
                      "Username",
                      "username",
                      muni_verification.left_fields.username
                    )}
                    {generateDashboardTierThree(
                      "Password",
                      "password",
                      muni_verification.left_fields.password
                    )}
                    {generateDashboardTierThree(
                      "Inspection",
                      "inspection",
                      muni_verification.left_fields.inspection
                    )}
                  </>
                )}
              </div>
            )}
        </>
      )}
    </React.Fragment>
  );
}
