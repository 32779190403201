import React, { useState, useEffect } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import ListTable from "./ListTable";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent";
import config from "../../utils/config";
export default function HoasComponent(props) {
  const [hoaSearchText, sethoaSearchText] = useState("");
  const [hoas, sethoas] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [userRole, setuserRole] = useState("");
  const [totalCount, settotalCount] = useState();
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        setuserRole(role);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getList();
  }, [form_inputs.sort]);

  const getList = (event, pageNo, perPage) => {
    setpageNo(pageNo);
    setisLoading(true);
    apicall.getHoas(form_inputs, pageNo, perPage).then((response) => {
      setisLoading(false);
      sethoas(response.results);
      settotalCount(response.total);
    });
  };

  const handleChange = (event) => {
    this.setState({
      hoaSearchText: event.target.value,
    });
  };

  const handleSubmitForSearch = (event, pageNo, perPage) => {
    setpageNo(typeof pageNo !== "undefined" ? pageNo : "1");
    setperPage(typeof pageNo !== "undefined" ? perPage : "10");
    let inputValue = hoaSearchText;
    if (typeof inputValue === "undefined" || inputValue.length === 0) {
      getList(event, pageNo, perPage);
    } else {
      apicall.searchHoa(inputValue, pageNo, false, perPage).then((response) => {
        setisLoading(false);
        sethoas(response.results);
        settotalCount(response.total);
      });
    }
    if (event) {
      event.preventDefault();
    }
  };

  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <section className="content">
        <header className="content-title">
          <h2 className="page-heading">
            <strong>HOA</strong>
          </h2>
        </header>

        <ListTable
          data={hoas}
          refreshList={handleSubmitForSearch}
          pageNo={pageNo}
          perPage={perPage}
          totalCount={totalCount}
          userRole={userRole}
          hideAdd={!config["hoa_mc_access"].includes(userRole) ? true : false}
          sortColumns={sortColumns}
        />
      </section>
    </React.Fragment>
  );
}
