import React, { useState, useEffect } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import ListTable from "./ListTable";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent";

export default function HoaSearchComponent(props) {
  const [hoas, sethoas] = useState([]);
  const [loading, setloading] = useState(true);
  const [pageNo, setpageNo] = useState(1);
  const [perpage, setperpage] = useState(10);
  const [total, settotal] = useState();
  const searchText = props.match.params.searchText;
  const user_role = props.match.params.user_role;
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    searchHoa();
  }, [form_inputs.sort]);

  const searchHoa = (event, pageNo, perPage) => {
    setloading(true);
    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperpage(perPage);
    }
    apicall
      .searchHoa(searchText, pageNo, false, perPage, form_inputs)
      .then((response) => {
        sethoas(response.results);
        setloading(false);
        settotal(response.total);
      });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <section className="content">
        <header className="content-title">
          <h2 className="page-heading">
            <strong>HOA</strong>
          </h2>
        </header>
        <ListTable
          data={hoas}
          refreshList={searchHoa}
          pageNo={pageNo}
          perPage={perpage}
          totalCount={total}
          hideAdd={true}
          userRole={user_role}
          sortColumns={sortColumns}
          //   hideAdd={
          //     !config["hoa_mc_access"].includes(this.state.userRole)
          //       ? true
          //       : false
          //   }
        />
      </section>
    </React.Fragment>
  );
}
