import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
var titleDashboardapi = {
  getTitleDashboardStats: async function (
    data,
    tierOne,
    tierTwo,
    tierThree,
    manager_user,
    page,
    per_page
  ) {
    let url = `${config.baseUrl}/api/v1/dashboard/title_dashboard?`;
    let params = {};

    if (tierOne) params["tier1"] = tierOne;
    if (tierTwo) params["tier2"] = tierTwo;
    if (tierThree) params["tier3"] = tierThree;
    if (manager_user)
      params["manager_user"] = manager_user === "user" ? "true" : "false";
    if (page) params["page"] = page;
    if (per_page) params["per_page"] = per_page;
    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return "dashboard[" + k + "]=" + encodeURIComponent(params[k]);
      })
      .join("&");

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },
};

export default withRouter(titleDashboardapi);
