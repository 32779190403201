import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Input,
} from "reactstrap";
import "../../styles/_deedVerification.scss";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import TitleDocumentsModal from "../documents/TitleDocumentsModal";
import helpers from "../../utils/helpers";
import LoaderComponent from "../helper_components/LoaderComponent";
import { Linking } from "react-native";
import DatePicker from "react-datepicker";
import DocumentsTab from "../documents/DocumentsTab";
import _ from "lodash";
import PaymentModal from "../payments/paymentModal";
import AsyncCreatable from "../helper_components/AsyncCreatable";
import Select from "react-select";
import NotesHistory from "../CodeViolation/NotesHistory";

export default function DeedVerificationModal(props) {
  const [row, setrow] = useState({});
  const [corrective_document, setcorrective_document] = useState([
    { value: "New Deed", label: "New Deed" },
    { value: "Corrective", label: "Corrective" },
    { value: "Affidavit", label: "Affidavit" },
    { value: "Nothing is Needed", label: "Nothing is Needed" },
  ]);
  const [method_of_resolution, setmethod_of_resolution] = useState([
    { value: "Foreclosing Attorney", label: "Foreclosing Attorney" },
    { value: "ResiHOME", label: "ResiHOME" },
  ]);
  const [responsible_recording, setresponsible_recording] = useState([
    { value: " Municipality", label: " Municipality" },
    { value: "Lawyer", label: "Lawyer" },
    { value: "Resicap", label: "Resicap" },
    { value: "Handled at Closing", label: "Handled at Closing" },
  ]);
  const [openAddDocumentModal, setopenAddDocumentModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [lawFirmPoc, setlawFirmPoc] = useState([]);
  const [openPaymentModal, setopenpaymentModal] = useState(false);

  useEffect(() => {
    readDeedVerification();
  }, []);
  const readDeedVerification = () => {
    setisLoading(true);
    apicall
      .readDeedVerification(props.actionID, props.propertyID)
      .then((response) => {
        setrow(response);
        setisLoading(false);
      });
  };

  let lawFirmPOCOptions = [];
  if (!_.isEmpty(lawFirmPoc))
    lawFirmPOCOptions = lawFirmPoc.poc.map((c) => {
      return { label: c, value: c };
    });

  const handleSelection = (event, id) => {
    setrow(Object.assign({ ...row }, { [id]: event.value }));
  };
  const handleNotesChange = (event) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: event.target.value }));
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const toggleDocument = () => {
    setopenAddDocumentModal(!openAddDocumentModal);
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const deleteDeedVerificationDocument = (documentId) => {
    setisLoading(true);
    apicall
      .deleteDeedVerificationDocument(
        props.propertyID,
        props.actionID,
        documentId
      )
      .then((response) => {
        helpers.notify("Document deleted successfully");
        readDeedVerification();
      });
  };

  const handleEmailSubmit = (key) => {
    const target = "_blank";
    if (key === "multiple_lots_parcels") {
      const body = "Multiple Lots and Parcels";
      const href = `https://mail.google.com/mail/?view=cm&fs=1&su=&body=${body}&to=&tf=1`;
      Linking.canOpenURL(href, target).then((supported) => {
        if (!supported) {
          console.log("Can't handle url: " + href, target);
        } else {
          if (window) {
            return window.open(href, target);
          } else {
            return Linking.openURL(href, target);
          }
        }
      });
    } else if (key === "client_approval_needed") {
      const body = "Client approval needed";
      const href = `https://mail.google.com/mail/?view=cm&fs=1&su=&body=${body}&to=&tf=1`;
      Linking.canOpenURL(href, target).then((supported) => {
        if (!supported) {
          console.log("Can't handle url: " + href, target);
        } else {
          if (window) {
            return window.open(href, target);
          } else {
            return Linking.openURL(href, target);
          }
        }
      });
    }
  };

  const handleDateChange = (value, key) => {
    let date = helpers.formatApiDate(value);

    setrow(Object.assign({ ...row }, { [key]: date }));
  };

  const handleSubmit = () => {
    apicall
      .updateDeedVerification(props.actionID, props.propertyID, row)
      .then((response) => {
        if (response) {
          helpers.notify("Updated successfully");
          setrow(response);
          setisLoading(false);
          // if (openAddDocumentModal) {
          //   toggleDocument();
          // } else {
          props.toggle();
          props.refershActions(props.propertyID);
          // }
          // props.fetchDocuments();
        }
      });
  };

  const handleSelectChange = (id, valueObj) => {
    if (!_.has(valueObj, "__isNew__") && !_.isEmpty(valueObj)) {
      let x;
      let pocs;
      x = {
        law_firm_name: valueObj.label,
        law_firm_id: valueObj.data["value"],
        poc: valueObj.data.code_violation_pocs.map((x) => {
          return x.name;
        }),
      };
      pocs = {
        poc: valueObj.data.code_violation_pocs.map((x) => {
          return x.name;
        }),
      };
      setlawFirmPoc(pocs);

      setrow(Object.assign({ ...row }, { ...x }));
    }
  };

  const renderRadioInput = (name) => {
    return (
      <div className="radio-button">
        <CustomInput
          id={`${name}yes`}
          type="radio"
          name={name}
          value={true}
          onClick={(e) => handleRadioButton(e, true)}
          checked={row[name] === true ? "checked" : ""}
        />
        <label>Yes</label>
        <CustomInput
          id={`${name}no`}
          type="radio"
          name={name}
          value={false}
          onClick={(e) => handleRadioButton(e, false)}
          checked={row[name] === false ? "checked" : ""}
        />
        <label>No</label>
      </div>
    );
  };

  const formElements = () => {
    let x;
    if (!_.isEmpty(row.law_firm)) {
      x = {
        label: row.law_firm.name,
        value: row.law_firm.id,
      };
    }
    let vesting_name = !_.isEmpty(props.propertyInfo.property_group)
      ? props.propertyInfo.property_group
      : "";
    return (
      <>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <FormGroup row>
              <Label for="" sm={3}>
                Deed upload
              </Label>

              <Col sm={3}>
                <Button
                  className="upload_deed_document"
                  onClick={() => {
                    setopenAddDocumentModal(true);
                  }}
                >
                  File Upload
                </Button>
              </Col>
            </FormGroup>

            <hr />
            {!_.isEmpty(row.title_documents) && (
              <>
                <FormGroup row>
                  <Label for="" sm={3}>
                    {"Are multiple lots and/or parcels listed?"}
                  </Label>
                  <Col sm={3} className="deed-radio-col">
                    {renderRadioInput("is_multiple_lots_parcels")}
                  </Col>

                  {row.is_multiple_lots_parcels === true && (
                    <Col sm={3} style ={{ position: "relative",left: "-70px"}}>
                      <Button
                        type="button"
                        id="submit"
                        onClick={() =>
                          handleEmailSubmit("multiple_lots_parcels")
                        }
                        style={{ marginBottom: "0px", marginTop: "0px" }}
                      >
                        Send Email
                      </Button>
                    </Col>
                  )}
                </FormGroup>
                <FormGroup row>
                  <Label for="" sm={3}>
                    {`Is ${vesting_name} the vesting name on the Deed?`}
                  </Label>
                  <Col sm={3} className="deed-radio-col">
                    {renderRadioInput("vesting_deed_correct")}
                  </Col>

                  {row.vesting_deed_correct === false &&
                    props.propertyInfo.acquisition_type === "MLS" && (
                      <Col sm={3} style ={{ position: "relative",left: "-70px"}}>
                        <Button
                          type="button"
                          id="submit"
                          onClick={() =>
                            handleEmailSubmit("multiple_lots_parcels")
                          }
                          style={{ marginBottom: "0px", marginTop: "0px" }}
                        >
                          Send Email
                        </Button>
                      </Col>
                    )}
                </FormGroup>
              </>
            )}

            {row.vesting_deed_correct === false &&
              props.propertyInfo.acquisition_type === "Foreclosure" && (
                <>
                  <FormGroup row>
                    <Label for="" sm={3}>
                      Method of Resolution
                    </Label>{" "}
                    <Col sm={2}>
                      <Select
                        options={method_of_resolution}
                        placeholder="Search Type"
                        name="method_of_resolution"
                        id="method_of_resolution"
                        onChange={(event) =>
                          handleSelection(event, "method_of_resolution")
                        }
                        value={{
                          value: row.method_of_resolution,
                          label: row.method_of_resolution,
                        }}
                      />
                    </Col>
                    {row.method_of_resolution === "Foreclosing Attorney" && (
                      <>
                        <Col sm={3}>
                          <AsyncCreatable
                            callapi={apicall.listLawFirm}
                            handleOnChange={(id, value) => {
                              handleSelectChange(id, value);
                            }}
                            valueShown={x}
                            placeholder="Search"
                            isClearable={true}
                            propertyID={props.propertyID}
                            isDeedVerification={true}
                          />
                        </Col>
                        <Col sm={2}>
                          <Select
                            options={lawFirmPOCOptions}
                            placeholder="Search Type"
                            name="law_firm_poc_name"
                            id="law_firm_poc_name"
                            onChange={(event) =>
                              handleSelection(event, "law_firm_poc_name")
                            }
                            value={{
                              value: row.law_firm_poc_name,
                              label: row.law_firm_poc_name,
                            }}
                          />
                        </Col>
                        <Col sm={2}>
                          <Button
                            type="button"
                            id="submit"
                            onClick={() =>
                              handleEmailSubmit("multiple_lots_parcels")
                            }
                            style={{
                              marginBottom: "0px",
                              marginTop: "0px",
                            }}
                          >
                            Send Email
                          </Button>
                        </Col>
                      </>
                    )}
                    {row.method_of_resolution === "ResiHOME" && (
                      <>
                        <Label sm={1}></Label>
                        <Col sm={3}>
                          <Button
                            type="button"
                            id="submit"
                            onClick={() =>
                              handleEmailSubmit("multiple_lots_parcels")
                            }
                            style={{
                              marginBottom: "0px",
                              marginTop: "0px",
                            }}
                          >
                            Send Email
                          </Button>
                        </Col>
                      </>
                    )}
                  </FormGroup>

                  {row.method_of_resolution === "Foreclosing Attorney" && (
                    <>
                      {" "}
                      <FormGroup row>
                        <Label for="" sm={3}>
                          Type of Corrective Document Required?
                        </Label>{" "}
                        <Col sm={2}>
                          <Select
                            options={corrective_document}
                            placeholder="Search Type"
                            onChange={(event) =>
                              handleSelection(event, "corrective_type")
                            }
                            value={{
                              value: row.corrective_type,
                              label: row.corrective_type,
                            }}
                            //isDisabled={true}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="" sm={3}>
                          Corrective Document Received?
                        </Label>{" "}
                        <Col sm={3} className="deed-radio-col">
                          {renderRadioInput("received_corrective_document")}
                        </Col>
                      </FormGroup>
                    </>
                  )}

                  {row.method_of_resolution === "ResiHOME" && (
                    <>
                      <FormGroup row>
                        <Label for="" sm={3}>
                          Draft Requested
                        </Label>{" "}
                        <Col sm={2}>
                          <div className="code-violation-date">
                            <DatePicker
                              id="draft_requested"
                              selected={
                                row.draft_requested
                                  ? helpers.restructureDate(row.draft_requested)
                                  : null
                              }
                              onChange={(value) =>
                                handleDateChange(value, "draft_requested")
                              }
                              value={
                                row.draft_requested
                                  ? helpers.restructureDate(row.draft_requested)
                                  : null
                              }
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="" sm={3}>
                          Draft Received
                        </Label>{" "}
                        <Col sm={2}>
                          <div className="code-violation-date">
                            <DatePicker
                              id="draft_received"
                              selected={
                                row.draft_received
                                  ? helpers.restructureDate(row.draft_received)
                                  : null
                              }
                              onChange={(value) =>
                                handleDateChange(value, "draft_received")
                              }
                              value={
                                row.draft_received
                                  ? helpers.restructureDate(row.draft_received)
                                  : null
                              }
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="" sm={3}>
                          {"Approved"}
                        </Label>
                        <Col sm={3} className="deed-radio-col">
                          {renderRadioInput("approved")}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="" sm={3}>
                          {"Document Signed?"}
                        </Label>
                        <Col sm={3} className="deed-radio-col">
                          {renderRadioInput("signed_document")}
                        </Col>
                        {row.signed_document === false && (
                          <Col sm={2}>
                            <Button
                              type="button"
                              id="submit"
                              onClick={() =>
                                handleEmailSubmit("multiple_lots_parcels")
                              }
                              style={{
                                marginBottom: "0px",
                                marginTop: "0px",
                              }}
                            >
                              Send Email
                            </Button>
                          </Col>
                        )}
                      </FormGroup>
                    </>
                  )}
                </>
              )}

            {row.vesting_deed_correct === false &&
              props.propertyInfo.acquisition_type === "MLS" && (
                <FormGroup row>
                  <Label for="" sm={3}>
                    {"Does the closing attorney agree?"}
                  </Label>
                  <Col sm={3} className="deed-radio-col">
                    {renderRadioInput("closing_attorney_agree")}
                  </Col>
                </FormGroup>
              )}
            {row.vesting_deed_correct === false && (
              <>
                {((props.propertyInfo.acquisition_type === "MLS" &&
                  row.closing_attorney_agree === true) ||
                  (props.propertyInfo.acquisition_type === "Foreclosure" &&
                    row.received_corrective_document !== true)) && (
                  <FormGroup row>
                    <Label for="" sm={3}>
                      Responsible for Recording?
                    </Label>{" "}
                    <Col sm={2}>
                      <Select
                        options={responsible_recording}
                        placeholder="Search Type"
                        name="responsible_for_recording"
                        id="responsible_for_recording"
                        onChange={(event) =>
                          handleSelection(event, "responsible_for_recording")
                        }
                        value={{
                          value: row.responsible_for_recording,
                          label: row.responsible_for_recording,
                        }}
                      />
                    </Col>
                    {row.responsible_for_recording === "Resicap" &&
                      _.includes(row, row.payment) && (
                        <Col sm={3} className="municipal-website">
                          <div
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() => {
                              setopenpaymentModal(!openPaymentModal);
                            }}
                            className="municipal-violation-button"
                          >
                            General Payment
                          </div>
                        </Col>
                      )}
                  </FormGroup>
                )}
                <FormGroup row>
                  <Label for="" sm={3}>
                    Date Uploaded in HBAM
                  </Label>{" "}
                  <Col sm={2}>
                    <div className="code-violation-date">
                      <DatePicker
                        id="hbam_upload_date"
                        selected={
                          row.hbam_upload_date
                            ? helpers.restructureDate(row.hbam_upload_date)
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange(value, "hbam_upload_date")
                        }
                        value={
                          row.hbam_upload_date
                            ? helpers.restructureDate(row.hbam_upload_date)
                            : null
                        }
                      />
                    </div>
                  </Col>
                </FormGroup>
              </>
            )}
            <FormGroup row>
                <Label for="" sm={3}>Notes</Label>
                <Col sm={8}>
                  <Input
                    type="textarea"
                    name="deed_verification_notes"
                    id="deed_verification_notes"
                    placeholder="Notes"
                    onChange={handleNotesChange}
                  // value={row.code_violation_notes}
                  />
                </Col>
              </FormGroup>
            <div className="status">
              <Button
                className={""}
                onClick={handleSubmit}
                style={{ marginTop: "0px", marginBottom: "0px" }}
              >
                Submit
              </Button>
            </div>
            <hr />
            <FormGroup row>
              <Label for="" sm={3}></Label>
              <Col sm={8}>
                {row.title_documents && (
                  <DocumentsTab
                    propertyID={props.propertyID}
                    documents={row.title_documents}
                    isDeedVerification={true}
                    deleteDocument={deleteDeedVerificationDocument}
                    theme={"general-info-tab"}
                    fetchDocuments={readDeedVerification}
                  />
                )}
              </Col>
            </FormGroup>
            <NotesHistory notes={row.notes ? row.notes : []} noteStyle= "true" />
          </>
        )}
      </>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Deed Verification</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="deedVerification-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
      {openAddDocumentModal && (
        <TitleDocumentsModal
          toggle={toggleDocument}
          title="Document"
          open={openAddDocumentModal}
          propertyID={props.propertyID}
          isDeedVerification={true}
          actionID={props.actionID}
          fetchDocuments={readDeedVerification}
          row={row}
        />
      )}
      {openPaymentModal && (
        <PaymentModal
          toggle={() => {
            setopenpaymentModal(!openPaymentModal);
          }}
          title="Payment"
          open={true}
          row={row}
          propertyID={row.property_id}
          actionID={row.payment.id}
        />
      )}
    </div>
  );
}
