import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
//import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";
import config from "../../../utils/config";
import PaginationComponent from "../../helper_components/PaginationComponent";
export default class PropertySearchTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openQuestionsModal: false,
      modalAnalystsWindow: new Array(20).fill(false),
      rowIndex: 0,
      row: {},
      aerialSearchid: "",
      userId: null,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentDidMount() {
    config.localforage.getItem("userid").then((resp) => {
      this.setState({
        userId: resp,
      });
    });
  }
  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  toggleQuestionsModal = () => {
    this.setState({
      openQuestionsModal: !this.state.openQuestionsModal,
    });
  };

  linkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return this.props.guestSearch ? (
        <Link to={"/guest-property-manage/" + row.id} key={cell + row.id}>
          {cell}
        </Link>
      ) : (
        <Link to={"/property-manage/3/" + row.id + "/0"} key={cell + row.id}>
          {cell}
        </Link>
      );
      // <Link to={"/property-manage/2/" + row.id + "/0"} key={cell + row.id}>
      //   {cell}
      // </Link>
    }
    return null;
  };
  render() {
    let properties = this.state.properties;
    const columns = [
      {
        dataField: "clientid",
        text: "Client ID",
        formatter: this.linkFormatter,
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "1%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "street_address",
        text: "Street Address",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "3%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      // {
      //   dataField: "address2",
      //   text: "Address 2",
      //   headerClasses: "table-header-cell-alignment",
      //   headerStyle: (colum, colIndex) => {
      //     return { width: "3%" };
      //   },
      // },
      {
        dataField: "city",
        text: "City",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "1%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "state",
        text: "State",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "0.5%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "zip",
        text: "Zip",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "0.8%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },

      {
        dataField: "county",
        text: "County",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "1%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "hbpm_status",
        text: "HBPM Status",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "2%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
    ];

    return (
      <>
        <PaginationComponent
          columns={columns}
          rows={properties}
          handleSubmit={this.props.handleSubmit}
          pageNo={this.props.pageNo}
          perPage={this.props.page}
          totalCount={this.props.totalCount}
          guestSearch={this.props.guestSearch}
          propertySearch={this.props.propertySearch}
        />
      </>
    );
  }
}
