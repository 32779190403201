import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
var amenitiesapi = {
  getAmenities: async function (hoaID) {
    let url = config.baseUrl + "/api/v1/hoa_amenities?hoa_id=" + hoaID;
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong, while getAmenities" + response);
      })
      .catch(function (error) {});
  },
  updateAmenities: async function (hoaID, amenities, id) {
    console.log("the data is", amenities);
    let url = config.baseUrl + `/api/v1/hoa_amenities/${id}?hoa_id=` + hoaID;
    var form = new FormData();
    for (var key in amenities) {
      if (amenities[key] === null) {
        amenities[key] = "";
      }
      if (_.has(config.amenitiesMap, key))
        form.append("hoa_amenity[" + key + "]", amenities[key]);
    }

    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    return await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .catch(function (error) {});
  },
  deleteAmentiesDoc: async function (hoaID, amentiesId, documentId) {
    //console.log(propertyID + " " + amentiesId + " " + documentId);

    let url = `${config.baseUrl}/api/v1/hoa_amenities/${amentiesId}/delete_documents?hoa_id=${hoaID}&document_id=${documentId}`;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((res) => res.json);
  },
};
export default withRouter(amenitiesapi);
