import React, { useState, useEffect } from "react";
import config from "../../utils/config";
import apicall from "../../utils/apicall";
//import _ from 'lodash'
import DatePicker from "react-datepicker";
import { FormGroup, Label, Button } from "reactstrap";
import Loader from "../helper_components/LoaderComponent";
export default (props) => {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [isloading, setisloading] = useState(false);
  let reportsMap = config.reportsMap;
  let reportsDiv = Object.keys(reportsMap).map((reportKey, index) => {
    return (
      <li>
        <a
          id={reportKey + index}
          href="#"
          onClick={(e) => {
            setisloading(true);
            apicall
              .downloadReports(reportKey, start_date, end_date)
              .then((resp) => {
                console.log("resp==>", resp);
                setisloading(false);
              });

            e.preventDefault();
          }}
        >
          {reportsMap[reportKey]}
        </a>
      </li>
    );
  });
  console.log(start_date);

  return (
    <section className="content">
      <h1>Reports</h1>
      <FormGroup>
        <Label for="" style={{ marginRight: "20px" }}>
          Start Date
        </Label>
        <DatePicker
          id="start_date"
          selected={start_date ? new Date(start_date) : null}
          onChange={(date) => {
            setStartDate(date);
          }}
          //   dateFormat={config.US_DATE_FORMAT}
        />
      </FormGroup>
      <FormGroup>
        <Label for="" style={{ marginRight: "25px" }}>
          End Date
        </Label>
        <DatePicker
          id="end_date"
          selected={end_date ? new Date(end_date) : null}
          onChange={(date) => {
            setEndDate(date);
          }}
          //   dateFormat={config.US_DATE_FORMAT}
        />
        <Button
          style={{
            marginLeft: "3rem",
            marginTop: "0px",
            marginBottom: "0px",
          }}
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
          }}
        >
          Clear
        </Button>
      </FormGroup>
      {/* <Button
        style={{ position: "absolute", top: "25%", left: "45%" }}
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
        }}
      >
        Clear
      </Button> */}

      {/* {isloading ? (
        <Loader />
      ) : (
        <> */}
      {/* <ul>{reportsDiv}</ul> */}
      <div style={{ display: "flex" }}>
        {" "}
        <ul style={{ width: "50%" }}>{reportsDiv}</ul>
        {isloading && <Loader />}
      </div>
      {/* </>
      )} */}
    </section>
  );
};
