import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
//import _ from "lodash";
import Axios from "axios";

var associationVerificationapi = {
  getAssociationVerification: async function (
    id,
    association_verification_info_id
  ) {
    let url =
      config.baseUrl +
      `/api/v1/association_verification_infos/${association_verification_info_id}?property_id=${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateAssociationVerification: async function (
    formData,
    association_verification_info_id,
    pid
  ) {
    let url =
      config.baseUrl +
      `/api/v1/association_verification_infos/${association_verification_info_id}?property_id=${pid}`;
    let headers;
    let response;
    delete formData.created_at;
    delete formData.updated_at;
    delete formData.id;
    let form = new FormData();
    for (var key in formData) {
      if (!Array.isArray(formData[key])) {
        if (formData[key] !== null && typeof formData[key] !== "undefined")
          form.append(
            "association_verification_info[" + key + "]",
            formData[key]
          );
      }
    }
    headers = await helpers.defaultHeaders();
    await Axios.put(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  listAssociationName: async function (searchKeyword, id) {
    let url =
      config.baseUrl +
      `/api/v1/hoas/state_hoas_search?property_id=${id}&q=${searchKeyword}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteDocument: async function (
    association_verification_info_id,
    property_id,
    document_id
  ) {
    let url =
      config.baseUrl +
      `/api/v1/association_verification_infos/${association_verification_info_id}/delete_documents?property_id=${property_id}&document_id=${document_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(associationVerificationapi);
