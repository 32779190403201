import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

export default (props) => {
        return (
            <div>
                <Modal isOpen={true} toggle={props.onNoClick}>
	                <ModalHeader>
	                    <div className="">
							<span className="heading" style={ props.vendorFileDelete ? { position: "relative", left: "140px" } : null}>
	                            {props.heading}
	                        </span>
	                    </div>
	                </ModalHeader>
	                <ModalBody>
	                    {props.children}
						<div
						style = {{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-evenly",
							alignItems: "center",
							width: "30%",
							position: "relative",
							left: "155px",
						}}
						>
						<Button onClick={props.onYesClick}>Yes</Button>
	                    <Button onClick={props.onNoClick}>No</Button>
						</div>
	                </ModalBody>
	            </Modal>
            </div>
        );
}
