import React, { useState, useEffect } from "react";
import "../../styles/content.css";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import PropertySearchTable from "../properties/property-search/PropertySearchTable";
import { toast } from "react-toastify";
import _ from "lodash";
import config from "../../utils/config";
import Loader from "../helper_components/LoaderComponent";

export default function CaseNumberSearchComponent(props) {
  const [case_number_results, setcase_number_results] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const searchText = props.match.params.searchText;
  const user_role = props.match.params.user_role;
  const [loading, setloading] = useState(true);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    searchCaseNumber();
  }, [form_inputs.sort]);

  const notify = (message) => toast(message);

  const searchCaseNumber = (event, pageNo, perPage) => {
    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperPage(perPage);
    }
    setloading(true);
    apicall
      .searchCaseNumber(searchText, form_inputs, pageNo, perPage)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("error")) {
          let errorMsg = helpers.getErrorMsg(response.error);
          if (errorMsg) notify("Search Failed: No Records Found: " + errorMsg);
        } else {
          if (typeof response !== "undefined" && response.length === 0) {
            notify("No Records Found: ");
          } else {
            if (_.isString) {
              config.localforage.setItem("searchResult", response);
            }
            setcase_number_results(response.results);
            settotalCount(response.total);
            setloading(false);
          }
        }
      });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };
  return (
    <React.Fragment>
      <section className="content">
        {props.guestSearch ? (
          <></>
        ) : (
          <>
            {loading && <Loader />}
            <header className="content-title">
              <h2 className="page-heading">
                <strong>Property Search</strong>
              </h2>
            </header>
          </>
        )}
        <div className="search-result">
          <PropertySearchTable
            properties={case_number_results}
            handleSubmit={searchCaseNumber}
            pageNo={pageNo}
            page={perPage}
            totalCount={totalCount}
            propertySearch={true}
            sortColumns={sortColumns}
          />
        </div>
      </section>
    </React.Fragment>
  );
}
