import React, { useState, useEffect } from "react";
import ListTable from "./ListTable";
import apicall from "../../utils/apicall";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import LoaderComponent from "../helper_components/LoaderComponent";
import config from "../../utils/config";

export default function ManagementCompanySearch(props) {
  const [managementCompanies, setmanagementCompanies] = useState([]);
  const [loading, setloading] = useState(true);
  const [pageNo, setpageNo] = useState(1);
  const [perpage, setperpage] = useState(10);
  const [total, settotal] = useState();
  const searchText = props.match.params.searchText;
  const user_role = props.match.params.user_role;
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    searchManagementCompanies();
  }, [form_inputs.sort]);

  const searchManagementCompanies = (event, pageNo, perPage) => {
    console.log("submit", perPage);

    setloading(true);
    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperpage(perPage);
    }

    apicall
      .searchManagementCompanies(form_inputs, searchText, null, pageNo, perPage)
      .then((response) => {
        setloading(false);
        setmanagementCompanies(response.results);
        settotal(response.total);
        setpageNo(pageNo);
      });
  };

  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {loading && <LoaderComponent />}
      <section className="content">
        <header className="content-title ">
          {/* <div className='page-title'>Management Companies</div> */}
          <h2 className="page-heading">
            <strong>Management Companies</strong>
          </h2>
        </header>
        <ListTable
          managementCompanies={managementCompanies}
          refreshList={searchManagementCompanies}
          pageNo={pageNo}
          perPage={perpage}
          totalCount={total}
          userRole={user_role}
          sortColumns={sortColumns}
        />
      </section>
    </React.Fragment>
  );
}
