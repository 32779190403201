import React, { Component } from "react";
import "../../styles/_general.scss";
import AccessCodes from "./access_codes/AccessCodes";
import DisplayHOA from "./hoa_info/DisplayHOA";
import FinanceLeaseInfo from "./lease_info/FinanceLeaseInfo";
import Amenities from "./Amenities";
import TabsComponent from "../helper_components/TabsComponent";
import Inspections from "./inspections/Inspections";
import TenantMoveInCheckList from "./tenant/TenantMoveInCheckList";
import AttorneysComponent from "../attorneys/AttorneysComponent";
import config from "../../utils/config";
import DisplayMunicipal from "../Municipal/DisplayMunicipal";
import UnderwritingUtilitiesAction from "./UnderwritingUtilitiesAction"
export default class GeneralComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property: null,
      propertyid: props.propertyID,
      activeTab: 1,
    };
  }
  updateActiveTab = (value) => {
    this.setState({ activeTab: value });
  };
  render() {
    let listofTabs = "";
    if (config["LeaseHide"].includes(this.props.userRole)) {
      listofTabs = [
        "Association",
        "Municipality",
        //"Utilities"
        // "Access Codes",
        // "Inspections",
        // "Amenities",
        // "Tenant Move-in Check",
        // "Attorneys",
      ];
    } else {
      listofTabs = [
        "Association",
        "Municipality",
        //"Utilities"
        // "Access Codes",
        // "Inspections",
        // "Amenities",
        // "Tenant Move-in Check",
        // "Attorneys",
        // "Leasing Information",
      ];
    }
    let tabsComponentsList = new Array(listofTabs.length).fill(null);
    tabsComponentsList[0] = (
      <DisplayHOA
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
        getActions={this.props.getActions}
        updateMasterHoa={this.props.updateMasterHoa}
      />
    );
    tabsComponentsList[1] = (
      <DisplayMunicipal
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
        getActions={this.props.getActions}
        // updateMasterHoa={this.props.updateMasterHoa}
      />
    );
    tabsComponentsList[2] = (
      <UnderwritingUtilitiesAction
        propertyid={this.state.propertyid}
        // userRole={this.props.userRole}
        // getActions={this.props.getActions}
        // updateMasterHoa={this.props.updateMasterHoa}
      />
    );
    tabsComponentsList[3] = (
      <AccessCodes
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
        getPropertyInfo={this.props.getPropertyInfo}
      />
    );
    tabsComponentsList[4] = (
      <Inspections
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
      />
    );
    tabsComponentsList[5] = (
      <Amenities
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
      />
    );
    tabsComponentsList[6] = (
      <TenantMoveInCheckList
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
      />
    );
    tabsComponentsList[7] = (
      <AttorneysComponent
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
      />
    );
    tabsComponentsList[8] = (
      <FinanceLeaseInfo
        propertyid={this.state.propertyid}
        userRole={this.props.userRole}
      />
    );
    return (
      <React.Fragment>
        <div className="general-component">
          <TabsComponent
            //theme="tab"
            components={tabsComponentsList}
            listofTabs={listofTabs}
            generateTabURL={false}
            activeTab={this.state.activeTab}
            theme={"general-info-tab"}
            updateActiveTab={this.updateActiveTab}
            isVendor={true}
          />
        </div>
      </React.Fragment>
    );
  }
}

