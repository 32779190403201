import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var vendorapi = {
  clientVendorOrders: async function (pageNo, perPage) {
    let url = `${config.baseUrl}/api/v1/client_vendor_orders/title_orders`;
    if (pageNo) {
      url = url + "?page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  titleOrdersSearch: async function (id) {
    let url = `${config.baseUrl}/api/v1/client_vendor_orders/title_orders_search?q=${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  uploadDocuments: async function (data, id, key) {
    let url = `${config.baseUrl}/api/v1/client_vendor_orders/title_order_update?title_order_id=${id}`;
    let headers;
    let response;
    headers = await helpers.titleFileHeaders();
    var form = new FormData();
    if (key === "notes") form.append("title_order[notes]", data);
    else {
      for (var key in data) {
        form.append("title_order[" + key + "]", data[key]);
      }
    }

    await Axios.put(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(vendorapi);
