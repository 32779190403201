import React, { useState } from "react";
import apicall from "../../utils/apicall";
import { FaTrashAlt, FaCheckSquare, FaLink } from "react-icons/fa";
import "../../styles/_access-codes.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import dayjs from "dayjs";
import _ from "lodash";
import config from "../../utils/config";
import "../../styles/_payments.scss";
import "../../styles/_document-tab.scss";
import helpers from "../../utils/helpers";
import Loader from "../helper_components/LoaderComponent";
export default (props) => {
  const [isloading, setisloading] = useState(false);
  const handleChange = (event, row) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    var requestObject = {
      "documents_attributes[][applicable]": !value,
      "documents_attributes[][id]": row.id,
    };
    setisloading(true);
    apicall
      .addDocuments(
        props.isHOAAssociationModal ? props.hoaID : row.documentable_id,
        requestObject
      )
      .then((response) => {
        if (response) {
          if (props.isHOAAssociationModal) props.fetchDocuments(props.hoaID);
          else props.fetchDocuments();
        }
        setisloading(false);
      });
  };
  const applicableFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center", width: "5rem" }}>
        <input
          type="checkbox"
          name={row.name}
          id={row.id}
          value={row.applicable}
          onChange={(event) => handleChange(event, row)}
          checked={row.applicable === false ? true : false}
          style={{
            width: "20%",
            marginLeft: "15px",
          }}
          disabled={!_.isEmpty(row.file_url) ? true : false}
        />
      </div>
    );
  };

  const guestUserApplicableFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center", width: "5rem" }}>
        <input
          type="checkbox"
          name={row.name}
          id={row.id}
          value={row.applicable}
          // onChange={(event) => handleChange(event, row)}
          checked={row.applicable === false ? true : false}
          style={{
            width: "20%",
            marginLeft: "15px",
          }}
          disabled={true}
        />
      </div>
    );
  };

  const deleteDocument = (row) => {
    if (
      props.isPayment === true ||
      props.isassociationVerification === true ||
      props.isHoa === true ||
      props.isHOAAssociationModal
    ) {
      props.deleteDocument(row.id);
    } else if (props.isLienUpload) {
      props.deleteDocument(row.id);
    } else if (props.isassociationVerification === true) {
      props.deleteDocument(row.id);
    } else if (props.isAmenity) {
      props.deleteDocument(row.id, props.hoaId, props.amenitiesId);
    } else if (props.isLeaseInfo) {
      props.deleteDocument(row.id, props.hoaId, props.leaseInfoId);
    } else if (props.isAssociationModal) {
      props.deleteDocument(row.hoa_id, row.id);
    } else if (props.iscodeViolation) {
      props.deleteDocument(row.id);
    } else if (props.isMunicipalityReview) {
      props.deleteDocument(row.documentable_id, row.id);
    } else if (props.isDeedVerification) {
      props.deleteDocument(row.id);
    } else if (props.isMuniRequirementDocument) {
      props.deleteDocument(row.documentable_id, row.id);
    } else if(props.isMunicipalRequirementAction) {
      props.deleteDocument(row.id);
    } else {
      apicall
        .deletePropertyDocument(props.propertyID, row.id, row.section)
        .then((resp) => {
          helpers.notify("Document deleted successfully");
        });
      setTimeout(() => props.fetchDocuments(), 3000);
    }
  };

  const associationModalTrashFormatter = (cell, row) => {
    return (
      <>
        {!_.isEmpty(row.file_url) ? (
          <div style={{ textAlign: "center", cursor: "pointer" }}>
            <FaTrashAlt size="1.4em" onClick={() => deleteDocument(row)} />
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const trashFormatter = (cell, row) => {
    return (
      <>
        {!_.isEmpty(row.file_url) ? (
          <div style={{ textAlign: "center", cursor: "pointer" }}>
            <FaTrashAlt size="1.4em" onClick={() => deleteDocument(row)} />
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  }
  const renderFileURL = (cell, row) => {
    return (
      <a
        href={!_.isEmpty(row.file_url) ? row.file_url : null}
        style={{ float: "none", color: "black" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.paymentDocs ||
        props.isAssociationModal ||
        props.isPropertyModal ||
        props.isassociationVerification
          ? row.name
          : // <FaLink size="1.5em" />
            cell}
      </a>
    );
  };
  const renderTimestamp = (cell, row) => {
    if (_.isEmpty(cell)) return "";
    else if (!_.isEmpty(row.file_url))
      return dayjs(cell).format(config.US_DATE_FORMAT);
  };

  const renderName = (cell, row) => {
    return (
      <>
        {!_.isEmpty(row.file_url) ? (
          <a
            href={!_.isEmpty(row.file_url) ? row.file_url : null}
            style={{ float: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.paymentDocs ||
            props.isAssociationModal ||
            props.isPropertyModal ||
            props.isassociationVerification
              ? !_.isEmpty(row.name)
                ? row.name
                : "Click to Download Doc"
              : // <FaLink size="1.5em" />
              !_.isEmpty(cell)
              ? cell
              : "Click to Download Doc"}
          </a>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderPropertyName = (cell, row) => {
    let name;
    if (row.category === "Deed" && row.section === "title")
      name = !_.isEmpty(row.doc_type) ? row.doc_type : "Click to Download Doc";
    else if (row.section === "title") {
      let case_number = !_.isEmpty(row.case_number) ? row.case_number : "";
      let type = !_.isEmpty(row.doc_type) ? row.doc_type : "";
      name = case_number + " - " + type;
    } else name = !_.isEmpty(row.name) ? row.name : "Click to Download Doc";
    return (
      <>
        {!_.isEmpty(row.file_url) ? (
          <a
            href={!_.isEmpty(row.file_url) ? row.file_url : null}
            style={{ float: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderNotes = (cell, row) => {
    if (row.category !== "Deed" && row.section === "title") {
      return row.notification_sequence + " - " + row.classification;
    } else return cell;
    // else if (row.section === "doc") return cell;
  };

  const renderHOAName = (cell, row) => {
    return (
      <a
        href={!_.isEmpty(row.file_url) ? row.file_url : null}
        style={{ float: "none", color: "black" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {row.name}
      </a>
    );
  };

  const renderCategory = (cell, row) => {
    if(props.isMunicipalRequirementAction){
      if (!_.isEmpty(row.file_url)) return props.category;
    }
    if (!_.isEmpty(row.file_url)) return cell;
  };

  const renderMunicipalityCategory = (cell, row) => {
    if (!_.isEmpty(row.file_url)) return "Municipality";
  };

  const renderCodeViolationCaseNumber = (cell, row) => {
    let case_number = !_.isEmpty(row.case_number) ? row.case_number : "";
    let doc_type = !_.isEmpty(row.doc_type) ? row.doc_type : "";
    let hyphenSymbol =
      !_.isEmpty(row.case_number) && !_.isEmpty(row.doc_type) ? " - " : "";
    if (!_.isEmpty(row.file_url))
      return (
        <a
          href={!_.isEmpty(row.file_url) ? row.file_url : null}
          style={{ float: "none", color: "black" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {case_number + hyphenSymbol + doc_type}
        </a>
      );
  };

  const renderCodeViolationNotificationSequence = (cell, row) => {
    let notification_sequence = !_.isEmpty(row.notification_sequence)
      ? row.notification_sequence
      : "";
    let classification = !_.isEmpty(row.classification)
      ? row.classification
      : "";
    let hyphenSymbol =
      !_.isEmpty(row.notification_sequence) && !_.isEmpty(row.classification)
        ? " - "
        : "";
    if (!_.isEmpty(row.file_url))
      return notification_sequence + hyphenSymbol + classification;
  };

  const renderEntity = (cell, row) => {
    if (!_.isEmpty(row.associable)) return row.associable.name;
  };

  const guestUsercolumns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: renderTimestamp,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "category",
      text: "Category",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "name",
      text: "Name",
      formatter: renderFileURL,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Notes",
      dataField: "memo_notes",
      classes: "notes-width",
      headerClasses: "header-cell-alignment",
    },
    {
      text: "Not Applicable",
      formatter: guestUserApplicableFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ];
  const associationcolumns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: renderTimestamp,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "hoa_name",
      text: "HOA",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "category",
      text: "Category",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "name",
      text: "Name",
      formatter: renderFileURL,
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Notes",
      dataField: "memo_notes",
      classes: "notes-width",
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Not Applicable",
      formatter: applicableFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
    {
      text: "Action",
      formatter: associationModalTrashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ];

  const paymentColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerClasses: "header-cell-alignment",
    },

    {
      dataField: "category",
      text: "Category",
      formatter: renderCategory,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "name",
      text: "Name",
      formatter: renderName,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "associable",
      text: "Entity Name",
      formatter: renderEntity,
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Notes",
      dataField: "memo_notes",
      // classes: "notes-width",
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ];

  const columns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      sort: true,
      headerClasses: "header-cell-alignment",
    },

    {
      dataField: "category",
      text: "Category",
      formatter: renderCategory,
      sort: true,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "name",
      text: "Name",
      formatter: renderPropertyName,
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Notes",
      dataField: "memo_notes",
      classes: "notes-width",
      headerClasses: "header-cell-alignment",
      formatter: renderNotes,
    },

    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ];

  const hoaColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerClasses: "header-cell-alignment",
    },

    {
      dataField: "category",
      text: "Category",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "name",
      text: "Name",
      formatter: renderHOAName,
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Notes",
      dataField: "memo_notes",
      classes: "notes-width",
      headerClasses: "header-cell-alignment",
    },
    {
      text: "Not Applicable",
      formatter: applicableFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ];

  const municipalityReviewColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "file_name",
      text: "Name",
      formatter: renderFileURL,
    },
    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
  ];

  const deedverificationColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "doc_type",
      text: "Name",
      formatter: renderFileURL,
    },
    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
  ];

  const titleReviewColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "file_name",
      text: "Name",
      formatter: renderFileURL,
    },
    {
      dataField: "notification_sequence",
      text: "Notification Sequence",
    },
    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
  ];

  const codeViolationColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "case_number",
      text: " Case # / Name",
      formatter: renderCodeViolationCaseNumber,
    },
    {
      dataField: "category",
      text: "Category",
    },

    {
      dataField: "notification_sequence",
      text: "Notification Sequence / Classification",
      formatter: renderCodeViolationNotificationSequence,
    },

    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
  ];

  const municipalityColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "municipality_name",
      text: "Municipality",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "category",
      text: "Category",
      formatter: renderMunicipalityCategory,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "category",
      text: "Name",
      formatter: renderName,
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Notes",
      dataField: "memo_notes",
      classes: "notes-width",
      headerClasses: "header-cell-alignment",
    },

    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ];

  const lienColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "category",
      text: "Category",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "doc_type",
      text: "Type",
      formatter: renderFileURL,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "classification",
      text: "Classification",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "recording_location",
      text: "Recording Location",
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "recording_date",
      text: "Recording Date",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "book",
      text: "Book",
      headerClasses: "header-cell-alignment",
    },
    { 
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ]
  const municipalColumns = [
    {
      dataField: "uploaded_date",
      text: "Date",
      formatter: renderTimestamp,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "category",
      text: "Category",
      formatter: renderCategory,
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "file_name",
      text: "name",
      formatter: renderFileURL,
      headerClasses: "header-cell-alignment",
    },
    {
      text: "Action",
      formatter: trashFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      headerClasses: "header-cell-alignment",
    },
  ]
  let dataColumns;
  if (props.isAssociationModal === true) dataColumns = associationcolumns;
  else if (
    props.isGuestAssociationModal === true ||
    props.isGuestPropertyModal === true
  )
    dataColumns = guestUsercolumns;
  else if (props.isHOAAssociationModal === true) dataColumns = hoaColumns;
  else if (
    props.isMunicipalityReview === true ||
    props.isMuniRequirementDocument === true
  )
    dataColumns = municipalityReviewColumns;
  else if (props.isDeedVerification === true)
    dataColumns = deedverificationColumns;
  else if (props.iscodeViolation === true) dataColumns = codeViolationColumns;
  else if (props.isPayment === true) dataColumns = paymentColumns;
  else if (props.isTitleReviewAction === true) dataColumns = titleReviewColumns;
  else if (props.isMunicipalityDocs === true) dataColumns = municipalityColumns;
  else if (props.isLienUpload === true) dataColumns = lienColumns;
  else if (props.isMunicipalRequirementAction === true) dataColumns = municipalColumns;
  else dataColumns = columns;

  return _.isEmpty(props.documents) ? (
    <></>
  ) : (
    <React.Fragment>
      {isloading ? (
        <Loader />
      ) : (
        <>
          <div
            className={
              props.isAssociationModal ||
              props.isPropertyModal ||
              props.isLeaseInfo ||
              props.isMunicipalityDocs
                ? "documentTab"
                : "hoa-documentTab"
            }
            style={{
              width:
                props.isGuestAssociationModal || props.isGuestPropertyModal
                  ? "90%"
                  : "",
              marginLeft:
                props.isGuestAssociationModal || props.isGuestPropertyModal
                  ? "4rem"
                  : "",
              marginTop: props.isPayment ? "-3rem" : "",
            }}
          >
            <BootstrapTable
              keyField="id"
              data={props.documents}
              columns={dataColumns}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};
