import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Col,
} from "reactstrap";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_hearingModal.scss";
import config from "../../utils/config";
import helpers from "../../utils/helpers";
import AsyncCreatable from "../helper_components/AsyncCreatable";
import _ from "lodash";
import AttendeesModal from "./AttendeesModal";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import dayjs from "dayjs";
import moment from "moment";

export default function HearingModal(props) {
  const [row, setrow] = useState(props.row ? props.row : {});
  const [pocOptions, setpocOptions] = useState();
  const [actionable_id, setactionable_id] = useState(props.actionID);
  const [representation, setrepresentation] = useState({
    Internal: "Internal",
    External: "External",
  });
  const [openAttendeesModal, setopenAttendeesModal] = useState(false);
  //const [attendeesName, setattendeesName] = useState([]);

  const toggleAttendeesModal = () => {
    setopenAttendeesModal(!openAttendeesModal);
  };

  useEffect(() => {
    if (!_.isEmpty(props.row)) {
      if (
        !_.isEmpty(props.row.law_firm) &&
        !_.isEmpty(props.row.law_firm.code_violation_pocs)
      ) {
        setpocOptions(
          props.row.law_firm.code_violation_pocs.map((x) => x.name)
        );
      }
    }
  }, []);

  const attendeesListTable = (list) => {
    let data = _.isArray(list) ? list : list.split(",");
    return (
      !_.isEmpty(data) &&
      data.map((x, index) => {
        return (
          <tr>
            <td>{`${index + 1}: ${x}`}</td>
          </tr>
        );
      })
    );
  };

  const handleAttendeesChange = (attendees, absentees) => {
    setopenAttendeesModal(!openAttendeesModal);

    let x = {};
    x.absentees = absentees;
    x.attendees = attendees;

    setrow(Object.assign({ ...row }, { ...x }));
  };

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value = event.target.value;

    let data;
    if (targetID === "poc_name") {
      data = pocOptions.poc.find((x) => x.name === value);
      let x;
      x = {
        poc_name: data.name,
        poc_title: data.title,
        poc_email: data.email,
        poc_phone: data.phone,
      };
      setrow(Object.assign({ ...row }, { ...x }));
    } else {
      setrow(Object.assign({ ...row }, { [targetID]: value }));
    }
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const handleDateChange = (value, key) => {
    let date = dayjs(value).format(config.US_DATE_TIME_FORMAT);
    console.log("The date time is", date);
    setrow(Object.assign({ ...row }, { [key]: date }));
  };

  const handleSubmit = (event) => {
    let data = {};
    for (let key in row) {
      if (key !== "law_firm") {
        let newKey = "title_hearings_attributes[][" + key + "]";
        if (
          key === "attendees" &&
          row[key] !== undefined &&
          !_.isEmpty(row[key])
        ) {
          data[newKey] = row[key].toString();
        } else if (
          key === "absentees" &&
          row[key] !== undefined &&
          !_.isEmpty(row[key])
        ) {
          data[newKey] = row[key].toString();
        } else data[newKey] = row[key];
      }
    }
    if (!_.includes(row, row.time)) {
      let date = dayjs(new Date()).format(config.US_DATE_TIME_FORMAT);
      data = { ...data, "title_hearings_attributes[][time]": date };
    }
    if (actionable_id) {
      apicall
        .updateCodeViolation(actionable_id, props.propertyID, data)
        .then((response) => {
          if (response) {
            helpers.notify("Updated successfully");
            setrow(response);
            if (props.open) {
              props.toggle();
              props.fetchDocuments(response.id);
            }
          }
        });
    } else {
      apicall.createCodeViolation(props.propertyID, data).then((response) => {
        if (response) {
          helpers.notify("Created successfully");
          setrow(response);
          setactionable_id(response.id);
          if (props.open) {
            props.toggle();
            props.fetchDocuments(response.id);
          }
        }
      });
    }
    event.preventDefault();
  };

  const handleSelectChange = (id, valueObj) => {
    if (!_.has(valueObj, "__isNew__") && !_.isEmpty(valueObj)) {
      let x;
      let pocs;
      x = {
        law_firm_name: valueObj.label,
        law_firm_id: valueObj.data["value"],
        poc: valueObj.data.code_violation_pocs.map((x) => {
          return x.name;
        }),
      };
      pocs = {
        poc: valueObj.data.code_violation_pocs.map((x) => {
          return x.name;
        }),
      };
      setpocOptions(pocs);
      setrow(Object.assign({ ...row }, { ...x }));
    }
  };

  const pocData = [];
  let pocDetails;
  if (!_.isEmpty(pocOptions) && _.includes(pocOptions, pocOptions.poc)) {
    pocDetails = pocOptions.poc;
  } else if (!_.isEmpty(pocOptions)) {
    pocDetails = pocOptions;
  }

  pocData.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  if (!_.isEmpty(pocDetails)) {
    for (let type of pocDetails) {
      pocData.push(
        <option key={type} value={type}>
          {type}
        </option>
      );
    }
  }

  const representationOption = [];
  representationOption.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  for (let type in representation) {
    representationOption.push(
      <option key={type} value={type}>
        {type}
      </option>
    );
  }

  const formElements = () => {
    let x;
    if (!_.isEmpty(row.law_firm)) {
      x = {
        label: row.law_firm.name,
        value: row.law_firm.id,
      };
    }

    return (
      <div>
        <FormGroup row>
          <Label for="" sm={2}>
            Date
          </Label>
          <Col sm={3}>
            <Datetime
              id="time"
              name="time"
              onChange={(date) => handleDateChange(date, "time")}
              value={moment(row.time)}
              dateFormat={config.US_DATE_FORMAT}
              timeFormat={"hh:mm a"}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Representation
          </Label>
          <Col sm={2}>
            <Input
              type="select"
              id="representation"
              name="representation"
              onChange={handleChange}
              value={row.representation}
            >
              {representationOption}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Assigned To
          </Label>
          <Col sm={8}>
            <AsyncCreatable
              callapi={apicall.listLawFirm}
              handleOnChange={(id, value) => {
                handleSelectChange(id, value);
              }}
              valueShown={x}
              placeholder="Search"
              isClearable={true}
              propertyID={props.propertyID}
            />
          </Col>
          <Col sm={2}>
            {/* <Button className="hearing-buttons" style={{ marginTop: "5px" }}>
              Add
            </Button> */}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Lawyer
          </Label>
          <Col sm={8}>
            <Input
              type="select"
              name="law_firm_poc_name"
              id="law_firm_poc_name"
              onChange={handleChange}
              value={row.law_firm_poc_name}
            >
              {pocData}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Attending Notes
          </Label>
          <Col sm={8}>
            <Input
              type="textarea"
              name="attending_notes"
              id="attending_notes"
              onChange={handleChange}
              value={row.attending_notes}
            />
          </Col>
          <Col sm={2}>
            {" "}
            {/* <Button className="hearing-buttons" style={{ marginTop: "10px" }}>
              Save
            </Button> */}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Attended
          </Label>
          <div className="code-violation-hearing-radio-button">
            <CustomInput
              id={`attendedyes`}
              type="radio"
              name="attended"
              value={true}
              onClick={(e) => handleRadioButton(e, true)}
              checked={row.attended === true ? "checked" : ""}
            />
            <label>Yes</label>
            <CustomInput
              id={`attended}no`}
              type="radio"
              name="attended"
              value={false}
              onClick={(e) => handleRadioButton(e, false)}
              checked={row.attended === false ? "checked" : ""}
            />
            <label>No</label>
          </div>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Attendees
          </Label>

          {!_.isEmpty(row.attendees) && (
            <table> {attendeesListTable(row.attendees)}</table>
          )}
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            <Button
              className="hearing-buttons"
              onClick={() => setopenAttendeesModal(true)}
            >
              Select
            </Button>
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col sm={7}>
            <Button
              className="hearing-buttons"
              style={{ float: "right" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Hearing Details</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="hearing-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
      {openAttendeesModal && (
        <AttendeesModal
          toggle={toggleAttendeesModal}
          title="Attendees Modal"
          open={openAttendeesModal}
          handleAttendeesChange={handleAttendeesChange}
          row={row}
          pocOptions={pocOptions}
        />
      )}
    </div>
  );
}
