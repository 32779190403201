import React, { useState, useEffect } from "react";
import "../../styles/_general.scss";
import apicall from "../../utils/apicall";
import AsyncMutliMuniSelect from "./AsyncMutliMuniSelect";
import "../../styles/_display-hoa.scss";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import Loader from "../helper_components/LoaderComponent";
import config from "../../utils/config";
import ConfirmBox from "../helper_components/ConfirmBox";
import helpers from "../../utils/helpers";
import { FaEdit, FaTrashAlt, FaLink } from "react-icons/fa";
import _ from "lodash";
import MunicipalModal from "./MunicipalModal";
import MunicipalRequirementLoginModal from "./MunicipalRequirementLoginModal";
import MunicipalRequirementAction from "./MunicipalRequirementAction";

export default function DisplayMunicipal(props) {
  const [propertyid, setpropertyid] = useState(props.propertyid);
  const [municipals, setmunicipals] = useState([]);
  const [row, setrow] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [deleteModal, setdeleteModal] = useState(false);
  const [openMunicipalModal, setopenMunicipalModal] = useState(false);
  const [toggleConfirmBoxModal, settoggleConfirmBoxModal] = useState(false);
  const [states, set_States] = useState();
  const [
    openMunicipalRequirementModal,
    setopenMunicipalRequirementModal,
  ] = useState(false);
  const [municipality_requirements, setmunicipality_requirements] = useState();

  const [
    openMunicipalRequirementAction,
    setopenMunicipalRequirementAction,
  ] = useState(false);
  const [municipalActionData, setmunicipalActionData] = useState({});

  useEffect(() => {
    loadMunicipals();

    readStates();
  }, []);

  const toggleConfirmBox = () => {
    settoggleConfirmBoxModal(!toggleConfirmBoxModal);
  };
  const readStates = () => {
    apicall.filterStates().then((response) => {
      if (response) {
        let statesArray = [];
        if (response.states) {
          statesArray = response.states.map((x) => {
            return { value: x, label: x };
          });

          set_States(statesArray);
        }
      }
    });
  };

  const loadMunicipals = () => {
    apicall.getMunicipalByPropertyID(props.propertyid).then((response) => {
      setmunicipals(response.results);
      //settotalCount(response.total);
      setisLoading(false);
    });
  };

  const deleteRecord = (row) => {
    apicall
      .removeMunicipalAtPropertyLevel(row.id, propertyid)
      .then((response) => {
        if (response) {
          toast("Deleted successfully");
          setisLoading(true);
          setTimeout(loadMunicipals, 3000);
        }
      });
  };

  const municipalNametableData = (nameValue, row, isMunicipal) => {
    return (
      !_.isEmpty(nameValue) &&
      nameValue.map((x, index) => {
        return (
          <td key={index} style={{ wordBreak: "break-all" }}>
            {!_.isEmpty(x) && (
              <>
                <div className="edit-link" style={{ width: "70%" }}>
                  {isMunicipal && x !== "County" && (
                    <FaTrashAlt
                      style={{ float: "right" }}
                      size="1.5em"
                      onClick={() => {
                        setrow(row[index]);
                        setdeleteModal(true);
                      }}
                    />
                  )}
                  <FaEdit
                    style={{ float: "right" }}
                    size="1.5em"
                    onClick={() => {
                      if (isMunicipal) {
                        setrow(row[index]);
                        setopenMunicipalModal(true);
                      } else {
                        setmunicipality_requirements(
                          row.municipality_requirements
                        );
                        setrow(row);
                        setopenMunicipalRequirementModal(true);
                      }
                    }}
                  />
                </div>
                <div style={{ fontWeight: "bold" }}>{x}</div>
              </>
            )}
          </td>
        );
      })
    );
  };

  const tableData = (name, key) => {
    return name.map((x, i) => {
      return <td key={i}> {!_.isEmpty(x) ? x : ""}</td>;
    });
  };

  const filterData = (mc, key) => {
    return mc.map((x, i) => {
      if (_.has(x, [key])) return x[key];
      else return null;
    });
  };

  const fetchPocData = (row, key) => {
    return row.map((muni, i) => {
      if (_.has(muni, "muni_registrations")) {
        // let pocId = hoa[pocid];
        let pocArray = muni.muni_registrations;
        let poc = pocArray.find((x) => x.municipal_requirement_name === key);
        return poc === undefined ? null : poc;
      } else {
        return null;
      }
    });
  };

  const loadCell = (row) => {
    let data = [];
    let county_data = row.filter((x) => x.muni_type === "County");
    let city_town_data = row.filter((x) => x.muni_type === "City / Township");
    let village_borough__data = row.filter(
      (x) => x.muni_type === "Village / Burrough"
    );
    county_data = Object.assign({}, ...county_data);
    city_town_data = Object.assign({}, ...city_town_data);
    village_borough__data = Object.assign({}, ...village_borough__data);

    data.push(county_data);
    data.push(city_town_data);
    data.push(village_borough__data);

    let nameTitle = "Municipality Name";
    let municipalType = "Municipality Type";
    let muni_type = filterData(data, "muni_type");
    let municipal_name = filterData(data, "name");
    let state = filterData(data, "state");

    let businessData = fetchPocData(data, "Business License Registration");
    let landlordData = fetchPocData(data, "Landlord Registration");
    let vacantData = fetchPocData(data, "Vacant Property Registration");
    let certificateOccupancyData = fetchPocData(
      data,
      "Certificate of Occupancy"
    );
    let rentalRegistrationData = fetchPocData(data, "Rental Registration");
    let crimeFreeHousingData = fetchPocData(data, "Crime Free Housing");
    let rentalTaxesData = fetchPocData(data, "Rental Taxes");
    let periodicData = fetchPocData(data, "Periodic Registrations");

    let width = "100%";
    return (
      <div>
        <table
          className="municipal-display-table"
          style={{ width: width, marginLeft: "-5rem" }}
        >
          <tbody>
            <tr>
              <th>{municipalType}</th>
              {municipalNametableData(muni_type, data, true)}
            </tr>
            <tr>
              <th>{nameTitle}</th>
              {tableData(municipal_name)}
            </tr>
            <tr>
              <th>{"State"}</th>
              {tableData(state)}
            </tr>
            <br />
            <tr>
              <th>{"Website,User Name,Password"}</th>
              {municipalRequirementUserNameEdit(data)}
            </tr>
            <br />

            {!_.isEmpty(businessData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  businessData,
                  "Business License Registration"
                )}
              </tr>
            )}
            {!_.isEmpty(landlordData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  landlordData,
                  "Landlord Registration"
                )}
              </tr>
            )}
            {!_.isEmpty(vacantData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  vacantData,
                  "Vacant Property Registration"
                )}
              </tr>
            )}
            {!_.isEmpty(certificateOccupancyData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  certificateOccupancyData,
                  "Certificate of Occupancy"
                )}
              </tr>
            )}
            {!_.isEmpty(rentalRegistrationData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  rentalRegistrationData,
                  "Rental Registration"
                )}
              </tr>
            )}
            {!_.isEmpty(crimeFreeHousingData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  crimeFreeHousingData,
                  "Crime Free Housing"
                )}
              </tr>
            )}
            {!_.isEmpty(rentalTaxesData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(rentalTaxesData, "Rental Taxes")}
              </tr>
            )}

            {!_.isEmpty(periodicData.filter((x) => x !== null)) && (
              <tr>
                {municipalRequirementTable(
                  periodicData,
                  "Property Deregistration"
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const municipalRequirementTable = (data, nameTitle) => {
    return (
      <>
        <th>{nameTitle}</th>
        {municipalTableData(data)}
      </>
    );
  };

  const municipalTableData = (data) => {
    return data.map((x, i) => {
      let color;
      if (!_.isEmpty(x)) {
        if (x.date_diff >= 60) color = "green";
        else if (x.date_diff >= 1 && x.date_diff < 60) color = "yellow";
        else if (x.date_diff <= 0) color = "red";
      }

      return (
        <>
          {!_.isEmpty(x) ? (
            <td key={i}>
              <div className="edit-link" style={{ width: "70%" }}>
                {!_.isEmpty(x.municipal_requirement_website) && (
                  <a
                    href={`https://${x.municipal_requirement_website}`}
                    style={{ float: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLink style={{ float: "right" }} size="1.5em" />
                  </a>
                )}
                <FaEdit
                  style={{ float: "right" }}
                  size="1.5em"
                  onClick={() => {
                    setmunicipalActionData(x);
                    setopenMunicipalRequirementAction(true);
                  }}
                />
              </div>
              <div className="municipal-color-div">
                <div className="dot" style={{ background: color }}></div>
                <div className="municipal-color-text">
                  {helpers.formatDate(x.registration_issued_date)}
                </div>
              </div>
            </td>
          ) : (
            <td></td>
          )}
        </>
      );
    });
  };

  const municipalRequirementUserNameEdit = (name) => {
    return (
      !_.isEmpty(name) &&
      name.map((x, index) => {
        return (
          <td key={name} style={{ wordBreak: "break-all" }}>
            {!_.isEmpty(x) && (
              <div className="edit-link" style={{ width: "70%" }}>
                <FaEdit
                  style={{ float: "right" }}
                  size="1.5em"
                  onClick={() => {
                    setmunicipality_requirements(x.municipality_requirements);
                    setrow(x);
                    setopenMunicipalRequirementModal(true);
                  }}
                />
                <div style={{ float: "right" }}>{""}</div>
              </div>
            )}
          </td>
        );
      })
    );
  };

  const renderMunicipals = () => {
    if (
      municipals !== null &&
      typeof municipals !== "undefined" &&
      Object.keys(municipals).length !== 0
    ) {
      let municipaldata = municipals;
      return (
        <React.Fragment>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="hoa-details">
              {!config["generalInfoRead"].includes(props.userRole) && (
                <div className="mutli-select">
                  <AsyncMutliMuniSelect
                    isMunicipal={true}
                    propertyid={propertyid}
                    getActions={props.getActions}
                    loadHoaList={loadMunicipals}
                  />
                </div>
              )}

              {loadCell(municipaldata)}
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="hoa-details">
              Municipality is not linked
              {!config["generalInfoRead"].includes(props.userRole) && (
                <div className="multi-select">
                  <AsyncMutliMuniSelect
                    isMunicipal={true}
                    propertyid={propertyid}
                    getActions={props.getActions}
                    loadHoaList={loadMunicipals}
                  />
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      );
    }
  };
  return (
    <React.Fragment>
      {config["showHoa"].includes(props.userRole) && (
        <Button
          className="create-municipal"
          onClick={() => {
            setrow({});
            setopenMunicipalModal(true);
          }}
        >
          Create Municipal
        </Button>
      )}

      {renderMunicipals()}

      {openMunicipalModal && (
        <MunicipalModal
          toggle={() => {
            setopenMunicipalModal(!openMunicipalModal);
            loadMunicipals();
          }}
          title="MUNICIPAL"
          toggleConfirmBox={toggleConfirmBox}
          open={true}
          formdata={row}
          setRow={(row) => setrow(row)}
          isDisplayMunicipal={true}
          propertyid={propertyid}
          states={states}
        />
      )}

      {openMunicipalRequirementModal && (
        <MunicipalRequirementLoginModal
          open={openMunicipalRequirementModal}
          toggle={() => {
            setopenMunicipalRequirementModal(!openMunicipalRequirementModal);
            loadMunicipals();
          }}
          formdata={municipality_requirements}
          setRow={(row) => setrow(row)}
          isDisplayMunicipal={true}
          propertyid={propertyid}
          municipality={row}
          readMunicipal={loadMunicipals}
        />
      )}

      {openMunicipalRequirementAction && (
        <MunicipalRequirementAction
          open={openMunicipalRequirementAction}
          toggle={() => {
            setopenMunicipalRequirementAction(!openMunicipalRequirementAction);
            loadMunicipals();
          }}
          propertyID={propertyid}
          readMunicipal={loadMunicipals}
          actionID={municipalActionData.id}
          description={municipalActionData.municipal_requirement_name}
          isDisplayMunicipal={true}
        />
      )}

      {deleteModal && (
        <ConfirmBox
          onYesClick={() => {
            deleteRecord(row);
            setdeleteModal(false);
          }}
          onNoClick={() => setdeleteModal(false)}
          heading={
            <span>
              Are you sure you want to delete the Municipality: {row.name}?
            </span>
          }
        >
          <div>
            <p>
              All Municipality related details from properties will be deleted.
            </p>
          </div>
        </ConfirmBox>
      )}
    </React.Fragment>
  );
}
