import FormComp from "../crud/FormComp";
import React from "react";
import DatePicker from "react-datepicker";
import { FormGroup, Label } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import "react-datepicker/dist/react-datepicker.css";
import apicall from "../../utils/apicall";
import TabsComponent from "../helper_components/TabsComponent";
import config from "../../utils/config";
import "../../styles/_liens.scss";
import helpers from "../../utils/helpers";
import _ from "lodash";
export default class LienModal extends FormComp {
  constructor(props) {
    super(props);
    this.state = {
      row: {},
      className: " lien-modal",
      submitButton: "",
      isDisabled: false,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentDidMount() {
    if (config["LiensRead"].includes(this.props.userRole)) {
      this.hideSubmit();
    }
    apicall
      .lienFetch(this.state.row, this.state.propertyID, this.state.actionID)
      .then((response) => {
        if (!_.isEmpty(response))
          this.setState({
            row: response,
          });
      });
  }

  handleSubmit = () => {
    apicall
      .lienUpdate(this.state.row, this.state.propertyID, this.state.actionID)
      .then((response) => {
        if (response) {
          this.notify("Lien Updated Successfully.");
          this.setState({
            row: response,
          });
          this.props.refershActions(this.state.propertyID);
        }
      });
  };

  lienDataComponent = () => {
    return (
      <>
        <FormGroup>
          <Label for="">Type of Lien *</Label>
          <TextValidator
            type="text"
            name="type_of_lien"
            id="type_of_lien"
            placeholder="Type of Lien"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Lien Type is required"]}
            value={
              this.state.row.type_of_lien === "null"
                ? ""
                : this.state.row.type_of_lien
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Description *</Label>
          <TextValidator
            type="text"
            name="description"
            id="description"
            placeholder="Description"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Description is required"]}
            value={
              this.state.row.description === "null"
                ? ""
                : this.state.row.description
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Instrument *</Label>
          <TextValidator
            type="text"
            name="instrument"
            id="instrument"
            placeholder="Instrument"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Instrument is required"]}
            value={
              this.state.row.instrument === "null"
                ? ""
                : this.state.row.instrument
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label style={{ marginRight: "2%" }} for="lien_date_recorded">
            Lien Date Recorded
          </Label>
          <DatePicker
            id="lien_date_recorded"
            selected={
              this.state.row.lien_date_recorded
                ? helpers.restructureDate(this.state.row.lien_date_recorded)
                : null
            }
            onChange={(date) => {
              this.handleDateChange("lien_date_recorded", date);
            }}
            disabled={this.state.isDisabled}
            // value={this.state.row.lien_date_recorded}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Attorney *</Label>
          <TextValidator
            type="text"
            name="attorney"
            id="attorney"
            placeholder="Attorney"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Attorney is required"]}
            value={
              this.state.row.attorney === "null" ? "" : this.state.row.attorney
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Attorney Mobile *</Label>
          <TextValidator
            type="number"
            name="attorney_mobile"
            id="attorney_mobile"
            placeholder="Attorney Mobile"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Attorney Mobile is required"]}
            value={this.state.row.attorney_mobile}
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Attorney Address *</Label>
          <TextValidator
            type="text"
            name="attorney_address"
            id="attorney_address"
            placeholder="Attorney Adress"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Attorney Address is required"]}
            value={
              this.state.row.attorney_address === "null"
                ? ""
                : this.state.row.attorney_address
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Attorney Email *</Label>
          <TextValidator
            type="email"
            name="attorney_email"
            id="attorney_email"
            placeholder="Attorney Email"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Attorney Email is required"]}
            value={
              this.state.row.attorney_email === "null"
                ? ""
                : this.state.row.attorney_email
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">SA Amount *</Label>
          <TextValidator
            type="number"
            name="amount"
            id="amount"
            placeholder="SA Amount"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["SA Amount is required"]}
            value={
              this.state.row.amount === "null" ? "" : this.state.row.amount
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Respond By</Label>
          <input
            type="text"
            name="respond_by"
            id="respond_by"
            placeholder="Respond By"
            onChange={this.handleChange}
            value={
              this.state.row.respond_by === "null"
                ? ""
                : this.state.row.respond_by
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Book Page *</Label>
          <TextValidator
            type="text"
            name="book_page"
            id="book_page"
            placeholder="Book Page"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Book Page is required"]}
            value={
              this.state.row.book_page === "null"
                ? ""
                : this.state.row.book_page
            }
            disabled={this.state.isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Notes *</Label>
          <TextValidator
            type="text"
            name="notes"
            id="notes"
            placeholder="Notes"
            onChange={this.handleChange}
            validators={["required"]}
            errorMessages={["Notes is required"]}
            value={this.state.row.notes === "null" ? "" : this.state.row.notes}
            disabled={this.state.isDisabled}
          />
        </FormGroup>
      </>
    );
  };

  formElements = () => {
    var listofTabs = ["Lien", "Documents"];
    let tabsComponentsList = new Array(listofTabs.length).fill(null);
    tabsComponentsList[0] = this.lienDataComponent();
    tabsComponentsList[1] = this.documentComp(this.state.isDisabled);
    return (
      <>
        <TabsComponent
          theme=" lienModal "
          components={tabsComponentsList}
          listofTabs={listofTabs}
          generateTabURL={false}
        />
      </>
    );
  };
}
