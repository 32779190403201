import React, { Component, useState, useEffect } from "react";
import apicall from "../../../utils/apicall";
import GuestUserNavbar from "../../page_layout_components/GuestUserNavbar";
import helpers from "../../../utils/helpers";
import config from "../../../utils/config";
import _ from "lodash";
import { CustomInput } from "reactstrap";
import "../../../styles/_displayGuestInfo.scss";
import PropertyInfo from "../../general_info/GuestPropertyInfo";
import Loader from "../../helper_components/LoaderComponent";

export default function DisplayGuestProperty(props) {
  const [propertyDetails, setpropertyDetails] = useState({});
  const [utilities_amenities, setutilities_amenities] = useState({});
  const [leasing_property_access, setleasing_property_access] = useState({});
  const [actionArray, setactionArray] = useState([]);
  const [propertyInfo, setpropertyInfo] = useState(null);
  const [hoa_status, sethoa_status] = useState();
  const [leasing_permitted, setleasing_permitted] = useState();
  const [leasing_info_applicable, setleasing_info_applicable] = useState();
  const [hoaId, sethoaId] = useState();
  const [amenity, setamenity] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const fetchPropertyInfo = () => {
    apicall
      .guestPropertyInfo(props.match.params.propertyID)
      .then((response) => {
        if (response) {
          let masterHOA = null;
          if (response && response.total == 1) {
            masterHOA = response.results[0];
          } else if (response && response.total > 1) {
            masterHOA = response.results.find((x) => x.status === "Master");
          }
          if (!_.isEmpty(masterHOA) && masterHOA !== null) {
            sethoaId(masterHOA.id);
            setleasing_info_applicable(masterHOA.leasing_info_applicable);
          }
          setisLoading(false);
          setamenity(response.hoa_amenity);
          setpropertyDetails(response.results);

          setutilities_amenities(response.utilities_amenities);
          setleasing_property_access(response.leasing_property_access);
          sethoa_status(response.hoa_status);
          setleasing_permitted(response.leasing_permitted);
        }
      });
  };
  useEffect(() => {
    fetchPropertyInfo();
    // getActions(props.match.params.propertyID);
    getPropertyInfo(props.match.params.propertyID);
  }, []);

  const getActions = (propertyID) => {
    apicall.listActions(propertyID).then((response) => {
      setactionArray(response);
    });
  };

  const getPropertyInfo = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      setpropertyInfo(response);
      //setleasing_info_applicable(response.leasing_info_applicable);
    });
  };

  const filterData = (mc, key) => {
    return mc.map((x, i) => {
      if (_.has(x, [key])) return x[key];
      else return null;
    });
  };

  const fetchPocData = (row, pocid) => {
    return row.map((hoa, i) => {
      if (_.has(hoa, [pocid]) && _.has(hoa, "management_company")) {
        let pocId = hoa[pocid];
        let pocArray = hoa.management_company.point_of_contacts;
        let poc = pocArray.find((x) => x.id === pocId);
        return poc;
      } else {
        return null;
      }
    });
  };
  const hoaNametableData = (nameValue, isHOA, row) => {
    let showEdit = true;
    return (
      !_.isEmpty(nameValue) &&
      nameValue.map((x, index) => {
        return (
          <td key={index} className="hoatableData">
            {x}
          </td>
        );
      })
    );
  };

  const tableData = (name, key) => {
    return name.map((x, i) => {
      if (key === "Good Through Date") {
        return (
          <td key={i} className="hoatableData">
            {helpers.formatDate(x)}
          </td>
        );
      } else
        return (
          <td key={i} className="hoatableData">
            {x}
          </td>
        );
    });
  };

  const hoasPropertiestableData = (data, key) => {
    return data.map((x) => {
      if (
        key === "username" &&
        _.has(x, "username") &&
        (x.name !== null || x.name !== undefined)
      ) {
        return <td className="hoatableData">{x.username}</td>;
      } else if (key === "password" && _.has(x, "password")) {
        return <td className="hoatableData">{x.password}</td>;
      }
      if (
        key === "payment_website_username" &&
        _.has(x, "payment_username") &&
        (x.name !== null || x.name !== undefined)
      ) {
        return <td className="hoatableData">{x.payment_username}</td>;
      } else if (
        key === "payment_website_password" &&
        _.has(x, "payment_password")
      ) {
        return <td className="hoatableData">{x.payment_password}</td>;
      } else return <td className="hoatableData"></td>;
    });
  };

  const pocTable = (data, key) => {
    return data.map((x) => {
      if (
        key === "name" &&
        _.has(x, "name") &&
        (x.name !== null || x.name !== undefined)
      ) {
        return <td className="hoatableData">{x.name}</td>;
      } else if (key === "title" && _.has(x, "title")) {
        return <td className="hoatableData">{config.McPocTitles[x.title]}</td>;
      } else if (key === "phone" && _.has(x, "phone")) {
        return <td className="hoatableData">{x.phone}</td>;
      } else if (key === "email" && _.has(x, "email")) {
        return <td className="hoatableData">{x.email}</td>;
      } else return <td className="hoatableData"></td>;
    });
  };

  const loadCell = (isHOA, row) => {
    // let showEdit = config['showHoa'].includes(this.props.userRole);

    let management_company = filterData(row, "management_company");
    let management_poc1 = fetchPocData(row, "management_poc1_id");
    let management_poc2 = fetchPocData(row, "management_poc2_id");
    let management_poc3 = fetchPocData(row, "management_poc3_id");

    let data = isHOA
      ? row.map((x) => {
          return x;
        })
      : management_company;
    let nameTitle = isHOA ? "HOA Name" : "MC";
    let management_company_name = filterData(management_company, "name");
    let nameValue = isHOA ? filterData(row, "name") : management_company_name;
    let address = filterData(data, "physical_street_no_and_name");
    let status = filterData(data, "status");
    let cityStateZip = data.map((x) => {
      return helpers.formatAddress(
        x,
        config.generalInfo.addressFields,
        config.generalInfo.extraField
      );
    });

    let good_through_date = filterData(data, "good_through_date");

    if (isHOA) {
      data = row;
    } else {
      // let poc1 = this.fetchPocData(management_company, management_poc1_id);
      // console.log(poc1);
    }
    const getAssessment_type = (id, assessment_types) => {
      return (
        assessment_types &&
        assessment_types.map((assessment_type, index) => {
          if (assessment_type.id === id) return assessment_type.name;
        })
      );
    };
    let width;
    if (data.length === 4) width = "105%";
    else if (data.length === 3) width = "100%";
    else if (data.length === 2) width = "70%";
    else if (data.length === 1) width = "40%";
    else width = "40%";
    return (
      <div>
        <table
          style={{
            width: width,
            marginLeft: "15px",
          }}
        >
          <tbody>
            <tr>
              <th className="hoatableHeading">
                {/* <div
                  style={{
                    fontWeight: "bold",
                  }}
                > */}
                {nameTitle}
                {/* </div> */}
              </th>
              <>{hoaNametableData(nameValue, isHOA, row)}</>
            </tr>

            <tr>
              <th className="hoatableHeading">{"Address"}</th>
              {tableData(address)}
            </tr>
            <tr>
              <th className="hoatableHeading">{"City,State,Zip"}</th>
              {tableData(cityStateZip)}
            </tr>
            <tr>
              <th className="hoatableHeading">{"Status"}</th>
              {tableData(status)}
            </tr>

            {isHOA && (
              <>
                {" "}
                <br />
                <tr>
                  <th className="hoatableHeading">{"Good Through Date"}</th>
                  {tableData(good_through_date, "Good Through Date")}
                </tr>
              </>
            )}

            {!isHOA && (
              <>
                {" "}
                <br />
                <tr>
                  <th className="hoatableHeading">{"POC 1 Name"}</th>
                  {pocTable(management_poc1, "name")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Title"}</th>
                  {pocTable(management_poc1, "title")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Phone"}</th>
                  {pocTable(management_poc1, "phone")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Email"}</th>
                  {pocTable(management_poc1, "email")}
                </tr>
              </>
            )}
            {!isHOA && (
              <>
                <tr>
                  <th className="hoatableHeading">{"POC 2 Name"}</th>
                  {pocTable(management_poc2, "name")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Title"}</th>
                  {pocTable(management_poc2, "title")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Phone"}</th>
                  {pocTable(management_poc2, "phone")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Email"}</th>
                  {pocTable(management_poc2, "email")}
                </tr>
              </>
            )}
            {!isHOA && (
              <>
                <tr>
                  <th className="hoatableHeading">{"POC 3 Name"}</th>
                  {pocTable(management_poc3, "name")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Title"}</th>
                  {pocTable(management_poc3, "title")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Phone"}</th>
                  {pocTable(management_poc3, "phone")}
                </tr>
                <tr>
                  <th className="hoatableHeading">{"Email"}</th>
                  {pocTable(management_poc3, "email")}
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderUtilitiesTable = (utilities) => {
    let electricity =
      utilities !== null && utilities.electricity === true ? "Yes" : "No";
    let gas = utilities !== null && utilities.gas === true ? "Yes" : "No";
    let water = utilities !== null && utilities.water === true ? "Yes" : "No";
    let cabel_internet =
      utilities !== null && utilities.cabel_internet === true ? "Yes" : "No";
    let trash = utilities !== null && utilities.trash === true ? "Yes" : "No";
    let snow_removal =
      utilities !== null && utilities.snow_removal === true ? "Yes" : "No";
    let sewer = utilities !== null && utilities.sewer === true ? "Yes" : "No";
    let pest_control =
      utilities !== null && utilities.pest_control === true ? "Yes" : "No";
    let landscaping =
      utilities !== null && utilities.landscaping === true ? "Yes" : "No";

    return (
      <div>
        <div className="guestDisplaycode" style={{ marginBottom: "0px" }}>
          <div className="question-leftSide">
            <div className="answerText">{"Electricity"}</div>
            <div className="questionHeading">{electricity}</div>
          </div>

          <div className="question-rightSide">
            <div className="answerText">{"Pest Control"}</div>
            <div className="questionHeading">{pest_control}</div>
          </div>
        </div>
        <div className="guestDisplaycode" style={{ marginBottom: "0px" }}>
          <div className="question-leftSide">
            <div className="answerText">{"Gas"}</div>
            <div className="questionHeading">{gas}</div>
          </div>

          <div className="question-rightSide">
            <div className="answerText">{"Landscaping"}</div>
            <div className="questionHeading">{landscaping}</div>
          </div>
        </div>
        <div className="guestDisplaycode" style={{ marginBottom: "0px" }}>
          <div className="question-leftSide">
            <div className="answerText">{"Water"}</div>
            <div className="questionHeading">{water}</div>
          </div>

          <div className="question-rightSide">
            <div className="answerText">{"Cable/Internet"}</div>
            <div className="questionHeading">{cabel_internet}</div>
          </div>
        </div>
        <div className="guestDisplaycode" style={{ marginBottom: "0px" }}>
          <div className="question-leftSide">
            <div className="answerText">{"Trash"}</div>
            <div className="questionHeading">{trash}</div>
          </div>

          <div className="question-rightSide">
            <div className="answerText">{"Snow Removal"}</div>
            <div className="questionHeading">{snow_removal}</div>
          </div>
        </div>
        <div className="guestDisplaycode" style={{ marginBottom: "0px" }}>
          <div className="question-leftSide">
            <div className="answerText">{"Sewer"}</div>
            <div className="questionHeading">{sewer}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderLeasingTable = (leasing_property_access) => {
    if (!_.isEmpty(leasing_property_access)) {
      let tenant_application_required = "";
      if (leasing_property_access.tenant_application_required === true)
        tenant_application_required = "Yes";
      else if (leasing_property_access.tenant_application_required === false)
        tenant_application_required = "No";

      let association_move_in_checklist = "";
      if (leasing_property_access.association_move_in_checklist === true)
        association_move_in_checklist = "Yes";
      else if (leasing_property_access.association_move_in_checklist === false)
        association_move_in_checklist = "No";

      let tenant_approval_required = "";
      if (leasing_property_access.tenant_approval_required === true)
        tenant_approval_required = "Yes";
      else if (leasing_property_access.tenant_approval_required === false)
        tenant_approval_required = "No";

      let lease_license_required = "";
      if (leasing_property_access.lease_license_required === true)
        lease_license_required = "Yes";
      else if (leasing_property_access.lease_license_required === false)
        lease_license_required = "No";

      let lease_approval_required = "";
      if (leasing_property_access.lease_approval_required === true)
        lease_approval_required = "Yes";
      else if (leasing_property_access.lease_approval_required === false)
        lease_approval_required = "No";

      let association_app_fee_required = "";
      if (leasing_property_access.association_app_fee_required === true)
        association_app_fee_required = "Yes";
      else if (leasing_property_access.association_app_fee_required === false)
        association_app_fee_required = "No";

      let association_app_fee =
        association_app_fee_required === "No"
          ? ""
          : leasing_property_access.association_app_fee;

      let bg_check_required = "";
      if (leasing_property_access.bg_check_required === true)
        bg_check_required = "Yes";
      else if (leasing_property_access.bg_check_required === false)
        bg_check_required = "No";

      let bg_check_responsiblilty =
        bg_check_required === "No"
          ? ""
          : leasing_property_access.bg_check_responsiblilty;

      let move_in_fee_required = "";
      if (leasing_property_access.move_in_fee_required === true)
        move_in_fee_required = "Yes";
      else if (leasing_property_access.move_in_fee_required === false)
        move_in_fee_required = "No";

      let move_in_fee_amount =
        move_in_fee_required === "No"
          ? ""
          : leasing_property_access.move_in_fee_amount;

      let max_time_for_tenant_approval =
        leasing_property_access.max_time_for_tenant_approval;

      let pet_allowed = "";
      if (leasing_property_access.pet_allowed === true) pet_allowed = "Yes";
      else if (leasing_property_access.pet_allowed === false)
        pet_allowed = "No";

      let pet_restrictions =
        pet_allowed === "No" ? "" : leasing_property_access.pet_restrictions;

      var date = new Date();
      var numberOfDaysToAdd = max_time_for_tenant_approval;
      date.setDate(date.getDate() + numberOfDaysToAdd);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yy = date.getFullYear();
      var tenantApprovaldate;
      if (max_time_for_tenant_approval === null) tenantApprovaldate = "";
      else tenantApprovaldate = mm + "/" + dd + "/" + yy;

      return (
        <div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">
                {"Is a tenant application required?"}
              </div>
              <div className="questionHeading">
                {tenant_application_required}
              </div>
            </div>
          </div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">
                {"Is there an association move-in checklist?"}
              </div>
              <div className="questionHeading">
                {association_move_in_checklist}
              </div>
            </div>
          </div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">{"Tenant approval required?"}</div>
              <div className="questionHeading">{tenant_approval_required}</div>
            </div>
            {tenant_approval_required === "Yes" ? (
              <div className="question-rightSide">
                <div className="answerText">
                  {"Earliest a prospective Tenant can Move in"}
                </div>
                <div className="questionHeading">{tenantApprovaldate}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">{"Lease license required?"}</div>
              <div className="questionHeading">{lease_license_required}</div>
            </div>
          </div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">{"Lease approval required?"}</div>
              <div className="questionHeading">{lease_approval_required}</div>
            </div>
          </div>

          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">
                {"Association Application Fee Required?"}
              </div>
              <div className="questionHeading">
                {association_app_fee_required}
              </div>
            </div>
            {association_app_fee_required === "Yes" ? (
              <div className="question-rightSide">
                <div className="answerText">
                  {"Association Application Fee"}
                </div>
                <div className="questionHeading">{association_app_fee}</div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">{"Background Check Required?"}</div>
              <div className="questionHeading">{bg_check_required}</div>
            </div>
            {bg_check_required === "Yes" ? (
              <div className="question-rightSide">
                {" "}
                <div className="answerText">
                  {"Background Check Responsibility"}
                </div>
                <div className="questionHeading">{bg_check_responsiblilty}</div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">
                {"Association Move-in Fee Required?"}
              </div>
              <div className="questionHeading">{move_in_fee_required}</div>
            </div>
            {move_in_fee_required === "Yes" ? (
              <div className="question-rightSide">
                {" "}
                <div className="answerText">{"Association Move-in Fee"}</div>
                <div className="questionHeading">{move_in_fee_amount}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">{"Assigned Parking"}</div>
              <div className="questionHeading">
                {amenity.parking_assigned === true ? "Yes" : "No"}
              </div>
            </div>
            {amenity.parking_assigned === true ? (
              <div className="question-rightSide">
                <div className="answerText">{"Parking Fee"}</div>
                <div className="questionHeading">{""}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">{"Assigned mailbox"}</div>
              <div className="questionHeading">
                {amenity.assigned_mailbox === true ? "Yes" : "No"}
              </div>
            </div>
          </div>

          <div className="guestDisplaycode">
            <div className="question-leftSide">
              <div className="answerText">
                {"Are there any Pet Restrictions?"}
              </div>
              <div className="questionHeading">{pet_allowed}</div>
            </div>
            {pet_allowed === "Yes" ? (
              <div className="question-rightSide">
                <div className="answerText">{"Pet Restrictions"}</div>
                <div className="questionHeading">{pet_restrictions}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    } else {
      return leasing_info_applicable === false ? (
        <div className="propertyDisplaycode">
          <div className="propertyinfo-leftSide" style={{ width: "65%" }}>
            <div className="answerText">{"Is Property Leasing Permitted?"}</div>
            <div className="questionHeading">{"No"}</div>
          </div>
        </div>
      ) : (
        <h6 className="noLeasingExist">
          {"Leasing Info Does Not Exist For This Property "}
        </h6>
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <GuestUserNavbar
            isGuestUser={true}
            propertyID={props.match.params.propertyID}
            {...props}
          />
          <PropertyInfo
            propertyInfo={propertyInfo}
            // actionArray={actionArray}
            className="property-data"
            hideLink={true}
            isGuestUser={true}
            hoa_status={hoa_status}
            isHoaStatus={true}
            leasing_permitted={leasing_permitted}
            leasing_info_applicable={leasing_info_applicable}
            hoaId={hoaId}
          />

          {_.isEmpty(propertyDetails) ? (
            <h6 className="noHoaExist">
              {"HOA Does Not Exist For This Property"}
            </h6>
          ) : (
            <>
              {loadCell(true, propertyDetails)}
              <br />
              {loadCell(false, propertyDetails)}
              <hr />
              <div>{renderLeasingTable(leasing_property_access)}</div>
              <hr />
              <div>
                {/* {_.isEmpty(utilities_amenities) ? (
              <h6 className="noLeasingExist">
                {"Utilities Does Not Exist For This Property "}
              </h6>
            ) : ( */}
                <>
                  {/* <table
                    style={{
                      width: "49%",
                      // marginLeft: "11%",
                    }}
                  >
                    <tr>
                      <th>
                        {"Are any utilities paid for by the association?"}
                      </th>
                      <td>
                        {utilities_amenities !== null &&
                        utilities_amenities.utilities_paid === true
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                  </table> */}
                  <div className="guestDisplaycode">
                    <div className="question-leftSide">
                      <div className="answerText">
                        {"Are any utilities paid for by the association?"}
                      </div>
                      <div className="questionHeading">
                        {utilities_amenities !== null &&
                        utilities_amenities.utilities_paid === true
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                  </div>
                  {renderUtilitiesTable(utilities_amenities)}
                </>
                {/* )} */}
              </div>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}
