import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_businessUnit.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import _ from "lodash";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import config from "../../utils/config";
import { toast } from "react-toastify";
import BusinessUnitLocation from "./BusinessUnitLocation";
import { Link } from "react-router-dom";
import LoaderComponent from "../helper_components/LoaderComponent";

export default function BusinessUnitModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : {});
  const [pocArray, setpocArray] = useState(
    props.formdata !== undefined &&
      !_.isEmpty(props.formdata.title_point_of_contacts)
      ? props.formdata.title_point_of_contacts
      : []
  );
  const [emails, setEmails] = useState(
    _.has(props, "formdata") && props.formdata.cc_email
      ? props.formdata.cc_email.split(",")
      : []
  );
  const [poc_cc_email, setpoc_cc_email] = useState(
    _.has(props, "formdata") && props.formdata.title_point_of_contacts
      ? props.formdata.title_point_of_contacts.map((poc) => poc.poc_cc_email)
      : []
  );
  const [openLocationModal, setopenLocationModal] = useState(false);
  const [locationPocArray, setlocationPocArray] = useState();
  const [states, set_States] = useState();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    readBusinessUnitStates();
  }, []);

  const readBusinessUnitStates = () => {
    apicall.filterStates().then((response) => {
      if (response) {
        let statesArray = [];
        if (response.states) {
          statesArray = response.states.map((x) => {
            return { value: x, label: x };
          });
          set_States(statesArray);
        }
      }
    });
  };

  const toggleLocationModal = () => {
    setopenLocationModal(!openLocationModal);
  };

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      let res = helpers.formatZip(value);
      setform(Object.assign({ ...formdata }, { [targetID]: res }));
    } else {
      setform(Object.assign({ ...formdata }, { [targetID]: value }));
    }
  };

  const notify = (errorMsg) => toast(errorMsg);

  const handleSubmit = (event) => {
    let pocdata = pocArray;
    //remove temporary Id'

    for (let index in pocdata) {
      if (_.has(pocdata[index], "status")) {
        delete pocdata[index]["id"];
        delete pocdata[index]["status"];
      }
      if (_.isEmpty(pocdata[index]["location"]))
        pocdata[index]["country"] = "US";
    }
    formdata.title_point_of_contacts_attributes = pocdata;

    var requestObject = {
      business_unit: {
        name: formdata.name,
        cc_email: emails.toString(),
        title_point_of_contacts_attributes:
          formdata.title_point_of_contacts_attributes,
      },
    };
    setisLoading(true);
    if (formdata.id) {
      apicall
        .updateBusinessUnit(requestObject, formdata.id)
        .then((response) => {
          if (response !== null && response.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(response.errors);
            if (errorMsg) notify(errorMsg);
          } else {
            helpers.notify("Business Unit Updated Successfully");
            setform(response);
            setpocArray(response.title_point_of_contacts);
            setTimeout(() => {
              // setform(response);
              setisLoading(false);
              // props.listOfBusinessUnit();
              //props.toggle();
            }, 600);
          }
        })
        .catch((e) => {
          helpers.notify("Location is already selected in another POC");
          setisLoading(false);
        });
    } else {
      apicall
        .addBusinessUnit(requestObject)
        .then((response) => {
          if (response !== null && response.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(response.error);
            if (errorMsg) notify(errorMsg);
          } else {
            helpers.notify("Business Unit Created Successfully");
            setform(response);
            setpocArray(response.title_point_of_contacts);
            setTimeout(() => {
              // setform(response);
              setisLoading(false);
              // props.listOfBusinessUnit();
              props.toggle();
            }, 600);
          }
        })
        .catch((e) => {
          helpers.notify("Location is already selected in another POC");
          setisLoading(false);
        });
    }
  };

  const handlePocChange = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    //var pocArray = pocArray.slice();

    var newPocArray = pocArray.map((poc, index) => {
      for (var key in poc) {
        if (key == item.name && poc.id == item.id) {
          poc[key] = item.value;
        }
      }

      return poc;
    });

    setpocArray(newPocArray);
  };

  const handlePocCCEmailChange = (data, id) => {
    var locationArray = pocArray.map((poc, index) => {
      let x = poc;
      if (!_.isEmpty(data)) {
        if (poc.id === id) {
          x["poc_cc_email"] = data.toString();
        }
      }
      return x;
    });
    setpocArray(locationArray);
  };

  const readBusinessUnit = (id) => {
    apicall.readBusinessUnit(id).then((response) => {
      if (response) setpocArray(response.title_point_of_contacts);
    });
  };

  const handleDeleteEvent = (event, poc) => {
    formdata.id &&
      apicall.deleteBusinessUnitPOC(formdata.id, poc.id).then((resp) => {
        helpers.notify("POC deleted");
      });
    setTimeout(() => readBusinessUnit(formdata.id), 3000);
  };

  const handleLocationChange = (data) => {
    setopenLocationModal(!openLocationModal);

    var locationArray = pocArray.map((poc, index) => {
      let x = poc;
      let description;
      if (!_.isEmpty(data.area_name)) {
        description =
          formdata.name + " - " + data.state + " (" + data.area_name + ")";
      } else if (!_.isEmpty(data.state)) {
        description = formdata.name + " - " + data.state;
      } else {
        description = formdata.name;
      }

      let location;
      if (data.area_type === "Zip Code") {
        if (!_.isEmpty(data.zip_code)) {
          location = data.zip_code;
        }
      } else if (data.area_type === "County") {
        if (!_.isEmpty(data.assigned_counties)) {
          location = data.assigned_counties;
        }
      } else location = data.state;

      if (!_.isEmpty(data)) {
        if (poc.id == data.id) {
          x["state"] = data.state;
          x["description"] = description;
          x["location"] = location;
          x["area_name"] = data.area_name;
          x["area_type"] = data.area_type;
          if (data.area_type === "Zip Code") {
            x["zip_code"] = data.zip_code;
          } else if (data.area_type === "County") {
            x["assigned_counties"] = data.assigned_counties;
            x["poc_county"] = data.assigned_counties;
          }
          if (_.isEmpty(location)) {
            x["country"] = "US";
          }
        }
      }

      return x;
    });

    setpocArray(locationArray);
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    var poc = {
      id: id,
      name: "",
      email: "",
      state: "",
      poc_cc_email: "",
      location: "",
      description: formdata.name + " - US",
      area_type: "",
      area_name: "",
      poc_county: "",
      status: "temp",
      country: "",
    };
    setpocArray([...pocArray, poc]);
  };

  const pocModel = (poc, index) => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];

    if (
      (poc["email"] == null || poc["email"] === "") &&
      (poc["phone"] == null || poc["phone"] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID",
      ];
    }

    let assigned_counties = "";
    if (
      !_.isEmpty(poc.assigned_counties) &&
      poc.assigned_counties !== undefined
    )
      assigned_counties = poc.assigned_counties;

    let zip_code = "";
    if (!_.isEmpty(poc.zip_code) && poc.zip_code !== undefined)
      zip_code = poc.zip_code;

    let location;
    if (poc.area_type === "County") {
      if (!_.isEmpty(assigned_counties)) location = assigned_counties;
    } else if (poc.area_type === "Zip Code") {
      if (!_.isEmpty(zip_code)) location = zip_code;
    } else if (!_.isEmpty(poc.state)) location = poc.state;

    const cc_emails = !_.isEmpty(poc.poc_cc_email)
      ? poc.poc_cc_email.split(",")
      : [];

    let description = "";
    if (_.isEmpty(poc.description)) {
      if (_.isEmpty(poc.country)) description = formdata.name + " - US";
    } else description = poc.description;

    let pocDiv = (
      <>
        <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
          {"Point Of Contact Details "}
        </div>
        <div className="poc-style">
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{
                fontWeight: "bold",
                textAlign: "end",
                // paddingLeft: "0px",
              }}
            >
              Description
            </Label>
            <Col sm={9}>
              <TextValidator
                type="text"
                name="description"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={description}
              />
            </Col>
            <FaTrashAlt
              style={{ float: "right", cursor: "pointer" }}
              size="1.5em"
              onClick={(event) => handleDeleteEvent(event, poc)}
            />
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Name
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="name"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={poc.name}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="email"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={emailValidators}
                errorMessages={emailValidatorMessage}
                value={poc.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              <div
                className="location"
                onClick={() => {
                  setopenLocationModal(true);
                  setlocationPocArray(poc);
                }}
                style={{ cursor: "pointer" }}
              >
                Location
              </div>
            </Label>
            <Col sm={10}>
              <Input
                type="textarea"
                name="state"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={location}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Cc Email
            </Label>
            <Col sm={10}>
              <ReactMultiEmail
                name="poc_cc_email"
                id={poc.id}
                placeholder="Enter cc emails "
                emails={cc_emails}
                onChange={(_emails) => {
                  handlePocCCEmailChange(_emails, poc.id);
                  setpoc_cc_email(_emails);
                }}
                validateEmail={(email) => {
                  return isEmail(email); // return boolean
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </Col>
          </FormGroup>
        </div>
      </>
    );
    return pocDiv;
  };

  const formElements = () => {
    return (
      <React.Fragment>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <FormGroup row>
              <Label for="" sm={2} style={{ paddingRight: "0px" }}>
                BU Name *
              </Label>
              <Col sm={10}>
                <TextValidator
                  type="text"
                  name="name"
                  id="name"
                  placeholder="BU Name"
                  validators={["required"]}
                  errorMessages={["Name is required"]}
                  onChange={handleChange}
                  value={formdata.name}
                />
              </Col>
            </FormGroup>

            <div className="add-business-model-poc ">
              {" "}
              <div>
                {pocArray &&
                  pocArray.map((poc, index) => {
                    return <div>{pocModel(poc, index)}</div>;
                  })}
              </div>
              <div className="add-poc" onClick={handleAddEvent}>
                <span className="text">Add POC</span>
                <FaPlus size="1.5em" />
              </div>
            </div>
            <FormGroup row>
              <Label sm={2} for="" style={{ paddingRight: "0px" }}>
                E-mail ID CC
              </Label>
              <Col sm={10}>
                <ReactMultiEmail
                  placeholder="Enter cc emails "
                  emails={emails}
                  onChange={(_emails) => {
                    setEmails(_emails);
                  }}
                  validateEmail={(email) => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </Col>
            </FormGroup>
            <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
              Submit
            </Button>
          </>
        )}
      </React.Fragment>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Business Unit</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="businessUnit-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
      {openLocationModal && (
        <BusinessUnitLocation
          id="businessUnitLocation-modal"
          title="BusinessUnitLocation"
          toggle={toggleLocationModal}
          open={openLocationModal}
          pocArray={locationPocArray}
          handleLocationChange={handleLocationChange}
          states={states}
        />
      )}
    </div>
  );
}
