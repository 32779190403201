import React from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
//import { ValidatorForm } from "react-form-validator-core";

export default function AddressComponent(props) {
  let mailing_street_name;
  if (props.type === "physical")
    mailing_street_name = props.type + "_street_no_and_name";
  else if (props.type === "mailing")
    mailing_street_name = props.type + "_street_name";
  else mailing_street_name = props.type + "_mailing_street";

  const mailing_name =
    props.type === "payment"
      ? props.type + "_address_care_of"
      : props.type + "_care_of";

  const mailing_city =
    props.type === "payment"
      ? props.type + "_mailing_city"
      : props.type + "_city";

  const mailing_state =
    props.type === "payment"
      ? props.type + "_mailing_state"
      : props.type + "_state";

  const mailing_zip =
    props.type === "payment"
      ? props.type + "_mailing_zipcode"
      : props.type + "_zip";
  return (
    <div>
      <div style={{ fontWeight: "bold", marginTop: "2%" }}>{props.heading}</div>
      <div className="address-body">
        <FormGroup row>
          <Label for="" sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
            {" "}
            Name
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              value={
                props.form === "mc"
                  ? props.managementCompany.name
                  : props.hoaName
              }
              disabled={true}
            />
          </Col>
        </FormGroup>
        {props.type !== "physical" && (
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              C/o Name
            </Label>
            <Col sm={10}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={{ borderRadius: "14px 0px 0px 14px" }}>
                    C/o
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="address-name"
                  type="text"
                  name={mailing_name}
                  id={mailing_name}
                  placeholder=""
                  onChange={props.handleChange}
                  //value={props.managementCompany[props.type + "_name"]}
                  value={props.managementCompany[mailing_name]}
                />
              </InputGroup>
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label for="" sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
            Address
          </Label>
          <Col sm={10}>
            <TextValidator
              type="text"
              name={mailing_street_name}
              id={mailing_street_name}
              placeholder=""
              onChange={props.handleChange}
              value={props.managementCompany[mailing_street_name]}
            />
          </Col>
        </FormGroup>
        <div>
          <Row form>
            <Col md={7} style={{ paddingLeft: "20px" }}>
              <FormGroup row>
                <Label
                  for=""
                  sm={3}
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  City
                </Label>
                <Col sm={9}>
                  <TextValidator
                    type="text"
                    name={mailing_city}
                    id={mailing_city}
                    placeholder=" city"
                    onChange={props.handleChange}
                    //value={props.managementCompany[props.type + "_city"]}
                    value={props.managementCompany[mailing_city]}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup row>
                <Label
                  for=""
                  sm={4}
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  State
                </Label>
                <Col sm={8}>
                  <TextValidator
                    type="text"
                    name={mailing_state}
                    id={mailing_state}
                    placeholder=" state"
                    onChange={props.handleChange}
                    //value={props.managementCompany[props.type + "_state"]}
                    value={props.managementCompany[mailing_state]}
                    maxLength="2"
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup row>
                <Label
                  for=""
                  sm={2}
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Zip
                </Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name={mailing_zip}
                    id={mailing_zip}
                    placeholder=" zip"
                    maxLength="10"
                    onChange={(event) => props.handleChange(event, "zipCode")}
                    validators={["isAddressZipcodeMatch"]}
                    errorMessages={["Invalid Zip Code"]}
                    //value={props.managementCompany[props.type + "_zip"]}
                    value={props.managementCompany[mailing_zip]}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
