import React, { Component, useState, useEffect } from "react";
import "../../../styles/_PropertyLeasingInfo.scss";
import apicall from "../../../utils/apicall";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import _ from "lodash";
import "../../../styles/_access-codes.scss";
import { FaEdit, FaLink, FaTrashAlt } from "react-icons/fa";

export default function PropertyAccessCodeInfo(props) {
  const [accessCodes, setaccessCodes] = useState([]);

  useEffect(() => {
    getAccessCodes();
  }, []);

  const getAccessCodes = (event, pageNo, perPage) => {
    apicall.getAcessCodes(props.hoaId, pageNo, perPage).then((response) => {
      setaccessCodes(response.results);
    });
  };

  const nameValueDiv = (name, value) => {
    if (value === null || typeof value === "undefined" || value === "Null")
      return null;

    return (
      <div className="accesss-codes">
        <div className="heading">{_.startCase(name)}</div>
        <div className="accesss-codes-values">{value}</div>
      </div>
    );
  };

  const codes = accessCodes.map((x, ix) => {
    return (
      <>
        {x.access_for === "building" && (
          <label style={{ fontWeight: "bold" }}>
            {"Community / Building Access"}
          </label>
        )}
        {x.access_for === "pool" && (
          <label style={{ fontWeight: "bold" }}>{"Pool Access"}</label>
        )}
        {x.access_for === "fitness" && (
          <label style={{ fontWeight: "bold" }}>
            {"Fitness Center Access"}
          </label>
        )}
        <div className="code" key={ix}>
          {nameValueDiv("access_to", _.startCase(x.access_to))}
          {nameValueDiv("access_control", _.startCase(x.access_control))}
          {nameValueDiv("access_contact_email", x.access_contact_email)}
          {nameValueDiv("access_contact_name", x.access_contact_name)}
          {nameValueDiv("access_contact_phone", x.access_contact_phone)}
          {nameValueDiv("access_control_cost", x.access_control_cost)}
          {nameValueDiv("access_description", x.access_description)}
          {nameValueDiv("notes", x.notes)}
          {nameValueDiv("code", x.code)}
          {x.documents[0] &&
            nameValueDiv(
              "access_form",
              <a
                href={x.documents[0].file_url}
                target="_blank"
                style={{ float: "right", color: "black" }}
              >
                <FaLink size="1em" />
              </a>
            )}
        </div>
      </>
    );
  });

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Access Code Details</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="access-codes-container">{codes}</div>
        </ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="association-modal"
        isOpen={props.modal}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
