import React, { Component, useState, useEffect } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import { Button, Col, Row } from "reactstrap";
import TabsComponent from "../helper_components/TabsComponent";
import "../../styles/_tab.scss";
import apicall from "../../utils/apicall";
import config from "../../utils/config";
import DocumentsTab from "./DocumentsTab";
import DocumentsModal from "./DocumentsModal";
import _ from "lodash";
//import HoaAsyncSelect from '../helper_components/HoaAsyncSelect'
//import AsyncMultiSelect from '../general_info/AsyncMultiSelect'
import Select from "react-select";
import "../../styles/_documents.scss";
export default (props) => {
  const [documents, set_Documents] = useState([]);
  const [activeTab, set_ActiveTab] = useState(1);
  const [
    openAssociationDocumentModal,
    set_openAssociationDocumentModal,
  ] = useState(false);
  const [openPropertyDocumentModal, set_openPropertyDocumentModal] = useState(
    false
  );
  const [hoaOptions, set_HOA] = useState();
  const [hoaID, set_HOA_ID] = useState(props.hoaID);
  const [associationDocs, setassociationDocs] = useState([]);
  const [muniDocs, setmuniDocs] = useState([]);

  useEffect(() => {
    //fetchDocuments();
    fetchAssociationDocuments();
    fetchPropertyDocuments();
  }, [hoaID]);

  useEffect(() => {
    fetchHoas();
    fetchAssociationDocuments();
    fetchPropertyDocuments();
    fetchMunicipalityDocuments();
  }, []);

  const fetchHoas = () => {
    apicall.getHoaByPropertyID(props.propertyID, 1, 20).then((response) => {
      let hoaOptions = [];
      hoaOptions = response.results.map((x) => {
        return { label: x.name, value: x.id };
      });
      set_HOA(hoaOptions);
      console.log(hoaOptions);
    });
  };

  const fetchDocuments = () => {
    apicall
      .propertyDocumentsFetch(props.propertyID, hoaID)
      .then((resp) => {
        set_Documents(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const fetchPropertyDocuments = () => {
    apicall
      .getPropertyDocuments(props.propertyID)
      .then((resp) => {
        set_Documents(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const fetchAssociationDocuments = () => {
    apicall
      .getPropertyAssociationDocuments(props.propertyID)
      .then((resp) => {
        setassociationDocs(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const fetchMunicipalityDocuments = () => {
    apicall
      .getPropertyMunicipalityDocuments(props.propertyID)
      .then((resp) => {
        setmuniDocs(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const deleteHoaDocument = (id, documentId) => {
    apicall.deleteHoaDoc(id, documentId).then((response) => {
      fetchAssociationDocuments();
    });
  };

  const requiredDocsNames = _.without(config.documentNames, "Others").map((x) =>
    x.toLowerCase()
  );

  // if(_.isEmpty(documents))
  // 	return <h4>No Documents</h4>

  const requiredDocs = !_.isEmpty(documents)
    ? documents.filter((x) =>
        _.includes(requiredDocsNames, x.name && x.name.toLowerCase())
      )
    : [];
  const miscDocs = !_.isEmpty(documents)
    ? documents.filter(
        (x) => !_.includes(requiredDocsNames, x.name && x.name.toLowerCase())
      )
    : [];

  const listofTabs = ["Required", "Misc"];
  // const tabsComponentsList = [
  //   <DocumentsTab
  //     propertyID={props.propertyID}
  //     documents={requiredDocs}
  //     fetchDocuments={fetchDocuments}
  //     required={true}
  //   />,
  //   <DocumentsTab
  //     propertyID={props.propertyID}
  //     documents={miscDocs}
  //     fetchDocuments={fetchDocuments}
  //   />,
  // ];

  return (
    <div>
      {/* <Row>
        <Col xs="6"> */}
      {/* <AsyncMultiSelect isHoa={true} propertyid={props.propertyID} documentFilter={true}
						handleOnChange = {
							(value) => {
								set_HOA(value)
								let hoaIDs = [];
								value.map(
									(hoa,index) => {
										hoaIDs.push(hoa.value)
									}
								)
								set_HOA_ID(hoaIDs)
							}
						}
					/> */}
      {/* <Select
            options={hoaOptions ? hoaOptions : []}
            isMulti={true}
            onChange={(value) => {
              let hoaIDs = [];
              value.map((hoa, index) => {
                hoaIDs.push(hoa.value);
              });
              set_HOA_ID(hoaIDs);
            }}
            placeholder="Select Hoa..."
            className="states-select"
          />
        </Col>
      </Row>
      <div
        style={{
          float: "right",
          "margin-right": "30px",
          "margin-left": "30px",
        }}
      >
        <Button onClick={() => set_openAddDocumentModal(true)}>Add</Button>
      </div>
      <TabsComponent
        theme={"general-info-tab"}
        components={tabsComponentsList}
        listofTabs={listofTabs}
        generateTabURL={false}
        activeTab={activeTab}
      /> */}

      <div className="document-manage">
        <div className="document-title">
          <h5 className="document-heading">Municipality Documents</h5>
          {/* <div className="add-button">
            <Button onClick={() => set_openPropertyDocumentModal(true)}>
              File Upload
            </Button>
          </div> */}
        </div>
      </div>
      <DocumentsTab
        propertyID={props.propertyID}
        documents={muniDocs}
        fetchDocuments={fetchMunicipalityDocuments}
        isMunicipalityDocs={true}
      />
      <hr />
      <div className="document-manage">
        <div className="document-title">
          <h5 className="document-heading">Association Documents</h5>
          <div className="add-button">
            <Button onClick={() => set_openAssociationDocumentModal(true)}>
              File Upload
            </Button>
          </div>
        </div>
      </div>
      <DocumentsTab
        propertyID={props.propertyID}
        documents={associationDocs}
        fetchDocuments={fetchAssociationDocuments}
        isAssociationModal={true}
        deleteDocument={deleteHoaDocument}
      />
      <hr />
      <div className="document-manage">
        <div className="document-title">
          <h5 className="document-heading">Property Documents</h5>
          <div className="add-button">
            <Button onClick={() => set_openPropertyDocumentModal(true)}>
              File Upload
            </Button>
          </div>
        </div>
      </div>
      <DocumentsTab
        propertyID={props.propertyID}
        documents={documents}
        fetchDocuments={fetchPropertyDocuments}
        isPropertyModal={true}
      />

      {openAssociationDocumentModal && (
        <DocumentsModal
          toggle={() => set_openAssociationDocumentModal(false)}
          title="Document"
          open={openAssociationDocumentModal}
          fetchDocuments={fetchAssociationDocuments}
          propertyID={props.propertyID}
          pageNo={1}
          hideDocumentName={true}
          hoaOptions={hoaOptions}
          isAssociationModal={true}
        />
      )}
      {openPropertyDocumentModal && (
        <DocumentsModal
          toggle={() => set_openPropertyDocumentModal(false)}
          title="Document"
          open={openPropertyDocumentModal}
          fetchDocuments={fetchPropertyDocuments}
          propertyID={props.propertyID}
          pageNo={1}
          hideDocumentName={true}
          //hoaOptions={hoaOptions}
          isPropertyModal={true}
        />
      )}
    </div>
  );
};
