import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
//import BootstrapTable from 'react-bootstrap-table-next';
import CreateAnalystModal from "./CreateAnalystModal";
//import { Button } from 'reactstrap';
//import apicall from "../../utils/apicall";
import PaginationComponent from "../helper_components/PaginationComponent";
import { FaEdit } from "react-icons/fa";
import helpers from "../../utils/helpers";
export default class AnalystListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAnalystsWindow: new Array(20).fill(false),
      rowIndex: 0,
      row: {},
      pageNo: 1,
      perPage: 10,
      currentAnalyst: null,
    };
  }

  updateUserList = () => {
    this.props.getAnalysts();
  };

  cellFormatter = (cell) => {
    if (cell !== "undefined" && cell !== null) {
      let textArray = cell.split("_");
      let formattedString = "";
      for (let text of textArray) {
        formattedString =
          formattedString + " " + helpers.capitalizeFirstLetter(text);
      }
      return formattedString;
    }
  };

  buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    // this.props.toggle()
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            var boolArray = this.state.modalAnalystsWindow;
            boolArray[rowIndex] = !boolArray[rowIndex];
            this.setState({
              modalAnalystsWindow: boolArray,
              rowIndex: rowIndex,
              row: row,
            });
          }}
        />
      </div>
    );
  };
  render() {
    // if (this.state.row == null) {
    //     console.log('render_AnalystLisTable',this.state.row,this.state.rowIndex)
    //     this.props.toggle()
    // }
    let analysts = this.props.analystArray;
    const columns = [
      {
        dataField: "name",
        text: "Name",
        formatter: this.cellFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "email",
        text: "Email",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "mobile",
        text: "Mobile",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "role",
        text: "Role",
        formatter: this.cellFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "status",
        text: "Status",
        formatter: this.cellFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "edit",
        text: "Edit",
        sort: false,
        formatter: this.buttonFormatter,
        headerAttrs: { width: 100 },
        attrs: { width: 100, class: "EditRow" },
        headerClasses: "table-header-cell-alignment",
      },
    ];

    return (
      <>
        <PaginationComponent
          columns={columns}
          rows={analysts}
          handleSubmit={this.props.getAnalysts}
          pageNo={this.props.pageNo}
          perPage={this.props.perPage}
          totalCount={this.props.totalCount}
        />
        <CreateAnalystModal
          toggle={() => {
            var modalAnalystsWindow = this.state.modalAnalystsWindow;
            modalAnalystsWindow[this.state.rowIndex] = !modalAnalystsWindow[
              this.state.rowIndex
            ];
            this.setState({
              modalAnalystsWindow: modalAnalystsWindow,
            });
          }}
          modal={this.state.modalAnalystsWindow[this.state.rowIndex]}
          analystArray={this.props.analystArray}
          row={this.state.row}
          updateData={false}
          rowIndex={this.state.rowIndex}
          refreshUserList={this.updateUserList}
        />
      </>
    );
  }
}
