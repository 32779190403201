import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
} from "reactstrap";

import apicall from "../../utils/apicall";
import "../../styles/_quickStatus.scss";
import Select from "react-select";
import Loader from "../helper_components/LoaderComponent";

export default function QuickStatus(props) {
  const [states, set_States] = useState();
  const [selectedStates, set_selectedStates] = useState([]);
  const [property_groups, set_property_groups] = useState([]);
  const [entity_names, set_entity_names] = useState([]);
  const [selectedProperty, set_selectedProperty] = useState([]);
  const [selectedentity, set_selectedentity] = useState([]);
  const [overallResults, setoverallResults] = useState({});
  const [activeResults, setactiveResults] = useState({});
  const [form_inputs, set_form_inputs] = useState({
    property_groups: [],
    entity_names: [],
    states: [],
  });
  const [isLoading, setisLoading] = useState(false);
  const [zipcodes, setzipcodes] = useState();

  const handleClickOutside = (event) => {
    props.toggle(event);
  };

  useEffect(() => {
    getQuickStatus();
  }, [
    form_inputs.property_groups,
    form_inputs.entity_names,
    form_inputs.states,
    // form_inputs.zipcodes,
    zipcodes,
  ]);

  useEffect(() => getStates(), [selectedStates]);
  useEffect(() => getEntity_names(), [selectedentity]);
  useEffect(() => getProperty_groups(), [selectedProperty]);

  const getStates = () => {
    apicall.getQuickStatusStates().then((response) => {
      let statesArray = [];
      statesArray = response.results.states.map((x) => {
        return { value: x, label: x };
      });
      set_States(statesArray);
    });
  };

  const getEntity_names = () => {
    apicall.getEntity_names().then((response) => {
      let statesArray = [];
      statesArray = response.results.map((x) => {
        return { value: x, label: x };
      });
      set_entity_names(statesArray);
    });
  };

  const getProperty_groups = () => {
    apicall.getProperty_groups().then((response) => {
      let statesArray = [];
      statesArray = response.results.map((x) => {
        return { value: x, label: x };
      });
      set_property_groups(statesArray);
    });
  };

  const getQuickStatus = () => {
    setisLoading(true);
    apicall.getQuickStatus(form_inputs, zipcodes).then((response) => {
      setoverallResults(response.results.overall);
      setactiveResults(response.results.active);
      setisLoading(false);
    });
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Quick Status</span>
            <span className="association-status">Associations: </span>
            <span className="association-status-value">
              {overallResults.associations_count}
            </span>
            <span className="association-status" style={{ marginLeft: "5rem" }}>
              Management Companies:{" "}
            </span>
            <span className="association-status-value">
              {overallResults.management_company_count}
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <Row form>
              <Col md={6}>
                <FormGroup row>
                  <Label for="" sm={1}>
                    Entity
                  </Label>
                  <Col sm={10}>
                    <Select
                      options={entity_names ? entity_names : []}
                      isMulti={true}
                      onChange={(value) => {
                        set_form_inputs({
                          ...form_inputs,
                          entity_names: value,
                        });
                      }}
                      placeholder="Select Entity..."
                      className="states-select"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup row>
                  <Label for="" sm={2}>
                    Property Group
                  </Label>
                  <Col sm={10}>
                    <Select
                      options={property_groups ? property_groups : []}
                      isMulti={true}
                      onChange={(value) => {
                        set_form_inputs({
                          ...form_inputs,
                          property_groups: value,
                        });
                      }}
                      placeholder="Select Property Group..."
                      className="states-select"
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div>
            <Row form>
              <Col md={6}>
                <FormGroup row>
                  <Label for="" sm={1}>
                    State
                  </Label>
                  <Col sm={10}>
                    <Select
                      options={states ? states : []}
                      isMulti={true}
                      onChange={(value) => {
                        set_form_inputs({
                          ...form_inputs,
                          states: value,
                        });
                      }}
                      placeholder="Select States..."
                      className="states-select"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup row>
                  <Label for="" sm={2}>
                    Zip Code
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="textarea"
                      name="zipCode"
                      id="zipcodes"
                      placeholder="Enter Zip"
                      onChange={(event) => {
                        setzipcodes(event.target.value);
                        // set_form_inputs({
                        //   ...form_inputs,
                        //   zipcodes: value,
                        // });
                      }}
                      style={{ height: "50px" }}
                      //value={this.state.zipcodes}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <React.Fragment>
            {isLoading ? (
              <Loader isQuickStatus={true} />
            ) : (
              <>
                <table className="quickStatusTable">
                  <tr>
                    <th></th>
                    <th className="quickStatusTableRow"> Properties</th>
                    <th className="quickStatusTableRow">
                      Properties with Associations
                    </th>
                    <th className="quickStatusTableRow">Unique Associations</th>
                    <th className="quickStatusTableRow">
                      Voluntary Associations
                    </th>
                    <th
                      className="quickStatusTableRow"
                      style={{ width: "15%" }}
                    >
                      Non-Association Payments
                    </th>
                  </tr>

                  <tr className="quickStatusTableRow">
                    <td style={{ color: "black", fontSize: "13px" }}>
                      {"Overall"}
                    </td>
                    <td className="quickStatusTableRow">
                      {overallResults.properties_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {overallResults.properties_with_associations_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {overallResults.unique_associations_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {overallResults.voluntary_associations_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {overallResults.non_association_payments_count}
                    </td>
                  </tr>

                  <tr className="quickStatusTableActiveRow">
                    <td style={{ color: "black", fontSize: "13px" }}>
                      {"Active"}
                    </td>
                    <td className="quickStatusTableRow">
                      {activeResults.properties_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {activeResults.properties_with_associations_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {activeResults.active_unique_associations_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {activeResults.voluntary_associations_count}
                    </td>
                    <td className="quickStatusTableRow">
                      {activeResults.non_association_payments_count}
                    </td>
                  </tr>
                </table>
              </>
            )}
          </React.Fragment>
        </ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="quickStats-modal"
        isOpen={props.modal}
        toggle={props.toggle}
        // backdrop={false}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
