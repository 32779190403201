import React, { Component } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import apicall from "../../utils/apicall";
import UpdatePropertyModal from "../properties/property-upload/CreatePropertyModal";
//import dayjs from 'dayjs'
import _ from "lodash";
import helpers from "../../utils/helpers";
import config from "../../utils/config";
//import config from "../../utils/config";
import PropertyLeasingInfo from "../general_info/lease_info/PropertyLeasingInfo";
import PropertyAccessCodeInfo from "../general_info/access_codes/PropertyAccessCodeInfo";

export default class PropertyInfo extends Component {
  constructor(props) {
    // console.log("props",props)
    super(props);
    this.state = {
      property: {},
      openUpdateModal: false,
      openLeasingModal: false,
      openAccessModal: false,
    };
  }

  componentWillReceiveProps(newprops) {
    // console.log("componentWillReceiveProps",newprops);
    this.setState({
      property: newprops.propertyInfo,
      masterHOA: newprops.masterHOA,
    });
  }

  reloadProperty = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      this.setState({
        property: response,
      });
    });
  };

  toggleUpdateModal = () => {
    this.setState({
      openUpdateModal: !this.state.openUpdateModal,
    });
  };

  toggleLeasingModal = () => {
    this.setState({
      openLeasingModal: !this.state.openLeasingModal,
    });
  };
  toggleAccessModal = () => {
    this.setState({
      openAccessModal: !this.state.openAccessModal,
    });
  };

  handleOnClick = () => {
    if (!this.props.isGuestUser) this.props.handleHistoryTab();
  };

  processLeasingPermitted = () => {
    let masterHOA = this.state.masterHOA;
    if (!_.has(masterHOA, "leasing_information")) return;
    if (masterHOA && masterHOA.leasing_info_applicable === false) {
      return "NO";
    } else if (!_.isEmpty(masterHOA.leasing_information)) {
      let restrictionChecked = false;
      let msg = "";
      let leasingInfo = masterHOA.leasing_information;
      let sectionTwoRestriction = config.leaseRestriction.sectionTwoRestriction;
      let sectionThreeRestriction =
        config.leaseRestriction.sectionThreeRestriction;
      for (let key of sectionTwoRestriction) {
        if (leasingInfo[key] == true) {
          restrictionChecked = true;
          msg = "NO - Lease Restricted";
          break;
        }
      }
      if (restrictionChecked) return msg;
      for (let key of sectionThreeRestriction) {
        if (leasingInfo[key] == true) {
          restrictionChecked = true;
          msg = "Yes - With Restrictions";
          break;
        }
      }
      if (restrictionChecked) return msg;
      return " Yes - No Restrictions";
    }
  };

  generateHOAStatus = () => {
    const renderAssocVerifStatus = () => {
      const associationverifications =
        this.props.actionArray &&
        !_.isEmpty(this.props.actionArray.results) &&
        this.props.actionArray.results.filter(
          (x) => x.actionable_type === "AssociationVerification"
        );
      if (!_.isEmpty(associationverifications)) {
        return associationverifications[0].current_status;
      }
    };
    return renderAssocVerifStatus();
  };
  generatePropertyInfoDiv = (property) => {
    // const renderAssocVerifStatus = () => {
    //   const associationverifications =
    //     this.props.actionArray &&
    //     !_.isEmpty(this.props.actionArray.results) &&
    //     this.props.actionArray.results.filter(
    //       (x) => x.actionable_type === "AssociationVerification"
    //     );
    //   if (!_.isEmpty(associationverifications)) {
    //     {
    //       let DIV =
    //         _.toLower(associationverifications[0].current_status) ===
    //         _.toLower("HOA Exists") ? (
    //           <div className="green">
    //             HOA Status: {associationverifications[0].current_status}
    //           </div>
    //         ) : (
    //           <div className="red">
    //             HOA Status: {associationverifications[0].current_status}
    //           </div>
    //         );

    //       return DIV;
    //     }
    //   } else if (this.props.isHoaStatus) {
    //     if (this.props.hoa_status === "HOA Exists")
    //       return (
    //         <div className="green">HOA Status: {this.props.hoa_status}</div>
    //       );
    //     else {
    //       return <div className="red">HOA Status: {this.props.hoa_status}</div>;
    //     }
    //   }
    // };

    return (
      <div className="last">
        {this.props.userRole === "admin" && (
          <button onClick={() => this.toggleUpdateModal()} className="edit-btn">
            Edit
          </button>
        )}

        {/* <div>
          Property State: <span>{_.toUpper(property.state)}</span>
        </div> */}
        <div className="child">
          <label className="property-label"> Service Status: </label>{" "}
          <div
            className={
              _.toLower(property.status) === "active" ? "green" : "red"
            }
          >
            {property.status
              ? helpers.capitalizeFirstLetter(property.status)
              : "---"}
          </div>
        </div>
        <div className="child">
          {" "}
          <label className="property-label">HBAM Property Status: </label>
          <div
            className={
              !_.isEmpty(_.toLower(property.hbam_property_status))
                ? "green"
                : "red"
            }
          >
            {property.hbam_property_status
              ? helpers.capitalizeFirstLetter(property.hbam_property_status)
              : "---"}
          </div>
        </div>
        <div className="child">
          {" "}
          <label className="property-label"> HBAM Property Stage: </label>
          <div
            className={
              !_.isEmpty(_.toLower(property.hbam_property_stage))
                ? "green"
                : "red"
            }
          >
            {property.hbam_property_stage
              ? helpers.capitalizeFirstLetter(property.hbam_property_stage)
              : "---"}
          </div>
        </div>
        <div className="child">
          {" "}
          <label className="property-label">HBPM Property Status: </label>
          <div
            className={
              !_.isEmpty(_.toLower(property.hbpm_status)) ? "green" : "red"
            }
          >
            {property.hbpm_status
              ? helpers.capitalizeFirstLetter(property.hbpm_status)
              : "---"}
          </div>
        </div>
        <div className="child">
          {" "}
          <label className="property-label"> Inc Status: </label>
          <div
            className={
              !_.isEmpty(_.toLower(property.inc_status)) ? "green" : "red"
            }
          >
            {property.inc_status
              ? helpers.capitalizeFirstLetter(property.inc_status)
              : "---"}
          </div>
        </div>
        {/* {renderAssocVerifStatus()} */}
      </div>
    );
  };

  render() {
    if (
      this.state.property !== null &&
      typeof this.state.property !== "undefined" &&
      Object.keys(this.state.property).length !== 0
    ) {
      let property = this.state.property;

      let PropertyInfoDiv = this.generatePropertyInfoDiv(property);
      let addressUrl = helpers.formatAddress(
        property,
        config.propertyManage.addressFields,
        config.propertyManage.extraField
      );
      return (
        <React.Fragment>
          <UpdatePropertyModal
            toggle={this.toggleUpdateModal}
            modal={this.state.openUpdateModal}
            property={property}
            updateProperty={true}
            reloadProperty={this.reloadProperty}
          />
          {this.state.openLeasingModal && (
            <PropertyLeasingInfo
              toggle={this.toggleLeasingModal}
              modal={this.state.openLeasingModal}
              leasingInfo={
                this.props.isGuestUser
                  ? this.props.leasing_info_applicable
                  : !_.isEmpty(this.state.masterHOA)
                  ? this.state.masterHOA.leasing_info_applicable
                  : ""
              }
              hoaId={
                this.props.isGuestUser
                  ? this.props.hoaId
                  : !_.isEmpty(this.state.masterHOA)
                  ? this.state.masterHOA.id
                  : ""
              }
            />
          )}
          {this.state.openAccessModal && (
            <PropertyAccessCodeInfo
              toggle={this.toggleAccessModal}
              modal={this.state.openAccessModal}
              hoaId={
                this.props.isGuestUser
                  ? this.props.hoaId
                  : this.state.masterHOA.id
              }
            />
          )}
          <div className={"property-details " + this.props.className}>
            <div className="property-data">
              <div className="first">
                <div onClick={this.handleOnClick} style={{ marginTop: "-5px" }}>
                  {!this.props.hideLink ? (
                    <a
                      href={`/property-manage/${this.props.listofTabs.length}/${property.id}/0`}
                    >
                      {property.clientid}
                    </a>
                  ) : (
                    property.clientid
                  )}
                </div>
                <div>{property.entity_name}</div>
                <div>
                  <a
                    href={`https://www.google.com/maps/place/${addressUrl}`}
                    target="_blank"
                  >
                    {/* {helpers.formatAddress(
                      property,
                      config.propertyManage.addressFields,
                      config.propertyManage.extraField
                    )} */}
                    {addressUrl}
                  </a>
                </div>
                <div>
                  Acquisition Date :{" "}
                  {helpers.formatDate(property.acquisition_date)}
                </div>
                <div>
                  Transfer Date: {helpers.formatDate(property.transfer_date)}
                </div>
                <div>Acquisition Type : {property.acquisition_type} </div>
                <div><p>Parcel ID /Block & Lot : {property.parcelid_block_lot} </p></div>
              </div>
              <div className="center">
                {this.props.hoa_status ? (
                  <div className="child">
                    <label className="property-label">
                      Association Status:{" "}
                    </label>
                    {this.props.hoa_status === "HOA Exists" ? (
                      <div
                        style={{
                          color: "green",
                        }}
                      >
                        {this.props.hoa_status}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        {this.props.hoa_status}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="child">
                    <label className="property-label">
                      Association Status:{" "}
                    </label>
                    {this.generateHOAStatus() === "HOA Exists" ? (
                      <div
                        className="property-content"
                        style={{
                          color: "green",
                        }}
                      >
                        {this.generateHOAStatus()}
                      </div>
                    ) : (
                      <div
                        className="property-content"
                        style={{
                          color: "red",
                        }}
                      >
                        {this.generateHOAStatus()}
                      </div>
                    )}
                  </div>
                )}

                <div className="child">
                  <label className="property-label">Leasing Permitted :</label>
                  {this.props.isGuestUser ? (
                    <span
                      className="property-content"
                      style={{
                        cursor: "pointer",
                        color:
                          this.props.leasing_permitted === null ||
                          !_.isEmpty(
                            this.props.leasing_permitted &&
                              this.props.leasing_permitted.slice(0, 2)
                          ) === "No"
                            ? "red"
                            : "blue",
                      }}
                      onClick={() => {
                        this.setState({ openLeasingModal: true });
                      }}
                    >
                      {this.props.leasing_permitted}
                    </span>
                  ) : (
                    <div>
                      {" "}
                      <span
                        className="property-content"
                        style={{
                          cursor: "pointer",
                          color:
                            property.leasing_permitted === null ||
                            property.leasing_permitted.slice(0, 2) === "No"
                              ? "red"
                              : "blue",
                        }}
                        onClick={() => {
                          this.setState({ openLeasingModal: true });
                        }}
                      >
                        {property.leasing_permitted}
                      </span>
                    </div>
                  )}
                </div>
                <div className="child">
                  <label className="property-label">Access Control :</label>
                  <div
                    className="property-content"
                    style={{
                      cursor: property.access_control === true && "pointer",
                      color: property.access_control === true ? "blue" : "red",
                    }}
                    onClick={() => {
                      property.access_control === true &&
                        this.setState({ openAccessModal: true });
                    }}
                  >
                    {property.access_control === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="child">
                  <label className="property-label">Legal :</label>
                  <div className="property-content">{property.hbam_legal_status}</div>
                </div>
                <div className="utility">
                  <span className="utilityItem electricity">E</span>
                  <span className="utilityItem gas">G</span>
                  <span className="utilityItem water">W</span>
                  <span className="utilityItem sewage">S</span>
                  <span className="utilityItem trash">T</span>
                </div>
              </div>
              {PropertyInfoDiv}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
