import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "../../styles/action.css";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent"
import _ from "lodash";

export default function UnderwritingUtilitiesAction(props) {
    const [propertyID, setpropertyID] = useState(props.propertyid ? props.propertyid : "");
    const [uderUtilitiesArray,setunderUtilitiesArray]=useState([]);
    const [onetime_fees_utilitiesArray,setonetime_fees_utilitiesArray]=useState([]);
    const [isLoading,setisLoading]=useState(true);    
    const [totalUtility,settotalUtility]=useState([]);
    

    useEffect(() => {
        listUnderUtilities();
        listUnderUtilitiesOnetime_fees();
      }, [propertyID]);
    
    const listUnderUtilities = () => {
        
        apicall
        .listUnderUtilities(propertyID)
        .then((response) => {
          
          setunderUtilitiesArray(response)
          setisLoading(false)
        })
        .catch((e) => console.log(e));
      };

      const listUnderUtilitiesOnetime_fees = () => {
        
        apicall
        .listUnderUtilitiesOnetime_fees(propertyID)
        .then((response) => {
          setonetime_fees_utilitiesArray(response)
          setisLoading(false)
        })
        .catch((e) => console.log(e));
      };

      const utilitiesdataCharge = (cell, row, rowIndex, formatExtraData) => {
        let data;
        if (!_.isNumber(cell) && _.isEmpty(cell)  ) data= 0;
        else data= parseFloat(cell).toFixed(2);
        return <div style={{textAlign:"right"}}>{data}</div>

      };

      const recurringFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
          <div style={{ textAlign: "left" ,fontWeight:"bold"}}>
           {cell}
          </div>
        );
      };

      const totalCalculationFormatter= (cell, row, rowIndex, formatExtraData) => {
        let total=0;
        total=row.ELCharges+row.NGCharges+row.WACharges+row.SECharges+row.TRCharges;
        total= Number(total).toFixed(2);
        settotalUtility(totalUtility=>[...totalUtility,total]);
        return (
          <div style={{ textAlign: "right" }}>
           {"$"+total}
          </div>
        );
      };
    
   
    const utilitiesColumns = [
        {
          dataField: "recurring",
          text: "Recurring",
          formatter:recurringFormatter,
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "ELCharges",
          text: "Electricity",
          formatter:utilitiesdataCharge,
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "NGCharges",
          text: "Natural Gas",
          formatter:utilitiesdataCharge,
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "WACharges",
          text: "Water",
          formatter:utilitiesdataCharge,
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "SECharges",
          text: "Sewer",
          formatter:utilitiesdataCharge,
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
            dataField: "TRCharges",
            text: "Trash",
            formatter:utilitiesdataCharge,
            headerClasses: "header-cell-alignment",
            headerStyle: (colum, colIndex) => {
              return { width: "10%" };
            },
          },
          {
            dataField: "total",
            text: "Total",
            formatter:totalCalculationFormatter,
            headerClasses: "header-cell-alignment",
            headerStyle: (colum, colIndex) => {
              return { width: "10%" };
            },
          },
      ];

      const entityProviderColumns = [
        
        {
          dataField: "entity",
          text: "Entity",
          formatter:recurringFormatter,
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "service_type",
          text: "Service Type",
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "one_time_fee",
          text: "One Time Fee",
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "amount",
          text: "Amount",
          headerClasses: "header-cell-alignment",
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        
      ];
      const sum = totalUtility.reduce((partialSum, a) => partialSum + a, " ");
      
    return (
        <React.Fragment>
            {isLoading && <Loader/>}
         {uderUtilitiesArray && (
        <div style={{width:"80%"}}>
           <BootstrapTable
              keyField="id"
              data={uderUtilitiesArray}
              columns={utilitiesColumns}
            />
            </div>
          )}
          <div style={{width:"80%"}}>
        <div></div> 
        <div style={{width:"15%",float:"right"}}>
           <FormGroup row>
          <Label for="">
            Total
            </Label>
            <Col sm={8}>
            <Input
              type="text"
              id="total"
              name="total"
            value={"$"+parseFloat(sum).toFixed(2)}
            style={{textAlign:"right"}}
            >
            </Input>
          </Col>
        </FormGroup>
        </div></div>
       {onetime_fees_utilitiesArray && (   
        <div style={{width:"80%"}}>
         <BootstrapTable
              keyField="id"
              data={onetime_fees_utilitiesArray}
              columns={entityProviderColumns}
            />
            </div>
          )}
           <div style={{width:"80%"}}>
        <div></div> 
        <div style={{width:"15%",float:"right"}}>
           <FormGroup row>
          <Label for="">
            Total
            </Label>
            <Col sm={8}>
            <Input
              type="text"
              id="total"
              name="total"
              //value={formdata.muni_type}
            >
            </Input>
          </Col>
        </FormGroup>
        </div></div>
              </React.Fragment>
    );
  
}
