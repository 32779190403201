import React, { useState, useEffect } from "react";
import "../../styles/_newDashboard.scss";
import Loader from "../helper_components/LoaderComponent";
import _ from "lodash";

export default function PendingStatsDashboard(props) {
  const [loading, set_loading] = useState(true);
  const [toggleModal, settoggleModal] = useState(false);
  const [associationtierOne, setassociationtierOne] = useState("");
  const [associationtierTwo, setassociationtierTwo] = useState("");
  const [associationtierThree, setassociationtierThree] = useState("");
  const [associationtierFour, setassociationtierFour] = useState("");
  const [dashBoardData, setdashBoardData] = useState();

  const [general_infoData, setgeneral_infoData] = useState();
  const [associationDocumentsData, setassociationDocumentsData] = useState();
  const [leasingFields, setleasingFields] = useState();
  const [amenityFields, setamenityFields] = useState();
  const [inspectionFields, setinspectionFields] = useState();
  const [accessControlFields, setaccessControlFields] = useState();
  const [assessmentFields, setassessmentFields] = useState();

  const [verificationFields, setverificationFields] = useState();
  const [accountReviewFields, setaccountReviewFields] = useState();
  const [generalPaymentFields, setgeneralPaymentFields] = useState();
  const [fyAssessmentFields, setfyAssessmentFields] = useState();
  const [fyAssessmentRightFields, setfyAssessmentRightFields] = useState();
  const [accountReviewRightFields, setaccountReviewRightFields] = useState();
  const [generalPaymentRightFields, setgeneralPaymentRightFields] = useState();
  const [propertyReviewFields, setpropertyReviewFields] = useState();

  useEffect(() => {
    getDashboardPendingDetails();
  }, [props.pendingDashboardFilterData]);

  const getDashboardPendingDetails = () => {
    setdashBoardData(props.pendingDashboardFilterData);
    if (
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.general_info
      )
    ) {
      setgeneral_infoData(
        props.pendingDashboardFilterData.association_review.general_info
          .left_fields
      );
    }

    if (
      !_.isEmpty(props.pendingDashboardFilterData.association_review.document)
    ) {
      setassociationDocumentsData(
        props.pendingDashboardFilterData.association_review.document.left_fields
      );
    }

    if (
      !_.isEmpty(props.pendingDashboardFilterData.association_review.leasing) &&
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.leasing.left_fields
      )
    ) {
      setleasingFields(
        props.pendingDashboardFilterData.association_review.leasing.left_fields
      );
    }

    if (
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.access_control
      ) &&
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.access_control
          .left_fields
      )
    ) {
      setaccessControlFields(
        props.pendingDashboardFilterData.association_review.access_control
          .left_fields
      );
    }

    if (
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.assessment
      ) &&
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.assessment
          .left_fields
      )
    ) {
      setassessmentFields(
        props.pendingDashboardFilterData.association_review.assessment
          .left_fields
      );
    }

    if (
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.inspection
      ) &&
      !_.isEmpty(
        props.pendingDashboardFilterData.association_review.inspection
          .left_fields
      )
    ) {
      setinspectionFields(
        props.pendingDashboardFilterData.association_review.inspection
          .left_fields
      );
    }

    if (
      !_.isEmpty(props.pendingDashboardFilterData.association_review.amenity)
    ) {
      setamenityFields(
        props.pendingDashboardFilterData.association_review.amenity.left_fields
      );
    }
    if (
      !_.isEmpty(props.pendingDashboardFilterData.association_review.inspection)
    ) {
      setinspectionFields(
        props.pendingDashboardFilterData.association_review.inspection
          .left_fields
      );
    }

    if (!_.isEmpty(props.pendingDashboardFilterData.verification.fields))
      setverificationFields(
        props.pendingDashboardFilterData.verification.fields
      );

    if (
      !_.isEmpty(props.pendingDashboardFilterData.general_payment.left_fields)
    ) {
      setgeneralPaymentFields(
        props.pendingDashboardFilterData.general_payment.left_fields
      );
    }
    if (
      !_.isEmpty(props.pendingDashboardFilterData.general_payment.right_fields)
    ) {
      setgeneralPaymentRightFields(
        props.pendingDashboardFilterData.general_payment.right_fields
      );
    }

    if (
      !_.isEmpty(props.pendingDashboardFilterData.account_review.left_fields)
    ) {
      setaccountReviewFields(
        props.pendingDashboardFilterData.account_review.left_fields
      );
    }
    if (
      !_.isEmpty(props.pendingDashboardFilterData.account_review.right_fields)
    ) {
      setaccountReviewRightFields(
        props.pendingDashboardFilterData.account_review.right_fields
      );
    }

    if (
      !_.isEmpty(props.pendingDashboardFilterData.fy_assessment.left_fields)
    ) {
      setfyAssessmentFields(
        props.pendingDashboardFilterData.fy_assessment.left_fields
      );
    }
    if (
      !_.isEmpty(props.pendingDashboardFilterData.fy_assessment.right_fields)
    ) {
      setfyAssessmentRightFields(
        props.pendingDashboardFilterData.fy_assessment.right_fields
      );
    }
    if (
      !_.isEmpty(props.pendingDashboardFilterData.property_review.left_fields)
    )
      setpropertyReviewFields(
        props.pendingDashboardFilterData.property_review.left_fields
      );
    set_loading(false);
    settoggleModal(true);
  };

  const generateDashboardTierOne = (
    name,
    filterValue,
    isAssociationreview,
    tierCount
  ) => {
    return (
      <div
        style={{
          backgroundColor: associationtierOne === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierOne(filterValue);
          props.filterTierTwo("");
          props.filterTierThree("");
          props.filterTierFour("");
          setassociationtierOne(name);
          setassociationtierTwo("");
          setassociationtierThree("");
          setassociationtierFour("");
          props.filterPaymentRightSideValue("");
          if (isAssociationreview) props.filterName(filterValue);
        }}
      >
        <div className="card-firstRow">{tierCount.total}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount.left_count}</div>
          <div className="secondRowData">{tierCount.right_count}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateDashboardTierTwo = (
    name,
    filterValue,
    isAssociationreview,
    tierCount
  ) => {
    let total;
    if (
      filterValue === "amenity" ||
      filterValue === "assessment" ||
      filterValue === "document" ||
      filterValue === "inspection" ||
      filterValue === "utility"
    )
      total = tierCount.total_hoa_count;
    else if (
      filterValue === "access_control" ||
      filterValue === "general_info" ||
      filterValue === "leasing"
    )
      total = tierCount.total_count;
    else total = tierCount.total;
    return (
      <div
        style={{
          backgroundColor: associationtierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierTwo(filterValue);
          props.filterTierThree("");
          props.filterTierFour("");
          setassociationtierTwo(name);
          setassociationtierThree("");
          setassociationtierFour("");
          props.filterPaymentRightSideValue("");
          if (isAssociationreview) props.filterName(filterValue);
        }}
      >
        <div className="card-firstRow">{total}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount.left_count}</div>
          <div className="secondRowData">{tierCount.right_count}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateAssociationReviewTierThreeDashboard = (
    name,
    filterTierValue,
    filterName,
    isAssociationreview,
    tierCount
  ) => {
    return (
      <div
        style={{
          backgroundColor:
            associationtierThree === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierThree(filterTierValue);
          props.filterTierFour("");
          setassociationtierThree(name);
          setassociationtierFour("");
          props.filterPaymentRightSideValue("");
          if (isAssociationreview) props.filterName(filterName);
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount}</div>
          <div className="secondRowData">{""}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateAssociationVerificationTierTwoDashboard = (
    name,
    filterTierValue,
    tierCount
  ) => {
    return (
      <div
        style={{
          backgroundColor: associationtierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierTwo(filterTierValue);
          props.filterTierThree("");
          props.filterTierFour("");
          setassociationtierTwo(name);
          setassociationtierThree("");
          setassociationtierFour("");
          props.filterPaymentRightSideValue("");
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount}</div>
          <div className="secondRowData">{""}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generatePaymentTierTwoDashboard = (
    name,
    tierLeftCount,
    tierRightCount
  ) => {
    return (
      <div
        style={{
          backgroundColor: associationtierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierTwo(name);
          props.filterTierThree("");
          props.filterTierFour("");
          setassociationtierTwo(name);
          setassociationtierThree("");
          setassociationtierFour("");
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div
            className="firstRowData"
            onClick={() => {
              props.filterPaymentRightSideValue("");
            }}
          >
            {tierLeftCount}
          </div>
          <div
            className="secondRowData"
            onClick={() => {
              props.filterPaymentRightSideValue("right");
            }}
          >
            {tierRightCount}
          </div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateAccessControlThreeDashboard = (
    name,
    filterTierValue,
    filterName,
    isAssociationreview,
    tierCount
  ) => {
    return (
      <div
        style={{
          backgroundColor:
            associationtierThree === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierThree(filterTierValue);
          props.filterTierFour("");
          setassociationtierThree(name);
          setassociationtierFour("");
          props.filterPaymentRightSideValue("");
          if (isAssociationreview) props.filterName(filterName);
        }}
      >
        <div className="card-firstRow">{tierCount.total_count}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount.left_count}</div>
          <div className="secondRowData">{tierCount.right_count}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateAssociationReviewTierFourDashboard = (
    name,
    filterTierValue,
    filterName,
    isAssociationreview,
    tierCount
  ) => {
    return (
      <div
        style={{
          backgroundColor: associationtierFour === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierFour(filterTierValue);
          setassociationtierFour(name);
          props.filterPaymentRightSideValue("");
          if (isAssociationreview) props.filterName(filterName);
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount}</div>
          <div className="secondRowData">{""}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      {toggleModal && (
        <>
          <div className="flex-container" style={{ width: "95%" }}>
            <div
              style={{
                backgroundColor:
                  associationtierOne === "Association Verification"
                    ? "#866D4B"
                    : "#500000",
              }}
            >
              <div className="card-firstRow">{/* {association_review} */}</div>
              {/* <hr className="horizontalClass"/> */}
              <div className="middleRow" style={{ marginTop: "2rem" }}>
                <div
                  className="firstRowData"
                  style={{ width: "100%", border: "none" }}
                  onClick={() => {
                    props.filterTierOne("verification");
                    props.filterTierTwo("");
                    props.filterTierThree("");
                    props.filterTierFour("");
                    setassociationtierOne("Association Verification");
                    setassociationtierTwo("");
                    setassociationtierThree("");
                    setassociationtierFour("");
                    props.filterPaymentRightSideValue("");
                  }}
                >
                  {dashBoardData.verification.total}
                </div>
                {/* <div className="secondRowData" >
                  {right_count}
                </div> */}
              </div>
              <hr className="horizontalClass" />
              <div className="flex-heading">{" Association Verification"}</div>
            </div>

            {generateDashboardTierOne(
              "Association Review",
              "association_review",
              true,
              dashBoardData.association_review
            )}
            {generateDashboardTierOne(
              "Property Review",
              "property_review",
              false,
              dashBoardData.property_review
            )}
            {generateDashboardTierOne(
              "Account Review",
              "account_review",
              false,
              dashBoardData.account_review
            )}
            {generateDashboardTierOne(
              "FY Assessment",
              "fy_assessment",
              false,
              dashBoardData.fy_assessment
            )}
            {generateDashboardTierOne(
              "General Payment",
              "general_payment",
              false,
              dashBoardData.general_payment
            )}
          </div>
          {associationtierOne === "Association Review" &&
            !_.isEmpty(dashBoardData.association_review.general_info) && (
              <div className="flex-container" style={{ width: "96%" }}>
                {generateDashboardTierTwo(
                  "Gen Info",
                  "general_info",
                  true,
                  dashBoardData.association_review.general_info
                )}
                {generateDashboardTierTwo(
                  "Leasing",
                  "leasing",
                  true,
                  dashBoardData.association_review.leasing
                )}
                {generateDashboardTierTwo(
                  "Amenities",
                  "amenity",
                  true,
                  dashBoardData.association_review.amenity
                )}
                {generateDashboardTierTwo(
                  "Access",
                  "access_control",
                  true,
                  dashBoardData.association_review.access_control
                )}
                {generateDashboardTierTwo(
                  "Inspections",
                  "inspection",
                  true,
                  dashBoardData.association_review.inspection
                )}
                {generateDashboardTierTwo(
                  "Utility",
                  "utility",
                  true,
                  dashBoardData.association_review.utility
                )}
                {generateDashboardTierTwo(
                  "Assessments",
                  "assessment",
                  true,
                  dashBoardData.association_review.assessment
                )}
                {generateDashboardTierTwo(
                  "Documents",
                  "document",
                  true,
                  dashBoardData.association_review.document
                )}
              </div>
            )}
          {associationtierTwo === "Gen Info" && !_.isEmpty(general_infoData) && (
            <div className="flex-container" style={{ width: "95%" }}>
              {generateAssociationReviewTierThreeDashboard(
                "Status",
                "status",
                "general_info",
                true,
                general_infoData.status
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Management Company",
                "management_company",
                "general_info",
                true,
                general_infoData.management_company
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Physical Address",
                "physical_street_no_and_name",
                "general_info",
                true,
                general_infoData.physical_street_no_and_name
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Local Mailing Address",
                "mailing_street_name",
                "general_info",
                true,
                general_infoData.mailing_street_name
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Payment Address",
                "payment_mailing_street",
                "general_info",
                true,
                general_infoData.payment_mailing_street
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Architectural Review",
                "arch_review_committee",
                "general_info",
                true,
                general_infoData.arch_review_committee
              )}
            </div>
          )}
          {associationtierTwo === "Documents" &&
            !_.isEmpty(associationDocumentsData) && (
              <div className="flex-container" style={{ width: "96%" }}>
                {generateAssociationReviewTierThreeDashboard(
                  "CC&Rs",
                  "cc_rs",
                  "document",
                  true,
                  associationDocumentsData.cc_rs
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Bylaws",
                  "by_laws",
                  "document",
                  true,
                  associationDocumentsData.bylaws
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "ARC Guidelines / Form",
                  "architectural_guidelines",
                  "document",
                  true,
                  associationDocumentsData.arc_guidelines ||
                    associationDocumentsData.arc_guidelines_form
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Tenant Registration Form",
                  "tenant_registration_form",
                  "document",
                  true,
                  associationDocumentsData.tenant_registration_form
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Move-in Checklist",
                  "move_in_checklist_form",
                  "document",
                  true,
                  associationDocumentsData.move_in_checklist
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Parking Permit Form",
                  "parking_permit_form",
                  "document",
                  true,
                  associationDocumentsData.parking_permit_form
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Pool Registration Form",
                  "pool_registration_form",
                  "document",
                  true,
                  associationDocumentsData.pool_registration_form
                )}
              </div>
            )}

          {associationtierTwo === "Leasing" && !_.isEmpty(leasingFields) && (
            <div className="flex-container" style={{ width: "95%" }}>
              {generateAssociationReviewTierThreeDashboard(
                "Leasing Allowed",
                "pending_first_question",
                "leasing",
                true,
                leasingFields.pending_first_question_count
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Critical Rent Restrictions",
                "pending_followup_question",
                "leasing",
                true,
                leasingFields.pending_followup_question_count
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Misc. Leasing",
                "pending_misc_question",
                "leasing",
                true,
                leasingFields.pending_misc_question_count
              )}
            </div>
          )}

          {associationtierTwo === "Access" && !_.isEmpty(accessControlFields) && (
            <div className="flex-container" style={{ width: "95%" }}>
              {generateAccessControlThreeDashboard(
                "Building Access",
                "building",
                "access_control_building",
                true,
                accessControlFields.pending_building_access_codes
              )}
              {generateAccessControlThreeDashboard(
                "Pool Access",
                "pool",
                "access_control_pool",
                true,
                accessControlFields.pending_pool_access_codes
              )}
              {generateAccessControlThreeDashboard(
                "Fitness Access",
                "fitness",
                "access_control_fitness",
                true,
                accessControlFields.pending_fitness_access_codes
              )}
              {generateAccessControlThreeDashboard(
                "Tennis Access",
                "tennis",
                "access_control_tennis",
                true,
                accessControlFields.pending_tennis_access_codes
              )}
              {generateAccessControlThreeDashboard(
                "Club House Access",
                "club_house",
                "access_control_club_house",
                true,
                accessControlFields.pending_club_house_access_codes
              )}
            </div>
          )}

          {associationtierThree === "Building Access" &&
            !_.isEmpty(accessControlFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierFourDashboard(
                  "Access Control(Y/N)",
                  "pending_access_controlled",
                  "access_control_building",
                  true,
                  accessControlFields.pending_building_access_codes.left_fields
                    .pending_access_controlled
                )}
                {generateAssociationReviewTierFourDashboard(
                  "Access Questions",
                  "pending_access_questions",
                  "access_control_building",
                  true,
                  accessControlFields.pending_building_access_codes.left_fields
                    .pending_access_questions
                )}
                {generateAssociationReviewTierFourDashboard(
                  "Access Creation",
                  "pending_access_creation",
                  "access_control_building",
                  true,
                  accessControlFields.pending_building_access_codes.left_fields
                    .pending_access_creation
                )}
              </div>
            )}
          {associationtierThree === "Pool Access" &&
            !_.isEmpty(accessControlFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierFourDashboard(
                  "Access Control(Y/N)",
                  "pending_access_controlled",
                  "access_control_pool",
                  true,
                  accessControlFields.pending_pool_access_codes.left_fields
                    .pending_access_controlled
                )}
                {generateAssociationReviewTierFourDashboard(
                  "Access Creation",
                  "pending_access_creation",
                  "access_control_pool",
                  true,
                  accessControlFields.pending_pool_access_codes.left_fields
                    .pending_access_creation
                )}
              </div>
            )}

          {associationtierThree === "Fitness Access" &&
            !_.isEmpty(accessControlFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierFourDashboard(
                  "Access Control(Y/N)",
                  "pending_access_controlled",
                  "access_control_fitness",
                  true,
                  accessControlFields.pending_fitness_access_codes.left_fields
                    .pending_access_controlled
                )}
                {generateAssociationReviewTierFourDashboard(
                  "Access Creation",
                  "pending_access_creation",
                  "access_control_fitness",
                  true,
                  accessControlFields.pending_fitness_access_codes.left_fields
                    .pending_access_creation
                )}
              </div>
            )}

          {associationtierThree === "Tennis Access" &&
            !_.isEmpty(accessControlFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierFourDashboard(
                  "Access Control(Y/N)",
                  "pending_access_controlled",
                  "access_control_tennis",
                  true,
                  accessControlFields.pending_tennis_access_codes.left_fields
                    .pending_access_controlled
                )}
                {generateAssociationReviewTierFourDashboard(
                  "Access Creation",
                  "pending_access_creation",
                  "access_control_tennis",
                  true,
                  accessControlFields.pending_tennis_access_codes.left_fields
                    .pending_access_creation
                )}
              </div>
            )}

          {associationtierThree === "Club House Access" &&
            !_.isEmpty(accessControlFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierFourDashboard(
                  "Access Control(Y/N)",
                  "pending_access_controlled",
                  "access_control_club_house",
                  true,
                  accessControlFields.pending_club_house_access_codes
                    .left_fields.pending_access_controlled
                )}

                {generateAssociationReviewTierFourDashboard(
                  "Access Creation",
                  "pending_access_creation",
                  "access_control_club_house",
                  true,
                  accessControlFields.pending_club_house_access_codes
                    .left_fields.pending_access_creation
                )}
              </div>
            )}

          {associationtierTwo === "Assessments" &&
            !_.isEmpty(assessmentFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierThreeDashboard(
                  "Assessment Description",
                  "assessment_description",
                  "assessment",
                  true,
                  assessmentFields.pending_assessment_description_count
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Charges",
                  "charge",
                  "assessment",
                  true,
                  assessmentFields.pending_charge_count
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "FY Start",
                  "fy_start",
                  "assessment",
                  true,
                  assessmentFields.pending_fy_start_count
                )}
              </div>
            )}

          {associationtierTwo === "Inspections" &&
            !_.isEmpty(inspectionFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generateAssociationReviewTierThreeDashboard(
                  "Pending Inspections",
                  "pending_inspection",
                  "inspection",
                  true,
                  inspectionFields.pending_inspection_count
                )}
                {generateAssociationReviewTierThreeDashboard(
                  "Periodicity",
                  "pending_periodicity",
                  "inspection",
                  true,
                  inspectionFields.pending_periodicity_count
                )}
              </div>
            )}

          {associationtierTwo === "Amenities" && !_.isEmpty(amenityFields) && (
            <div className="flex-container" style={{ width: "95%" }}>
              {generateAssociationReviewTierThreeDashboard(
                "Pool",
                "pending_pool",
                "amenity",
                true,
                amenityFields.pending_pool_count
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Fitness",
                "pending_fitness_center",
                "amenity",
                true,
                amenityFields.pending_fitness_count
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Tennis",
                "pending_tennis_court",
                "amenity",
                true,
                amenityFields.pending_tennis_count
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Club House",
                "pending_club_house",
                "amenity",
                true,
                amenityFields.pending_club_house_count
              )}
              {generateAssociationReviewTierThreeDashboard(
                "Golf Court",
                "pending_golf_course",
                "amenity",
                true,
                amenityFields.pending_golf_court_count
              )}
            </div>
          )}

          {associationtierOne === "Association Verification" &&
            !_.isEmpty(verificationFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {verificationFields.pending && (
                  <>
                    {generateAssociationVerificationTierTwoDashboard(
                      "Pending",
                      "Pending",
                      verificationFields.pending
                    )}
                  </>
                )}
                {verificationFields.pending_vendor && (
                  <>
                    {generateAssociationVerificationTierTwoDashboard(
                      "Pending Vendor",
                      "Pending – Vendor",
                      verificationFields.pending_vendor
                    )}
                  </>
                )}
                {verificationFields.verifying_hoa_exists && (
                  <>
                    {generateAssociationVerificationTierTwoDashboard(
                      "Verifying HOA Exists",
                      "Verifying HOA Exists",
                      verificationFields.verifying_hoa_exists
                    )}
                  </>
                )}
                {verificationFields.verifying_no_hoa_exists && (
                  <>
                    {generateAssociationVerificationTierTwoDashboard(
                      "Verifying NO HOA Exists",
                      "Verifying NO HOA Exists",
                      verificationFields.verifying_no_hoa_exists
                    )}
                  </>
                )}
              </div>
            )}

          {associationtierOne === "Account Review" &&
            !_.isEmpty(accountReviewFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generatePaymentTierTwoDashboard(
                  "New Payment",
                  accountReviewFields.new_payment,
                  accountReviewRightFields.new_payment
                )}
                {generatePaymentTierTwoDashboard(
                  "Invoice Received",
                  accountReviewFields.invoice_received,
                  accountReviewRightFields.invoice_received
                )}
                {generatePaymentTierTwoDashboard(
                  "In-Review",
                  accountReviewFields.in_review,
                  accountReviewRightFields.in_review
                )}
                {generatePaymentTierTwoDashboard(
                  "Awaiting Approval",
                  accountReviewFields.awaiting_approval,
                  accountReviewRightFields.awaiting_approval
                )}
                {generatePaymentTierTwoDashboard(
                  "Approved",
                  accountReviewFields.approved,
                  accountReviewRightFields.approved
                )}
                {generatePaymentTierTwoDashboard(
                  "Rejected",
                  accountReviewFields.rejected,
                  accountReviewRightFields.rejected
                )}
                {generatePaymentTierTwoDashboard(
                  "Check Sent",
                  accountReviewFields.check_sent,
                  accountReviewRightFields.check_sent
                )}
              </div>
            )}
          {associationtierOne === "General Payment" &&
            !_.isEmpty(generalPaymentFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generatePaymentTierTwoDashboard(
                  "New Payment",
                  generalPaymentFields.new_payment,
                  generalPaymentRightFields.new_payment
                )}
                {generatePaymentTierTwoDashboard(
                  "Invoice Received",
                  generalPaymentFields.invoice_received,
                  generalPaymentRightFields.invoice_received
                )}
                {generatePaymentTierTwoDashboard(
                  "In-Review",
                  generalPaymentFields.in_review,
                  generalPaymentRightFields.in_review
                )}
                {generatePaymentTierTwoDashboard(
                  "Awaiting Approval",
                  generalPaymentFields.awaiting_approval,
                  generalPaymentRightFields.awaiting_approval
                )}
                {generatePaymentTierTwoDashboard(
                  "Approved",
                  generalPaymentFields.approved,
                  generalPaymentRightFields.approved
                )}
                {generatePaymentTierTwoDashboard(
                  "Rejected",
                  generalPaymentFields.rejected,
                  generalPaymentRightFields.rejected
                )}
                {generatePaymentTierTwoDashboard(
                  "Check Sent",
                  generalPaymentFields.check_sent,
                  generalPaymentRightFields.check_sent
                )}
              </div>
            )}

          {associationtierOne === "FY Assessment" &&
            !_.isEmpty(fyAssessmentFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generatePaymentTierTwoDashboard(
                  "New Payment",
                  fyAssessmentFields.new_payment,
                  fyAssessmentRightFields.new_payment
                )}
                {generatePaymentTierTwoDashboard(
                  "Invoice Received",
                  fyAssessmentFields.invoice_received,
                  fyAssessmentRightFields.invoice_received
                )}
                {generatePaymentTierTwoDashboard(
                  "In-Review",
                  fyAssessmentFields.in_review,
                  fyAssessmentRightFields.in_review
                )}
                {generatePaymentTierTwoDashboard(
                  "Awaiting Approval",
                  fyAssessmentFields.awaiting_approval,
                  fyAssessmentRightFields.awaiting_approval
                )}
                {generatePaymentTierTwoDashboard(
                  "Approved",
                  fyAssessmentFields.approved,
                  fyAssessmentRightFields.approved
                )}
                {generatePaymentTierTwoDashboard(
                  "Rejected",
                  fyAssessmentFields.rejected,
                  fyAssessmentRightFields.rejected
                )}
                {generatePaymentTierTwoDashboard(
                  "Check Sent",
                  fyAssessmentFields.check_sent,
                  fyAssessmentRightFields.check_sent
                )}
              </div>
            )}

          {associationtierOne === "Property Review" &&
            !_.isEmpty(propertyReviewFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {propertyReviewFields.pending_account_number_count !== 0 &&
                  propertyReviewFields.pending_account_number_count && (
                    <>
                      {generateAssociationVerificationTierTwoDashboard(
                        "Account Number",
                        "pending_account_number",
                        propertyReviewFields.pending_account_number_count
                      )}
                    </>
                  )}
                {propertyReviewFields.pending_username_password_count !== 0 &&
                  propertyReviewFields.pending_username_password_count && (
                    <>
                      {generateAssociationVerificationTierTwoDashboard(
                        "UserName/Password",
                        "pending_username_password",
                        propertyReviewFields.pending_username_password_count
                      )}
                    </>
                  )}

                {propertyReviewFields.pending_assessment_description_count !==
                  0 &&
                  propertyReviewFields.pending_assessment_description_count && (
                    <>
                      {generateAssociationVerificationTierTwoDashboard(
                        "Assessment Description",
                        "pending_assessment_description",
                        propertyReviewFields.pending_assessment_description_count
                      )}
                    </>
                  )}
                {propertyReviewFields.pending_association_inspections_count !==
                  0 &&
                  propertyReviewFields.pending_association_inspections_count && (
                    <>
                      {generateAssociationVerificationTierTwoDashboard(
                        "Inspection",
                        "pending_association_inspections",
                        propertyReviewFields.pending_association_inspections_count
                      )}
                    </>
                  )}
              </div>
            )}
        </>
      )}
    </React.Fragment>
  );
}
