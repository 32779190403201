import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
var notesapi = {
  getNotes: async function (propertyId, pageNo, perpage , data) {
    let sort_column;
    let column_order;
    if(data.sort) {
      if(Object.keys(data.sort)[0] === "user.name") {
        sort_column = "user"
      } else {
        sort_column = Object.keys(data.sort)[0];
      }
      column_order = Object.values(data.sort)[0];
    }
    let url = `${config.baseUrl}/api/v1/notes?property_id=${propertyId}&page=${pageNo}&per_page=${perpage}&sort_column=${sort_column}&column_order=${column_order}`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  createNote: async function (propertyId, description) {
    let url = `${config.baseUrl}/api/v1/notes?property_id=${propertyId}`;
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ note: { description } }),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};

export default withRouter(notesapi);
