import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Row,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_createvendor.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import _ from "lodash";

export default function TitleVendorCreationModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : {});
  const [emails, setEmails] = useState(
    _.has(props, "formdata") && props.formdata.order_cc_emails
      ? props.formdata.order_cc_emails.split(",")
      : []
  );

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      let res = helpers.formatZip(value);
      setform(Object.assign({ ...formdata }, { [targetID]: res }));
    } else {
      setform(Object.assign({ ...formdata }, { [targetID]: value }));
    }
  };

  const handlePhoneChange = (event) => {
    let targetName = event.target.name;
    let phone = helpers.formatPhone(event.target.value);
    setform(Object.assign({ ...formdata }, { [targetName]: phone }));
    return;
  };

  const handleSubmit = (event) => {
    if (formdata.id) {
      apicall.updateVendor(formdata, emails).then((response) => {
        helpers.notify("Vendor Created Successfully");
        setTimeout(() => {
          // setform(response);
          props.getVendors();
          props.toggle();
        }, 600);
      });
    } else {
      apicall.createVendor(formdata, emails).then((response) => {
        helpers.notify("Vendor Created Successfully");
        setTimeout(() => {
          setform(response);
          props.getVendors();
          props.toggle();
        }, 600);
      });
    }
  };

  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={true} toggle={props.toggle} className="vendor-modal">
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className="">
                {" "}
                {`${formdata.id ? "Edit " : "Add "}Vendor `}
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              <FormGroup row>
                <Label for="" sm={2}>
                  Vendor Name *
                </Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Vendor Name"
                    validators={["required"]}
                    errorMessages={["Name is required"]}
                    onChange={handleChange}
                    value={formdata.name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Vendor Short Code</Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="short_code"
                    id="short_code"
                    placeholder="Vendor Short Code"
                    validators={["required"]}
                    errorMessages={["Code is required"]}
                    onChange={handleChange}
                    value={formdata.short_code}
                    maxLength={4}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} for="">
                  Order E-mail ID To
                </Label>
                <Col sm={5}>
                  <TextValidator
                    type="text"
                    name="order_email"
                    id="order_email"
                    placeholder="Email"
                    onChange={handleChange}
                    errorMessages={["Email is required"]}
                    validators={["required"]}
                    value={formdata.order_email}
                  />
                </Col>
                <Label sm={1}>Contact</Label>
                <Col sm={4}>
                  <TextValidator
                    type="text"
                    name="contact"
                    id="contact"
                    placeholder="phone"
                    onChange={handlePhoneChange}
                    validators={["required"]}
                    value={formdata.contact}
                    maxLength="14"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} for="">
                  Order E-mail ID CC
                </Label>
                <Col sm={10}>
                  <ReactMultiEmail
                    placeholder="Enter cc emails "
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails(_emails);
                    }}
                    validateEmail={(email) => {
                      return isEmail(email); // return boolean
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="" sm={2}>
                  Address
                </Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    validators={["required"]}
                    errorMessages={["Mailing street name is required"]}
                    onChange={handleChange}
                    value={formdata.address}
                  />
                </Col>
              </FormGroup>
              <div>
                <Row form>
                  <Col md={6}>
                    <FormGroup row>
                      <Label for="" sm={4}>
                        City
                      </Label>
                      <Col sm={8}>
                        <TextValidator
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Mailing city"
                          validators={["required"]}
                          errorMessages={["Mailing city is required"]}
                          onChange={handleChange}
                          value={formdata.city}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup row>
                      <Label for="" sm={3}>
                        State
                      </Label>
                      <Col sm={9}>
                        <TextValidator
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Mailing state"
                          validators={["required"]}
                          errorMessages={["Mailing state is required"]}
                          onChange={handleChange}
                          value={formdata.state}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup row>
                      <Label for="" sm={2}>
                        Zip
                      </Label>
                      <Col sm={10}>
                        <TextValidator
                          type="text"
                          name="zip"
                          id="zip"
                          placeholder="XXXXX-XXXX"
                          maxLength="10"
                          validators={["required", "isAddressZipcodeMatch"]}
                          errorMessages={[
                            "zip is required",
                            "Invalid Zip Code",
                          ]}
                          onChange={(event) => handleChange(event, true)}
                          value={formdata.zip}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
                Submit
              </Button>
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}
