import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "../../styles/action.css";
import actionsapi from "../../utils/api/actionsapi";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Loader from "../helper_components/LoaderComponent";
import config from "../../utils/config";
import _ from "lodash";
import helpers from "../../utils/helpers";
import MunicipalRequirementAction from "../Municipal/MunicipalRequirementAction";
import PaymentModal from "../payments/paymentModal";

export default class MunicipalityActionListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionModal: false,
      updateModal: false,
      paymentModal: false,
      rowIndex: 0,
      propertyID: 0,
      actionArray: [],
      titleActionArray: [],
      row: {},
      action: {},
      isLoading: true,
      municipality_id: 0,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillMount() {
    this.getActions(this.state.propertyID);
  }

  toggleModal = () => {
    this.getActions(this.state.propertyID);
  };

  getActions = (propertyID) => {
    actionsapi
      .listMunicipalityActions(propertyID)
      .then((response) => {
        this.setState({
          actionArray: response.results,
          isLoading: false,
        });
      })
      .catch((e) => console.log(e));
  };

  getActionType = (cell) => {
    if (cell === "MunicipalityRegistration") return "Registration Action";
    else {
      let lowercase = cell.trim();
      let regEx = /\W+|(?=[A-Z])|_/g;
      let result = lowercase.split(regEx).join(" ");
      return result;
    }
  };

  descriptionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.actionable_type.trim().toLowerCase() === "titlereview") {
      return "";
    } else return cell;
  };

  actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              this.setState({
                [cell.trim().toLowerCase() + "Modal"]: !this.state[
                  cell.trim().toLowerCase() + "Modal"
                ],
                actionID: row.actionable_id,
                action: row,
                row: row,
                municipality_id: row.municipality_id,
              });
            }}
          >
            {this.getActionType(cell)}
          </div>
        </React.Fragment>
      );
    }
    let cellName = cell.trim().toLowerCase();
    let tabNo = cellName === "documentinforeport" ? 5 : null;
    tabNo = cellName === "pir" ? 1 : tabNo;
    let hoaID = _.has(row, "hoa_id") ? row.hoa_id : 0;
    if (tabNo !== null) {
      return (
        <Link
          className="link"
          to={
            "/property-manage/" +
            tabNo +
            "/" +
            this.state.propertyID +
            "/" +
            hoaID
          }
        >
          {this.getActionType(cell)}
        </Link>
      );
    }
    return cell;
  };

  dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  notesFormatter = (cell, row) => {
    let notesArr = !_.isEmpty(cell) && cell.split(/\n/g);
    let notes =
      _.isArray(notesArr) &&
      notesArr
        .filter((x) => x !== "")
        .map((x, ind) => {
          return <p>{x}</p>;
        });
    return (
      <>
        <p>{notes}</p>
      </>
    );
  };

  editFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.actionable_type !== "Payment") {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <FaEdit
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => {
              this.setState({
                actionModal: !this.state.actionModal,
                updateModal: true,
                actionID: row.actionable_id,
                action: row,
              });
            }}
          />
        </div>
      );
    }
  };

  rowStyle = (row, rowIndex) => {
    if (
      row.actionable_type === "Payment" &&
      config["paymentHide"].includes(this.props.userRole)
    ) {
      return { display: "none" };
    }
    if (
      row.actionable_type === "Violation" &&
      config["ViolationRead"].includes(this.props.userRole)
    ) {
      return { display: "none" };
    }
    if (
      row.actionable_type === "Lien" &&
      config["LiensHide"].includes(this.props.userRole)
    ) {
      return { display: "none" };
    }
  };

  render() {
    const columns = [
      {
        dataField: "actionable_type",
        text: "Action Type",
        formatter: this.actionTypeFormatter,
        headerClasses: "header-cell-alignment",
        sort: true,
      },
      {
        dataField: "description",
        text: "Description",
        headerClasses: "header-cell-alignment",
        formatter: this.descriptionTypeFormatter,
        sort: true,
      },
      {
        dataField: "hoa.name",
        text: "Entity",
        classes: "reduce-width",
        headerClasses: "header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
        sort: true,
      },
      {
        dataField: "creation_date",
        text: "Creating Date",
        classes: "reduce-width",
        formatter: this.dateFormatter,
        headerClasses: "header-cell-alignment",
        sort: true,
      },
      {
        dataField: "due_date",
        text: "Due Date",
        classes: "reduce-width",
        formatter: this.dateFormatter,
        headerClasses: "header-cell-alignment",
        sort: true,
      },
      {
        dataField: "current_status",
        text: "Current Status",
        classes: "reduce-width",
        headerClasses: "header-cell-alignment",
        sort: true,
      },

      {
        dataField: "notes",
        text: "Notes/Actions/Next Steps",
        classes: "notes-width",
        formatter: this.notesFormatter,
        headerClasses: "header-cell-alignment",
      },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.editFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 200, class: "table-actions" },
        headerClasses: "header-cell-alignment",
      },
    ];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          {this.state.actionArray && (
            <div style={{ width: "94%" }}>
              <BootstrapTable
                keyField="id"
                data={this.state.actionArray}
                columns={columns}
                Pagination={paginationFactory}
                rowStyle={this.rowStyle}
              />
            </div>
          )}

          {this.state.municipalityregistrationModal && (
            <MunicipalRequirementAction
              actionID={this.state.actionID}
              propertyID={this.state.propertyID}
              open={this.state.municipalityregistrationModal}
              toggle={() => {
                this.setState({
                  municipalityregistrationModal: !this.state
                    .municipalityregistrationModal,
                });
              }}
              formdata={this.state.row}
              title="Municipality Registration Action"
              fetchDocuments={this.toggleModal}
              isMunicipalityAction={true}
              refershActions={this.toggleModal}
              actionTab="municipalityAction"
              municipality_id={this.state.municipality_id}
            />
          )}
          {this.state.municipalpaymentModal && (
            <PaymentModal
              toggle={() => {
                this.setState({
                  municipalpaymentModal: !this.state.municipalpaymentModal,
                });
              }}
              title="Payment"
              open={this.state.municipalpaymentModal}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              userRole={this.props.userRole}
              refershActions={this.toggleModal}
              propertyInfo={this.props.propertyInfo}
              actionHoaId={this.state.action.hoa_id}
              actionTab="municipalityAction"
              municipality_id={this.state.municipality_id}
            />
          )}
        </React.Fragment>
      );
    }
  }
}
