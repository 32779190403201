import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
  CustomInput,
} from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_municipalRequirementAction.scss";
import DatePicker from "react-datepicker";
import SelectOptions from "../helper_components/SelectOptions";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import "../../styles/_municipalRequirementAction.scss";
import CodeViolationModal from "../CodeViolation/CodeViolationModal";
import TitleDocumentsModal from "../documents/TitleDocumentsModal";
import PaymentModal from "../payments/paymentModal";
import _ from "lodash";
import NotesHistory from "../CodeViolation/NotesHistory";
import DocumentsTab from "../documents/DocumentsTab";

export default function MunicipalRequirementAction(props) {
  const [row, setrow] = useState({});
  const paymentMethodOptions = ["Credit Card", "Check", "eCheck", "N/A"];
  const [openCodeViolationModal, setopenCodeViolationModal] = useState(false);
  const [openFileUpload, setopenFileUpload] = useState(false);
  const [isInspectionRequired, setisInspectionRequired] = useState(false);
  const [openPaymentModal, setopenpaymentModal] = useState(false);
  const [codeViolation_id, setcodeViolation_id] = useState();
  const [isReInspectionDisabled, setisReInspectionDisabled] = useState(true);
  const [isInspectionDisabled, setisInspectionDisabled] = useState(true);
  const [isExpirationRowDisabled, setisExpirationRowDisabled] = useState(true);
  const [isExpirationDateDisabled, setisExpirationDateDisabled] =
    useState(true);
  const [isNotApplicableDisabled, setisNotApplicableDisabled] = useState(true);
  const [inspectionCodeViolationsData, setinspectionCodeViolationsData] =
    useState({});
  const [reInspectionCodeViolationsData, setreInspectionCodeViolationsData] =
    useState({});
  const [inspectionClick, setinspectionClick] = useState(false);
  const [reInspectionClick, setreInspectionClick] = useState(false);

  useEffect(() => {
    readMunicipalityAction();
  }, []);

  const readMunicipalityAction = () => {
    apicall.readMunicipalityAction(props.actionID).then((response) => {
      let munidata = response.municipality_requirement;
      let inspection =
        munidata.inspection_required === null
          ? false
          : munidata.inspection_required;
      setisInspectionRequired(inspection);

      if (inspection) {
        if (
          response.inspection_passed === false ||
          response.inspection_passed === null
        ) {
          if (
            response.payment_method === "Credit Card" ||
            response.payment_method === "N/A"
          ) {
            setisInspectionDisabled(false);
            if (
              _.isNull(response.expiration_date) &&
              (_.isNull(response.expiration_date_not_applicable) ||
                response.expiration_date_not_applicable === false)
            ) {
              setisNotApplicableDisabled(false);
              setisExpirationDateDisabled(false);
            } else if (
              _.isNull(response.expiration_date) &&
              response.expiration_date_not_applicable === true
            ) {
              setisNotApplicableDisabled(false);
              setisExpirationDateDisabled(true);
            } else if (
              _.isNull(response.expiration_date_not_applicable) &&
              !_.isNull(response.expiration_date)
            ) {
              setisNotApplicableDisabled(true);
              setisExpirationDateDisabled(false);
            }
          } else if (
            response.payment_method === "Check" ||
            response.payment_method === "eCheck"
          ) {
            if (
              response.payment &&
              response.payment.action_status === "Closed"
            ) {
              setisInspectionDisabled(false);
            }
          }
        } else if (response.inspection_passed === true) {
          setisInspectionDisabled(false);
          setisExpirationRowDisabled(false);
          //setisExpirationDateDisabled(false);
          if (
            _.isNull(response.expiration_date) &&
            (_.isNull(response.expiration_date_not_applicable) ||
              response.expiration_date_not_applicable === false)
          ) {
            setisNotApplicableDisabled(false);
            setisExpirationDateDisabled(false);
          } else if (
            _.isNull(response.expiration_date) &&
            response.expiration_date_not_applicable === true
          ) {
            setisNotApplicableDisabled(false);
            setisExpirationDateDisabled(true);
          } else if (
            _.isNull(response.expiration_date_not_applicable) &&
            !_.isNull(response.expiration_date)
          ) {
            setisNotApplicableDisabled(true);
            setisExpirationDateDisabled(false);
          }
        }
      } else {
        if (
          response.payment_method === "Credit Card" ||
          response.payment_method === "N/A"
        ) {
          setisExpirationRowDisabled(false);
          if (
            _.isNull(response.expiration_date) &&
            (_.isNull(response.expiration_date_not_applicable) ||
              response.expiration_date_not_applicable === false)
          ) {
            setisNotApplicableDisabled(false);
            setisExpirationDateDisabled(false);
          } else if (
            _.isNull(response.expiration_date) &&
            response.expiration_date_not_applicable === true
          ) {
            setisNotApplicableDisabled(false);
            setisExpirationDateDisabled(true);
          } else if (
            _.isNull(response.expiration_date_not_applicable) &&
            !_.isNull(response.expiration_date)
          ) {
            setisNotApplicableDisabled(true);
            setisExpirationDateDisabled(false);
          }
        } else if (
          response.payment_method === "Check" ||
          response.payment_method === "eCheck"
        ) {
          if (response.payment && response.payment.action_status === "Closed") {
            setisExpirationRowDisabled(false);
            if (
              _.isNull(response.expiration_date) &&
              (_.isNull(response.expiration_date_not_applicable) ||
                response.expiration_date_not_applicable === false)
            ) {
              setisNotApplicableDisabled(false);
              setisExpirationDateDisabled(false);
            } else if (
              _.isNull(response.expiration_date) &&
              response.expiration_date_not_applicable === true
            ) {
              setisNotApplicableDisabled(false);
              setisExpirationDateDisabled(true);
            } else if (
              _.isNull(response.expiration_date_not_applicable) &&
              !_.isNull(response.expiration_date)
            ) {
              setisNotApplicableDisabled(true);
              setisExpirationDateDisabled(false);
            }
          }
        }
      }

      if (response.code_violations.length > 0) {
        readCodeViolation(response.code_violations[0].id, "inspection");
        if (response.code_violations[0].status === "Closed")
          setisReInspectionDisabled(false);
      } else if (response.code_violations.length > 1) {
        readCodeViolation(response.code_violations[1].id, "reInspection");
        if (response.code_violations[1].status === "Closed") {
          setisExpirationRowDisabled(false);
          if (
            _.isNull(response.expiration_date) &&
            (_.isNull(response.expiration_date_not_applicable) ||
              response.expiration_date_not_applicable === false)
          ) {
            setisNotApplicableDisabled(false);
            setisExpirationDateDisabled(false);
          } else if (
            _.isNull(response.expiration_date) &&
            response.expiration_date_not_applicable === true
          ) {
            setisNotApplicableDisabled(false);
            setisExpirationDateDisabled(true);
          } else if (
            _.isNull(response.expiration_date_not_applicable) &&
            !_.isNull(response.expiration_date)
          ) {
            setisNotApplicableDisabled(true);
            setisExpirationDateDisabled(false);
          }
        }
      }
      if (response.reinspection_passed) {
        setisExpirationRowDisabled(false);
        if (
          _.isNull(response.expiration_date) &&
          (_.isNull(response.expiration_date_not_applicable) ||
            response.expiration_date_not_applicable === false)
        ) {
          setisNotApplicableDisabled(false);
          setisExpirationDateDisabled(false);
        } else if (
          _.isNull(response.expiration_date) &&
          response.expiration_date_not_applicable === true
        ) {
          setisNotApplicableDisabled(false);
          setisExpirationDateDisabled(true);
        } else if (
          _.isNull(response.expiration_date_not_applicable) &&
          !_.isNull(response.expiration_date)
        ) {
          setisNotApplicableDisabled(true);
          setisExpirationDateDisabled(false);
        }
      }
      if (_.isEmpty(response.payment_method || response.reg_payment_amount)) {
        let data = {
          ...response,
          reg_payment_amount: munidata.registration_amount,
          payment_method: munidata.payment_method,
          periodicity: munidata.periodicity,
        };
        setrow(Object.assign({ ...response }, { ...data }));
      } else setrow(response);
    });
  };

  const readCodeViolation = (id, type) => {
    apicall
      .readCodeViolation(id ? id : codeViolation_id, props.propertyID)
      .then((response) => {
        if (type === "inspection") setinspectionCodeViolationsData(response);
        else if (type === "reInspection")
          setreInspectionCodeViolationsData(response);
        //setactionable_id(response.id);
      });
  };

  const toggleDocument = () => {
    setopenFileUpload(!openFileUpload);
  };

  const toggleModal = (event) => {
    setopenCodeViolationModal(!openCodeViolationModal);
    if (openCodeViolationModal) readMunicipalityAction();
  };

  const handleSubmit = (event, isTrue) => {
    apicall
      .updateMunicipalityAction(row, props.actionID, props.propertyID)
      .then((response) => {
        if (response) {
          helpers.notify("Municipality Action Updated Successfully");
          if (response.payment_created === true)
            helpers.notify("General Payment Created Successfully");
          if (!isTrue) {
            props.toggle();
            if (!props.isDisplayMunicipal)
              props.refershActions(props.propertyID);
          }
        } else {
          helpers.notify("Failed to Update");
          props.toggle();
        }
      })
      .catch((e) => console.log(e));
  };

  const handleDateChange = (value, key) => {
    let date = !_.isNull(value) ? helpers.restructureDate(value) : null;
    if (key === "expiration_date" && !_.isNull(value)) {
      setisNotApplicableDisabled(true);
    } else if (key === "expiration_date" && _.isNull(value)) {
      setisNotApplicableDisabled(false);
    }
    setrow(Object.assign({ ...row }, { [key]: date }));
    return;
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    const target = event.target;

    if (
      (targetName === "payment_method" && target.value === "Credit Card") ||
      target.value === "N/A"
    ) {
      if (isInspectionRequired) {
        setisInspectionDisabled(false);
      } else {
        setisExpirationRowDisabled(false);
        setisExpirationDateDisabled(false);
        setisNotApplicableDisabled(false);
      }
    } else if (
      (targetName === "payment_method" && target.value === "Check") ||
      target.value === "eCheck"
    ) {
      if (isInspectionRequired) {
        if (!_.isEmpty(row.payment) && row.payment.action_status === "Closed")
          setisInspectionDisabled(false);
        else setisInspectionDisabled(true);
      } else {
        setisExpirationRowDisabled(true);
        setisExpirationDateDisabled(true);
        setisNotApplicableDisabled(true);
      }
    }
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (targetName === "not_applicable" && value === true) {
    
      setisExpirationDateDisabled(true);
    } else if (targetName === "not_applicable" && value === false) {
      
      setisExpirationDateDisabled(false);
    }
    value = event.target.type === "file" ? event.target.files[0] : value;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const handleCheckBoxChange = (event) => {
    let target = event.target;
    let targetID = event.target.id;
    let targetName = event.target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (targetName === "not_applicable" && value === true) {
      setisExpirationDateDisabled(true);
      setrow(Object.assign({ ...row }, { [targetID]: value }));
    } else if (targetName === "not_applicable" && value === false) {
      setisExpirationDateDisabled(false);
      setrow(Object.assign({ ...row }, { [targetID]: value }));
    }
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    if (
      (targetName === "inspection_passed" ||
        targetName === "reinspection_passed") &&
      value === true
    ) {
      setisExpirationRowDisabled(false);
      setisExpirationDateDisabled(false);
      setisNotApplicableDisabled(false);
    }
    if (
      (targetName === "inspection_passed" ||
        targetName === "reinspection_passed") &&
      value === false
    ) {
      setisExpirationRowDisabled(true);
      setisExpirationDateDisabled(true);
      setisNotApplicableDisabled(true);
    }
    setrow(Object.assign({ ...row }, { [targetName]: value }));
    if (
      row["reinspection_passed"] === true &&
      ((targetName === "reinspection_passed" && value === true) ||
        (targetName === "inspection_passed" && value === false))
    ) {
      setisExpirationRowDisabled(false);
      setisExpirationDateDisabled(false);
    }
  };

  const deleteMunicipalActionUploadedDocument = (documentID) => {
    apicall
      .deleteMunicipalityActionUploadDocument(row.id, documentID)
      .then((response) => {
        helpers.notify("Document deleted successfully");
        readMunicipalityAction();
      });
  };

  const renderRadioInput = (name, disabled) => {
    return (
      <div className="municipal-action-radio">
        <CustomInput
          id={`${name}yes`}
          type="radio"
          name={name}
          value={true}
          onClick={(e) => handleRadioButton(e, true)}
          checked={row[name] === true ? "checked" : ""}
          disabled={disabled}
        />
        <label>Yes</label>
        <CustomInput
          id={`${name}no`}
          type="radio"
          name={name}
          value={false}
          onClick={(e) => handleRadioButton(e, false)}
          checked={row[name] === false ? "checked" : ""}
          disabled={disabled}
        />
        <label>No</label>
      </div>
    );
  };

  const displayPaymentWebsite = (paymentWebsite) => {
    if (paymentWebsite) {
      let newPaymentWebsite = paymentWebsite.slice(0, 26);
      return newPaymentWebsite.concat("" + "...");
    }
  };

  const formElements = () => {
    let documents =
      !_.isEmpty(row.municipality_requirement) &&
      row.municipality_requirement.title_documents;
    return (
      <>
        <FormGroup row>
          <Label for="" sm={2}>
            Payee Website
          </Label>
          <Col sm={2} className="municipal-website">
            {!_.isEmpty(row) && !_.isEmpty(row.municipality_requirement) && (
              <a
                href={`https://${row.municipality_requirement.muni_req_website}`}
                style={{ float: "none", color: "blue" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.municipality_requirement.muni_req_website &&
                row.municipality_requirement.muni_req_website.length < 25
                  ? row.municipality_requirement.muni_req_website
                  : displayPaymentWebsite(
                      row.municipality_requirement.muni_req_website
                    )}
                <span className="tooltiptext">
                  {row.municipality_requirement.muni_req_website}
                </span>
              </a>
            )}
          </Col>

          <Label for="" sm={1}>
            Username
          </Label>
          <Col sm={2} className="municipal-website">
            {!_.isEmpty(row) &&
              !_.isEmpty(row.municipality_requirement) &&
              row.municipality_requirement.username}
          </Col>

          <Label for="" sm={1}>
            Password
          </Label>
          <Col sm={2} className="municipal-website">
            {!_.isEmpty(row) &&
              !_.isEmpty(row.municipality_requirement) &&
              row.municipality_requirement.password}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Document
          </Label>
          <div>
            {!_.isEmpty(documents) &&
              documents.map((data) => {
                return (
                  <Col sm={12} className="municipal-website">
                    <a
                      href={data.file_url}
                      style={{ float: "none", color: "blue" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.file_name}
                    </a>
                  </Col>
                );
              })}
          </div>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for="" sm={2}>
            Payment Method:
          </Label>
          <Col sm={1.5} style={{ paddingLeft: "15px" }}>
            <Input
              type="select"
              name={"payment_method"}
              id={"payment_method"}
              onChange={handleChange}
              value={row.payment_method}
            >
              <SelectOptions options={paymentMethodOptions} />
            </Input>
          </Col>

          {row.payment_method !== "N/A" && (
            <>
              <Label for="" sm={2}>
                Payment Amount:
              </Label>
              <Col sm={1}>
                <Input
                  type="number"
                  name="reg_payment_amount"
                  id="reg_payment_amount"
                  onChange={handleChange}
                  value={row.reg_payment_amount}
                />
              </Col>
            </>
          )}

          {row.payment_method === "Credit Card" ? (
            <>
              <Label for="" sm={2}>
                Confirmation Number:
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name="confirmation_number"
                  id="confirmation_number"
                  onChange={handleChange}
                  value={row.confirmation_number}
                />
              </Col>
            </>
          ) : (
            (row.payment_method === "Check" ||
              row.payment_method === "eCheck") &&
            _.includes(row, row.payment) && (
              <Col sm={2} className="municipal-website">
                <div
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => {
                    setopenpaymentModal(!openPaymentModal);
                  }}
                  className="municipal-violation-button"
                >
                  General Payment
                </div>
              </Col>
            )
          )}
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Registration Applied For Date:
          </Label>
          <Col sm={2}>
            <div className="code-violation-date">
              <DatePicker
                id="registration_applied_date"
                selected={
                  row.registration_applied_date
                    ? helpers.restructureDate(row.registration_applied_date)
                    : null
                }
                onChange={(value) =>
                  handleDateChange(value, "registration_applied_date")
                }
                value={
                  row.registration_applied_date
                    ? helpers.restructureDate(row.registration_applied_date)
                    : null
                }
              />
            </div>
          </Col>
        </FormGroup>
        {isInspectionRequired === true && (
          <>
            <FormGroup row>
              <Label for="" sm={2}>
                Inspection Date:
              </Label>
              <Col sm={2}>
                <div className="code-violation-date">
                  <DatePicker
                    id="inspection_date"
                    selected={
                      row.inspection_date
                        ? helpers.restructureDate(row.inspection_date)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "inspection_date")
                    }
                    value={
                      row.inspection_date
                        ? helpers.restructureDate(row.inspection_date)
                        : null
                    }
                    disabled={isInspectionDisabled}
                  />
                </div>
              </Col>

              <Label for="" sm={2}>
                Inspection Passed:
              </Label>
              <Col sm={1}>
                {" "}
                {renderRadioInput("inspection_passed", isInspectionDisabled)}
              </Col>

              {_.includes(row, row.code_violations) &&
              !_.isEmpty(row.code_violations[0]) ? (
                <Col sm={2} className="municipal-website">
                  <div
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => {
                      setopenCodeViolationModal(!openCodeViolationModal);
                      setcodeViolation_id(row.code_violations[0].id);
                      setinspectionClick(true);
                    }}
                    className="municipal-violation-button"
                  >
                    Code Violation
                  </div>
                </Col>
              ) : (
                <>
                  {row.inspection_passed === false && (
                    <Col sm={2}>
                      <Button
                        className="municipal-violation-button"
                        onClick={(event) => {
                          handleSubmit(event, true);
                          setopenCodeViolationModal(true);
                          setcodeViolation_id(null);
                        }}
                      >
                        Create Violation
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </FormGroup>
            {row.inspection_passed === false && (
              <FormGroup row>
                <Label for="" sm={2}>
                  Re-Inspection Date:
                </Label>
                <Col sm={2}>
                  <div className="code-violation-date">
                    <DatePicker
                      id="reinspection_date"
                      selected={
                        row.reinspection_date
                          ? helpers.restructureDate(row.reinspection_date)
                          : null
                      }
                      onChange={(value) =>
                        handleDateChange(value, "reinspection_date")
                      }
                      value={
                        row.reinspection_date
                          ? helpers.restructureDate(row.reinspection_date)
                          : null
                      }
                      disabled={isReInspectionDisabled}
                    />
                  </div>
                </Col>
                <Label for="" sm={2}>
                  Inspection Passed:
                </Label>
                <Col sm={1}>
                  {/* {renderRadioInput("reinspection_passed", isReInspectionDisabled)} */}
                  {renderRadioInput(
                    "reinspection_passed",
                    isReInspectionDisabled
                  )}
                </Col>
                {_.includes(row, row.code_violations) &&
                !_.isEmpty(row.code_violations[1]) ? (
                  <Col sm={2} className="municipal-website">
                    <div
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => {
                        setopenCodeViolationModal(!openCodeViolationModal);
                        setcodeViolation_id(row.code_violations[1].id);
                        setreInspectionClick(true);
                      }}
                      className="municipal-violation-button"
                    >
                      Code Violation
                    </div>
                  </Col>
                ) : (
                  <>
                    {row.reinspection_passed === false && (
                      <Col sm={2}>
                        <Button
                          className="municipal-violation-button"
                          onClick={(event) => {
                            setopenCodeViolationModal(true);
                            setcodeViolation_id(null);
                            handleSubmit(event, true);
                          }}
                          //disabled={isReInspectionDisabled}
                        >
                          Create Violation
                        </Button>
                      </Col>
                    )}
                  </>
                )}
              </FormGroup>
            )}
          </>
        )}

        <FormGroup row>
          <Label for="" sm={2}>
            Registration Issued Date:
          </Label>
          <Col sm={1}>
            <div className="code-violation-date">
              <DatePicker
                id="registration_issued_date"
                selected={
                  row.registration_issued_date
                    ? helpers.restructureDate(row.registration_issued_date)
                    : null
                }
                onChange={(value) =>
                  handleDateChange(value, "registration_issued_date")
                }
                value={
                  row.registration_issued_date
                    ? helpers.restructureDate(row.registration_issued_date)
                    : null
                }
                disabled={isExpirationRowDisabled}
              />
            </div>
          </Col>

          <Label for="" sm={2}>
            Expiration Date:
          </Label>
          <Col sm={1}>
            <div className="code-violation-date">
              <DatePicker
                id="expiration_date"
                selected={
                  row.expiration_date
                    ? helpers.restructureDate(row.expiration_date)
                    : null
                }
                onChange={(value) => handleDateChange(value, "expiration_date")}
                value={
                  row.expiration_date
                    ? helpers.restructureDate(row.expiration_date)
                    : null
                }
                disabled={isExpirationDateDisabled}
              />
            </div>
          </Col>
          <Label for="" sm={1.5}>
            Expiry Date N/A
          </Label>
          <Col sm={0.5}>
            <div style={{ textAlign: "center", width: "5rem" }}>
              <input
                type="checkbox"
                name="not_applicable"
                id="expiration_date_not_applicable"
                //value={row.not_applicable}
                onChange={(event) => handleCheckBoxChange(event)}
                checked={row.expiration_date_not_applicable ? true : false}
                style={{
                  width: "20%",
                  marginLeft: "15px",
                }}
                disabled={isNotApplicableDisabled}
              />
            </div>
          </Col>

          <Label for="" sm={2}>
            Registration Number:
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="registration_number"
              id="registration_number"
              onChange={handleChange}
              value={row.registration_number}
              disabled={isExpirationRowDisabled}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
              <Label for="" sm={2}>
                Follow up Date:
              </Label>
              <Col sm={1}>
                <div className="code-violation-date">
                  {" "}
                  <DatePicker
                    id="followup_date"
                    selected={
                      row.followup_date
                        ? helpers.restructureDate(row.followup_date)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "followup_date")
                    }
                    value={
                      row.followup_date
                        ? helpers.restructureDate(row.followup_date)
                        : null
                    }
                  />
                </div>
              </Col>

              <Label for="" sm={2}>
                Due Date:
              </Label>
              <Col sm={1}>
                <div className="code-violation-date">
                  <DatePicker
                    id="due_date"
                    selected={
                      row.due_date
                        ? helpers.restructureDate(row.due_date)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "due_date")
                    }
                    value={
                      row.due_date
                        ? helpers.restructureDate(row.due_date)
                        : null
                    }
                  />
                </div>
              </Col>
            </FormGroup>

        <FormGroup row>
          <Label for="" sm={2}>
            Is Document Required?
          </Label>
          <Col sm={1}>
            {renderRadioInput("document_required", isExpirationRowDisabled)}
          </Col>
          {row.document_required === true && (
            <Col sm={2}>
              <Button
                onClick={() => setopenFileUpload(true)}
                style={{
                  marginBottom: "0px",
                  marginTop: "0px",
                  marginLeft: "15px",
                }}
              >
                File Upload
              </Button>
            </Col>
          )}
        </FormGroup>
        {!_.isEmpty(row.title_documents) ? (
          <FormGroup row>
            <Label for="" sm={2}></Label>
            <Col sm={9}>
              {row.title_documents && (
                <DocumentsTab
                  propertyID={props.propertyID}
                  documents={row.title_documents}
                  isMunicipalRequirementAction={true}
                  deleteDocument={deleteMunicipalActionUploadedDocument}
                  theme={"general-info-tab"}
                  category={props.category}
                />
              )}
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup row>
          <Label for="" sm={2}>
            Notes
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="muni_reg_notes"
              id="muni_reg_notes"
              placeholder="Notes"
              onChange={handleChange}
              //value={row.notes}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <div className="municipal-submit">
            <Button className="municipal-submit-button" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </FormGroup>
        <NotesHistory notes={row.notes ? row.notes : []} registration={true} />
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Municipality </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };
  return (
    <div style={{ marginTop: "15px" }}>
      <Modal
        className="municipal-action-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>

      {openCodeViolationModal && (
        <CodeViolationModal
          toggle={toggleModal}
          className=""
          title="CodeViolation"
          open={openCodeViolationModal}
          propertyID={props.propertyID}
          isMunicipalityRegistrationAction={true}
          municipality_registration_id={row.id}
          actionID={codeViolation_id}
          row={
            inspectionClick
              ? inspectionCodeViolationsData
              : reInspectionCodeViolationsData
          }
        />
      )}
      {openFileUpload && (
        <TitleDocumentsModal
          toggle={toggleDocument}
          open={openFileUpload}
          title="Document"
          id={row.id}
          isMunicipalityRegistrationAction={true}
          fetchDocuments={readMunicipalityAction}
          propertyID={props.propertyID}
          row={row}
        />
      )}
      {openPaymentModal && (
        <PaymentModal
          toggle={() => {
            setopenpaymentModal(!openPaymentModal);
          }}
          title="Payment"
          open={true}
          row={row}
          propertyID={props.propertyID}
          actionID={row.payment.id}
          actionTab={props.actionTab}
          municipality_id={row.municipality_id}
        />
      )}
    </div>
  );
}
