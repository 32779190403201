import React, { Component } from "react";
import "../../../styles/tenants.scss";
import TenantMoveInListTable from "./TenantMoveInListTable";
import apicall from "../../../utils/apicall";
import Loader from "../../helper_components/LoaderComponent";
//import {CustomInput} from 'reactstrap'
export default class TenantMoviInCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageNo: 1,
      isLoading: true,
    };
  }

  componentWillMount() {
    this.getList();
  }

  getList = (event, pageNo) => {
    this.setState({
      pageNo,
    });
    apicall.getTenantMoveInList(this.props.propertyid).then((resp) => {
      this.setState({ data: resp.results, isLoading: false });
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <TenantMoveInListTable
            data={this.state.data}
            refreshList={this.getList}
            pageNo={this.state.pageNo}
            propertyid={this.props.propertyid}
            userRole={this.props.userRole}
          />
        )}
      </React.Fragment>
    );
  }
}
