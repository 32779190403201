import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import { toast } from "react-toastify";
//import TextValidator from "../helper_components/TextValidator";
//import { FaTrashAlt } from "react-icons/fa";
//import { Input, Table } from "reactstrap";
import DocumentUploadComponent from "../helper_components/DocumentUploadComponent";
import helpers from "../../utils/helpers";
import LoaderComponent from "../helper_components/LoaderComponent";
//import _ from "lodash";
class FormComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      row: {},
      title: props.title,
      processJSON: false,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  hideSubmit = () => {
    this.setState({
      submitButton: " hide-button",
      isDisabled: true,
    });
  };

  toggleModal = () => {
    this.state.toggle();
  };

  handleRadioButton = (event) => {
    var row = this.state.row;
    let value = event.target.value;
    if (typeof value === "string") {
      value = helpers.getBoolean(value);
    }
    row[event.target.name] = value;
    this.setState({
      row: row,
    });
  };

  handleChange = (event, format) => {
    console.log('handleChange',event.target.id,event.target.value)
    let targetID = event.target.id;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "file" ? event.target.files[0] : value;
    value = event.target.type === "radio" ? helpers.getBoolean(value) : value;
    if (targetID === "settlement_status") {
      this.setState({
        row: Object.assign(this.state.row, {
          status: "new",
        }),
      });
    }
    if (
      this.state.processJSON &&
      targetID.includes("[") &&
      targetID.includes("]")
    ) {
      let targetArray = targetID.split("[");
      let targetParent = targetArray[0];
      let targetIndex = targetArray[1].split("]")[0];
      let targetChild = targetArray[1].split("]")[1].replace(".", "");
      if (this.state.row[targetParent]) {
        let targetParentData = this.state.row[targetParent];
        let targetIndexData = targetParentData[targetIndex];
        if (typeof targetIndexData === "undefined") {
          targetIndexData = {};
        }
        targetIndexData[targetChild] = value;
        targetParentData[targetIndex] = targetIndexData;
        this.setState({
          row: Object.assign(this.state.row, {
            [targetParent]: targetParentData,
          }),
        });
      }
    } else {
      if (format === "zipCode" && value.length > 5) {
        console.log("zip value ---->", value);
        var len = value.length;
        var res;
        if (value.substring(5, 6) != "-") {
          // console.log("indise substrin IF")
          res = value.substring(0, 5) + "-" + value.substring(5, len);
        } else {
          res = value;
        }
        this.setState({
          row: Object.assign(this.state.row, { [targetID]: res }),
        });
      } else if (format === "fax" || format === "phone") {
        let text = event.target.value;
        var cleaned = ("" + text).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          cleaned = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
            match[3] ? "-" : ""
          }${match[3]}`;
        }
        this.setState({
          row: Object.assign(this.state.row, { [targetID]: cleaned }),
        });
      } else if (format === "taxid" && value.length > 2) {
        var len = value.length;
        var res;
        if (value.substring(2, 3) != "-") {
          // console.log("indise substrin IF")
          res = value.substring(0, 2) + "-" + value.substring(2, len);
        } else {
          res = value;
        }
        this.setState({
          row: Object.assign(this.state.row, { [targetID]: res }),
        });
      } else {
        this.setState({
          row: Object.assign(this.state.row, { [targetID]: value }),
        });
      }
    }
    event.preventDefault();
  };

  handleDateChange = (key, value) => {
    this.setState({
      row: Object.assign(this.state.row, { [key]: value }),
    });
  };

  notify = (errorMsg) => toast(errorMsg);

  handleSubmit = (event) => {
    event.preventDefault();
  };

  documentComp = (isDisabled) => {
    return (
      <DocumentUploadComponent
        row={this.state.row}
        onChange={this.handleChange}
        showDocuments={true}
        isDisabled={this.state.isDisabled}
        deleteDocument={this.deleteDocument}
      />
    );
  };

  formElements = () => {
    return "";
  };

  assessmentsForm = () => {
    return "";
  };

  getSelectionValue = (obj) => {
    console.log("  >>obj: ", obj);
    if (obj === Object(obj)) return obj.value;
    else return obj;
  };

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">
              {(this.state.row && this.state.row.id ? "Update " : "Add ") +
                this.state.title}
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
            {this.state.isLoading ? <LoaderComponent /> : this.formElements()}
            {this.props.row &&
            this.props.row.current_status &&
            this.props.row.current_status.trim().toLowerCase() ===
              "closed" ? null : (
              <Button className={this.state.submitButton}>Submit</Button>
            )}
            {this.state.isLoading ? (
              <LoaderComponent />
            ) : (
              this.assessmentsForm()
            )}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.renderForm ? (
          <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
            {this.state.isLoading ? <LoaderComponent /> : this.formElements()}
            {this.props.row &&
            this.props.row.current_status &&
            this.props.row.current_status.trim().toLowerCase() ===
              "closed" ? null : (
              <Button className={this.state.submitButton}>Submit</Button>
            )}
            {this.state.isLoading ? (
              <LoaderComponent />
            ) : (
              this.assessmentsForm()
            )}
          </ValidatorForm>
        ) : (
          <Modal
            className={"area-analyst-form-modal " + this.state.className}
            isOpen={this.state.open}
            toggle={this.toggleModal}
          >
            {this.renderModal()}
          </Modal>
        )}
      </div>
    );
  }
}

export default FormComp;
