import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var titleClearanceapi = {
  getIssueTypes: async function () {
    let url = `${config.baseUrl}/api/v1/title_clearances/issue_types`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  readTitleClearance: async function (id) {
    let url = `${config.baseUrl}/api/v1/title_clearances/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateTitleClearance: async function (id, data, followup_date, title_clearance_notes) {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/title_clearances/${id}`;

    let headers;
    let response;
    var form = new FormData();
    data.map((x, index) => {
      for (var key in x) {
        if (
          key === "title_clearance_id" ||
          key === "title_order_id" ||
          key === "property_id" ||
          key === "created_at" ||
          key === "updated_at"
        ) {
          delete x[key];
        } else {
          if (key === "issue_resolved" && x[key] === null) {
            form.append(
              "title_clearance[clearance_issues_attributes][][" + key + "]",
              false
            );
          } else {
            form.append(
              "title_clearance[clearance_issues_attributes][][" + key + "]",
              x[key]
            );
          }
        }
      }
    });
    if (followup_date) {
      form.append("title_clearance[followup_date]", followup_date);
    }
    if(title_clearance_notes) {
      form.append("title_clearance[title_clearance_notes]", title_clearance_notes);
    }
    console.log("The form data is", form);
    headers = await helpers.defaultHeaders();
    await Axios.put(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};

export default withRouter(titleClearanceapi);
