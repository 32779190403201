import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var municipalityOrderapi = {
  createMunicipalOrder: async function (data) {
    let url = config.baseUrl + "/api/v1/client_municipal_orders";
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  getMunicipalOrder: async function (id) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listMunicipalOrder: async function (data, pageNo, perPage) {
    //let url = config.baseUrl + "/api/v1/client_municipal_orders?";
    let url =
      config.baseUrl +
      "/api/v1/client_municipal_orders/municipal_orders_all_vendors?";
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  listMunicipalOrderByid: async function (data, clientId, pageNo, perPage) {
    // let url = `${config.baseUrl}/api/v1/client_municipal_orders?clientid=${clientId}`;
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/municipal_orders_all_vendors?`;
    let params = {};
    if (clientId) params["clientid"] = clientId;
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // if (pageNo) {
    //   url = url + "&page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    // let headers;
    // let response;
    // headers = await helpers.defaultHeaders();
    // await Axios.get(url, { headers: headers }).then((resp) => {
    //   response = resp;
    // });
    // return response.data;
  },

  listMunicipalOrderHistory: async function (id) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/${id}`;
    // if (pageNo) {
    //   url = url + "?page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateMunicipalOrder: async function (data) {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/${data.id}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteMunicipalOrder: async function (id) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  searchMunicipalOrderByClientid: async function (pageNo, perPage, clientId) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/search?q=${clientId}`;
    if (pageNo) {
      url = url + "&page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  cancelClientMunicipalOrder: async function (
    orderid,
    title_order_id,
    order_id
  ) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/${orderid}/cancel_order?municipal_order_id=${title_order_id}&orderid=${order_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, "", { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listMunicipalVendorOrder: async function () {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/municipal_vendor_list`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};

export default withRouter(municipalityOrderapi);
