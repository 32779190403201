import React from "react";
import FormComp from "../crud/FormComp";
import { FormGroup, Label, CustomInput, Col } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import Select from "react-select";
import apicall from "../../utils/apicall";
import DocumentUploadComponent from "../helper_components/DocumentUploadComponent";
import config from "../../utils/config";
import amenitiesApi from "../../utils/api/amenitiesapi";
import leaseapi from "../../utils/api/leaseapi";
import Loader from "../helper_components/LoaderComponent";
import _ from "lodash";
import helpers from "../../utils/helpers";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

export default class DocumentsModal extends FormComp {
  handleSelectChange = (selectedType) => {
    let selectedTypeValue = selectedType.value;
    let row = this.state.row;
    row["documents_attributes[][name]"] = selectedTypeValue;
    //row["documents_attributes[][category]"] = null;

    this.setState({
      selectedTypeValue,
      row,
      hideDocumentName: selectedTypeValue === "Others" ? false : true,
    });
  };

  handleAvailable = () => {
    this.setState({
      row: Object.assign(this.state.row, {
        "documents_attributes[][applicable]": !this.state.row[
          "documents_attributes[][applicable]"
        ],
      }),
    });
  };

  formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={{ fontWeight: "bold", color: "black" }}>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  formElements = () => {
    var documentNameOptions;
    // if (this.props.isPayment) {
    //   documentNameOptions =
    //     this.state.row["documents_attributes[][category]"] === "Invoice"
    //       ? config.payment_document_name_invoice
    //       : config.payment_document_name_document;
    // }
    if (this.state.row["documents_attributes[][category]"] === "Accounting") {
      documentNameOptions = config.accounting_document_name;
    } else if (this.state.row["documents_attributes[][category]"] === "Legal") {
      documentNameOptions = config.legal_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] ===
      "Association Correspondence"
    ) {
      documentNameOptions = config.association_correspondence_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] === "Municipal"
    ) {
      documentNameOptions = config.municipal_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] ===
      "Property Management"
    ) {
      documentNameOptions = config.property_management_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] === "Violation"
    ) {
      documentNameOptions = config.violation_document_name;
    } else if (
      this.props.isHoa ||
      this.state.row["documents_attributes[][category]"] === "Architectural"
    ) {
      documentNameOptions = config.architectural_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] === "Governance"
    ) {
      documentNameOptions = config.governing_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] === "Leasing"
    ) {
      documentNameOptions = config.leasing_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] ===
      "Newsletter / Notice"
    ) {
      documentNameOptions = config.newsletterNotice_document_name;
    } else if (
      this.state.row["documents_attributes[][category]"] === "Rules and Policy"
    ) {
      documentNameOptions = config.rulesAndPolicy_document_name;
    } else if (
      !this.props.isHOAAssociationModal &&
      this.state.row["documents_attributes[][category]"] ===
        "Financial / Budget"
    ) {
      documentNameOptions = config.financial_budget_document_name;
    } else if (
      this.props.isHOAAssociationModal &&
      this.state.row["documents_attributes[][category]"] ===
        "Financial / Budget"
    ) {
      documentNameOptions = config.hoa_financial_budget_document_name;
    } else if (this.state.row["documents_attributes[][category]"] === "Deed") {
      documentNameOptions = config.deed_document_name;
    } else if (this.state.row["documents_attributes[][category]"] === "Lien") {
      documentNameOptions = config.lien_document_name;
    } else {
      documentNameOptions = config.documentNames;
    }
    var documentOptions = documentNameOptions.map((c) => {
      return { label: c, value: c };
    });

    const categoryOptions = () => {
      let categories;
      // if (this.props.isPayment) {
      //   categories = config.payment_document_category;
      // }
      if (this.props.isPropertyModal || this.props.isPayment) {
        categories = config.property_document_category;
      } else if (
        this.props.isAssociationModal ||
        this.props.isassociationVerification
      ) {
        categories = config.association_document_category;
      } else if (this.props.isHOAAssociationModal) {
        categories = config.hoa_association_document_category;
      }
      // else if (this.props.isHoa) {
      //   categories = ["Architectural"];
      // }
      else {
        categories = [
          "Architectural",
          "Checklist",
          "Forms",
          "Governance",
          "Policies & Rules",
        ];
      }

      if (this.state.selectedTypeValue === "Others") {
        categories = [
          "Annual Meetings",
          "Architectural",
          "Board Meetings",
          "Community Items",
          "Contracts",
          "Financial / Budget",
          "Forms",
          "Governing Documents",
          "Insurance",
          "Legal",
          "Newsletter",
          "Policies & Rules",
          "Questionnaire",
          "Reserve Study",
        ];
      }

      return categories.map((c) => {
        return { label: c, value: c };
      });
    };

    let groupedOptions = [
      {
        label: "Hoas",
        options: this.props.hoaOptions,
      },
      {
        label: "Office",
        options: this.props.officeOptions,
      },
    ];

    let groupedHoaOptions = [
      {
        label: "Hoas",
        options: this.props.hoaOptions,
      },
    ];

    console.log("Office---", this.props.officeOptions);
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <FormGroup>
            <Label for="">Category</Label>
            {this.props.isHoa ||
            this.props.isLeaseInfo ||
            this.props.isAmenties ? (
              <TextValidator
                type="text"
                name="document_name"
                id="documents_attributes[][name]"
                value={this.props.isHoa ? "Architectural" : "Leasing"}
                disabled={true}
              />
            ) : (
              <Select
                name="document_category"
                id="documents_attributes[][category]"
                value={{
                  value: this.state.row["documents_attributes[][category]"],
                  label: this.state.row["documents_attributes[][category]"],
                }}
                onChange={(selectedType) => {
                  let row = this.state.row;
                  row["documents_attributes[][category]"] = selectedType.value;
                  this.setState({ row });
                }}
                options={categoryOptions()}
                required
              />
            )}
          </FormGroup>

          <FormGroup>
            <Label for="">Name</Label>
            {this.props.isLeaseInfo || this.props.isAmenties ? (
              <TextValidator
                type="text"
                name="document_name"
                id="documents_attributes[][name]"
                value={
                  this.props.isAmenties
                    ? "Parking Permit Form"
                    : this.props.moveInChecklist
                    ? "Move-in Checklist"
                    : "Tenant Registration Form"
                }
                disabled={true}
              />
            ) : (
              <Select
                name="document_name"
                id="documents_attributes[][name]"
                value={{
                  value: this.state.row["documents_attributes[][name]"],
                  label: this.state.row["documents_attributes[][name]"],
                }}
                onChange={this.handleSelectChange}
                options={documentOptions}
              />
            )}
          </FormGroup>

          {this.props.isassociationVerification ||
          this.props.isPropertyModal ||
          this.props.isHoa ||
          this.props.isAmenties ||
          this.props.isLeaseInfo ||
          this.props.isHOAAssociationModal ? (
            <> </>
          ) : (
            <>
              {/* <Select
                style={{ marginBottom: "10px" }}
                options={this.props.hoaOptions ? this.props.hoaOptions : []}
                onChange={(hoa) => {
                  let row = this.state.row;
                  row["documents_attributes[][hoa_id]"] = hoa && hoa.value;
                  hoa && this.setState({ row });
                }}
                placeholder="Select Hoa..."
                className="property-hoa-select"
                isClearable
                validators={["required"]}
                errorMessages={["Please select a HOA"]}
              /> */}
              <Select
                options={
                  this.props.isAssociationModal
                    ? groupedHoaOptions
                    : groupedOptions
                }
                formatGroupLabel={this.formatGroupLabel}
                value={this.state.associable_id}
                onChange={(valueobj) => {
                  let row = this.state.row;
                  if (this.props.isAssociationModal) {
                    row["documents_attributes[][hoa_id]"] = valueobj.value;
                  } else {
                    row["documents_attributes[][associable_id]"] =
                      valueobj.value;
                    row["documents_attributes[][associable_type]"] =
                      valueobj.group;
                  }
                  this.setState({ row });
                }}
                isClearable
                validators={["required"]}
                errorMessages={["Please select a HOA"]}
              />

              {/* <FormGroup>
              <CustomInput
                style={{ padding: "15px" }}
                type="checkbox"
                name="documents_attributes[][applicable]"
                id="documents_attributes[][applicable]"
                label={"Not Applicable"}
                value={false}
                onClick={this.handleAvailable}
                checked={
                  this.state.row["documents_attributes[][applicable]"]
                    ? true
                    : false
                }
              />
            </FormGroup> */}
            </>
          )}

          <DocumentUploadComponent
            row={this.state.row}
            hideDocumentName={this.state.hideDocumentName}
            onChange={this.handleChange}
            showDocuments={true}
            // notApplicable={this.state.row["documents_attributes[][applicable]"]}
          />
        </div>
      );
    }
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (this.props.isPayment) {
      if (this.props.actionTab === "municipalityAction") {
        apicall
          .municipal_paymentUpdate(
            this.state.row,
            this.props.municipality_id,
            this.props.actionID
          )
          .then((response) => {
            if (response) {
              this.notify("Document Uploaded Successfully.");
              this.setState({
                row: response,
              });
              this.props.toggle();
              this.props.fetchDocuments();
            }
          });
      } else {
        apicall
          .paymentUpdate(
            this.state.row,
            this.props.propertyID,
            this.props.actionID
          )
          .then((response) => {
            if (response) {
              this.notify("Document Uploaded Successfully.");
              this.setState({
                row: response,
              });
              this.props.toggle();
              this.props.fetchDocuments();
            }
          });
      }
    } else if (this.props.isassociationVerification) {
      let formdata = { ...this.state.row, ...this.props.formdata };
      apicall
        .updateAssociationVerification(
          formdata,
          this.props.avId,
          this.props.propertyID
        )
        .then((response) => {
          if (response) {
            this.notify("Document Uploaded Successfully.");
            this.props.updateform(response);
            this.props.toggle();
            this.props.fetchDocuments();
          }
        });
    } else if (this.props.isHoa) {
      this.props.isFileUpload();
      let row = this.state.row;
      row["documents_attributes[][category]"] = "Architectural";
      this.setState({
        row: row,
      });
      console.log("The props is", this.props.formdata);
      let formdata = { ...this.state.row, ...this.props.formdata };
      apicall.updateHoa(formdata).then((response) => {
        if (response) {
          this.notify("Document Uploaded Successfully.");
          this.setState({
            row: response,
          });
          this.props.isFileUpload();
          this.props.toggle();
          this.props.fetchDocuments(this.props.hoaID);
        }
      });
    } else if (this.props.isAmenties) {
      // console.log("the row is", this.state.row);
      let row = this.state.row;
      row["documents_attributes[][category]"] = "Leasing";
      row["documents_attributes[][name]"] = "Parking Permit Form";
      this.setState({
        row: row,
      });
      let formdata = { ...this.state.row, ...this.props.formdata };
      amenitiesApi
        .updateAmenities(this.props.hoaId, formdata, this.props.amenitiesId)
        .then((response) => {
          if (response) {
            this.notify("Document Uploaded Successfully.");
            this.setState({
              row: response,
            });
            this.props.toggle();
            this.props.fetchDocuments(this.props.hoaId);
          }
        });
    } else if (this.props.isLeaseInfo) {
      let row = this.state.row;
      row["documents_attributes[][name]"] = this.props.moveInChecklist
        ? "Move-in Checklist"
        : "Tenant Registration Form";
      row["documents_attributes[][category]"] = "Leasing";
      this.setState({
        row: row,
      });

      let formdata = { ...this.state.row, ...this.props.formdata };
      if (
        this.props.leaseInfoId === undefined ||
        this.props.leaseInfoId === null
      ) {
        apicall.createLeaseInfo(formdata, this.props.hoaId).then((res) => {
          this.notify("Updated Successfully");
          this.setState({
            row: res,
          });
          this.props.toggle();
          this.props.fetchDocuments(this.props.propertyId);
        });
      } else {
        apicall
          .updateleaseInfo(formdata, this.props.hoaId, this.props.leaseInfoId)
          .then((response) => {
            if (response) {
              this.notify("Document Uploaded Successfully.");
              this.setState({
                row: response,
              });
              this.props.toggle();
              this.props.fetchDocuments(this.props.propertyId);
            }
          });
      }
    } else if (
      this.props.isAssociationModal ||
      this.props.isHOAAssociationModal
    ) {
      if (this.props.isHOAAssociationModal) {
        this.props.isFileUpload();
      }
      let id = this.props.isHOAAssociationModal
        ? this.props.hoaID
        : this.state.row["documents_attributes[][hoa_id]"];

      if (!_.isUndefined(id)) {
        apicall.addDocuments(id, this.state.row).then((response) => {
          if (response) {
            this.setState({
              row: response,
            });
            if (this.props.isHOAAssociationModal) {
              this.props.isFileUpload();
              this.props.fetchDocuments(this.props.hoaID);
            } else this.props.fetchDocuments();
            this.props.toggle();
          }
        });
      } else {
        this.notify("Please select a HOA");
        this.props.toggle();
      }
    } else {
      apicall
        .propertyDocumentUpload(this.state.row, this.state.propertyID)
        .then((response) => {
          if (response) {
            this.notify("Document Uploaded Successfully.");
            this.setState({
              row: response,
            });
            this.props.toggle();
            this.props.fetchDocuments(this.state.propertyID);
          }
        });
    }
  };
}
