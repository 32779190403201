import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var lienReleaseActionapi = {
  readLienReleaseAction: async function (actionId, propertyID) {
    let url = `${config.baseUrl}/api/v1/lien_releases/${actionId}?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  listAllLienReleaseAction: async function (propertyID) {
    let url = `${config.baseUrl}/api/v1/lien_releases?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  createLienReleaseAction: async (title_lien_id, propertyID, data) => {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/lien_releases?title_lien_id=${title_lien_id}&property_id=${propertyID}`;
    let res = "";

    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if (data[key] === null) {
          data[key] = "";
        }
        form.append("lien_release[" + key + "]", data[key]);
      }
    }
    let init = {
      method: "POST",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },

  updateLienReleaseAction: async (lienReleaseID, propertyID, data) => {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/lien_releases/${lienReleaseID}?property_id=${propertyID}`;
    let res = "";

    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if (data[key] === null) {
          data[key] = "";
        }

        form.append("lien_release[" + key + "]", data[key]);
      }
    }
    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while  Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },

  deleteLienReleaseAction: async function (actionID, propertyID) {
    let url = `${config.baseUrl}/api/v1/lien_releases/${actionID}?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteLienReleaseDocument: async function (actionID, propertyId, documentId) {
    let url =
      config.baseUrl +
      `/api/v1/lien_releases/${actionID}/delete_lien_release_documents?property_id=${propertyId}&document_id=${documentId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};

export default withRouter(lienReleaseActionapi);
