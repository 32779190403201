import React, { Component, useState, useEffect } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import "../../styles/_tab.scss";
import apicall from "../../utils/apicall";
import DocumentsTab from "./DocumentsTab";
import _ from "lodash";
import "../../styles/_documents.scss";
import "../../styles/_displayGuestInfo.scss";
import GuestUserNavbar from "../page_layout_components/GuestUserNavbar";
import PropertyInfo from "../general_info/GuestPropertyInfo";

export default (props) => {
  const [documents, set_Documents] = useState([]);

  const [hoaOptions, set_HOA] = useState();
  const [hoaID, set_HOA_ID] = useState(props.hoaID);
  const [associationDocs, setassociationDocs] = useState([]);
  const [propertyInfo, setpropertyInfo] = useState(null);
  const [hoa_status, sethoa_status] = useState();
  const [leasing_permitted, setleasing_permitted] = useState();
  const [leasing_info_applicable, setleasing_info_applicable] = useState();
  const [hoaId, sethoaId] = useState();

  useEffect(() => {
    //fetchDocuments();
    fetchAssociationDocuments();
    fetchPropertyDocuments();
  }, [hoaID]);

  useEffect(() => {
    //fetchHoas();
    fetchAssociationDocuments();
    fetchPropertyDocuments();
    fetchPropertyInfo();
    getPropertyInfo(props.match.params.propertyID);
  }, []);

  const fetchHoas = () => {
    apicall
      .getHoaByPropertyID(props.match.params.propertyID, 1, 20)
      .then((response) => {
        let hoaOptions = [];
        hoaOptions = response.results.map((x) => {
          return { label: x.name, value: x.id };
        });
        set_HOA(hoaOptions);
        console.log(hoaOptions);
      });
  };

  const fetchDocuments = () => {
    apicall
      .propertyDocumentsFetch(props.match.params.propertyID, hoaID)
      .then((resp) => {
        set_Documents(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const fetchPropertyDocuments = () => {
    apicall
      .getPropertyDocuments(props.match.params.propertyID)
      .then((resp) => {
        set_Documents(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const fetchAssociationDocuments = () => {
    apicall
      .getPropertyAssociationDocuments(props.match.params.propertyID)
      .then((resp) => {
        setassociationDocs(resp.results);
      })
      .catch((e) => console.log(e));
  };

  const deleteHoaDocument = (id, documentId) => {
    apicall.deleteHoaDoc(id, documentId).then((response) => {
      fetchAssociationDocuments();
    });
  };
  const fetchPropertyInfo = () => {
    apicall
      .guestPropertyInfo(props.match.params.propertyID)
      .then((response) => {
        if (response) {
          let masterHOA = null;
          if (response && response.total == 1) {
            masterHOA = response.results[0];
          } else if (response && response.total > 1) {
            masterHOA = response.results.find((x) => x.status === "Master");
          }
          if (masterHOA !== null) {
            sethoaId(masterHOA.id);
            setleasing_info_applicable(masterHOA.leasing_info_applicable);
          }

          sethoa_status(response.hoa_status);
          setleasing_permitted(response.leasing_permitted);
        }
      });
  };

  const getPropertyInfo = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      setpropertyInfo(response);
    });
  };

  return (
    <>
      <GuestUserNavbar
        isGuestUser={true}
        propertyID={props.match.params.propertyID}
        {...props}
      />
      <PropertyInfo
        propertyInfo={propertyInfo}
        // actionArray={actionArray}
        className="property-data"
        hideLink={true}
        isGuestUser={true}
        hoa_status={hoa_status}
        isHoaStatus={true}
        leasing_permitted={leasing_permitted}
        leasing_info_applicable={leasing_info_applicable}
        hoaId={hoaId}
      />

      <div>
        <div className="document-manage">
          <div className="document-title">
            <h5 className="document-heading">Association Documents</h5>
            <div className="add-button">
              {/* <Button onClick={() => set_openAssociationDocumentModal(true)}>
              File Upload
            </Button> */}
            </div>
          </div>
        </div>
        <DocumentsTab
          propertyID={props.match.params.propertyID}
          documents={associationDocs}
          fetchDocuments={fetchAssociationDocuments}
          isGuestAssociationModal={true}
          deleteDocument={deleteHoaDocument}
        />
        {/* <hr />
        <div className="document-manage">
          <div className="document-title">
            <h5 className="document-heading">Property Documents</h5>
            <div className="add-button"> */}
        {/* <Button onClick={() => set_openPropertyDocumentModal(true)}>
              File Upload
            </Button> */}
        {/* </div>
          </div>
        </div>
        <DocumentsTab
          propertyID={props.match.params.propertyID}
          documents={documents}
          fetchDocuments={fetchPropertyDocuments}
          isGuestPropertyModal={true}
        /> */}
      </div>
    </>
  );
};
