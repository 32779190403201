import React, { Component } from "react";
import "../../styles/navbar.scss";
import resicap_logo from "../../images/resicap_aims_logo.png";
import avatar from "../../images/avatar.jpg";
import apicall from "../../utils/apicall";
import config from "../../utils/config";
import EditUserModal from "../analysts/EditUserModal";
import { Form, FormGroup, Col, Input, Button } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
// import PropertySearchComponent from "../properties/property-search/PropertySearchComponent";
// import PropertySearchTable from "../properties/property-search/PropertySearchTable";
// import ManagmentCompaniesComponent from "../management_companies/ManagementCompaniesComponent";
// import HoasComponent from "../hoas/HoasComponent";
// import { Link } from "react-router-dom";
import QuickStatusModal from "./QuickStatusModal";

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      openModal: false,
      userid: "",
      mobile: "",
      option: [
        {
          value: "case_number",
          label: "Case Number",
        },
        { value: "hoa", label: "HOA" },
        { value: "mc", label: "MC" },
        { value: "municipality", label: "Municipality" },
        { value: "property", label: "Property" },
      ],
      selectedSearchOption: "Property",
      searchText: this.props.match.params.searchText,
      pageNo: 1,
      showhoaSearchResult: false,
      showPropertySearchResult: false,
      showMCSearchResult: false,
      user_role: "",
      openQuickStatusModal: false,
    };
  }

  componentWillMount() {
    config.localforage.getItem("email").then((resp) => {
      this.setState({
        email: resp,
      });
    });
    config.localforage.getItem("userid").then((resp) => {
      this.setState({
        userid: resp,
      });
    });
    config.localforage.getItem("mobile").then((resp) => {
      this.setState({
        mobile: resp,
      });
    });
    config.localforage.getItem("user_role").then((resp) => {
      this.setState({
        user_role: resp,
      });
    });
    this.setSelectedOption();
    // console.log("the props", this.props);
    // this.setState({
    //   searchText: this.props.match.params.searchText,
    // });
  }

  refereshData = (mobile) => {
    this.setState({
      mobile: mobile,
    });
  };

  toggleModal = () => {
    console.log("togllellllll");
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  toggleQuickStatusModal = () => {
    this.setState({
      openQuickStatusModal: !this.state.openQuickStatusModal,
    });
  };

  handleChange = (event, key) => {
    if (key === true)
      this.setState({
        selectedSearchOption: event.label,
      });
    else {
      this.setState({
        searchText: event.target.value,
      });
    }
  };

  setSelectedOption = () => {
    let selectedOptionValue;
    if (this.props.path.split("/")[1] === "hoa") selectedOptionValue = "HOA";
    else if (this.props.path.split("/")[1] === "management")
      selectedOptionValue = "MC";
    else if (this.props.path.split("/")[1] === "municipality")
      selectedOptionValue = "Municipality";
    else if (this.props.path.split("/")[1] === "case_number")
      selectedOptionValue = "Case Number";
    else selectedOptionValue = "Property";
    this.setState({
      selectedSearchOption: selectedOptionValue,
    });
  };

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.handleSubmitForSearch();
    }
  }

  handleSubmitForSearch = (event, pageNo, perPage) => {
    if (!_.isEmpty(this.state.searchText)) {
      if (this.state.selectedSearchOption === "MC") {
        this.props.history.push(
          "/management/global/" +
            this.state.searchText +
            "/" +
            this.state.user_role
        );
      } else if (this.state.selectedSearchOption === "HOA") {
        this.props.history.push(
          "/hoa/global/" + this.state.searchText + "/" + this.state.user_role
        );
      } else if (this.state.selectedSearchOption === "Municipality") {
        this.props.history.push(
          "/municipality/global/" +
            this.state.searchText +
            "/" +
            this.state.user_role
        );
      } else if (this.state.selectedSearchOption === "Property") {
        this.props.history.push(
          "/property/global/" +
            this.state.searchText +
            "/" +
            this.state.user_role
        );
      } else if (this.state.selectedSearchOption === "Case Number") {
        this.props.history.push(
          "/case_number/global/" +
            this.state.searchText +
            "/" +
            this.state.user_role
        );
      }
    }
  };

  render() {
    let searchOptions = [];
    searchOptions.push(
      <option value="" key="defaultValue">
        Select Search Type
      </option>
    );
    for (let searchOption in this.state.option) {
      searchOptions.push(
        <option value={searchOption} key={searchOption}>
          {this.state.option[searchOption]}
        </option>
      );
    }

    return (
      <React.Fragment>
        <header className="header">
          <div
            className="header__logo hidden-sm-down header-logo"
            id="headerleft"
          >
            <img src={resicap_logo} alt="Resicap Logo" />
          </div>
          <div className="user-div">
            <div className="users-sub-div">
              <p>{this.state.email}</p>
              <p className="logout-btn" onClick={() => apicall.logout()}>
                Logout
              </p>
            </div>
            <div className="userimg">
              <img
                className="user__img"
                src={avatar}
                alt="Avatar "
                onClick={this.toggleModal}
              />
            </div>
          </div>
          <div
            className="search_type_selection_div"
            //style={{ width: "10%", marginLeft: "5%" }}
          >
            <Select
              options={this.state.option}
              placeholder="Search Type"
              onChange={(event) => this.handleChange(event, true)}
              value={{
                value: this.state.selectedSearchOption,
                label: this.state.selectedSearchOption,
              }}
            />
          </div>
          <div className="searchBar" style={{ marginLeft: "1rem" }}>
            {/* <Form onSubmit={this.handleSubmitForSearch}> */}
            <FormGroup row>
              <Col sm={8}>
                <Input
                  type="address"
                  name="address"
                  id="search"
                  placeholder=" Search"
                  onKeyUp={this.handleKeyPress.bind(this)}
                  onChange={this.handleChange}
                  value={
                    this.state.searchText !== "null"
                      ? this.state.searchText
                      : ""
                  }
                />
              </Col>
              <Col sm={2}>
                <Button
                  className="search-btn"
                  onClick={this.handleSubmitForSearch}
                >
                  Search
                </Button>
              </Col>
            </FormGroup>

            {/* </Form> */}
          </div>
          <div className="quickStatus">
            <Button
              onClick={() => this.setState({ openQuickStatusModal: true })}
              className=""
            >
              Quick Status
            </Button>
          </div>
        </header>
        {this.state.openModal && (
          <EditUserModal
            toggle={this.toggleModal}
            modal={this.state.openModal}
            mobile={this.state.mobile}
            id={this.state.userid}
            refereshData={this.refereshData}
          />
        )}
        {this.state.openQuickStatusModal && (
          <QuickStatusModal
            toggle={this.toggleQuickStatusModal}
            modal={this.state.openQuickStatusModal}
            refereshData={this.refereshData}
          />
        )}
      </React.Fragment>
    );
  }
}
