import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
//import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";
export default class PropertyListTable extends Component {
  constructor(props) {
    super(props);
  }

  formatDate = (cell) => {
    return helpers.formatDate(cell);
  };

  render() {
    let properties = this.props.properties;

    const columns = [
      {
        dataField: "transfer_date",
        text: "Transfer Date",
        formatter: this.formatDate,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "clientid",
        text: "Client ID",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "entity_name",
        text: "Entity Name",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "property_group",
        text: "Property Group",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "status",
        text: "Asset Status",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "street_address",
        text: "Street Address",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "city",
        text: "City",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "state",
        text: "State",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "2%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "zip",
        text: "Zip",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "2%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "county",
        text: "County",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "acquisition_type",
        text: "Acquisition Type",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "acquisition_date",
        text: "Acquisition Date",
        formatter: this.formatDate,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "mortgage_amount",
        text: "Mortgage Amount",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
    ];

    return (
      <PaginationComponent
        columns={columns}
        rows={properties}
        handleSubmit={this.props.handleSubmit}
        pageNo={this.props.pageNo}
        totalCount={this.props.totalCount}
      />
    );
  }
}
