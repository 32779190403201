import React, { Component, Fragment } from "react";
import "../../../styles/_inspections.scss";
import apicall from "../../../utils/apicall";
import Select from "react-select";
import { toast } from "react-toastify";
import Loader from "../../helper_components/LoaderComponent";
import config from "../../../utils/config";
import { CustomInput } from "reactstrap";
import DatePicker from "react-datepicker";
import helpers from "../../../utils/helpers";

export default class Inspections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyid: props.propertyid,
      categoryTypes: null,
      selectedOption: null,
      InspectioncategoriesArray: null,
      intervals: null,
      isLoading: true,
      startDate: new Date(),
      radioButtonStatus: false,
      dateSelected: null,
      hoaId: props.hoaId,
    };
  }

  componentDidMount() {
    apicall.getInspectionCategory(this.state.hoaId).then((resp) => {
      this.setState({
        categoryTypes: resp,
        isLoading: false,
      });
    });
    this.listInspectionCategories(this.state.hoaId, this.state.propertyid);
    this.getIntervals(this.state.hoaId);
  }

  notify = (msg) => toast(msg);

  createInspectionCategory = (propertyid, inspection_category, hoaId) => {
    apicall
      .createInspectionCategory(this.state.hoaId, inspection_category)
      .then((resp) => {
        if (resp.hasOwnProperty("errors")) {
          this.notify("Inspection Category Creation Failed");
        } else {
          this.notify("Inspection Category Created");
          this.listInspectionCategories(hoaId, propertyid);
        }
      });
  };

  listInspectionCategories = (hoaId, propertyid) => {
    apicall.listInspectionCategories(hoaId, propertyid).then((resp) => {
      this.setState({
        InspectioncategoriesArray: resp.results,
      });
    });
  };

  getIntervals = (hoaId) => {
    apicall.getIntervals(hoaId).then((resp) => {
      this.setState({
        intervals: resp,
      });
    });
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }

    //event.preventDefault();
  }

  handleSubmit = (event, inspectionid) => {
    event.preventDefault();
    apicall
      .updateInspectionCategory(
        this.state.hoaId,
        this.state.InspectioncategoriesArray
      )
      .then((resp) => {
        if (resp.hasOwnProperty("errors")) {
          this.notify("Interval Update Failed");
        } else {
          this.notify("Interval updated");
          this.listInspectionCategories(
            this.state.hoaId,
            this.state.propertyid
          );
        }
      });
  };

  handleDelete = (inspectionid) => {
    apicall
      .deleteInspectionCategory(inspectionid, this.state.propertyid)
      .then((resp) => {
        if (resp.hasOwnProperty("errors")) {
          this.notify("Deletion Failed");
        } else {
          this.notify("Inspection Category deleted");
          this.listInspectionCategories(
            this.state.hoaId,
            this.state.propertyid
          );
        }
      });
  };

  handleRadio = (e, category, require_inspection) => {
    if (e.target.value === "true") {
      require_inspection = true;
    } else if (e.target.value === "false") {
      require_inspection = false;
    }

    let newArray = this.state.InspectioncategoriesArray;
    let index = newArray.findIndex((x) => x.inspection_category == category);
    newArray[index].require_inspection = require_inspection;
    this.setState({
      InspectioncategoriesArray: newArray,
    });
  };

  handleSelect = (e, id) => {
    let newArray = this.state.InspectioncategoriesArray;
    let index = newArray.findIndex((x) => x.id == id);
    newArray[index].inspection_interval = e.value;
    this.setState({
      InspectioncategoriesArray: newArray,
    });
  };

  handleDate = (date, id) => {
    let newArray = this.state.InspectioncategoriesArray;
    let index = newArray.findIndex((x) => x.id == id);
    newArray[index].last_inspection_date = date;
    this.setState({
      InspectioncategoriesArray: newArray,
    });
  };

  listCategoriesDiv = () => {
    let InspectioncategoriesArray = this.state.InspectioncategoriesArray;
    let intervalsArray = this.state.intervals;
    let Inspectiondiv = InspectioncategoriesArray.map(
      (inspectionobject, index) => {
        let inspectionName = inspectionobject.inspection_name;
        let inspectionid = inspectionobject.id;
        let inspectionCategory = inspectionobject.inspection_category;
        let last_inspection_date = inspectionobject.last_inspection_date;
        let require_inspection = inspectionobject.require_inspection;
        let value = {
          label: inspectionobject.interval_name,
          value: inspectionobject.inspection_interval,
        };
        if (value.label === null) {
          value.label = "Select Interval..";
          value.value = "null";
        }
        return (
          <div className="container">
            <form onSubmit={this.handleSubmit}>
              <div className="row row-items" key={"interval" + index}>
                <span className="col-lg-4 right-align">{inspectionName}</span>
                {/* <input
                                    type="radio"
                                    id="yes"
                                    name={inspectionCategory}
                                    className="radio-button"
                                    value="Yes"
                                    onChange={(e) => this.handleRadio(e, inspectionCategory, require_inspection)}
                                    checked={require_inspection ? true : false}
                                />

                                <span className="text-yes">Yes</span>
                                <input
                                    type="radio"
                                    id="no"
                                    name={inspectionCategory}
                                    className="radio-button"
                                    value="No"
                                    onChange={(e) => this.handleRadio(e, inspectionCategory, require_inspection)}
                                    checked={require_inspection ? false : true}
                                />
                                <span className="text-no">No</span> */}
                <div className="inspection-radio">
                  <CustomInput
                    id={`${inspectionCategory}yes`}
                    type="radio"
                    name={inspectionCategory}
                    value={true}
                    onClick={(e) =>
                      this.handleRadio(
                        e,
                        inspectionCategory,
                        require_inspection
                      )
                    }
                    checked={require_inspection === true ? true : false}
                  />
                  <label>Yes</label>
                  <CustomInput
                    id={`${inspectionCategory}no`}
                    type="radio"
                    name={inspectionCategory}
                    value={false}
                    onClick={(e) =>
                      this.handleRadio(
                        e,
                        inspectionCategory,
                        require_inspection
                      )
                    }
                    checked={require_inspection === false ? true : false}
                  />
                  <label>No</label>
                </div>

                {inspectionobject.require_inspection && (
                  <div className="dropdown-date-row-align">
                    <span className="select-dropdown-width">
                      {intervalsArray && (
                        <Select
                          options={intervalsArray}
                          placeholder="Select Interval"
                          defaultValue={value}
                          isDisabled={
                            config["generalInfoRead"].includes(
                              this.props.userRole
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => this.handleSelect(e, inspectionid)}
                        />
                      )}
                    </span>
                    {/* <div className="display-in-same-row">
                      Date of last Inspection&nbsp;&nbsp;
                      <span className="date-width">
                        <DatePicker
                          selected={
                            last_inspection_date
                              ? helpers.restructureDate(last_inspection_date)
                              : null
                          }
                          onChange={(date) =>
                            this.handleDate(date, inspectionid)
                          }
                        ></DatePicker>
                      </span>
                    </div> */}
                  </div>
                )}
              </div>
            </form>
          </div>
        );
      }
    );
    return Inspectiondiv;
  };

  render() {
    var radios = document.getElementsByTagName("input");
    for (let i = 0; i < radios.length; i++) {
      radios[i].onclick = function (e) {
        if (e.ctrlKey) {
          this.checked = false;
        }
      };
    }

    return (
      <div className="inspections">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="inspections-intervals">
            {this.state.InspectioncategoriesArray && this.listCategoriesDiv()}

            <div style={{ marginLeft: "75%" }}>
              <button
                type="submit"
                className="btn btn-secondary"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
