import React, { Component, Fragment } from "react";
import "../../../styles/_lease-info.scss";
import config from "../../../utils/config";
import apicall from "../../../utils/apicall";
import DocumentsModal from "../../documents/DocumentsModal";
import DocumentsTab from "../../documents/DocumentsTab";
import _ from "lodash";
import { CustomInput, Button, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import helpers from "../../../utils/helpers";
//import "../../../styles/_association-verification.scss";

const initialState = {
  at_lease_cap: null,
  owner_reside_before_lease: null,
  own_before_leasing_min: null,
  senior_living_comunity: null,
  lease_max_times_per_year: null,
  lease_max: null,
  lease_license_required: null,
  rental_fee_required: null,
  lease_approval_required: null,
  association_app_fee_required: null,
  copy_of_lease_required: null,
  tenant_approval_required: null,
  move_in_fee_required: null,
  pet_allowed: null,
  bg_check_required: null,
  tenant_application_required: null,
  association_move_in_checklist: null,
  check_in_documents: [],
  critical_rental_restrictions: null,
  lease_copy_submitted_at: null,
  lease_license_address: null,
  lease_max_months: null,
  lease_max_times_per_year_value: null,
  lease_min: null,
  lease_min_months: null,
  max_time_for_tenant_approval: null,
  min_age_for_leasing: null,
  min_age_for_leasing_months: null,
  move_in_fee_amount: null,
  own_before_leasing_min_months: null,
  rental_fee_amount: null,
  association_app_fee: null,
  pet_restrictions: null,
  bg_check_responsiblilty: null,
  senior_living_comunity: null,
  status: null,
  tenant_documents: [],
};

//Move all inline css to external file

class FinanceLeaseInfo extends Component {
  constructor(props) {
    //console.log("------------>", props);
    super(props);
    this.state = {
      leaseInfo: initialState,
      openTenantDocumentModal: false,
      opencheckListDocumentModal: false,
      leaseInfoObject: {},
      refreshList: this.loadLeaseInfo,
      isLoading: true,
      leasing_info_applicable: props.leasing_info_applicable,
      feePlaceHolder: "0.00",
    };
    this.start = 0;
  }

  componentDidMount() {
    if (!_.isUndefined(this.props.hoaId)) this.getLeaseInfo();
  }

  getLeaseInfo = () => {
    apicall.getLeaseInfo(this.props.hoaId).then((res) => {
      let x = Object.assign({ ...this.state.leaseInfo }, res.results);
      if (!_.isEmpty(res.results))
        this.setState({
          leaseInfo: x,
          // leasing_info_applicable: true,
        });
    });
  };

  //API to update HOA data
  handleHoaSubmit = (val) => {
    var requestObject = {
      leasing_info_applicable: val,
      id: this.props.hoaId,
    };
    apicall.updateHoa(requestObject).then((response) => {
      if (response) {
        console.log(response);
        this.props.updateHoaProps(response);
      }
    });
  };

  handleSectionOneRadio = (val) => {
    this.setState({ leasing_info_applicable: val });
    this.handleHoaSubmit(val);
  };

  toggleTenantDocument = () => {
    this.setState({
      openTenantDocumentModal: !this.state.openTenantDocumentModal,
    });
  };
  toggleCheckListDocument = () => {
    this.setState({
      opencheckListDocumentModal: !this.state.opencheckListDocumentModal,
    });
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  //API to update leasing info
  handleSubmit = (e) => {
    let isUpdate = this.state.leaseInfo.id ? true : false;
    if (isUpdate) {
      apicall
        .updateleaseInfo(
          this.state.leaseInfo,
          this.props.hoaId,
          this.state.leaseInfo.id
        )
        .then((res) => {
          let x = Object.assign({ ...this.state.leaseInfo }, res.results);
          helpers.notify("Updated");
          this.setState({ leaseInfo: x });
          this.getLeaseInfo();
        });
    } else {
      apicall
        .createLeaseInfo(this.state.leaseInfo, this.props.hoaId)
        .then((res) => {
          let x = Object.assign({ ...this.state.leaseInfo }, res.results);
          helpers.notify("Updated");
          this.setState({ leaseInfo: x });
          this.getLeaseInfo();
        });
    }
  };

  handleRadio = (key, value) => {
    this.setState({
      leaseInfo: Object.assign({ ...this.state.leaseInfo }, { [key]: value }),
    });
  };

  handleChange = (e) => {
    if (
      e.target.name === "rental_fee_amount" ||
      e.target.name === "move_in_fee_amount" ||
      e.target.name === "association_app_fee"
    ) {
      this.start = e.target.selectionStart;
      let val = e.target.value;
      val = val.replace(/([^0-9.]+)/, "");
      val = val.replace(/^(0|\.)/, "");
      const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
      const value = match[1] + match[2];
      e.target.value = value;
      this.setState({
        leaseInfo: Object.assign(
          { ...this.state.leaseInfo },
          { [e.target.name]: value }
        ),
      });

      if (val.length > 0) {
        e.target.value = Number(value).toFixed(2);
        e.target.setSelectionRange(this.start, this.start);
        this.setState({
          leaseInfo: Object.assign(
            { ...this.state.leaseInfo },
            { [e.target.name]: Number(value).toFixed(2) }
          ),
        });
      }
    } else {
      this.setState({
        leaseInfo: Object.assign(
          { ...this.state.leaseInfo },
          { [e.target.name]: e.target.value }
        ),
      });
    }
  };

  handleDate = (date) => {
    this.setState({
      leaseInfo: Object.assign(
        { ...this.state.leaseInfo },
        { lease_copy_submitted_at: date }
      ),
    });
  };

  deleteLeaseInfoDoc = (documentId, hoaId, leaseInfoId) => {
    apicall.deleteLeaseInfoDoc(hoaId, leaseInfoId, documentId).then((resp) => {
      this.getLeaseInfo(hoaId);
    });
  };

  listSectionOneQuery = () => {
    let leasing_info_applicable = this.state.leasing_info_applicable;
    return (
      <div className="lease-radio-question">
        <div className="lease-question">
          <div className="lease-text">Is Property Leasing Permitted?</div>
          <div className="lease-radio">
            <CustomInput
              type="radio"
              id={`sectionOne`}
              name="leasing_info_applicable"
              onClick={() => this.handleSectionOneRadio(true)}
              checked={leasing_info_applicable === true ? true : false}
              disabled={this.state.isDisabled}
            />
            <label>Yes</label>
            <CustomInput
              id={`sectionOne-no`}
              type="radio"
              name="leasing_info_applicable"
              onClick={() => this.handleSectionOneRadio(false)}
              checked={leasing_info_applicable === false ? true : false}
              disabled={this.state.isDisabled}
              className="gap-between-yes-no"
            />
            <label>No</label>
          </div>
        </div>
      </div>
    );
  };

  listSecondSectionQueries = () => {
    let leaseInfo = this.state.leaseInfo;
    const leaseObj = config.leasingSectionTwoObj;
    let leaseDiv = _.map(leaseInfo, (val, lease) => {
      let nextQues = leaseObj[lease];
      if (!_.isEmpty(config.leasingMap[lease]) && _.has(leaseObj, lease)) {
        return (
          // <div className="row">
          //   <div className="col-lg-5 right-align">
          //     <label>{config.leasingMap[lease]}</label>
          //   </div>

          //   <div className="col-lg-2 display-in-same-row">
          <div className="lease-radio-question">
            <div className="lease-question">
              <div className="lease-text">{config.leasingMap[lease]}</div>
              <div className="lease-radio">
                <CustomInput
                  type="radio"
                  id={`${lease}-yes`}
                  name={lease}
                  onClick={() => this.handleRadio(lease, true)}
                  checked={val === true ? "checked" : ""}
                  disabled={this.state.isDisabled}
                />
                <label>Yes</label>
                <CustomInput
                  id={`${lease}-no`}
                  type="radio"
                  name={lease}
                  checked={val === false ? "checked" : ""}
                  onClick={() => this.handleRadio(lease, false)}
                  disabled={this.state.isDisabled}
                  className="gap-between-yes-no"
                />
                <label>No</label>
              </div>
            </div>

            {val === true && (
              <>
                {config.leasingMap[nextQues] ===
                  "Number of months a unit must be owned before leasing" && (
                  <div className="input-fields">
                    <label>{config.leasingMap[nextQues]}</label>

                    <CustomInput
                      className="min-month-unit-own-leasing-width"
                      type="number"
                      pattern="/^-?\d*$/"
                      min="1"
                      max="999"
                      maxLength="3"
                      onInput={this.maxLengthCheck}
                      //placeholder="##"
                      name="own_before_leasing_min_months"
                      onChange={this.handleChange}
                      value={this.state.leaseInfo.own_before_leasing_min_months}
                      disabled={this.props.isGuestProperty ? true : ""}
                      style={{ width: "10%" }}
                    />

                    <label
                      style={{
                        marginLeft: "5px",
                        marginRight: "-2.5rem",
                      }}
                    >
                      months
                    </label>
                  </div>
                )}

                {config.leasingMap[nextQues] ===
                  "Number of times a unit may be leased in a year?" && (
                  <div className="input-fields" style={{ marginRight: "5rem" }}>
                    <label> {config.leasingMap[nextQues]}</label>

                    <CustomInput
                      className="textbox-width"
                      type="number"
                      pattern="/^-?\d*$/"
                      min="1"
                      max="999"
                      maxLength="3"
                      onInput={this.maxLengthCheck}
                      //placeholder="##"
                      name="lease_max_times_per_year_value"
                      onChange={this.handleChange}
                      value={
                        this.state.leaseInfo.lease_max_times_per_year_value
                      }
                      disabled={this.props.isGuestProperty ? true : ""}
                      style={{ width: "10%" }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        );
      }
    });

    return leaseDiv;
  };

  listThirdSectionQueries = () => {
    let leaseInfo = this.state.leaseInfo;
    const leaseObj = config.leasingSectionThreeObj;
    let leaseDiv = _.map(leaseInfo, (val, lease) => {
      let nextQues = leaseObj[lease];
      if (!_.isEmpty(config.leasingMap[lease]) && _.has(leaseObj, lease)) {
        return (
          <>
            <div className="lease-radio-question">
              <div className="lease-question">
                <div className="lease-text">{config.leasingMap[lease]}</div>
                <div className="lease-radio">
                  <CustomInput
                    type="radio"
                    id={`${lease}-yes`}
                    name={lease}
                    onClick={() => this.handleRadio(lease, true)}
                    checked={val === true ? "checked" : ""}
                    disabled={this.state.isDisabled}
                  />
                  <label>Yes</label>
                  <CustomInput
                    id={`${lease}-no`}
                    type="radio"
                    name={lease}
                    checked={val === false ? "checked" : ""}
                    onClick={() => this.handleRadio(lease, false)}
                    disabled={this.state.isDisabled}
                    className="gap-between-yes-no"
                  />
                  <label>No</label>
                </div>
              </div>

              {val === true &&
                nextQues !== "tenant_documents" &&
                nextQues !== "check_in_documents" && (
                  <>
                    {config.leasingMap[nextQues] ===
                      "Township,City or Municipality name?" && (
                      <div className="input-fields">
                        <label> {config.leasingMap[nextQues]}</label>

                        <CustomInput
                          className="textbox-width"
                          type="text"
                          //placeholder="city,town,municipality"
                          name="lease_license_address"
                          onChange={this.handleChange}
                          value={this.state.leaseInfo.lease_license_address}
                        />
                      </div>
                    )}
                    {config.leasingMap[nextQues] ===
                      "How much is the rental fee?" && (
                      <div className="input-fields">
                        <label>{config.leasingMap[nextQues]}</label>

                        <CustomInput
                          className="textbox-width"
                          type="text"
                          pattern="^\d{0,7}(\.\d{1,2})?$"
                          //placeholder={this.state.feePlaceHolder}
                          onChange={this.handleChange}
                          name="rental_fee_amount"
                          value={this.state.leaseInfo.rental_fee_amount}
                          style={{ width: "15%" }}
                        />
                      </div>
                    )}

                    {config.leasingMap[nextQues] ===
                      "Association Application Fee" && (
                      <div className="input-fields">
                        <label>{config.leasingMap[nextQues]}</label>
                        <CustomInput
                          className="textbox-width"
                          type="text"
                          pattern="^\d{0,7}(\.\d{1,2})?$"
                          // placeholder={this.state.feePlaceHolder}
                          onChange={this.handleChange}
                          name="association_app_fee"
                          value={this.state.leaseInfo.association_app_fee}
                          style={{ width: "15%" }}
                        />
                      </div>
                    )}

                    {config.leasingMap[nextQues] ===
                      "Date a copy of the lease was provided" && (
                      <div className="input-fields">
                        <label> {config.leasingMap[nextQues]}</label>

                        <DatePicker
                          //placeholderText="mm-dd-yyyy"
                          dateFormat="MM/dd/yyyy"
                          selected={
                            this.state.leaseInfo.lease_copy_submitted_at
                              ? helpers.restructureDate(
                                  this.state.leaseInfo.lease_copy_submitted_at
                                )
                              : null
                          }
                          onChange={(date) => this.handleDate(date)}
                          className="date-picker"
                        />
                      </div>
                    )}

                    {config.leasingMap[nextQues] ===
                      "Maximum length of time for board to approve a tenant?" && (
                      <>
                        {" "}
                        <div className="input-fields">
                          <label> {config.leasingMap[nextQues]}</label>
                          <CustomInput
                            className="textbox-width"
                            type="number"
                            pattern="/^-?\d*$/"
                            min="1"
                            max="999"
                            maxLength="3"
                            onInput={this.maxLengthCheck}
                            //placeholder="##"
                            name="max_time_for_tenant_approval"
                            onChange={this.handleChange}
                            value={
                              this.state.leaseInfo.max_time_for_tenant_approval
                            }
                            style={{ width: "10%" }}
                          />

                          <label
                            style={{
                              marginLeft: "5px",
                              marginRight: "-2.5rem",
                            }}
                          >
                            days
                          </label>
                        </div>
                      </>
                    )}

                    {config.leasingMap[nextQues] ===
                      "How much is the move-in fee?" && (
                      <div className="input-fields">
                        <label> {config.leasingMap[nextQues]}</label>

                        <CustomInput
                          className="move-in-fee-textbox"
                          type="text"
                          pattern="\d{0,7}(\.\d{1,2})?$"
                          //placeholder={this.state.feePlaceHolder}
                          name="move_in_fee_amount"
                          onChange={this.handleChange}
                          value={this.state.leaseInfo.move_in_fee_amount}
                          style={{ width: "15%" }}
                        />
                      </div>
                    )}

                    {config.leasingMap[nextQues] === "Pet Restrictions" && (
                      <div className="input-fields">
                        <label>{config.leasingMap[nextQues]}</label>
                        <Input
                          style={{ width: "60%" }}
                          type="textarea"
                          id="pet_restrictions"
                          // placeholder="pet restrictions"
                          name="pet_restrictions"
                          onChange={this.handleChange}
                          value={this.state.leaseInfo.pet_restrictions}
                        />
                      </div>
                    )}

                    {config.leasingMap[nextQues] ===
                      "Background Check Responsibility" && (
                      <div className="input-fields">
                        <label>{config.leasingMap[nextQues]}</label>
                        <Input
                          style={{ width: "60%" }}
                          type="textarea"
                          id="bg_check_responsiblilty"
                          // placeholder="background check responsiblilty"
                          name="bg_check_responsiblilty"
                          onChange={this.handleChange}
                          value={this.state.leaseInfo.bg_check_responsiblilty}
                        />
                      </div>
                    )}
                  </>
                )}

              {val === true && nextQues === "tenant_documents" && (
                <>
                  <div className="file-upload-button-div">
                    <Button
                      onClick={() =>
                        this.setState({ openTenantDocumentModal: true })
                      }
                      className="file-upload-button"
                    >
                      Upload Tenant Application
                    </Button>
                    &nbsp;
                    {_.isEmpty(this.state.leaseInfo.documents) ? (
                      <h6 className="no-Document" style={{ marginLeft: "5px" }}>
                        No File Uploaded
                      </h6>
                    ) : (
                      <></>
                    )}
                  </div>

                  {this.state.openTenantDocumentModal && (
                    <DocumentsModal
                      toggle={this.toggleTenantDocument}
                      title="Document"
                      open={this.state.openTenantDocumentModal}
                      pageNo={1}
                      hideDocumentName={true}
                      isLeaseInfo={true}
                      hoaId={this.props.hoaId}
                      leaseInfoId={this.state.leaseInfo.id}
                      fetchDocuments={this.getLeaseInfo}
                      leaseInfo={this.state.leaseInfo}
                      formdata={this.state.leaseInfo}
                      tenantApplication={true}
                    />
                  )}
                  {/* <div className="lease-document-table">
                    <DocumentsTab
                      documents={this.state.leaseInfo.tenant_documents}
                      isLeaseInfo={true}
                      theme={"general-info-tab"}
                      deleteDocument={this.deleteLeaseInfoDoc}
                      propertyId={this.props.propertyid}
                      leaseInfoId={this.state.leaseInfo.id}
                    />
                  </div> */}
                </>
              )}

              {val === true && nextQues === "check_in_documents" && (
                <>
                  <div className="file-upload-button-div">
                    {/* Upload Move-in checklist &nbsp;&nbsp;&nbsp; */}
                    <Button
                      onClick={() =>
                        this.setState({ opencheckListDocumentModal: true })
                      }
                      className="file-upload-button"
                    >
                      Upload Move-in Checklist
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {_.isEmpty(this.state.leaseInfo.documents) ? (
                      <h6 className="no-Document">No File Uploaded</h6>
                    ) : (
                      <></>
                    )}
                  </div>

                  {this.state.opencheckListDocumentModal && (
                    <DocumentsModal
                      toggle={this.toggleCheckListDocument}
                      title="Document"
                      open={this.state.opencheckListDocumentModal}
                      pageNo={1}
                      hideDocumentName={true}
                      isLeaseInfo={true}
                      hoaId={this.props.hoaId}
                      leaseInfoId={this.state.leaseInfo.id}
                      fetchDocuments={this.getLeaseInfo}
                      leaseInfo={this.state.leaseInfo}
                      formdata={this.state.leaseInfo}
                      moveInChecklist={true}
                    />
                  )}
                </>
              )}
            </div>
            {(val === true && nextQues === "tenant_documents") ||
              (nextQues === "check_in_documents" && (
                <>
                  {" "}
                  <div className="lease-document-table">
                    <DocumentsTab
                      documents={this.state.leaseInfo.documents}
                      isLeaseInfo={true}
                      theme={"general-info-tab"}
                      deleteDocument={this.deleteLeaseInfoDoc}
                      hoaId={this.props.hoaId}
                      leaseInfoId={this.state.leaseInfo.id}
                    />
                  </div>
                </>
              ))}
          </>
        );
      }
    });

    return leaseDiv;
  };

  render() {
    let requireSectionTwo =
      this.state.leaseInfo && this.state.leaseInfo.at_lease_cap === null
        ? true
        : false || this.state.leaseInfo.owner_reside_before_lease === null
        ? true
        : false || this.state.leaseInfo.own_before_leasing_min === null
        ? true
        : false || this.state.leaseInfo.senior_living_comunity === null
        ? true
        : false || this.state.leaseInfo.lease_max_times_per_year === null
        ? true
        : false || this.state.leaseInfo.lease_max === null
        ? true
        : false;

    if (
      this.state.leaseInfo.lease_max === true ||
      this.state.leaseInfo.at_lease_cap === true ||
      this.state.leaseInfo.owner_reside_before_lease === true ||
      this.state.leaseInfo.own_before_leasing_min === true ||
      this.state.leaseInfo.senior_living_comunity === true ||
      this.state.leaseInfo.lease_max_times_per_year === true
    ) {
      //console.log("Value is " + requireSectionTwo);
      requireSectionTwo = true;
    }

    if (this.props.hoaId) {
      return (
        <Fragment>
          <div className="container-fluid">
            {this.listSectionOneQuery()}
            <hr className="horizonal-line-leasing"></hr>
            {this.state.leasing_info_applicable &&
              this.listSecondSectionQueries()}
            <hr className="horizonal-line-leasing"></hr>
            {this.state.leasing_info_applicable &&
            requireSectionTwo === false ? (
              this.listThirdSectionQueries()
            ) : (
              <></>
            )}

            {/* {this.state.leasing_info_applicable &&
              requireSectionTwo===false && this.listThirdSectionQueries()} */}

            <div className="submit-button-align">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          HOA must be created to add lease data
        </div>
      );
    }
  }
}

export default FinanceLeaseInfo;
