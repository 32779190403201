import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
var accesscodesapi = {
  getAcessCodes: async function (hoaId, pageNo, perPage) {
    var questions = {};
    let url = config.baseUrl + "/api/v1/hoa_access_codes?hoa_id=" + hoaId;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    if (pageNo) {
      url = url + "&page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;

    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting access codes" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },
  createAccessCodes: async function (accessCodesObject, hoaId, file) {
    let url = config.baseUrl + "/api/v1/hoa_access_codes";
    var form = new FormData();
    for (var key in accessCodesObject) {
      form.append("hoa_access_code[" + key + "]", accessCodesObject[key]);
    }
    if (accessCodesObject.access_form_exist === true) {
      for (var key in file) {
        form.append("hoa_access_code[" + key + "]", file[key]);
      }
    }
    form.append("hoa_id", hoaId);
    let init = {
      method: "POST",
      headers: await helpers.accessCodeHeaders(),
      body: form,
    };
    var createAccessCodeResponse = null;
    await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        createAccessCodeResponse = responsejson;
      })
      .catch(function (error) {});
    return createAccessCodeResponse;
  },
  updateAccessCodes: async function (
    accessCodesObject,
    hoaId,
    accessCodeId,
    file
  ) {
    let url = config.baseUrl + "/api/v1/hoa_access_codes/" + accessCodeId;
    var form = new FormData();
    for (var key in accessCodesObject) {
      form.append("hoa_access_code[" + key + "]", accessCodesObject[key]);
    }
    if (accessCodesObject.access_form_exist === true) {
      for (var key in file) {
        form.append("hoa_access_code[" + key + "]", file[key]);
      }
    }
    form.append("hoa_id", hoaId);
    let init = {
      method: "PUT",
      headers: await helpers.accessCodeHeaders(),
      body: form,
    };
    var updateAccessCodesResponse = null;
    await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        updateAccessCodesResponse = responsejson;
      })
      .catch(function (error) {});
    return updateAccessCodesResponse;
  },
  deleteAccessCodes: async function (accessCodeId, hoaId) {
    var res = "";
    let url =
      config.baseUrl +
      "/api/v1/hoa_access_codes/" +
      accessCodeId +
      "?hoa_id=" +
      hoaId;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while deleting access codes" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  getAccessFor: async function (hoaId) {
    var questions = {};
    let url =
      config.baseUrl + "/api/v1/hoa_access_codes/access_for?hoa_id=" + hoaId;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting access for" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },
  getAccessTo: async function (hoaId) {
    var questions = {};
    let url =
      config.baseUrl + "/api/v1/hoa_access_codes/access_to?hoa_id=" + hoaId;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting access to" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },
  getAccessControls: async function (hoaId) {
    var questions = {};
    let url =
      config.baseUrl +
      "/api/v1/hoa_access_codes/access_control?hoa_id=" +
      hoaId;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting access control" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },
  updateAccessCodeHoa: async function (id, hoa) {
    let url = config.baseUrl + "/api/v1/hoas/" + id;
    let form = new FormData();
    for (var key in hoa) {
      form.append("hoa[" + key + "]", hoa[key]);
    }
    console.log("The hoa is", form);
    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};
export default withRouter(accesscodesapi);
