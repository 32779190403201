import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";

import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_createvendor.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import "react-multi-email/style.css";
import _ from "lodash";

export default function VendorCreationModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : "");

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value = event.target.value;
    setform(value);
    //setform(Object.assign({ ...formdata }, { [targetID]: value }));
  };

  const handleSubmit = (event) => {
    if (props.isTitleAction) {
      var requestObject = {
        title_action: {
          notes: formdata,
        },
      };
      apicall
        .updateTitleAction(requestObject, props.actionID, props.propertyID)
        .then((response) => {
          if (response) {
            setform(response);
            props.toggle();
            props.fetchDocuments();
          }
        });
    } else if (props.isMuniAction) {
      var requestObject = {
        title_action: {
          notes: formdata,
        },
      };
      apicall
        .updateTitleAction(requestObject, props.id, props.propertyID)
        .then((response) => {
          if (response) {
            setform(response);
            props.toggle();
            props.fetchDocuments();
          }
        });
    } else if (props.isMuniVendorUser) {
      apicall
        .municipalityUploadDocuments(formdata, props.id, "notes")
        .then((response) => {
          if (response) {
            setform(response);
            props.toggle();
            props.fetchDocuments();
          }
        });
    } else {
      apicall.uploadDocuments(formdata, props.id, "notes").then((response) => {
        if (response) {
          setform(response);
          props.toggle();
          props.fetchDocuments();
        }
      });
    }
  };

  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={true} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className="">Notes</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              {/* <FormGroup row> */}
              <Label for="" sm={2}>
                Notes
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  onChange={handleChange}
                  value={formdata}
                />
              </Col>
              {/* </FormGroup> */}

              <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
                Submit
              </Button>
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}
