import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import Axios from "axios";
var actionsapi = {
  listActions: async function (propertyID) {
    let url =
      config.baseUrl + "/api/v1/property_actions?property_id=" + propertyID;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  listTitleActions: async function (propertyID) {
    let url = `${config.baseUrl}/api/v1/title_actions?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  fetchActionTypes: async function () {
    let url = config.baseUrl + "/api/v1/property_actions/types";
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    let actionTypes = [];
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while fetching action types" + response
        );
      })
      .then((actiontypeList) => {
        config.localforage.setItem(config.actionType, actiontypeList);
        actionTypes = actiontypeList;
      })
      .catch(function (error) {});
    return actionTypes;
  },
  actionCreate: async function (action, propertyID) {
    let url =
      config.baseUrl + "/api/v1/property_actions?property_id=" + propertyID;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    //let actionTypes = [];
    var property_action = {
      ...action,
    };
    //var actionCreateResponse = null;
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(property_action),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  updateActions: async function (propertyID, actionID, actionData) {
    // let res = '';
    let url =
      config.baseUrl +
      "/api/v1/property_actions/" +
      actionID +
      "?property_id=" +
      propertyID;
    let init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ property_action: actionData }),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  listMunicipalityActions: async function (propertyID) {
    let url =
      config.baseUrl + "/api/v1/municipal_actions?property_id=" + propertyID;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};
export default withRouter(actionsapi);
