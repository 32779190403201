import React, { Component } from "react";
import { Button } from "reactstrap";
import "../../../styles/propertyupload.css";
import apicall from "../../../utils/apicall";
import "../../../styles/content.css";
import PropertyListTable from "../PropertyListTable";
import CreatePropertyModal from "./CreatePropertyModal";
import ImportPropertyModal from "../../helper_components/ImportModal";
import LoaderComponent from "../../helper_components/LoaderComponent";
import helpers from "../../../utils/helpers";
import config from "../../../utils/config";

export default class PropertyUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createPropertyModal: false,
      properties: [],
      propertyfile: null,
      openImportModal: false,
      pageNo: 1,
      isLoading: true,
      totalCount: 0,
      form_inputs: { sort: {} },
    };
  }

  toggleCreateProperty = () => {
    this.setState({
      createPropertyModal: !this.state.createPropertyModal,
    });
  };

  componentWillMount() {
    this.getProperties();
  }

  getProperties = (event, pageNo, perPage) => {
    this.setState({ isLoading: true });
    apicall
      .getProperties(this.state.form_inputs, pageNo, perPage)
      .then((response) => {
        this.setState({
          properties: response.results,
          isLoading: false,
          totalCount: response.total,
          pageNo,
        });
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.files[0],
    });
  };

  handleSubmit = (event) => {
    apicall
      .uploadPropertyFile(this.state.propertyfile, "property-file")
      .then((response) => {
        helpers.notify("Uploaded Successfully");
      })
      .catch((e) => {});
    event.preventDefault();
  };

  toggleImportModal = () => {
    this.setState({
      openImportModal: !this.state.openImportModal,
    });
  };
  sortColumns = (response) => {
    this.setState({
      form_inputs: Object.assign(this.state.form_inputs, { sort: response }),
    });
    setTimeout(() => {
      this.getProperties();
    }, 1000);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <LoaderComponent />}
        <section className="content">
          <header className="content-title property-upload">
            <div className="page-title">Property Upload</div>
            <div className="buttons">
              <a
                className="btn template"
                href={`${config.baseUrl}/upload_templates/HOAUploadFileV7.xlsx`}
              >
                Download Template File
              </a>
              <Button
                onClick={() => this.toggleImportModal()}
                className="createBtn"
              >
                Import
                <ImportPropertyModal
                  uploadType="property-file"
                  toggleParentModal={this.toggleImportModal}
                  className=""
                  title="Properties"
                  open={this.state.openImportModal}
                  uploaded={false}
                />
              </Button>
              <Button
                onClick={() => this.toggleCreateProperty()}
                className="createBtn"
              >
                Create
              </Button>
            </div>
          </header>
          <PropertyListTable
            properties={this.state.properties}
            handleSubmit={this.getProperties}
            pageNo={this.state.pageNo}
            totalCount={this.state.totalCount}
            sortColumns={this.sortColumns}
          />
          {this.state.createPropertyModal && (
            <CreatePropertyModal
              toggle={this.toggleCreateProperty}
              modal={this.state.createPropertyModal}
              getProperties={this.getProperties}
            />
          )}
        </section>
      </React.Fragment>
    );
  }
}
