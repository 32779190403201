import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";

var analystapi = {
  createAnalyst: async function (
    name,
    email,
    password,
    password_confirmation,
    role,
    mobile,
    status,
    entity_names,
    zipcodes,
    dashboard_scope
  ) {
    let url = config.baseUrl + "/api/v1/auth";
    var requestObject = {
      user: {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        role: role,
        mobile: mobile,
        status: status,
        entity_names: entity_names,
        zipcodes: zipcodes,
        dashboard_scope: dashboard_scope,
      },
    };
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(requestObject),
    };
    var createAnalystResponse = null;
    await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        createAnalystResponse = responsejson;
      })
      .catch(function (error) {});
    return createAnalystResponse;
  },
  updateAnalyst: async function (user, id) {
    let url = config.baseUrl + "/api/v1/users/" + id;
    var requestObject = {
      user: user,
    };
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(requestObject),
    };
    var updateAnalystResponse = null;
    await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        updateAnalystResponse = responsejson;
      })
      .catch(function (error) {});
    return updateAnalystResponse;
  },

  fetchUserRole: async function () {
    var userRole = [];
    let url = config.baseUrl + "/api/v1/users/roles";
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((analystList) => {
        userRole = analystList;
      })
      .catch(function (error) {});
    return userRole;
  },

  getAnalysts: async function (data, pageNo, role = "All", perPage) {
    let url = config.baseUrl + "/api/v1/users?";
    let params = {};
    if (typeof role !== "undefined" && role !== null) {
      params["role"] = role !== "All" ? role : "";
    }
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;

    // var init = {
    //   method: "GET",
    //   headers: await helpers.defaultHeaders(),
    // };
    // if (pageNo) {
    //   url = url + "&page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;

    // await fetch(url, init)
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(
    //       "Something went wrong, while getting analyst" + response
    //     );
    //   })
    //   .then((analystList) => {
    //     analysts = analystList;
    //   })
    //   .catch(function (error) {});
    // return analysts;
  },
  getAreaAnalysts: async function (data, pageNo, perPage, q) {
    let url = config.baseUrl + "/api/v1/area_analysts?";
    let params = {};

    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (q) params["q"] = q;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // var init = {
    //   method: "GET",
    //   headers: await helpers.defaultHeaders(),
    // };
    // let params = {};
    // params["page"] = pageNo;
    // params["per_page"] = perPage;
    // params["q"] = q;

    // let query = Object.keys(params)
    //   .filter((k) => typeof params[k] !== "undefined")
    //   .map((k) => `${k}=` + encodeURIComponent(params[k]))
    //   .join("&");

    // url = `${url}?${query}`;

    // await fetch(url, init)
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(
    //       "Something went wrong, while getting analyst" + response
    //     );
    //   })
    //   .then((analystList) => {
    //     analysts = analystList;
    //   })
    //   .catch(function (error) {});
    // return analysts;
  },
  getAreaAnalyst: async function (analyst_id) {
    var analyst;
    let url = config.baseUrl + "/api/v1/area_analysts/" + analyst_id;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((a) => {
        analyst = a;
      })
      .catch(function (error) {});
    return analyst;
  },
  updateAreaAnalyst: async function (area_analyst) {
    var res = "";
    let url = config.baseUrl + "/api/v1/area_analysts/" + area_analyst.id;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ area_analyst: area_analyst }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while area analyst update" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  createAreaAnalyst: async function (area_analyst) {
    let url = config.baseUrl + "/api/v1/area_analysts";
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ area_analyst: area_analyst }),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  deleteAreaAnalyst: async function (id) {
    var res = "";
    let url = config.baseUrl + "/api/v1/area_analysts/" + id;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return config.success;
        }
        throw new Error(
          "Something went wrong, while deleting analyst" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  fetchUserEntity: async function (data) {
    var entity = [];
    let url = `${config.baseUrl}/api/v1/properties/entity_names`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting entity search" + response
        );
      })
      .then((entityList) => {
        entity = entityList;
      })
      .catch(function (error) {});
    return entity;
  },
  fetchVendorCodes: async function (data) {
    var vendorCode = [];
    let url = `${config.baseUrl}/api/v1/users/vendor_codes`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting vendor code search" + response
        );
      })
      .then((vendorCodeList) => {
        vendorCode = vendorCodeList;
      })
      .catch(function (error) {});
    return vendorCode;
  },
  fetchMunicipalVendorCodes: async function (data) {
    var vendorCode = [];
    let url = `${config.baseUrl}/api/v1/users/municipal_vendor_codes`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting vendor code search" + response
        );
      })
      .then((vendorCodeList) => {
        vendorCode = vendorCodeList;
      })
      .catch(function (error) {});
    return vendorCode;
  },
};
export default withRouter(analystapi);
