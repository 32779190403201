import React from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
//import { ValidatorForm } from "react-form-validator-core";

export default function AddressComponent(props) {
  return (
    <div>
      <div style={{ fontWeight: "bold", marginTop: "2%" }}>{props.heading}</div>
      <div className="address-body">
        <FormGroup row>
          <Label for="" sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
            Payee
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="ofc_payee_name"
              id="ofc_payee_name"
              onChange={props.handleChange}
              value={props.row.ofc_payee_name}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="" sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
            C/o Name
          </Label>
          <Col sm={10}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ borderRadius: "14px 0px 0px 14px" }}>
                  C/o
                </InputGroupText>
              </InputGroupAddon>
              <Input
                className="address-name"
                type="text"
                name="payee_co_name"
                id="payee_co_name"
                placeholder=""
                onChange={props.handleChange}
                value={props.row.payee_co_name}
              />
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="" sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
            Address
          </Label>
          <Col sm={10}>
            <TextValidator
              type="text"
              name="mailing_address"
              id="mailing_address"
              placeholder=""
              onChange={props.handleChange}
              value={props.row.mailing_address}
            />
          </Col>
        </FormGroup>
        <div>
          <Row form>
            <Col md={7} style={{ paddingLeft: "20px" }}>
              <FormGroup row>
                <Label
                  for=""
                  sm={3}
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  City
                </Label>
                <Col sm={9}>
                  <TextValidator
                    type="text"
                    name="mailing_city"
                    id="mailing_city"
                    placeholder=" city"
                    onChange={props.handleChange}
                    value={props.row.mailing_city}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup row>
                <Label
                  for=""
                  sm={4}
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  State
                </Label>
                <Col sm={8}>
                  <TextValidator
                    type="text"
                    name="mailing_state"
                    id="mailing_state"
                    placeholder=" state"
                    onChange={props.handleChange}
                    value={props.row.mailing_state}
                    maxLength="2"
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup row>
                <Label
                  for=""
                  sm={2}
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Zip
                </Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="mailing_zip"
                    id="mailing_zip"
                    placeholder=" zip"
                    maxLength="10"
                    onChange={(event) => props.handleChange(event, "zipCode")}
                    validators={["isAddressZipcodeMatch"]}
                    errorMessages={["Invalid Zip Code"]}
                    value={props.row.mailing_zip}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
