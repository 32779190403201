import React, { Component, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import HoaForm from "../hoas/FormComponent";
import LeaseInfo from "../general_info/lease_info/FinanceLeaseInfo";
import _ from "lodash";
import TabsComponent from "../helper_components/TabsComponent";
import Amenities from "../general_info/Amenities";
import AccessCodes from "../general_info/access_codes/AccessCodes";
import Inspections from "../general_info/inspections/Inspections";

export default function AssocaitionModal(prop) {
  const [loading, setLoading] = useState(true);
  const [props, setprops] = useState(prop);
  const updateHoaProps = (hoa) => {
    setprops(Object.assign({ ...props }, { row: hoa }));
  };
  const [activeTab, setactiveTab] = useState(
    props.activeTab ? props.activeTab : 1
  );

  console.log("The props", props);

  const tabs = () => {
    var listofTabs = [
      "HOA",
      "Leasing Info",
      "Amenities",
      "Access Codes",
      "Inspections",
    ];
    let tabsComponentsList = new Array(listofTabs.length).fill(null);
    tabsComponentsList[0] = <HoaForm {...props} />;
    tabsComponentsList[1] = (
      <LeaseInfo
        hoaId={props.row.id}
        leasing_info_applicable={props.row.leasing_info_applicable}
        updateHoaProps={updateHoaProps}
      />
    );
    tabsComponentsList[2] = (
      <Amenities
        hoaId={props.row.id}
        updateHoaProps={updateHoaProps}
        propertyid={props.propertyid}
      />
    );
    tabsComponentsList[3] = (
      <AccessCodes hoaId={props.row.id} propertyid={props.propertyid} />
    );
    tabsComponentsList[4] = (
      <Inspections
        hoaId={props.row.id}
        propertyid={props.propertyid}
        // userRole={this.props.userRole}
      />
    );
    return (
      <>
        <TabsComponent
          theme="paymentModal"
          components={tabsComponentsList}
          listofTabs={listofTabs}
          generateTabURL={false}
          theme={"general-info-tab"}
          activeTab={activeTab}
        />
      </>
    );
  };

  return (
    <div>
      <Modal className="association-modal" isOpen={true} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Association Details</span>
          </div>
        </ModalHeader>
        <ModalBody>{tabs()}</ModalBody>
      </Modal>
    </div>
  );
}
