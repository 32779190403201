import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import LoaderComponent from "../helper_components/LoaderComponent";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { toast } from "react-toastify";
import config from "../../utils/config";

export default function (props) {
  const [isLoading, setisLoading] = useState(false);
  const [openFileUpload, setopenFileUpload] = useState(false);
  const [file, setfile] = useState(null);
  const [response, setresponse] = useState(null);

  const toggleImportModal = () => {
    setopenFileUpload(!openFileUpload);
  };
  const notify = (errorMsg) => toast(errorMsg);
  const handleChange = (event) => {
    setfile(event.target.files[0]);
  };
  const handleSubmit = (event) => {
    setisLoading(true);
    // apicall
    //   .paymentCheckNumberFileImport(file)
    //   .then((response) => {
    //     setisLoading(false);
    //     setresponse(response);
    //     if (response.error) helpers.notify(response.error);
    //     else helpers.notify(response.message);
    //     toggleImportModal();
    //   })
    //   .catch((e) => {});
    apicall
      .paymentFileImport(file)
      .then((response) => {
        setisLoading(false);
        setresponse(response);
        if (response.status) helpers.notify(response.status);
        else helpers.notify(response.message);
        toggleImportModal();
      })
      .catch((e) => {
        let errorMsg = helpers.getErrorMsg(e.response.data.errors.base);
        if (errorMsg) notify(errorMsg);
        setisLoading(false);
        toggleImportModal();
      });
    event.preventDefault();
  };

  const formElements = () => {
    return (
      <Modal
        isOpen={openFileUpload}
        toggle={toggleImportModal}
        className={"import-modal"}
      >
        <ModalHeader toggle={toggleImportModal}>
          <span className="heading">File Upload </span>
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="import-file">
              <Input
                type="file"
                name="file"
                id="name"
                onChange={handleChange}
              />
              <button>Submit</button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <section className="content">
          <header className="content-title property-upload">
            <div className="page-title">Payment Upload</div>
            <div className="buttons">
              {/* <Button onClick={() => toggleImportModal()} className="createBtn">
                Import
              </Button>
              {openFileUpload && formElements()} */}
              <a
                className="btn template"
                href={`${config.baseUrl}/upload_templates/PaymentUpload.xlsx`}
              >
                Download Template File
              </a>
              <Button onClick={() => toggleImportModal()} className="createBtn">
                Payment Upload
              </Button>
              {openFileUpload && formElements()}
            </div>
          </header>
        </section>
      )}
    </React.Fragment>
  );
}
