import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import MunicipalModal from "./MunicipalModal";
import apicall from "../../utils/apicall";
import ConfirmBox from "../helper_components/ConfirmBox";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "../../styles/_municipalComponent.scss";
import Loader from "../helper_components/LoaderComponent";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";
import _ from "lodash";

export default function ListMunicipalTable(props) {
  const [deleteModal, setdeleteModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [openFormModal, setopenFormModal] = useState(false);
  const [formdata, setformdata] = useState();
  const [states, set_States] = useState();
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  useEffect(() => {
    readStates();
   
  }, []);

  const readStates = () => {
    apicall.filterStates().then((response) => {
      if (response) {
        let statesArray = [];
        if (response.states) {
          statesArray = response.states.map((x) => {
            return { value: x, label: x };
          });

          set_States(statesArray);
        }
      }
    });
  };

  const toggleAddFormModal = () => {
    props.refreshList();
    setopenFormModal(!openFormModal);
  };

  const deleteMunicipal = (formdata) => {
    setisLoading(true);
    apicall.deleteMunicipal(formdata.id).then((status) => {
      if (status < 400) {
        setisLoading(false);
        helpers.notify("Deleted successfully");
        props.refreshList();
      }
    });
  };

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            setopenFormModal(true);
          }}
        />
       {props.userRole === "admin" && (  <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            setdeleteModal(true);
          }}
        />)}
      </div>
    );
  };

  const periodicityCapitalize = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(cell)) return cell.replace(/,/g, ", ");
  };
  let municipalities = props.municipalities;
  const columns = [
    {
      dataField: "name",
      text: " Name",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "state",
      text: "State",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "county",
      text: "County",
      formatter: periodicityCapitalize,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "muni_type",
      text: "Type",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: buttonFormatter,
      headerAttrs: { width: 200 },
      attrs: { width: 200, class: "table-actions" },
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="content">
          <header className="content-title">
            <div style={{ display: "flex" }}>
              <h2 className="page-heading">
                <strong>Municipality</strong>
              </h2>
              <Button
                style={{ marginTop: "0px", marginLeft: "20px" }}
                onClick={() => {
                  setopenFormModal(true);
                  setformdata({});
                }}
              >
                Create
              </Button>
            </div>
          </header>

          <PaginationComponent
            columns={columns}
            rows={municipalities}
            handleSubmit={props.refreshList}
            perPage={props.perPage}
            pageNo={props.pageNo}
            totalCount={props.totalCount}
          />
        </section>
      )}
      {deleteModal && (
        <ConfirmBox
          onYesClick={() => {
            deleteMunicipal(formdata);
            setdeleteModal(false);
          }}
          onNoClick={() => setdeleteModal(false)}
          heading={
            <span>
              Are you sure you want to delete the Municipality: {formdata.name}?
            </span>
          }
        >
          <div>
            <p>All Municipality related will be deleted.</p>
          </div>
        </ConfirmBox>
      )}
      {openFormModal && (
        <MunicipalModal
          title="Municipal"
          toggle={toggleAddFormModal}
          open={openFormModal}
          formdata={formdata}
          states={states}
        />
      )}
    </React.Fragment>
  );
}
