import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Col,
} from "reactstrap";
import _ from "lodash";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_hearingModal.scss";
import HearingModal from "./HearingModal";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import ConfirmBox from "../helper_components/ConfirmBox";
import dayjs from "dayjs";
import config from "../../utils/config";

export default function HearingListing(props) {
  const [row, setrow] = useState(props.row ? props.row : {});
  const [openHearingModal, setopenHearingModal] = useState(false);
  const [totalCount, settotalCount] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [actionable_id, setactionable_id] = useState(props.actionID);
  const [deleteModal, setdeleteModal] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);

  const handleCaseNumberChange = (event) => {
    let targetID = event.target.id;
    let value = event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
    // setTimeout(() => {
    //   handleSubmit(true);
    // }, 2000);
  };

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value = event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  useEffect(() => {
    if (_.isEmpty(row.case_number)) {
      setisDisabled(true);
    } else setisDisabled(false);
  }, [row]);

  const toggleHearingModal = () => {
    setopenHearingModal(!openHearingModal);
    if (_.isEmpty(row.case_number)) {
      setisDisabled(true);
    } else setisDisabled(false);
    if (openHearingModal) readCodeViolation();
    else setrow({});
  };

  const readCodeViolation = (id) => {
    apicall
      .readCodeViolation(id ? id : actionable_id, props.propertyID)
      .then((response) => {
        setrow(response);
        setactionable_id(response.id);
      });
  };

  const handleSubmit = (isUpdated) => {
    if (actionable_id) {
      apicall
        .updateCodeViolation(actionable_id, props.propertyID, row)
        .then((response) => {
          if (response) {
            if (!isUpdated) helpers.notify("Updated successfully");
            setrow(response);
            if (!isUpdated) {
              props.toggle();
            }
          }
        });
    } else {
      apicall.createCodeViolation(props.propertyID, row).then((response) => {
        if (response) {
          if (!isUpdated) helpers.notify("Created successfully");
          setrow(response);
          setactionable_id(response.id);
          if (!isUpdated) {
            props.toggle();
          }
        }
      });
    }
    //event.preventDefault();
  };

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setrow(row);
            //seteditModal(true);
            setopenHearingModal(true);
          }}
        />
        <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setrow(row);
            setdeleteModal(true);
            //setState({ row, deleteModal: true });
          }}
        />
      </div>
    );
  };
  const deleteHearing = (formdata) => {
    apicall
      .deleteHearing(actionable_id, props.propertyID, formdata.id)
      .then((response) => {
        if (response) {
          helpers.notify(response.message);
          readCodeViolation();
        }
      });
  };

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return !_.isEmpty(cell)
      ? dayjs(cell).format(config.US_DATE_TIME_FORMAT)
      : "";
  };

  const attendedFormatter = (cell, row, rowIndex, formatExtraData) => {
    return cell === true ? "Yes" : "No";
  };

  let actions = row.title_hearings;

  const columns = [
    {
      dataField: "time",
      text: "Date/Time",
      formatter: dateFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      headerClasses: "header-cell-alignment",
    },
    {
      dataField: "representation",
      text: "Representation",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "law_firm.name",
      text: "Assigned",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
    {
      dataField: "law_firm_poc_name",
      text: "Lawyer",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
    {
      dataField: "attended",
      text: "Attended",
      headerClasses: "header-cell-alignment",
      formatter: attendedFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
    {
      dataField: "attendees",
      text: "Attendees",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
    {
      dataField: "attending_notes",
      text: "Attending Notes",
      headerClasses: "header-cell-alignment",
      // formatter: buttonFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: buttonFormatter,
      headerAttrs: { width: 200 },
      attrs: { width: 200, class: "table-actions" },
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
    },
  ];

  const formElements = () => {
    return (
      <div>
        <FormGroup row style={{ marginLeft: "0px" }}>
          <Label
            for=""
            sm={1}
            style={{ paddingLeft: "0px", paddingRight: "8px" }}
          >
            Court Case Number
          </Label>
          <Col sm={4} style={{ paddingLeft: "5px" }}>
            <Input
              type="text"
              name={"case_number"}
              id={"case_number"}
              onChange={handleCaseNumberChange}
              value={row.case_number}
            />
          </Col>
          <Col sm={1}>
            <Button
              className="hearing-buttons"
              style={{ float: "right" }}
              onClick={() => {
                handleSubmit(true);
                toggleHearingModal();
              }}
            >
              Add
            </Button>
          </Col>
        </FormGroup>

        <PaginationComponent
          columns={columns}
          rows={actions}
          //handleSubmit={listCodeViolationActions}
          pageNo={pageNo}
          totalCount={totalCount}
          isHearingModal={true}
        />
        <FormGroup row>
          <Label for="" sm={2}>
            Notes
          </Label>
          <Col sm={8}>
            <Input
              type="textarea"
              name="hearing_notes"
              id="hearing_notes"
              onChange={handleChange}
              value={row.hearing_notes}
            />
          </Col>
          <Col sm={2}>
            <Button
              className="hearing-buttons"
              style={{ marginTop: "10px", float: "right" }}
              onClick={() => handleSubmit(false)}
            >
              Save
            </Button>
          </Col>
        </FormGroup>
        <div style={{ width: "50%" }}>
          <Button
            style={{ float: "right" }}
            onClick={() => handleSubmit(false)}
            disabled={isDisabled ? true : false}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Hearing </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="hearing-listing-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
      {openHearingModal && (
        <HearingModal
          toggle={toggleHearingModal}
          title="Hearing Details"
          open={openHearingModal}
          row={row}
          actionID={actionable_id}
          fetchDocuments={readCodeViolation}
          propertyID={props.propertyID}
        />
      )}
      {deleteModal && (
        <ConfirmBox
          onYesClick={() => {
            deleteHearing(row);
            setdeleteModal(false);
          }}
          onNoClick={() => setdeleteModal(false)}
          heading={
            <span>
              Are you sure you want to delete the Title Hearing: {row.name}?
            </span>
          }
        >
          <div>
            <p>All Title Hearing related will be deleted.</p>
          </div>
        </ConfirmBox>
      )}
    </div>
  );
}
