import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var vendorapi = {
  createVendor: async function (data, emails) {
    data.order_cc_emails = emails.toString();
    let url = config.baseUrl + "/api/v1/vendors";
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listVendor: async function (data, pageNo, perPage) {
    let url = config.baseUrl + "/api/v1/vendors?";
    let params = {};

    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // if (pageNo) {
    //   url = url + "?page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    // let headers;
    // let response;
    // headers = await helpers.defaultHeaders();
    // await Axios.get(url, { headers: headers }).then((resp) => {
    //   response = resp;
    // });
    // return response.data;
  },
  createVendorPricing: async function (data) {
    let url = config.baseUrl + "/api/v1/vendor_prices";
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listVendorPrice: async function (pageNo, perPage) {
    let url = config.baseUrl + "/api/v1/vendor_prices";
    if (pageNo) {
      url = url + "?page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateVendorPricing: async function (data) {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/vendor_prices/${data.id}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteVendorPricing: async function (id) {
    let url = `${config.baseUrl}/api/v1/vendor_prices/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  deleteVendorCreation: async function (id) {
    let url = `${config.baseUrl}/api/v1/vendors/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  updateVendor: async function (data, emails) {
    data.order_cc_emails = emails.toString();
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/vendors/${data.id}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();

    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(vendorapi);
