import config from "./config";
import { withRouter } from "react-router";
import helpers from "./helpers";
import Propertyapi from "./api/Propertyapi";
import lienapi from "./api/lienapi";
import analystapi from "./api/analystapi";
import leaseapi from "./api/leaseapi";
import hoaapi from "./api/hoaapi";
import managementapi from "./api/managementapi";
import inspectioncategoryapi from "./api/inspectioncategoryapi";
import violationapi from "./api/violationapi";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import accesscodesapi from "./api/accesscodesapi";
import actionsapi from "./api/actionsapi";
import inspectionapi from "./api/inspectionapi";
import tenantmoveinapi from "./api/tenantmoveinapi";
import paymentsapi from "./api/paymentsapi";
import attorneyapi from "./api/attorneyapi";
import dashboardapi from "./api/dashboardapi";
import notesapi from "./api/notesapi";
import reportsapi from "./api/reportsapi";
import associationVerificationapi from "./api/associationVerificationapi";
import amenitiesapi from "./api/amenitiesapi";
import codeViolationapi from "./api/codeViolationapi";
import businessunitapi from "./api/businessunitapi";
import lawFirmapi from "./api/lawFirmapi";
import addmunicipalityapi from "./api/addmunicipalityapi";
import vendorapi from "./api/vendorapi";
import municipalVendorsapi from "./api/municipalVendorsapi";
import MunicipalityActionsapi from "./api/MunicipalityActionsapi";
import DeedVerificationapi from "./api/DeedVerificationapi";
import TitleActionsapi from "./api/TitleActionsapi";
import TitleClearanceapi from "./api/TitleClearanceapi";
import clientMunicipalityLoginapi from "./api/clientMunicipalityLoginapi";
import clientVendorapi from "./api/clientVendorapi";
import TitleOrderapi from "./api/TitleOrderapi";
import MunicipalityOrderapi from "./api/MunicipalityOrderapi";
import titleLienActionapi from "./api/titleLienActionapi";
import municipalityActionApi from "./api/municipalityActionApi";
import municipalVerificationapi from "./api/municipalVerificationapi";
import municipalDashboardapi from "./api/municipalDashboardapi";
import titleDashboardapi from "./api/titleDashboardapi";
import LienReleaseActionapi from "./api/LienReleaseActionapi";
import UnderwritingActionapi from "./api/underwritingActionapi"
import _ from "lodash";
import underwritingapi from "./api/underwritingapi";

var apicall = {
  getOauthToken: async function (email, password, props) {
    var url = config.baseUrl + "/api/v1/auth/sign_in";
    var validuser = false;

    var requestObject = {
      email: email,
      password: password,
    };
    var init = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObject),
    };

    var loginResp = null;
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          // console.log(response)
          return response
            .json()
            .then((data) => ({ body: data, headers: response.headers }));
        } else {
          return response.json();
        }
      })
      .then((responsejson) => {
        console.log(responsejson);
        let accessToken = responsejson.headers.get(config.accessToken);
        let uid = responsejson.headers.get("uid");
        let expiry = responsejson.headers.get("expiry");
        let client = responsejson.headers.get("client");
        if (typeof accessToken !== "undefined" && accessToken !== null) {
          config.localforage.setItem(config.accessToken, accessToken);
          config.localforage.setItem("tokenDate", new Date());
          config.localforage.setItem("email", email);
          config.localforage.setItem("uid", uid);
          config.localforage.setItem("expiry", expiry);
          config.localforage.setItem("client", client);
          //Wait till the data is stored
          setTimeout(() => {
            console.log("store data");
            if (
              _.has(responsejson.body.data, "role") &&
              responsejson.body.data.role
            ) {
              let role = responsejson.body.data.role;
              if (role === "guest" || role === "guest_resicap") {
                props.history.push("/guest");
              } else if (role === "vendor_title") {
                props.history.push("/vendor");
              } else if (role === "vendor_municipality") {
                props.history.push("/municipality-user");
              } else if (role) {
                props.history.push("/homepage");
              }
            }
          }, 600);
        }
        loginResp = responsejson.body;
      })
      .catch(function (error) {});
    return loginResp;
  },

  logout: function (props) {
    config.localforage.clear();
    window.location.href = config.netlifyUrl;
  },

  getSearchQuestions: async function (clientId) {
    var questions = {};
    let url =
      config.baseUrl + "/api/v1/properties/" + clientId + "/hoa_questions";
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting search questions" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },
  ...Propertyapi,
  ...lienapi,
  ...analystapi,
  ...leaseapi,
  ...hoaapi,
  ...managementapi,
  ...inspectioncategoryapi,
  ...violationapi,
  ...accesscodesapi,
  ...actionsapi,
  ...inspectionapi,
  ...tenantmoveinapi,
  ...paymentsapi,
  ...attorneyapi,
  ...dashboardapi,
  ...notesapi,
  ...reportsapi,
  ...associationVerificationapi,
  ...amenitiesapi,
  ...codeViolationapi,
  ...businessunitapi,
  ...addmunicipalityapi,
  ...lawFirmapi,
  ...vendorapi,
  ...municipalVendorsapi,
  ...MunicipalityActionsapi,
  ...DeedVerificationapi,
  ...TitleActionsapi,
  ...TitleClearanceapi,
  ...clientMunicipalityLoginapi,
  ...clientVendorapi,
  ...TitleOrderapi,
  ...MunicipalityOrderapi,
  ...titleLienActionapi,
  ...municipalityActionApi,
  ...municipalVerificationapi,
  ...municipalDashboardapi,
  ...titleDashboardapi,
  ...LienReleaseActionapi,
  ...UnderwritingActionapi,
};
//500ms lock to call the specific api methods to avoid calling the same API again and again

Object.keys(apicall).map(function (key, index) {
  apicall[key] = AwesomeDebouncePromise(apicall[key], config.debounceTimer);
});
export default withRouter(apicall);