import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_businessUnitLocation.scss";
import apicall from "../../utils/apicall";
import _ from "lodash";
import config from "../../utils/config";
import SelectOptions from "../helper_components/SelectOptions";

export default function BusinessUnitLocationModal(props) {
  const [formdata, setform] = useState(props.pocArray ? props.pocArray : {});
  const [checkedMap, setcheckedMap] = useState(
    props.pocArray.area_type === "County"
      ? props.pocArray.assigned_counties
        ? props.pocArray.assigned_counties.split(",")
        : []
      : props.pocArray.zip_code
      ? props.pocArray.zip_code.split(",")
      : []
  );
  const [counties, setcounties] = useState();
  const [zipCodes, setzipCodes] = useState();
  const [states, set_States] = useState(props.states ? props.states : []);

  const readBusinessUnitStates = () => {
    apicall.filterStates(formdata).then((response) => {
      if (response) {
        setcounties(response.counties);
      }
    });
  };

  const readBusinessUnitZipCode = () => {
    apicall.filterZipCode(formdata).then((response) => {
      if (response) {
        setzipCodes(response.zips);
      }
    });
  };

  useEffect(() => {
    if (formdata.area_type === "County") readBusinessUnitStates();
    else if (formdata.area_type === "Zip Code") readBusinessUnitZipCode();
  }, [formdata.area_type, formdata.state]);

  useEffect(() => {
    setform(Object.assign({ ...formdata }, { ["id"]: props.pocArray.id }));

    if (_.isEmpty(formdata.area_type)) {
      setform(Object.assign({ ...formdata }, { ["area_type"]: "County" }));
    }
  }, []);

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (targetID === "state" || targetID === "area_type") setcheckedMap([]);
    setform(Object.assign({ ...formdata }, { [targetID]: value }));
  };

  const handleSubmit = () => {
    props.handleLocationChange(formdata);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let checkedValue = checkedMap;
    if (value === true) {
      checkedValue.push(name);
    } else {
      checkedValue = checkedMap.filter((x) => x !== name);
    }

    setcheckedMap(checkedValue);
    if (formdata.area_type === "Zip Code") {
      let zipCodes = { zip_code: checkedValue.toString() };
      setform(Object.assign({ ...formdata }, { ...zipCodes }));
    } else {
      let counties = { assigned_counties: checkedValue.toString() };
      setform(Object.assign({ ...formdata }, { ...counties }));
    }
  };

  const renderCheckBoxInput = (x) => {
    return (
      <div className="checkbox_div">
        <input
          type="checkbox"
          name={x.county}
          id={x.county}
          //value={checkedMap[x.value]}
          onChange={(event) => {
            handleInputChange(event);
          }}
          checked={_.includes(checkedMap, x.county)}
          className="checkbox_input"
        />
        <div className="checkbox_div_data"> {x.county}</div>
      </div>
    );
  };

  const renderZipCodeCheckBoxInput = (x) => {
    return (
      <div className="checkbox_div">
        <input
          type="checkbox"
          name={x.zip}
          id={x.zip}
          //value={checkedMap[x.value]}
          onChange={(event) => {
            handleInputChange(event);
          }}
          checked={_.includes(checkedMap, x.zip)}
          className="checkbox_input"
        />
        <div className="checkbox_div_data"> {x.zip}</div>
      </div>
    );
  };

  const createItemList = (counties) => {
    let rows = {};
    let counter = 1;
    let length = Math.floor(counties.length / 7);
    let maxItems = length * 7;
    let overFlow = counties.length - maxItems;

    let totalRows = overFlow > 0 ? 1 : 0;

    counties.forEach((item, idx) => {
      // if (counter === parseInt(length) + totalRows) {
      //   counter = 0;
      //   overFlow--;
      //   if (overFlow === 0) totalRows = 0;
      // }
      // rows[counter] = rows[counter] ? [...rows[counter]] : [];
      // rows[counter].push(item);
      // counter++;
      rows[counter] = rows[counter] ? [...rows[counter]] : [];
      if (idx % 7 === 0 && idx !== 0) {
        counter++;
        rows[counter] = rows[counter] ? [...rows[counter]] : [];
        rows[counter].push(item);
      } else {
        rows[counter].push(item);
      }
    });
    return rows;
  };

  const renderCountyModal = (list, type) => {
    let rows = createItemList(list);
    return (
      <div className="counties_list_main_div">
        {Object.keys(rows).map((row, rowIndex) => {
          let backgroundColor;
          if (rowIndex % 2 !== 0) {
            backgroundColor = "lightgray";
          }
          return (
            <div
              className="county_data"
              style={{
                backgroundColor: backgroundColor,
              }}
              key={row}
            >
              {rows[row].map((item) => {
                return (
                  <>
                    {type === "County"
                      ? renderCheckBoxInput(item)
                      : renderZipCodeCheckBoxInput(item)}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const stateOptions = [];
  let stateDetails;
  if (!_.isEmpty(states)) {
    stateDetails = states;
  }

  stateOptions.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );

  if (!_.isEmpty(stateDetails)) {
    for (let type of stateDetails) {
      stateOptions.push(
        <option key={type.label} value={type.value}>
          {type.value}
        </option>
      );
    }
  }

  const formElements = () => {
    return (
      <>
        <FormGroup row>
          <Label for="" sm={2}>
            State
          </Label>
          <Col sm={8}>
            <Input
              type="select"
              id={"state"}
              name="state"
              value={formdata.state}
              onChange={handleChange}
            >
              {stateOptions}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Area Type
          </Label>
          <Col sm={8}>
            <Input
              type="select"
              id={"area_type"}
              name="area_type"
              value={formdata.area_type}
              onChange={handleChange}
            >
              <SelectOptions options={config.area_type} />
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="" sm={2}>
            Area Name
          </Label>
          <Col sm={8}>
            <TextValidator
              type="text"
              name="area_name"
              id="area_name"
              onChange={handleChange}
              value={formdata.area_name}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          {formdata.area_type === "County" &&
            !_.isEmpty(counties) &&
            renderCountyModal(counties, "County")}
          {formdata.area_type === "Zip Code" &&
            !_.isEmpty(zipCodes) &&
            renderCountyModal(zipCodes, "Zip Code")}
        </FormGroup>
        <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
          Submit
        </Button>
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Location</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm autoComplete="off" onSubmit={handleSubmit}>
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        id="businessUnitLocation-modal"
        className="businessUnitLocation-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
