import React, { Component, useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  Label
} from "reactstrap";
import "../../styles/_association-verification.scss";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import _ from "lodash";
import apicall from "../../utils/apicall";
// import HoaAsyncSelect from "../helper_components/HoaAsyncSelect";
import helpers from "../../utils/helpers";
import AsyncCreatable from "../helper_components/AsyncCreatable";
import DocumentsModal from "../documents/DocumentsModal";
import DocumentsTab from "../documents/DocumentsTab";
import Select from "react-select";
import NotesHistory from "../CodeViolation/NotesHistory";

export default function AssociationVerification(props) {
  const [row, setrow] = useState({});
  const [nextQuestion, setnextQuestion] = useState(false);
  const { propertyID, avId, actionID } = props;
  const [disableForm, setdisableForm] = useState(true);
  // const [status, setstatus] = useState("Pending - RESICAP");
  const [openAddDocumentModal, setopenAddDocumentModal] = useState(false);
  const [disableSwimmingPool, setdisableSwimmingPool] = useState(false);
  const [disableTennisCourt, setdisableTennisCourt] = useState(false);
  const [disableGolfCourse, setdisableGolfCourse] = useState(false);

  const toggleDocument = () => {
    setopenAddDocumentModal(!openAddDocumentModal);
  };
  const getAssociationVerification = () => {
    apicall.getAssociationVerification(propertyID, avId).then((response) => {
      if (response) {
        // if (
        //   Object.values(response).includes(true) ||
        //   Object.values(response).includes(false)
        // )
        // setnextQuestion(true);
        setrow(response);
        if (
          response.building_name === false ||
          response.building_name === null
        ) {
          setnextQuestion(false);
        } else {
          setnextQuestion(true);
        }
      }
    });
  };

  const deleteDocument = (documentId) => {
    apicall.deleteDocument(avId, propertyID, documentId).then((response) => {
      helpers.notify("Document deleted successfully");
      getAssociationVerification();
    });
  };

  useEffect(() => {
    getAssociationVerification();
  }, []);

  const handleSubmit = (isUpdate) => {
    if (!nextQuestion) {
      // if (Object.values(row).includes(true)) setnextQuestion(true);
      apicall
        .updateAssociationVerification(row, avId, propertyID)
        .then((response) => {
          if (response) {
            if (
              response.building_name === false ||
              response.building_name === null
            ) {
              setnextQuestion(false);
            } else {
              setnextQuestion(true);
            }
            helpers.notify("Updated successfully");
            setrow(response);
            if (isUpdate) props.toggle();
            props.refershActions(propertyID);
          }
          //setnextQuestion(true);
        });
    } else {
      apicall
        .updateAssociationVerification(row, avId, propertyID)
        .then((response) => {
          if (response) {
            helpers.notify("Updated successfully");
            setrow(response);
            if (isUpdate) props.toggle();
            props.refershActions(propertyID);
          }
        });
    }
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: event.target.value }));
  };

  const handlePhoneChange = (event) => {
    let targetName = event.target.name;
    let text = event.target.value;
    var cleaned = ("" + text).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      cleaned = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }
    setrow(Object.assign({ ...row }, { [targetName]: cleaned }));
    return;
  };

  // useEffect(() => {
  //   if (row.building_name === false) {
  //     setdisableForm(true);
  //   } else if (row.business_search === true || row.online_search === true)
  //     setdisableForm(false);
  //   else setdisableForm(true);
  // }, [row]);

  useEffect(() => {
    if (row.hoa_id || row.association_name) setdisableForm(false);
  }, [row]);

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    if (targetName === "building_name") {
      if (value === false || value === null) {
        setnextQuestion(false);
      } else {
        setnextQuestion(true);
      }
    }
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const renderRadioInput = (label, name, disabled) => {
    return (
      <div className="question">
        <div className="text">{label}</div>
        <div className="radio">
          <CustomInput
            id={`${name}yes`}
            type="radio"
            name={name}
            value={true}
            onClick={(e) => handleRadioButton(e, true)}
            checked={row[name] === true ? "checked" : ""}
            disabled={disabled}
          />
          <label>Yes</label>
          <CustomInput
            id={`${name}no`}
            type="radio"
            name={name}
            value={false}
            onClick={(e) => handleRadioButton(e, false)}
            checked={row[name] === false ? "checked" : ""}
            disabled={disabled}
          />
          <label>No</label>
        </div>
      </div>
    );
  };
  const getPhone = (value) => {
    if (value) return value.split(" ")[1].trim();
  };
  const getExt = (value) => {
    if (value) return Number(value.split(" ")[0].slice(1, -1));
  };

  const handleSelectChange = (id, valueObj) => {
    console.log("The hoa value is", valueObj);
    if (!_.has(valueObj, "__isNew__") && !_.isEmpty(valueObj)) {
      setdisableForm(true);
      let x = {
        association_name: valueObj.data["label"],
        hoa_id: valueObj.data["value"],
        point_of_contact_1: valueObj.data["poc1"],
        email1: valueObj.data["email1"],
        phone1: valueObj.data["phone1"],
        // phone1: getPhone(valueObj.data["phone1"]),
        ext1: getExt(valueObj.data["phone1"]),
        point_of_contact_2: valueObj.data["poc2"],
        email2: valueObj.data["email2"],
        phone2: valueObj.data["phone2"],
        ext2: getExt(valueObj.data["phone2"]),
        point_of_contact_3: valueObj.data["poc3"],
        email3: valueObj.data["email3"],
        phone3: valueObj.data["phone3"],
        ext3: getExt(valueObj.data["phone3"]),
        swimming_pool: valueObj.data["hoa_amenity"]["community_pool"],
        tennis_court: valueObj.data["hoa_amenity"]["community_tennis_court"],
        golf_court: valueObj.data["hoa_amenity"]["community_golf_course"],
      };
      if (valueObj.data.hoa_amenity.community_golf_course !== null)
        setdisableGolfCourse(true);
      if (valueObj.data.hoa_amenity.community_pool !== null)
        setdisableSwimmingPool(true);
      if (valueObj.data.hoa_amenity.community_tennis_court !== null)
        setdisableTennisCourt(true);

      //console.log(Object.assign({ ...row }, { ...x }));
      setrow(Object.assign({ ...row }, { ...x }));
    } else if (!_.isEmpty(valueObj)) {
      setdisableForm(false);
      let x = {
        association_name: valueObj["label"],
        hoa_id: "new",
        point_of_contact_1: "",
        email1: "",
        phone1: "",
        ext1: "",
        point_of_contact_2: "",
        email2: "",
        phone2: "",
        ext2: "",
        point_of_contact_3: "",
        email3: "",
        phone3: "",
        ext3: "",
        // swimming_pool: "",
        // tennis_court: "",
        // golf_court: "",
      };
      setrow(Object.assign({ ...row }, { ...x }));
    }
  };

  const renderFormInputElements = (name, label, type) => {
    return (
      <div className="ip-fields">
        <label>{label}</label>
        <TextValidator
          type={type}
          name={name}
          id={name}
          onChange={handleChange}
          value={row[name]}
          disabled={disableForm ? true : false}
        />
      </div>
    );
  };

  ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
    var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return phoneNumberPattern.test(value);
  });

  const renderPhone = (name, label, type) => {
    return (
      <div className={label}>
        <label>{label}</label>
        <TextValidator
          type={type}
          name={name}
          id={name}
          onChange={handlePhoneChange}
          value={row[name]}
          disabled={disableForm ? true : false}
          maxLength="14"
        />
      </div>
    );
  };

  const renderExt = (name, label, type) => {
    return (
      <div className={label}>
        <label>{label}</label>
        <TextValidator
          type={type}
          name={name}
          id={name}
          onChange={handleChange}
          value={row[name]}
          disabled={disableForm ? true : false}
          maxLength="14"
        />
      </div>
    );
  };

  const formElements = () => {
    // console.log(row.building_name);
    let x = {
      label: row.association_name,
      value: row.hoa_id,
    };
    return (
      <>
        <div className="code-radio">
          {renderRadioInput(
            "Does the property share a common wall with another property?",
            "share_common_wall"
          )}
          {renderRadioInput(
            "Does the community have a swimming pool?",
            "swimming_pool",
            disableSwimmingPool
          )}
        </div>
        <div className="code-radio">
          {renderRadioInput(
            "Does the community have a single enty?",
            "single_entry"
          )}
          {renderRadioInput(
            "Does the community have a tennis court?",
            "tennis_court",
            disableTennisCourt
          )}
        </div>
        <div className="code-radio">
          {renderRadioInput(
            "Is there a gate or guard building?",
            "guard_building"
          )}
          {renderRadioInput(
            "Does the community have a golf course?",
            "golf_court",
            disableGolfCourse
          )}
        </div>
        <div className="code-radio">
          {renderRadioInput(
            "Does the community have small lake(s)?",
            "have_small_lakes"
          )}
        </div>
        <hr></hr>
        <div className="next-section">
          <div className="ques">
            {renderRadioInput("Is there a view available?", "street_view")}
            {renderRadioInput(
              "Is there a community name/ building name?",
              "building_name"
            )}
          </div>
        </div>
        <hr />
        {nextQuestion && (
          <>
            <div className="nextQuestion">
              {renderRadioInput(
                "Can the community/building name association be identified via the state's Secretary of State Business Search?",
                "business_search",
                row.building_name === false ? true : false
              )}

              {row.business_search === true ? (
                <div className="ip-fields">
                  <label>Registration Status</label>
                  <div>
                    <Input
                      type="select"
                      name="hoa_status"
                      id="hoa_status"
                      onChange={handleChange}
                      value={row["hoa_status"]}
                    >
                      <option value="" key="defaultValue">
                        Select...
                      </option>
                      <option value="Active" key="Active">
                        Active
                      </option>
                      <option value="Dissolved" key="Dissolved">
                        Dissolved
                      </option>
                      <option value="Inactive" key="Inactive">
                        Inactive
                      </option>
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="nextQuestion">
              {renderRadioInput(
                "Can the association be identified via an online search?",
                "online_search",
                row.building_name === false ? true : false
              )}
              {row.online_search === true ? (
                <>
                  {renderFormInputElements(
                    "website_address",
                    "Web Addreess",
                    "text"
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            {row.business_search === true || row.online_search === true ? (
              <>
                <div className="next-section">
                  <div className="ques">
                    <div className="sel">
                      <label>Association Name</label>
                      <AsyncCreatable
                        callapi={apicall.listAssociationName}
                        handleOnChange={(id, value) => {
                          handleSelectChange(id, value);
                        }}
                        valueShown={x}
                        placeholder="Search Association Name"
                        isClearable={true}
                        isassociationVerification={true}
                        propertyID={propertyID}
                        // disabled={disableForm ? true : false}
                      />
                    </div>
                    {renderFormInputElements(
                      "point_of_contact_1",
                      "Point of Contact 1",
                      "text"
                    )}
                    {renderFormInputElements("email1", "Email", "text")}
                    <div className="tel">
                      {renderPhone("phone1", "Phone", "text")}
                      {renderExt("ext1", "Ext", "number")}
                    </div>

                    <div className="file-upload">
                      <Button onClick={() => setopenAddDocumentModal(true)}>
                        File Upload
                      </Button>
                      {_.isEmpty(row.documents) ? (
                        <h6 className="noDocument">No File Uploaded</h6>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="hoa">
                    {renderFormInputElements(
                      "point_of_contact_2",
                      "Point of Contact 2",
                      "text"
                    )}
                    {renderFormInputElements("email2", "Email", "text")}
                    <div className="tel">
                      {renderPhone("phone2", "Phone", "text")}
                      {renderExt("ext2", "Ext", "number")}
                    </div>
                    {renderFormInputElements(
                      "point_of_contact_3",
                      "Point of Contact 3",
                      "text"
                    )}
                    {renderFormInputElements("email3", "Email", "text")}
                    <div className="tel">
                      {renderPhone("phone3", "Phone", "text")}
                      {renderExt("ext3", "Ext", "number")}
                    </div>
                  </div>
                </div>
                <div className="association-document-modal">
                  <DocumentsTab
                    propertyID={propertyID}
                    documents={row.documents}
                    isassociationVerification={true}
                    deleteDocument={deleteDocument}
                    theme={"general-info-tab"}
                  />
                </div>
                <hr />
                <div className="next-section">
                  <div className="ques">
                    {(row.online_search || row.business_search) &&
                      renderRadioInput(
                        "Has the Association been confirmed via email or phone?",
                        "confirmed_email_or_post",
                        row.email1 || row.phone1 ? false : true
                      )}
                  </div>
                </div>
              </>
            ) : (
              <> </>
            )}
          </>
        )}
        <div className="status">
          <div>Search Status : {row.status ? row.status : "Pending"}</div>
          <Button
            type="button"
            id="submit"
            // style={{ float: "right", marginRight: "50%" }}
            onClick={() => handleSubmit(true)}
          >
            Submit
          </Button>
          <Button
            type="button"
            id="update"
            style={{ marginLeft: "10px" }}
            onClick={() => handleSubmit(false)}
          >
            Update
          </Button>
        </div>
        <FormGroup row>
        <Label for="" sm={2}></Label>
        <Col sm={9} style={{ position: "relative !important", left: "20px"}}>
          <Input
            type="textarea"
            name="avi_notes"
            id="avi_notes"
            placeholder="Notes"
            onChange={handleChange}
            //value={row.notes}
          />
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "3rem" }}>
          <NotesHistory notes={row.notes ? row.notes : []} />
        </FormGroup>
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Update Association Verification</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            {formElements()}
          </ValidatorForm>
        </ModalBody>
        <div className="association-document-modal">
          {openAddDocumentModal && (
            <DocumentsModal
              toggle={toggleDocument}
              title="Document"
              open={openAddDocumentModal}
              propertyID={propertyID}
              pageNo={1}
              hideDocumentName={true}
              isassociationVerification={true}
              actionID={actionID}
              avId={avId}
              fetchDocuments={getAssociationVerification}
              updateform={setrow}
              formdata={row}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="association-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
