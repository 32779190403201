import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  CustomInput,
  Button,
  Row,
} from "reactstrap";
import apicall from "../../utils/apicall";
import TextValidator from "../helper_components/TextValidator";
import FormComp from "../crud/FormComp";
//import HoaAsyncSelect from "../helper_components/HoaAsyncSelect";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_management.scss";
import config from "../../utils/config";
//import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import _ from "lodash";
import { FaTrashAlt } from "react-icons/fa";
import SelectOptions from "../helper_components/SelectOptions";
import AddressComponent from "../management_companies/AddressComponent";
import AssessmentComponent from "./Testcomponent";
import SelectManagementCompany from "../helper_components/AsyncSelectManagementCompany";
import DocumentsModal from "../documents/DocumentsModal";
import DocumentsTab from "../documents/DocumentsTab";
import helpers from "../../utils/helpers";
import TabsComponent from "../helper_components/TabsComponent";
import Leasing from "../general_info/lease_info/FinanceLeaseInfo";
import NotesHistory from "../CodeViolation/NotesHistory";

class FormComponent extends FormComp {
  constructor(props) {
    console.log("FormComponent", props);
    super(props);
    this.state = {
      open: false,
      row: props.row,
      title: props.title,
      managementCompanies: [],
      className: "hoaForm",
      openAddDocumentModal: false,
      test: null,
      isutilties: false,
      fileUpload: false,
      openAssociationDocumentModal: false,
      associationDocs: [],
      renderForm: true,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillMount() {
    if (this.props.dashboardProperty) this.getHoaById(this.state.row.id);
  }

  toggleFileUpload = () => {
    this.setState({
      fileUpload: !this.state.fileUpload,
    });
  };

  getHoaById = (hoaId) => {
    apicall.getHoa(hoaId).then((resp) => {
      setTimeout(() => {
        this.setState({
          row: resp,
        });

        if (!_.isEmpty(resp.management_company)) {
          console.log("the states is", this.state.row);
          this.setState({
            row: {
              ...this.state.row,
              management_company_id: {
                value: resp.management_company_id,
                label: resp.management_company && resp.management_company.name,
              },
            },
          });
        }
      }, 3000);
    });
  };

  handleFyChange = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var fyArray = this.state.fyArray.slice();
    var newfyArray = fyArray.map((fy, index) => {
      for (var key in fy) {
        if (key == item.name && fy.id == item.id) {
          fy[key] = item.value;
        }
      }
      return fy;
    });
    this.setState({ fyArray: newfyArray });
  };

  deleteHoaDocument = (documentId) => {
    this.setState({ isLoading: true });
    apicall.deleteHoaDoc(this.state.row.id, documentId).then((response) => {
      this.getHoaById(this.state.row.id);
      this.setState({ isLoading: false });
    });
  };

  handleNotesChange = (event) => {
    let targetName = event.target.name;
    this.setState({
      row: Object.assign(this.state.row, {
        [targetName]: event.target.value,
      }),
    });
  };

  handleSubmit = (event) => {
    if (this.state.fileUpload === true) {
      return;
    }
    let row = this.state.row;
    let utilities = [
      row.electricity,
      row.water,
      row.pest_control,
      row.gas,
      row.landscaping,
      row.sewer,
      row.cabel_internet,
      row.trash,
      row.snow_removal,
    ];

    if (this.state.row.utilities_paid && !utilities.includes(true)) {
      this.setState({
        isutilties: true,
      });
      return;
    }
    let fyArray = this.state.fyArray;
    //remove temporary Id'
    for (let index in fyArray) {
      if (_.has(fyArray[index], "status")) {
        delete fyArray[index]["id"];
        delete fyArray[index]["status"];
      }
    }
    row.assessments_attributes = fyArray;
    row.management_company_id = this.getSelectionValue(
      row.management_company_id
    );
    row.first_due_date = row.fiscal_year_start;
    delete row.management_company;
    if (row.id) {
      apicall.updateHoa(row).then((response) => {
        if (response) {
          this.setState({
            row: response,
          });
          this.notify("Updated successfully.");
          this.toggleModal();
          let diff = dayjs(row.first_due_date).diff(dayjs(), "days");

          if (row.first_due_date && diff <= 60) this.props.toggleConfirmBox();
        }
      });
    } else {
      apicall.createHoa(row).then((response) => {
        if (response) {
          this.props.setRow(response);
          this.setState({
            row: response,
          });
          this.notify("Added successfully.");
          // this.toggleModal();
          let diff = dayjs(row.first_due_date).diff(dayjs(), "days");

          if (row.first_due_date && diff <= 60) this.props.toggleConfirmBox();
        }
      });
    }
    event.preventDefault();
  };

  componentDidMount = () => {
    {
      this.props.isDisplayHOA && this.setCheckedPoc(this.props.row);
    }
  };

  setCheckedPoc = (row, newprops) => {
    if (row.id && row.management_company_id && row.management_company) {
      let option = {};
      option["value"] = row.management_company["id"];
      option["label"] = row.management_company["name"];

      if (option && newprops) newprops.row.management_company_id = option;
      else row.management_company_id = option;
    }
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      var row = newprops.row;
      this.setCheckedPoc(row, newprops);
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  handleManagementCompany = (id, valueObj) => {
    let pocs = valueObj.point_of_contacts;
    let temp = {};
    for (let poc of pocs) {
      temp[poc.id] = false;
    }
    this.setState({
      row: Object.assign(this.state.row, { [id]: valueObj }),
      checkedPoc: Object.assign({}, temp),
    });

    this.setState((prevState) => ({
      ...prevState,
      row: {
        ...prevState.row,
        management_company: {
          ...prevState.row.management_company,
          id: valueObj.value,
          name: valueObj.label,
          point_of_contacts: valueObj.point_of_contacts,
        },
      },
    }));
  };

  fy_assesment_div = (fyMap, index) => {
    const fy_assesment_div = (
      <>
        <div className="fy_assessment">
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {"FY Assessment "}
          </div>
          <FaTrashAlt
            style={{ float: "right", cursor: "pointer" }}
            size="1.5em"
            onClick={(event) => this.handleDeleteEvent(event, fyMap)}
          />
          <FormGroup row>
            <Label sm={4}>Fiscal Year Month</Label>
            <Col sm={8}>
              <Input
                type="select"
                id={fyMap.id}
                name="fiscal_year_start"
                value={fyMap.fiscal_year_start}
                onChange={this.handleFyChange}
              >
                {<SelectOptions options={config.Months_Array} />}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="">Days past Due</Label>
            <TextValidator
              type="text"
              name="days_past_due"
              id={fyMap.id}
              placeholder=""
              onChange={this.handleFyChange}
              validators={["isNumber"]}
              errorMessages={["Please enter valid number"]}
              value={fyMap.days_past_due}
            />
          </FormGroup>
          <FormGroup>
            <Label for="">Assessment Dues</Label>
            <TextValidator
              type="text"
              name="assesment_dues"
              id={fyMap.id}
              placeholder="$"
              onChange={this.handleFyChange}
              validators={["isFloat"]}
              errorMessages={["Please enter valid number"]}
              value={fyMap.assesment_dues}
            />
          </FormGroup>
          <FormGroup>
            <Label>Frequency</Label>
            <Input
              type="select"
              name="assessment_frequency"
              id={fyMap.id}
              value={_.toLower(fyMap.assessment_frequency)}
              onChange={this.handleFyChange}
            >
              {<SelectOptions options={config.FrequencyMap} />}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="">Special Assessment Dues</Label>
            <TextValidator
              type="text"
              name="special_assessment_dues"
              id="special_assessment_dues"
              placeholder="$"
              onChange={this.handleFyChange}
              validators={["isFloat"]}
              errorMessages={["Please enter valid number"]}
              value={this.state.row.special_assessment_dues}
            />
          </FormGroup>
          <FormGroup>
            <Label>Special Assessment Frequency</Label>
            <Input
              type="select"
              name="special_assessment_frequency"
              id={fyMap.id}
              value={_.toLower(fyMap.special_assessment_frequency)}
              onChange={this.handleFyChange}
            >
              {<SelectOptions options={config.FrequencyMap} />}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="">Late Fee Description</Label>
            <Input
              type="textarea"
              name="late_fee_description"
              id={fyMap.id}
              value={fyMap.late_fee_description}
              onChange={this.handleFyChange}
            />
          </FormGroup>
        </div>
      </>
    );
    return fy_assesment_div;
  };

  pocModel = (pocCount) => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });

    let pocTitleOptions = [];
    let PocTitles = config.HoaPocTitles;
    pocTitleOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    PocTitles.map((title, index) => {
      pocTitleOptions.push(
        <option value={title} key={title + index}>
          {title}
        </option>
      );
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];
    var phoneValidators = [];
    var phoneValidatorMessage = [];
    let pocNameId = "poc" + pocCount;
    let pocEmailId = "email" + pocCount;
    let pocPhoneId = "phone" + pocCount;
    let pocExtId = "ext" + pocCount;
    let pocTitleId = "title" + pocCount;
    if (
      (this.state.row[pocEmailId] == null ||
        this.state.row[pocEmailId] === "") &&
      (this.state.row[pocPhoneId] == null || this.state.row[pocPhoneId] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID",
      ];
    } else if (
      this.state.row[pocPhoneId] != null &&
      this.state.row[pocPhoneId] !== ""
    ) {
      phoneValidators = ["isUSAPhoneNumMatch"];
      phoneValidatorMessage = ["Invalid phone number"];
    }
    let pocContentPage = (
      <>
        {
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {"Point of Contact " + pocCount + (pocCount === 1 ? "*" : "")}
          </div>
        }
        <div className="poc-style">
          <FormGroup row>
            <Label
              sm={2}
              for=""
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              Name{" "}
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name={pocNameId}
                id={pocNameId}
                placeholder=""
                onChange={this.handleChange}
                value={this.state.row[pocNameId]}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ textAlign: "end", fontWeight: "bold" }}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                id={pocTitleId}
                name={pocTitleId}
                value={this.state.row[pocTitleId]}
                onChange={this.handleChange}
              >
                {pocTitleOptions}
              </Input>
              {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              sm={2}
              for=""
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name={pocEmailId}
                id={pocEmailId}
                placeholder=""
                onChange={this.handleChange}
                validators={pocCount === 1 ? emailValidators : null}
                errorMessages={pocCount === 1 ? emailValidatorMessage : null}
                value={this.state.row[pocEmailId]}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "0rem" }}>
            <Label
              sm={2}
              for=""
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              Phone{" "}
            </Label>
            <Col sm={5}>
              <TextValidator
                type="text"
                name={pocPhoneId}
                id={pocPhoneId}
                placeholder=""
                onChange={(event) => this.handleChange(event, "phone")}
                //validators={pocCount === 1 ? phoneValidators : null}
                errorMessages={pocCount === 1 ? phoneValidatorMessage : null}
                value={this.state.row[pocPhoneId]}
                maxLength="14"
              />
            </Col>
            <Label
              sm={2}
              for=""
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              Ext{" "}
            </Label>
            <Col sm={3}>
              <TextValidator
                type="text"
                name={pocExtId}
                id={pocExtId}
                placeholder=""
                onChange={this.handleChange}
                //validators={pocCount === 1 ? phoneValidators : null}
                // errorMessages={pocCount === 1 ? phoneValidatorMessage : null}
                value={this.state.row[pocExtId]}
                maxLength="14"
              />
            </Col>
          </FormGroup>
        </div>{" "}
      </>
    );
    return pocContentPage;
  };

  handleCheckbox = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let checkedPoc = this.state.checkedPoc;
    checkedPoc[name] = value;
    this.setState({
      checkedPoc: Object.assign({}, checkedPoc),
    });
  };

  assessmentsForm = () => {
    return (
      <div>
        {/* <div style={{ position: "relative", top: "60px" }}> */}
        <NotesHistory
          notes={this.state.row.notes ? this.state.row.notes : []}
          municipalModal={true}
        />
        {/* </div> */}
        <AssessmentComponent
          hoaID={this.state.row.id}
          refreshHoa={this.getHoaById}
          hoa={this.state.row}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  };

  handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    this.setState({
      row: Object.assign({ ...this.state.row }, { [targetName]: value }),
      //test: value,
    });
    if (this.state.isutilties) {
      this.setState({
        isutilties: false,
      });
    }
  };

  handleutility_price_box_change = (event) => {    
    let targetName = event.target.name;
    let value = event.target.value;
    this.setState({
      row: Object.assign({ ...this.state.row }, { [targetName]: value }),
    }); 
  };

  handleBlur = (event) => {
   let targetName = event.target.name;
    var num = event.target.value;
    var cleanNum = "";
    num = num.replace("$", "");
    if (!isNaN(num)) {
      num = parseFloat(num);
      cleanNum =  num.toFixed(2);
    }
    if (isNaN(num)) {
      num = 0;
    }

    this.setState({
      row: Object.assign({ ...this.state.row }, { [targetName]: cleanNum }),
    }); 
    
  };

  

  renderRadioInput = (label, name, utility_price_box) => {
    let utility_price_box_name;
    if (utility_price_box === true) {
      utility_price_box_name = name + "_amount"
    }
    return (
      <div className="question">
        <div className="text">{label}</div>
        <div className="radio">
          <CustomInput
            id={`${name}yes`}
            type="radio"
            name={name}
            value={true}
            onClick={(e) => this.handleRadioButton(e, true)}
            checked={this.state.row[name] === true ? true : false}
          //defaultValue={false}
          />
          <label>Yes</label>
          <CustomInput
            id={`${name}no`}
            type="radio"
            name={name}
            value={false}
            onClick={(e) => this.handleRadioButton(e, false)}
            checked={this.state.row[name] === false ? true : false}
            defaultValue={false}
          />
          <label>No</label>
          {utility_price_box === true && this.state.row[name] === true && <div className="input-fields">
            <CustomInput
              className="textbox-width"
              type="number"
              pattern="$^\d{0,7}(\.\d{1,2})?$"
              onChange={(e) => this.handleutility_price_box_change(e)}   
              onBlur={(e) =>this.handleBlur(e)}       
              name={utility_price_box_name}
              value={this.state.row[utility_price_box_name]}
              style={{ width: "100px", marginLeft: "10px", marginBottom: "10px", height:"20px" }}
            />
          </div>}
        </div>
      </div>
    );
  };

  toggleDocument = () => {
    this.setState({ openAddDocumentModal: !this.state.openAddDocumentModal });
  };
  toggleAssociationDocument = () => {
    this.setState({
      openAssociationDocumentModal: !this.state.openAssociationDocumentModal,
    });
  };

  fetchAssociationDocuments = () => {
    apicall
      .getPropertyAssociationDocuments(this.props.propertyID)
      .then((resp) => {
        this.setState({
          associationDocs: resp.results,
        });
      })
      .catch((e) => console.log(e));
  };

  formElements = () => {
    let addressTypes = config.addressTypes;
    let managementCompany = this.state.row.management_company;
    let pocArray = _.has(managementCompany, "point_of_contacts")
      ? managementCompany.point_of_contacts
      : [];
    let mc_pocs = [];

    console.log("The poc array", this.state.row.management_company_id);
    mc_pocs.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    for (let poc of pocArray) {
      mc_pocs.push(
        <option value={poc.id} key={poc.id}>
          {poc.name + (poc.title && " | " + config.McPocTitles[poc.title])}
        </option>
      );
    }

    return (
      <>
        <div className="add-management-company">
          <div className="add-model-left-portion">
            <FormGroup row>
              <Label
                for=""
                sm={2}
                style={{ fontWeight: "bold", textAlign: "end" }}
              >
                Name *
              </Label>

              <Col sm={10}>
                {" "}
                <TextValidator
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Name is required"]}
                  value={this.state.row.name}
                />
              </Col>
            </FormGroup>
            <Row form style={{ marginLeft: "3.5rem" }}>
              <Col md={4}>
                <FormGroup row>
                  <Label
                    for=""
                    sm={3}
                    style={{ fontWeight: "bold", textAlign: "end" }}
                  >
                    {" "}
                    Status{" "}
                  </Label>
                  <Col sm={9}>
                    {" "}
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      onChange={this.handleChange}
                      value={this.state.row.status}
                    >
                      <option value="" key="defaultValue">
                        Select...
                      </option>
                      <option value="Active" key="Active">
                        Active
                      </option>
                      <option value="Dissolved" key="Dissolved">
                        Dissolved
                      </option>
                      <option value="Inactive" key="Inactive">
                        Inactive
                      </option>
                      <option value="Master" key="Master">
                        Master
                      </option>
                      <option value="NonHOAPayment" key="NonHOAPayment">
                        Non HOA Payment
                      </option>
                      <option value="Voluntary" key="Voluntary">
                        Voluntary
                      </option>
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </Col>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    style={{ fontWeight: "bold", textAlign: "end" }}
                  >
                    Fax{" "}
                  </Label>
                  <Col sm={10}>
                    <TextValidator
                      type="text"
                      name="contact_fax"
                      id="contact_fax"
                      placeholder="Fax"
                      onChange={(event) => this.handleChange(event, "fax")}
                      value={this.state.row.contact_fax}
                      maxLength="14"
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup row>
                  <Label
                    for=""
                    sm={4}
                    style={{ fontWeight: "bold", textAlign: "end" }}
                  >
                    EIN/TaxID
                  </Label>
                  <Col sm={8}>
                    {" "}
                    <TextValidator
                      type="text"
                      name="ein_taxid"
                      id="ein_taxid"
                      placeholder="EIN/TaxID"
                      onChange={(event) => this.handleChange(event, "taxid")}
                      value={this.state.row.ein_taxid}
                      maxLength="10"
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup row>
              <Label for=""
                sm={2}
                style={{ fontWeight: "bold", textAlign: "end" }}>
                Invoice Recovery
              </Label>
              <Col sm={2} className="deed-radio-col">
                <div className="radio-button">
                  <CustomInput
                    id="invoice_recovery_portal"
                    type="radio"
                    name="invoice_recovery"
                    value={true}
                    onClick={(event) => this.handleRadioButton(event, "Portal")}
                    checked={this.state.row.invoice_recovery === "Portal" ? "checked" : ""}
                  />
                  <label>Portal</label>
                  <CustomInput
                    id="invoice_recovery_mail"
                    type="radio"
                    name="invoice_recovery"
                    value={false}
                    onClick={(event) => this.handleRadioButton(event, "Mail")}
                    checked={this.state.row.invoice_recovery === "Mail" ? "checked" : ""}
                  />
                  <label>Mail</label>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for=""
                sm={2}
                style={{ fontWeight: "bold", textAlign: "end" }}
              >
                Management Company{" "}
              </Label>
              {/* <HoaAsyncSelect
                id="management_company_id"
                callapi={apicall.searchManagementCompanies}
                placeholder="Search Company..."
                handleOnChange={(id, value) => {
                  this.handleManagementCompany(id, value);
                }}
                valueShown={this.state.row.management_company_id}
                isManagementCompany={true}
              /> */}
              <Col sm={10}>
                <SelectManagementCompany
                  id="management_company_id"
                  placeholder="Search Company..."
                  handleOnChange={(id, value) => {
                    this.handleManagementCompany(id, value);
                  }}
                  valueShown={this.state.row.management_company_id}
                  isRequired={true}
                />
              </Col>
            </FormGroup>
            {this.state.row.management_company_id && (
              <>
                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    className="management-company-poc"
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    Management Company POC 1
                  </Label>
                  <Col sm={10}>
                    {" "}
                    <Input
                      type="select"
                      name="management_poc1_id"
                      id="management_poc1_id"
                      value={this.state.row.management_poc1_id}
                      onChange={this.handleChange}
                    >
                      {mc_pocs}
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    className="management-company-poc"
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    Management Company POC 2
                  </Label>
                  <Col sm={10}>
                    {" "}
                    <Input
                      type="select"
                      name="management_poc2_id"
                      id="management_poc2_id"
                      value={this.state.row.management_poc2_id}
                      onChange={this.handleChange}
                    >
                      {mc_pocs}
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  {" "}
                  <Label
                    for=""
                    sm={2}
                    className="management-company-poc"
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    Management Company POC 3
                  </Label>
                  <Col sm={10}>
                    {" "}
                    <Input
                      type="select"
                      name="management_poc3_id"
                      id="management_poc3_id"
                      value={this.state.row.management_poc3_id}
                      onChange={this.handleChange}
                    >
                      {mc_pocs}
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </Col>
                </FormGroup>
              </>
            )}
            {Object.keys(addressTypes).map((addressType, index) => {
              return (
                <AddressComponent
                  key={index + "address"}
                  type={addressType}
                  handleChange={this.handleChange}
                  managementCompany={this.state.row}
                  heading={addressTypes[addressType]}
                  hoaName={this.state.row.name}
                />
              );
            })}
            {/* <FormGroup>
              <Label for="">Account </Label>
              <TextValidator
                type="text"
                name="account"
                id="account"
                placeholder="Account"
                onChange={this.handleChange}
                value={this.state.row.account}
              />
            </FormGroup> */}
          </div>
          <div className="add-model-right-portion">
            {this.pocModel(1)}
            {this.pocModel(2)}
            {this.pocModel(3)}
            {/* <FormGroup>
              <Label for="">Website</Label>
              <TextValidator
                type="text"
                name="website_address"
                id="website_address"
                placeholder="Website"
                onChange={this.handleChange}
                value={this.state.row.website_address}
              />
            </FormGroup> */}
            {/* <FormGroup>
              <Label for="">Website username</Label>
              <TextValidator
                type="text"
                name="website_username"
                id="website_username"
                placeholder="Website username"
                onChange={this.handleChange}
                value={this.state.row.website_username}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Website password</Label>
              <TextValidator
                type="text"
                name="website_password"
                id="website_password"
                placeholder="Website password"
                onChange={this.handleChange}
                value={this.state.row.website_password}
              />
            </FormGroup> */}

            {this.renderRadioInput(
              "Are any utilities paid for by the association?",
              "utilities_paid"
            )}
            {this.state.row.utilities_paid ? (
              <>
                <div className="code" style={{ width: "100%" }}>
                  {this.renderRadioInput("Electricity", "electricity", true)}
                  {this.renderRadioInput("Pest Control", "pest_control", true)}
                </div>
                <div className="code" style={{ width: "100%" }}>
                  {this.renderRadioInput("Gas", "gas", true)}
                  {this.renderRadioInput("Landscaping", "landscaping", true)}
                </div>
                <div className="code" style={{ width: "100%" }}>
                  {this.renderRadioInput("Water", "water", true)}
                  {this.renderRadioInput("Cable/Internet", "cabel_internet", true)}
                </div>
                <div className="code" style={{ width: "100%" }}>
                  {this.renderRadioInput("Trash", "trash", true)}
                  {this.renderRadioInput("Snow Removal", "snow_removal", true)}
                </div>
                <div className="code" style={{ width: "100%" }}>
                  {this.renderRadioInput("Sewer", "sewer", true)}
                </div>
              </>
            ) : (
              <></>
            )}
            {this.state.isutilties ? (
              <b style={{ color: "red" }}>
                Please select any one of the utility as Yes
              </b>
            ) : (
              <></>
            )}
            {this.renderRadioInput(
              "Is there an architectural review committee?",
              "arch_review_committee"
            )}
            {this.state.row.arch_review_committee ? (
              <div style={{ display: "flex", marginLeft: "14rem" }}>
                <Button
                  className="file-btn"
                  onClick={() => this.setState({ openAddDocumentModal: true })}
                  style={{
                    marginLeft: _.isEmpty(this.state.row.all_documents)
                      ? "3.5rem"
                      : "10rem",
                  }}
                >
                  ARC File Upload
                </Button>
                {_.isEmpty(this.state.row.all_documents) ? (
                  <h6 className="noDocument" style={{ marginLeft: "1rem" }}>
                    No File Uploaded
                  </h6>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {this.state.openAddDocumentModal && (
              <DocumentsModal
                isFileUpload={this.toggleFileUpload}
                toggle={this.toggleDocument}
                title="Document"
                open={this.state.openAddDocumentModal}
                hoaID={this.state.row.id}
                pageNo={1}
                hideDocumentName={true}
                isHoa={true}
                fetchDocuments={this.getHoaById}
                formdata={this.state.row}
              />
            )}

            <div className="hoa-document-manage">
              <div
                className={
                  _.isEmpty(this.state.row.all_documents)
                    ? "hoa-noDocument"
                    : "hoa-document"
                }
              >
                <div className="hoa-document-heading">
                  Association Documents
                </div>
                <Button
                  className="association-file-btn"
                  onClick={() =>
                    this.setState({ openAssociationDocumentModal: true })
                  }
                >
                  File Upload
                </Button>
                {_.isEmpty(this.state.row.all_documents) ? (
                  <h6 className="noDocument">No File Uploaded</h6>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            _.isEmpty(this.state.row.all_documents)
              ? "hoa-noDocument-table"
              : "hoa-document-table"
          }
        >
          {this.state.openAssociationDocumentModal && (
            <DocumentsModal
              isFileUpload={this.toggleFileUpload}
              toggle={this.toggleAssociationDocument}
              title="Document"
              open={this.state.openAssociationDocumentModal}
              pageNo={1}
              hideDocumentName={true}
              hoaID={this.state.row.id}
              fetchDocuments={this.getHoaById}
              isHOAAssociationModal={true}
              formdata={this.state.row}
            />
          )}
        </div>

        {this.state.row.all_documents && (
          <DocumentsTab
            propertyID={this.props.propertyID}
            documents={this.state.row.all_documents}
            fetchDocuments={this.getHoaById}
            isHOAAssociationModal={true}
            deleteDocument={this.deleteHoaDocument}
            hoaID={this.state.row.id}
          />
        )}

        <FormGroup row>
          <Label for="" style={{ position: "relative", left: "40px" }}>
            Notes
          </Label>
          <Col sm={11} style={{ position: "relative", left: "60px" }}>
            <Input
              type="textarea"
              name="hoa_notes"
              id="hoa_notes"
              placeholder="Notes"
              onChange={this.handleNotesChange}
            />
          </Col>
        </FormGroup>

        <div className="add_assessment">
          {/* {this.state.fyArray &&
            this.state.fyArray.map((fyMap, index) =>
              this.fy_assesment_div(fyMap, index)
            )} */}
          {/* <div className="add_fy_Assesment" onClick={this.handleAddEvent}>
            <span className="text">Add FY Assessment</span>
            <FaPlus size="1.5em" />
          </div> */}
        </div>
      </>
    );
  };
}

export default FormComponent;
