import React from "react";
import {
  Button,
  FormGroup,
  Label,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Row,
  Col,
} from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import "../../styles/_editAddress.scss";
export default class EditAddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row: {},
      title: props.title,
    };
    console.log(props);
  }

  toggleModal = () => {
    this.props.toggle();
  };

  componentWillMount() {
    var data = this.props.getAddressAndAccount(
      this.props.payee_recipient.value
    );
    let newRow = {};
    if (this.props.payee_recipient_type === "Office")
      newRow = {
        ofc_payee_name: data.ofc_payee_name,
        payee_co_name: data.payee_co_name,
        mailing_address: data.mailing_address,
        mailing_city: data.mailing_city,
        mailing_state: data.mailing_state,
        mailing_zip: data.mailing_zip,
        municipality_id: data.municipality_id,
      };
    else
      newRow = {
        payment_address_care_of: data.payment_address_care_of,
        payment_mailing_street: data.payment_mailing_street,
        payment_mailing_city: data.payment_mailing_city,
        payment_mailing_state: data.payment_mailing_state,
        payment_mailing_zipcode: data.payment_mailing_zipcode,
      };
    this.setState({
      row: Object.assign(this.state.row, newRow),
    });
  }

  handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      console.log("zip value ---->", value);
      var len = value.length;
      var res;
      if (value.substring(5, 6) != "-") {
        // console.log("indise substrin IF")
        res = value.substring(0, 5) + "-" + value.substring(5, len);
      } else {
        res = value;
      }
      this.setState({
        row: Object.assign(this.state.row, { [targetID]: res }),
      });
    } else {
      this.setState({
        row: Object.assign(this.state.row, { [targetID]: value }),
      });
    }
    // this.setState({
    //   row: Object.assign(this.state.row, {
    //     [event.target.id]: event.target.value,
    //   }),
    // });
  };

  handleAddressSubmit = (event) => {
    let row = this.state.row;
    let payee_recipient_id = this.props.payee_recipient.value;
    let payee_recipient_type = this.props.payee_recipient_type;
    row["id"] = payee_recipient_id;
    let updateApi = null;
    if (payee_recipient_type === "Hoa") {
      updateApi = "updateHoa";
    } else if (payee_recipient_type === "ManagementCompany") {
      updateApi = "updateManagementCompany";
    } else {
      updateApi = "updateAttorney";
    }
    if (payee_recipient_type === "Office") {
      var requestObject = {
        municipality: { offices_attributes: row },
      };
      console.log("The requestObject is", requestObject);

      apicall
        .updatemunicipality(
          requestObject,
          row.municipality_id,
          this.props.propertyID
        )
        .then((response) => {
          if (response) {
            this.props.updateAddress(response, row.id);
            helpers.notify("Updated successfully.");
            this.toggleModal();
          }
        });
    } else {
      apicall[updateApi](row, this.props.propertyID).then((response) => {
        if (response) {
          this.props.updateAddress(response);
          helpers.notify("Updated successfully.");
          this.toggleModal();
        }
      });
    }
    event.preventDefault();
  };

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">{"Update Payment Mailing Address"}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleAddressSubmit} autoComplete="off">
            {this.props.payee_recipient_type === "Office" ? (
              <>
                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    style={{ fontWeight: "bold", textAlign: "end" }}
                  >
                    Payee
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="ofc_payee_name"
                      id="ofc_payee_name"
                      onChange={this.handleChange}
                      value={this.state.row.ofc_payee_name}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    style={{ fontWeight: "bold", textAlign: "end" }}
                  >
                    C/o Name
                  </Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          style={{ borderRadius: "14px 0px 0px 14px" }}
                        >
                          C/o
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="address-name"
                        type="text"
                        name="payee_co_name"
                        id="payee_co_name"
                        placeholder=""
                        onChange={this.handleChange}
                        value={this.state.row.payee_co_name}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    style={{ fontWeight: "bold", textAlign: "end" }}
                  >
                    Address
                  </Label>
                  <Col sm={10}>
                    <TextValidator
                      type="text"
                      name="mailing_address"
                      id="mailing_address"
                      placeholder=""
                      onChange={this.handleChange}
                      value={this.state.row.mailing_address}
                    />
                  </Col>
                </FormGroup>
                <div>
                  <Row form>
                    <Col md={7} style={{ paddingLeft: "20px" }}>
                      <FormGroup row>
                        <Label
                          for=""
                          sm={3}
                          style={{ fontWeight: "bold", textAlign: "end" }}
                        >
                          City
                        </Label>
                        <Col sm={9}>
                          <TextValidator
                            type="text"
                            name="mailing_city"
                            id="mailing_city"
                            placeholder=" city"
                            onChange={this.handleChange}
                            value={this.state.row.mailing_city}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup row>
                        <Label
                          for=""
                          sm={4}
                          style={{ fontWeight: "bold", textAlign: "end" }}
                        >
                          State
                        </Label>
                        <Col sm={8}>
                          <TextValidator
                            type="text"
                            name="mailing_state"
                            id="mailing_state"
                            placeholder=" state"
                            onChange={this.handleChange}
                            value={this.state.row.mailing_state}
                            maxLength="2"
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup row>
                        <Label
                          for=""
                          sm={2}
                          style={{ fontWeight: "bold", textAlign: "end" }}
                        >
                          Zip
                        </Label>
                        <Col sm={10}>
                          <TextValidator
                            type="text"
                            name="mailing_zip"
                            id="mailing_zip"
                            placeholder=" zip"
                            maxLength="10"
                            onChange={(event) => this.handleChange(event, true)}
                            validators={["isAddressZipcodeMatch"]}
                            errorMessages={["Invalid Zip Code"]}
                            value={this.state.row.mailing_zip}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <>
                <FormGroup row>
                  <Label
                    for=""
                    sm={2}
                    style={{
                      paddingLeft:
                        this.props.payee_recipient_type ===
                          "ManagementCompany" && "8px",
                      paddingRight:
                        this.props.payee_recipient_type ===
                          "ManagementCompany" && "0px",
                      paddingTop:
                        this.props.payee_recipient_type ===
                          "ManagementCompany" && "0px",
                    }}
                  >
                    {this.props.payee_recipient_type === "ManagementCompany"
                      ? "Management Company Name"
                      : this.props.payee_recipient_type + " Name"}
                  </Label>
                  <Col sm={10}>
                    <Input disabled value={this.props.payee_recipient.label} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="" sm={2}>
                    C/O Name
                  </Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          style={{ borderRadius: "14px 0px 0px 14px" }}
                        >
                          C/o
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="address-name"
                        name="payment_address_care_of"
                        id="payment_address_care_of"
                        placeholder="Mailing contact name"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Mailing contact name is required"]}
                        value={this.state.row.payment_address_care_of}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="" sm={2}>
                    Address *
                  </Label>
                  <Col sm={10}>
                    <TextValidator
                      type="text"
                      name="payment_mailing_street"
                      id="payment_mailing_street"
                      placeholder="Mailing street name"
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["Mailing street name is required"]}
                      value={this.state.row.payment_mailing_street}
                    />
                  </Col>
                </FormGroup>
                <div>
                  <Row form>
                    <Col md={6}>
                      <FormGroup row>
                        <Label for="" sm={4}>
                          City *
                        </Label>
                        <Col sm={8}>
                          <TextValidator
                            type="text"
                            name="payment_mailing_city"
                            id="payment_mailing_city"
                            placeholder="Mailing city"
                            onChange={this.handleChange}
                            validators={["required"]}
                            errorMessages={["Mailing city is required"]}
                            value={this.state.row.payment_mailing_city}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup row>
                        <Label for="" sm={4}>
                          State*
                        </Label>
                        <Col sm={8}>
                          <TextValidator
                            type="text"
                            name="payment_mailing_state"
                            id="payment_mailing_state"
                            placeholder="Mailing state"
                            onChange={this.handleChange}
                            validators={["required"]}
                            errorMessages={["Mailing state is required"]}
                            value={this.state.row.payment_mailing_state}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup row>
                        <Label for="" sm={2}>
                          Zip*
                        </Label>
                        <Col sm={10}>
                          <TextValidator
                            type="text"
                            name="payment_mailing_zipcode"
                            id="payment_mailing_zipcode"
                            placeholder="XXXXX-XXXX"
                            maxLength="10"
                            onChange={(event) => this.handleChange(event, true)}
                            validators={["required", "isAddressZipcodeMatch"]}
                            errorMessages={[
                              "Mailing zip is required",
                              "Invalid Zip Code",
                            ]}
                            value={this.state.row.payment_mailing_zipcode}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    console.log(this.state.row);
    return (
      <div>
        <Modal className="edit-address" isOpen={true} toggle={this.toggleModal}>
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}
