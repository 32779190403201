import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_createvendor.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import _ from "lodash";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import config from "../../utils/config";
import { toast } from "react-toastify";

export default function MunicipalityVendorCreationModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : {});
  const [emails, setEmails] = useState(
    _.has(props, "formdata") && props.formdata.order_cc_emails
      ? props.formdata.order_cc_emails.split(",")
      : []
  );

  const [pocArray, setpocArray] = useState(
    props.formdata ? props.formdata.code_officer_details : []
  );

  useEffect(() => {
    // handleAddEvent();
    // readMunicipalVendor();
  }, []);

  const readMunicipalVendor = (id) => {
    apicall.readMunicipalVendor(id).then((response) => {
      if (response) setpocArray(response.code_officer_details);
    });
  };

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      let res = helpers.formatZip(value);
      setform(Object.assign({ ...formdata }, { [targetID]: res }));
    } else {
      setform(Object.assign({ ...formdata }, { [targetID]: value }));
    }
  };

  const handlePhoneChange = (event) => {
    let targetName = event.target.name;
    let phone = helpers.formatPhone(event.target.value);
    setform(Object.assign({ ...formdata }, { [targetName]: phone }));
    return;
  };
  const notify = (errorMsg) => toast(errorMsg);
  const handleSubmit = (event) => {
    let pocdata = pocArray;
    //remove temporary Id'
    for (let index in pocdata) {
      if (_.has(pocdata[index], "status")) {
        delete pocdata[index]["id"];
        delete pocdata[index]["status"];
      }
    }
    formdata.code_officer_details_attributes = pocdata;
    var requestObject = {
      municipal_vendor: {
        name: formdata.name,
        short_code: formdata.short_code,
        order_email: formdata.order_email,
        contact: formdata.contact,
        address: formdata.address,
        city: formdata.city,
        state: formdata.state,
        zip: formdata.zip,
        order_cc_emails: emails.toString(),
        code_officer_details_attributes:
          formdata.code_officer_details_attributes,
      },
    };
    if (formdata.id) {
      apicall
        .updateMunicipalVendor(requestObject, formdata.id)
        .then((response) => {
          if (response !== null && response.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(response.error);
            if (errorMsg) notify(errorMsg);
          } else {
            helpers.notify("Vendor Created Successfully");
            setTimeout(() => {
              // setform(response);
              props.getMunicipalVendors();
              props.toggle();
            }, 600);
          }
        });
    } else {
      apicall.createMunicipalVendor(requestObject).then((response) => {
        if (response !== null && response.hasOwnProperty("errors")) {
          let errorMsg = helpers.getErrorMsg(response.error);
          if (errorMsg) notify(errorMsg);
        } else {
          helpers.notify("Vendor Created Successfully");
          setTimeout(() => {
            setform(response);
            props.getMunicipalVendors();
            props.toggle();
          }, 600);
        }
      });
    }
  };

  const handlePocChange = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    //var pocArray = pocArray.slice();

    var newPocArray = pocArray.map((poc, index) => {
      for (var key in poc) {
        if (key == item.name && poc.id == item.id) {
          if (key === "phone") {
            let phone = helpers.formatPhone(item.value);
            poc[key] = phone;
          } else poc[key] = item.value;
        }
      }
      return poc;
    });

    setpocArray(newPocArray);
  };

  const handleDeleteEvent = (event, poc) => {
    formdata.id &&
      apicall.deleteCodeOfficerDetails(formdata.id, poc.id).then((resp) => {
        helpers.notify("POC deleted");
      });
    setTimeout(() => readMunicipalVendor(formdata.id), 3000);
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    var poc = {
      id: id,
      name: "",
      title: "",
      email: "",
      phone: "",
      status: "temp",
    };

    setpocArray([...pocArray, poc]);
  };

  const pocModel = (poc, index) => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];
    var phoneValidators = [];
    var phoneValidatorMessage = [];
    let pocTitleOptions = [];
    let PocTitles = config.McPocTitles;
    pocTitleOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    let titleIndex = 0;
    for (var key in PocTitles) {
      if (PocTitles.hasOwnProperty(key)) {
        pocTitleOptions.push(
          <option value={key} key={PocTitles[key] + titleIndex}>
            {PocTitles[key]}
          </option>
        );
      }
      titleIndex = titleIndex + 1;
    }

    if (
      (poc["email"] == null || poc["email"] === "") &&
      (poc["phone"] == null || poc["phone"] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID",
      ];
    } else if (poc["phone"] != null && poc["phone"] !== "") {
      phoneValidators = ["isUSAPhoneNumMatch"];
      phoneValidatorMessage = ["Invalid phone number"];
    }
    let pocDiv = (
      <>
        <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
          {"Code Officer Details "}
        </div>
        <div className="poc-style">
          <FaTrashAlt
            style={{ float: "right", cursor: "pointer" }}
            size="1.5em"
            onClick={(event) => handleDeleteEvent(event, poc)}
          />
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Name
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="name"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={poc.name}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                id={poc.id}
                name="title"
                value={_.kebabCase(poc.title)}
                onChange={handlePocChange}
              >
                {pocTitleOptions}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="email"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={emailValidators}
                errorMessages={emailValidatorMessage}
                value={poc.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "0rem" }}>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Phone
            </Label>
            <Col sm={10}>
              {/* <TextValidator
                type="text"
                name="phone"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={phoneValidators}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
              /> */}
              <TextValidator
                type="text"
                name="phone"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={["required"]}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
                maxLength="14"
              />
              {/* <TextValidator
                type="number"
                name="phone"
                id={poc.id}
                placeholder="phone"
                onChange={handlePocChange}
                validators={["required"]}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
                maxLength="14"
              /> */}
            </Col>
          </FormGroup>
        </div>
      </>
    );
    return pocDiv;
  };

  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={true} toggle={props.toggle} className="vendor-modal">
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className="">
                {" "}
                {`${formdata.id ? "Edit " : "Add "}Vendor `}
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              <FormGroup row>
                <Label for="" sm={2}>
                  Vendor Name *
                </Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Vendor Name"
                    validators={["required"]}
                    errorMessages={["Name is required"]}
                    onChange={handleChange}
                    value={formdata.name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Vendor Short Code</Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="short_code"
                    id="short_code"
                    placeholder="Vendor Short Code"
                    validators={["required"]}
                    errorMessages={["Code is required"]}
                    onChange={handleChange}
                    value={formdata.short_code}
                    maxLength={4}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} for="">
                  Order E-mail ID To
                </Label>
                <Col sm={5}>
                  <TextValidator
                    type="text"
                    name="order_email"
                    id="order_email"
                    placeholder="Email"
                    onChange={handleChange}
                    errorMessages={["Email is required"]}
                    validators={["required"]}
                    value={formdata.order_email}
                  />
                </Col>
                <Label sm={1}>Contact</Label>
                <Col sm={4}>
                  <TextValidator
                    type="text"
                    name="contact"
                    id="contact"
                    placeholder="phone"
                    onChange={handlePhoneChange}
                    validators={["required"]}
                    value={formdata.contact}
                    maxLength="14"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} for="">
                  Order E-mail ID CC
                </Label>
                <Col sm={10}>
                  <ReactMultiEmail
                    placeholder="Enter cc emails "
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails(_emails);
                    }}
                    validateEmail={(email) => {
                      return isEmail(email); // return boolean
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="" sm={2}>
                  Address
                </Label>
                <Col sm={10}>
                  <TextValidator
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    validators={["required"]}
                    errorMessages={["Mailing street name is required"]}
                    onChange={handleChange}
                    value={formdata.address}
                  />
                </Col>
              </FormGroup>
              <div>
                <Row form>
                  <Col md={6}>
                    <FormGroup row>
                      <Label for="" sm={4}>
                        City
                      </Label>
                      <Col sm={8}>
                        <TextValidator
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Mailing city"
                          validators={["required"]}
                          errorMessages={["Mailing city is required"]}
                          onChange={handleChange}
                          value={formdata.city}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup row>
                      <Label for="" sm={3}>
                        State
                      </Label>
                      <Col sm={9}>
                        <TextValidator
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Mailing state"
                          validators={["required"]}
                          errorMessages={["Mailing state is required"]}
                          onChange={handleChange}
                          value={formdata.state}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup row>
                      <Label for="" sm={2}>
                        Zip
                      </Label>
                      <Col sm={10}>
                        <TextValidator
                          type="text"
                          name="zip"
                          id="zip"
                          placeholder="XXXXX-XXXX"
                          maxLength="10"
                          validators={["required", "isAddressZipcodeMatch"]}
                          errorMessages={[
                            "zip is required",
                            "Invalid Zip Code",
                          ]}
                          onChange={(event) => handleChange(event, true)}
                          value={formdata.zip}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="add-model-right-portion">
                {" "}
                <div>
                  {pocArray &&
                    pocArray.map((poc, index) => {
                      return <div>{pocModel(poc, index)}</div>;
                    })}
                </div>
                <div className="add-poc" onClick={handleAddEvent}>
                  <span className="text">Add POC</span>
                  <FaPlus size="1.5em" />
                </div>
              </div>
              <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
                Submit
              </Button>
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}
