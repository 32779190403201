import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import apicall from "../../utils/apicall";
//import helpers from "../../utils/helpers";
import { toast } from "react-toastify";
import "../../styles/_import-modal.scss";
import Loader from "../helper_components/LoaderComponent";
import BootstrapTable from "react-bootstrap-table-next";
import _ from "lodash";
class ImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      title: props.title,
      className: props.className,
      toggleParentModal: props.toggleParentModal,
      formAction: props.formAction,
      file: null,
      uploadType: props.uploadType,
      uploaded: false,
      response: null,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  handleSubmit = (event) => {
    let me = this;
    me.setState({ isLoading: true });
    apicall
      .uploadPropertyFile(this.state.file, this.state.uploadType)
      .then((response) => {
        // helpers.notify(this.state.title + " Uploaded Successfully");
        me.setState({ isLoading: false });
        me.setState({ uploaded: true });
        this.setState({ response: response });
        // me.toggle();
      })
      .catch((e) => {
        me.setState({ isLoading: false });
        // me.toggle();
        me.setState({ uploaded: true });
      });
    event.preventDefault();
  };

  toggle() {
    this.setState({
      open: !this.state.open,
    });
    this.state.toggleParentModal();
  }

  notify = (message) => toast(message);

  reasonFormatter = (cell, row) => {
    let notesArr = !_.isEmpty(cell) && cell.split("\\n");
    let notes =
      _.isArray(notesArr) &&
      notesArr
        .filter((x) => x !== "")
        .map((x, ind) => {
          return (
            <>
              <span>{x}</span>
              <br />
            </>
          );
        });
    return <>{notes}</>;
  };

  render() {
    const columnsData = [
      {
        dataField: "clientid",
        text: "Client ID",
        headerClasses: "clientid-width",
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "status-width",
      },
      {
        dataField: "reason",
        text: "Reason",
        formatter: this.reasonFormatter,
      },
    ];

    const titleMuniColumns = [
      {
        dataField: "Row number",
        text: "Row Number",
        headerClasses: "clientid-width",
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "status-width",
      },
      {
        dataField: "reason",
        text: "Reason",
        formatter: this.reasonFormatter,
      },
    ];
    let columns;
    if (
      this.state.uploadType === "title-order" ||
      this.state.uploadType === "muni-order"
    )
      columns = titleMuniColumns;
    else columns = columnsData;
    return (
      <>
        {this.state.isLoading && <Loader />}
        <div>
          <Modal
            isOpen={this.state.open}
            toggle={this.toggle}
            className={this.state.uploaded ? "status-modal" : "import-modal"}
          >
            <ModalHeader toggle={this.toggle}>
              {!this.state.uploaded ? (
                <span className="heading">{"Upload " + this.state.title}</span>
              ) : (
                <span>File Upload Status</span>
              )}
            </ModalHeader>
            {!this.state.uploaded ? (
              <ModalBody>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup className="import-file">
                    <Input
                      type="file"
                      name="file"
                      id="name"
                      onChange={this.handleChange}
                    />
                    <button>Submit</button>
                  </FormGroup>
                </Form>
              </ModalBody>
            ) : (
              <ModalBody className="upload-status">
                {this.state.response && (
                  <>
                    <div className="status">
                      <div>
                        Success Count : {this.state.response.success_count}
                      </div>
                      <div>
                        Failure Count : {this.state.response.failure_count}
                      </div>
                      <div>
                        <a href={this.state.response.report_file}>
                          Download Report
                        </a>
                      </div>
                    </div>
                    <p>
                      <BootstrapTable
                        keyField="id"
                        data={
                          this.state.response.failed_rows
                            ? this.state.response.failed_rows
                            : []
                        }
                        columns={columns}
                      />
                    </p>
                  </>
                )}
              </ModalBody>
            )}
          </Modal>
        </div>
      </>
    );
  }
}

export default ImportModal;
