import React, { Component } from "react";
import { Button, CustomInput, Col, Row, Form } from "reactstrap";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import "../../styles/area_analysts.css";
import AreaAnalystListTable from "./AreaAnalystListTable";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent";
import config from "../../utils/config";
export default class AreaAnalystsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaAnalystArray: [],
      analystfile: null,
      isLoading: true,
      pageNo: 1,
      form_inputs: { sort: {} },
    };
  }

  componentWillMount() {
    config.localforage
      .getItem("search-text")
      .then((searchText) => {
        this.setState({ searchText: searchText });
      })
      .catch((e) => console.log(e));
    config.localforage
      .getItem("page-number")
      .then((pageNo) => {
        this.setState({ pageNo: pageNo });
      })
      .catch((e) => console.log(e));

    config.localforage
      .getItem("per-page")
      .then((perPage) => {
        this.setState({ perPage: perPage });
      })
      .catch((e) => console.log(e));

    this.getList(1);
  }

  getList = (event, pageNo, perPage) => {
    this.setState({ isLoading: true });
    apicall
      .getAreaAnalysts(this.state.form_inputs, pageNo, perPage, this.state.q)
      .then((response) => {
        this.setState({
          areaAnalystArray: response.results,
          totalCount: response.total,
          isLoading: false,
          pageNo,
          perPage,
        });
      });
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props && !newprops.openImportModal) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }
  sortColumns = (response) => {
    this.setState({
      form_inputs: Object.assign(this.state.form_inputs, { sort: response }),
    });
    setTimeout(() => {
      this.getList();
    }, 1000);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loader />}

        <section className="content">
          <header className="content-title">
            <div className="page-title">Area Analysts</div>
          </header>
          <Row>
            <Col xs="4">
              <Form
                onSubmit={(e) => {
                  this.getList(e, 1);
                  e.preventDefault();
                }}
              >
                <CustomInput
                  className="filter-input"
                  id="q"
                  type="text"
                  placeholder="Filter by State or Zip code"
                  onChange={(e) => this.setState({ q: e.target.value })}
                />
                <Button
                  className="search-btn"
                  onClick={(e) => {
                    this.getList(e, 1);
                    e.preventDefault();
                  }}
                >
                  Search
                </Button>
              </Form>
            </Col>
          </Row>
          <AreaAnalystListTable
            areaAnalystArray={this.state.areaAnalystArray}
            refreshList={this.getList}
            pageNo={this.state.pageNo}
            perPage={this.state.perPage}
            totalCount={this.state.totalCount}
            sortColumns={this.sortColumns}
          />
        </section>
      </React.Fragment>
    );
  }
}
