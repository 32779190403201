import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import "../../../styles/_general.scss";
import ImportPropertyModal from "../../helper_components/ImportModal";
import MuniOrderList from "../municipality_order/MuniOrderList";
import apicall from "../../../utils/apicall";
import _ from "lodash";
import LoaderComponent from "../../helper_components/LoaderComponent";
import MunicipalNewOrderModal from "./MunicipalNewOrderModal";

export default function MuniOrderHistory(props) {
  const [importModal, setimportModal] = useState(false);
  const [orderList, setorderList] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(20);
  const [isLoading, setisLoading] = useState(true);
  const [total, settotal] = useState();
  const [vendors, setvendors] = useState([]);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  const [openOderModal, setopenOderModal] = useState(false);

  const toggleImport = () => {
    setimportModal(!importModal);
  };

  const toggleOderModal = () => {
    setopenOderModal(!openOderModal);
  };

  useEffect(() => {
    console.log(props.clientId);
    if (props.clientId) {
      getMuniOrderList();
    }
  }, [props.clientId, form_inputs.sort]);

  const getMuniOrderList = (event, pageNo, perPage) => {
    apicall
      .listMunicipalOrderByid(form_inputs, props.clientId, pageNo, perPage)
      .then((response) => {
        setorderList(response.results);
        setisLoading(false);
        settotal(response.total);
        setpageNo(pageNo);
        setperPage(perPage);
      });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };
  return (
    <div className="general">
      <div className="headings">
        {isLoading && <LoaderComponent />}
        <Button
          onClick={toggleOderModal}
          className=""
          style={{ marginBottom: "0px", marginTop: "0px" }}
        >
          Muni Order
        </Button>
        <Button
          onClick={toggleImport}
          className=""
          style={{ marginBottom: "0px", marginTop: "0px", marginLeft: "10px" }}
        >
          Muni Order Import
        </Button>
      </div>
      {_.isEmpty(orderList) ? (
        <div className="table-empty">No Muni Ordered</div>
      ) : (
        <MuniOrderList
          orderList={orderList}
          getMunicipalOrderList={getMuniOrderList}
          pageNo={pageNo}
          total={total}
          toggle={toggleImport}
          vendors={vendors}
          sortColumns={sortColumns}
        />
      )}
      {importModal && (
        <ImportPropertyModal
          uploadType="muni-order"
          toggleParentModal={toggleImport}
          className=""
          title="Muni Order"
          open={importModal}
          uploaded={false}
        />
      )}
      {openOderModal && (
        <MunicipalNewOrderModal
          toggle={toggleOderModal}
          open={openOderModal}
          getMunicipalOrderList={getMuniOrderList}
          clientId={props.clientId}
        />
      )}
    </div>
  );
}
