import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { Button } from "reactstrap";
import TitleDocumentsModal from "../documents/TitleDocumentsModal";
import PaginationComponent from "../helper_components/PaginationComponent";
import { FaEdit, FaTrashAlt, FaBan } from "react-icons/fa";
import VendorUserNotesModal from "./VendorUserNotesModal";
import _ from "lodash";
import ConfirmBox from "../helper_components/ConfirmBox";
export default function MunicipalityUserTable(props) {
  const [vendors, setvendors] = useState(props.vendors);
  const [openAddDocumentModal, setopenAddDocumentModal] = useState(false);
  const [rowID, setrowID] = useState();
  const [editModal, seteditModal] = useState(false);
  const [notes, setnotes] = useState();
  const [deleteModal, setdeleteModal] = useState(false);
  const [rowData, setRowData] = React.useState(null);
  const [uploadedFileId, setUploadedFileId] = React.useState(null);

  useEffect(() => {
    setvendors(props.vendors);
  }, [props.vendors]);

  const toggleDocument = () => {
    setopenAddDocumentModal(!openAddDocumentModal);
  };

  const fetchClientOrder = (event, pageNo, perPage) => {
    apicall.clientMunicipalityOrders(pageNo, perPage).then((response) => {
      setvendors(response.results);
    });
  };

  const toggleEditModal = () => {
    seteditModal(!editModal);
  };

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return helpers.formatDate(cell);
  };

  const cancelledDateFormatter = (cell, row, rowIndex, formatExtraData) => {
    // return helpers.formatDate(cell);
    if (!_.isEmpty(cell))
      return <div style={{ color: "red" }}>{helpers.formatDate(cell)}</div>;
  };

  const deleteFileUpload = (rowData) => {
    apicall
    .municipalityDeleteUploadDocuments(rowData.id,uploadedFileId)
    .then((resp) => {
      helpers.notify("Document deleted successfully");
    });
    setTimeout(() => {
      fetchClientOrder()
    },1000)
  }

  const addHistoryLink = (cell, row, rowIndex, formatExtraData) => {
    let address, street, cityStateZip;
    address = row.address;
    address = address.split(",");
    street = address[0];
    cityStateZip = address[1] + "," + address[2] + "," + address[3];
    let current_owner_name = row.property_group;
    let county = row.county;
    let parcel_id = row.parcel_id;
    return (
      <div>
        <span
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            // setorderid(row.id);
            // setclientid(row.clientid);
            // setordersModal(true);
          }}
        >
          {cell}
        </span>
        <div>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>Address:</span>
          <span>{street}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            City,State,Zip:
          </span>
          <span>{cityStateZip}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>County:</span>
          <span>{county}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            Current Owner Name:
          </span>
          <span>{current_owner_name}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            Parcel ID :
          </span>
          <span>{parcel_id}</span>
        </div>
      </div>
    );
  };

  const renderFileURL = (cell, row) => {
    let file_urls = row.file_urls;
    // console.log("The file ", file_urls);

    return file_urls.map((x) => {
      return (
        <>
          <a
            href={x[1]}
            style={{ float: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {x[0]}
          </a>
          <FaTrashAlt size="1.0em" style ={{color: "red", marginLeft: "15px", cursor: "pointer"}}
           onClick={() => {
            setUploadedFileId(x[2])
            setdeleteModal(true);
            setRowData(row)
           }}
            />
          <br />
        </>
      );
    });
  };

  const uploadFile = (cell, row) => {
    if (row.cancelled_at === null)
      return (
        <Button
          style={{ marginBottom: "0px", marginTop: "0px" }}
          onClick={() => {
            setopenAddDocumentModal(true);
            setrowID(row.id);
          }}
        >
          Upload File
        </Button>
      );
  };

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        {" "}
        {cell}
        <FaEdit
          style={{ marginRight: "4%", float: "right" }}
          size="1.5em"
          onClick={() => {
            //  setformdata(row);
            seteditModal(true);
            setrowID(row.id);
            setnotes(row.notes);
          }}
        />
      </div>
    );
  };
  const columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      formatter: addHistoryLink,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "vendor_name",
      text: "Vendor Name",
    },
    {
      dataField: "order_type",
      text: "Order Type",
    },
    {
      dataField: "orderid",
      text: "Order ID",
    },

    {
      dataField: "ordered_at",
      text: "Order Date",
      formatter: dateFormatter,
    },
    // {
    //   dataField: "order_received_date",
    //   text: "Order Rrceived Date",
    //   formatter: dateFormatter,
    // },
    {
      dataField: "cancelled_at",
      text: "Cancel Date",
      formatter: cancelledDateFormatter,
    },

    // {
    //   dataField: "notes",
    //   text: "Notes",
    //   formatter: buttonFormatter,
    // },
    {
      dataField: "file_urls",
      text: "File Uploaded",
      formatter: renderFileURL,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      formatter: uploadFile,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];

  return (
    <>
      <div
        key={props.vendors}
        style={{ marginTop: "10rem", width: "90%", marginLeft: "1rem" }}
      >
        {/* <BootstrapTable
          remote
          keyField={"id"}
          data={vendors}
          columns={columns}
        /> */}
        <PaginationComponent
          columns={columns}
          rows={vendors}
          handleSubmit={fetchClientOrder}
          pageNo={props.pageNo}
          totalCount={props.totalCount}
        />
      </div>
      {openAddDocumentModal && (
        <TitleDocumentsModal
          toggle={toggleDocument}
          open={openAddDocumentModal}
          id={rowID}
          title="Document"
          isMuniVendorUser={true}
          fetchDocuments={fetchClientOrder}
        />
      )}
      {editModal && (
        <VendorUserNotesModal
          modal={editModal}
          toggle={toggleEditModal}
          id={rowID}
          fetchDocuments={fetchClientOrder}
          formdata={notes}
          isMuniVendorUser={true}
        />
      )}
      {deleteModal && (
        <ConfirmBox
          onYesClick={() => {
            deleteFileUpload(rowData);
            setdeleteModal(false);
          }}
          onNoClick={() => setdeleteModal(false)}
          heading={
            <span> Confirm Delete? </span>
          }
          vendorFileDelete= {true}
        >
        </ConfirmBox>
      )}
    </>
  );
}
