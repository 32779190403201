import React, { useState } from "react";
import "../../styles/propertyupload.css";
import "../../styles/content.css";
import MunicipalityOrder from "./municipality_order/MunicipalityOrder";
import TitleOrder from "./title_order/TitleOrder";
import TabsComponent from "../helper_components/TabsComponent";

export default function OrdersComponent(props) {
  const [activeTab, setactiveTab] = useState(1);

  const updateActiveTab = (value) => {
    setactiveTab(value);
  };

  let listofTabs = "";

  listofTabs = ["Title Orders", "Municipality Orders"];

  let tabsComponentsList = new Array(listofTabs.length).fill(null);
  tabsComponentsList[0] = <TitleOrder />;
  tabsComponentsList[1] = <MunicipalityOrder />;

  return (
    <React.Fragment>
      <section className="content">
        <header className="content-title property-upload">
          <div className="page-title">Order Management</div>
        </header>

        <div className="general-component" style={{ width: "95%" }}>
          <TabsComponent
            components={tabsComponentsList}
            listofTabs={listofTabs}
            generateTabURL={false}
            activeTab={activeTab}
            theme={"general-info-tab"}
            updateActiveTab={updateActiveTab}
            isVendor={true}
          />
        </div>
      </section>
    </React.Fragment>
  );
}
