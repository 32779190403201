import React, { useState, useEffect } from "react";
import { Input, Modal, ModalHeader, ModalBody, CustomInput , FormGroup, Label, Col,} from "reactstrap";
import SelectOptions from "../../helper_components/SelectOptions";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import { ValidatorForm } from "react-form-validator-core";
import { FaEye } from "react-icons/fa";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "../../../styles/_TitleClearance.scss";
import NotesHistory from "../../CodeViolation/NotesHistory";

export default function TitleClearance(props) {
  const [rows, setrows] = useState([]);
  const [issueTypes, setissueTypes] = useState();
  const [responsibility, setresponsibility] = useState([
    "FA",
    "Client",
    "Servicer",
  ]);
  const [title_clearance_notes, setTitle_Clearance_Notes] =React.useState(null);
  const [notes, setNotes] = React.useState([]);
  const [followup_date, setfollowup_date] = useState();
  useEffect(() => {
    if (rows.length === 0) addRows();
  }, []);

  useEffect(() => {
    getIssueTypes();
    readTitleClearance();
  }, []);

  const getIssueTypes = () => {
    apicall.getIssueTypes().then((response) => {
      // let arr = Object.keys(response).map(function (key, index) {
      //   return response[key];
      setissueTypes(response);

      // });
    });
  };

  const readTitleClearance = () => {
    apicall.readTitleClearance(props.titleActionID).then((response) => {
      if(response){
        setrows(response.clearance_issues);
        setfollowup_date(response.followup_date);
        setTitle_Clearance_Notes(response.title_clearance_notes);
        setNotes(response.notes);
      }
    });
  };
  const addRows = () => {
    const tempId = (+new Date() + Math.floor(Math.random() * 999999)).toString(
      36
    );
    let row = {
      id: tempId,
      issue_type: "",
      resicap_input: "",
      responsibility: "",
      fa_client_servicer: "",
      issue_resolved: "",
      status: "new",
    };
    setrows([...rows, row]);
  };

  const handleSubmit = () => {
    let arr;
    arr = rows.map((x) => {
      if (x.status === "new") {
        delete x.id;
        delete x.status;
      }
      return x;
    });

    apicall
      .updateTitleClearance(props.titleActionID, arr, followup_date, title_clearance_notes)
      .then((resp) => {
        helpers.notify("Title Clearance Details Saved");
        props.toggle();
        props.refershActions();
      });
  };

  const updateTable = (newData) => {
    const updatedRows =
      rows &&
      rows.slice().map((row, index) => {
        if (row.id == newData.id) {
          let x = { ...row };
          x[newData.name] = newData.value;
          return x;
        } else return row;
      });
    setrows(updatedRows);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const newData = {
      id: event.target.id,
      name: event.target.name,
      value: value,
    };
    updateTable(newData);
  };
  const handleDateChange = (value) => {
    let date = helpers.restructureDate(value);
    setfollowup_date(date);
  };

  const formElements = () => {
    return (
      <>
        <Table
          addRows={addRows}
          rows={rows}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          issueTypes={issueTypes}
          responsibility={responsibility}
        ></Table>
        <div class="follow-up-date">
          <h6>Follow Up Date</h6>
          <div className="dateField">
            <DatePicker
              id={"followup_date"}
              selected={
                followup_date ? helpers.restructureDate(followup_date) : null
              }
              onChange={(value) => {
                handleDateChange(value);
              }}
              value={followup_date ? helpers.restructureDate(followup_date) : null}
            />
          </div>
        </div>
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Title Clearance Action</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  const handleNotesChange = (event) => {
    setTitle_Clearance_Notes(event.target.value)
  };

  return (
    <div>
      <Modal className="title-modal" isOpen={props.open} toggle={props.toggle}>
        {renderModal()}
        <FormGroup row>
                <Label for="" sm={2}>Notes</Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="title_clearance_notes"
                    id="title_clearance_notes"
                    placeholder="Notes"
                    onChange={handleNotesChange}
                  // value={row.code_violation_notes}
                  />
                </Col>
              </FormGroup>
        <NotesHistory  notes = {notes ? notes : []} />
      </Modal>
    </div>
  );
}

function Table(props) {
  const tableRows =
    props.rows &&
    props.rows.map((row, index) => {
      return (
        <Row
          key={row.id}
          index={index}
          handleChange={props.handleChange}
          handleDateChange={props.handleDateChange}
          handleSubmit={props.handleSubmit}
          deleteAssessment={props.deleteAssessment}
          row={row}
          issueTypes={props.issueTypes}
          responsibility={props.responsibility}
        />
      );
    });
  return (
    <div>
      <></>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: "12%" }}>Issue Type</th>
              <th style={{ width: "17%" }}>RESICAP Input</th>
              <th style={{ width: "3%" }}>Responsibility</th>
              <th style={{ width: "17%" }}>FA/Client/Servicer</th>
              <th style={{ width: "2%", textAlign: "center" }}>
                Issue Resolved
              </th>
              {/* <th style={{ width: "1%", textAlign: "center" }}>Notes</th> */}
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
      <div className="charges-header">
        <div
          className="add-charge"
          style={{ marginLeft: "40%", display: "flex" }}
        >
          <button
            type="button"
            onClick={props.addRows}
            className="btn btn-secondary "
          >
            Add New Issue
          </button>
          <button
            type="button"
            onClick={props.handleSubmit}
            className="btn btn-secondary add-charge-button"
            style={{ marginLeft: "10px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

function Row(props) {
  return (
    <tr>
      <DropDownCell
        handleChange={props.handleChange}
        cellData={{
          type: "issue_type",
          value: props.row.issue_type,
          id: props.row.id,
        }}
        issueTypes={props.issueTypes}
        disabled={props.row.status !== "new"}
      />
      <InputCell
        handleChange={props.handleChange}
        cellData={{
          type: "resicap_input",
          value: props.row.resicap_input,
          id: props.row.id,
        }}
      />
      <DropDownCell
        handleChange={props.handleChange}
        cellData={{
          type: "responsibility",
          value: props.row.responsibility,
          id: props.row.id,
        }}
        responsibility={props.responsibility}
      />
      <InputCell
        handleChange={props.handleChange}
        cellData={{
          type: "fa_client_servicer",
          value: props.row.fa_client_servicer,
          id: props.row.id,
        }}
      />
      <CheckboxCell
        handleChange={props.handleChange}
        cellData={{
          type: "issue_resolved",
          value: props.row.issue_resolved,
          id: props.row.id,
        }}
      />

      {/* <td style={{ textAlign: "center" }}>
        <FaEye
          style={{ fontSize: "14px", cursor: "pointer" }}
          size="1.5em"
          onClick={() => props.deleteAssessment(props.row.id, props.row.status)}
        />
      </td> */}
    </tr>
  );
}

// Cell component

function InputCell(props) {
  return (
    <td>
      <Input
        type="textarea"
        name={props.cellData.type}
        id={props.cellData.id}
        value={props.cellData.value === "null" ? "" : props.cellData.value}
        onChange={props.handleChange}
      />
    </td>
  );
}

function CheckboxCell(props) {
  return (
    <td>
      <input
        type="checkbox"
        name={props.cellData.type}
        id={props.cellData.id}
        value={props.cellData.value}
        onChange={props.handleChange}
        checked={props.cellData.value ? true : false}
        style={{
          width: "20%",
          marginLeft: "30px",
        }}
      />
    </td>
  );
}

function DropDownCell(props) {
  return (
    <td>
      <Input
        type="select"
        name={props.cellData.type}
        id={props.cellData.id}
        value={props.cellData.value}
        onChange={props.handleChange}
        disabled={props.disabled}
      >
        {props.cellData.type === "issue_type" ? (
          <SelectOptions options={props.issueTypes} />
        ) : (
          <SelectOptions options={props.responsibility} />
        )}
      </Input>
    </td>
  );
}
