import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
var Underwritingapi = {
    getUnderwritinglist: async function(id) {
        let url = config.baseUrl + `/api/v1/properties/${id}/underwriting_tab`;
        let res = "";
        let init = {
            method: "GET",
            headers: await helpers.getSavedToken(),
          };
          await fetch(url, init)
            .then((response) => {
              if (response.ok) {
                return response.clone().json();
              }
              throw new Error("Something went wrong, while getting underwriting List" + response);
            })
            .then((responseJson) => {
              res = responseJson;
            })
            .catch(function (error) {});
          return res;
    },
    deleteAssessment: async function (assessmentTypeId, assessmentId, hoaID) {
        let url = `${config.baseUrl}/api/v1/assessment_types/${assessmentTypeId}/delete_assessments?assessment_id=${assessmentId}&hoa_id=${hoaID}`;
        var init = {
          method: "DELETE",
          headers: await helpers.defaultHeaders(),
        };
        await fetch(url, init)
          .then(helpers.fetchHandler)
          .then((json) => json.json);
      },
};

export default withRouter(Underwritingapi);
