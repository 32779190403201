import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import apicall from "../utils/apicall";
import TextValidator from "./helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import helpers from "../utils/helpers";
import _ from "lodash";
import config from "../utils/config";
import Select from "react-select";
import CodeViolationModal from "./CodeViolation/CodeViolationModal";
import LienActionModal from "./LienActions/LienActionModal";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

class ActionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentTypes: {},
      actionModal: false,
      actionId: "",
      actionTypes: [],
      description: "",
      creationDateAge: "",
      noteActionsNextSteps: "",
      actionable_type: "",
      current_status: "",
      notes: "",
      action_notes: "",
      due_date: "",
      payment_type: "",
      paymentHoas: [],
      hoa_id: null,
      hideSubmit: false,
      paymentMunicipality: [],
      office_id: null,
      municipality_id: null,
      associable_type: null,
      requirement_type:"",
      openCodeViolationModal: false,
      openLienActionModal: false,
    };
    this.state = {
      ...this.state,
      ...this.props,
      // ...this.props.action,
    };
  }

  componentWillMount() {
    apicall.fetchActionTypes().then((actionTypes) => {
      this.setState({
        actionTypes: actionTypes.types,
      });
    });

    apicall.fetchPaymentTypes(this.props.propertyID).then((response) => {
      this.setState({
        paymentTypes: response,
      });
    });
    apicall.fetchPaymentHoas(this.props.propertyID).then((response) => {
      this.setState({
        paymentHoas: response.Hoa,
        paymentMunicipality: response.Office,
      });
    });
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
      if (newprops.updateModal) {
        if (
          typeof newprops.action !== "undefined" &&
          typeof newprops.action.current_status !== "undefined"
        ) {
          this.setState({
            current_status: newprops.action.current_status,
            notes: newprops.action.notes,
          });
        }
      }
    }
  }

  handleDateChange = (value) => {
    this.setState({
      due_date: value,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  notify = (errorMsg) => toast(errorMsg);

  handleSubmit = (event) => {
    if (this.state.actionable_type === "TitleLien") {
      this.setState({
        openLienActionModal: !this.state.openLienActionModal,
      })
      return
    }
    if (this.state.actionable_type === "CodeViolation") {
      this.setState({
        openCodeViolationModal: !this.state.openCodeViolationModal,
      })
      return
    }
    let actions = {};
    if (!this.state.updateModal) {
      let actionable_type;
      if (
        this.state.actionable_type === "FyAssessment" ||
        this.state.actionable_type === "AccountReview" ||
        this.state.actionable_type === "GeneralPayment"
      )
        actionable_type = "Payment";
      else actionable_type = this.state.actionable_type;

      let description;
      if (this.state.actionable_type === "FyAssessment")
        description = "fy_assessment";
      else if (this.state.actionable_type === "AccountReview")
        description = "account_review";
      else if (this.state.actionable_type === "GeneralPayment")
        description = "general_payment";
      else description = this.state.description;

      let payment_type;
      if (this.state.actionable_type === "FyAssessment")
        payment_type = "fy_assessment";
      else if (this.state.actionable_type === "AccountReview")
        payment_type = "account_review";
      else if (this.state.actionable_type === "GeneralPayment")
        payment_type = "general_payment";
      else payment_type = this.state.payment_type;

      actions["actionable_type"] = actionable_type;
      actions["description"] = description;
      actions["creation_date"] = new Date();
      actions["current_status"] = "Pending";
      actions["due_date"] = helpers.formatApiDate(this.state.due_date);
      actions["payment_type"] = payment_type;
      actions["hoa_id"] = this.state.hoa_id;
      actions["office_id"] = this.state.office_id;
      actions["municipality_id"] = this.state.municipality_id;
      actions["requirement_type"] = this.state.requirement_type;
      apicall
        .actionCreate(actions, this.state.propertyID)
        .then((actionCreate) => {
          this.notify(actionCreate.actionable_type + " created successfully");
          this.resetStateValues();
          this.props.newActionCreated(actionCreate);
        })
        .catch((e) => console.log(e));
    } else {
      actions["current_status"] = this.state.current_status;
      actions["action_notes"] = this.state.action_notes;
      apicall
        .updateActions(this.state.propertyID, this.state.action.id, actions)
        .then((actionUpdate) => {
          this.notify(actionUpdate.description + " updated successfully");
          this.resetStateValues();
        })
        .catch((e) => console.log(e));
    }
    setTimeout(() => {
      this.props.getActions(this.state.propertyID);
      this.props.toggle();
    }, 3000);
    //this.props.toggle();
    event.preventDefault();
  };

  closingTab = ()=> {
    setTimeout(() => {
      this.props.getActions(this.state.propertyID);
      this.props.toggle();
    }, 500);
  }

   actionChanges = () => {
     this.props.getActions(this.state.propertyID);
     this.props.toggle();
  }

  resetStateValues = () => {
    this.setState({
      actionable_type: "",
      description: "",
      due_date: "",
    });
  };

  // toggleSubmit = () => {
  //   // if (
  //   //   config.paymentHoaAction.includes(this.state.payment_type) &&
  //   //   _.isEmpty(this.state.hoa_id)
  //   // )
  //   console.log("Came to toggle modal", this.state.modal);
  //   if (
  //     config.paymentHoaAction.includes(this.state.actionable_type) &&
  //     _.isEmpty(this.state.hoa_id)
  //   )
  //     return true;
  //   else return false;
  // };

  formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={{ fontWeight: "bold", color: "black" }}>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  renderModal = () => {
    let typeActions = [];
    let i = 0;
    let statuses = [];
    if (
      this.props.action &&
      this.props.action.actionable_type === "AssociationVerification"
    ) {
      const arr = [
        "Pending – RESICAP",
        "Pending – Vendor",
        "HOA Exists",
        "NO HOA Exists",
        "Verifying HOA Exists",
        "Verifying NO HOA Exists",
      ];
      statuses = arr.map((x) => (
        <option value={x} disabled={x === "HOA Exists" ? true : false}>
          {x}
        </option>
      ));
    } else {
      statuses = ["Closed", "Pending"].map((x) => (
        <option value={x}  disabled={x === "Closed" && this.props.action.actionable_type === "MunicipalityRegistration" ? true : false}>{x}</option>
      ));
    }

    typeActions.push(<option value="">Select Type</option>);
    for (let type in this.state.actionTypes) {
      typeActions.push(
        <option value={type}>{this.state.actionTypes[type]}</option>
      );
    }

    let requirementTypeOptions = [];
    requirementTypeOptions.push(
      <option value="" key="defaultValue">
      Select...{" "}
    </option>
    );

    for (let requirementType of config.requirementTypes) {
      requirementTypeOptions.push(
        <option value={requirementType} key={requirementType}>
          {requirementType}
        </option>
      );
    }

    let paymentTypes = [];
    i = 0;
    paymentTypes.push(
      <option value="" key="defaultValue">
        Select...{" "}
      </option>
    );
    for (let paymentType in this.state.paymentTypes) {
      paymentTypes.push(
        <option value={paymentType} key={paymentType}>
          {this.state.paymentTypes[paymentType]}
        </option>
      );
    }
    let paymentHoas = [];
    paymentHoas.push(
      <option value="" key="defaulthoaValue">
        Select Payment Entity
      </option>
    );
    for (let hoa of this.state.paymentHoas) {
      paymentHoas.push(
        <option value={hoa.id} key={hoa.id}>
          {hoa.name}
        </option>
      );
    }

    for (let muni of this.state.paymentMunicipality) {
      paymentHoas.push(
        <option value={muni.id} key={muni.id}>
          {muni.name}
        </option>
      );
    }

    let hoaOptions = helpers.formatOptions(this.state.paymentHoas, "Hoa");
    let municipalOptions = helpers.formatOptions(
      this.state.paymentMunicipality,
      "Municipality"
    );

    let groupedOptions = [
      {
        label: "Hoas",
        options: hoaOptions,
      },
      {
        label: "Office",
        options: municipalOptions,
      },
    ];

    return (
      <div>
        <ModalHeader>
          <div className="">
            <span className="heading">Create Action</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
            {!this.props.updateModal && (
              <>
                <FormGroup>
                  <Label for="">Action Type</Label>
                  <Input
                    type="select"
                    name="actionable_type"
                    id="actionable_type"
                    value={this.state.actionable_type}
                    onChange={this.handleChange}
                  >
                    {typeActions}
                  </Input>
                </FormGroup>
                 {
                  this.state.actionable_type === "MunicipalityRegistration" && (
                    <FormGroup>
                  <Label for="">Requirement Type</Label>
                  <Input
                    type="select"
                    name="requirement_type"
                    id="requirement_type"
                    value={this.state.requirement_type}
                    onChange={this.handleChange}
                  >
                    {requirementTypeOptions}
                  </Input>
                </FormGroup>
                  )
                } 

                {(this.state.actionable_type === "FyAssessment" ||
                  this.state.actionable_type == "GeneralPayment" ||
                  this.state.actionable_type == "AccountReview") && (
                    <>
                      {/* <FormGroup>
                      <Label for="">Description</Label>
                      <Input
                        type="select"
                        name="payment_type"
                        id="payment_type"
                        onChange={this.handleChange}
                        required
                      >
                        {paymentTypes}
                      </Input>
                    </FormGroup> */}
                      {config.paymentHoaAction.includes(
                        this.state.actionable_type
                      ) && (
                          <FormGroup className="">
                            <Label>Entity*</Label>
                            {/* <Input
                          type="select"
                          name="hoa_id"
                          id="hoa_id"
                          value={this.state.hoa_id}
                          onChange={this.handleChange}
                        >
                          {paymentHoas}
                        </Input> */}
                            <Select
                              options={groupedOptions}
                              formatGroupLabel={this.formatGroupLabel}
                              onChange={(valueObj) => {
                                if (valueObj.group === "Hoa") {
                                  this.setState({
                                    hoa_id: valueObj.value,
                                  });
                                } else if (valueObj.group === "Municipality") {
                                  this.setState({
                                    office_id: valueObj.value,
                                  });
                                }
                              }}
                            />
                            {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                          </FormGroup>
                        )}
                    </>
                  )}

                 {
                   this.state.actionable_type === "MunicipalityRegistration" && (
                    <FormGroup className="">
                    <Label>Entity*</Label>
                    <Select
                              options={municipalOptions}
                              formatGroupLabel={this.formatGroupLabel}
                              onChange={(valueObj) => {
                                if (valueObj.group === "Municipality") {
                                  this.setState({
                                    municipality_id: valueObj.muni_id,
                                    office_id: valueObj.value,
                                  });
                                }
                              }}
                            />
                    </FormGroup>
                   )
                 } 
                {this.state.actionable_type !== "FyAssessment" ||
                  this.state.actionable_type !== "GeneralPayment" ||
                  (this.state.actionable_type !== "AccountReview" && (
                    <>
                      <FormGroup>
                        <Label for="">Description</Label>
                        <TextValidator
                          type="text"
                          name="description"
                          id="description"
                          placeholder="Description"
                          onChange={this.handleChange}
                          value={this.state.description}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label style={{ marginRight: "2%" }} for="due_date">
                          Due Date
                        </Label>
                        <DatePicker
                          id="due_date"
                          selected={this.state.due_date}
                          onChange={this.handleDateChange}
                          value={this.state.due_date}
                        />
                      </FormGroup>
                    </>
                  ))}
              </>
            )}
            {this.props.updateModal && (
              <>
                <FormGroup>
                  <Label for="">Status</Label>
                  <Input
                    type="select"
                    name="current_status"
                    id="current_status"
                    value={this.state.current_status}
                    onChange={this.handleChange}
                  >
                    {statuses}
                  </Input>
                  {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                </FormGroup>
                {/* {this.props.action && this.props.action.actionable_type && (this.props.action.actionable_type !== "MunicipalityVerification" && this.props.action.actionable_type !== "DeedVerification" ) &&
                <FormGroup>
                  <Label for="">Notes</Label>
                  <Input
                    style={{ height: "150px" }}
                    type="textarea"
                    name="notes"
                    id="action_notes"
                    placeholder="Notes"
                    onChange={this.handleChange}
                    value={this.state.action_notes}
                  />
                </FormGroup>
                 } */}
              </>
            )}
            {/* <Button disabled={this.toggleSubmit}>Submit</Button> */}
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="actionModal"
          isOpen={this.state.modal}
          toggle={this.props.toggle}
        >
          {this.renderModal()}
        </Modal>
        {this.state.openCodeViolationModal && (
          <CodeViolationModal
            toggle={() => {
              this.setState({
                openCodeViolationModal: !this.state.openCodeViolationModal,
              });
              this.closingTab()
            }}
            className=""
            title="CodeViolation"
            open={this.state.openCodeViolationModal}
            propertyID={this.props.propertyID}
            closingTab={this.closingTab}
            getActionChanges= {this.actionChanges}
          />
        )}
         {this.state.openLienActionModal && (
               <LienActionModal
               toggle={() => {
                this.setState({
                  openLienActionModal: !this.state.openLienActionModal,
                });
                this.closingTab()
              }}
               className=""
               title="Lien Action "
               open={this.state.openLienActionModal}
               propertyID={this.props.propertyID}
               closingTab={this.closingTab}
               getActionChanges= {this.actionChanges}
             />
          )}
      </div>
    );
  }
}

export default ActionModal;
