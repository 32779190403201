import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var codeViolationapi = {
  readCodeViolation: async function (codeViolationID, propertyID) {
    let url = `${config.baseUrl}/api/v1/code_violations/${codeViolationID}?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listCodeViolations: async function (data, propertyID, pageNo, perPage) {
    let url = `${config.baseUrl}/api/v1/code_violations?`;
    let params = {};
    if (propertyID) params["property_id"] = propertyID;
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // if (pageNo) {
    //   url = url + "&page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    // let headers;
    // let response;
    // headers = await helpers.defaultHeaders();
    // await Axios.get(url, { headers: headers }).then((resp) => {
    //   response = resp;
    // });
    // return response.data;
  },
  listCodeViolationMunicipalityName: async function (searchKeyword, id) {
    let url =
      config.baseUrl +
      `/api/v1/municipalities/search?q=${searchKeyword}&property_id=${id}&select_box=${true}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  listHOAAssociationName: async function (searchKeyword, id) {
    let url =
      config.baseUrl +
      `/api/v1/code_violations/hoa_search?q=${searchKeyword}&property_id=${id}&select_box=${true}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  createCodeViolation: async (propertyID, data) => {
    let url = `${config.baseUrl}/api/v1/code_violations?property_id=${propertyID}`;
    let res = "";

    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if(key === "code_violation_notes") {
          if(data[key] === "" || !data[key]) {
            data[key] = null
            continue
          }
        }
        if (data[key] === null) {
          data[key] = "";
        }
        form.append("code_violation[" + key + "]", data[key]);
      }
    }

    let init = {
      method: "POST",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while lien Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  updateCodeViolation: async (codeViolationID, propertyID, data) => {
    if (data.created_at) delete data.created_at;
    if (data.updated_at) delete data.updated_at;
    if (data.status) delete data.status;
    if (data.url_token) delete data.url_token;
    if (data.created_user_id) delete data.created_user_id;
    if (data.municipality_verification_id)
      delete data.municipality_verification_id;
    if (data.muni_verification_type) delete data.mmuni_verification_type;

    let url = `${config.baseUrl}/api/v1/code_violations/${codeViolationID}?property_id=${propertyID}`;
    let res = "";
    if (data.originator === "Muni") data["hoa_id"] = null;
    else if (data.originator === "Association") data["municipality_id"] = null;
    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if(key === "code_violation_notes") {
          if(data[key] === "" || !data[key]) {
            data[key] = null
            continue
          }
        }
        if (data[key] === null) {
          data[key] = "";
        }

        form.append("code_violation[" + key + "]", data[key]);
      }
    }

    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while lien Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  deleteCodeViolationDocument: async function (
    propertyId,
    actionID,
    documentId
  ) {
    let url =
      config.baseUrl +
      `/api/v1/code_violations/${actionID}/delete_code_violation_documents?property_id=${propertyId}&document_id=${documentId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  getResponsibleBU: async function (propertyID) {
    let url = `${config.baseUrl}/api/v1/code_violations/responsible_bu?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  getResponsibleBUPOC: async function (propertyID, bu_id) {
    let url = `${config.baseUrl}//api/v1/code_violations/responsible_bu_poc?property_id=${propertyID}&bu_id=${bu_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listLawFirm: async function (searchKeyword) {
    let url =
      config.baseUrl +
      `/api/v1/code_violations/law_firm_search?q=${searchKeyword}&select_box=${true}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  emailClickValidation: async (propertyID, codeViolationID, url_token) => {
    let url = `${config.baseUrl}/api/v1/code_violations/click_to_confirm?property_id=${propertyID}&cv_id=${codeViolationID}&token=${url_token}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteHearing: async function (actionID, propertyId, hearingId) {
    let url =
      config.baseUrl +
      `/api/v1/code_violations/${actionID}/delete_title_hearing?property_id=${propertyId}&title_hearing_id=${hearingId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listDocumentType: async function () {
    let url = config.baseUrl + `/api/v1/code_violations/document_type`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  completionNotificationValidation: async (
    propertyID,
    codeViolationID,
    url_token
  ) => {
    let url = `${config.baseUrl}/api/v1/code_violations/click_to_completion_notification?property_id=${propertyID}&cv_id=${codeViolationID}&token=${url_token}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  searchCaseNumber: async function (address, data, pageNo, perPage) {
    let url = `${config.baseUrl}/api/v1/code_violations/search_case_number?`;
    let params = {};
    if (typeof address !== "undefined" && address !== null) {
      params["q"] = address;
    }
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;

    // if (pageNo) {
    //   url = `${url}&page=${pageNo}`;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    // let headers;
    // let response;
    // headers = await helpers.defaultHeaders();
    // await Axios.get(url, { headers: headers }).then((resp) => {
    //   response = resp;
    // });
    // return response.data;
  },
  readConfirmationCodeViolation: async function (codeViolationID, propertyID,url_token) {
    let url = `${config.baseUrl}/api/v1/code_violations/${codeViolationID}?property_id=${propertyID}&token=${url_token}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listConfirmationDocumentType: async function (url_token) {
    let url = config.baseUrl + `/api/v1/code_violations/document_type?token=${url_token}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateConfirmationCodeViolation: async (codeViolationID, propertyID, data,url_token) => {
    if (data.created_at) delete data.created_at;
    if (data.updated_at) delete data.updated_at;
    if (data.status) delete data.status;
    if (data.url_token) delete data.url_token;
    if (data.created_user_id) delete data.created_user_id;
    if (data.municipality_verification_id)
      delete data.municipality_verification_id;
    if (data.muni_verification_type) delete data.mmuni_verification_type;

    let url = `${config.baseUrl}/api/v1/code_violations/${codeViolationID}?property_id=${propertyID}&token=${url_token}`;
    let res = "";
    if (data.originator === "Muni") data["hoa_id"] = null;
    else if (data.originator === "Association") data["municipality_id"] = null;
    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if(key === "code_violation_notes") {
          if(data[key] === "" || !data[key]) {
            data[key] = null
            continue
          }
        }
        if (data[key] === null) {
          data[key] = "";
        }

        form.append("code_violation[" + key + "]", data[key]);
      }
    }

    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while lien Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  
};
export default withRouter(codeViolationapi);
