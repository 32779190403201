import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_violationFileUpload.scss";
import TextValidator from "../helper_components/TextValidator";
import helpers from "../../utils/helpers";
import _ from "lodash";
import config from "../../utils/config";

export default function TitleReviewDocument(props) {
  const [row, setrow] = useState(props.row ? props.row : {});

  const handleSubmit = (event) => {
    if (props.isLienModal) {
      if (props.actionID) {
        apicall
          .updateTitleLien(row, props.actionID, props.propertyID)
          .then((response) => {
            if (response) {
              helpers.notify("Document uploaded successfully");
              setrow(response);
              props.toggle();
              props.fetchDocuments(response.id);
            }
          });
      } else {
        apicall.createTitleLien(row, props.propertyID).then((response) => {
          if (response) {
            helpers.notify("Document uploaded successfully");
            setrow(response);
            props.toggle();
            props.fetchDocuments(response.id);
          }
        });
      }
    }
    if (props.isTitleReviewAction) {
      if (_.includes(row, row.selectedValue)) delete row.selectedValue;
      console.log("The action is ", props.actionID);
      apicall.updateTitleReview(row, props.actionID).then((response) => {
        if (response) {
          helpers.notify("Updated successfully");
          let data = response.title_review_orders.filter(
            (x) => x.id === props.rowId
          );

          if (data[0].lien_created)
            helpers.notify("Lien Action created successfully");
          setrow(response);
          props.toggle();
          props.fetchDocuments();
        }
      });
    }
    event.preventDefault();
  };

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const categoryOptions = () => {
    let categories = ["Judgement", "Municipal"];
    return categories.map((c) => {
      return { label: c, value: c };
    });
  };

  const classificationOptions = () => {
    let classification = ["Recorded Lien", "Unrecorded Lien"];
    return classification.map((c) => {
      return { label: c, value: c };
    });
  };

  var notification_sequenceValues = config.violation_document_name;
  var notificationSequenceOptions = notification_sequenceValues.map((c) => {
    return { label: c, value: c };
  });

  const formElements = () => {
    return (
      <div>
        <FormGroup>
          <Label for="">Category</Label>
          <Select
            name="title_documents_attributes[][category]"
            id="title_documents_attributes[][category]"
            value={{
              value: row["title_documents_attributes[][category]"],
              label: row["title_documents_attributes[][category]"],
            }}
            onChange={(selectedType) => {
              let selectedValue = row;
              selectedValue["title_documents_attributes[][category]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={categoryOptions()}
            validators={["required", "isValidSelect"]}
            errorMessages={[
              "Document Category is required",
              "Name is required",
            ]}
          />
        </FormGroup>

        <FormGroup>
          <Label for="">Classification</Label>
          <Select
            name="title_documents_attributes[][classification]"
            id="title_documents_attributes[][classification]"
            value={{
              value: row["title_documents_attributes[][classification]"],
              label: row["title_documents_attributes[][classification]"],
            }}
            onChange={(selectedType) => {
              let selectedValue = row;
              selectedValue["title_documents_attributes[][classification]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={classificationOptions()}
            validators={["required"]}
            errorMessages={["Document Classification is required"]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Notification Sequence</Label>
          <Select
            id={"title_documents_attributes[][notification_sequence]"}
            name="title_documents_attributes[][notification_sequence]"
            value={{
              value: row["title_documents_attributes[][notification_sequence]"],
              label: row["title_documents_attributes[][notification_sequence]"],
            }}
            onChange={(selectedType) => {
              let selectedValue = row;
              selectedValue[
                "title_documents_attributes[][notification_sequence]"
              ] = selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={notificationSequenceOptions}
          />
        </FormGroup>
        <FormGroup>
          <Label for=""> Lien Holder</Label>

          <TextValidator
            type="text"
            id={"title_documents_attributes[][lien_holder]"}
            name="title_documents_attributes[][lien_holder]"
            onChange={handleChange}
            value={row["title_documents_attributes[][lien_holder]"]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Document File Upload</Label>
          <Input
            type="file"
            name="file"
            id={"title_documents_attributes[][file]"}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Button className={""} onSubmit={handleSubmit}>
          Submit
        </Button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            {/* <span className="heading">
              {props.isLienModal ? "Lien Upload" : "Add Document"}
            </span> */}
            <span className="heading">{"Lien Upload"}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="violationFileUpload-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
