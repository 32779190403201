import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  CustomInput,
} from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_municipalModal.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import _ from "lodash";

export default function MunicipalRequirementLoginModal(props) {
  const [formdata, setformdata] = useState(
    props.formdata ? props.formdata : []
  );
  const [isBusinessDisabled, setisBusinessDisabled] = useState(false);
  const [isLandlordDisabled, setisLandlordDisabled] = useState(false);
  const [isVacantDisabled, setisVacantDisabled] = useState(false);
  const [isOccupancyDisabled, setisOccupancyDisabled] = useState(false);
  const [isRentalDisabled, setisRentalDisabled] = useState(false);
  const [isCrimeHousingDisabled, setisCrimeHousingDisabled] = useState(false);
  const [isRentalTaxesDisabled, setisRentalTaxesDisabled] = useState(false);
  const [isPeriodicDisabled, setisPeriodicDisabled] = useState(false);

  const [
    isBusinessInspectionDisabled,
    setisBusinessInspectionDisabled,
  ] = useState(false);
  const [isVacantInspectionDisabled, setisVacantInspectionDisabled] = useState(
    false
  );
  const [
    isOccupancyInspectionDisabled,
    setisOccupancyInspectionDisabled,
  ] = useState(false);
  const [isRentalInspectionDisabled, setisRentalInspectionDisabled] = useState(
    false
  );

  const [businessRequirement, setbusinessRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Business License Registration",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [landlordRequirement, setlandlordRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Landlord Registration",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [vacantRequirement, setvacantRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Vacant Property Registration",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [occupancyRequirement, setoccupancyRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Certificate of Occupancy",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [rentalRequirement, setrentalRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Rental Registration",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [crimeHousingRequirement, setcrimeHousingRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Crime Free Housing",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [rentalTaxesRequirement, setrentalTaxesRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Rental Taxes",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  const [periodicRequirement, setperiodicRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Periodic Registrations",
    username: "",
    password: "",
    muni_req_website: "",
    inspection_required: "",
  });

  useEffect(() => {
    setmunicipality_requirements();
  }, []);

  const setmunicipality_requirements = () => {
    if (!_.isEmpty(formdata)) {
      let businessResult;
      businessResult = formdata.filter((e) => {
        if (e.requirement_type === "Business License Registration") return e;
      });
      setbusinessRequirement(businessResult[0]);
      // if (_.isEmpty(businessResult[0].required)) {
      //   setisBusinessDisabled(true);
      //   setisBusinessInspectionDisabled(true);
      // } else 
      // if (
      //   !_.isEmpty(businessResult[0].registration_method) &&
      //   businessResult[0].registration_method === "Offline"
      // )
      //   setisBusinessDisabled(true);
      // else if (businessResult[0].required === "N/A") {
      //   setisBusinessInspectionDisabled(true);
      //   setisBusinessDisabled(true);
      // }

      let landlordResult;
      landlordResult = formdata.filter((e) => {
        if (e.requirement_type === "Landlord Registration") return e;
      });
      setlandlordRequirement(landlordResult[0]);
      // if (
      //   _.isEmpty(landlordResult[0].required) ||
      //   landlordResult[0].required === "N/A" ||
      //   landlordResult[0].registration_method === "Offline"
      // )
      //   setisLandlordDisabled(true);

      let vacantResult;
      vacantResult = formdata.filter((e) => {
        if (e.requirement_type === "Vacant Property Registration") return e;
      });
      setvacantRequirement(vacantResult[0]);

      // if (_.isEmpty(vacantResult[0].required)) {
      //   setisVacantDisabled(true);
      //   setisVacantInspectionDisabled(true);
      // } else
      //  if (
      //   !_.isEmpty(vacantResult[0].registration_method) &&
      //   vacantResult[0].registration_method === "Offline"
      // )
      //   setisVacantDisabled(true);
      // else if (vacantResult[0].required === "N/A") {
      //   setisVacantDisabled(true);
      //   setisVacantInspectionDisabled(true);
      // }

      let occupancyResult;
      occupancyResult = formdata.filter((e) => {
        if (e.requirement_type === "Certificate of Occupancy") return e;
      });
      setoccupancyRequirement(occupancyResult[0]);

      // if (_.isEmpty(occupancyResult[0].required)) {
      //   setisOccupancyDisabled(true);
      //   setisOccupancyInspectionDisabled(true);
      // } else 
      // if (
      //   !_.isEmpty(occupancyResult[0].registration_method) &&
      //   occupancyResult[0].registration_method === "Offline"
      // )
      //   setisOccupancyDisabled(true);
      // else if (occupancyResult[0].required === "N/A") {
      //   setisOccupancyDisabled(true);
      //   setisOccupancyInspectionDisabled(true);
      // }

      let rentalResult;
      rentalResult = formdata.filter((e) => {
        if (e.requirement_type === "Rental Registration") return e;
      });
      setrentalRequirement(rentalResult[0]);

      // if (_.isEmpty(rentalResult[0].required)) {
      //   setisRentalDisabled(true);
      //   setisRentalInspectionDisabled(true);
      // } else 
      // if (
      //   !_.isEmpty(rentalResult[0].registration_method) &&
      //   rentalResult[0].registration_method === "Offline"
      // ) {
      //   setisRentalDisabled(true);
      // } 
      // else if (rentalResult[0].required === "N/A") {
      //   setisRentalDisabled(true);
      //   setisRentalInspectionDisabled(true);
      // }

      let crimeResult;
      crimeResult = formdata.filter((e) => {
        if (e.requirement_type === "Crime Free Housing") return e;
      });
      setcrimeHousingRequirement(crimeResult[0]);
      // if (
      //   _.isEmpty(crimeResult[0].required) ||
      //   crimeResult[0].required === "N/A" ||
      //   crimeResult[0].registration_method === "Offline"
      // )
      //   setisCrimeHousingDisabled(true);

      let rentalTaxResult;
      rentalTaxResult = formdata.filter((e) => {
        if (e.requirement_type === "Rental Taxes") return e;
      });
      setrentalTaxesRequirement(rentalTaxResult[0]);
      // if (
      //   _.isEmpty(rentalTaxResult[0].required) ||
      //   rentalTaxResult[0].required === "N/A" ||
      //   rentalTaxResult[0].registration_method === "Offline"
      // )
      //   setisRentalTaxesDisabled(true);

      let periodicResult;
      periodicResult = formdata.filter((e) => {
        if (e.requirement_type === "Periodic Registrations") return e;
      });
      setperiodicRequirement(periodicResult[0]);
    //   if (
    //     _.isEmpty(periodicResult[0].required) ||
    //     periodicResult[0].required === "N/A" ||
    //     periodicResult[0].registration_method === "Offline"
    //   )
    //     setisPeriodicDisabled(true);
    }
  };

  const handleSubmit = (event) => {
    if ("validateForm") {
      let dataobject = [
        {
          id: businessRequirement.id,
          requirement_type: "Business License Registration",
          required: businessRequirement.required,
          registration_method: businessRequirement.registration_method,
          payment_method: businessRequirement.payment_method,
          periodicity: businessRequirement.periodicity,
          registration_amount: !_.isEmpty(
            businessRequirement.registration_amount
          )
            ? _.includes(businessRequirement.registration_amount, "$")
              ? businessRequirement.registration_amount
              : "$" +
                parseFloat(businessRequirement.registration_amount).toFixed(2)
            : "",
          username: businessRequirement.username,
          password: businessRequirement.password,
          muni_req_website: businessRequirement.muni_req_website,
          inspection_required: businessRequirement.inspection_required,
        },
        {
          id: landlordRequirement.id,
          requirement_type: "Landlord Registration",
          required: landlordRequirement.required,
          registration_method: landlordRequirement.registration_method,
          payment_method: landlordRequirement.payment_method,
          periodicity: landlordRequirement.periodicity,
          registration_amount: !_.isEmpty(
            landlordRequirement.registration_amount
          )
            ? _.includes(landlordRequirement.registration_amount, "$")
              ? landlordRequirement.registration_amount
              : "$" +
                parseFloat(landlordRequirement.registration_amount).toFixed(2)
            : "",
          username: landlordRequirement.username,
          password: landlordRequirement.password,
          muni_req_website: landlordRequirement.muni_req_website,
          inspection_required: landlordRequirement.inspection_required,
        },
        {
          id: vacantRequirement.id,
          requirement_type: "Vacant Property Registration",
          required: vacantRequirement.required,
          registration_method: vacantRequirement.registration_method,
          payment_method: vacantRequirement.payment_method,
          periodicity: vacantRequirement.periodicity,
          registration_amount: !_.isEmpty(vacantRequirement.registration_amount)
            ? _.includes(vacantRequirement.registration_amount, "$")
              ? vacantRequirement.registration_amount
              : "$" +
                parseFloat(vacantRequirement.registration_amount).toFixed(2)
            : "",
          username: vacantRequirement.username,
          password: vacantRequirement.password,
          muni_req_website: vacantRequirement.muni_req_website,
          inspection_required: vacantRequirement.inspection_required,
        },
        {
          id: occupancyRequirement.id,
          requirement_type: "Certificate of Occupancy",
          required: occupancyRequirement.required,
          registration_method: occupancyRequirement.registration_method,
          payment_method: occupancyRequirement.payment_method,
          periodicity: occupancyRequirement.periodicity,
          registration_amount: !_.isEmpty(
            occupancyRequirement.registration_amount
          )
            ? _.includes(occupancyRequirement.registration_amount, "$")
              ? occupancyRequirement.registration_amount
              : "$" +
                parseFloat(occupancyRequirement.registration_amount).toFixed(2)
            : "",
          username: occupancyRequirement.username,
          password: occupancyRequirement.password,
          muni_req_website: occupancyRequirement.muni_req_website,
          inspection_required: occupancyRequirement.inspection_required,
        },
        {
          id: rentalRequirement.id,
          requirement_type: "Rental Registration",
          required: rentalRequirement.required,
          registration_method: rentalRequirement.registration_method,
          payment_method: rentalRequirement.payment_method,
          periodicity: rentalRequirement.periodicity,
          registration_amount: !_.isEmpty(rentalRequirement.registration_amount)
            ? _.includes(rentalRequirement.registration_amount, "$")
              ? rentalRequirement.registration_amount
              : "$" +
                parseFloat(rentalRequirement.registration_amount).toFixed(2)
            : "",
          username: rentalRequirement.username,
          password: rentalRequirement.password,
          muni_req_website: rentalRequirement.muni_req_website,
          inspection_required: rentalRequirement.inspection_required,
        },
        {
          id: crimeHousingRequirement.id,
          requirement_type: "Crime Free Housing",
          required: crimeHousingRequirement.required,
          registration_method: crimeHousingRequirement.registration_method,
          payment_method: crimeHousingRequirement.payment_method,
          periodicity: crimeHousingRequirement.periodicity,
          registration_amount: !_.isEmpty(
            crimeHousingRequirement.registration_amount
          )
            ? _.includes(crimeHousingRequirement.registration_amount, "$")
              ? crimeHousingRequirement.registration_amount
              : "$" +
                parseFloat(crimeHousingRequirement.registration_amount).toFixed(
                  2
                )
            : "",
          username: crimeHousingRequirement.username,
          password: crimeHousingRequirement.password,
          muni_req_website: crimeHousingRequirement.muni_req_website,
          inspection_required: crimeHousingRequirement.inspection_required,
        },
        {
          id: rentalTaxesRequirement.id,
          requirement_type: "Rental Taxes",
          required: rentalTaxesRequirement.required,
          registration_method: rentalTaxesRequirement.registration_method,
          payment_method: rentalTaxesRequirement.payment_method,
          periodicity: rentalTaxesRequirement.periodicity,
          registration_amount: !_.isEmpty(
            rentalTaxesRequirement.registration_amount
          )
            ? _.includes(rentalTaxesRequirement.registration_amount, "$")
              ? rentalTaxesRequirement.registration_amount
              : "$" +
                parseFloat(rentalTaxesRequirement.registration_amount).toFixed(
                  2
                )
            : "",
          username: rentalTaxesRequirement.username,
          password: rentalTaxesRequirement.password,
          muni_req_website: rentalTaxesRequirement.muni_req_website,
          inspection_required: rentalTaxesRequirement.inspection_required,
        },
        {
          id: periodicRequirement.id,
          requirement_type: "Periodic Registrations",
          required: periodicRequirement.required,
          registration_method: periodicRequirement.registration_method,
          payment_method: periodicRequirement.payment_method,
          periodicity: periodicRequirement.periodicity,
          registration_amount: !_.isEmpty(
            periodicRequirement.registration_amount
          )
            ? _.includes(periodicRequirement.registration_amount, "$")
              ? periodicRequirement.registration_amount
              : "$" +
                parseFloat(periodicRequirement.registration_amount).toFixed(2)
            : "",
          username: periodicRequirement.username,
          password: periodicRequirement.password,
          muni_req_website: periodicRequirement.muni_req_website,
          inspection_required: periodicRequirement.inspection_required,
        },
      ];

      const data = { municipality: props.municipality };
      data.municipality.municipality_requirements_attributes = dataobject;
      apicall
        .updatemunicipality(data, props.municipality.id)
        .then((response) => {
          helpers.notify("Municipality updated Successfully");
          setTimeout(() => {
            props.readMunicipal(response.id);
            props.toggle();
          }, 600);
        });
    }
  };

  const handleChange = (event, key) => {
    let targetName = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (_.includes(targetName, "business_license_registration")) {
      setbusinessRequirement({
        ...businessRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "landlord_registration")) {
      setlandlordRequirement({
        ...landlordRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "vacant_property_registration")) {
      setvacantRequirement({
        ...vacantRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "certificateOfOccupancy_registration")) {
      setoccupancyRequirement({
        ...occupancyRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "rentalRegistration_registration")) {
      setrentalRequirement({
        ...rentalRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "crimeFreeHousing_registration")) {
      setcrimeHousingRequirement({
        ...crimeHousingRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "rentalTaxes_registration")) {
      setrentalTaxesRequirement({
        ...rentalTaxesRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "periodicRegistrations_registration")) {
      setperiodicRequirement({
        ...periodicRequirement,
        [key]: value,
      });
    }
  };

  const handleRadioButton = (event, value, key) => {
    let targetName = event.target.name;
    if (_.includes(targetName, "business_license_registration")) {
      setbusinessRequirement({
        ...businessRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "landlord_registration")) {
      setlandlordRequirement({
        ...landlordRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "vacant_property_registration")) {
      setvacantRequirement({
        ...vacantRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "certificateOfOccupancy_registration")) {
      setoccupancyRequirement({
        ...occupancyRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "rentalRegistration_registration")) {
      setrentalRequirement({
        ...rentalRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "crimeFreeHousing_registration")) {
      setcrimeHousingRequirement({
        ...crimeHousingRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "rentalTaxes_registration")) {
      setrentalTaxesRequirement({
        ...rentalTaxesRequirement,
        [key]: value,
      });
    } else if (_.includes(targetName, "periodicRegistrations_registration")) {
      setperiodicRequirement({
        ...periodicRequirement,
        [key]: value,
      });
    }
  };

  const renderCheckBox = (name, value, key, isdisabled) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "20%" }}></div>
        <div className="radio-button">
          <CustomInput
            id={`${name}yes`}
            type="radio"
            name={name}
            value={true}
            onClick={(e) => handleRadioButton(e, true, key)}
            checked={value === true ? "checked" : ""}
          />
          <label>Yes</label>
          <CustomInput
            id={`${name}no`}
            type="radio"
            name={name}
            value={false}
            onClick={(e) => handleRadioButton(e, false, key)}
            checked={value === false ? "checked" : ""}
          />
          <label>No</label>
        </div>
      </div>
      /* <div style={{ textAlign: "center" }}>
 <input
          type="checkbox"
          name={name}
          id={name}
          value={value}
          onChange={(event) => handleChange(event, key)}
          checked={value === true ? "Checked" : ""}
          style={{
            width: "20px",
            //marginLeft: "15px",
          }}
          disabled={isdisabled}
        /> 
       </div>*/
    );
  };

  const renderFormInputElements = (name, value, key, isdisabled) => {
    return (
      <div className="ip-fields">
        <Input
          type={"text"}
          name={name}
          id={name}
          onChange={(e) => handleChange(e, key)}
          value={value}
          disabled={isdisabled}
        />
      </div>
    );
  };

  const formElements = () => {
    return (
      <>
        <table className="reviewActionTable" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "6%" }}></th>
              <th style={{ width: "5%" }}>Inspection Required</th>
              <th style={{}}>Website</th>
              <th style={{}}>User Name</th>
              <th style={{}}>Password</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style={{ fontSize: "1rem" }}>
                Business License Registration
              </td>{" "}
              <td>
                {renderCheckBox(
                  "business_license_registration_inspection_required",
                  businessRequirement.inspection_required,
                  "inspection_required",
                  isBusinessInspectionDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "business_license_registration_website",
                  businessRequirement.muni_req_website,
                  "muni_req_website",
                  isBusinessDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "business_license_registration_username",
                  businessRequirement.username,
                  "username",
                  isBusinessDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "business_license_registration_password",
                  businessRequirement.password,
                  "password",
                  isBusinessDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Landlord Registration</td>{" "}
              <td></td>
              <td>
                {renderFormInputElements(
                  "landlord_registration_website",
                  landlordRequirement.muni_req_website,
                  "muni_req_website",
                  isLandlordDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "landlord_registration_username",
                  landlordRequirement.username,
                  "username",
                  isLandlordDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "landlord_registration_password",
                  landlordRequirement.password,
                  "password",
                  isLandlordDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Vacant Property Registration</td>{" "}
              <td>
                {renderCheckBox(
                  "vacant_property_registration_inspection_required",
                  vacantRequirement.inspection_required,
                  "inspection_required",
                  isVacantInspectionDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "vacant_property_registration_website",
                  vacantRequirement.muni_req_website,
                  "muni_req_website",
                  isVacantDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "vacant_property_registration_username",
                  vacantRequirement.username,
                  "username",
                  isVacantDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "vacant_property_registration_password",
                  vacantRequirement.password,
                  "password",
                  isVacantDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Certificate of Occupancy</td>{" "}
              <td>
                {renderCheckBox(
                  "certificateOfOccupancy_registration_inspection_required",
                  occupancyRequirement.inspection_required,
                  "inspection_required",
                  isOccupancyInspectionDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "certificateOfOccupancy_registration_website",
                  occupancyRequirement.muni_req_website,
                  "muni_req_website",
                  isOccupancyDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "certificateOfOccupancy_registration_username",
                  occupancyRequirement.username,
                  "username",
                  isOccupancyDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "certificateOfOccupancy_registration_password",
                  occupancyRequirement.password,
                  "password",
                  isOccupancyDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Rental Registration</td>{" "}
              <td>
                {renderCheckBox(
                  "rentalRegistration_registration_inspection_required",
                  rentalRequirement.inspection_required,
                  "inspection_required",
                  isRentalInspectionDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "rentalRegistration_registration_website",
                  rentalRequirement.muni_req_website,
                  "muni_req_website",
                  isRentalDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "rentalRegistration_registration_username",
                  rentalRequirement.username,
                  "username",
                  isRentalDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "rentalRegistration_registration_password",
                  rentalRequirement.password,
                  "password",
                  isRentalDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Crime Free Housing</td> <td></td>
              <td>
                {renderFormInputElements(
                  "crimeFreeHousing_registration_website",
                  crimeHousingRequirement.muni_req_website,
                  "muni_req_website",
                  isCrimeHousingDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "crimeFreeHousing_registration_username",
                  crimeHousingRequirement.username,
                  "username",
                  isCrimeHousingDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "crimeFreeHousing_registration_password",
                  crimeHousingRequirement.password,
                  "password",
                  isCrimeHousingDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Rental Taxes</td> <td></td>
              <td>
                {renderFormInputElements(
                  "rentalTaxes_registration_website",
                  rentalTaxesRequirement.muni_req_website,
                  "muni_req_website",
                  isRentalTaxesDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "rentalTaxes_registration_username",
                  rentalTaxesRequirement.username,
                  "username",
                  isRentalTaxesDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "rentalTaxes_registration_password",
                  rentalTaxesRequirement.password,
                  "password",
                  isRentalTaxesDisabled
                )}
              </td>
            </tr>

            <tr>
              {/* <td style={{ fontSize: "1rem" }}>Periodic Registrations</td>{" "} */}
              <td style={{ fontSize: "1rem" }}>Property Deregistration</td>
              <td></td>
              <td>
                {renderFormInputElements(
                  "periodicRegistrations_registration_website",
                  periodicRequirement.muni_req_website,
                  "muni_req_website",
                  isPeriodicDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "periodicRegistrations_registration_username",
                  periodicRequirement.username,
                  "username",
                  isPeriodicDisabled
                )}
              </td>
              <td>
                {renderFormInputElements(
                  "periodicRegistrations_registration_password",
                  periodicRequirement.password,
                  "password",
                  isPeriodicDisabled
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="municipal-submit">
          <Button
            className="municipal-submit-button"
            type="button"
            id="submit"
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Submit
          </Button>
        </div>
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Municipality Requirement</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="municipal-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
