import React, { useState, useEffect } from "react";
import apicall from "../../utils/apicall";
import "../../styles/_municipalComponent.scss";
import Loader from "../helper_components/LoaderComponent";
import ListMunicipalTable from "./ListMunicipalTable";
import config from "../../utils/config";

export default function MunicipalComponent(props) {
  const [isLoading, setisLoading] = useState(true);
  const [municipalities, setMunicipalities] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  const [userRole, setuserRole] = useState("");

  useEffect(() => {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        setuserRole(role);
      })
      .catch((e) => console.log(e));
    getAllMunicipalities();
  }, [form_inputs.sort]);

  const getAllMunicipalities = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall
      .getAllMunicipalities(form_inputs, pageNo, perPage)
      .then((response) => {
        setisLoading(false);
        setMunicipalities(response.results);
        settotalCount(response.total);
        setpageNo(pageNo);
        setperPage(perPage);
      })
      .catch("Error While fetching  municipalities");
  };

  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <>
        <ListMunicipalTable
          municipalities={municipalities}
          refreshList={getAllMunicipalities}
          pageNo={pageNo}
          perPage={perPage}
          totalCount={totalCount}
          sortColumns={sortColumns}
          userRole={userRole}
        />
      </>
    </React.Fragment>
  );
}
