import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import "../../../styles/propertyupload.css";
import "../../../styles/content.css";
import LoaderComponent from "../../helper_components/LoaderComponent";
import ImportPropertyModal from "../../helper_components/ImportModal";
import MuniOrderList from "./MuniOrderList";
import apicall from "../../../utils/apicall";
import "../../../styles/_titleorder.scss";

export default function MunicipalityOrder() {
  const [pageNo, setpageNo] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [orderList, setorderList] = useState([]);
  const [importModal, setimportModal] = useState(false);
  const [total, settotal] = useState();
  const [vendors, setvendors] = useState([]);
  const [perPage, setperPage] = useState(10);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  const toggleImport = () => {
    setimportModal(!importModal);
  };

  useEffect(() => {
    getMunicipalOrderList();
  }, [form_inputs.sort]);

  const getMunicipalOrderList = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall
      .listMunicipalOrder(form_inputs, pageNo, perPage)
      .then((response) => {
        setorderList(response.results);
        setisLoading(false);
        settotal(response.total);
        setpageNo(pageNo);
        setperPage(perPage);
      });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderComponent />}

      <section
        className="content muni-order"
        style={{ marginTop: "0px", marginLeft: "0%" }}
      >
        <header
          className="content-muni property-upload"
          style={{ justifyContent: "normal" }}
        >
          <Button
            onClick={toggleImport}
            className="createBtn"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            Create Municipality Order
          </Button>
        </header>

        <MuniOrderList
          orderList={orderList}
          getMunicipalOrderList={getMunicipalOrderList}
          pageNo={pageNo}
          perPage={perPage}
          total={total}
          toggle={toggleImport}
          vendors={vendors}
          sortColumns={sortColumns}
        />
      </section>

      {importModal && (
        <ImportPropertyModal
          uploadType="muni-order"
          toggleParentModal={toggleImport}
          className=""
          title="Muni Order"
          open={importModal}
          uploaded={false}
        />
      )}
    </React.Fragment>
  );
}
