import React, { useState, useEffect } from "react";
import "../../../styles/content.css";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import PropertySearchTable from "./PropertySearchTable";
import { toast } from "react-toastify";
import _ from "lodash";
import config from "../../../utils/config";
import Loader from "../../helper_components/LoaderComponent";

export default function PropertySearchComponent(props) {
  const [address, setaddress] = useState("");
  const [properties, setproperties] = useState([]);
  const [isSearch, setisSearch] = useState(false);
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [showSearchResult, setshowSearchResult] = useState();
  const [totalCount, settotalCount] = useState();
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  const [isLoading, setisLoading] = useState(true);

  const getProperties = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall.getProperties(form_inputs, pageNo, perPage).then((response) => {
      setproperties(response.results);
      settotalCount(response.total);
      setpageNo(pageNo);
      setperPage(perPage);
      setshowSearchResult(true);
      setisLoading(false);
    });
  };

  useEffect(() => {
    getProperties();
  }, [form_inputs.sort]);

  useEffect(() => {
    if (props.match) {
      let searchtext = props.match.params.searchText;
      console.log(props.match);
      console.log("searchtext", searchtext);
      config.localforage
        .getItem("searchResult")
        .then((searchresult) => {
          setproperties(searchresult.results);
          setshowSearchResult(true);
          settotalCount(searchresult.total);
        })
        .catch((e) => console.log(e));
      config.localforage
        .getItem("page-number")
        .then((pageNo) => {
          console.log("page", pageNo);

          setpageNo(pageNo);
        })
        .catch((e) => console.log(e));
      config.localforage
        .getItem("per-page")
        .then((perPage) => {
          console.log("perpage", perPage);
          // this.setState({ perPage: perPage });
          setperPage(perPage);
        })
        .catch((e) => console.log(e));

      setaddress(searchtext);
    }
  }, []);

  const notify = (message) => toast(message);

  const handleSubmit = (event, pageNo, perPage) => {
    config.localforage.setItem("search-text", address);
    config.localforage.setItem("page-number", pageNo);
    config.localforage.setItem("per-page", perPage);

    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperPage(perPage);
    }

    let inputValue = address;
    if (typeof inputValue === "undefined" || inputValue.length === 0) {
      getProperties(event, pageNo, perPage);
    } else {
      setisLoading(true);
      apicall
        .searchProperties(inputValue, form_inputs, pageNo, perPage)
        .then((response) => {
          if (response !== null && response.hasOwnProperty("error")) {
            let errorMsg = helpers.getErrorMsg(response.error);
            if (errorMsg)
              notify("Search Failed: No Records Found: " + errorMsg);
          } else {
            if (typeof response !== "undefined" && response.length === 0) {
              notify("No Records Found: ");
            } else {
              if (_.isString) {
                config.localforage.setItem("searchResult", response);
              }
              setproperties(response.results);
              setshowSearchResult(true);
              settotalCount(response.total);
              setisLoading(false);
            }
          }
        });
    }
    event.preventDefault();
  };

  const handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      <section className="content">
        {props.guestSearch ? (
          <></>
        ) : (
          <>
            {isLoading && <Loader />}
            <header className="content-title">
              <h2 className="page-heading">
                <strong>Property Search</strong>
              </h2>
            </header>
          </>
        )}
        <div className="search-result">
          {showSearchResult && (
            <PropertySearchTable
              properties={properties}
              handleSubmit={handleSubmit}
              pageNo={pageNo}
              page={perPage}
              totalCount={totalCount}
              propertySearch={true}
              sortColumns={sortColumns}
            />
          )}
        </div>
      </section>
    </React.Fragment>
  );
}
