import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var titleActionapi = {
  listTitleActions: async function (propertyID, pageNo, perPage, actionType) {
    let url = `${config.baseUrl}/api/v1/title_actions?property_id=${propertyID}&actions_type=${actionType}`;
    if (pageNo) {
      url = url + "&page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  viewTitleAction: async function (titleActionId, propertyID) {
    let url = `${config.baseUrl}/api/v1/title_actions/${titleActionId}?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateTitleAction: async function (data, titleActionId, propertyID) {
    // delete data.created_at;
    // delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/title_actions/${titleActionId}?property_id=${propertyID}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateVendorFields: async function (data, titleOrderId, propertyID) {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/title_review_orders/update_vendor_field?title_order_id=${titleOrderId}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  readTitleReview: async function (actionId) {
    //let url = `${config.baseUrl}/api/v1/title_review_orders/vendor_fields?property_id=${actionId}`;
    let url = `${config.baseUrl}/api/v1/title_reviews/${actionId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateTitleReview: async function (data, titleActionId, rowData) {
    delete data.created_at;
    delete data.updated_at;

    let url = `${config.baseUrl}/api/v1/title_reviews/${titleActionId}`;
    let res = "";

    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if (data[key] === null) {
          data[key] = "";
        }

        form.append(
          "title_review[title_review_orders_attributes[][" + key + "]]",
          data[key]
        );
      }
    }
    if (rowData) {
      if (rowData.title_review_notes) {
        form.append(
          "title_review[title_review_notes]", rowData.title_review_notes);
      }
    }
    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error("Something went wrong, while lien Update" + response);
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  createTitleAction: async function (data, propertyID) {
    let url = `${config.baseUrl}/api/v1/title_actions?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(titleActionapi);
