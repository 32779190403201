import React, { useState, useEffect } from "react";
import "../../styles/_newDashboard.scss";
import Loader from "../helper_components/LoaderComponent";
import _ from "lodash";

export default function TitlePendingStatsDashboard(props) {
  const [loading, set_loading] = useState(false);
  const [toggleModal, settoggleModal] = useState(false);
  const [titleThierOne, settitleThierOne] = useState("");
  const [titleThierTwo, settitleThierTwo] = useState("");
  const [titleThierThree, settitleThierThree] = useState("");
  const [dashboardValue, setdashboardValue] = useState();
  const [pendingDashboardFilter, setpendingDashboardFilter] = useState();
  const [generalPaymentFields, setgeneralPaymentFields] = useState();
  const [generalPaymentRightFields, setgeneralPaymentRightFields] = useState();
  const [deedDisabled, setdeedDisabled] = useState(true);
  const [titleDisabled, settitleDisabled] = useState(true);
  const [muniDisabled, setmuniDisabled] = useState(true);
  const [violationDisabled, setviolationDisabled] = useState(true);
  const [lienDisabled, setlienDisabled] = useState(true);

  useEffect(() => {
    getDashboardPendingDetails();
  }, [
    props.dashboardValue,
    props.pendingDashboardFilter,
    props.generalPaymentRightFields,
    props.generalPaymentRightFields,
  ]);

  const getDashboardPendingDetails = () => {
    if (!_.isEmpty(props.dashboardValue)) {
      setdashboardValue(props.dashboardValue);
    }
    if (!_.isEmpty(props.pendingDashboardFilter)) {
      setpendingDashboardFilter(props.pendingDashboardFilter);
    }
    if (!_.isEmpty(props.generalPaymentFields)) {
      setgeneralPaymentFields(props.generalPaymentFields);
    }
    if (!_.isEmpty(props.generalPaymentRightFields)) {
      setgeneralPaymentRightFields(props.generalPaymentRightFields);
    }
    set_loading(false);
    settoggleModal(true);
  };

  useEffect(() => {
    if (!_.isEmpty(props.userRole)) {
      if (props.userRole === "admin") {
        setdeedDisabled(false);
        setmuniDisabled(false);
        settitleDisabled(false);
        setviolationDisabled(false);
        setlienDisabled(false);
      } else if (!_.isEmpty(props.dashboard_scope)) {
        console.log("The scope", props.dashboard_scope);
        if (_.includes(props.dashboard_scope, "Deed")) setdeedDisabled(false);
        else {
          setdeedDisabled(true);
        }
        _.includes(props.dashboard_scope, "Municipal")
          ? setmuniDisabled(false)
          : setmuniDisabled(true);
        _.includes(props.dashboard_scope, "Title")
          ? settitleDisabled(false)
          : settitleDisabled(true);
        _.includes(props.dashboard_scope, "Violation")
          ? setviolationDisabled(false)
          : setviolationDisabled(true);
        _.includes(props.dashboard_scope, "Lien")
          ? setlienDisabled(false)
          : setlienDisabled(true);
      }
    }
  }, [props.userRole, props.dashboard_scope]);

  const generateDashboardTierOne = (name, filterValue, disabled, tierCount) => {
    let total_count;
    let left_count;
    if (_.isEmpty(tierCount)) {
      total_count = 0;
      left_count = 0;
    } else {
      total_count = tierCount.total ? tierCount.total : tierCount.total_count;
      left_count = tierCount.left_count;
    }
    return (
      <div
        style={{
          backgroundColor: titleThierOne === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          if (!disabled) {
            props.filterTierOne(filterValue);
            props.filterTierTwo("");
            props.filterTierThree("");
            settitleThierOne(name);
            settitleThierTwo("");
            settitleThierThree("");
          }
        }}
      >
        <div className="card-firstRow">{total_count}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div
            className="firstRowData"
            onClick={() => {
              props.filterTierOne(filterValue);
              props.filterTierTwo("");
              props.filterTierThree("");
              settitleThierOne(name);
              settitleThierTwo("");
              settitleThierThree("");
            }}
          >
            {left_count}
          </div>
          <div className="secondRowData">{"0"}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateDashboardTierTwo = (name, filterValue, tierCount) => {
    let total_count;
    let left_count;
    if (_.isEmpty(tierCount)) {
      total_count = 0;
      left_count = 0;
    } else {
      total_count = tierCount.total_count;
      left_count = tierCount.left_count;
    }
    return (
      <div
        style={{
          backgroundColor: titleThierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierTwo(filterValue);
          props.filterTierThree("");
          settitleThierTwo(name);
          settitleThierThree("");
        }}
      >
        <div className="card-firstRow">{total_count}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{left_count}</div>
          <div className="secondRowData">{"0"}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateDeedTierTwoDashboard = (name, filterTierValue, tierCount) => {
    return (
      <div
        style={{
          backgroundColor: titleThierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          props.filterTierTwo(filterTierValue);
          props.filterTierThree("");
          settitleThierTwo(name);
          settitleThierThree("");
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierCount}</div>
          <div className="secondRowData">{""}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generateDashboardTierThree = (name, filterValue, tierCount) => {
    let total_count;

    if (_.isUndefined(tierCount)) {
      total_count = 0;
    } else {
      total_count = tierCount;
    }
    return (
      <div
        style={{
          backgroundColor: titleThierThree === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          settitleThierThree(name);

          props.filterTierThree(filterValue);
        }}
      >
        <div className="card-firstRow">{}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{total_count}</div>
          <div className="secondRowData">{"0"}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  const generatePaymentTierTwoDashboard = (
    name,
    tierLeftCount,
    tierRightCount
  ) => {
    return (
      <div
        style={{
          backgroundColor: titleThierTwo === name ? "#866D4B" : "#500000",
        }}
        onClick={() => {
          settitleThierTwo(name);
          settitleThierThree("");
          props.filterTierTwo(name);
          props.filterTierThree("");
        }}
      >
        <div className="card-firstRow">{""}</div>
        <hr className="horizontalClass" />
        <div className="middleRow">
          <div className="firstRowData">{tierLeftCount}</div>
          <div className="secondRowData">{tierRightCount}</div>
        </div>
        <hr className="horizontalClass" />
        <div className="flex-heading">{name}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      {toggleModal && (
        <>
          <div className="flex-container" style={{ width: "95%" }}>
            { (props && props.manager_user && props.manager_user === "user") && !_.isEmpty(dashboardValue) && (
              <>
              {" "}
                { props.dashboard_scope.includes("Deed") && generateDashboardTierOne(
                  "Deed / Co",
                  "deed",
                  deedDisabled,
                  dashboardValue.deed
                )}
                {props.dashboard_scope.includes("Title") && generateDashboardTierOne(
                  "Title",
                  "title",
                  titleDisabled,
                  dashboardValue.title
                )}
                {props.dashboard_scope.includes("Municipal") && generateDashboardTierOne(
                  "Municipal",
                  "municipal",
                  muniDisabled,
                  dashboardValue.municipal
                )}
                {props.dashboard_scope.includes("Violation") && generateDashboardTierOne(
                  "Violation",
                  "violation",
                  violationDisabled,
                  dashboardValue.violation
                )}
                {props.dashboard_scope.includes("Lien") && generateDashboardTierOne(
                  "Lien",
                  "lien",
                  lienDisabled,
                  dashboardValue.lien
                )}
              </>
              )  
            }
            { (props && props.manager_user && props.manager_user !== "user") && !_.isEmpty(dashboardValue) && (
              <>
                {" "}
                {generateDashboardTierOne(
                  "Deed / Co",
                  "deed",
                  deedDisabled,
                  dashboardValue.deed
                )}
                {generateDashboardTierOne(
                  "Title",
                  "title",
                  titleDisabled,
                  dashboardValue.title
                )}
                {generateDashboardTierOne(
                  "Municipal",
                  "municipal",
                  muniDisabled,
                  dashboardValue.municipal
                )}
                {generateDashboardTierOne(
                  "Violation",
                  "violation",
                  violationDisabled,
                  dashboardValue.violation
                )}
                {generateDashboardTierOne(
                  "Lien",
                  "lien",
                  lienDisabled,
                  dashboardValue.lien
                )}
              </>
            )}
            {!_.isEmpty(pendingDashboardFilter) &&
              !_.isEmpty(pendingDashboardFilter.general_payment) && (
                <>
                  {generateDashboardTierOne(
                    "General Payment",
                    "general_payment",
                    "",
                    pendingDashboardFilter.general_payment
                  )}
                </>
              )}
          </div>
          {titleThierOne === "General Payment" &&
            !_.isEmpty(generalPaymentFields) &&
            !_.isEmpty(generalPaymentRightFields) && (
              <div className="flex-container" style={{ width: "95%" }}>
                {generatePaymentTierTwoDashboard(
                  "New Payment",
                  generalPaymentFields.new_payment,
                  generalPaymentRightFields.new_payment
                )}
                {generatePaymentTierTwoDashboard(
                  "Invoice Received",
                  generalPaymentFields.invoice_received,
                  generalPaymentRightFields.invoice_received
                )}
                {generatePaymentTierTwoDashboard(
                  "In-Review",
                  generalPaymentFields.in_review,
                  generalPaymentRightFields.in_review
                )}
                {generatePaymentTierTwoDashboard(
                  "Awaiting Approval",
                  generalPaymentFields.awaiting_approval,
                  generalPaymentRightFields.awaiting_approval
                )}
                {generatePaymentTierTwoDashboard(
                  "Approved",
                  generalPaymentFields.approved,
                  generalPaymentRightFields.approved
                )}
                {generatePaymentTierTwoDashboard(
                  "Rejected",
                  generalPaymentFields.rejected,
                  generalPaymentRightFields.rejected
                )}
                {generatePaymentTierTwoDashboard(
                  "Check Sent",
                  generalPaymentFields.check_sent,
                  generalPaymentRightFields.check_sent
                )}
              </div>
            )}
          {titleThierOne === "Title" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(dashboardValue.title) && (
                <>
                  {" "}
                  {generateDashboardTierTwo(
                    "Orders",
                    "title_orders",
                    dashboardValue.title.title_orders
                  )}
                  {generateDashboardTierTwo(
                    "Title Review",
                    "title_reviews",
                    dashboardValue.title.title_reviews
                  )}
                  {generateDashboardTierTwo(
                    "Clearance",
                    "title_clearances",
                    dashboardValue.title.title_clearances
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Title" && titleThierTwo === "Orders" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(dashboardValue.title.title_orders.left_fields) && (
                <>
                  {" "}
                  {generateDashboardTierThree(
                    "Required",
                    "required",
                    dashboardValue.title.title_orders.left_fields.required
                  )}
                  {generateDashboardTierThree(
                    "Outstanding",
                    "outstanding",
                    dashboardValue.title.title_orders.left_fields.outstanding
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Title" && titleThierTwo === "Title Review" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(dashboardValue.title.title_reviews.left_fields) && (
                <>
                  {generateDashboardTierThree(
                    "Chain of Title",
                    "chain_of_title",
                    dashboardValue.title.title_reviews.left_fields
                      .chain_of_title
                  )}
                  {generateDashboardTierThree(
                    "Vesting Name",
                    "vesting_name",
                    dashboardValue.title.title_reviews.left_fields.vesting_name
                  )}
                  {generateDashboardTierThree(
                    "Foreclosure Chain Correct",
                    "foreclosure_chain_correct",
                    dashboardValue.title.title_reviews.left_fields
                      .foreclosure_chain_correct
                  )}
                  {generateDashboardTierThree(
                    "Mortgages to Clear",
                    "mortgage_to_clear",
                    dashboardValue.title.title_reviews.left_fields
                      .mortgage_to_clear
                  )}
                  {generateDashboardTierThree(
                    "Delinquent Taxes",
                    "delinquent_taxes",
                    dashboardValue.title.title_reviews.left_fields
                      .delinquent_taxes
                  )}
                  {generateDashboardTierThree(
                    "Mobile Home",
                    "mobile_home",
                    dashboardValue.title.title_reviews.left_fields.mobile_home
                  )}
                  {generateDashboardTierThree(
                    "Governing Association",
                    "governing_associations",
                    dashboardValue.title.title_reviews.left_fields
                      .governing_associations
                  )}
                  {generateDashboardTierThree(
                    "Liens",
                    "liens",
                    dashboardValue.title.title_reviews.left_fields.liens
                  )}
                  {generateDashboardTierThree(
                    "Other Issue",
                    "other_issues",
                    dashboardValue.title.title_reviews.left_fields.other_issues
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Title" && titleThierTwo === "Clearance" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(
                dashboardValue.title.title_clearances.left_fields
              ) && (
                <>
                  {generateDashboardTierThree(
                    "Legal Description",
                    "LegalDescriptionIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .legal_description
                  )}
                  {generateDashboardTierThree(
                    "Vesting Chain",
                    "VestingChainIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .vesting_chain
                  )}
                  {generateDashboardTierThree(
                    "Mortgage Chain",
                    "MortgageChainIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .mortgage_chain
                  )}
                  {generateDashboardTierThree(
                    "Mortgage Issue",
                    "MortgageIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .mortgage_issue
                  )}
                  {generateDashboardTierThree(
                    "Judgement Foreclosure",
                    "JudgementForeclosureIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .judmental_foreclosure
                  )}
                  {generateDashboardTierThree(
                    "Mobile Home",
                    "MobileHomeIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .mobile_home
                  )}
                  {generateDashboardTierThree("Liens", "liens", 0)}
                  {generateDashboardTierThree(
                    "Other Issue",
                    "OtherIssue",
                    dashboardValue.title.title_clearances.left_fields
                      .other_issue
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Deed / Co" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(dashboardValue.deed.left_fields) && (
                <>
                  {dashboardValue.deed.left_fields.pending && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Deed",
                        "Pending Deed",
                        dashboardValue.deed.left_fields.pending
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_review && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Review",
                        "Pending Review",
                        dashboardValue.deed.left_fields.pending_review
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.in_review && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "In Review",
                        "In-Review",
                        dashboardValue.deed.left_fields.in_review
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_closing_attorney && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Closing Attorney",
                        "Pending Closing Attorney",
                        dashboardValue.deed.left_fields.pending_closing_attorney
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields
                    .pending_foreclosing_attorney && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Foreclosing Attorney",
                        "Pending Foreclosing Attorney",
                        dashboardValue.deed.left_fields
                          .pending_foreclosing_attorney
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_corrective_draft && (
                    <>
                      {" "}
                      {generateDeedTierTwoDashboard(
                        "Pending Corrective Draft",
                        "Pending Corrective Draft",
                        dashboardValue.deed.left_fields.pending_corrective_draft
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_draft_approval && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Draft Approval",
                        "Pending Draft Approval",
                        dashboardValue.deed.left_fields.pending_draft_approval
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_signed_document && (
                    <>
                      {" "}
                      {generateDeedTierTwoDashboard(
                        "Pending Signed Document",
                        "Pending Signed Document",
                        dashboardValue.deed.left_fields.pending_signed_document
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_recording && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Recording",
                        "Pending Recording",
                        dashboardValue.deed.left_fields.pending_recording
                      )}
                    </>
                  )}
                  {dashboardValue.deed.left_fields.pending_upload_to_HBAM && (
                    <>
                      {generateDeedTierTwoDashboard(
                        "Pending Upload to HBAM",
                        "Pending Upload to HBAM",
                        dashboardValue.deed.left_fields.pending_upload_to_HBAM
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Municipal" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(dashboardValue.municipal) && (
                <>
                  {generateDashboardTierTwo(
                    "Orders",
                    "municipal_order",
                    dashboardValue.municipal.municipal_order
                  )}
                  {generateDashboardTierTwo(
                    "Verification",
                    "muni_verification",
                    dashboardValue.municipal.muni_verification
                  )}
                  {generateDashboardTierTwo(
                    "Review",
                    "municipal_review",
                    dashboardValue.municipal.municipal_review
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Municipal" && titleThierTwo === "Orders" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {/* {generateDashboardTierThree("Required")}
              {generateDashboardTierThree("Outstanding")} */}
              {!_.isEmpty(
                dashboardValue.municipal.municipal_order.left_fields
              ) && (
                <>
                  {" "}
                  {generateDashboardTierThree(
                    "Required",
                    "required",
                    dashboardValue.municipal.municipal_order.left_fields
                      .required
                  )}
                  {generateDashboardTierThree(
                    "Outstanding",
                    "outstanding",
                    dashboardValue.municipal.municipal_order.left_fields
                      .outstanding
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Municipal" && titleThierTwo === "Verification" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(
                dashboardValue.municipal.muni_verification.left_fields
              ) && (
                <>
                  {generateDashboardTierThree(
                    "Permit Issues",
                    "permit_issue",
                    dashboardValue.municipal.muni_verification.left_fields
                      .permit_issues
                  )}
                  {generateDashboardTierThree(
                    "Code Issues",
                    "code_issue",
                    dashboardValue.municipal.muni_verification.left_fields
                      .code_issues
                  )}
                </>
              )}
            </div>
          )}
          {titleThierOne === "Violation" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(dashboardValue.violation.left_fields) && (
                <>
                  {generateDashboardTierTwo(
                    "Pending",
                    "Pending",
                    dashboardValue.violation.left_fields.pending
                  )}
                  {generateDashboardTierTwo(
                    "In Review",
                    "In Review",
                    dashboardValue.violation.left_fields.in_review
                  )}
                  {generateDashboardTierTwo(
                    "Pending B/U Acceptance",
                    "Pending B / U Accept",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                  )}
                  {generateDashboardTierTwo(
                    "In Process with B/U",
                    "In Progress B / U",
                    dashboardValue.violation.left_fields.in_progress_bu
                  )}
                  {generateDashboardTierTwo(
                    "Verifying Complete",
                    "Verifying Complete",
                    dashboardValue.violation.left_fields.verifying_complete
                  )}
                  {generateDashboardTierTwo(
                    "Pending Lien",
                    "Pending Lien",
                    dashboardValue.violation.left_fields.pending_lien
                  )}
                  {generateDashboardTierTwo(
                    "Pending Payment",
                    "Pending Payment",
                    dashboardValue.violation.left_fields.pending_payment
                  )}
                  {generateDashboardTierTwo(
                    "Pending Hearing",
                    "hearing",
                    dashboardValue.violation.left_fields.pending_hearing
                  )}
                </>
              )}
            </div>
          )}
          {titleThierTwo === "Pending Payment" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(
                dashboardValue.violation.left_fields.pending_payment.left_fields
              ) && (
                <>
                  {generateDashboardTierThree(
                    "In Review",
                    "In-Review",
                    dashboardValue.violation.left_fields.pending_payment
                      .left_fields.in_review
                  )}
                  {generateDashboardTierThree(
                    "Awaiting Approval",
                    "Awaiting Approval",
                    dashboardValue.violation.left_fields.pending_payment
                      .left_fields.awaiting_approval
                  )}
                  {generateDashboardTierThree(
                    "Approved",
                    "Approved",
                    dashboardValue.violation.left_fields.pending_payment
                      .left_fields.approved
                  )}
                  {generateDashboardTierThree(
                    "Rejected",
                    "Rejected",
                    dashboardValue.violation.left_fields.pending_payment
                      .left_fields.rejected
                  )}
                  {generateDashboardTierThree(
                    "Check Sent",
                    "Check Sent",
                    dashboardValue.violation.left_fields.pending_payment
                      .left_fields.check_sent
                  )}
                </>
              )}
            </div>
          )}
          {titleThierTwo === "Pending Hearing" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(
                dashboardValue.violation.left_fields.pending_hearing.left_fields
              ) && (
                <>
                  {generateDashboardTierThree(
                    "Assign Law Firm",
                    "law_firm_id",
                    dashboardValue.violation.left_fields.pending_hearing
                      .left_fields.assign_law_firm
                  )}
                  {generateDashboardTierThree(
                    "Assign Attorney",
                    "law_firm_poc_name",
                    dashboardValue.violation.left_fields.pending_hearing
                      .left_fields.assign_attorney
                  )}
                  {generateDashboardTierThree(
                    "Attended",
                    "attended",
                    dashboardValue.violation.left_fields.pending_hearing
                      .left_fields.attended
                  )}
                  {generateDashboardTierThree(
                    "Attendees",
                    "attendees",
                    dashboardValue.violation.left_fields.pending_hearing
                      .left_fields.attendees
                  )}
                </>
              )}
            </div>
          )}
          {titleThierTwo === "Pending B/U Acceptance" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(
                dashboardValue.violation.left_fields.pending_bu_acceptance
                  .left_fields
              ) && (
                <>
                  {" "}
                  {generateDashboardTierThree(
                    "Resicap (Acquisition)",
                    "Resicap (Acquisition)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resicap_acquisition
                  )}
                  {generateDashboardTierThree(
                    "ResiPRO (Construction)",
                    "ResiPRO (Construction)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resipro_construction
                  )}
                  {generateDashboardTierThree(
                    "ResiPRO (Maintenance / PPI)",
                    "ResiPRO (Maintenance / PPI)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resipro_maintenance
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (Leasing)",
                    "ResiHOME (Leasing)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resihome_leasing
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (PM)",
                    "ResiHOME (PM)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resihome_pm
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (Evictions)",
                    "ResiHOME (Evictions)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resihome_evictions
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (Violation)",
                    "ResiHOME (Violation)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resihome_violation
                  )}
                  {generateDashboardTierThree(
                    "Resicap (Dispositions)",
                    "Resicap (Dispositions)",
                    dashboardValue.violation.left_fields.pending_bu_acceptance
                      .left_fields.resicap_disposition
                  )}
                </>
              )}
            </div>
          )}
          {titleThierTwo === "In Process with B/U" && (
            <div className="flex-container" style={{ width: "95%" }}>
              {!_.isEmpty(
                dashboardValue.violation.left_fields.in_progress_bu.left_fields
              ) && (
                <>
                  {generateDashboardTierThree(
                    "Resicap (Acquisition)",
                    "Resicap (Acquisition)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resicap_acquisition
                  )}
                  {generateDashboardTierThree(
                    "ResiPRO (Construction)",
                    "ResiPRO (Construction)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resipro_construction
                  )}
                  {generateDashboardTierThree(
                    "ResiPRO (Maintenance / PPI)",
                    "ResiPRO (Maintenance / PPI)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resipro_maintenance
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (Leasing)",
                    "ResiHOME (Leasing)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resihome_leasing
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (PM)",
                    "ResiHOME (PM)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resihome_pm
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (Evictions)",
                    "ResiHOME (Evictions)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resihome_evictions
                  )}
                  {generateDashboardTierThree(
                    "ResiHOME (Violation)",
                    "ResiHOME (Violation)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resihome_violation
                  )}
                  {generateDashboardTierThree(
                    "Resicap (Dispositions)",
                    "Resicap (Dispositions)",
                    dashboardValue.violation.left_fields.in_progress_bu
                      .left_fields.resicap_disposition
                  )}
                </>
              )}
            </div>
          )}{" "}
        </>
      )}
    </React.Fragment>
  );
}
