import React, { useState, useEffect } from "react";
//import Assessments from "./assessments";
import NewAssessments from "./NewAssessments";
import { Button, FormGroup, Input } from "reactstrap";
import apicall from "../../utils/apicall";
//import _ from "lodash";
import helpers from "../../utils/helpers";
export default (props) => {
  const [assessments, set_assessments] = useState([]);
  const [assessment_type, set_assessment_type] = useState();
  const [show, set_show] = useState(false);

  const deleteAssessmentType = (assessment_type_id, hoaID) => {
    apicall.deleteAssessmentType(assessment_type_id, hoaID).then((resp) => {
      props.refreshHoa(hoaID);
      helpers.notify("Assessment Deleted ");
    });
  };

  const AssessmentsTables =
    assessments &&
    assessments.map((assessment, index) => {
      return (
        <NewAssessments
          key={"fy" + index}
          assessmentType={assessment}
          hoaID={props.hoaID}
          refreshHoa={props.refreshHoa}
          deleteAssessmentType={deleteAssessmentType}
          toggleModal={props.toggleModal}
        />
      );
    });

  useEffect(() => {
    set_assessments(props.hoa.assessment_types);
    console.log("The hoa is", props.hoa.assessment_types);
  }, [props.hoa.assessment_types]);

  const createAssessmentType = () => {
    set_show(false);
    apicall.createAssessmentType(props.hoaID, assessment_type).then((resp) => {
      if (resp) set_assessments([...assessments, resp]);
    });
  };

  return (
    <>
      <hr></hr>

      <div
        className={
          props.hoaID ? "add_New_Assessment" : " add_New_Assessment disable"
        }
        onClick={() => set_show(true)}
      >
        Add Assessment
      </div>
      {show && (
        <div className="add_address">
          <div className="new_assessment">
            <FormGroup>
              <Input
                type="text"
                name="assessment_type"
                id="assessment_type"
                placeholder="assessment_type"
                onChange={(event) => set_assessment_type(event.target.value)}
                value={assessment_type}
              />
            </FormGroup>
          </div>
          <div className="create_assessment">
            <Button onClick={createAssessmentType}> Save </Button>
          </div>
        </div>
      )}
      <div>{AssessmentsTables}</div>
    </>
  );
};
