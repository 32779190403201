import React, { Component } from "react";
//import { Button, FormGroup, Label } from "reactstrap";
import "../../styles/navbar.scss";
import resicap_logo from "../../images/resicap_aims_logo.png";
// import images from '../../images/images.png';
import "../../styles/login.css";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
//import TextValidator from "../helper_components/TextValidator";
import { toast } from "react-toastify";
import config from "../../utils/config";
//import eye from "../../images/eye.png";
// import { Ionicons } from "@expo/vector-icons"
// import { FaUserCircle } from 'react-icons/fa';
// import { FaLock } from'react-icons/fa';
// import { FaEye } from 'react-icons/fa';
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userPassword: "",
      loginResponse: null,
      isPasswordShown: false,
    };
  }
  notify = () => toast("Invalid Credentials");

  handleSubmit = (event) => {
    apicall
      .getOauthToken(this.state.userEmail, this.state.userPassword, this.props)
      .then((resp) => {
        if (resp !== null && resp.hasOwnProperty("data")) {
          let userId = resp.data.id;
          let userRole = resp.data.role;
          let mobile = resp.data.mobile;
          let dashboard_scope = resp.data.dashboard_scope;
          config.localforage.setItem("userid", userId);
          config.localforage.setItem("user_role", userRole);
          config.localforage.setItem("mobile", mobile);
          config.localforage.setItem("dashboard_scope", dashboard_scope);
        }
        if (resp === null || (resp !== null && resp.hasOwnProperty("errors"))) {
          this.notify();
        }
      });
    event.preventDefault();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  passwordShow = () => {
    this.setState({
      type: this.state.type === "input" ? "password" : "input",
    });
  };
  togglePasswordidVisibility = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  render() {
    const { isPasswordShown } = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div
            className="header__logo hidden-sm-down header-logo"
            id="headerleft"
          >
            <img src={resicap_logo} alt="Resicap Logo" />
          </div>
        </header>
        <div className="container login-container">
          <div className="row col-12 login-div">
            <ValidatorForm
              ref="form"
              className="login-form"
              onSubmit={this.handleSubmit}
              autoComplete="off"
            >
              <div class="ccil">
                <div class="limiter">
                  <div class="container-login100">
                    <div class="wrap-login100">
                      <form class="login100-form validate-form">
                        <span class="login100-form-logo">
                          <img
                            src={resicap_logo}
                            alt="images"
                            style={{ height: "15%", width: "90%" }}
                          />
                        </span>
                        {/* <span class="login100-form-title p-b-34 p-t-27">
                              LOG IN
                    </span> */}
                        <div class="wrap-input100">
                          <input
                            class="input100"
                            type="email"
                            name="email"
                            id="userEmail"
                            placeholder="Email"
                            value={this.state.userEmail}
                            onChange={this.handleChange}
                            style={{
                              border: "1px solid gray",
                              outline: "none",
                            }}
                          />{" "}
                        </div>
                        <div
                          class="faber"
                          style={{
                            marginTop: "-5.9%",
                            position: "fixed",
                            fontSize: "160%",
                            marginLeft: "-0.5%",
                          }}
                        >
                          {/* <i class="fa fa-user-circle" ></i> */}
                        </div>
                        <div class="wrap-input100 ">
                          <input
                            class="input100"
                            type={isPasswordShown ? "text" : "password"}
                            style={{
                              border: "1px solid gray",
                              outline: "none",
                            }}
                            name="password"
                            id="userPassword"
                            placeholder="Password"
                            value={this.state.userPassword}
                            onChange={this.handleChange}
                          />
                          <div
                            class="eye"
                            style={{
                              marginLeft: "89%",
                              marginTop: "-12%",
                              cursor: "pointer",
                              color: "black",
                            }}
                          >
                            <i
                              className={`${
                                isPasswordShown
                                  ? " fa fa-eye-slash"
                                  : " fa fa-eye"
                              }`}
                              onClick={this.togglePasswordidVisibility}
                              style={{ width: "47%" }}
                            ></i>
                            {/*<img src={eye} alt="Eye Logo"  
                              onClick={this.togglePasswordidVisibility} style={{width:"47%"}}></img> */}
                          </div>
                        </div>

                        <div
                          class="faber"
                          style={{
                            marginTop: "-6.3%",
                            position: "fixed",
                            fontSize: "220%",
                            marginLeft: "-0.5%",
                          }}
                        >
                          {/* </i> */}
                        </div>

                        <div class="container-login100-form-btn">
                          <button class="login100-form-btn">Login</button>
                        </div>

                        <div class="text-center p-t-90">
                          <a class="txt1" href="#"></a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </ValidatorForm>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
