import React, { Component } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";

const associationCustomStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "450px",
    marginBottom: "5px",
  }),
};

const deedVerificationCustomStyles = {
  container: (provided, state) => ({
    ...provided,
    marginBottom: "5px",
  }),
};

export default class AsyncCreatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selectedOption: "",
    };
  }

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterResults(inputValue));
      }, 1000);
    });

  //  filterResults = (inputValue) => {
  //     this.props.callapi(inputValue, this.props.propertyID).then((resp) => {
  //       let options = [];
  //       options = resp.results.map((x) => {
  //         return {
  //           value: x.value,
  //           label: x.label,
  //           // point_of_contact_1: x.poc1,
  //           // email: x.email1,
  //           // phone: x.phone1,
  //         };
  //       });
  //       console.log('options',options)
  //       return options;
  //     });
  //   };

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      this.filterResults(inputValue, callback);
    }, 1000);
  };

  filterResults = (inputValue, callback) => {
    this.props.callapi(inputValue, this.props.propertyID).then((resp) => {
      let options = [];
      options = resp.results.map((x) => {
        return {
          value: x.value,
          label: x.label,
          data: x,
        };
      });

      callback(options);
      return options;
    });
  };

  registrationStatusOption = () => {
    let registrationStatus = ["Active", "Dissolved", "Inactive"];
    return registrationStatus.map((c) => {
      return { label: c, value: c };
    });
  };

  render() {
    return (
      <AsyncCreatableSelect
        loadOptions={
          this.props.isRegistrationStatus
            ? this.registrationStatusOption
            : this.loadOptions
        }
        // onInputChange={this.handleInputChange}
        onChange={(value) => {
          this.props.handleOnChange(this.props.id, value);
        }}
        styles={
          this.props.isDeedVerification
            ? deedVerificationCustomStyles
            : associationCustomStyles
        }
        className="react-select-container"
        value={this.props.valueShown}
        placeholder={this.props.placeholder}
        isRequired={this.props.isRequired}
        isClearable={this.props.isClearable}
        isDisabled={this.props.disabled}
      />
    );
  }
}
