import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
//import BootstrapTable from 'react-bootstrap-table-next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/violation.css";
import apicall from "../../utils/apicall";
//import utils from "../../utils/helpers";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";

export default class ViolationsListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transfer_date: new Date(),
      violationList: [],
      pageNo: 1,
    };
  }

  componentWillMount() {
    this.fetchViolationList(this.props.propertyID, this.state.pageNo);
  }

  fetchViolationList = (propertyID, pageNo, perPage) => {
    apicall.fetchViolatioList(propertyID, pageNo, perPage).then((response) => {
      this.setState({
        violationList: response.results,
      });
    });
  };

  refreshList = (event, pageNo, perPage) => {
    this.setState({ pageNo: pageNo });
    this.fetchViolationList(this.props.propertyID, this.state.pageNo, perPage);
  };

  handleTransferDate = (date) => {
    this.setState({
      transfer_date: date,
      foreclosure_date: new Date(),
    });
  };

  handleForeclosureDate = (date) => {
    this.setState({
      foreclosure_date: date,
    });
  };

  changeDateFormat = (col) => {
    return helpers.formatDate(col);
  };
  render() {
    //let analysts = this.state.violationList;
    const columns = [
      {
        dataField: "id",
        text: "Violation ID",
        headerClasses: "table-header-cell-alignment",
      },
      {
        dataField: "violation_type",
        text: "Violation Type",
        headerClasses: "table-header-cell-alignment",
      },
      {
        dataField: "description",
        text: "Violation Description",
        headerClasses: "table-header-cell-alignment",
      },
      {
        dataField: "notes",
        text: "Memo/Notes",
        headerClasses: "table-header-cell-alignment",
      },
      {
        dataField: "status",
        text: "Current Status",
        headerClasses: "table-header-cell-alignment",
      },
      {
        dataField: "created_at",
        text: "Creation Date",
        formatter: this.changeDateFormat,
        headerClasses: "table-header-cell-alignment",
      },
      // , {
      //     dataField: 'resolution date',
      //     text: 'Resolution Date'
      // }
    ];
    var list = this.state.violationList;
    return (
      <React.Fragment>
        <section className="date">
          <div className="dates">
            <div className="from-date">
              <h6>From Date</h6>
              <DatePicker
                id="transfer_date"
                selected={this.state.transfer_date}
                onChange={this.handleTransferDate}
                value={this.state.transfer_date}
              />
            </div>
            <div className="to-date">
              <h6>To Date</h6>
              <DatePicker
                id="foreclosure_date"
                selected={this.state.foreclosure_date}
                onChange={this.handleForeclosureDate}
                value={this.state.foreclosure_date}
              />
            </div>
          </div>
          <PaginationComponent
            columns={columns}
            rows={list}
            handleSubmit={this.props.refreshList}
            pageNo={this.state.pageNo}
          />
        </section>
      </React.Fragment>
    );
  }
}
