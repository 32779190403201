import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
  CustomInput,
} from "reactstrap";
import apicall from "../../../utils/apicall";
//import helpers from "../../../utils/helpers";
//import {  Dropdown,  DropdownToggle,  DropdownMenu,  DropdownItem,} from "reactstrap";
import DatePicker from "react-datepicker";
import helpers from "../../../utils/helpers";

export default (props) => {
  //const [account, setAccount] = useState(props.account);
  const [account_number, setAccount] = useState(props.account);
  const [website_address, setWebsite_address] = useState(props.website_address);
  const [payment_website, setPayment_Website] = useState(props.payment_website);
  const [username, setUsername] = useState(props.username);
  const [password, setPassword] = useState(props.password);
  const [payment_website_username, setpayment_website_username] = useState(
    props.payment_website_username
  );
  const [payment_website_password, setpayment_website_password] = useState(
    props.payment_website_password
  );
  const [assessment_type_id, set_assessment_type] = useState(
    props.assessment_type_id
  );
  
  const [chimney_last_inspection_date, setchimney_last_inspection_date] =
    useState(props.chimney_last_inspection_date);
  const [dryer_last_inspection_date, setdryer_last_inspection_date] = useState(
    props.dryer_last_inspection_date
  );
  const [hvac_last_inspection_date, sethvac_last_inspection_date] = useState(
    props.hvac_last_inspection_date
  );
  const [fire_last_inspection_date, setfire_last_inspection_date] = useState(
    props.fire_last_inspection_date
  );

  const [fire_inspection, setfire_inspection] = useState(
    props.hoa_inspection_categories.find(
      (x) => x.inspection_category === "fire"
    )
  );

  const [chimney_inspection, setchimney_inspection] = useState(
    props.hoa_inspection_categories.find(
      (x) => x.inspection_category === "chimney"
    )
  );
  const [dryer_inspection, setdryer_inspection] = useState(
    props.hoa_inspection_categories.find(
      (x) => x.inspection_category === "dryer"
    )
  );
  const [hvac_inspection, sethvac_inspection] = useState(
    props.hoa_inspection_categories.find(
      (x) => x.inspection_category === "hvac"
    )
  );

  const onSubmitClick = () => {
    console.log("chimney_last_inspection_date", chimney_last_inspection_date);
    apicall
      .updateAccount(
        props.propertyid,
        props.hoaId,
        account_number,
        website_address,
        payment_website,
        username,
        password,
        assessment_type_id,
        payment_website_username,
        payment_website_password,
        chimney_last_inspection_date,
        dryer_last_inspection_date,
        hvac_last_inspection_date,
        fire_last_inspection_date
      )
      .then((resp) => {
        props.refreshList();
        props.onCancelClick();
      });
  };

  useEffect(() => {
    if (props.assessment_types.length && props.assessment_types.length === 1) {
      set_assessment_type(props.assessment_types[0].id);
    }
  }, [props.assessment_types]);

  const createSelectItems = () => {
    let selectOptions = [];
    selectOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    props.assessment_types.map((assessment_type, index) => {
      selectOptions.push(
        <option key={assessment_type.id} value={assessment_type.id}>
          {assessment_type.name}
        </option>
      );
    });
    return selectOptions;
  };

  const checkInspections = () => {
    if (
      fire_inspection.require_inspection === true ||
      chimney_inspection.require_inspection === true ||
      dryer_inspection.require_inspection === true ||
      hvac_inspection.require_inspection === true
    )
      return true;
  };
  return (
    <div>
      <Modal isOpen={true} toggle={props.onCancelClick}>
        <ModalHeader>
          <div className="">
            <span className="heading">{props.heading}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={2} for="">
              Account Number{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="account_number"
                id="account_number"
                placeholder=""
                onChange={(event) => {
                  setAccount(event.target.value);
                }}
                value={account_number}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              HOA Website{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="website_address"
                id="website_address"
                placeholder="HOA_Website"
                onChange={(event) => {
                  setWebsite_address(event.target.value);
                }}
                value={website_address}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Username{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder=""
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                value={username}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Password{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="password"
                id="password"
                placeholder=""
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Payment Website{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="payment_address"
                id="payment_address"
                placeholder="Payment_Website"
                onChange={(event) => {
                  setPayment_Website(event.target.value);
                }}
                value={payment_website}
              />
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label sm={2} for="">
              Hoa Website{""}
            </Label>
            <Col sm={10}>
              <Input 
              type = "text"
              name="account_number"
              id=""
              placeholder=""onChange={(event) => {setHoa(event.target.value)}}
              value={hoa_Website} />
               </Col>
          </FormGroup>
        */}
          <FormGroup row>
            <Label sm={2} for="">
              Username{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="payment_website_username"
                id="payment_website_username"
                placeholder=""
                onChange={(event) => {
                  setpayment_website_username(event.target.value);
                }}
                value={payment_website_username}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Password{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="payment_website_password"
                id="payment_website_password"
                placeholder=""
                onChange={(event) => {
                  setpayment_website_password(event.target.value);
                }}
                value={payment_website_password}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="">Assessment Description</Label>
            <Input
              type="select"
              name="assessment_type_id"
              id="assessment_type_id"
              placeholder=""
              onChange={(event) => {
                set_assessment_type(event.target.value);
              }}
              value={assessment_type_id}
            >
              {createSelectItems()}
            </Input>
          </FormGroup>
          
          <hr />
          {checkInspections() && (
            <>
              {" "}
              <div className="display-in-same-row">
                <div
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  Inspection
                </div>
                <div
                  style={{
                    width: "45%",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  Date of last Inspection&nbsp;&nbsp;
                </div>
              </div>
              {fire_inspection.require_inspection === true && (
                <div className="display-in-same-row">
                  <div style={{ width: "40%", textAlign: "right" }}> Fire</div>
                  <span style={{ width: "30%", marginLeft: "10%" }}>
                    <DatePicker
                      id="fire_last_inspection_date"
                      selected={
                        fire_last_inspection_date !== null
                          ? helpers.restructureDate(fire_last_inspection_date)
                          : null
                      }
                      onChange={(date) => setfire_last_inspection_date(date)}
                      style={{ width: "50%" }}
                    ></DatePicker>
                  </span>
                </div>
              )}
              {chimney_inspection.require_inspection === true && (
                <div className="display-in-same-row">
                  <div style={{ width: "40%", textAlign: "right" }}>
                    Chimney/Fireplace{" "}
                  </div>
                  <span style={{ width: "30%", marginLeft: "10%" }}>
                    <DatePicker
                      id="chimney_last_inspection_date"
                      selected={
                        chimney_last_inspection_date !== null
                          ? helpers.restructureDate(
                              chimney_last_inspection_date
                            )
                          : null
                      }
                      onChange={(date) => setchimney_last_inspection_date(date)}
                    ></DatePicker>
                  </span>
                </div>
              )}
              {dryer_inspection.require_inspection === true && (
                <div className="display-in-same-row">
                  <div style={{ width: "40%", textAlign: "right" }}>
                    {" "}
                    Dryer Vent
                  </div>
                  <span style={{ width: "30%", marginLeft: "10%" }}>
                    <DatePicker
                      id="dryer_last_inspection_date"
                      selected={
                        dryer_last_inspection_date !== null
                          ? helpers.restructureDate(dryer_last_inspection_date)
                          : null
                      }
                      onChange={(date) => setdryer_last_inspection_date(date)}
                    ></DatePicker>
                  </span>
                </div>
              )}
              {hvac_inspection.require_inspection === true && (
                <div className="display-in-same-row">
                  <div style={{ width: "40%", textAlign: "right" }}>HVAC</div>
                  <span style={{ width: "30%", marginLeft: "10%" }}>
                    <DatePicker
                      id="hvac_last_inspection_date"
                      selected={
                        hvac_last_inspection_date !== null
                          ? helpers.restructureDate(hvac_last_inspection_date)
                          : null
                      }
                      onChange={(date) => sethvac_last_inspection_date(date)}
                    ></DatePicker>
                  </span>
                </div>
              )}
            </>
          )}
          <Button onClick={onSubmitClick} style={{ marginLeft: "65%" }}>
            Save
          </Button>
          &nbsp;
          <Button onClick={props.onCancelClick}>Cancel</Button>
        </ModalBody>
      </Modal>
    </div>
  );
};
