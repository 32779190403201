import React, { useState, useEffect } from "react";
import { Input, FormGroup, Label, Col } from "reactstrap";
import SelectOptions from "../helper_components/SelectOptions";
import config from "../../utils/config";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { FaTrashAlt } from "react-icons/fa";
import _ from "lodash";

export default (props) => {
    console.log("mallesh",props)
    const [isButtonDisabled, set_isButtonDisabled] = useState(true);
    const [hoaNames, setHoaNames] = useState([]);
    React.useEffect(() => {
        if(props && props.hoaNames) {
            setHoaNames(props.hoaNames)
           }
    },[props]);
    const [rows, set_rows] = useState(
        _.isEmpty(props.one_time_fees_rows)
            ? []
            : props.one_time_fees_rows.map((x) => {
                x.amount = parseFloat(x.amount).toFixed(2);
                return x;
            })
    );
    console.log("dattat",rows)
    useEffect(() => {
        console.log("use effect rows--->", rows);
        if (rows.length === 0) addRows();
    }, []);

    const addRows = () => {
        set_isButtonDisabled(false);
        const tempId = (+new Date() + Math.floor(Math.random() * 999999)).toString(
            36
        );
        let row = {
            id: tempId,
            hoa_id: "",
            type_of_fee: "",
            amount: "",
            status: "new",
        };
        set_rows([...rows, row]);
    };

    const deleteAssessment = (row) => {
        if (row && row.status === "new") {
            deleteArrayObject(row.id);
        } 
        else {
            apicall
                .deleteAssessment(row.id, row.hoa_id, row.management_company_id)
                .then((resp) => {
                    // props.refreshHoa(props.hoaID);
                    // helpers.notify("deleted");
                });
        }
    };

    const saveAssessments = () => {
        set_isButtonDisabled(true);
        const restructuredAssessments =
        rows &&
        rows.slice().map((row, index) => {
          if (row.status === "new") {
            let x = { ...row };
            delete x["status"];
            delete x["id"];
            return x;
          } else return row;
        });
        props.handleManagementFees(restructuredAssessments)
    };

    const deleteArrayObject = (assessementID) => {
        const idToRemove = assessementID;
        const filternewArray = rows
            .slice()
            .filter((items) => items.id !== idToRemove);
        set_rows(filternewArray);
    };

    const updateTable = (newData) => {
        set_isButtonDisabled(false);
        const updatedRows =
            rows &&
            rows.slice().map((row, index) => {
                if (row.id == newData.id) {
                    let x = { ...row };
                    x[newData.name] = newData.value;
                    return x;
                } else return row;
            });

        set_rows(updatedRows);
    };

    const handleCellChange = (event) => {
        console.log(event.target.name,event.target.value)
        const newData = {
            id: event.target.id,
            name: event.target.name,
            value: event.target.value,
        };
        updateTable(newData);
    };

    const handleBlur = (event) => {
        var num = event.target.value;
        var cleanNum = "";
        num = num.replace("$", "");
        if (!isNaN(num)) {
            num = parseFloat(num);
            cleanNum = num.toFixed(2);
        }
        if (isNaN(num)) {
            num = 0;
        }

        const newData = {
            id: event.target.id,
            name: event.target.name,
            value: cleanNum,
        };
        updateTable(newData);
    };

    const handleSubmit = () => { };

    return (
        <>
            <div className="form-bordered">
                <Table
                    addRows={addRows}
                    rows={rows}
                    handleChange={handleCellChange}
                    handleSubmit={handleSubmit}
                    saveAssessments={saveAssessments}
                    deleteAssessment={deleteAssessment}
                    isButtonDisabled={isButtonDisabled}
                    handleBlur={handleBlur}
                    hoaNames={hoaNames}
                ></Table>
            </div>
        </>
    );
};

// Table component

function Table(props) {
    const tableRows =
        props.rows &&
        props.rows.map((row, index) => {
            return (
                <Row
                    key={row.id}
                    index={index}
                    handleChange={props.handleChange}
                    handleSubmit={props.handleSubmit}
                    deleteAssessment={props.deleteAssessment}
                    row={row}
                    handleBlur={props.handleBlur}
                    hoaNames={props.hoaNames}
                />
            );
        });
    return (
        <>
            <div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{width:"20px"}}>Hoa Name</th>
                            <th style={{width:"10px"}}>Type of Fee</th>
                            <th style={{padding:"12px 36px"}}>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </div>
            <div className="charges-header">
                <div className="add-charge">
                    <button
                        type="button"
                        onClick={props.addRows}
                        className="btn btn-success pull-right"
                    >
                        Add Line Item
                    </button>
                    <button
                        type="button"
                        onClick={props.saveAssessments}
                        disabled={props.isButtonDisabled}
                        className="btn btn-secondary pull-right add-charge-button"
                    >
                        Save
                    </button>
                </div>
            </div>
        </>
    );
}

// Row component

function Row(props) {
    return (
        <tr>
            <DropDownCell
                handleChange={props.handleChange}
                cellData={{
                    type: "hoa_id",
                    value: props.row.hoa_id,
                    id: props.row.id,
                    style: true,
                }}
                hoaNames={props.hoaNames}
            />
            <DropDownCell
                handleChange={props.handleChange}
                cellData={{
                    type: "type_of_fee",
                    value: props.row.type_of_fee,
                    id: props.row.id,
                }}
            />
            <InputCell
                handleChange={props.handleChange}
                cellData={{
                    type: "amount",
                    value: props.row.amount,
                    id: props.row.id,
                }}
                isNumber={true}
                handleBlur={props.handleBlur}
            />
            <td>
                <FaTrashAlt
                    style={{ fontSize: "14px", marginLeft: "16px", cursor: "pointer" }}
                    size="1.5em"
                    onClick={() => props.deleteAssessment(props.row)}
                />
            </td>
        </tr>
    );
}

// Cell component
function InputCell(props) {
    return (
        <td>
            <Input
                type="number"
                name={props.cellData.type}
                id={props.cellData.id}
                value={props.cellData.value}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            />
        </td>
    );
}

function DropDownCell(props) {
    return (
        <td>
            <Input
                type="select"
                name={props.cellData.type}
                id={props.cellData.id}
                value={props.cellData.value}
                onChange={props.handleChange}
                style= {{fontSize : props.cellData.style ? "13px" :null,height : props.cellData.style ? "36px" :null}}
            >
                {props.cellData.type === "hoa_id" ? (
                    <SelectOptions options={props.hoaNames} managementCompany={true}/>
                ) : (
                    <SelectOptions options={config.managementFees} />
                )}
            </Input>
        </td>
    );
}
