import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
var inspectioncategoryapi = {
  getInspectionCategory: async function (hoaID) {
    let url =
      config.baseUrl +
      "/api/v1/hoa_inspection_categories/category_types?hoa_id=" +
      hoaID;
    let inspection_categories = null;
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting inspection category" + response
        );
      })
      .then((responseJson) => {
        inspection_categories = responseJson;
      })
      .catch(function (error) {});
    return inspection_categories;
  },
  createInspectionCategory: async function (hoaID, inspection_category) {
    var res = "";
    let url =
      config.baseUrl +
      "/api/v1/hoa_inspection_categories/update_incpection_categories?hoa_id=" +
      hoaID;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ inspection_category: inspection_category }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while creating inspection category" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  listInspectionCategories: async function (hoaID, propertyID) {
    var inspection_categories = [];
    let url =
      config.baseUrl + "/api/v1/hoa_inspection_categories?hoa_id=" + hoaID;
    // "&property_id=" +
    // propertyID;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting inspection_categories" + response
        );
      })
      .then((resp) => {
        inspection_categories = resp;
      })
      .catch(function (error) {});
    return inspection_categories;
  },
  updateInspectionCategory: async function (hoaID, inspectionCatergoriesArray) {
    var res = "";
    let url =
      config.baseUrl +
      `/api/v1/hoa_inspection_categories/update_incpection_categories?hoa_id=` +
      hoaID;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({
        hoa_inspection_categories: inspectionCatergoriesArray,
      }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while inspection category update" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  getIntervals: async function (hoaID) {
    var intervals = [];
    let url =
      config.baseUrl +
      "/api/v1/hoa_inspection_categories/category_intervals?hoa_id=" +
      hoaID;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting intervals" + response
        );
      })
      .then((resp) => {
        intervals = resp;
      })
      .catch(function (error) {});
    return intervals;
  },
};
export default withRouter(inspectioncategoryapi);
