import React, { useState, useEffect } from "react";
import "../../../styles/content.css";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import PropertySearchTable from "./PropertySearchTable";
import { toast } from "react-toastify";
import _ from "lodash";
import config from "../../../utils/config";
import GuestUserNavbar from "../../page_layout_components/GuestUserNavbar";
import Loader from "../../helper_components/LoaderComponent";

export default function PropertyGuestSearch(props) {
  const [properties, setproperties] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [perpage, setperpage] = useState(10);
  const [total, settotal] = useState();
  const searchText = props.match.params.searchText;
  const [isLoading, setisLoading] = useState(false);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    searchProperties();
    console.log("Came to property search");
  }, [form_inputs.sort]);

  const notify = (message) => toast(message);

  const handleSubmit = (event, pageNo, perPage) => {
    config.localforage.setItem("search-text", searchText);
    config.localforage.setItem("page-number", pageNo);
    config.localforage.setItem("per-page", perPage);

    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperpage(perPage);
    }
    setisLoading(true);
    apicall
      .guestSearch(searchText, form_inputs, pageNo, perPage)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("error")) {
          let errorMsg = helpers.getErrorMsg(response.error);
          if (errorMsg) notify("Search Failed: No Records Found: " + errorMsg);
        } else {
          if (typeof response !== "undefined" && response.length === 0) {
            notify("No Records Found: ");
          } else {
            if (_.isString) {
              config.localforage.setItem("searchResult", response);
            }

            setproperties(response.results);
            settotal(response.total);
            setisLoading(false);
          }
        }
      });

    event.preventDefault();
  };

  const searchProperties = () => {
    setisLoading(true);
    apicall.guestSearch(searchText, form_inputs).then((response) => {
      if (response !== null && response.hasOwnProperty("error")) {
        let errorMsg = helpers.getErrorMsg(response.error);
        if (errorMsg) notify("Search Failed: No Records Found: " + errorMsg);
      } else {
        if (typeof response !== "undefined" && response.length === 0) {
          notify("No Records Found: ");
        } else {
          if (_.isString) {
            config.localforage.setItem("searchResult", response);
          }
          setproperties(response.results);
          settotal(response.total);
          setisLoading(false);
        }
      }
    });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      <GuestUserNavbar {...props} />
      {isLoading && <Loader />}
      <div
        className="search-result"
        style={{ marginTop: "12vh", marginLeft: "20rem" }}
      >
        <header className="content-title">
          <h2 className="page-heading">
            <strong>Property Search</strong>
          </h2>
        </header>
        <PropertySearchTable
          guestSearch={true}
          properties={properties}
          handleSubmit={handleSubmit}
          pageNo={pageNo}
          page={perpage}
          totalCount={total}
          sortColumns={sortColumns}
        />
      </div>
    </React.Fragment>
  );
}
