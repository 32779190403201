import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var deedVerificationapi = {
  readDeedVerification: async function (id, propertyID) {
    let url = `${config.baseUrl}/api/v1/deed_verifications/${id}?property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  readQCDTypes: async function () {
    let url = `${config.baseUrl}/api/v1/deed_verifications/qcd_types`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  readQCDStages: async function () {
    let url = `${config.baseUrl}/api/v1/deed_verifications/qcd_stages`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateDeedVerification: async function (id, propertyID, data) {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/deed_verifications/${id}?property_id=${propertyID}`;
    delete data.id;
    let headers;
    let response;
    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if(key === "deed_verification_notes") {
          if(!data[key] || data[key] === "") {
            data[key] = null
            continue
          }
        }
        if (data[key] === null) {
          data[key] = "";
        }
        form.append("deed_verification[" + key + "]", data[key]);
      }
    }
    headers = await helpers.defaultHeaders();
    await Axios.put(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  deleteDeedVerificationDocument: async function (
    propertyId,
    actionID,
    documentId
  ) {
    let url = `${config.baseUrl}/api/v1/deed_verifications/${actionID}/delete_documents?document_id=${documentId}&property_id=${propertyId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(deedVerificationapi);
