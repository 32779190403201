import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import TextValidator from "../../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../../styles/_createvendor.scss";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import DatePicker from "react-datepicker";

export default function TitleVendorFieldsModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : {});
  const [title_effective_date, settitle_effective_date] = useState(
    props.formdata ? props.formdata.title_effective_date : null
  );
  const [title_search_receive_date, settitle_search_receive_date] = useState(
    props.formdata ? props.formdata.title_search_receive_date : null
  );
  const [recording_date, setrecording_date] = useState(
    props.formdata ? props.formdata.recording_date : null
  );

  const handleDateChange = (value, key) => {
    let date = helpers.formatApiDate(value);
    //let date = helpers.restructureDate(value);
    if (key === "title_effective_date") settitle_effective_date(date);
    if (key === "title_search_receive_date") settitle_search_receive_date(date);
    if (key === "recording_date") setrecording_date(date);
    return;
  };

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    setform(Object.assign({ ...formdata }, { [targetID]: value }));
  };

  const handleSubmit = (event) => {
    var requestObject = {
      vendor_field: {
        title_effective_date: title_effective_date,
        title_search_receive_date: title_search_receive_date,
        transfer_deed_type: formdata.transfer_deed_type,
        recording_date: recording_date,
        current_vesting_name: formdata.current_vesting_name,
        number_of_parcels: formdata.number_of_parcels,
        apn_tax_parcel_ids: formdata.apn_tax_parcel_ids,
      },
    };

    apicall
      .updateVendorFields(requestObject, props.formdata.title_order_id)
      .then((response) => {
        setform(response);
        helpers.notify("Title Vendor Fields Updated Successfully ");
        setTimeout(() => {}, 600);
        props.toggle();
        props.fetchDocuments();
      });
  };

  const vendorFields = () => {
    return (
      <div>
        <table>
          <tr>
            <th style={{ width: "8%" }}>Title Effective Date</th>
            <th style={{ width: "8%" }}>Title Search Receive Date</th>
            <th>Transfer Deed Type</th>
            <th style={{ width: "8%" }}>Recording Date For Transfer Deed</th>
            <th>Current Vesting Name</th>
            <th style={{ width: "15%" }}>
              Vendor Number of Parcels contain in Legal Description of lastest
              Transfer Deed
            </th>
            <th>Vendor-APN/Tax Parcel Id</th>
          </tr>
          <tr>
            <td>
              <DatePicker
                id="title_effective_date"
                selected={
                  title_effective_date
                    ? helpers.restructureDate(title_effective_date)
                    : null
                }
                onChange={(value) =>
                  handleDateChange(value, "title_effective_date")
                }
                value={
                  title_effective_date
                    ? helpers.restructureDate(title_effective_date)
                    : null
                }
              />
            </td>
            <td>
              <DatePicker
                id="title_search_receive_date"
                selected={
                  title_search_receive_date
                    ? helpers.restructureDate(title_search_receive_date)
                    : null
                }
                onChange={(value) =>
                  handleDateChange(value, "title_search_receive_date")
                }
                value={
                  title_search_receive_date
                    ? helpers.restructureDate(title_search_receive_date)
                    : null
                }
              />
            </td>
            <td>
              <TextValidator
                type="text"
                name="transfer_deed_type"
                id="transfer_deed_type"
                placeholder="Transfer Deed Type"
                onChange={handleChange}
                value={formdata.transfer_deed_type}
              />
            </td>
            <td>
              <DatePicker
                id="recording_date"
                selected={
                  recording_date
                    ? helpers.restructureDate(recording_date)
                    : null
                }
                onChange={(value) => handleDateChange(value, "recording_date")}
                value={
                  recording_date
                    ? helpers.restructureDate(recording_date)
                    : null
                }
              />
            </td>
            <td>
              <TextValidator
                type="text"
                name="current_vesting_name"
                id="current_vesting_name"
                placeholder="Current Vesting Name"
                validators={["required"]}
                errorMessages={["Current Vesting Name is required"]}
                onChange={handleChange}
                value={formdata.current_vesting_name}
              />
            </td>
            <td>
              <TextValidator
                type="text"
                name="number_of_parcels"
                id="number_of_parcels"
                placeholder="Legal Description"
                validators={["required"]}
                errorMessages={["Legal Description is required"]}
                onChange={handleChange}
                value={formdata.number_of_parcels}
              />
            </td>
            <td>
              <TextValidator
                type="text"
                name="apn_tax_parcel_ids"
                id="apn_tax_parcel_ids"
                placeholder="Vendor- APN/Tax Parcel Id"
                validators={["required"]}
                errorMessages={["Vendor- APN/Tax Parcel Id is required"]}
                onChange={handleChange}
                value={formdata.apn_tax_parcel_ids}
              />
            </td>
          </tr>
        </table>
      </div>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <Modal isOpen={true} toggle={props.toggle} className="vendor-modal">
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className=""> {` Title Vendor Fields`}</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              {vendorFields()}

              <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
                Submit
              </Button>
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </div>
    );
  };
  //console.log("the props is ", props.formdata);
  return <React.Fragment>{renderModal()}</React.Fragment>;
}
