import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var municipalVendorapi = {
  createMunicipalVendor: async function (data) {
    let url = config.baseUrl + "/api/v1/municipal_vendors";
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listMunicipalVendor: async function (data, pageNo, perPage) {
    let url = config.baseUrl + "/api/v1/municipal_vendors?";
    let params = {};

    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // if (pageNo) {
    //   url = url + "?page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    // let headers;
    // let response;
    // headers = await helpers.defaultHeaders();
    // await Axios.get(url, { headers: headers }).then((resp) => {
    //   response = resp;
    // });
    // return response.data;
  },

  readMunicipalVendor: async function (id) {
    let url = `${config.baseUrl}/api/v1/municipal_vendors/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  deleteMunicipalVendorCreation: async function (id) {
    let url = `${config.baseUrl}/api/v1/municipal_vendors/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  updateMunicipalVendor: async function (data, id) {
    delete data.created_at;
    delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/municipal_vendors/${id}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();

    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteCodeOfficerDetails: async function (id, code_officer_detail_id) {
    let url = `${config.baseUrl}/api/v1/municipal_vendors/${id}/delete_code_officer_details?code_officer_detail_id=${code_officer_detail_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
};
export default withRouter(municipalVendorapi);
