import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var lawFirmapi = {
  createLawFirm: async (municipalityobj) => {
    let url = `${config.baseUrl}/api/v1/law_firms`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, municipalityobj, { headers: headers }).then(
      (resp) => {
        response = resp;
      }
    );
    return response.data;
  },

  updateLawFirm: async (data, lawFirmID) => {
    let url = `${config.baseUrl}/api/v1/law_firms/${lawFirmID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listAllLawFirm: async (data, pageNo, perPage) => {
    let url = `${config.baseUrl}/api/v1/law_firms?`;
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  readLawFirm: async function (id) {
    let url = `${config.baseUrl}/api/v1/law_firms/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteLawFirmPOC: async function (id, poc_id) {
    let url = `${config.baseUrl}/api/v1/municipalities/${id}/destroy_title_poc?title_point_of_contact_id=${poc_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  deleteLawFirm: async function (id) {
    let url = `${config.baseUrl}/api/v1/law_firms/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
};
export default withRouter(lawFirmapi);
