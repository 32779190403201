import React, { Component } from "react";
import "../../../styles/_general.scss";
import apicall from "../../../utils/apicall";
import AsyncMultiSelect from "../AsyncMultiSelect";
import "../../../styles/_display-hoa.scss";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import Loader from "../../helper_components/LoaderComponent";
import config from "../../../utils/config";
import HoaFormComponent from "../../hoas/FormComponent.js";
import MgmtCoFormComponent from "../../management_companies/FormComponent.js";
import { FaEdit, FaTrashAlt, FaBan } from "react-icons/fa";
import PaginationComponent from "../../helper_components/PaginationComponent";
import ConfirmBox from "../../helper_components/ConfirmBox";
import helpers from "../../../utils/helpers";
import _ from "lodash";
import AccountModal from "./AccountEdit";
import AssociationModal from "../../hoas/AssocaitionModal";
export default class DisplayHOA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoas: [],
      propertyid: props.propertyid,
      isLoading: true,
      row: {},
      totalCount: 0,
      pageNo: 1,
      accountModalOpen: false,
    };
  }

  componentWillMount() {
    this.loadHoas();
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  loadHoas = () => {
    apicall
      .getHoaByPropertyID(
        this.props.propertyid,
        this.state.pageNo,
        this.state.perPage
      )
      .then((response) => {
        let masterHOA = null;
        if (response && response.total == 1) {
          masterHOA = response.results[0];
        } else if (response && response.total > 1) {
          masterHOA = response.results.find((x) => x.status === "Master");
        }
        this.setState({
          hoas: response.results,
          totalCount: response.total,
          isLoading: false,
        });
        this.props.updateMasterHoa(masterHOA);
      });
  };

  refreshList = (event, pageNo, perPage) => {
    this.setState({
      pageNo,
      perPage,
      isLoading: true,
    });
    setTimeout(this.loadHoas, 3000);
  };

  deleteRecord = (row) => {
    apicall.removeHOA(row.id, this.state.propertyid).then((response) => {
      if (response) {
        toast("Deleted successfully");
        this.setState({ isLoading: true });
        setTimeout(this.loadHoas, 3000);
        setTimeout(() => this.props.getActions(this.props.propertyid), 3000);
      }
    });
  };

  buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (config["showHoa"].includes(this.props.userRole)) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <FaTrashAlt
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => this.setState({ row, deleteModal: true })}
          />
        </div>
      );
    } else {
      return <FaBan className="font-awesome-disabled" size="1.6em" />;
    }
  };
  hoaInfo = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {this.loadCell(true, row)}
        {this.loadCell(false, row)}
      </div>
    );
  };

  // companyInfo = (cell, row, rowIndex, formatExtraData) => {
  //   return !_.isEmpty(row.management_company)
  //     ? this.loadCell(false, row)
  //     : "Nil";
  // };

  nameValueDiv = (name, value) => {
    return (
      <div
        style={{
          display: "flex",
          marginTop: name === "Assessment Amount" ? "2%" : "",
        }}
      >
        <div style={{ width: "30%", fontWeight: "bold" }}>{name}</div>
        <div>{value}</div>
      </div>
    );
  };

  pocDetails = (data, count, isHOA) => {
    let mailId = isHOA ? data["email" + count] : data["email"];
    let phoneNumber = isHOA ? data["phone" + count] : data["phone"];
    let pocName = isHOA ? data["poc" + count] : data["name"];
    let titleName = isHOA ? data["title" + count] : data["title"];
    return (
      // <div style={{ marginTop: "7px", marginBottom: "7px" }}>
      <div>
        {this.nameValueDiv("POC " + count + " Name", pocName)}
        {this.nameValueDiv(
          "Title",
          isHOA ? titleName : config.McPocTitles[titleName]
        )}
        {this.nameValueDiv("Phone Number", phoneNumber)}
        {this.nameValueDiv("Email", mailId)}
      </div>
    );
  };
  hoaNametableData = (nameValue, isHOA, row) => {
    let showEdit = true;
    return (
      !_.isEmpty(nameValue) &&
      nameValue.map((x, index) => {
        return (
          <td key={index} style={{ wordBreak: "break-all" }}>
            {" "}
            <div className="edit-link">
              {showEdit ? (
                <>
                  {isHOA && (
                    <FaTrashAlt
                      style={{ float: "right" }}
                      size="1.5em"
                      onClick={() =>
                        this.setState({ row: row[index], deleteModal: true })
                      }
                    />
                  )}
                  <FaEdit
                    style={{ float: "right" }}
                    size="1.5em"
                    onClick={() => {
                      if (isHOA) {
                        this.setState({
                          row: {
                            ...row[index],
                            management_company_id: {
                              value: row[index].management_company_id,
                              label:
                                row[index].management_company &&
                                row[index].management_company.name,
                            },
                          },
                          openHoaModal: true,
                        });
                      } else
                        this.setState({
                          row: row[index],
                          openMCModal: true,
                        });
                    }}
                  />
                </>
              ) : null}
            </div>{" "}
            {x}
          </td>
        );
      })
    );
  };

  tableData = (name, key) => {
    return name.map((x, i) => {
      if (key === "Periodicity")
        return <td key={i}>{config.periodicityDummy[x]}</td>;
      else if (key === "HOA Website" || key === "Payment Website")
        return (
          <td key={i} style={{ wordBreak: "break-all" }}>
            <a href={"http://" + x} target="_blank">
              {x}
            </a>
          </td>
        );
      else if (key === "Good Through Date") {
        return <td key={i}>{helpers.formatDate(x)}</td>;
      } else return <td key={i}>{x}</td>;
    });
  };

  accountTableData = (name, row) => {
    return name.map((x, i) => {
      return (
        <>
          <td key={i} style={{ wordBreak: "break-all" }}>
            <div
              onClick={() => {
                this.setState({
                  hoaID: row[i].id,
                  accountModalOpen: true,
                  property_hoa_account: row[i].property_hoa_account,
                  assessment_types: row[i].assessment_types,
                  hoas_properties: row[i].hoas_properties,
                  website_address: row[i].website_address,
                  payment_website: row[i].payment_website,
                  chimney_last_inspection_date:
                    row[i].chimney_last_inspection_date,
                  dryer_last_inspection_date: row[i].dryer_last_inspection_date,
                  fire_last_inspection_date: row[i].fire_last_inspection_date,
                  hvac_last_inspection_date: row[i].hvac_last_inspection_date,
                  hoa_inspection_categories: row[i].hoa_inspection_categories,
                });
              }}
            >
              {" "}
              <FaEdit style={{ float: "right" }} size="1.5em" /> {x}
            </div>
          </td>
        </>
      );
    });
  };

  filterData = (mc, key) => {
    return mc.map((x, i) => {
      if (_.has(x, [key])) return x[key];
      else return null;
    });
  };

  fetchPocData = (row, pocid) => {
    return row.map((hoa, i) => {
      if (_.has(hoa, [pocid]) && _.has(hoa, "management_company")) {
        let pocId = hoa[pocid];
        let pocArray = hoa.management_company.point_of_contacts;
        let poc = pocArray.find((x) => x.id === pocId);
        return poc;
      } else {
        return null;
      }
    });
  };

  pocTable = (data, key) => {
    return data.map((x) => {
      if (
        key === "name" &&
        _.has(x, "name") &&
        (x.name !== null || x.name !== undefined)
      ) {
        return <td>{x.name}</td>;
      } else if (key === "title" && _.has(x, "title")) {
        return (
          <td style={{ wordBreak: "break-all" }}>
            {config.McPocTitles[x.title]}
          </td>
        );
      } else if (key === "phone" && _.has(x, "phone")) {
        return <td style={{ wordBreak: "break-all" }}>{x.phone}</td>;
      } else if (key === "email" && _.has(x, "email")) {
        return <td style={{ wordBreak: "break-all" }}>{x.email}</td>;
      } else return <td></td>;
    });
  };

  hoasPropertiestableData = (data, key) => {
    return data.map((x) => {
      if (
        key === "username" &&
        _.has(x, "username") &&
        (x.name !== null || x.name !== undefined)
      ) {
        return <td>{x.username}</td>;
      } else if (key === "password" && _.has(x, "password")) {
        return <td style={{ wordBreak: "break-all" }}>{x.password}</td>;
      } else if (
        key === "payment_username" &&
        _.has(x, "payment_username") &&
        (x.name !== null || x.name !== undefined)
      ) {
        return <td style={{ wordBreak: "break-all" }}>{x.payment_username}</td>;
      } else if (key === "payment_password" && _.has(x, "payment_password")) {
        return <td style={{ wordBreak: "break-all" }}>{x.payment_password}</td>;
      } else return <td></td>;
    });
  };

  loadCell = (isHOA, row) => {
    // let showEdit = config['showHoa'].includes(this.props.userRole);
    let showEdit = true;
    let management_company = this.filterData(row, "management_company");
    let management_poc1 = this.fetchPocData(row, "management_poc1_id");
    let management_poc2 = this.fetchPocData(row, "management_poc2_id");
    let management_poc3 = this.fetchPocData(row, "management_poc3_id");

    let data = isHOA
      ? row.map((x) => {
          return x;
        })
      : management_company;
    let nameTitle = isHOA ? "HOA Name" : "MC";
    let management_company_name = this.filterData(management_company, "name");
    let nameValue = isHOA
      ? this.filterData(row, "name")
      : management_company_name;
    let address = this.filterData(data, "physical_street_no_and_name");
    let cityStateZip = data.map((x) => {
      return helpers.formatAddress(
        x,
        config.generalInfo.addressFields,
        config.generalInfo.extraField
      );
    });
    let paymentAddress = this.filterData(data, "payment_mailing_street");
    let paymentcityStateZip = data.map((x) => {
      return helpers.formatAddress(
        x,
        config.payment_generalInfo.addressFields,
        config.payment_generalInfo.extraField
      );
    });
    let invoice_recovery=this.filterData(data,"invoice_recovery");
    let fy_start = this.filterData(data, "fy_start");
    let assessment_amount = this.filterData(data, "assessment_amount");
    let assessment_frequency = this.filterData(data, "periodicity");
    let account = this.filterData(data, "property_hoa_account");
    let status = this.filterData(data, "status");
    let hoa_website = this.filterData(data, "website_address");
    let payment_website = this.filterData(data, "payment_website");
    let hoas_properties = this.filterData(data, "hoas_properties");
    let good_through_date = this.filterData(data, "good_through_date");

    if (isHOA) {
      data = row;
    } else {
      // let poc1 = this.fetchPocData(management_company, management_poc1_id);
      // console.log(poc1);
    }
    const getAssessment_type = (id, assessment_types) => {
      return (
        assessment_types &&
        assessment_types.map((assessment_type, index) => {
          if (assessment_type.id === id) return assessment_type.name;
        })
      );
    };

    let width;
    if (data.length === 4) width = "105%";
    else if (data.length === 3) width = "100%";
    else if (data.length === 2) width = "70%";
    else width = "40%";
    return (
      <div>
        <table
          className="hoa-display-table"
          style={{ width: width, marginLeft: "-5rem" }}
        >
          <tbody>
            <tr>
              <th>
                {/* <div
                  style={{
                    fontWeight: "bold",
                  }}
                > */}
                {nameTitle}
                {/* </div> */}
              </th>
              <>{this.hoaNametableData(nameValue, isHOA, row)}</>
            </tr>

            <tr>
              <th>{"Address"}</th>
              {this.tableData(address)}
            </tr>
            <tr>
              <th>{"City,State,Zip"}</th>
              {this.tableData(cityStateZip)}
            </tr>
            {isHOA && (
              <>
                <tr>
                  <th>{"Status"}</th>
                  {this.tableData(status)}
                </tr>
                <tr>
                  <th>{"HOA Website"}</th>
                  {this.tableData(hoa_website, "HOA Website")}
                </tr>
                <tr>
                  <th>{"User Name"}</th>
                  {this.hoasPropertiestableData(hoas_properties, "username")}
                </tr>
                <tr>
                  <th>{"Password"}</th>
                  {this.hoasPropertiestableData(hoas_properties, "password")}
                </tr>
              </>
            )}

            <br />
            {isHOA && (
              <>
               <tr>
                  <th>{"Invoice Recovery"}</th>
                  {this.tableData(invoice_recovery)}
                </tr>
                <tr>
                  <th>{"FY Start"}</th>
                  {this.tableData(fy_start)}
                </tr>
                <tr>
                  <th>{"Assessment Amount"}</th>
                  {this.tableData(assessment_amount)}
                </tr>
                <tr>
                  <th>{"Periodicity"}</th>
                  {this.tableData(assessment_frequency, "Periodicity")}
                </tr>
                {/* {data.good_through_date &&
                  this.nameValueDiv(
                    "Good Through Date",
                    helpers.formatDate(data.good_through_date)
                  )} */}
                <tr>
                  <th>{"Good Through Date"}</th>

                  {this.tableData(good_through_date, "Good Through Date")}
                </tr>
                <div
                  className="acc-number"
                  onClick={() => {
                    this.setState({
                      hoaID: row.id,
                      accountModalOpen: true,
                      property_hoa_account: row.property_hoa_account,
                      assessment_types: row.assessment_types,
                      hoas_properties: row.hoas_properties,
                      website_address: row.website_address,
                      payment_website: row.payment_website,
                      chimney_last_inspection_date:
                        row.chimney_last_inspection_date,
                      dryer_last_inspection_date:
                        row.dryer_last_inspection_date,
                      fire_last_inspection_date: row.fire_last_inspection_date,
                      hvac_last_inspection_date: row.hvac_last_inspection_date,
                      hoa_inspection_categories: row.hoa_inspection_categories,
                    });
                  }}
                >
                  {" "}
                </div>
                <tr>
                  <th> {"Account Number "}</th>
                  {this.accountTableData(account, row)}
                </tr>
                <tr>
                  <th>{"Payment Address"}</th>
                  {this.tableData(paymentAddress)}
                </tr>
                <tr>
                  <th>{"City,State,Zip"}</th>
                  {this.tableData(paymentcityStateZip)}
                </tr>{" "}
                <tr>
                  <th>{"Payment Website"}</th>
                  {this.tableData(payment_website, "Payment Website")}
                </tr>
                <tr>
                  <th>{"User Name"}</th>
                  {this.hoasPropertiestableData(
                    hoas_properties,
                    "payment_username"
                  )}
                </tr>
                <tr>
                  <th>{"Password"}</th>
                  {this.hoasPropertiestableData(
                    hoas_properties,
                    "payment_password"
                  )}
                </tr>
              </>
            )}
            {/* <br /> */}

            {!isHOA && (
              <>
                <tr>
                  <th>{"POC 1 Name"}</th>
                  {this.pocTable(management_poc1, "name")}
                </tr>
                <tr>
                  <th>{"Title"}</th>
                  {this.pocTable(management_poc1, "title")}
                </tr>
                <tr>
                  <th>{"Phone"}</th>
                  {this.pocTable(management_poc1, "phone")}
                </tr>
                <tr>
                  <th>{"Email"}</th>
                  {this.pocTable(management_poc1, "email")}
                </tr>
              </>
            )}
            {!isHOA && (
              <>
                <tr>
                  <th>{"POC 2 Name"}</th>
                  {this.pocTable(management_poc2, "name")}
                </tr>
                <tr>
                  <th>{"Title"}</th>
                  {this.pocTable(management_poc2, "title")}
                </tr>
                <tr>
                  <th>{"Phone"}</th>
                  {this.pocTable(management_poc2, "phone")}
                </tr>
                <tr>
                  <th>{"Email"}</th>
                  {this.pocTable(management_poc2, "email")}
                </tr>
              </>
            )}
            {!isHOA && (
              <>
                <tr>
                  <th>{"POC 3 Name"}</th>
                  {this.pocTable(management_poc3, "name")}
                </tr>
                <tr>
                  <th>{"Title"}</th>
                  {this.pocTable(management_poc3, "title")}
                </tr>
                <tr>
                  <th>{"Phone"}</th>
                  {this.pocTable(management_poc3, "phone")}
                </tr>
                <tr>
                  <th>{"Email"}</th>
                  {this.pocTable(management_poc3, "email")}
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  toggleConfirmBox = () =>
    this.setState({ toggleConfirmBox: !this.state.toggleConfirmBox });

  toggleAccountModal = () =>
    this.setState({ accountModalOpen: !this.state.accountModalOpen });

  columns = () => {
    return [
      {
        dataField: "name",
        text: "HOA Information",
        formatter: this.hoaInfo,
        classes: "increase-width",
      },
      // {
      //   dataField: "name",
      //   text: "Management Company",
      //   formatter: this.companyInfo,
      //   classes: "increase-width",
      // },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.buttonFormatter,
        headerAttrs: { width: 200 },
        classes: "reduce-width",
        attrs: { width: 200, class: "table-actions" },
      },
    ];
  };

  render() {
    let columns = this.columns();
    let pocArray =
      this.state.row.management_company &&
      this.state.row.management_company.point_of_contacts;
    const renderHoas = () => {
      if (
        this.state.hoas !== null &&
        typeof this.state.hoas !== "undefined" &&
        Object.keys(this.state.hoas).length !== 0
      ) {
        let hoas = this.state.hoas;
        return (
          <React.Fragment>
            {this.state.isLoading ? (
              <Loader />
            ) : (
              <div className="hoa-details">
                {!config["generalInfoRead"].includes(this.props.userRole) && (
                  <div className="mutli-select">
                    <AsyncMultiSelect
                      isHoa={true}
                      propertyid={this.state.propertyid}
                      getActions={this.props.getActions}
                      loadHoaList={this.loadHoas}
                    />
                  </div>
                )}
                {/* <BootstrapTable keyField='id' data={hoas} columns={columns} /> */}
                {/* <PaginationComponent
                  columns={columns}
                  rows={hoas}
                  handleSubmit={this.state.refreshList}
                  pageNo={this.state.pageNo}
                  totalCount={this.state.totalCount}
                /> */}
                {this.loadCell(true, hoas)}
                <br />
                {this.loadCell(false, hoas)}
              </div>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {this.state.isLoading ? (
              <Loader />
            ) : (
              <div className="hoa-details">
                HOA doesn't exist
                {!config["generalInfoRead"].includes(this.props.userRole) && (
                  <div className="multi-select">
                    <AsyncMultiSelect
                      isHoa={true}
                      propertyid={this.state.propertyid}
                      getActions={this.props.getActions}
                      loadHoaList={this.loadHoas}
                    />
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        );
      }
    };
    return (
      <React.Fragment>
        {config["showHoa"].includes(this.props.userRole) && (
          <Button
            className="create-hoa"
            onClick={() => this.setState({ openHoaModal: true, row: {} })}
          >
            Create HOA
          </Button>
        )}

        {renderHoas()}

        {this.state.openHoaModal && (
          <AssociationModal
            toggle={() => {
              this.setState({ openHoaModal: !this.state.openHoaModal });
              this.loadHoas();
            }}
            title="HOA"
            toggleConfirmBox={this.toggleConfirmBox}
            open={true}
            row={this.state.row}
            setRow={(row) => this.setState({ row })}
            isDisplayHOA={true}
            propertyid={this.state.propertyid}
          />
        )}

        {this.state.openMCModal && (
          <MgmtCoFormComponent
            toggle={() => {
              this.setState({ openMCModal: !this.state.openMCModal, row: {} });
              this.loadHoas();
            }}
            title="HOA"
            open={true}
            managementCompany={this.state.row.management_company}
            pocArray={pocArray ? pocArray : []}
          />
        )}

        {this.state.toggleConfirmBox && (
          <ConfirmBox
            onYesClick={() => {
              apicall
                .generatePaymentActions(this.state.row.id)
                .then(() => {
                  helpers.notify("Payment action created.");
                  this.toggleConfirmBox();
                })
                .catch((e) => console.log(e));
            }}
            onNoClick={this.toggleConfirmBox}
            heading={<span>Create payment action?</span>}
          >
            <h6>
              Due date before 60 days, create payment action for related
              properties?
            </h6>
          </ConfirmBox>
        )}
        {this.state.deleteModal && (
          <ConfirmBox
            onYesClick={() => {
              this.deleteRecord(this.state.row);
              this.setState({ deleteModal: false });
            }}
            onNoClick={() => this.setState({ deleteModal: false })}
            heading={
              <span>
                Are you sure you want to delete the HOA: {this.state.row.name}?
              </span>
            }
          >
            <div>
              <p>All HOA related details from properties will be deleted.</p>
            </div>
          </ConfirmBox>
        )}
        {this.state.accountModalOpen && (
          <AccountModal
            heading={<span>Property-Association Update</span>}
            onCancelClick={this.toggleAccountModal}
            account={this.state.property_hoa_account}
            website_address={this.state.website_address}
            payment_website={this.state.payment_website}
            username={this.state.hoas_properties.username}
            password={this.state.hoas_properties.password}
            propertyid={this.props.propertyid}
            chimney_last_inspection_date={
              this.state.chimney_last_inspection_date
            }
            dryer_last_inspection_date={this.state.dryer_last_inspection_date}
            fire_last_inspection_date={this.state.fire_last_inspection_date}
            hvac_last_inspection_date={this.state.hvac_last_inspection_date}
            hoaId={this.state.hoaID}
            refreshList={this.loadHoas}
            assessment_types={this.state.assessment_types}
            assessment_type_id={
              this.state.hoas_properties["assessment_type_id"]
            }
            payment_website_username={
              this.state.hoas_properties.payment_username
            }
            payment_website_password={
              this.state.hoas_properties.payment_password
            }
            hoa_inspection_categories={this.state.hoa_inspection_categories}
          ></AccountModal>
        )}
      </React.Fragment>
    );
  }
}
