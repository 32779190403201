import React, { Component, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/page_layout_components/Login";
import PrivateRoute from "./components/helper_components/PrivateRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropertyUploadComponent from "./components/properties/property-upload/PropertyUploadComponent";
import PropertyManageComponent from "./components/properties/property-manage/PropertyManageComponent";
import ActionStatusUploadContainer from "./components/properties/action-status-upload/ActionStatusUploadContainer";
import CreateAnalystComponent from "./components/analysts/CreateAnalystComponent";
import DashboardContainer from "./components/homepage/DashboardContainer";
import Reports from "./components/reports/Reports";
import AreaAnalystsContainer from "./components/area_analysts/AreaAnalystsContainer";
import SidebarComponent from "./components/page_layout_components/SidebarComponent";
import NavbarComponent from "./components/page_layout_components/NavbarComponent";
import apicall from "./utils/apicall";
import "./App.css";
import PropertySearchComponent from "./components/properties/property-search/PropertySearchComponent";
import ManagmentCompaniesComponent from "./components/management_companies/ManagementCompaniesComponent";
import HoasComponent from "./components/hoas/HoasComponent";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import GeneralInformation from "./components/EmailLinkAccess/GeneralInformation";
import config from "./utils/config";
import GuestUserNavbar from "./components/page_layout_components/GuestUserNavbar";
import DisplayGuestProperty from "./components/general_info/guest_property_info/DisplayGuestPropert";
import ManagementCompanySearch from "./components/management_companies/ManagementCompanySearch";
import HoaSearchComponent from "./components/hoas/HoaSearchComponent";
import PropertyGlobalSearchComponent from "./components/properties/property-search/PropertyGlobalSearchComponent";
import GuestUserReports from "./components/reports/GuestUserReports";
import GuestUserDocuments from "./components/documents/GuestUserDocuments";
import PropertyGuestSearch from "./components/properties/property-search/PropertyGuestSearch";
import MunicipalComponent from "./components/Municipal/MunicipalComponent";
import BusinessUnitComponent from "./components/BusinessUnit/BusinessUnitComponent";
import LawFirmComponent from "./components/LawFirm/LawFirmComponent";
import VendorComponent from "./components/vendor/VendorComponent";
import OrdersComponent from "./components/orders/OrdersComponent";
import VendorNavbar from "./components/page_layout_components/VendorNavbar";
import MunicipalityUserNavbar from "./components/page_layout_components/MunicipalityUserNavbar";
import ThankYouPage from "./components/CodeViolationThankYou/ThankYouPage";
import MunicipalitySearchComponent from "./components/Municipal/MunicipalitySearchComponent";
import IssueResolvedConfirmationPage from "./components/CodeViolationThankYou/IssueResolvedConfirmationPage";
import PaymentCheckNumberFileUpload from "./components/payments/PaymentCheckNumberFileUpload";
import CaseNumberSearchComponent from "./components/CodeViolation/CaseNumberSearchComponent";

const RouteWithSidebar = ({ component: Component, ...props }) => {
  console.log("The props inside navbar is", props);
  return (
    <PrivateRoute
      {...props}
      component={(routeProps) => (
        <>
          <NavbarComponent {...routeProps} {...props} />
          <SidebarComponent />
          <div className="componentDiv">
            <Component {...routeProps} {...props} />
          </div>
        </>
      )}
    />
  );
};

const RouteWithNavbar = ({ component: Component, ...props }) => {
  console.log("The props inside navbar is", props);
  return (
    <PrivateRoute
      {...props}
      component={(routeProps) => (
        <>
          <div className="componentDiv">
            <Component {...routeProps} {...props} />
          </div>
        </>
      )}
    />
  );
};

const RouteWithMunicipalityNavbar = ({ component: Component, ...props }) => {
  return (
    <PrivateRoute
      {...props}
      component={(routeProps) => (
        <>
          <div className="componentDiv">
            <Component {...routeProps} {...props} />
          </div>
        </>
      )}
    />
  );
};
const MINUTES_UNITL_AUTO_LOGOUT = 120; // in mins
const CHECK_INTERVAL = 15000; // in ms
const STORE_KEY = "lastAction";

class App extends Component {
  constructor(props) {
    super(props);
    this.check();
    this.initListener();
    this.initInterval();
  }

  setLastAction(lastAction) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  initListener() {
    document.body.addEventListener("click", () => this.reset());
    document.body.addEventListener("mouseover", () => this.reset());
    document.body.addEventListener("mouseout", () => this.reset());
    document.body.addEventListener("keydown", () => this.reset());
    document.body.addEventListener("keyup", () => this.reset());
    document.body.addEventListener("keypress", () => this.reset());
  }

  initInterval() {
    setInterval(() => {
      this.checkDebounce();
    }, CHECK_INTERVAL);
  }

  reset() {
    this.setLastAction(Date.now());
  }
  getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  notify = () => toast("Session timeout!!! Please login again");

  checkDebounce = () => AwesomeDebouncePromise(this.check, CHECK_INTERVAL * 2);

  check() {
    const now = Date.now();
    const timeleft =
      this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout && !this.isPublicUrl()) {
      this.notify();
      apicall.logout();
    }
  }

  isPublicUrl = () => {
    let publicURL = ["/thankyou/", "/issue-resolved-confirmation/"];
    let currentURL = window.location.pathname;
    for (let i = 0; i < publicURL.length; i++) {
      if (currentURL.includes(publicURL[i])){
        return true;
      } else {
        if(i ===  publicURL.length-1) return false;
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <RouteWithSidebar path="/homepage" component={DashboardContainer} />
          <RouteWithSidebar
            path="/property-upload"
            component={PropertyUploadComponent}
          />
          <RouteWithSidebar
            path="/payment-upload"
            component={PaymentCheckNumberFileUpload}
          />
          <RouteWithSidebar
            path="/action-status-upload"
            component={ActionStatusUploadContainer}
          />
          <RouteWithSidebar path="/users" component={CreateAnalystComponent} />
          <RouteWithSidebar
            path="/area-analysts"
            component={AreaAnalystsContainer}
          />
          <RouteWithSidebar
            exact
            path="/property-manage/"
            component={PropertyManageComponent}
          />
          <RouteWithSidebar
            path="/property-manage/:activeTab/:propertyID/:hoaID"
            component={PropertyManageComponent}
          />
          <RouteWithSidebar
            exact
            path="/property-search/:searchText/:page/:perPage"
            component={PropertySearchComponent}
          />
          <RouteWithSidebar
            path="/property-search"
            component={PropertySearchComponent}
          />
          <RouteWithSidebar
            path="/property/global/:searchText/:user_role"
            component={PropertyGlobalSearchComponent}
          />
          <RouteWithSidebar
            path="/management-companies"
            component={ManagmentCompaniesComponent}
          />
          <RouteWithSidebar
            exact
            path="/management/global/:searchText/:user_role"
            component={ManagementCompanySearch}
          />
          <RouteWithSidebar path="/hoas" component={HoasComponent} />
          <RouteWithSidebar
            exact
            path="/hoa/global/:searchText/:user_role"
            component={HoaSearchComponent}
          />
          <RouteWithSidebar
            exact
            path="/municipality/global/:searchText/:user_role"
            component={MunicipalitySearchComponent}
          />
          <RouteWithSidebar
            exact
            path="/case_number/global/:searchText/:user_role"
            component={CaseNumberSearchComponent}
          />
          <RouteWithSidebar path="/reports" component={Reports} />
          <RouteWithSidebar path="/municipal" component={MunicipalComponent} />
          <RouteWithSidebar
            path="/business-units"
            component={BusinessUnitComponent}
          />
          <RouteWithSidebar path="/law-firm" component={LawFirmComponent} />
          <RouteWithSidebar
            path="/vendor-creation"
            component={VendorComponent}
          />
          <RouteWithSidebar path="/title-order" component={OrdersComponent} />
          <Route path="/general-info" component={GeneralInformation} />

          <RouteWithNavbar path="/guest" component={GuestUserNavbar} />
          <RouteWithNavbar
            path="/guest-property-manage/:propertyID/"
            component={DisplayGuestProperty}
          />
          <RouteWithNavbar
            path="/guest-reports/:propertyID/"
            component={GuestUserReports}
          />
          <RouteWithNavbar
            path="/guest-reports"
            component={GuestUserReports}
          />
          <RouteWithNavbar
            path="/guest-documents/:propertyID/"
            component={GuestUserDocuments}
          />
          <RouteWithNavbar
            exact
            path="/guest-property-search/:searchText"
            component={PropertyGuestSearch}
          />
          <RouteWithNavbar path="/vendor" component={VendorNavbar} />
          <RouteWithMunicipalityNavbar
            path="/municipality-user"
            component={MunicipalityUserNavbar}
          />
          <Route
            path="/thankyou/:propertyID/:cv_id/:url_token"
            component={ThankYouPage}
          />
          <Route
            path="/issue-resolved-confirmation/:propertyID/:cv_id/:url_token"
            component={IssueResolvedConfirmationPage}
          />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;
