import React, { Component } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import ListTable from "./ListTable";
import apicall from "../../utils/apicall";
import LoaderComponent from "../helper_components/LoaderComponent";
import config from "../../utils/config";
export default class ManagmentCompaniesComponent extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      managementCompanies: [],
      isLoading: true,
      searchBoxText: "",
      pageNo: 1,
      userRole: "",
      form_inputs: { sort: {} },
    };
  }

  componentWillMount() {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        this.setState({ userRole: role });
      })
      .catch((e) => console.log(e));
    this.getList(1);
  }

  getList = (event, pageNo, perPage) => {
    this.setState({ isLoading: true });
    console.log("getlist", perPage);
    apicall
      .getManagementCompanies(this.state.form_inputs, pageNo, perPage)
      .then((response) => {
        this.setState({
          managementCompanies: response.results,
          isLoading: false,
          totalCount: response.total,
          pageNo,
          perPage,
        });
      });
  };

  handleChange = (event) => {
    this.setState({
      searchBoxText: event.target.value,
    });
  };

  handleSubmitForSearch = (event, role, pageNo, perPage) => {
    console.log("submit", perPage);
    this.setState({ isLoading: true });
    this.setState({
      pageNo: typeof pageNo !== "undefined" ? pageNo : "1",
      perPage: typeof pageNo !== "undefined" ? perPage : "10",
    });

    let inputValue = this.state.searchBoxText;
    apicall
      .searchManagementCompanies(
        this.state.form_inputs,
        inputValue,
        null,
        pageNo,
        perPage
      )
      .then((response) => {
        this.setState({
          managementCompanies: response.results,
          isLoading: false,
          totalCount: response.total,
          pageNo,
        });
      });
    event.preventDefault();
  };

  handleNextPrviousAction = (event, pageNo, perPage) => {
    if (this.state.searchBoxText == "") {
      this.getList(event, pageNo, perPage);
    } else {
      this.handleSubmitForSearch(event, null, pageNo, perPage);
    }
  };

  sortColumns = (response) => {
    this.setState({
      form_inputs: Object.assign(this.state.form_inputs, { sort: response }),
    });
    setTimeout(() => {
      this.getList();
    }, 1000);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <LoaderComponent />}
        <section className="content">
          <header className="content-title ">
            <h2 className="page-heading">
              <strong>Management Companies</strong>
            </h2>
          </header>
          <ListTable
            managementCompanies={this.state.managementCompanies}
            refreshList={this.handleNextPrviousAction}
            pageNo={this.state.pageNo}
            perPage={this.state.perPage}
            totalCount={this.state.totalCount}
            userRole={this.state.userRole}
            sortColumns={this.sortColumns}
          />
        </section>
      </React.Fragment>
    );
  }
}
