import React, { useState, useEffect } from "react";
import apicall from "../../utils/apicall";
import _ from "lodash";
import { Link } from "react-router-dom";
import PaginationComponent from "../helper_components/PaginationComponent";
import LienModal from "../lien/LienModal";
import InspectionModal from "../inspection/InspectionModal";
import ViolationModal from "../violations/ViolationModal";
import PaymentModal from "../payments/paymentModal";
import helpers from "../../utils/helpers";
import AssociationVerificationModal from "../AssociationVerification/AssociationVerificationModal";
import PendingStatsDashboard from "./PendingStatsDashboard";
import AssociationModal from "../hoas/AssocaitionModal";
import dayjs from "dayjs";
import Loader from "../helper_components/LoaderComponent";
import config from "../../utils/config";

export default function AssociationDashboard(props) {
  const [loading, set_loading] = useState(false);
  const [toggleModal, settoggleModal] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  const [total, set_total] = useState();
  const [modal_inputs, set_modal_inputs] = useState({
    associationverificationModal: false,
    lienModal: false,
    inspectionModal: false,
    violationModal: false,
    paymentModal: false,
    associationinformationModal: false,
  });
  const [row, set_row] = useState();

  const [isHoaTable, setisHoaTable] = useState(false);
  const [hoaModal, sethoaModal] = useState(false);
  const [hoaFilterName, sethoaFilterName] = useState();
  const [isPropertyTable, setisPropertyTable] = useState(false);
  const [isPaymentTable, setisPaymentTable] = useState(false);
  const [tierOnefilter, settierOnefilter] = useState();
  const [tierTwofilter, settierTwofilter] = useState();
  const [tierThreefilter, settierThreefilter] = useState();
  const [tierFourfilter, settierFourfilter] = useState();

  const [pageNumber, setpageNumber] = useState();
  const [perPage, setperPage] = useState();
  const [pendingDashboardFilter, setpendingDashboardFilter] = useState();
  const [dashboardModal, setdashboardModal] = useState(false);
  const [userRole, setuserRole] = useState("");
  const [paymentRightSideValue, setpaymentRightSideValue] = useState("");
  const [table_data, set_table_data] = useState();
  const [fyAsssessmentData, setFyAssessmentData] = React.useState(false);

  useEffect(() => {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        setuserRole(role);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getDashboardPendingStats();
  }, [
    tierOnefilter,
    tierTwofilter,
    tierThreefilter,
    tierFourfilter,
    paymentRightSideValue,
    form_inputs.sort,
  ]);
  const getDashboardPendingStats = () => {
    set_loading(true);

    apicall
      .getDashboardPendingStats(
        form_inputs,
        tierOnefilter,
        tierTwofilter,
        tierThreefilter,
        tierFourfilter,
        paymentRightSideValue,
        pageNumber,
        perPage
      )
      .then((response) => {
        setpendingDashboardFilter(response.results.data);
        setdashboardModal(true);
        settoggleModal(true);

        if (!_.isEmpty(response.results.records.results)) {
          set_table_data(response.results.records.results);
          set_total(response.results.records.total);
        } else {
          set_table_data("");
          set_total();
        }
        if( tierOnefilter === "fy_assessment") {
          setFyAssessmentData(true)
        }
        tierOnefilter === "account_review" ||
        tierOnefilter === "fy_assessment" ||
        tierOnefilter === "general_payment"
          ? setisPaymentTable(true)
          : setisPaymentTable(false);

        tierOnefilter === "association_review"
          ? setisHoaTable(true)
          : setisHoaTable(false);
        tierOnefilter === "property_review"
          ? setisPropertyTable(true)
          : setisPropertyTable(false);

        set_loading(false);
      })
      .catch((e) => set_loading(false));
  };

  const filterName = (response) => {
    sethoaFilterName(response);
  };

  const filterPaymentRightSideValue = (response) => {
    setpaymentRightSideValue(response);
  };

  const filterTierOne = (response) => {
    settierOnefilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierTwo = (response) => {
    settierTwofilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierThree = (response) => {
    settierThreefilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierFour = (response) => {
    settierFourfilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const handleSubmit = (event, page, perPage) => {
    set_loading(true);
    apicall
      .getDashboardPendingStats(
        form_inputs,
        tierOnefilter,
        tierTwofilter,
        tierThreefilter,
        tierFourfilter,
        paymentRightSideValue,
        page,
        perPage
      )
      .then((response) => {
        tierOnefilter === "association_review"
          ? setisHoaTable(true)
          : setisHoaTable(false);
        tierOnefilter === "property_review"
          ? setisPropertyTable(true)
          : setisPropertyTable(false);
        tierOnefilter === "account_review" ||
        tierOnefilter === "fy_assessment" ||
        tierOnefilter === "general_payment"
          ? setisPaymentTable(true)
          : setisPaymentTable(false);

        if (tierOnefilter === "fy_assessment") {
          setFyAssessmentData(true)
        }
        set_table_data(response.results.records.results);
        set_total(response.results.records.total);

        setpageNumber(page);
        setperPage(perPage);
        set_loading(false);
      });
  };

  const actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      !_.isEmpty(cell) &&
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
      // && cell.trim().toLowerCase() !== "associationverification"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              let modalName = cell.trim().toLowerCase() + "Modal";
              set_modal_inputs({
                ...modal_inputs,
                [modalName]: !modal_inputs[modalName],
              });
              set_row(row);
            }}
          >
            {cell}
          </div>
        </React.Fragment>
      );
    }
    let tabNo =
      !_.isEmpty(cell) && cell.trim().toLowerCase() === "documentinforeport"
        ? 5
        : null;
    tabNo = !_.isEmpty(cell) && cell.trim().toLowerCase() === "pir" ? 1 : tabNo;
    if (tabNo !== null) {
      return (
        <Link
          className="link"
          to={"/property-manage/" + tabNo + "/" + row.property_id}
          target="_blank"
        >
          {cell}
        </Link>
      );
    }
    return cell;
  };

  const paymentActionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      !_.isEmpty(cell) &&
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
      // && cell.trim().toLowerCase() !== "associationverification"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              let modalName = "paymentModal";
              set_modal_inputs({
                ...modal_inputs,
                [modalName]: !modal_inputs[modalName],
              });
              set_row(row);
            }}
          >
            {cell}
          </div>
        </React.Fragment>
      );
    }
    let tabNo =
      !_.isEmpty(cell) && cell.trim().toLowerCase() === "documentinforeport"
        ? 5
        : null;
    tabNo = !_.isEmpty(cell) && cell.trim().toLowerCase() === "pir" ? 1 : tabNo;
    if (tabNo !== null) {
      return (
        <Link
          className="link"
          to={"/property-manage/" + tabNo + "/" + row.property_id}
          target="_blank"
        >
          {cell}
        </Link>
      );
    }
    return cell;
  };

  const linkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/3/" + row.property_id + "/" + row.hoa_id}
          target="_blank"
          key={cell + row.id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const propertyLinkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/1/" + row.id + "/" + null}
          target="_blank"
          key={cell + row.id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const notesFormatter = (cell, row) => {
    let notesArr = !_.isEmpty(cell) && cell.split(/\n/g);
    let notes =
      _.isArray(notesArr) &&
      notesArr
        .filter((x) => x !== "")
        .map((x, ind) => {
          return <p key={x}>{x}</p>;
        });
    return (
      <>
        <p>{notes}</p>
      </>
    );
  };

  const hoaTableActionTypeFormatter = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    return (
      <React.Fragment>
        <div
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            sethoaModal(true);
            set_row(row);
            if (tierTwofilter === "general_info") setactiveTab(1);
            else if (tierTwofilter === "leasing") setactiveTab(2);
            else if (tierTwofilter === "amenity") setactiveTab(3);
            else if (tierTwofilter === "access_control") setactiveTab(4);
            else if (tierTwofilter === "inspection") setactiveTab(5);
          }}
        >
          {cell}
        </div>
      </React.Fragment>
    );
  };

  const propertyReviewStatusFormatter = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    return (
      <div className="propertyReviewStatusMainDiv">
        <div
          className="propertyReviewStatus"
          style={{
            backgroundColor: row.pending_account_number ? "red" : "green",
          }}
        ></div>
        <div
          className="propertyReviewStatus"
          style={{
            backgroundColor: row.pending_username_password ? "red" : "green",
          }}
        ></div>
        <div
          className="propertyReviewStatus"
          style={{
            backgroundColor: row.pending_assessment_description
              ? "red"
              : "green",
          }}
        ></div>
        <div
          className="propertyReviewStatus"
          style={{
            backgroundColor: row.pending_inspection_dates ? "red" : "green",
          }}
        ></div>
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (hoaFilterName === "association_review" || hoaFilterName === "utility") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.gen_info_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.leasing_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.amenities_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.access_controll_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.inspections_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.utility_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.assessments_pending ? "red" : "green",
            }}
          ></div>

          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.documents_pending ? "red" : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "general_info") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.status_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.management_company_pending ? "red" : "green",
            }}
          ></div>

          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.physical_address_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.local_mailing_address_pending
                ? "red"
                : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.payment_address_pending ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.architectural_review_pending
                ? "red"
                : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "leasing") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_first_question === null
                  ? "white"
                  : row.pending_first_question
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_followup_question === null
                  ? "white"
                  : row.pending_followup_question
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_misc_question === null
                  ? "white"
                  : row.pending_misc_question
                  ? "red"
                  : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "assessment") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_assessment_description
                ? "red"
                : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_charge ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_fy_start ? "red" : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "access_control") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_building_access_codes === null
                  ? "white"
                  : row.pending_building_access_codes
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_pool_access_codes === null
                  ? "white"
                  : row.pending_pool_access_codes
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_fitness_access_codes === null
                  ? "white"
                  : row.pending_fitness_access_codes
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_tennis_access_codes === null
                  ? "white"
                  : row.pending_tennis_access_codes
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_club_house_access_codes === null
                  ? "white"
                  : row.pending_club_house_access_codes
                  ? "red"
                  : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "access_control_building") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_access_controlled === null
                  ? "white"
                  : row.pending_access_controlled
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_access_questions === null
                  ? "white"
                  : row.pending_access_questions
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_access_creation === null
                  ? "white"
                  : row.pending_access_creation
                  ? "red"
                  : "green",
            }}
          ></div>
        </div>
      );
    } else if (
      hoaFilterName === "access_control_pool" ||
      hoaFilterName === "access_control_fitness" ||
      hoaFilterName === "access_control_tennis" ||
      hoaFilterName === "access_control_club_house"
    ) {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_access_controlled === null
                  ? "white"
                  : row.pending_access_controlled
                  ? "red"
                  : "green",
            }}
          ></div>

          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_access_creation === null
                  ? "white"
                  : row.pending_access_creation
                  ? "red"
                  : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "inspection") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_inspection === null
                  ? "white"
                  : row.pending_inspection || row.pending_inspections
                  ? "red"
                  : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor:
                row.pending_periodicity === null
                  ? "white"
                  : row.pending_periodicity
                  ? "red"
                  : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "amenity") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_pool ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_fitness ? "red" : "green",
            }}
          ></div>{" "}
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_tennis ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_club_house ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_golf_court ? "red" : "green",
            }}
          ></div>
        </div>
      );
    } else if (hoaFilterName === "document") {
      return (
        <div className="hoaTableStatusMainDiv">
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_ccrs ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_by_laws ? "red" : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_architectural_guidelines
                ? "red"
                : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_tenant_registration_form
                ? "red"
                : "green",
            }}
          ></div>

          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_move_in_checklist_form
                ? "red"
                : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_parking_permit_form
                ? "red"
                : "green",
            }}
          ></div>
          <div
            className="hoaTableStatus"
            style={{
              backgroundColor: row.pending_pool_registration_form
                ? "red"
                : "green",
            }}
          ></div>
        </div>
      );
    }
  };

  const dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  const columns = () => {
    return [
      {
        dataField: "clientid",
        text: "Client ID",
        formatter: linkFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };

          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "actionable_type",
        text: "Action Type",
        formatter: actionTypeFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "full_address",
        text: "Address",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        },
      },
      {
        dataField: "zip",
        text: "Zip",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        headerStyle: (colum, colIndex) => {
          return { width: "8%" };
        },
      },
      {
        dataField: "age",
        text: "Age in days",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { age_in_days: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "last_updated",
        text: "Last Updated",
        headerStyle: (colum, colIndex) => {
          return { width: "9%" };
        },
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        formatter: dateFormatter,
      },
      {
        dataField: "due_date",
        text: "Due Date",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        formatter: dateFormatter,
      },
      {
        dataField: "current_status",
        text: "Status",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
      },
      {
        dataField: "notes",
        text: "Notes",
        classes: "notes-width",
        formatter: notesFormatter,
        headerClasses: "table-header-cell-alignment",
      },
    ];
  };

  const paymentColumns = () => {
    return [
      {
        dataField: "clientid",
        text: "Client ID",
        formatter: linkFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "description",
        text: "Description",
        formatter: paymentActionTypeFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: "14%" };
        },
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "full_address",
        text: "Address",
        classes: "address-width",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        },
      },
      {
        dataField: "zip",
        text: "Zip",
        headerStyle: (colum, colIndex) => {
          return { width: "8%" };
        },
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "age",
        text: "Age in days",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "10%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { age_in_days: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "last_updated",
        text: "Last Updated",
        headerStyle: (colum, colIndex) => {
          return { width: "10%" };
        },
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        formatter: dateFormatter,
      },
      {
        dataField: "due_date",
        text: "Due Date",
        headerStyle: (colum, colIndex) => {
          return { width: "9%" };
        },
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        formatter: dateFormatter,
      },
      {
        dataField: "current_status",
        text: "Status",
        //classes: "reduce-width",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "notes",
        text: "Notes",
        classes: "notes-width",
        formatter: notesFormatter,
        headerClasses: "table-header-cell-alignment",
      },
    ];
  };

  const propertyReviewColumns = () => {
    return [
      {
        dataField: "clientid",
        text: "Client ID",
        formatter: propertyLinkFormatter,
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "entity_name",
        text: "Enity Name",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "full_address",
        text: "Address",
        classes: "address-width",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        },
      },
      {
        dataField: "zip",
        text: "Zip",
        headerStyle: (colum, colIndex) => {
          return { width: "8%" };
        },
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },

      {
        dataField: "acquisition_date",
        text: "Acquisition Date",
        classes: "reduce-width",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
        formatter: dateFormatter,
      },
      {
        dataField: "status",
        text: "Status",
        classes: "propertyReview-status-width",
        headerClasses: "table-header-cell-alignment",
        formatter: propertyReviewStatusFormatter,
      },
      {
        dataField: "notes",
        text: "Notes",
        classes: "notes-width",
        formatter: notesFormatter,
        headerClasses: "table-header-cell-alignment",
      },
    ];
  };

  const hoaColumns = () => {
    return [
      {
        dataField: "name",
        text: "HOA Name",
        classes: "hoaName-width",
        formatter: hoaTableActionTypeFormatter,
        headerClasses: "table-header-cell-alignment",

        sort: true,
        onSort: (a, b) => {
          let obj = { hoa_name: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },
      {
        dataField: "full_address",
        text: "Address",
        classes: "hoa-address-width",
        headerClasses: "table-header-cell-alignment",
      },
      {
        dataField: "zip",
        text: "Zip",
        classes: "hoa-zip-width",
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "5%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { hoa_zip: b };
          set_form_inputs({ ...form_inputs, sort: obj });
        },
      },

      {
        dataField: "status",
        text: "Status",
        classes: "hoa-status-width",
        headerClasses: "table-header-cell-alignment",
        formatter: statusFormatter,
      },
    ];
  };

  const dashboardTable = () => {
    let hoaProperties =
      table_data &&
      table_data.map((x) => {
        const full_address = [
          x.physical_street_no_and_name,
          x.physical_city,
          x.physical_state,
        ].join(", ");

        let diff;
        if (x.created_at) diff = dayjs().diff(dayjs(x.created_at), "day");
        const age = diff && diff < 0 ? 0 : diff;
        let clientid = x.id;
        let zip = x.physical_zip;
        return { ...x, full_address, age, clientid, zip };
      });

    let properties =
      table_data &&
      table_data.map((x) => {
        const full_address = x.property
          ? [
              x.property && x.property.street_address,
              x.property.city,
              x.property.state,
            ].join(", ")
          : [
              x.physical_street_no_and_name,
              x.physical_city,
              x.physical_state,
            ].join(", ");
        let diff;
        if (x.created_at) diff = dayjs().diff(dayjs(x.created_at), "day");
        const age = diff && diff < 0 ? 0 : diff;
        let clientid = x.property ? x.property.clientid : "";
        let zip = x.property ? x.property.zip : x.physical_zip;
        return { ...x, full_address, age, clientid, zip };
      });

    let propertReviewProperties =
      table_data &&
      table_data.map((x) => {
        const full_address = [x.street_address, x.city, x.state].join(", ");
        let diff;
        if (x.created_at) diff = dayjs().diff(dayjs(x.created_at), "day");
        const age = diff && diff < 0 ? 0 : diff;
        let clientid = x.clientid;
        let zip = x.zip;
        return { ...x, full_address, age, clientid, zip };
      });

    let rowProperties;
    if (isHoaTable) rowProperties = hoaProperties;
    else if (isPropertyTable) rowProperties = propertReviewProperties;
    else rowProperties = properties;

    let tableColumns;
    if (isHoaTable) tableColumns = hoaColumns();
    else if (isPropertyTable) tableColumns = propertyReviewColumns();
    else if (isPaymentTable) tableColumns = paymentColumns();
    else tableColumns = columns();

    let newRowProperties = rowProperties.map((value) => {
      if (value.payment_charge_type !== null) {
        if(fyAsssessmentData && value.payment_charge_type && value.payment_charge_type.includes("FY")){
          value.description = "Assessment - "+value.payment_charge_type;
        }else{
          value.description = value.payment_charge_type;
        }  
      }
      return value;
    })

    return (
      <React.Fragment key={form_inputs.action_type}>
        <PaginationComponent
          columns={tableColumns}
          rows={newRowProperties}
          handleSubmit={handleSubmit}
          pageNo={pageNumber}
          perPage={perPage}
          totalCount={table_data && total}
        />

        {modal_inputs.associationverificationModal && (
          <AssociationVerificationModal
            toggle={() => {
              set_modal_inputs({
                ...modal_inputs,
                associationverificationModal: !modal_inputs.associationverificationModal,
              });
              getDashboardPendingStats();
            }}
            title="Association Verification"
            open={true}
            row={row}
            propertyID={row.property_id}
            actionID={row.actionable_id}
            avId={row.association_verification_info_id}
          />
        )}
        {modal_inputs.lienModal && (
          <LienModal
            toggle={() => {
              set_modal_inputs({
                ...modal_inputs,
                lienModal: !modal_inputs.lienModal,
              });
              getDashboardPendingStats();
            }}
            title="Lien"
            open={true}
            row={row}
            propertyID={row.property_id}
            actionID={row.actionable_id}
          />
        )}
        {modal_inputs.inspectionModal && (
          <InspectionModal
            toggle={() => {
              set_modal_inputs({
                ...modal_inputs,
                inspectionModal: !modal_inputs.inspectionModal,
              });
              getDashboardPendingStats();
            }}
            title="Inspection"
            open={true}
            row={row}
            propertyID={row.property_id}
            actionID={row.actionable_id}
          />
        )}
        {modal_inputs.violationModal && (
          <ViolationModal
            toggle={() => {
              set_modal_inputs({
                ...modal_inputs,
                violationModal: !modal_inputs.violationModal,
              });
              getDashboardPendingStats();
            }}
            title="Violation"
            open={true}
            row={row}
            propertyID={row.property_id}
            actionID={row.actionable_id}
          />
        )}
        {modal_inputs.paymentModal && (
          <PaymentModal
            toggle={() => {
              set_modal_inputs({
                ...modal_inputs,
                paymentModal: !modal_inputs.paymentModal,
              });
              getDashboardPendingStats();
            }}
            title="Payment"
            open={true}
            row={row}
            propertyID={row.property_id}
            actionID={row.actionable_id}
            dashboardProperty={true}
            userRole={userRole}
          />
        )}

        {hoaModal && (
          <AssociationModal
            toggle={() => {
              sethoaModal(!hoaModal);
              getDashboardPendingStats();
            }}
            title="HOA"
            open={true}
            row={row}
            propertyID={row.property_id}
            actionID={row.actionable_id}
            dashboardProperty={true}
            activeTab={activeTab}
          />
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {loading && <Loader />}
      {toggleModal && (
        <>
          {dashboardModal && (
            <PendingStatsDashboard
              filterName={filterName}
              filterTierOne={filterTierOne}
              filterTierTwo={filterTierTwo}
              filterTierThree={filterTierThree}
              filterTierFour={filterTierFour}
              perPage={perPage}
              pageNo={pageNumber}
              pendingDashboardFilterData={pendingDashboardFilter}
              filterPaymentRightSideValue={filterPaymentRightSideValue}
            />
          )}
        </>
      )}
      {table_data && dashboardTable()}
    </React.Fragment>
  );
}
