import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Col,
} from "reactstrap";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_createvendor.scss";
import helpers from "../../utils/helpers";
import _ from "lodash";
import "../../styles/_codeViolation.scss";
import DatePicker from "react-datepicker";
import ViolationFileUpload from "./ViolationFileUpload";
import HearingListComponent from "./HearingListComponent";
import LoaderComponent from "../helper_components/LoaderComponent";
import DocumentsTab from "../documents/DocumentsTab";
import config from "../../utils/config";
import { Linking } from "react-native";
import dayjs from "dayjs";
import NotesHistory from "./NotesHistory";

export default function CodeViolationModal(props) {
  const [row, setrow] = useState(props.row ? props.row : {});
  const [actionable_id, setactionable_id] = useState(props.actionID);
  const [openViolationFileUpload, setopenViolationFileUpload] = useState(false);
  const [openHearingList, setopenHearingList] = useState(false);
  const [originatorType, setoriginatorType] = useState({
    Association: "Association",
    Muni: "Muni",
  });
  const [isLoading, setisLoading] = useState(false);
  const [pocOptions, setpocOptions] = useState();
  const [responsibleBU, setresponsibleBU] = useState();
  const [businessUnitList, setbusinessUnitList] = useState([]);
  const [responsibleBUPoc, setresponsibleBUPoc] = useState([]);
  const [sendEmailInfo, setsendEmailInfo] = useState();
  const [urlToken, seturlToken] = useState(
    props.row ? props.row.url_token : ""
  );
  const [propertyInfo, setpropertyInfo] = useState();
  const [violationMunicipals, setviolationMunicipals] = useState([]);
  const [violationHoas, setviolationHoas] = useState([]);
  const [isSubmitDisabled, setisSubmitDisabled] = useState(true);
  const [rowNotes, setRowNotes] = React.useState([]);
  const [business_id, setbusiness_id] = useState();

  useEffect(() => {
    if (!_.isEmpty(props.row) && !_.isEmpty(props.row.title_documents))
      setisSubmitDisabled(false);
    if (!_.isEmpty(props.row) && props.row.originator === "Muni") {
      if (
        !_.isEmpty(props.row.associable) &&
        !_.isEmpty(props.row.associable.code_violation_pocs)
      ) {
        setpocOptions(props.row.associable.code_violation_pocs);
      }
    } else if (
      !_.isEmpty(props.row) &&
      props.row.originator === "Association"
    ) {
      if (
        !_.isEmpty(props.row.associable) &&
        !_.isEmpty(props.row.associable.code_violation_pocs)
      ) {
        setpocOptions(props.row.associable.code_violation_pocs);
      }
    }
  }, []);

  useEffect(() => {
    readCodeViolation();
    readResponsibleBU();
    listOfBusinessUnit();
  }, []);

  useEffect(() => {
    getPropertyInfo();
    fetchPaymentHoas();
  }, []);

  const toggleDocument = () => {
    setopenViolationFileUpload(!openViolationFileUpload);
  };

  const toggleHearingModal = () => {
    setopenHearingList(!openHearingList);
    readCodeViolation();
  };

  const fetchPaymentHoas = () => {
    apicall.fetchPaymentHoas(props.propertyID).then((response) => {
      setviolationMunicipals(response.Office);
      setviolationHoas(response.Hoa);
    });
  };
  const getPropertyInfo = () => {
    apicall.getProperty(props.propertyID).then((response) => {
      setpropertyInfo(response);
    });
  };

  const listOfBusinessUnit = () => {
    apicall
      .listOfBusinessUnitForCodeViolation()
      .then((response) => {
        if (!_.isEmpty(response.results)) {
          setbusinessUnitList(response.results);
        }
      })
      .catch("Error While fetching  BusinessUnit");
  };

  const readCodeViolation = (id) => {
    setisLoading(true);
    apicall
      .readCodeViolation(id ? id : actionable_id, props.propertyID)
      .then((response) => {
        setrow(response);
        setRowNotes(response.notes);
        setactionable_id(response.id);
        setisLoading(false);
        if (!_.isEmpty(response.title_documents)) setisSubmitDisabled(false);
      });
  };
  const readResponsibleBU = (id) => {
    setisLoading(true);
    if (_.isEmpty(row.business_unit)) {
      apicall.getResponsibleBU(props.propertyID).then((response) => {
        if (!_.isEmpty(response.results)) {
          setresponsibleBU(response.results);
          setresponsibleBUPoc(
            response.results.title_point_of_contacts.map((x) => x)
          );

          let x = { business_unit_id: response.results.id };
          setTimeout(() => {
            setrow(Object.assign({ ...row }, { ...x }));
            setbusiness_id(response.results.id);
          }, 3000);

          readResponsibleBUPOC(response.results.id, response.results.cc_email);
          setisLoading(true);
        } else setisLoading(false);
      });
    } else if (!_.isEmpty(row.business_unit)) {
      setisLoading(false);
      setresponsibleBUPoc(row.business_unit.title_point_of_contacts);
    } else setisLoading(false);
  };

  const readResponsibleBUPOC = (id, ccEmail) => {
    apicall.getResponsibleBUPOC(props.propertyID, id).then((response) => {
      if (!_.isEmpty(response)) {
        let poc = {
          bu_poc_name: response.description + " - " + response.name,
        };
        setrow(Object.assign({ ...row }, { ...poc }));
        let cc_email = "";
        if (!_.isEmpty(response.poc_cc_email)) {
          cc_email = response.poc_cc_email;
        }

        let x = {};
        x = { cc_email: ccEmail + cc_email, email: response.email };
        setsendEmailInfo(Object.assign({ ...sendEmailInfo }, { ...x }));
        // }
        setisLoading(false);
      } else if (!_.isEmpty(row.business_unit)) {
        setresponsibleBUPoc(row.business_unit.title_point_of_contacts);
      }
      setisLoading(false);
    });
  };

  const handleSubmit = (event) => {
    let data = row;
    if (row.validated === true && row.business_unit_id === null) {
      data = {
        ...row,
        business_unit_id: business_id,
      };
    }
    if (props.isMunicipalityRegistrationAction) {
      data = {
        ...row,
        municipality_registration_id: props.municipality_registration_id,
      };
    }

    if (
      !_.isEmpty(row.title_documents) &&
      _.includes(
        row.title_documents,
        row.title_documents.find((x) => x.category === "Court Hearing")
      ) &&
      _.isEmpty(row.case_number)
    ) {
      helpers.notify("Please enter Case Number");
      setopenHearingList(!openHearingList);
    } else if (_.isEmpty(row.originator) && _.isEmpty(row.associable_type)) {
      helpers.notify("Please select the Originator and Association/Muni");
    } else if (_.isEmpty(row.originator)) {
      helpers.notify("Please select the Originator");
    } else if (_.isEmpty(row.associable_type)) {
      helpers.notify("Please select the Association/Muni");
    } else if (actionable_id) {
      apicall
        .updateCodeViolation(actionable_id, props.propertyID, data)
        .then((response) => {
          if (response) {
            helpers.notify("Updated successfully");
            config.localforage.setItem("propertyID", props.propertyID);
            config.localforage.setItem("cv_id", response.id);
            config.localforage.setItem("url_token", response.url_token);
            setrow(response);
            setisLoading(true);
            seturlToken(response.url_token);
            if (response.lien_created)
              helpers.notify("Lien Action created successfully");
            if (response.payment_created)
              helpers.notify("Payment Action created successfully");
            if (openViolationFileUpload) {
              toggleDocument();
            }
            readCodeViolation();
            // else props.toggle();
          }
        });
    } else {
      apicall.createCodeViolation(props.propertyID, data).then((response) => {
        if (response) {
          helpers.notify("Created successfully");
          config.localforage.setItem("propertyID", props.propertyID);
          config.localforage.setItem("cv_id", response.id);
          config.localforage.setItem("url_token", response.url_token);
          setrow(response);
          setisLoading(true);
          readCodeViolation(response.id);
          setactionable_id(response.id);
          seturlToken(response.url_token);
          if (response.lien_created)
            helpers.notify("Lien Action created successfully");
          if (response.payment_created)
            helpers.notify("Payment Action created successfully");
          if (openViolationFileUpload) {
            toggleDocument();
            //readCodeViolation();
          }

          //else props.toggle();
        }
      });
    }
    if (props && props.closingTab) {
      props.closingTab();
    }
    if (props && props.refershActions) {
      props.refershActions();
    }
    if (props && props.getActionChanges) {
      props.getActionChanges();
    }
    event.preventDefault();
  };

  const handleDateChange = (value, key) => {
    let date = helpers.formatApiDate(value);

    setrow(Object.assign({ ...row }, { [key]: date }));
  };

  const handleChange = (event, key) => {
    let targetID = event.target.id;
    let value = event.target.value;

    let data;
    if (targetID === "poc_name") {
      data = pocOptions.find((x) => x.name === value);
      let x;

      if (!_.isEmpty(data))
        x = {
          poc_name: data.name,
          poc_title: data.title,
          poc_email: data.email,
          poc_phone: data.phone,
        };

      setrow(Object.assign({ ...row }, { ...x }));
    } else if (targetID === "bu_poc_name") {
      data = businessUnitList.find(
        (x) => x.id === parseInt(row.business_unit_id)
      );

      let pocInfo =
        !_.isEmpty(data) &&
        data.title_point_of_contacts.find(
          (x) => x.description + " - " + x.name === value
        );
      let x;
      let poc_email = "";

      if (!_.isEmpty(pocInfo)) {
        if (
          pocInfo.poc_cc_email !== undefined &&
          !_.isEmpty(pocInfo.poc_cc_email)
        )
          poc_email = pocInfo.poc_cc_email;

        x = {
          cc_email: poc_email + "," + data.cc_email,
          email: pocInfo.email,
        };
      }
      setsendEmailInfo(x);
      setrow(Object.assign({ ...row }, { [targetID]: value }));
    } else setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const handleResponsibleBuChange = (event, key) => {
    let targetID = event.target.id;
    let value = event.target.value;
    let dataPocList;
    let poc;
    let data;
    if (targetID === "business_unit_id") {
      dataPocList = businessUnitList;
      poc = dataPocList.find(
        (data) => data.id === parseInt(value)
      ).title_point_of_contacts;

      data = poc.map((x) => {
        return x;
      });
      setresponsibleBUPoc(data);
      setrow(Object.assign({ ...row }, { [targetID]: value }));
    } else if (targetID === "associable_id") {
      dataPocList =
        row.originator === "Muni" ? violationMunicipals : violationHoas;
      poc = dataPocList.find(
        (data) => data.id === parseInt(value)
      ).code_violation_pocs;
      data = poc.map((x) => {
        return x;
      });
      setpocOptions(data);
      let associable_type;
      if (row.originator === "Muni") associable_type = "Office";
      else if (row.originator === "Association") associable_type = "Hoa";

      let x = { [targetID]: value, associable_type: associable_type };
      setrow(Object.assign({ ...row }, { ...x }));
    }
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const typeActions = [];
  typeActions.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  for (let type in originatorType) {
    typeActions.push(
      <option key={type} value={type}>
        {originatorType[type]}
      </option>
    );
  }

  const deleteCodeViolationDocument = (documentId) => {
    setisLoading(true);
    apicall
      .deleteCodeViolationDocument(props.propertyID, props.actionID, documentId)
      .then((response) => {
        helpers.notify("Document deleted successfully");
        readCodeViolation();
      });
  };

  const renderRadioInput = (name, disabled) => {
    return (
      <div className="code-violation-radio-button">
        <CustomInput
          id={`${name}yes`}
          type="radio"
          name={name}
          value={true}
          onClick={(e) => handleRadioButton(e, true)}
          checked={row[name] === true ? "checked" : ""}
        />
        <label>Yes</label>
        <CustomInput
          id={`${name}no`}
          type="radio"
          name={name}
          value={false}
          onClick={(e) => handleRadioButton(e, false)}
          checked={row[name] === false ? "checked" : ""}
        />
        <label>No</label>
      </div>
    );
  };

  const handleEmailSubmit = () => {
    const target = "_blank";
    let address = helpers.formatAddress(
      propertyInfo,
      config.propertyManage.addressFields,
      config.propertyManage.extraField
    );
    console.log(address);
    let correct_by_date = row.correct_by_date
      ? helpers.formatDate(row.correct_by_date)
      : "";
    let received_date = row.received_date
      ? helpers.formatDate(row.received_date)
      : "";

    let body = `Hello,%0D%0A
%0D%0A
ResiHOME received the attached notification on ${received_date} for the above referenced property. Please review the attachment and confirm you have for resolution by ${correct_by_date} using this 
${config.netlifyUrl}thankyou/${props.propertyID}/${actionable_id}/${urlToken}
%0D%0A
%0D%0A
If you have any questions or concerns in identifying any steps needed to resolve the violation, please contact the violation originator for assistance.
%0D%0A
%0D%0A
If you require an extension in the resolution date, please contact the Violation’s team at xx@xxxxx.com
%0D%0A
%0D%0A
Once the issue is resolved, please confirm its resolution via this ${config.netlifyUrl}issue-resolved-confirmation/${props.propertyID}/${actionable_id}/${urlToken} to advise the Compliance team.
%0D%0A
%0D%0A
Thank you for your prompt attention to this matter,
%0D%0A
%0D%0A
Regards, 
%0D%0A
%0D%0A
Resicap.`;

    let toEmail = "";
    let ccEmail = "";
    if (!_.isUndefined(sendEmailInfo)) {
      toEmail = sendEmailInfo.email;
      ccEmail = sendEmailInfo.cc_email;
    }
    let value = `Code Violation Notification - ${propertyInfo.clientid} - ${address}`;
    let subject = value.replace("#", "");
    const href = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${body}&to=${toEmail}&cc=${ccEmail}&tf=1`;
    Linking.canOpenURL(href, target).then((supported) => {
      if (!supported) {
        console.log("Can't handle url: " + href, target);
      } else {
        if (window) {
          return window.open(href, target);
        } else {
          return Linking.openURL(href, target);
        }
      }
    });
  };

  const pocData = [];
  pocData.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  if (!_.isEmpty(pocOptions)) {
    for (let type of pocOptions) {
      if (type && type.name) {
        pocData.push(
          <option key={type.name} value={type.name}>
            {type.name}
          </option>
        );
      }
    }
  }

  const hoaData = [];
  hoaData.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  if (!_.isEmpty(violationHoas)) {
    for (let type of violationHoas) {
      hoaData.push(
        <option key={type.name} value={type.id}>
          {type.name}
        </option>
      );
    }
  }

  const municipalData = [];
  municipalData.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  if (!_.isEmpty(violationMunicipals)) {
    for (let type of violationMunicipals) {
      municipalData.push(
        <option key={type.name} value={type.id}>
          {type.name}
        </option>
      );
    }
  }

  const responsibleBUOptions = [];

  let data = {};
  if (!_.isEmpty(responsibleBU) && !_.isEmpty(businessUnitList)) {
    data = businessUnitList
      .filter((x) => x.name !== responsibleBU.name)
      .map((x) => x);
  } else {
    data = businessUnitList.map((x) => x);
  }

  if (!_.isEmpty(responsibleBU) && !_.isEmpty(businessUnitList)) {
    responsibleBUOptions.push(
      <option
        value={!_.isEmpty(responsibleBU) && responsibleBU.id}
        key="defaultValue"
      >
        {!_.isEmpty(responsibleBU) && responsibleBU.name}
      </option>
    );
  } else {
    responsibleBUOptions.push(
      <option value="" key="defaultValue">
        Select
      </option>
    );
  }
  if (!_.isEmpty(data)) {
    for (let type of data) {
      responsibleBUOptions.push(
        <option value={type.id} key={type.id}>
          {type.name}
        </option>
      );
    }
  }

  const responsibleBUPOCOptions = [];

  let responsibleBUPocData = {};
  if (!_.isEmpty(responsibleBUPoc)) {
    responsibleBUPocData = responsibleBUPoc.map((x) => {
      return x;
    });
  }

  responsibleBUPOCOptions.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );

  if (!_.isEmpty(responsibleBUPocData)) {
    for (let type of responsibleBUPocData) {
      responsibleBUPOCOptions.push(
        <option
          value={type.description + " - " + type.name}
          key={type.description + " - " + type.name}
        >
          {type.description + " - " + type.name}
        </option>
      );
    }
  }

  const formElements = () => {
    return (
      <>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <FormGroup row>
              <Label for="" sm={2}>
                Originator *
              </Label>

              <Col sm={2}>
                <Input
                  type="select"
                  name="originator"
                  id="originator"
                  onChange={handleChange}
                  value={row.originator}
                  // onClick={handleSubmit}
                  errorMessages={"Please select the Originator"}
                >
                  {typeActions}
                </Input>
              </Col>
              <Label for="" sm={1}>
                Category
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name={"category"}
                  id={"category"}
                  onChange={handleChange}
                  value={
                    !_.isEmpty(row.title_documents)
                      ? row.title_documents[0]["category"]
                      : ""
                  }
                  disabled={true}
                ></Input>
              </Col>
              <Label for="" sm={1} style={{ paddingLeft: "0px" }}>
                Classification
              </Label>
              <Col sm={3}>
                <Input
                  type="text"
                  name={"classification"}
                  id={"classification"}
                  onChange={handleChange}
                  value={
                    !_.isEmpty(row.title_documents)
                      ? row.title_documents[0]["classification"]
                      : ""
                  }
                  disabled={true}
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}>
                Association/Muni *
              </Label>
              <Col sm={5}>
                <Input
                  type="select"
                  name={"associable_id"}
                  id={"associable_id"}
                  onChange={handleResponsibleBuChange}
                  value={row.associable_id}
                >
                  {row.originator === "Association" ? hoaData : municipalData}
                </Input>
              </Col>
              <Label for="" sm={1}>
                POC
              </Label>
              <Col sm={3}>
                <Input
                  type="select"
                  name="poc_name"
                  id="poc_name"
                  onChange={handleChange}
                  value={row.poc_name}
                >
                  {pocData}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}>
                Document Upload *
              </Label>
              <Col sm={3}>
                {" "}
                <Button
                  onClick={() => {
                    setopenViolationFileUpload(true);
                  }}
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                >
                  File Upload
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}></Label>
              <Col sm={9}>
                {row.title_documents && (
                  <DocumentsTab
                    propertyID={props.propertyID}
                    documents={row.title_documents}
                    iscodeViolation={true}
                    deleteDocument={deleteCodeViolationDocument}
                    theme={"general-info-tab"}
                    fetchDocuments={readCodeViolation}
                  />
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}>
                Brief Description
              </Label>
              <Col sm={9}>
                <Input
                  type="textarea"
                  name="brief_description"
                  id="brief_description"
                  onChange={handleChange}
                  value={row.brief_description}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}>
                Type
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name={"code_violation_type"}
                  id={"code_violation_type"}
                  onChange={handleChange}
                  value={
                    !_.isEmpty(row.title_documents)
                      ? row.title_documents[0]["doc_type"]
                      : ""
                  }
                  disabled={true}
                ></Input>
              </Col>
              <Label for="" sm={2}>
                Responsibility
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name={"responsibility"}
                  id={"responsibility"}
                  onChange={handleChange}
                  value={row.responsibility}
                ></Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="" sm={2}>
                Date Of Record
              </Label>
              <Col sm={2}>
                <div className="code-violation-date">
                  {" "}
                  <DatePicker
                    id="date_of_record"
                    selected={
                      row.date_of_record
                        ? helpers.restructureDate(row.date_of_record)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "date_of_record")
                    }
                    value={
                      row.date_of_record
                        ? helpers.restructureDate(row.date_of_record)
                        : null
                    }
                  />
                </div>
              </Col>

              <Label for="" sm={2}>
                Received Date
              </Label>
              <Col sm={2}>
                <div className="code-violation-date">
                  <DatePicker
                    id="received_date"
                    selected={
                      row.received_date
                        ? helpers.restructureDate(row.received_date)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "received_date")
                    }
                    value={
                      row.received_date
                        ? helpers.restructureDate(row.received_date)
                        : null
                    }
                  />
                </div>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="" sm={2}>
                Follow up Date
              </Label>
              <Col sm={2}>
                <div className="code-violation-date">
                  {" "}
                  <DatePicker
                    id="followup_date"
                    selected={
                      row.followup_date
                        ? helpers.restructureDate(row.followup_date)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "followup_date")
                    }
                    value={
                      row.followup_date
                        ? helpers.restructureDate(row.followup_date)
                        : null
                    }
                  />
                </div>
              </Col>

              <Label for="" sm={2}>
                Due Date
              </Label>
              <Col sm={2}>
                <div className="code-violation-date">
                  <DatePicker
                    id="due_date"
                    selected={
                      row.due_date
                        ? helpers.restructureDate(row.due_date)
                        : null
                    }
                    onChange={(value) =>
                      handleDateChange(value, "due_date")
                    }
                    value={
                      row.due_date
                        ? helpers.restructureDate(row.due_date)
                        : null
                    }
                  />
                </div>
              </Col>
            </FormGroup>


            <FormGroup row>
              <Label for="" sm={2}>
                Violation Confirmed Active?
              </Label>
              <Col sm={2}>{renderRadioInput("validated")}</Col>
              {!_.isEmpty(row.case_number) &&
                !_.isEmpty(row.title_documents) &&
                _.includes(
                  row.title_documents,
                  row.title_documents.find(
                    (x) => x.category === "Court Hearing"
                  )
                ) && (
                  <>
                    {" "}
                    <Label for="" sm={2}>
                      Case Number
                    </Label>
                    <Col sm={2}>
                      <div
                        className="case-number"
                        onClick={() => {
                          setopenHearingList(true);
                        }}
                      >
                        {row.case_number}
                      </div>
                    </Col>
                    <Label for="" sm={1}>
                      Date/Time
                    </Label>
                    <Col sm={2} style={{ paddingTop: "calc(0.375rem + 1px)" }}>
                      {!_.isEmpty(row.title_hearings) && (
                        <div className="case-date-time">
                          {!_.isEmpty(row.title_hearings[0].time)
                            ? dayjs(row.title_hearings[0].time).format(
                                config.US_DATE_TIME_FORMAT
                              )
                            : ""}
                        </div>
                      )}
                    </Col>
                  </>
                )}
            </FormGroup>
            {row.validated === true && (
              <>
                <FormGroup row>
                  <Label for="" sm={2}>
                    Respond by Date
                  </Label>
                  <Col sm={2}>
                    <div className="code-violation-date">
                      <DatePicker
                        id="respond_by_date"
                        selected={
                          row.respond_by_date
                            ? helpers.restructureDate(row.respond_by_date)
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange(value, "respond_by_date")
                        }
                        value={
                          row.respond_by_date
                            ? helpers.restructureDate(row.respond_by_date)
                            : null
                        }
                      />
                    </div>
                  </Col>

                  <Label for="" sm={2}>
                    Correct by Date
                  </Label>
                  <Col sm={2}>
                    <div className="code-violation-date">
                      <DatePicker
                        id="correct_by_date"
                        selected={
                          row.correct_by_date
                            ? helpers.restructureDate(row.correct_by_date)
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange(value, "correct_by_date")
                        }
                        value={
                          row.correct_by_date
                            ? helpers.restructureDate(row.correct_by_date)
                            : null
                        }
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="" sm={2}>
                    Responsible B/U
                  </Label>
                  <Col sm={2}>
                    <Input
                      type="select"
                      name={"business_unit_id"}
                      id={"business_unit_id"}
                      onChange={handleResponsibleBuChange}
                      value={row.business_unit_id}
                    >
                      {responsibleBUOptions}
                    </Input>
                  </Col>
                  <Label for="" sm={2}>
                    B/U POC
                  </Label>
                  <Col sm={5}>
                    <Input
                      type="select"
                      name={"bu_poc_name"}
                      id={"bu_poc_name"}
                      onChange={handleChange}
                      value={row.bu_poc_name}
                    >
                      {responsibleBUPOCOptions}
                    </Input>
                  </Col>
                  {row.bu_poc_name && (
                    <Col sm={1} style={{ paddingLeft: "0px" }}>
                      {" "}
                      <Button
                        type="button"
                        id="submit"
                        onClick={() => handleEmailSubmit()}
                        style={{ marginBottom: "0px", marginTop: "0px" }}
                      >
                        Send Email
                      </Button>
                    </Col>
                  )}
                </FormGroup>
                <FormGroup row>
                  <Label for="" sm={2}>
                    Notified
                  </Label>
                  <Col sm={2}>
                    <div className="code-violation-date">
                      <DatePicker
                        id="notified"
                        selected={
                          row.notified
                            ? helpers.restructureDate(row.notified)
                            : null
                        }
                        onChange={(value) =>
                          handleDateChange(value, "notified")
                        }
                        value={
                          row.notified
                            ? helpers.restructureDate(row.notified)
                            : null
                        }
                      />
                    </div>
                  </Col>
                  <Label for="" sm={2}>
                    Confirmed
                  </Label>
                  <Col sm={2}>
                    {row.confirmed && (
                      <div className="case-number">
                        {dayjs(row.confirmed).format(
                          config.US_DATE_TIME_FORMAT
                        )}
                      </div>
                    )}
                  </Col>
                  <Label for="" sm={2}>
                    Completion Notification Performed?
                  </Label>
                  <Col sm={2} style={{ paddingLeft: "0px" }}>
                    {renderRadioInput("completion_notification_performed")}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="" sm={2}>
                    Confirmed Complete?
                  </Label>
                  <Col sm={2}>{renderRadioInput("confirmed_complete")}</Col>
                </FormGroup>
              </>
            )}
            <FormGroup row>
              <Label for="" sm={2}></Label>
              <Col sm={9}>
                <Input
                  type="textarea"
                  name="code_violation_notes"
                  id="code_violation_notes"
                  placeholder="Notes"
                  onChange={handleChange}
                  // value={row.code_violation_notes}
                />
              </Col>
            </FormGroup>
            <div className="status">
              <Button
                className={""}
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
              >
                Submit
              </Button>
            </div>
            <NotesHistory
              notes={rowNotes ? rowNotes : []}
              codeviolationStyle={true}
            />
          </>
        )}
      </>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Code Violation</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="codeViolation-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
      {openViolationFileUpload && (
        <ViolationFileUpload
          toggle={toggleDocument}
          title="Add Violation"
          open={openViolationFileUpload}
          row={row}
          handleSubmit={handleSubmit}
          actionID={actionable_id}
          propertyID={props.propertyID}
          fetchDocuments={readCodeViolation}
        />
      )}
      {openHearingList && (
        <HearingListComponent
          toggle={toggleHearingModal}
          title="Hearing Details"
          open={openHearingList}
          row={row}
          actionID={actionable_id}
          fetchDocuments={readCodeViolation}
          propertyID={props.propertyID}
        />
      )}
    </div>
  );
}
