import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import apicall from "../../utils/apicall";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import helpers from "../../utils/helpers";
import ReactPhoneInput from "react-phone-input-2";
import React, { Component } from "react";
import config from "../../utils/config";
export default class EditUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      id: props.id,
      mobile: props.mobile,
      password: "",
      password_confirmation: "",
    };
  }

  toggleModal = () => {
    this.props.toggle();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    var userData = {
      mobile: this.state.mobile,
      role: this.state.role,
      status: this.state.status,
    };
    if (this.state.password.length > 0) {
      userData["password"] = this.state.password;
      userData["password_confirmation"] = this.state.password_confirmationid;
    }

    apicall.updateAnalyst(userData, this.state.id).then((response) => {
      if (response !== null && response.hasOwnProperty("errors")) {
        let errorMsg = helpers.getErrorMsg(response.errors);
        if (errorMsg) helpers.notify(errorMsg);
      } else {
        config.localforage.setItem("mobile", response.mobile);
        helpers.notify("User Updated Successfully");
        this.props.refereshData(response.mobile);
        this.toggleModal();
      }
    });
    event.preventDefault();
  };

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">Update User</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
            <FormGroup>
              <Label for="">Mobile No</Label>
              <ReactPhoneInput
                defaultCountry={"us"}
                value={this.state.mobile || ""}
                inputExtraProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: true,
                }}
                onChange={(value) => {
                  this.setState({
                    mobile: value,
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Password</Label>
              <TextValidator
                type="password"
                name="password"
                id="password"
                placeholder="Enter password"
                onChange={this.handleChange}
                validators={[]}
                value={this.state.password}
                errorMessages={[]}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Confirm Password</Label>
              <TextValidator
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                placeholder="Confirm password"
                onChange={this.handleChange}
                validators={[]}
                value={this.state.password_confirmation}
                errorMessages={[]}
              />
            </FormGroup>
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    console.log(this.props.modal);
    return (
      <div>
        <Modal
          className="createAnalystModal"
          isOpen={this.props.modal}
          toggle={this.toggleModal}
        >
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}
