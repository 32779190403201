import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import LawFirmModal from "./LawFirmModal";
import apicall from "../../utils/apicall";
import ConfirmBox from "../helper_components/ConfirmBox";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "../../styles/_municipalComponent.scss";
import Loader from "../helper_components/LoaderComponent";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";

export default function LawFirmComponent(props) {
  const [isLoading, setisLoading] = useState(false);
  const [openFormModal, setopenFormModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [formdata, setformdata] = useState();
  const [lawFirmData, setlawFirmData] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    getAllLawyers();
  }, [form_inputs.sort]);

  const getAllLawyers = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall
      .listAllLawFirm(form_inputs, pageNo, perPage)
      .then((response) => {
        setisLoading(false);
        setlawFirmData(response.results);
        settotalCount(response.total);
        setpageNo(pageNo);
        setperPage(perPage);
      })
      .catch("Error While fetching law firm");
  };

  const toggleAddFormModal = () => {
    setopenFormModal(!openFormModal);
    getAllLawyers();
  };

  const deleteLawyers = (formdata) => {
    apicall.deleteLawFirm(formdata.id).then((status) => {
      if (status < 400) {
        helpers.notify("Deleted successfully");
        getAllLawyers();
      }
    });
  };

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            //seteditModal(true);
            setopenFormModal(true);
          }}
        />
        <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            setdeleteModal(true);
            //setState({ row, deleteModal: true });
          }}
        />
      </div>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: " Name",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },

    {
      dataField: "city",
      text: "City",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "state",
      text: "State",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "zip",
      text: "Zip",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "website",
      text: "Website",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: buttonFormatter,
      headerAttrs: { width: 200 },
      attrs: { width: 200, class: "table-actions" },
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
  ];
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <section className="content">
        <header className="content-title">
          <h2 className="page-heading">
            <strong>Law Firm</strong>
          </h2>
          <div className="listCompButton">
            <Button onClick={() => setopenFormModal(true)}>Add</Button>
          </div>
        </header>

        <PaginationComponent
          columns={columns}
          rows={lawFirmData}
          handleSubmit={getAllLawyers}
          pageNo={pageNo}
          perPage={perPage}
          totalCount={totalCount}
        />
      </section>

      {deleteModal && (
        <ConfirmBox
          onYesClick={() => {
            deleteLawyers(formdata);
            setdeleteModal(false);
          }}
          onNoClick={() => setdeleteModal(false)}
          heading={
            <span>
              Are you sure you want to delete the Lawyer: {formdata.name}?
            </span>
          }
        >
          <div>
            <p>All Lawyer related information will be deleted.</p>
          </div>
        </ConfirmBox>
      )}

      {openFormModal && (
        <LawFirmModal
          title="Law Firm"
          toggle={toggleAddFormModal}
          open={openFormModal}
          formdata={formdata}
          getAllLawyers={getAllLawyers}
        />
      )}
    </React.Fragment>
  );
}
