import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../helper_components/TextValidator";
import "../../styles/_violationFileUpload.scss";
import config from "../../utils/config";
import helpers from "../../utils/helpers";
import _ from "lodash";
import LoaderComponent from "../helper_components/LoaderComponent";

export default function LineFileUpload(props) {
  const [row, setrow] = useState(props.row ? props.row : {});
  const [actionable_id, setactionable_id] = useState(props.actionID);
  const [hideCategoryMessage, sethideCategoryMessage] = useState(false);
  const [hideTypeMessage, sethideTypeMessage] = useState(false);
  const [hideClassificationMessage, sethideClassificationMessage] = useState(false);
  const [recordingDate, setRecordingDate] = React.useState(null);
  const [isLoading, setisLoading] = useState(false);

  const handleSubmit = (event) => {
    setisLoading(true)
    if (_.includes(row, row.selectedValue)) delete row.selectedValue;
    if (actionable_id) {
      apicall
        .updateTitleLien(row, actionable_id, props.propertyID)
        .then((response) => {
          if (response) {
            helpers.notify("Updated successfully");
            setrow(response);
            if (props.open) {
              props.toggle();
              props.fetchDocuments(response.id);
            }
            setisLoading(false)
          }
        });
    } else {
      apicall.createTitleLien(row, props.propertyID).then((response) => {
        if (response) {
          helpers.notify("Created successfully");
          setrow(response);
          if (props.open) {
            props.toggle();
            props.fetchDocuments(response.id);
          }
          setisLoading(false)
        }
      });
    }
    event.preventDefault();
  };

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const categoryOptions = () => {
    let categories = [
        "Municipal Lien",
        "Judgement Lien",
        "Lien Release",
    ];
    return categories.map((c) => {
      return { label: c, value: c };
    });
  };

  const classificationOptions = () => {
    let classification = [
      "Unrecorded",
      "Recorded",
    ];
    return classification.map((c) => {
      return { label: c, value: c };
    });
  };
  var lienTypes = config.lienTypes;
  var lienTypeValues = lienTypes.map((c) => {
    return { label: c, value: c };
  });

 const handleDateChange = (value) => {
    let date = helpers.restructureDate(value);
    setRecordingDate(value)
    setrow(Object.assign({ ...row }, { "title_documents_attributes[][recording_date]": date }));
  };

  const formElements = () => {
    return (
      <>
      <div>
        <FormGroup>
          <Label for="">Category</Label>
          <Select
            name="title_documents_attributes[][category]"
            id="title_documents_attributes[][category]"
            value={{
              value: row["title_documents_attributes[][category]"],
              label: row["title_documents_attributes[][category]"],
            }}
            onChange={(selectedType) => {
              sethideCategoryMessage(false);
              let selectedValue = row;
              selectedValue["title_documents_attributes[][category]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={categoryOptions()}
            validators={["required", "isValidSelect"]}
            errorMessages={[
              "Document Category is required",
              "Name is required",
            ]}
          />
          {hideCategoryMessage ? (
            <b style={{ color: "red" }}>Please select Category</b>
          ) : (
            <></>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="">Classification</Label>
          <Select
            id={"title_documents_attributes[][classification]"}
            name="title_documents_attributes[][classificartion]"
            value={{
              value: row["title_documents_attributes[][classification]"],
              label: row["title_documents_attributes[][classification]"],
            }}
            onChange={(selectedType) => {
              sethideTypeMessage(false);
              let selectedValue = row;
              selectedValue["title_documents_attributes[][classification]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={classificationOptions()}
            validators={["required", "isValidSelect"]}
            errorMessages={["Document Type is required", "Name is required"]}
          />
          {hideClassificationMessage ? (
            <b style={{ color: "red" }}>Please select Classification</b>
          ) : (
            <></>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="">Type</Label>
          <Select
            id={"title_documents_attributes[][doc_type]"}
            name="title_documents_attributes[][doc_type]"
            value={{
              value: row["title_documents_attributes[][doc_type]"],
              label: row["title_documents_attributes[][doc_type]"],
            }}
            onChange={(selectedType) => {
              sethideTypeMessage(false);
              let selectedValue = row;
              selectedValue["title_documents_attributes[][doc_type]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={lienTypeValues}
            validators={["required", "isValidSelect"]}
            errorMessages={["Document Type is required", "Name is required"]}
          />
          {hideTypeMessage ? (
            <b style={{ color: "red" }}>Please select Type</b>
          ) : (
            <></>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="">Recording Location</Label>

          <TextValidator
            type="text"
            id={"title_documents_attributes[][recording_location]"}
            name="title_documents_attributes[][recording_location]"
            placeholder="Recording Location"
            onChange={handleChange}
            value={row["title_documents_attributes[][recording_location]"]}
            // validators={["required"]}
            // errorMessages={["Please enter case number"]}
          />
        </FormGroup>
        <FormGroup row>
          <Col sm ={5}>
          <Label for="">Recording date</Label>
              <DatePicker
                id={"title_documents_attributes[][recording_date]"}
                name="title_documents_attributes[][recording_date]"
                onChange={handleDateChange}
                selected={recordingDate ? recordingDate :row["title_documents_attributes[][recording_date]"]}
                // value={row["title_documents_attributes[][recording_date]"]}
              />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
          <Label for="">Book</Label>
          <TextValidator
            type="text"
            id={"title_documents_attributes[][book]"}
            name="title_documents_attributes[][book]"
            placeholder="Book"
            onChange={handleChange}
            value={row["title_documents_attributes[][book]"]}
            // validators={["required"]}
            // errorMessages={["Please enter case number"]}
          />
          </Col>
          <Col sm={3}>
          <Label for="">page</Label>
          <TextValidator
            type="text"
            id={"title_documents_attributes[][page]"}
            name="title_documents_attributes[][page]"
            placeholder="page"
            onChange={handleChange}
            value={row["title_documents_attributes[][page]"]}
            // validators={["required"]}
            // errorMessages={["Please enter case number"]}
          />
          </Col>
          <Col sm={5}>
          <Label for="">Instrument Number</Label>
          <TextValidator
            type="text"
            id={"title_documents_attributes[][instrument_number]"}
            name="title_documents_attributes[][instrument_number]"
            placeholder="Instrument Number"
            onChange={handleChange}
            value={row["title_documents_attributes[][instrument_number]"]}
            // validators={["required"]}
            // errorMessages={["Please enter case number"]}
          />
          </Col>
        </FormGroup>
        <FormGroup>
          <Label for="">Document File Upload</Label>
          <Input
            type="file"
            name="file"
            id={"title_documents_attributes[][file]"}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Button className={""} onSubmit={handleSubmit}>
          Submit
        </Button>
      </div>
      </>
    );
  };

  const renderModal = () => {
    return (
      <>
      {isLoading ? (
       <LoaderComponent /> 
      ) : (
        <>
        <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Lien Upload</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
        </>
      )}
      </>
    );
  };

  return (
    <div>
      <Modal
        className="violationFileUpload-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
