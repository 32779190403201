import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Button } from "reactstrap";
import FormComp from "./FormComp";
import { FaEdit, FaTrashAlt, FaBan } from "react-icons/fa";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";
import config from "../../utils/config";
export default class ListComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFormModal: false,
      refreshList: props.refreshList,
      data: props.data,
      pageNo: props.pageNo ? props.pageNo : 1,
      perPage: props.perPage ? props.perPage : 10,
      row: {},
    };
  }

  componentWillReceiveProps(newprops) {
    if (
      newprops !== this.props &&
      !newprops.openFormModal &&
      typeof newprops.data !== "undefined"
    ) {
      this.setState({
        data: newprops.data ? newprops.data : this.state.data,
      });
    }
  }

  toggleEditFormModal = () => {
    this.setState({
      openFormModal: !this.state.openFormModal,
    });
    window.location.reload();
    // this.state.refreshList();
  };

  toggleAddFormModal = () => {
    var openFormModal = !this.state.openFormModal;

    this.setState({
      row: {},
      openFormModal: openFormModal,
    });
    // this.state.refreshList();
  };

  cellFormatter = (cell) => {
    return helpers.getFormattedData(cell);
  };

  mailAddressFormatter = (cell, row, rowIndex, formatExtraData) => {
    let addressFields = [
      "mailing_contact_name",
      "mailing_street_name",
      "mailing_city",
      "mailing_state",
      "mailing_zip",
    ];
    return <>{helpers.formatAddress(row, addressFields)}</>;
  };

  dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!config["readOnlyTable"].includes(this.props.userRole)) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <FaEdit
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => {
              this.setState({
                row: row,
                openFormModal: true,
              });
            }}
          />
          <FaTrashAlt
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => this.deleteRecord(row)}
          />
        </div>
      );
    } else {
      return <FaBan className="font-awesome-disabled" size="1.6em" />;
    }
  };

  deleteRecord = (row) => {};

  columns = () => {
    return [];
  };

  formComp = () => {
    return (
      <FormComp
        toggle={this.toggleEditFormModal}
        title=""
        open={this.state.openFormModal}
        row={this.state.row}
      />
    );
  };

  tableInfo = () => {
    return null;
  };

  getButton = () => {
    if (config["generalInfoRead"].includes(this.props.userRole)) {
      return null;
    } else if (
      typeof this.state.hideAdd === "undefined" ||
      !this.state.hideAdd
    ) {
      return (
        <div className="listCompButton">
          <Button onClick={() => this.toggleAddFormModal()}>Add</Button>
        </div>
      );
    }
  };

  render() {
    let columns = this.columns();
    return (
      <div className="listdiv">
        <div className="tableInfo">{this.tableInfo()}</div>
        {this.getButton()}
        <div>
          {this.state.data &&
            this.state.data.length > 0 &&
            (this.state.tableHide === "undefined" || !this.state.tableHide) && (
              <PaginationComponent
                columns={columns}
                rows={this.state.data}
                handleSubmit={this.props.refreshList}
                perPage={this.props.perPage}
                pageNo={this.props.pageNo}
                totalCount={this.props.totalCount}
              />
            )}
        </div>
        {this.formComp()}
      </div>
    );
  }
}
