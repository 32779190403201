import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Input,
} from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_municipalVerification.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import CodeViolationModal from "../CodeViolation/CodeViolationModal";
import _ from "lodash";
import NotesHistory from "../CodeViolation/NotesHistory";

export default function AssociationVerification(props) {
  const [row, setrow] = useState({});
  const [openCodeViolationModal, setopenCodeViolationModal] = useState(false);
  const [isCountyDisabled, setisCountyDisabled] = useState(true);
  const [iscityDisabled, setiscityDisabled] = useState(true);
  const [isVillageDisabled, setisVillageDisabled] = useState(true);
  const [countyName, setcountyName] = useState();
  const [cityName, setcityName] = useState();
  const [villageName, setVillageName] = useState();
  const [codeViolationsData, setcodeViolationsData] = useState({});
  const [isMunicipalExist, setisMunicipalExist] = useState(false);

  const toggleModal = (event) => {
    setopenCodeViolationModal(!openCodeViolationModal);
    if (openCodeViolationModal) getMunicipalVerification();
  };
  useEffect(() => {
    getMunicipalVerification();
    loadMunicipals();
  }, []);

  const loadMunicipals = () => {
    apicall.getMunicipalByPropertyID(props.propertyID).then((response) => {
      if (_.isEmpty(response.results)) setisMunicipalExist(true);
      else {
        setcountyName(
          response.results
            .filter((x) => x.muni_type === "County")
            .map((data) => data.name)
            .toString()
        );
        setcityName(
          response.results
            .filter((x) => x.muni_type === "City / Township")
            .map((data) => data.name)
            .toString()
        );
        setVillageName(
          response.results
            .filter((x) => x.muni_type === "Village / Burrough")
            .map((data) => data.name)
            .toString()
        );
      }
    });
  };

  const getMunicipalVerification = () => {
    apicall
      .readMunicipalVerificationAction(props.actionID, props.propertyID)
      .then((response) => {
        if (response) {
          setrow(response);
          if (response.county_type === true) setisCountyDisabled(false);
          if (response.city_type === true) setiscityDisabled(false);
          if (response.village_type === true) setisVillageDisabled();
        }
      });
  };

  const readCodeViolation = (id) => {
    apicall.readCodeViolation(id, props.propertyID).then((response) => {
      setcodeViolationsData(response);
      setopenCodeViolationModal(!openCodeViolationModal);
    });
  };

  const handleSubmit = (event, isTrue) => {
    apicall
      .updateMunicipalVerificationAction(row, props.actionID, props.propertyID)
      .then((response) => {
        if (response) {
          setrow(response);
          if (!isTrue) {
            helpers.notify("Updated successfully");
            props.toggle();
            props.refershActions(props.propertyID);
          }
          // props.toggle();
          // props.refershActions(props.propertyID);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    const value = event.target.value;
    setrow(Object.assign({ ...row }, { [targetName]: value }));
  };

  const renderRadioInput = (name, disabled) => {
    return (
      <div className="radio-button">
        <CustomInput
          id={`${name}yes`}
          type="radio"
          name={name}
          value={true}
          onClick={(e) => handleRadioButton(e, true)}
          checked={row[name] === true ? "checked" : ""}
          disabled={disabled}
        />
        <label>Yes</label>
        <CustomInput
          id={`${name}no`}
          type="radio"
          name={name}
          value={false}
          onClick={(e) => handleRadioButton(e, false)}
          checked={row[name] === false ? "checked" : ""}
          disabled={disabled}
        />
        <label>No</label>
      </div>
    );
  };

  const renderCodeViolationCreated = (codeViolationsData) => {
    return (
      <>
        {!_.isEmpty(codeViolationsData) &&
          codeViolationsData.map((name, i) => {
            return (
              <Col sm={1} className="municipal-website">
                <div
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => {
                    readCodeViolation(name.id);
                  }}
                  className="municipal-violation-button"
                >
                  Code Violation
                </div>
              </Col>
            );
          })}
      </>
    );
  };

  const formElements = () => {
    return (
      <>
        <div>
          {isMunicipalExist && (
            <div style={{ color: "red" }}>
              Please include a municipality in the General Information Tab
            </div>
          )}
        </div>
        {row.county_type === true && (
          <>
            <FormGroup row>
              {!_.isEmpty(countyName) && (
                <Label
                  for=""
                  sm={2}
                  style={{
                    textAlign: "left",
                    paddingLeft: "15px",
                    paddingRight: "0px",
                  }}
                >{`County - ${countyName}`}</Label>
              )}

              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {"Are there permit issues?"}
              </Label>
              <Col sm={1} className="deed-radio-col">
                {renderRadioInput("county_permit_issue", isCountyDisabled)}
              </Col>
              {row.county_permit_issue === true && (
                <>
                  <Col sm={2} className="code-voilation-button">
                    <Button
                      onClick={(event) => {
                        handleSubmit(event, true);
                        let data = {
                          municipality_verification_id: row.id,
                          muni_verification_type: "county_permit_issue",
                        };
                        setcodeViolationsData(data);
                        setopenCodeViolationModal(!openCodeViolationModal);
                      }}
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      Create Code Violation
                    </Button>
                  </Col>

                  {_.includes(row, row.county_permit_violation) && (
                    <>
                      {renderCodeViolationCreated(row.county_permit_violation)}
                    </>
                  )}
                </>
              )}
            </FormGroup>
            <FormGroup row>
              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              ></Label>
              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {"Are there code violation issues?"}
              </Label>
              <Col sm={1} className="deed-radio-col">
                {renderRadioInput(
                  "county_code_violation_issue",
                  isCountyDisabled
                )}
              </Col>
              {row.county_code_violation_issue === true && (
                <>
                  <Col sm={2} className="code-voilation-button">
                    <Button
                      onClick={(event) => {
                        handleSubmit(event, true);
                        let data = {
                          municipality_verification_id: row.id,
                          muni_verification_type: "county_code_violation_issue",
                        };
                        setcodeViolationsData(data);
                        setopenCodeViolationModal(!openCodeViolationModal);
                      }}
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      Create Code Violation
                    </Button>
                  </Col>

                  {_.includes(row, row.county_issue_violation) && (
                    <>
                      {renderCodeViolationCreated(row.county_issue_violation)}
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </>
        )}
        <hr />
        {row.city_type === true && (
          <>
            <FormGroup row>
              {!_.isEmpty(cityName) && (
                <Label
                  for=""
                  sm={2}
                  style={{
                    textAlign: "left",
                    paddingLeft: "15px",
                    paddingRight: "0px",
                  }}
                >{` City / Township - ${cityName}`}</Label>
              )}

              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {"Are there permit issues?"}
              </Label>
              <Col sm={1} className="deed-radio-col">
                {renderRadioInput("city_permit_issue", iscityDisabled)}
              </Col>
              {row.city_permit_issue === true && (
                <>
                  <Col sm={2} className="code-voilation-button">
                    <Button
                      onClick={(event) => {
                        handleSubmit(event, true);
                        let data = {
                          municipality_verification_id: row.id,
                          muni_verification_type: "city_permit_issue",
                        };
                        setcodeViolationsData(data);
                        setopenCodeViolationModal(!openCodeViolationModal);
                      }}
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      Create Code Violation
                    </Button>
                  </Col>

                  {_.includes(row, row.city_permit_violation) && (
                    <>{renderCodeViolationCreated(row.city_permit_violation)}</>
                  )}
                </>
              )}
            </FormGroup>
            <FormGroup row>
              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              ></Label>
              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {"Are there code violation issues?"}
              </Label>
              <Col sm={1} className="deed-radio-col">
                {renderRadioInput("city_code_violation_issue", iscityDisabled)}
              </Col>
              {row.city_code_violation_issue === true && (
                <>
                  <Col sm={2} className="code-voilation-button">
                    <Button
                      onClick={(event) => {
                        handleSubmit(event, true);
                        let data = {
                          municipality_verification_id: row.id,
                          muni_verification_type: "city_code_violation_issue",
                        };
                        setcodeViolationsData(data);
                        setopenCodeViolationModal(!openCodeViolationModal);
                      }}
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      Create Code Violation
                    </Button>
                  </Col>

                  {_.includes(row, row.city_issue_violation) && (
                    <>{renderCodeViolationCreated(row.city_issue_violation)}</>
                  )}
                </>
              )}
            </FormGroup>
          </>
        )}
        <hr />
        {row.village_type === true && (
          <>
            <FormGroup row>
              {!_.isEmpty(villageName) && (
                <Label
                  for=""
                  sm={2}
                  style={{
                    textAlign: "left",
                    paddingLeft: "15px",
                    paddingRight: "0px",
                  }}
                >{`Village / Burrough - ${villageName}`}</Label>
              )}

              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {"Are there permit issues?"}
              </Label>
              <Col sm={1} className="deed-radio-col">
                {renderRadioInput("village_permit_issue", isVillageDisabled)}
              </Col>
              {row.village_permit_issue === true && (
                <>
                  <Col sm={2} className="code-voilation-button">
                    <Button
                      onClick={(event) => {
                        handleSubmit(event, true);
                        let data = {
                          municipality_verification_id: row.id,
                          muni_verification_type: "village_permit_issue",
                        };
                        setcodeViolationsData(data);
                        setopenCodeViolationModal(!openCodeViolationModal);
                      }}
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      Create Code Violation
                    </Button>
                  </Col>

                  {_.includes(row, row.village_permit_violation) && (
                    <>
                      {renderCodeViolationCreated(row.village_permit_violation)}
                    </>
                  )}
                </>
              )}
            </FormGroup>
            <FormGroup row>
              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              ></Label>
              <Label
                for=""
                sm={2}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                {"Are there code violation issues?"}
              </Label>
              <Col sm={1} className="deed-radio-col">
                {renderRadioInput(
                  "village_code_violation_issue",
                  isVillageDisabled
                )}
              </Col>
              {row.village_code_violation_issue === true && (
                <>
                  <Col sm={2} className="code-voilation-button">
                    <Button
                      onClick={(event) => {
                        handleSubmit(event, true);
                        let data = {
                          municipality_verification_id: row.id,
                          muni_verification_type:
                            "village_code_violation_issue",
                        };
                        setcodeViolationsData(data);
                        setopenCodeViolationModal(!openCodeViolationModal);
                      }}
                      style={{ marginBottom: "0px", marginTop: "0px" }}
                    >
                      Create Code Violation
                    </Button>
                  </Col>

                  {_.includes(row, row.village_issue_violation) && (
                    <>
                      {renderCodeViolationCreated(row.village_issue_violation)}
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </>
        )}
        <div className="status">
          {" "}
          <Button type="button" id="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <FormGroup row>
          <Label for="" sm={2}>
            Notes
          </Label>
          <Col sm={9}>
            <Input
              type="textarea"
              name="muni_verification_notes"
              id="muni_verification_notes"
              placeholder="Notes"
              onChange={handleChange}
              //value={row.notes}
            />
          </Col>
        </FormGroup>
        <NotesHistory notes={row.notes ? row.notes : []} municipalVerification= {true} />
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Update Municipal Verification</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            {formElements()}
          </ValidatorForm>
        </ModalBody>
        {openCodeViolationModal && (
          <CodeViolationModal
            toggle={toggleModal}
            className=""
            title="CodeViolation"
            open={openCodeViolationModal}
            propertyID={props.propertyID}
            isMunicipalVerificationAction={true}
            actionID={codeViolationsData.id}
            row={codeViolationsData}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="municipal-verification-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
