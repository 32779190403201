import React, { Component } from "react";
import "../../styles/navbar.scss";
import resicap_logo from "../../images/resicap_logo.png";
import avatar from "../../images/avatar.jpg";
import apicall from "../../utils/apicall";
import config from "../../utils/config";
import EditUserModal from "../analysts/EditUserModal";
import Select from "react-select";
import { Form, FormGroup, Col, Input, Button } from "reactstrap";
import helpers from "../../utils/helpers";
import _ from "lodash";
import MunicipalityUserTable from "../vendor/MunicipalityUserTable";

export default class MunicipalityNavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      openModal: false,
      userid: "",
      mobile: "",
      searchText: "",
      pageNo: 1,
      showMunicipalityUserTable: false,
      vendors: {},
    };
  }

  componentWillMount() {
    config.localforage.getItem("email").then((resp) => {
      this.setState({
        email: resp,
      });
    });
    config.localforage.getItem("userid").then((resp) => {
      this.setState({
        userid: resp,
      });
    });
    config.localforage.getItem("mobile").then((resp) => {
      this.setState({
        mobile: resp,
      });
    });
    this.fetchClientOrder();
  }

  refereshData = (mobile) => {
    this.setState({
      mobile: mobile,
    });
  };

  toggleModal = () => {
    console.log("togllellllll");
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  handleChange = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  fetchClientOrder = (event, pageNo, perPage) => {
    apicall.clientMunicipalityOrders(pageNo, perPage).then((response) => {
      this.setState({
        vendors: response.results,
        showMunicipalityUserTable: true,
        totalCount: response.total,
        pageNo: pageNo,
      });
    });
  };

  handleSubmitForSearch = (event, pageNo, perPage) => {
    this.setState({
      pageNo: typeof pageNo !== "undefined" ? pageNo : "1",
      perPage: typeof pageNo !== "undefined" ? perPage : "10",
    });

    let inputValue = this.state.searchText;
    apicall.municipalityOrdersSearch(inputValue).then((response) => {
      if (response !== null && response.hasOwnProperty("error")) {
        let errorMsg = helpers.getErrorMsg(response.error);
        if (errorMsg)
          this.notify("Search Failed: No Records Found: " + errorMsg);
      } else {
        if (typeof response !== "undefined" && response.length === 0) {
          this.notify("No Records Found: ");
        } else {
          if (_.isString) {
            config.localforage.setItem("searchResult", response);
          }
          this.setState({
            vendors: response.results,
            showMunicipalityUserTable: true,
            totalCount: response.total,
            pageNo: pageNo,
          });
        }
      }
    });

    event.preventDefault();
  };

  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div
            className="header__logo hidden-sm-down header-logo"
            id="headerleft"
          >
            <img src={resicap_logo} alt="Resicap Logo" />
          </div>

          <div className="searchBar">
            <Form onSubmit={this.handleSubmitForSearch}>
              <FormGroup row>
                <Col sm={10}>
                  <Input
                    type="address"
                    name="address"
                    id="search"
                    placeholder=" Search"
                    onChange={this.handleChange}
                  />
                </Col>
                <Col sm={2}>
                  <Button className="search-btn">Search</Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <div className="user-div">
            <div className="users-sub-div">
              <p>{this.state.email}</p>
              <p className="logout-btn" onClick={() => apicall.logout()}>
                Logout
              </p>
            </div>
            <div className="userimg">
              <img
                className="user__img"
                src={avatar}
                alt="Avatar "
                onClick={this.toggleModal}
              />
            </div>
          </div>
        </header>
        {this.state.openModal && (
          <EditUserModal
            toggle={this.toggleModal}
            modal={this.state.openModal}
            mobile={this.state.mobile}
            id={this.state.userid}
            refereshData={this.refereshData}
          />
        )}
        {this.state.showMunicipalityUserTable && (
          <MunicipalityUserTable
            vendors={this.state.vendors}
            pageNo={this.state.pageNo}
            page={this.state.perPage}
            totalCount={this.state.totalCount}
          />
        )}
      </React.Fragment>
    );
  }
}
