import React, { Component } from "react";
import { Button } from "reactstrap";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import CreateAnalystModal from "./CreateAnalystModal";
import AnalystListTable from "./AnalystListTable";
import apicall from "../../utils/apicall";
import "../../styles/_users.scss";
import LoaderComponent from "../helper_components/LoaderComponent";
export default class CreateAnalystComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAnalystModal: false,
      analystArray: [],
      isLoading: true,
      pageNo: 1,
      form_inputs: { sort: {} },
    };
  }

  componentWillMount() {
    this.getAnalysts();
  }

  getAnalysts = (event, pageNo, perPage, role) => {
    this.setState({ isLoading: true });
    apicall
      .getAnalysts(this.state.form_inputs, pageNo, role, perPage)
      .then((response) => {
        this.setState({
          analystArray: response.results,
          totalCount: response.total,
          isLoading: false,
          pageNo: pageNo,
          perPage: perPage,
        });
      });
  };

  toggleCreateAnalyst = () => {
    this.setState({
      createAnalystModal: !this.state.createAnalystModal,
    });
  };

  sortColumns = (response) => {
    this.setState({
      form_inputs: Object.assign(this.state.form_inputs, { sort: response }),
    });
    setTimeout(() => {
      this.getAnalysts();
    }, 1000);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <LoaderComponent />}
        <section className="content">
          <header className="content-title users">
            <div className="page-title">Users</div>
            <div>
              <Button
                onClick={() => this.toggleCreateAnalyst()}
                className="createBtn"
              >
                Create
              </Button>
              {this.state.createAnalystModal && (
                <CreateAnalystModal
                  toggle={this.toggleCreateAnalyst}
                  modal={this.state.createAnalystModal}
                  getAnalysts={this.getAnalysts}
                />
              )}
            </div>
          </header>
          <AnalystListTable
            analystArray={this.state.analystArray}
            getAnalysts={this.getAnalysts}
            isLoading={this.state.isLoading}
            pageNo={this.state.pageNo}
            totalCount={this.state.totalCount}
            toggle={this.toggleCreateAnalyst}
            sortColumns={this.sortColumns}
            perPage={this.state.perPage}
          />
        </section>
      </React.Fragment>
    );
  }
}
