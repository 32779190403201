import React, { useState, useEffect } from "react";
import "../../styles/_general.scss";
import config from "../../utils/config";
import TabsComponent from "../helper_components/TabsComponent";
import PropertyActionListTable from "./PropertyActionListTable";
import MunicipalityActionListTable from "./MunicipalityActionListTable";

export default function ActionListTabComponent(props) {
  {
    const [activeTab, setactiveTab] = useState(1);
    const [property, setproperty] = useState();
    const [propertyid, setpropertyid] = useState(props.propertyID);

    const updateActiveTab = (value) => {
      setactiveTab(value);
    };

    let listofTabs = "";
    if (config["LeaseHide"].includes(props.userRole)) {
      listofTabs = ["Property", "Municipality"];
    } else {
      listofTabs = ["Property", "Municipality"];
    }
    let tabsComponentsList = new Array(listofTabs.length).fill(null);
    tabsComponentsList[0] = (
      <PropertyActionListTable
        propertyID={propertyid}
        isHoaExist={props.propertyInfo.is_hoa}
        userRole={props.userRole}
        propertyInfo={props.propertyInfo}
      />
    );
    tabsComponentsList[1] = (
      <MunicipalityActionListTable
        propertyID={propertyid}
        isHoaExist={props.propertyInfo.is_hoa}
        userRole={props.userRole}
        propertyInfo={props.propertyInfo}
      />
    );

    return (
      <React.Fragment>
        <div className="general-component">
          <TabsComponent
            theme="tab"
            components={tabsComponentsList}
            listofTabs={listofTabs}
            generateTabURL={false}
            activeTab={activeTab}
            theme={"general-info-tab"}
            updateActiveTab={updateActiveTab}
            isVendor={true}
          />
        </div>
      </React.Fragment>
    );
  }
}
