import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import BusinessUnitModal from "./BusinessUnitModal";
import apicall from "../../utils/apicall";
import ConfirmBox from "../helper_components/ConfirmBox";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "../../styles/_municipalComponent.scss";
import Loader from "../helper_components/LoaderComponent";
import PaginationComponent from "../helper_components/PaginationComponent";
import helpers from "../../utils/helpers";

export default function BusinessComponent(props) {
  const [isLoading, setisLoading] = useState(false);
  const [openFormModal, setopenFormModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [formdata, setformdata] = useState({});
  const [businessUnitList, setbusinessUnitList] = useState();
  const [totalCount, settotalCount] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    listOfBusinessUnit();
  }, [form_inputs.sort]);

  const listOfBusinessUnit = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall
      .listOfBusinessUnit(form_inputs, pageNo, perPage)
      .then((response) => {
        setbusinessUnitList(response.results);
        setisLoading(false);
        settotalCount(response.results);
        setpageNo(pageNo);
        setperPage(perPage);
      })
      .catch("Error While fetching  BusinessUnit");
  };

  const toggleAddFormModal = () => {
    setopenFormModal(!openFormModal);
    listOfBusinessUnit();
  };

  const deleteBusinessUnit = (formdata) => {
    apicall.deleteBusinessUnit(formdata.id).then((status) => {
      if (status < 400) {
        helpers.notify("Deleted successfully");
        listOfBusinessUnit();
      }
    });
  };

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            setopenFormModal(true);
          }}
        />
        <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            setdeleteModal(true);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: " Name",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: buttonFormatter,
      headerAttrs: { width: 200 },
      attrs: { width: 200, class: "table-actions" },
    },
  ];

  return (
    <React.Fragment>
      {isLoading && <Loader />}

      <section className="content">
        <header className="content-title">
          <h2 className="page-heading">
            <strong>Business Unit</strong>
          </h2>
          <div className="listCompButton">
            <Button onClick={() => setopenFormModal(true)}>Add</Button>
          </div>
        </header>

        <PaginationComponent
          columns={columns}
          rows={businessUnitList}
          handleSubmit={listOfBusinessUnit}
          pageNo={pageNo}
          perPage={perPage}
          totalCount={totalCount}
        />
        {deleteModal && (
          <ConfirmBox
            onYesClick={() => {
              deleteBusinessUnit(formdata);
              setdeleteModal(false);
            }}
            onNoClick={() => setdeleteModal(false)}
            heading={
              <span>
                Are you sure you want to delete the Business Unit:{" "}
                {formdata.name}?
              </span>
            }
          >
            <div>
              <p>All Business Unit related will be deleted.</p>
            </div>
          </ConfirmBox>
        )}
      </section>

      {openFormModal && (
        <BusinessUnitModal
          title="BusinessUnit"
          toggle={toggleAddFormModal}
          open={openFormModal}
          formdata={formdata}
          listOfBusinessUnit={listOfBusinessUnit}
        />
      )}
    </React.Fragment>
  );
}
