import FormComp from "../crud/FormComp";
import React from "react";
import {
  Button,
  FormGroup,
  Input,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import apicall from "../../utils/apicall";
//import TabsComponent from "../helper_components/TabsComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Charges from "../payments/Charges";
import "../../styles/_payments.scss";
import config from "../../utils/config";
import Select from "react-select";
import helpers from "../../utils/helpers";
//import DocumentUploadComponent from "../helper_components/DocumentUploadComponent";
import EditAddressModal from "./EditAddressModal";
import EditOtherPayeeAddressModal from "./EditOtherPayeeAddressModal";
import { FaEdit } from "react-icons/fa";
import { ValidatorForm } from "react-form-validator-core";
import LoaderComponent from "../helper_components/LoaderComponent";
import _ from "lodash";
import dayjs from "dayjs";
//import { FaTrashAlt } from "react-icons/fa";
import DocumentsModal from "../documents/DocumentsModal";
import DocumentsTab from "../documents/DocumentsTab";
import "../../styles/_tab.scss";
import PaymentCheckSentDocument from "./PaymentCheckSentDocument";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

export default class PaymentModal extends FormComp {
  constructor(props) {
    super(props);
    this.state = {
      row: {},
      paymentHoas: [],
      className: " payment-modal",
      submitButton: "",
      paymentData: {},
      payeeRecipients: null,
      documentUpload: null,
      addressModal: false,
      address: "",
      isLoading: true,
      dasboardPropertyInfo: null,
      assessments: [],
      openAddDocumentModal: false,
      otherPayeeAddressModal: false,
      openPaymentCheckSentDocument: false,
      paymentMunicipals: [],
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  getPropertyInfo = () => {
    apicall.getProperty(this.props.propertyID).then((response) => {
      this.setState({
        dasboardPropertyInfo: response,
      });
      // console.log("the apis ", response);
    });
  };

  displayErrorMsg = () => {
    let other_payee = this.state.row.other_payee;
    let other_payee_recipient = this.state.row.other_payee_recipient;

    if (other_payee === true && other_payee_recipient == true) {
      return (
        <>
          <div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                position: "relative",
                left: "310px",
              }}
            >
              Note: Must submit Other Payee Details, Other Payee Recipient
              Details and Other Payee Account Number Details before Submit for
              Approval is available.
            </span>
          </div>
        </>
      );
    } else if (other_payee === true && other_payee_recipient === false) {
      return (
        <>
          <div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                position: "relative",
                left: "300px",
              }}
            >
              Note: Must submit Other Payee Details, Payee Recipient, Other
              Payee Account Number and Payee Recipient Mailing Address Details
              before Submit for Approval is available.
            </span>
          </div>
        </>
      );
    } else if (other_payee === false && other_payee_recipient === true) {
      return (
        <>
          <div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                position: "relative",
                left: "500px",
              }}
            >
              Note: Must submit Payee, Other Payee Recipient Details before
              Submit for Approval is available
            </span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                position: "relative",
                left: "380px",
              }}
            >
              Note: Must submit Payee , Payee Recipient and Payee Recipient
              Mailing Address Details before Submit for Approval is available.
            </span>
          </div>
        </>
      );
    }
  };

  getAssessments = (hoaId) => {
    let actionHoaId = hoaId ? hoaId : this.props.actionHoaId;
    if (actionHoaId) {
      apicall
        .fetchPropertyAssessments(this.props.propertyID, actionHoaId)
        .then((response) => {
          this.setState({
            assessments: response,
          });
          // console.log("assessments===>", response);
        });
    }
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  toggleAddressModal = () => {
    this.setState({
      addressModal: !this.state.addressModal,
    });
  };

  toggleOtherPayeeAddressModal = () => {
    this.setState({
      otherPayeeAddressModal: !this.state.otherPayeeAddressModal,
    });
  };

  fetchPayment = () => {
    if (this.props.actionTab === "municipalityAction") {
      apicall
        .fetchMunicipalPaymentData(
          this.state.actionID,
          this.props.municipality_id
        )
        .then((response) => {
          var row = this.createOptions(response);
          this.setState({
            row: row,
            isLoading: false,
          });
        });
    } else {
      apicall
        .fetchPaymentData(this.state.actionID ? this.state.actionID : this.props.row.id, this.props.propertyID)
        .then((response) => {
          var row = this.createOptions(response);
          this.setState({
            row: row,
            isLoading: false,
          });
        });
    }
  };

  deletePaymentDocument = (documentId) => {
    this.setState({ isLoading: true });
    apicall
      .deletePaymentDoc(this.props.propertyID, this.state.row.id, documentId)
      .then((response) => {
        helpers.notify("Document deleted successfully");
        this.fetchPayment();
      });
  };

  componentDidMount() {
    this.fetchPayment();
    this.props.dashboardProperty && this.getPropertyInfo();
    this.getAssessments();
  }

  createOptions = (row) => {
    if (row.id && row.payee) {
      let option = {};
      option["value"] = row.payee["id"];
      option["label"] = row.payee["name"];
      if (option) row.payee_id = option;
    }
    if (row.id && row.payee_recipient) {
      let option = {};
      option["value"] = row.payee_recipient["id"];
      option["label"] = row.payee_recipient["name"];
      if (option) row.payee_recipient_id = option;
    }
    if (row.id && row.negotiated_by) {
      let option = {};
      option["value"] = row.negotiated_by["id"];
      option["label"] = row.negotiated_by["email"];
      if (option) row.negotiated_user_id = option;
    }
    if (_.isEmpty(row.payment_type)) {
      row.payment_type = this.props.row.description;
    }
    if (row.id && row.associable) {
      let option = {};
      option["value"] = row.associable["id"];
      option["label"] = row.associable["name"];
      if (option) row.associable_id = option;
    }
    return row;
  };

  componentWillMount() {
    this.hideSubmit();
    // if(config['paymentRead'].includes(this.props.userRole)){
    //     this.hideSubmit()
    // }
    apicall.fetchPayee(this.props.propertyID).then((response) => {
      this.setState({
        payeeRecipients: response,
      });
    });

    apicall.fetchPaymentHoas(this.props.propertyID).then((response) => {
      this.setState({
        paymentHoas: response.Hoa,
        paymentMunicipals: response.Office,
      });
    });
  }

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">
              {(this.state.row && this.state.row.id ? "Update " : "Add ") +
                this.state.title}
            </span>
            {this.state.row.action_status === "Check Sent" && (
              <Button
                style={{ marginTop: "0px", marginBottom: "0px" }}
                onClick={() =>
                  this.setState({ openPaymentCheckSentDocument: true })
                }
              >
                Check Sent Status Upload
              </Button>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm autoComplete="off">
            {this.formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  updatePayment = (payment) => {
    let row = this.createOptions(payment);
    this.setState({
      row: row,
      isLoading: false,
    });
  };

  handleSubmit = (event, isApproval) => {
    this.setState({
      isLoading: true,
    });
    var row = this.state.row;
    // row.payee_id = this.getSelectionValue(row.payee_id);
    // row.payee_recipient_id = this.getSelectionValue(row.payee_recipient_id);
    // row.negotiated_user_id = this.getSelectionValue(row.negotiated_user_id);
    // if (row["other_payee"]) {
    //   delete row["payee_id"];
    // }
    // if (row["other_payee_recipient"]) {
    //   delete row["payee_recipient_id"];
    // }
    // if (row["payee_id"]) {
    //   delete row["other_payee_details"];
    // }
    // if (row["payee_recipient"]) {
    //   delete row["other_payee_recipient_details"];
    // }
    // delete row["payee"];
    // delete row["payee_recipient"];
    // delete row["negotiated_by"];
    // for (let key in row) {
    //   if (row[key] == null) delete row[key];
    // }
    if (isApproval === true) {
      row.submit_for_approval = true;
    }
    if (this.props.actionTab === "municipalityAction") {
      apicall
        .municipal_paymentUpdate(
          row,
          this.state.municipality_id,
          this.state.actionID
        )
        .then((response) => {
          if (response) {
            this.notify("Payment Updated Successfully.");
            let row = this.createOptions(response);
            this.setState({
              row: row,
              isLoading: false,
            });
            this.toggleModal();
            // this.props.refershActions(this.state.propertyID);
          }
        });
    } else {
      apicall
        .paymentUpdate(row, this.state.propertyID, this.state.actionID)
        .then((response) => {
          if (response) {
            this.notify("Payment Updated Successfully.");
            let row = this.createOptions(response);
            this.setState({
              row: row,
              isLoading: false,
            });
            this.toggleModal();
            // this.props.refershActions(this.state.propertyID);
          }
        });
    }

    let payment_status_comment = row.payment_status_comment;
    let payments_status = row.payment_status;
    let submit_approval_comment = row.submit_approval_comment;
    let commentsArray = [];
    let approval_comment = null;
    let status_comment = null;
    if (submit_approval_comment) {
      approval_comment = {
        comment: submit_approval_comment,
        comment_type: "submit_approval",
      };
      commentsArray.push(approval_comment);
    }
    if (payments_status && payment_status_comment) {
      status_comment = {
        comment: payment_status_comment,
        comment_type: config["commentType"][payments_status],
      };
      commentsArray.push(status_comment);
    }

    if (!_.isEmpty(commentsArray)) {
      let comments = {
        comments: {
          comments: commentsArray,
        },
      };
      apicall.addComments(this.state.actionID, comments).then((response) => {
        this.fetchPayment();
      });
    }
  };

  formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  handleSelection = (valuekey, valueObj, group) => {
    let newObject = {};
    if (group === "associable_type") {
      newObject = {
        payee_id: null,
        payee_recipient_id: null,
      };
    }
    this.setState({
      row: Object.assign(this.state.row, { [valuekey]: valueObj }),
      row: Object.assign(this.state.row, { [group]: valueObj["group"] }),
      row: Object.assign(this.state.row, { ...newObject }),
    });
  };

  updateAddress = (data, id) => {
    let newVal;
    if (this.state.row.payee_recipient_type === "Office") {
      newVal = this.state.payeeRecipients[
        this.state.row.payee_recipient_type
      ].map((x) => {
        let mappedOfficeData = data.offices.filter((data) => data.id === id);
        if (x.id == mappedOfficeData[0].id) {
          x.ofc_payee_name = mappedOfficeData[0].ofc_payee_name;
          x.payee_co_name = mappedOfficeData[0].payee_co_name;
          x.mailing_address = mappedOfficeData[0].mailing_address;
          x.mailing_city = mappedOfficeData[0].mailing_city;
          x.mailing_state = mappedOfficeData[0].mailing_state;
          x.mailing_zip = mappedOfficeData[0].mailing_zip;
        }
        return x;
      });
    } else {
      newVal = this.state.payeeRecipients[
        this.state.row.payee_recipient_type
      ].map((x) => {
        if (x.id == data.id) {
          x.payment_address_care_of = data.payment_address_care_of;
          x.payment_mailing_street = data.payment_mailing_street;
          x.payment_mailing_city = data.payment_mailing_city;
          x.payment_mailing_state = data.payment_mailing_state;
          x.payment_mailing_zipcode = data.payment_mailing_zipcode;
        }
        return x;
      });
    }
    // const payeeRecipients = {...this.state.payeeRecipients, this.state.row[payee_recipient_type]: newVal}
    const payeeRecipients = Object.assign(this.state.payeeRecipients, {
      [this.state.row.payee_recipient_type]: newVal,
    });
    this.setState({ payeeRecipients: payeeRecipients });
  };

  getAddressAndAccount = (id) => {
    let data = null;
    this.state.payeeRecipients[this.state.row.payee_recipient_type].map((x) => {
      if (x.id == id) {
        data = x;
        return;
      }
    });
    return data;
  };

  getPayeeRecipientCareOf = (payee_recipient_type, payee_recipient) => {
    const { payeeRecipients } = this.state;
    let payee_recipient_id =
      !_.isEmpty(payee_recipient) && _.isObject(payee_recipient)
        ? payee_recipient && payee_recipient["value"]
        : payee_recipient;

    let careOf = "";

    if (
      !_.isEmpty(payeeRecipients) &&
      !_.isEmpty(payee_recipient_type) &&
      !_.isEmpty(payeeRecipients[payee_recipient_type])
    ) {
      let p_a_c = payeeRecipients[payee_recipient_type].find(
        (x) => x.id == payee_recipient_id
      );
      if (p_a_c !== undefined && p_a_c !== null)
        if (payee_recipient_type === "Office") {
          careOf = p_a_c.payee_co_name;
        } else careOf = p_a_c.payment_address_care_of;
      return careOf;
    }
  };

  payeeRecipientsAddress = (
    payee_recipient_type,
    payee_recipient,
    getAdressObject
  ) => {
    let payeeRecipients = this.state.payeeRecipients;
    let payee_recipient_id = payee_recipient && payee_recipient["value"];
    let selectedRecipientAddress = "";
    if (
      payeeRecipients !== null &&
      typeof payeeRecipients !== "undefined" &&
      typeof payeeRecipients[payee_recipient_type] !== "undefined" &&
      payeeRecipients[payee_recipient_type] !== null &&
      payeeRecipients[payee_recipient_type].length > 0
    ) {
      selectedRecipientAddress =
        payee_recipient_type === "Office"
          ? payeeRecipients[payee_recipient_type].map((object) => {
            if (object.id == payee_recipient_id)
              return !getAdressObject ? (
                object.mailing_address === null ? (
                  <span>"Address not present"</span>
                ) : (
                  <div>
                    {/* <span>{object.payment_address_care_of + " "}</span> */}
                    {/* <br /> */}
                    <span>{object.mailing_address + " "} </span> <br />
                    <span>
                      {object.mailing_city +
                        ", " +
                        object.mailing_state +
                        " " +
                        object.mailing_zip}
                    </span>
                  </div>
                )
              ) : object["mailing_address"] ? (
                true
              ) : (
                false
              );
          })
          : payeeRecipients[payee_recipient_type].map((object) => {
            if (object.id == payee_recipient_id)
              return !getAdressObject ? (
                object.payment_mailing_street === null ? (
                  <span>"Address not present"</span>
                ) : (
                  <div>
                    {/* <span>{object.payment_address_care_of + " "}</span> */}
                    {/* <br /> */}
                    <span>{object.payment_mailing_street + " "} </span> <br />
                    <span>
                      {object.payment_mailing_city +
                        ", " +
                        object.payment_mailing_state +
                        " " +
                        object.payment_mailing_zipcode}
                    </span>
                  </div>
                )
              ) : object["payment_mailing_street"] ? (
                true
              ) : (
                false
              );
          });
    }
    return selectedRecipientAddress;
  };

  toggleDocument = () => {
    this.setState({
      openAddDocumentModal: !this.state.openAddDocumentModal,
    });
  };

  togglePaymentCheckSentDocument = () => {
    this.setState({
      openPaymentCheckSentDocument: !this.state.openPaymentCheckSentDocument,
    });
  };

  renderPaymentHistory = (settlementStatus, reasons) => {
    let payment_histories = this.state.row.payment_histories;
    return payment_histories.map((history, index) => {
      return (
        <FormGroup row className="section" key={history.id}>
          {index == 0 && (
            <div className="fields">
              <label className="">Payment Approved Date</label>
              <div className="">
                <Input
                  value={
                    this.state.row.payment_approved_date
                      ? dayjs(this.state.row.payment_approved_date).format(
                        config.US_DATE_FORMAT
                      )
                      : ""
                  }
                ></Input>
              </div>
            </div>
          )}
           <div className={`fields ${index != 0 ? "add-margin" : ""}`} >
            <label className="payment-status-label" for="sent_to_accounting_date">
              Sent to Accounting date
            </label>
            <div className="payment-status-input">
              <DatePicker
                id="sent_to_accounting_date"
                selected={
                  history.sent_to_accounting_date
                    ? helpers.restructureDate(history.sent_to_accounting_date)
                    : null
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="fields">
            <label className="payment-status-label" for="payment_sent_date">
              Payment Sent Date
            </label>
            <div className="payment-status-input">
              <DatePicker
                id="payment_sent_date"
                selected={
                  history.payment_sent_date
                    ? helpers.restructureDate(history.payment_sent_date)
                    : null
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="fields">
            <label for="" className="checkNumber">
              Check Number
            </label>
            <div className="checkNumber-input">
              <TextValidator
                type="text"
                id="check_transaction_no"
                placeholder="Check transaction Number"
                value={history.check_transaction_no}
                disabled={true}
              />
            </div>
          </div>
          <div className="fields">
            <label for="settlement_date" className="settlementDate">
              Settlement Date
            </label>
            <div className="payment-status-input">
              <DatePicker
                id="cleared_date"
                selected={
                  history.cleared_date && history.cleared_date !== ""
                    ? helpers.restructureDate(history.cleared_date)
                    : null
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="fields" style={{ width: "20%" }}>
            <label for="cleared_date" className="settlementStatus">
              Settlement Status
            </label>
            <div
              className="settlementStatus-inputField"
              style={{ width: "100%" }}
            >
              <Input
                type="select"
                id="settlement_status"
                value={history.settlement_status}
                disabled={true}
              >
                {settlementStatus}
              </Input>
              {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
            </div>
          </div>
          <div className="fields">
            <label for="cleared_date">Reason</label>
            <div className="reason-inputField">
              <Input
                type="select"
                name="reason"
                id="reason"
                value={history.reason}
                disabled={true}
              >
                {reasons}
              </Input>
              {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
            </div>
          </div>
        </FormGroup>
      );
    });
  };

  documentInvoiceComponent = () => {
    let payeeRecipients = this.state.payeeRecipients;
    let hoaOptions =
      payeeRecipients && helpers.formatOptions(payeeRecipients.Hoa, "Hoa");
    let officeOptions =
      payeeRecipients &&
      helpers.formatOptions(this.state.paymentMunicipals, "Office");
    let paymentTypes = [];
    paymentTypes.push(
      <option value="" key="defaultValue">
        Select...{" "}
      </option>
    );
    for (let paymentType in this.state.paymentTypes) {
      paymentTypes.push(
        <option value={paymentType} key={paymentType}>
          {this.state.paymentTypes[paymentType]}
        </option>
      );
    }

    let PayeeTypeOptions = [];
    PayeeTypeOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    for (let payeeType in config.payeeTypes) {
      PayeeTypeOptions.push(
        <option value={payeeType} key={payeeType}>
          {config.payeeTypes[payeeType]}
        </option>
      );
    }
    let settlement_status =
      this.state.row.status !== "new" &&
        this.state.row.settlement_status == "cleared"
        ? true
        : false;
    let isDisabled =
      config["paymentRead"].includes(this.props.userRole) ||
      this.state.row.eligible_to_approve_or_reject ||
      (!this.state.row.eligible_to_approve_or_reject &&
        this.state.row.rejected_user) ||
      this.state.row.action_status == "Awaiting Approval" ||
      settlement_status;
    let disableSettlementStatus =
      config["paymentRead"].includes(this.props.userRole) ||
      this.state.row.eligible_to_approve_or_reject ||
      (!this.state.row.eligible_to_approve_or_reject &&
        this.state.row.rejected_user) ||
      this.state.row.action_status == "Awaiting Approval";
    // let isDisabled =
    //   config["paymentRead"].includes(this.props.userRole) ||
    //   this.state.row.action_status == "Awaiting Approval";
    console.log(isDisabled);

    let settlementStatus = [];
    settlementStatus.push(
      <option value="" key="defaultValue">
        Select Settlement
      </option>
    );
    for (let settlement in config.payment_settlementStatus) {
      settlementStatus.push(
        <option value={settlement} key={settlement}>
          {config.payment_settlementStatus[settlement]}
        </option>
      );
    }

    let reasons = [];
    reasons.push(
      <option value="" key="defaultValue">
        Select Reasons
      </option>
    );
    for (let reason in config.payment_reason) {
      reasons.push(
        <option value={reason} key={reason}>
          {config.payment_reason[reason]}
        </option>
      );
    }
    console.log("test--->", _.isEmpty(this.state.row.payment_histories));
    return (
      <>
        {this.state.isLoading ? (
          <LoaderComponent />
        ) : (
          <div className="payment-section">
            <div className="payment-form">
              <div className="heading">Document/Invoice</div>
              {this.renderPaymentHistory(settlementStatus, reasons)}
              <FormGroup row className="section">
                {_.isEmpty(this.state.row.payment_histories) && (
                  <div className="fields">
                    <label className="">Payment Approved Date</label>
                    <div className="">
                      <Input
                        value={
                          this.state.row.payment_approved_date
                            ? dayjs(
                              this.state.row.payment_approved_date
                            ).format(config.US_DATE_FORMAT)
                            : ""
                        }
                      ></Input>
                    </div>
                  </div>
                )}
                <div
                  className={`fields ${_.isEmpty(this.state.row.payment_histories)
                    ? ""
                    : "add-margin"
                    }`}
                >
                  <label
                    className="payment-status-label"
                    for="sent_to_accounting_date"
                  >
                    Sent to Accounting date
                  </label>
                  <div className="payment-status-input">
                    <DatePicker
                      id="sent_to_accounting_date"
                      selected={
                        this.state.row.sent_to_accounting_date
                          ? helpers.restructureDate(
                            this.state.row.sent_to_accounting_date
                          )
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("sent_to_accounting_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label
                    className="payment-status-label"
                    for="payment_sent_date"
                  >
                    Payment Sent Date
                  </label>
                  <div className="payment-status-input">
                    <DatePicker
                      id="payment_sent_date"
                      selected={
                        this.state.row.payment_sent_date
                          ? helpers.restructureDate(
                            this.state.row.payment_sent_date
                          )
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("payment_sent_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label for="" className="checkNumber">
                    Check Number
                  </label>
                  <div className="checkNumber-input">
                    <TextValidator
                      type="text"
                      name="check_transaction_no"
                      id="check_transaction_no"
                      placeholder="Check transaction Number"
                      onChange={this.handleChange}
                      value={this.state.row.check_transaction_no}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label for="settlement_date" className="settlementDate">
                    Settlement Date
                  </label>
                  <div className="payment-status-input">
                    <DatePicker
                      id="cleared_date"
                      selected={
                        this.state.row.cleared_date &&
                          this.state.row.cleared_date !== ""
                          ? helpers.restructureDate(this.state.row.cleared_date)
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("cleared_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields" style={{ width: "20%" }}>
                  <label for="cleared_date" className="settlementStatus">
                    Settlement Status
                  </label>
                  <div
                    className="settlementStatus-inputField"
                    style={{ width: "100%" }}
                  >
                    <Input
                      type="select"
                      name="settlement_status"
                      id="settlement_status"
                      onChange={this.handleChange}
                      disabled={disableSettlementStatus ? true : false}
                      value={this.state.row.settlement_status}
                    >
                      {settlementStatus}
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </div>
                </div>
                <div className="fields">
                  <label for="cleared_date">Reason</label>
                  <div className="reason-inputField">
                    <Input
                      type="select"
                      name="reason"
                      id="reason"
                      onChange={this.handleChange}
                      disabled={isDisabled ? true : false}
                      value={this.state.row.reason}
                    >
                      {reasons}
                    </Input>
                    {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
                  </div>
                </div>
              </FormGroup>
              <FormGroup row className="section">
                <div className="fields">
                  <label className="">Payment Invoice Date</label>
                  <div className="">
                    <DatePicker
                      id="invoice_date"
                      selected={
                        this.state.row.invoice_date
                          ? helpers.restructureDate(this.state.row.invoice_date)
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("invoice_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label className="" for="invoice_received_date">
                    Invoice Received Date
                  </label>
                  <div className="">
                    <DatePicker
                      id="invoice_received_date"
                      selected={
                        this.state.row.invoice_received_date
                          ? helpers.restructureDate(
                            this.state.row.invoice_received_date
                          )
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("invoice_received_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label for="due_date" S>
                    Payment Due Date *
                  </label>
                  <div className="payment-div-date">
                    <DatePicker
                      id="due_date"
                      selected={
                        this.state.row.due_date
                          ? helpers.restructureDate(this.state.row.due_date)
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("due_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label>Invoice Number</label>
                  <div className="">
                    <TextValidator
                      type="text"
                      name="invoice_number"
                      id="invoice_number"
                      placeholder="Invoice Number"
                      onChange={this.handleChange}
                      value={this.state.row.invoice_number}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label>Tracking Number</label>
                  <TextValidator
                    type="text"
                    name="usps_tracking_no"
                    id="usps_tracking_no"
                    placeholder="USPS Tracking Number"
                    onChange={this.handleChange}
                    value={this.state.row.usps_tracking_no}
                    disabled={isDisabled ? true : false}
                  />
                </div>
              </FormGroup>
              <FormGroup
                style={{
                  paddingLeft: "5px",
                  display: "flex",
                }}
              >
                <div
                  className={
                    _.isEmpty(this.state.row.documents)
                      ? "nodocumentUpload"
                      : "documentUpload"
                  }
                >
                  <Button
                    onClick={() =>
                      this.setState({ openAddDocumentModal: true })
                    }
                    disabled={settlement_status}
                  >
                    File Upload
                  </Button>
                  {_.isEmpty(this.state.row.documents) ? (
                    <h6 className="noDocument">No File Uploaded</h6>
                  ) : (
                    <></>
                  )}
                </div>
                {this.state.openAddDocumentModal && (
                  <DocumentsModal
                    toggle={this.toggleDocument}
                    title="Document"
                    open={this.state.openAddDocumentModal}
                    propertyID={this.props.propertyID}
                    pageNo={1}
                    hideDocumentName={true}
                    hoaOptions={hoaOptions}
                    officeOptions={officeOptions}
                    isPayment={true}
                    actionID={this.state.actionID}
                    fetchDocuments={this.fetchPayment}
                    row={this.state.row}
                    municipality_id={this.props.municipality_id}
                    actionTab={this.props.actionTab}
                  />
                )}
                <div className="document-table">
                  <DocumentsTab
                    propertyID={this.props.propertyID}
                    documents={this.state.row.documents}
                    isPayment={true}
                    deleteDocument={this.deletePaymentDocument}
                    theme={"general-info-tab"}
                    paymentDocs={true}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        )}
      </>
    );
  };

  renderAccountNum = (isDisabled) => {
    const payee_id = this.state.row.payee_id;
    if (this.state.row.other_payee === true) {
      return (
        <div className="acc">
          <label for="">Other Payee Account Number *</label>{" "}
          <div className="text">
            <TextValidator
              type="text"
              name="other_account_number"
              id="other_account_number"
              placeholder="Other Account Number"
              onChange={this.handleChange}
              value={this.state.row.other_account_number}
              disabled={isDisabled ? true : false}
            />
          </div>
        </div>
      );
    } else if (!_.isEmpty(payee_id)) {
      let x =
        this.state.payeeRecipients &&
        this.state.payeeRecipients[this.state.row.payee_type].find(
          (x) => x.id === payee_id.value
        );
      return (
        <div className="acc" style={{ marginBottom: "-12px" }}>
          <label for=""></label>
          <div className="text">{x && x.account}</div>
        </div>
      );
    }
  };

  paymentInformationComponent = () => {
    let payeeRecipients = this.state.payeeRecipients;
    let hoaOptions =
      payeeRecipients && helpers.formatOptions(payeeRecipients.Hoa, "Hoa");

    let managementCompanyOptions =
      payeeRecipients &&
      helpers.formatOptions(
        payeeRecipients.ManagementCompany,
        "ManagementCompany"
      );
    let attorneyOptions =
      payeeRecipients &&
      helpers.formatOptions(payeeRecipients.Attorney, "Attorney");
    let officeOptions;
    if (this.state.row.associable_type === "Office") {
      if (!_.isEmpty(payeeRecipients)) {
        officeOptions = this.state.row.associable_id.value
          ? payeeRecipients.Office.filter(
            (x) => x.id === this.state.row.associable_id.value
          )
          : payeeRecipients.Office;
      }
    } else if (!_.isEmpty(payeeRecipients))
      officeOptions = payeeRecipients.Office;
    let municipalityOptions =
      payeeRecipients && helpers.formatOptions(officeOptions, "Office", true);
    let groupedOptions;
    if (
      this.state.row.associable_type !== "Office" &&
      this.state.row.associable_type !== "Hoa"
    ) {
      groupedOptions = [
        {
          label: "Hoas",
          options: hoaOptions,
        },
        {
          label: "Management Companies",
          options: managementCompanyOptions,
        },
        {
          label: "Attorneys",
          options: attorneyOptions,
        },
        {
          label: "Office",
          options: municipalityOptions,
        },
      ];
    } else if (this.state.row.associable_type === "Office") {
      groupedOptions = [
        {
          label: "Office",
          options: municipalityOptions,
        },
      ];
    } else if (this.state.row.associable_type === "Hoa") {
      groupedOptions = [
        {
          label: "Hoas",
          options: hoaOptions,
        },
        {
          label: "Management Companies",
          options: managementCompanyOptions,
        },
        {
          label: "Attorneys",
          options: attorneyOptions,
        },
      ];
    }
    let paymentTypes = [];
    //let i = 0;
    paymentTypes.push(
      <option value="" key="defaultValue">
        Select...{" "}
      </option>
    );
    for (let paymentType in this.state.paymentTypes) {
      paymentTypes.push(
        <option value={paymentType} key={paymentType}>
          {this.state.paymentTypes[paymentType]}
        </option>
      );
    }

    let PayeeTypeOptions = [];
    PayeeTypeOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    for (let payeeType in config.payeeTypes) {
      PayeeTypeOptions.push(
        <option value={payeeType} key={payeeType}>
          {config.payeeTypes[payeeType]}
        </option>
      );
    }
    let saveEligiblityDisabled =
      !this.state.row.eligible_to_approve_or_reject ||
        this.state.row.rejected_user
        ? true
        : false;
    let wrongPayee = false;
    let wrongAmount = false;
    let payment_histories = this.state.row.payment_histories;
    let reasonObj =
      payment_histories && payment_histories[payment_histories.length - 1];
    wrongAmount =
      reasonObj && reasonObj.reason == "wrong_amount" ? true : false;
    wrongPayee = reasonObj && reasonObj.reason == "wrong_payee" ? true : false;
    let settlement_status =
      this.state.row.settlement_status == "cleared" ? true : false;
    let isDisabled =
      config["paymentRead"].includes(this.props.userRole) ||
      this.state.row.eligible_to_approve_or_reject ||
      (!this.state.row.eligible_to_approve_or_reject &&
        this.state.row.rejected_user) ||
      this.state.row.action_status == "Awaiting Approval" ||
      this.state.row.action_status == "Approved" ||
      wrongAmount ||
      settlement_status;

    var propertyInfo = this.props.dashboardProperty
      ? this.state.dasboardPropertyInfo
      : this.props.propertyInfo;
    let row = this.state.row;

    let onsubmitApprovalDisable = isDisabled;
    // console.log(
    //   "isDisabled",
    //   isDisabled || this.state.row.action_status == "Awaiting Approval"
    // );
    return (
      <div className="payment-documents">
        <div className="heading">Payment Information</div>
        <FormGroup className="other-acc">
          {this.renderAccountNum(isDisabled)}
        </FormGroup>
        <FormGroup className="payment-info">
          <div className="question">
            <div className="text">Other Payee</div>
            <div className="radio">
              <CustomInput
                id="other_payee_yes"
                type="radio"
                name="other_payee"
                value={true}
                disabled={isDisabled ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  row["payee_id"] = null;
                  this.setState({ row });
                }}
                checked={this.state.row.other_payee === true ? "checked" : ""}
              />
              <label>Yes</label>
              {/* </div>
            <div> */}
              <CustomInput
                id="other_payee_no"
                type="radio"
                name="other_payee"
                value={false}
                disabled={onsubmitApprovalDisable ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  // row["other_payee_details"] = "";
                  this.setState({ row });
                }}
                checked={this.state.row.other_payee === false ? "checked" : ""}
              />
              <label>No</label>
            </div>
          </div>
          {!this.state.row.other_payee && (
            <div className="payee">
              <label className="">Payee *</label>
              <div className="select-box">
                <Select
                  options={groupedOptions}
                  formatGroupLabel={this.formatGroupLabel}
                  onChange={(value) => {
                    this.handleSelection("payee_id", value, "payee_type");
                  }}
                  value={this.state.row.payee_id}
                  isDisabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}

          {this.state.row.other_payee && (
            <div className="other-payee">
              <label className="">Other Payee Details *</label>
              <div className="select-box">
                <TextValidator
                  type="textarea"
                  name="other_payee_details"
                  id="other_payee_details"
                  placeholder="Details"
                  onChange={this.handleChange}
                  value={this.state.row.other_payee_details}
                  disabled={onsubmitApprovalDisable ? true : false}
                />
              </div>
            </div>
          )}
          <div className="memo">
            <label className="memo-head">Memo</label>
            <div className="memo-details">
              <div>
                {/* <div>Property Address : </div> */}
                <div>
                  {propertyInfo &&
                    propertyInfo.street_address +
                    ", " +
                    propertyInfo.city +
                    ", " +
                    propertyInfo.state +
                    " " +
                    propertyInfo.zip}
                </div>
              </div>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="payment-info">
          <div className="question">
            <div className="text">Other Payee Recipient</div>
            <div className="radio">
              <CustomInput
                id="other_payee_recipient_yes"
                type="radio"
                name="other_payee_recipient"
                value={true}
                disabled={isDisabled ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  row["payee_recipient_id"] = null;
                  this.setState({ row });
                }}
                checked={
                  this.state.row.other_payee_recipient === true ? "checked" : ""
                }
              />
              <label>Yes</label>
              <CustomInput
                id="other_payee_recipient_no"
                type="radio"
                name="other_payee_recipient"
                value={false}
                disabled={onsubmitApprovalDisable ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  // row["other_payee_recipient_details"] = "";
                  this.setState({ row });
                }}
                checked={
                  this.state.row.other_payee_recipient === false
                    ? "checked"
                    : ""
                }
              />
              <label>No</label>
            </div>
          </div>
          {!this.state.row.other_payee_recipient && (
            <div className="payee">
              <label className="otherPayeeRecipientDetails">
                Payee Recipient*
              </label>
              <div className="select-box">
                <Select
                  options={groupedOptions}
                  formatGroupLabel={this.formatGroupLabel}
                  onChange={(value) => {
                    this.handleSelection(
                      "payee_recipient_id",
                      value,
                      "payee_recipient_type"
                    );
                  }}
                  value={this.state.row.payee_recipient_id}
                  isDisabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}

          {this.state.row.other_payee_recipient && (
            <div className="other-payee">
              <label className="otherPayeeRecipientDetails">
                Other Payee Recipient Details *
              </label>
              <div className="select-box">
                <TextValidator
                  type="textarea"
                  name="other_payee_recipient_details"
                  id="other_payee_recipient_details"
                  placeholder="Details"
                  onChange={this.handleChange}
                  value={this.state.row.other_payee_recipient_details}
                  disabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}
          {!this.state.row.other_payee_recipient &&
            this.state.row.payee_recipient_type &&
            this.state.row.payee_recipient_id && (
              <div className="reciepent-addr">
                <div className="care-of">
                  <label
                    className="reciepent-addr-head"
                    style={{ marginTop: "22px" }}
                  >
                    Care Of
                  </label>
                  <div>
                    {this.getPayeeRecipientCareOf(
                      this.state.row.payee_recipient_type,
                      this.state.row.payee_recipient_id
                    )}
                  </div>
                </div>
                <div className="reciepent-addr-data">
                  <label
                    className="reciepent-addr-head"
                    style={{ marginTop: "22px" }}
                  >
                    Mailing Address
                  </label>
                  <div className="reciepent-addr-details">
                    {this.payeeRecipientsAddress(
                      this.state.row.payee_recipient_type,
                      this.state.row.payee_recipient_id
                    )}
                    <div
                      className="memo-edit-icon"
                      style={{ marginTop: "22px" }}
                    >
                      {!isDisabled && (
                        <FaEdit
                          style={{ marginRight: "4%", marginLeft: "20px" }}
                          size="1.5em"
                          onClick={() => this.toggleAddressModal()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {this.state.row.other_payee_recipient && (
            <div className="reciepent-addr">
              <label
                className="reciepent-addr-head"
                style={{ marginTop: "22px" }}
              >
                Mailing Address
              </label>
              <div className="reciepent-addr-details">
                {/* {this.payeeRecipientsAddress(
                  this.state.row.payee_recipient_type,
                  this.state.row.payee_recipient_id
                )} */}
                <div>
                  {/* <span>
                    {this.state.row.other_payee_recipient_care_of + " "}
                  </span> */}
                  {/* <br /> */}
                  <span>
                    {this.state.row.other_payee_recipient_address + " "}{" "}
                  </span>{" "}
                  <br />
                  <span>
                    {this.state.row.other_payee_recipient_city +
                      ", " +
                      this.state.row.other_payee_recipient_state +
                      " " +
                      this.state.row.other_payee_recipient_zip}
                  </span>
                </div>
                <div className="memo-edit-icon" style={{ marginTop: "22px" }}>
                  {!isDisabled && (
                    <FaEdit
                      style={{ marginRight: "4%", marginLeft: "20px" }}
                      size="1.5em"
                      onClick={() => this.toggleOtherPayeeAddressModal()}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </FormGroup>
        <FormGroup row style={{ marginTop: "4%" }}>
          {/* {!isDisabled && ( */}
          <Button
            disabled={!this.saveDisabled()}
            style={{
              position: "absolute",
              left: "38%",
              bottom: "2rem",
            }}
            onClick={this.handleSubmit}
          >
            Save Payment
          </Button>
          {/* )} */}
        </FormGroup>
        <div style={{ position: "relative", bottom: "1rem" }}>
          {this.displayErrorMsg()}
        </div>
      </div>
    );
  };

  handledocumentUpload = (event) => {
    let value = event.target.value;
    if (typeof value === "string") {
      value = helpers.getBoolean(value);
    }
    this.setState({
      [event.target.name]: value,
    });
  };

  updatePayment = (data) => {
    this.setState({
      row: data,
    });
  };

  formElements = () => {
    var isHideCharge = config["chargesHide"].includes(this.props.userRole);
    let row = this.state.row;
    let payeeApproval = false;
    if (
      row["other_payee"] &&
      !_.isEmpty(row["other_payee_details"]) &&
      !_.isEmpty(row["other_account_number"])
    ) {
      payeeApproval = true;
    } else if (!row["other_payee"] && !_.isEmpty(row["payee_id"])) {
      payeeApproval = true;
    }
    let ispayeeRecipientsAdressPresent = this.payeeRecipientsAddress(
      row.payee_recipient_type,
      row.payee_recipient_id,
      true
    );
    // console.log(ispayeeRecipientsAdressPresent);
    let recipientApproval = false;
    if (
      row["other_payee_recipient"] &&
      !_.isEmpty(row["other_payee_recipient_details"]) &&
      !_.isEmpty(row["other_payee_recipient_address"]) &&
      !_.isEmpty(row["other_payee_recipient_state"])
    ) {
      recipientApproval = true;
    } else if (
      !row["other_payee_recipient"] &&
      ispayeeRecipientsAdressPresent.includes(true)
    ) {
      recipientApproval = true;
    }

    return (
      <>
        {this.state.isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {this.documentInvoiceComponent()}
            <hr></hr>
            <Charges
              payment={this.state.row}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              paymentHoas={this.state.paymentHoas}
              handleChange={this.handleChange}
              handleDate={this.handleDateChange}
              userRole={this.props.userRole}
              handleSelection={this.handleSelection}
              handleRadioButton={this.handleRadioButton}
              submitPayment={this.handleSubmit}
              refershActions={this.state.refershActions}
              refreshPayment={this.fetchPayment}
              toggleModal={this.toggleModal}
              hoa_id={this.state.row.hoa_id}
              assessments={this.state.assessments}
              ispayeeRecipientsAdressPresent={
                payeeApproval && recipientApproval
              }
              paymentMunicipals={this.state.paymentMunicipals}
              charge_details={this.state.row.charge_details}
              updatePayment={this.updatePayment}
              actionTab={this.props.actionTab}
              municipality_id={this.props.municipality_id}
            />
            <hr></hr>
            {this.paymentInformationComponent()}
          </>
        )}
      </>
    );
  };

  saveDisabled = () => {
    let row = this.state.row;
    let payeePresent = false;
    if (
      row["other_payee"] &&
      !_.isEmpty(row["other_payee_details"]) &&
      !_.isEmpty(row["other_account_number"])
    ) {
      payeePresent = true;
    } else if (!row["other_payee"] && !_.isEmpty(row["payee_id"])) {
      payeePresent = true;
    }
    let recipientPresent = false;
    if (
      row["other_payee_recipient"] &&
      !_.isEmpty(row["other_payee_recipient_details"])
    ) {
      recipientPresent = true;
    } else if (!_.isEmpty(row.payee_recipient_id)) {
      recipientPresent = true;
    }
    return (
      !helpers.isNull(this.state.row.due_date) &&
      payeePresent &&
      recipientPresent
    );
  };

  render() {
    // console.log(this.state.row);
    return (
      <div>
        <Modal
          className={"area-analyst-form-modal " + this.state.className}
          isOpen={this.state.open}
          toggle={this.toggleModal}
        >
          {this.renderModal()}
        </Modal>

        {this.state.addressModal && (
          <EditAddressModal
            toggle={this.toggleAddressModal.bind(this)}
            title="Hello"
            open={this.state.addressModal}
            propertyID={this.state.propertyID}
            updateAddress={this.updateAddress}
            payee_recipient={this.state.row.payee_recipient_id}
            getAddressAndAccount={this.getAddressAndAccount}
            payee_recipient_type={this.state.row.payee_recipient_type}
            actionTab={this.props.actionTab}
            municipality_id={this.props.municipality_id}
          />
        )}
        {this.state.otherPayeeAddressModal && (
          <EditOtherPayeeAddressModal
            toggle={this.toggleOtherPayeeAddressModal.bind(this)}
            title="Hello"
            open={this.state.otherPayeeAddressModal}
            propertyID={this.state.propertyID}
            payment={this.state.row}
            actionID={this.state.actionID}
            updatePayment={this.updatePayment}
            actionTab={this.props.actionTab}
            municipality_id={this.props.municipality_id}
          />
        )}
        {this.state.openPaymentCheckSentDocument && (
          <PaymentCheckSentDocument
            toggle={this.togglePaymentCheckSentDocument}
            title="Document"
            open={this.state.openPaymentCheckSentDocument}
            propertyID={this.props.propertyID}
            pageNo={1}
            hideDocumentName={true}
            isPayment={true}
            actionID={this.state.actionID}
          //fetchDocuments={this.fetchPayment}
          //row={this.state.row}
          />
        )}
      </div>
    );
  }
}
