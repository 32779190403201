import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";

var managementapi = {
  getManagementCompanies: async function (data, pageNo, perPage) {
    let url = config.baseUrl + "/api/v1/management_companies?";
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // var init = {
    //     method: "GET",
    //     headers: await helpers.defaultHeaders()
    // };
    // if (pageNo) {
    //     url = url + "?page=" + pageNo;
    // }
    // if (perPage)
    //     url = `${url}&per_page=${perPage}`

    // await fetch(url, init)
    //     .then(response => {
    //         if (response.ok) {
    //             return response.json();
    //         }
    //         throw new Error('Something went wrong, while getting analyst' + response)
    //     }).then(companiesList => {
    //         companies = companiesList;
    //     }).catch(function (error) {

    //     })
    // return companies;
  },
  updateManagementCompany: async function (managementCompany) {
    var res = "";
    let url =
      config.baseUrl + "/api/v1/management_companies/" + managementCompany.id;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ management_company: managementCompany }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  createManagementCompany: async function (managementCompany) {
    var res = "";
    let url = config.baseUrl + "/api/v1/management_companies";
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ management_company: managementCompany }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  deleteManagementCompany: async function (id) {
    var res = "";
    let url = config.baseUrl + "/api/v1/management_companies/" + id;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  searchManagementCompanies: async function (
    data,
    searchText,
    role,
    pageNo,
    perPage,
    optimize
  ) {
    let url =
      config.baseUrl + "/api/v1/management_companies/search?q=" + searchText;
    // if (pageNo) {
    //   url = url + "&page=" + pageNo;
    // }
    // if (perPage) {
    //   url = url + "&per_page=" + perPage;
    // }
    if (optimize) {
      url = url + "&optimize=" + optimize;
    }
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + "&" + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // var init = {
    //   method: "GET",
    //   headers: await helpers.defaultHeaders(),
    // };
    // await fetch(url, init)
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(
    //       "Something went wrong, while getting companies list" + response
    //     );
    //   })
    //   .then((companiesList) => {
    //     searchReslutCompanies = companiesList;
    //   })
    //   .catch(function (error) {});
    // return searchReslutCompanies;
  },
  deleteManagementPoc: async function (managementCompanyId, pocId) {
    let url =
      config.baseUrl +
      "/api/v1/management_companies/" +
      managementCompanyId +
      "/destroy_poc?point_of_contact_id=" +
      pocId;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while deleting management POC" + response
        );
      })
      .catch(function (error) {});
  },
  getManagementCompany: async function (managementCompanyId) {
    let url =
      config.baseUrl + "/api/v1/management_companies/" + managementCompanyId;
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getManagementCompany" + response
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
export default withRouter(managementapi);
