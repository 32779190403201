import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Col,
  Row,
} from "reactstrap";

import { ValidatorForm } from "react-form-validator-core";
import _ from "lodash";
import "../../styles/_hearingModal.scss";
import BootstrapTable from "react-bootstrap-table-next";

export default function HearingModal(props) {
  const [row, setrow] = useState({});
  const [listOne, setlistOne] = useState(
    props.row.absentees
      ? _.isArray(props.row.absentees)
        ? props.row.absentees
        : props.row.absentees.split(",")
      : props.pocOptions
      ? props.pocOptions.poc
        ? props.pocOptions.poc
        : props.pocOptions
      : []
  );
  const [listTwo, setlistTwo] = useState(
    props.row.attendees
      ? _.isArray(props.row.attendees)
        ? props.row.attendees
        : props.row.attendees.split(",")
      : []
  );

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value = event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const handleDoubleClick = (value, type, fromArray, toArray) => {
    let newTo = [...toArray];
    newTo.push(value);
    let newFrom = fromArray.filter((item) => item !== value);

    if (type === "1") {
      setlistTwo(newTo);
      setlistOne(newFrom);
    } else if (type === "2") {
      setlistOne(newTo);
      setlistTwo(newFrom);
    }
  };

  const handleSubmit = () => {
    props.handleAttendeesChange(listTwo, listOne);
  };

  const renderNamesRows = (list) => {
    return (
      <>
        {list.map((ele, i) => {
          return (
            <tr
              onDoubleClick={() =>
                handleDoubleClick(ele, "1", listOne, listTwo)
              }
              style={{
                backgroundColor: i % 2 !== 0 ? "lightgray" : "white",
              }}
            >
              <th>{ele}</th>
            </tr>
          );
        })}
      </>
    );
  };

  const renderAttendeesRows = (list) => {
    return (
      <>
        {list.map((ele, i) => {
          return (
            <tr
              onDoubleClick={() =>
                handleDoubleClick(ele, "2", listTwo, listOne)
              }
              style={{
                backgroundColor: i % 2 !== 0 ? "lightgray" : "white",
              }}
            >
              <th>{ele}</th>
            </tr>
          );
        })}
      </>
    );
  };

  const formElements = () => {
    return (
      <div>
        <FormGroup row>
          <Col sm={3}></Col>
          <Col sm={6}>
            <Input
              type="text"
              name={"attendees_name"}
              id={"attendees_name"}
              onChange={handleChange}
              value={row.attendees_name}
            />
          </Col>
          <Col sm={1}>
            <Button
              className="hearing-buttons"
              style={{ float: "right" }}
              onClick={() => {
                let x = listOne;
                x = x.push(row.attendees_name);
                setlistOne([...listOne], x);
              }}
            >
              Add
            </Button>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={6}>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{"Name"}</th>
                </tr>
              </thead>
              <tbody>{renderNamesRows(listOne)}</tbody>
            </table>
          </Col>
          <Col sm={6}>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{"Attendees"}</th>
                </tr>
              </thead>
              <tbody>{renderAttendeesRows(listTwo)}</tbody>
            </table>
          </Col>
        </FormGroup>

        <div style={{ width: "55%" }}>
          <Button style={{ float: "right" }} onClick={() => handleSubmit()}>
            Submit
          </Button>
        </div>
      </div>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Attendees</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="attendees-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
