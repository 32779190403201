import React, { useState, useEffect } from "react";
import config from "../../utils/config";
import apicall from "../../utils/apicall";
//import _ from 'lodash'
import DatePicker from "react-datepicker";
import { FormGroup, Label, Button } from "reactstrap";
import GuestUserNavbar from "../page_layout_components/GuestUserNavbar";
import PropertyInfo from "../general_info/PropertyInfo";
export default (props) => {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [propertyInfo, setpropertyInfo] = useState(null);
  const [hoa_status, sethoa_status] = useState();
  const [leasing_permitted, setleasing_permitted] = useState();
  const [leasing_info_applicable, setleasing_info_applicable] = useState();
  const [hoaId, sethoaId] = useState();

  useEffect(() => {
    //fetchDocuments();
    fetchPropertyInfo();
    getPropertyInfo(props.match.params.propertyID);
  }, []);

  const fetchPropertyInfo = () => {
    apicall
      .guestPropertyInfo(props.match.params.propertyID)
      .then((response) => {
        if (response) {
          let masterHOA = null;
          if (response && response.total == 1) {
            masterHOA = response.results[0];
          } else if (response && response.total > 1) {
            masterHOA = response.results.find((x) => x.status === "Master");
          }
        if(masterHOA){
          sethoaId(masterHOA.id);
          setleasing_info_applicable(masterHOA.leasing_info_applicable);
          sethoa_status(response.hoa_status);
          setleasing_permitted(response.leasing_permitted);
        }
        }
      });
  };

  const getPropertyInfo = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      setpropertyInfo(response);
    });
  };
  let reportsMap = config.reportsMap;
  let reportsDiv = Object.keys(reportsMap).map((reportKey, index) => {
    return (
      <li>
        <a
          id={reportKey + index}
          href="#"
          onClick={(e) => {
            apicall.downloadReports(reportKey, start_date, end_date);
            e.preventDefault();
          }}
        >
          {reportsMap[reportKey]}
        </a>
      </li>
    );
  });
  console.log(start_date);

  return (
    <>
      {" "}
      <GuestUserNavbar
         //isGuestUser={true}
         isGuestUserReports={props.match.params.propertyID !==undefined?true:false}
        propertyID={props.match.params.propertyID}
        {...props}
      />
      <PropertyInfo
        propertyInfo={propertyInfo}
        // actionArray={actionArray}
        className="property-data"
        hideLink={true}
        isGuestUser={true}
        hoa_status={hoa_status}
        isHoaStatus={true}
        leasing_permitted={leasing_permitted}
        leasing_info_applicable={leasing_info_applicable}
        hoaId={hoaId}
      />
      <section style={{ marginTop: props.match.params.propertyID ===undefined?"100px":"0px", marginLeft: "3%"}}>
        <h1>Reports</h1>
        {/* <FormGroup>
          <Label for="" style={{ marginRight: "20px" }}>
            Start Date
          </Label>
          <DatePicker
            id="start_date"
            selected={start_date ? new Date(start_date) : null}
            onChange={(date) => {
              setStartDate(date);
            }}
            //   dateFormat={config.US_DATE_FORMAT}
          />
        </FormGroup>
        <FormGroup> 
          <Label for="" style={{ marginRight: "25px" }}>
            End Date
          </Label>
          <DatePicker
            id="end_date"
            selected={end_date ? new Date(end_date) : null}
            onChange={(date) => {
              setEndDate(date);
            }}
            //   dateFormat={config.US_DATE_FORMAT}
          />
          <Button
            style={{
              marginLeft: "3rem",
              marginTop: "0px",
              marginBottom: "0px",
            }}
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          >
            Clear
          </Button>
        </FormGroup>*/}

        <ul>{reportsDiv}</ul>
      </section>
    </>
  );
};
