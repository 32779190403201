import React, { Component } from "react";
import ListComponent from "../crud/ListComp";
import AssociationModal from "./AssocaitionModal";
import apicall from "../../utils/apicall";
import { toast } from "react-toastify";
import { FaEdit, FaTrashAlt, FaBan } from "react-icons/fa";
import config from "../../utils/config";
import ConfirmBox from "../../components/helper_components/ConfirmBox";
import helpers from "../../utils/helpers";

export default class ListTable extends ListComponent {
  buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (config["hoa_mc_access"].includes(this.props.userRole)) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <FaEdit
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => {
              this.setState({
                // row: row,
                row: {
                  ...row,
                  management_company_id: {
                    value: row.management_company_id,
                    label:
                      row.management_company && row.management_company.name,
                  },
                },
                openFormModal: true,
              });
            }}
          />
          <FaTrashAlt
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => this.setState({ row, deleteModal: true })}
          />
        </div>
      );
    } else {
      return <FaBan className="font-awesome-disabled" size="1.6em" />;
    }
  };

  columns = () => {
    return [
      {
        dataField: "name",
        text: "Name",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "mailing_contact_name",
        text: "Mailing Contact Name",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
      },
      {
        dataField: "contact_email",
        text: "Contact Email",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
        headerStyle: (colum, colIndex) => {
          return { width: "3%" };
        },
      },
      {
        dataField: "contact_phone",
        text: "Contact Phone",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
        headerStyle: (colum, colIndex) => {
          return { width: "3%" };
        },
      },
      {
        dataField: "contact_fax",
        text: "Contact Fax",
        headerClasses: "table-header-cell-alignment",
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          this.props.sortColumns(obj);
        },
        headerStyle: (colum, colIndex) => {
          return { width: "2%" };
        },
      },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.buttonFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 200, class: "table-actions" },
        headerClasses: "table-header-cell-alignment",
        headerStyle: (colum, colIndex) => {
          return { width: "1%" };
        },
      },
    ];
  };

  deleteRecord = (row) => {
    apicall.deleteHoa(row.id).then((response) => {
      if (response) {
        toast("Deleted successfully");
        this.state.refreshList();
      }
    });
  };

  toggleConfirmBox = () =>
    this.setState({ toggleConfirmBox: !this.state.toggleConfirmBox });

  formComp = () => {
    return (
      <React.Fragment>
        {this.state.openFormModal && (
          <AssociationModal
            toggle={this.toggleEditFormModal}
            toggleConfirmBox={this.toggleConfirmBox}
            title="HOA"
            open={this.state.openFormModal}
            setRow={(row) => this.setState({ row })}
            row={this.state.row}
            fyArray={
              this.state.row.assessments ? this.state.row.assessments : []
            }
          />
        )}

        {this.state.toggleConfirmBox && (
          <ConfirmBox
            onYesClick={() => {
              apicall
                .generatePaymentActions(this.state.row.id)
                .then(() => {
                  helpers.notify("Payment action created.");
                  this.toggleConfirmBox();
                })
                .catch((e) => console.log(e));
            }}
            onNoClick={this.toggleConfirmBox}
            heading={<span>Create payment action?</span>}
          >
            <h6>
              Due date before 60 days, create payment action for related
              properties?
            </h6>
          </ConfirmBox>
        )}
        {this.state.deleteModal && (
          <ConfirmBox
            onYesClick={() => {
              this.deleteRecord(this.state.row);
              this.setState({ deleteModal: false });
            }}
            onNoClick={() => this.setState({ deleteModal: false })}
            heading={
              <span>
                Are you sure you want to delete the HOA: {this.state.row.name}?
              </span>
            }
          >
            <div>
              <p>All HOA related details from properties will be deleted.</p>
            </div>
          </ConfirmBox>
        )}
      </React.Fragment>
    );
  };
}
