import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FaEdit, FaTrashAlt, FaBan } from "react-icons/fa";
import PaginationComponent from "../helper_components/PaginationComponent";
import apicall from "../../utils/apicall";
import ConfirmBox from "../helper_components/ConfirmBox";
import TitleVendorCreationModal from "./TitleVendorCreationModal";
import helpers from "../../utils/helpers";
export default (props) => {
  const [openFormModal, setopenFormModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [formdata, setformdata] = useState();

  const toggleEditModal = () => {
    seteditModal(!editModal);
  };

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            seteditModal(true);
            setopenFormModal(true);
          }}
        />
        <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            setformdata(row);
            setdeleteModal(true);
            //this.setState({ row, deleteModal: true });
          }}
        />
      </div>
    );
  };

  const deleteRecord = (formdata) => {
    apicall.deleteVendorCreation(formdata.id).then((status) => {
      if (status < 400) {
        helpers.notify("Deleted successfully");
        props.getVendors();
      }
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Vendor Name",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "short_code",
      text: "Vendor code",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },

    {
      dataField: "address",
      text: "Address",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "order_email",
      text: "Order Email ID",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "contact",
      text: "Contact Number",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        props.sortColumns(obj);
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: buttonFormatter,
      headerAttrs: { width: 200 },
      attrs: { width: 200, class: "table-actions" },
    },
  ];

  return (
    <React.Fragment>
      <PaginationComponent
        columns={columns}
        rows={props.vendors}
        handleSubmit={props.getVendors}
        pageNo={props.pageNo}
        totalCount={props.totalCount}
      />
      {deleteModal && (
        <ConfirmBox
          onYesClick={() => {
            deleteRecord(formdata);
            setdeleteModal(false);
          }}
          onNoClick={() => setdeleteModal(false)}
          heading={
            <span>
              Are you sure you want to delete the Vendor: {formdata.name}?
            </span>
          }
        >
          <div>
            <p>All vendor related details from properties will be deleted.</p>
          </div>
        </ConfirmBox>
      )}
      {editModal && (
        <TitleVendorCreationModal
          toggle={toggleEditModal}
          modal={editModal}
          getVendors={props.getVendors}
          formdata={formdata}
        />
      )}
    </React.Fragment>
  );
};
