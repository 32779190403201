import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import apicall from "../../utils/apicall";
import config from "../../utils/config";
import helpers from "../../utils/helpers";
import {
  FormGroup,
  Label,
  Input,
  Tooltip,
  CustomInput,
  Col,
  Button,
} from "reactstrap";
import { FaBan, FaTrashAlt } from "react-icons/fa";
//import TextValidator from "../helper_components/TextValidator";
import AsyncSelect from "../helper_components/HoaAsyncSelect";
import _ from "lodash";
import LoaderComponent from "../helper_components/LoaderComponent";
import SelectOptions from "../helper_components/SelectOptions";
import Select from "react-select";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

export default class Charges extends React.Component {
  constructor(props) {
    super(props);
    //  this.state.charges = [];
    this.state = {
      payment: props.payment,
      propertyID: props.propertyID,
      actionID: props.actionID,
      paymentHoas: props.paymentHoas,
      paymentMunicipals: props.paymentMunicipals,
      charges: props.payment.charges
        ? props.payment.charges.map((x) => {
            x.amount_payable = parseFloat(x.amount_payable).toFixed(2);
            x.original_amount = parseFloat(x.original_amount).toFixed(2);
            return x;
          })
        : [],
      isLoading: false,
      hoa_id: props.hoa_id,
      updateAssessment: false,
      refershActions: props.refershActions,
      ispayeeRecipientsAdressPresent: props.ispayeeRecipientsAdressPresent,
      isDocumentPresent: props.payment.documents.length > 0 ? true : false,
    };
    // console.log("The props are", props);
  }

  componentDidMount() {
    // this.getHoa();

    if (
      _.isEmpty(this.props.payment.charges) &&
      this.props.payment.payment_type === "fy_assessment"
    )
      this.createDefaultCharges(
        this.props.assessments.assessments,
        this.props.assessments.fy_start
      );
    if (
      _.isEmpty(this.props.payment.charges) &&
      !_.isEmpty(this.props.charge_details)
    ) {
      this.handleAddChargeDeatils(this.props.charge_details);
    }
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      let newCharges = newprops.payment.charges ? newprops.payment.charges : [];
      let newPayment = newprops.payment;
      let newAssessments = newprops.assessments.assessments;
      this.setState({
        charges: newCharges,
        payment: newPayment,
        updateAssessment: true,
        ispayeeRecipientsAdressPresent: newprops.ispayeeRecipientsAdressPresent,
      });
      if (
        _.isEmpty(newCharges) &&
        !_.isEmpty(newAssessments) &&
        newprops.payment.payment_type === "fy_assessment"
      ) {
        this.createDefaultCharges(
          newAssessments,
          newprops.assessments.fy_start
        );
      }
      this.getHoa();

      if (
        _.isEmpty(newprops.payment.charges) &&
        !_.isEmpty(newprops.charge_details)
      ) {
        this.handleAddChargeDeatils(newprops.charge_details);
      }
    }
    // if(newprops.payment.payment_comments !== this.props.payment.payment_comments){
    //     let newPayment = newprops.payment;
    //     this.setState({
    //         payment:newPayment
    //     })
    // }
  }

  getHoa = () => {
    if (this.state.hoa_id) {
      apicall.getHoa(this.state.hoa_id).then((resp) => {});
    }
  };

  createDefaultCharges = (assessments, fyStart) => {
    assessments &&
      assessments.map((assessment, index) => {
        this.handleAddEvent(assessment, fyStart);
      });
  };

  handleAddChargeDeatils(chargeData) {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);

    var charge = {
      id: id,
      original_amount: chargeData ? chargeData[0].original_amount : "",
      responsibility: "Owner",
      charge_type: "",
      periodicity: chargeData
        ? chargeData[0].periodicity !== null
          ? chargeData[0].periodicity
          : ""
        : "",
      start_date: null,
      end_date: null,
      dummy: "",
      statutory_adjustment: "",
      amount_payable: "",
      negotiation_adjustment: "",
      status: "New",
    };
    let chargedata = [];
    chargedata.push(charge);

    //this.state.charges.push(charge);
    this.setState({ charges: chargedata });
  }

  calculateFyEnddate = (start_date, assessment) => {
    let end_date;
    if (assessment.periodicity === "month")
      end_date = dayjs(start_date).add(1, "month").subtract(1, "day");
    else if (assessment.periodicity === "annual")
      end_date = dayjs(start_date).add(1, "year").subtract(1, "day");
    else if (assessment.periodicity === "quarter")
      end_date = dayjs(start_date).add(4, "month").subtract(1, "day");
    else if (assessment.periodicity === "week")
      end_date = dayjs(start_date).add(1, "week").subtract(1, "day");
    else if (assessment.periodicity === "semi-annual")
      end_date = dayjs(start_date).add(6, "month").subtract(1, "day");
    else if (assessment.periodicity === "bi-week")
      end_date = dayjs(start_date).add(2, "week").subtract(1, "day");
    else if (assessment.periodicity === "day") end_date = start_date;
    return end_date;
  };

  handleAddEvent(assessment, fyStart) {
    var today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();
    let fymonth = config.Months_Array.indexOf(fyStart);
    let start_date = today;
    if (currentMonth >= fymonth) {
      start_date = new Date(currentYear + 1, fymonth, 1);
    } else {
      start_date = new Date(currentYear, fymonth, 1);
    }
    if (!fyStart) start_date = today;
    let end_date =
      start_date && this.calculateFyEnddate(start_date, assessment);

    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);

    var charge = {
      id: id,
      responsibility: "Owner",
      charge_type: assessment ? assessment.type_of_charge : "",
      periodicity: assessment ? assessment.periodicity : "",
      start_date: start_date ? helpers.restructureDate(start_date) : null,
      end_date: end_date,
      dummy: "",
      original_amount: assessment ? assessment.amount : "",
      statutory_adjustment: "",
      amount_payable: "",
      negotiation_adjustment: "",
      status: "New",
    };
    this.state.charges.push(charge);
    this.setState(this.state.charges);
  }

  removeCharge = (chargeId) => {
    let charge = this.state.charges;
    let newCharge = charge.filter((x) => x.id !== chargeId);
    this.setState({
      charges: newCharge,
    });
  };

  updateCharge = (newPayment, isApproval) => {
    newPayment.due_date = this.state.payment.due_date;
    newPayment.payment_type = this.state.payment.payment_type;

    if (this.props.actionTab === "municipalityAction") {
      apicall
        .municipal_paymentUpdate(
          newPayment,
          this.props.municipality_id,
          this.state.actionID
        )
        .then((response) => {
          if (response) {
            if (isApproval) helpers.notify("Payment Submitted for Approval.");
            else helpers.notify("Charges Saved.");

            this.setState({ isLoading: false });
            this.props.updatePayment(response);
          }
        });
    } else {
      apicall
        .paymentUpdate(newPayment, this.state.propertyID, this.state.actionID)
        .then((response) => {
          if (response) {
            if (isApproval) helpers.notify("Payment Submitted for Approval.");
            else helpers.notify("Charges Saved.");
            // this.setState({
            //   payment: response,
            //   charges: response.charges,
            //   isLoading: false,
            // });
            this.setState({ isLoading: false });
            this.props.updatePayment(response);
          }
        });
    }
  };

  handleApproved = (event) => {
    // this.updateCharge(paymentApproved, true);
    this.props.submitPayment(event, true);
    this.props.refershActions(this.props.propertyID);
    this.props.toggleModal();
    event.preventDefault();
  };

  handleChargeSubmit = (index) => {
    this.setState({
      isLoading: true,
    });
    delete this.state.charges[index]["status"];
    this.state.charges.map((x) => {
      x.amount_payable = parseFloat(x.amount_payable).toFixed(2);
      x.original_amount = parseFloat(x.original_amount).toFixed(2);
      return x;
    });
    let charge = this.state.charges[index];
    //let payment = this.state.payment;
    let newCharge = {};
    for (let key in charge) {
      let newKey = "charges_attributes[][" + key + "]";
      if (key !== "id") {
        newCharge[newKey] = charge[key];
      } else if (key === "id" && typeof charge[key] !== "string") {
        newCharge[newKey] = charge[key];
      }
    }
    this.updateCharge(newCharge);
  };

  handleDateChange = (id, value, name) => {
    var item = {
      id: id,
      name: name,
      value: dayjs(value).format("YYYY-MM-DD"),
    };
    var charges = this.state.charges.slice();
    var newcharges = charges.map((charge) => {
      for (var key in charge) {
        if (key == item.name && charge.id == item.id) {
          charge[key] = item.value;
        }
        if (
          charge.id == item.id &&
          charge.start_date !== "" &&
          charge.end_date !== "" &&
          charge.periodicity !== "" &&
          charge.original_amount !== "" &&
          charge.amount_payable !== ""
        ) {
          let totalOriginalAmount = this.handleAmountPayable(charge, false);
          let negotiationAdjustment = null;
          if (charge.statutory_adjustment > 0) {
            negotiationAdjustment =
              totalOriginalAmount -
              charge.amount_payable -
              charge.statutory_adjustment;
          } else {
            negotiationAdjustment = totalOriginalAmount - charge.amount_payable;
          }
          if (charge.amount_payable == "") {
            negotiationAdjustment = 0;
          }
          charge["negotiation_adjustment"] = negotiationAdjustment.toFixed(2);
        }
      }
      return charge;
    });
    this.setState({ charges: newcharges });
  };

  handleChargesTable(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var ex = new RegExp(/^\d+(?:\.\d{1,2})?$/);
    if (evt.target.type === "number" && ex.test(evt.target.value) === false) {
      item.value = evt.target.value.substring(0, evt.target.value.length - 1);
    }
    var charges = this.state.charges.slice();
    var newcharges = charges.map((charge) => {
      // if (charge.id == item.id) {
      //     let o = { ...charge }
      //     o[item.name] = item.value
      //     if (charge.start_date !== '' && charge.end_date !== '' && charge.periodicity !== '' &&
      //         charge.original_amount !== '' && charge.negotiation_adjustment !== '') {
      //         let amountPayable = this.handleAmountPayable(charge, false);
      //         o['amount_payable'] = amountPayable;
      //     }
      //     return o
      // } else {
      //     return charge
      // }
      for (var key in charge) {
        if (key == item.name && charge.id == item.id) {
          charge[key] = item.value;
        }
        if (
          charge.id == item.id &&
          charge.start_date !== "" &&
          charge.end_date !== "" &&
          charge.periodicity !== "" &&
          charge.original_amount !== ""
          // &&
          // charge.amount_payable !== ""
        ) {
          let totalOriginalAmount = this.handleAmountPayable(charge, false);
          let negotiationAdjustment = null;
          if (charge.statutory_adjustment > 0) {
            negotiationAdjustment =
              totalOriginalAmount -
              charge.amount_payable -
              charge.statutory_adjustment;
          } else {
            // console.log(totalOriginalAmount, "-", charge.amount_payable);
            negotiationAdjustment = totalOriginalAmount - charge.amount_payable;
          }
          if (charge.amount_payable == "") {
            negotiationAdjustment = 0;
          }
          charge["negotiation_adjustment"] = negotiationAdjustment.toFixed(2);
        }
      }
      return charge;
    });
    this.setState({ charges: newcharges });
  }

  getPeriod = (divident, divisor) => {
    return Math.ceil(divident / divisor);
  };

  handleAmountPayable = (charge, getPeriod) => {
    let startDate = dayjs(charge.start_date);
    let endDate = dayjs(charge.end_date);
    let days = endDate.diff(startDate, "day") + 1;
    let months = endDate.diff(startDate, "month") + 1;
    let weeks = endDate.diff(startDate, "week") + 1;
    let periodicity = charge.periodicity;
    let period = null;
    let oneTime = 1;
    if (periodicity === "day") {
      period = days;
    } else if (periodicity === "week") {
      period = weeks;
    } else if (periodicity === "bi-week") {
      period = this.getPeriod(days, 14);
    } else if (periodicity === "month") {
      period = months;
    } else if (periodicity === "quarter") {
      period = this.getPeriod(months, 3);
    } else if (periodicity === "semi-annual") {
      period = this.getPeriod(months, 6);
    } else if (periodicity === "annual") {
      period = this.getPeriod(months, 12);
    } else if (periodicity === "one-time") {
      period = oneTime;
    }
    let totalOriginalAmount = charge.original_amount * period;
    if (getPeriod) {
      return period;
    } else {
      return totalOriginalAmount;
    }
  };

  formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={{ fontWeight: "bold", color: "black" }}>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  handleSelection = (valuekey, valueObj, group) => {
    this.setState({
      row: Object.assign(this.state.row, { [valuekey]: valueObj }),
      row: Object.assign(this.state.row, { [group]: valueObj["group"] }),
    });
  };

  render() {
    let payment = this.state.payment;
    let wrongPayee = false;
    let wrongAmount = false;
    let payment_histories = payment.payment_histories;
    let reasonObj =
      payment_histories && payment_histories[payment_histories.length - 1];
    wrongAmount =
      reasonObj && reasonObj.reason == "wrong_amount" ? true : false;
    wrongPayee = reasonObj && reasonObj.reason == "wrong_payee" ? true : false;
    let settlement_status =
      payment.settlement_status == "cleared" ? true : false;
    let disableGoodThrough =
      config["chargesRead"].includes(this.props.userRole) ||
      payment.eligible_to_approve_or_reject ||
      (!payment.eligible_to_approve_or_reject && payment.rejected_user) ||
      payment.action_status == "Awaiting Approval" ||
      payment.action_status == "Approved" ||
      wrongPayee;
    let isDisabled =
      config["chargesRead"].includes(this.props.userRole) ||
      payment.eligible_to_approve_or_reject ||
      (!payment.eligible_to_approve_or_reject && payment.rejected_user) ||
      payment.action_status == "Awaiting Approval" ||
      payment.action_status == "Approved" ||
      wrongPayee ||
      settlement_status;
    let disableInputs =
      isDisabled || wrongAmount || wrongPayee || settlement_status;
    let chargeApprove =
      config["chargeApprove"].includes(this.props.userRole) &&
      payment.eligible_to_approve_or_reject;
    // console.log("chargeApprove", chargeApprove);
    let submitForApproval = payment.submit_for_approval;
    let charges = this.state.charges;
    let totalAmountPayable = 0;
    if (!_.isEmpty(charges)) {
      for (let charge of charges) {
        totalAmountPayable = totalAmountPayable + Number(charge.amount_payable);
      }
    }
    const viewRejectionReasons =
      !_.isEmpty(payment.payment_comments) &&
      payment.payment_comments
        .filter((x) => x.comment_type !== "submit_approval")
        .map((x) => (
          <li>
            <span className="status-comment">
              {helpers.capitalizeFirstLetter(x.comment_type)}
            </span>
            <span className="status-comment">{x.user.name}</span>
            <span className="status-comment">
              {dayjs(x.created_at).format("MM/DD/YYYY HH:mm")}
            </span>
            <span className="status-comment">{x.comment}</span>
          </li>
        ));
    const viewApprovalComments =
      !_.isEmpty(payment.payment_comments) &&
      payment.payment_comments
        .filter((x) => x.comment_type == "submit_approval")
        .map((x) => (
          <li>
            <span className="status-comment">{x.user.name}</span>
            <span className="status-comment">
              {dayjs(x.created_at).format("MM/DD/YYYY HH:mm")}
            </span>
            <span className="status-comment">{x.comment}</span>
          </li>
        ));
    let hoaOptions = helpers.formatOptions(this.props.paymentHoas, "Hoa");
    let municipalOptions = helpers.formatOptions(
      this.props.paymentMunicipals,
      "Office"
    );

    let groupedOptions = [
      {
        label: "Hoas",
        options: hoaOptions,
      },
      {
        label: "Office",
        options: municipalOptions,
      },
    ];

    return (
      charges && (
        <div className="charges-section">
          <div className="heading">Charge Table</div>
          <div className="charges-payment-data">
            <div className="charges-payment-data-left">
              <FormGroup className="charges-hoas" row>
                <Label for="" sm={3}>
                  {/* HOA */}
                  Entity
                </Label>
                <Col sm={8}>
                  {/* <Input
                    type="select"
                    name="hoa_id"
                    id="hoa_id"
                    value={payment.hoa_id}
                    onChange={this.props.handleChange}
                    disabled={disableInputs ? true : false}
                  >
                    {paymentHoas}
                  </Input> */}
                  <Select
                    options={groupedOptions}
                    formatGroupLabel={this.formatGroupLabel}
                    value={payment.associable_id}
                    onChange={(value) => {
                      this.props.handleSelection(
                        "associable_id",
                        value,
                        "associable_type"
                      );
                    }}
                    disabled={disableInputs ? true : false}
                  />
                </Col>
              </FormGroup>
              {payment.payment_type === "fy_assessment" &&
              payment.cleared_date !== null ? (
                <FormGroup className="charges-date" row>
                  <Label for="good_through_date" sm={3}>
                    Good Through
                  </Label>
                  <Col sm={4}>
                    <DatePicker
                      id="good_through_date"
                      selected={
                        payment.good_through_date &&
                        payment.good_through_date !== ""
                          ? helpers.restructureDate(payment.good_through_date)
                          : null
                      }
                      onChange={(date) => {
                        this.props.handleDate("good_through_date", date);
                      }}
                      disabled={disableGoodThrough ? true : false}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              {
                <FormGroup row>
                  <Label sm={3} for="payment_status">
                    Payment Status
                  </Label>
                  <Col sm={4}>
                    <Input
                      type="select"
                      name="payment_status"
                      id="payment_status"
                      onChange={this.props.handleChange}
                      value={payment.payment_status}
                      disabled={!chargeApprove ? true : false}
                    >
                      <option value="" key="defaultValue">
                        Select...
                      </option>
                      <option value="Approved" key="Approved">
                        Approved
                      </option>
                      <option value="Rejected" key="Rejected">
                        Rejected
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
              }
            </div>
            <div className="charges-payment-data-right">
              <FormGroup row>
                <Label for="" sm={3}>
                  Negotiated By
                </Label>
                <Col sm={9}>
                  <AsyncSelect
                    id="negotiated_user_id"
                    callapi={apicall.searchAnalysts}
                    //role="senior_analyst"
                    placeholder="Search Sr. Analyst..."
                    handleOnChange={(id, value) => {
                      this.props.handleSelection(id, value);
                    }}
                    valueShown={payment.negotiated_user_id}
                    getEmail={true}
                    isDisabled={disableInputs ? true : false}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label className="" for="" sm={3}>
                  Legal Engagement
                </Label>
                <Col sm={4}>
                  <FormGroup style={{ display: "flex", marginTop: "5px" }}>
                    <CustomInput
                      id="legal_engagement_yes"
                      type="radio"
                      name="legal_engagement"
                      value={true}
                      checked={
                        payment.legal_engagement === true ? "checked" : ""
                      }
                      onClick={this.props.handleRadioButton}
                      disabled={disableInputs ? true : false}
                    />
                    <Label style={{ marginRight: "5px" }}>Yes</Label>
                    <CustomInput
                      id="legal_engagement_no"
                      type="radio"
                      name="legal_engagement"
                      value={false}
                      checked={
                        payment.legal_engagement === false ? "checked" : ""
                      }
                      onClick={this.props.handleRadioButton}
                      disabled={disableInputs ? true : false}
                    />
                    <Label>No</Label>
                  </FormGroup>
                </Col>
              </FormGroup>
            </div>
          </div>

          {this.state.isLoading ? (
            <LoaderComponent />
          ) : (
            <ChargesTable
              handleDateChange={this.handleDateChange}
              onChargesTableUpdate={this.handleChargesTable.bind(this)}
              handleChargeSubmit={(event) => this.handleChargeSubmit(event)}
              onRowAdd={this.handleAddEvent.bind(this)}
              charges={this.state.charges}
              payment={this.props.payment}
              handleChange={this.props.handleChange}
              handleDate={this.props.handleDate}
              handleAmountPayable={this.handleAmountPayable}
              style={{ display: "flex", marginTop: "5px" }}
              userRole={this.props.userRole}
              isDisabled={isDisabled || submitForApproval}
              actionID={this.props.actionID}
              propertyID={this.props.propertyID}
              refreshPayment={this.props.refreshPayment}
              removeCharge={this.removeCharge}
            />
          )}
          <div style={{ float: "right", fontWeight: "bold" }}>
            Total Amount Payable:$
            {totalAmountPayable.toFixed(2)}
          </div>
          {(payment.payment_status === "Rejected" ||
            payment.payment_status === "Approved") && (
            <div className="payment-rejected">
              <FormGroup>
                <Label for="status_comment">Payment Status Comments</Label>
                <Input
                  type="textarea"
                  name=""
                  id="payment_status_comment"
                  onChange={this.props.handleChange}
                  disabled={!chargeApprove ? true : false}
                  required
                />
              </FormGroup>
            </div>
          )}
          {!_.isEmpty(viewRejectionReasons) && (
            <div className="payment-comments">
              <strong>Payment Status Comments History:</strong>
              <ul>{viewRejectionReasons}</ul>
            </div>
          )}

          {!submitForApproval && (
            <div className="submit_approval_comment">
              <FormGroup style={{ marginLeft: "1rem" }}>
                <Label for="submit_approval_comment">Approval Comments</Label>
                <Input
                  type="textarea"
                  name=""
                  id="submit_approval_comment"
                  onChange={this.props.handleChange}
                  disabled={isDisabled ? true : false}
                />
              </FormGroup>
            </div>
          )}
          {!_.isEmpty(viewApprovalComments) && (
            <div className="payment-comments" style={{ marginLeft: "1rem" }}>
              <strong>Approval Comments:</strong>
              <ul>{viewApprovalComments}</ul>
            </div>
          )}
          <div className="approval-btn">
            {!submitForApproval &&
              charges &&
              charges.length > 0 &&
              !_.has(charges[0], "status") &&
              this.state.ispayeeRecipientsAdressPresent &&
              this.state.isDocumentPresent && (
                <Button
                  type="button"
                  className={this.state.submitButton}
                  onClick={this.handleApproved}
                  // disabled={isDisabled}
                >
                  Submit For Approval
                </Button>
              )}
          </div>
        </div>
      )
    );
  }
}

class ChargesTable extends React.Component {
  render() {
    var onChargesTableUpdate = this.props.onChargesTableUpdate;
    var handleDateChange = this.props.handleDateChange;
    var handleChargeSubmit = this.props.handleChargeSubmit;
    var payment = this.props.payment;
    var handleAmountPayable = this.props.handleAmountPayable;
    let userRole = this.props.userRole;
    let isDisabled = this.props.isDisabled;
    let actionID = this.props.actionID;
    let propertyID = this.props.propertyID;
    let refreshPayment = this.props.refreshPayment;
    let removeCharge = this.props.removeCharge;
    var charge =
      this.props.charges &&
      this.props.charges.map(function (charge, index) {
        return (
          <ChargesRow
            handleDateChange={handleDateChange}
            onChargesTableUpdate={onChargesTableUpdate}
            handleChargeSubmit={handleChargeSubmit}
            charge={charge}
            key={charge.id}
            index={index}
            payment={payment}
            handleAmountPayable={handleAmountPayable}
            userRole={userRole}
            isDisabled={isDisabled}
            actionID={actionID}
            propertyID={propertyID}
            refreshPayment={refreshPayment}
            removeCharge={removeCharge}
          />
        );
      });
    return (
      <div className="charge-table">
        <div className="charges-header">
          <div className="add-charge">
            <button
              type="button"
              onClick={this.props.onRowAdd}
              className="btn btn-success pull-right"
              disabled={isDisabled}
            >
              Add Charges
            </button>
          </div>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Responsibility</th>
              <th>Charge Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Periodicity</th>
              {/* <th>Dummy</th> */}
              <th>Original Amount</th>
              <th>Total Original Amount</th>
              {payment.payment_type === "account_review" && (
                <th>Statuatory Adjustment</th>
              )}
              <th>Negotiation Adjustment</th>
              <th>Amount Payable</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>{charge}</tbody>
        </table>
      </div>
    );
  }
}

class ChargesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tooltipOpen: false };
  }

  deleteCharge = (chargeID, actionID, propertyID, status) => {
    if (status === "New") {
      this.props.removeCharge(chargeID);
    } else {
      apicall.deleteCharge(chargeID, actionID, propertyID).then((response) => {
        if (response) {
          helpers.notify(response.message);
          this.props.refreshPayment();
        }
      });
    }
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    let index = this.props.index;
    let status = this.props.charge.status;
    let isChargeCreateUser = config["chargesCreateOnly"].includes(
      this.props.userRole
    );
    let chargeID = this.props.charge.id;
    let actionID = this.props.actionID;
    let propertyID = this.props.propertyID;
    var isHideSave = false;
    if ((isChargeCreateUser && status !== "New") || this.props.isDisabled) {
      isHideSave = true;
    }

    const saveDisabled = () => {
      return (
        helpers.isBlank(this.props.charge.responsibility) ||
        helpers.isBlank(this.props.charge.charge_type) ||
        helpers.isNull(this.props.charge.start_date) ||
        helpers.isNull(this.props.charge.end_date) ||
        helpers.isBlank(this.props.charge.periodicity) ||
        helpers.isBlank(this.props.charge.original_amount) ||
        helpers.isBlank(this.props.charge.amount_payable)
      );
    };

    return (
      <tr className="eachRow">
        <EditableCell
          onChargesTableUpdate={this.props.onChargesTableUpdate}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "responsibility",
            value: this.props.charge.responsibility,
            id: chargeID,
          }}
          isDropdown={true}
          isChargeType={true}
        />
        <EditableCell
          onChargesTableUpdate={this.props.onChargesTableUpdate}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "charge_type",
            value: this.props.charge.charge_type,
            id: chargeID,
          }}
          isDropdown={true}
          isChargeType={true}
        />
        <EditableCell
          handleDateChange={this.props.handleDateChange}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "start_date",
            value: this.props.charge.start_date,
            id: chargeID,
          }}
          isDate={true}
        />
        <EditableCell
          handleDateChange={this.props.handleDateChange}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "end_date",
            value: this.props.charge.end_date,
            id: chargeID,
          }}
          isDate={true}
        />
        <EditableCell
          onChargesTableUpdate={this.props.onChargesTableUpdate}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "periodicity",
            value: this.props.charge.periodicity,
            id: chargeID,
          }}
          isDropdown={true}
        />
        {/* <EditableCell onChargesTableUpdate={this.props.onChargesTableUpdate} cellData={{
                    type: "dummy",
                    value: this.props.charge.dummy,
                    id: this.props.charge.id
                }} /> */}
        <EditableCell
          onChargesTableUpdate={this.props.onChargesTableUpdate}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "original_amount",
            value: this.props.charge.original_amount,
            id: chargeID,
          }}
        />
        <td>
          {(
            this.props.charge.original_amount *
            this.props.handleAmountPayable(this.props.charge, true)
          ).toFixed(2)}
        </td>
        {this.props.payment.payment_type === "account_review" && (
          <EditableCell
            onChargesTableUpdate={this.props.onChargesTableUpdate}
            isDisabled={this.props.isDisabled}
            cellData={{
              type: "statutory_adjustment",
              value: this.props.charge.statutory_adjustment,
              id: chargeID,
            }}
          />
        )}
        <td>
          <input
            type="text"
            id="negotiation_adjustment"
            name="negotiation_adjustment"
            value={
              this.props.charge.negotiation_adjustment > 0
                ? parseFloat(this.props.charge.negotiation_adjustment).toFixed(
                    2
                  )
                : 0
            }
            readOnly
            disabled
          />

          <Tooltip
            placement="top"
            isOpen={this.state.tooltipOpen}
            target="negotiation_adjustment"
            toggle={this.toggle}
          >
            {this.props.payment.payment_type === "account_review" ? (
              <>
                {this.props.charge.original_amount *
                  this.props.handleAmountPayable(this.props.charge, true) +
                  "-" +
                  this.props.charge.amount_payable +
                  "-" +
                  this.props.handleAmountPayable(this.props.charge, true)}
              </>
            ) : (
              <>
                {this.props.charge.original_amount *
                  this.props.handleAmountPayable(this.props.charge, true) +
                  "-" +
                  this.props.charge.amount_payable}
              </>
            )}
          </Tooltip>
        </td>
        <EditableCell
          onChargesTableUpdate={this.props.onChargesTableUpdate}
          isDisabled={this.props.isDisabled}
          cellData={{
            type: "amount_payable",
            value: this.props.charge.amount_payable,
            id: chargeID,
          }}
        />
        <td>
          {!isHideSave && (
            <>
              <button
                disabled={saveDisabled()}
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  background: "white",
                }}
                onClick={() => this.props.handleChargeSubmit(index)}
              >
                Save Charge
              </button>
              <FaTrashAlt
                style={{
                  fontSize: "14px",
                  marginLeft: "16px",
                  cursor: "pointer",
                }}
                size="1.5em"
                onClick={() =>
                  this.deleteCharge(chargeID, actionID, propertyID, status)
                }
              />
            </>
          )}
          {isHideSave && (
            <FaBan className="font-awesome-disabled" size="1.6em" />
          )}
        </td>
      </tr>
    );
  }
}
class EditableCell extends React.Component {
  getDropdown = (name) => {
    let dropdownOptions = [];
    if (name === "charge_type") {
      dropdownOptions = <SelectOptions options={config.charge_type} />;
    } else if (name === "periodicity") {
      dropdownOptions.push(
        <option value="" key="defaultValue">
          Select
        </option>
      );
      for (let period in config.periodicity) {
        dropdownOptions.push(
          <option value={config.periodicity[period]} key={period}>
            {period}
          </option>
        );
      }
    } else if (name === "responsibility") {
      for (let charge in config.responsibility) {
        dropdownOptions.push(
          <option value={charge} key={charge}>
            {config.responsibility[charge]}
          </option>
        );
      }
    }

    return dropdownOptions;
  };

  render() {
    let Disabled = this.props.isDisabled;
    if (this.props.isDate) {
      return (
        <td>
          <DatePicker
            id={this.props.cellData.id}
            selected={
              this.props.cellData.value
                ? helpers.restructureDate(this.props.cellData.value)
                : null
            }
            onChange={(date) => {
              this.props.handleDateChange(
                this.props.cellData.id,
                date,
                this.props.cellData.type
              );
            }}
            disabled={Disabled}
          />
        </td>
      );
    } else if (this.props.isDropdown) {
      return (
        <td>
          <Input
            type="select"
            name={this.props.cellData.type}
            id={this.props.cellData.id}
            value={this.props.cellData.value}
            onChange={this.props.onChargesTableUpdate}
            required
            disabled={Disabled}
          >
            {this.getDropdown(this.props.cellData.type)}
          </Input>
        </td>
      );
    } else {
      return (
        <td>
          <input
            type="number"
            name={this.props.cellData.type}
            id={this.props.cellData.id}
            value={this.props.cellData.value}
            onChange={this.props.onChargesTableUpdate}
            disabled={Disabled}
          />
        </td>
      );
    }
  }
}
