import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../styles/propertyupload.css";
import apicall from "../../utils/apicall";
import "../../styles/content.css";
import TitleVendor from "./TitleVendor";
import MunicipalityVendor from "./MunicipalityVendor";
import TitleVendorCreationModal from "./TitleVendorCreationModal";
import LoaderComponent from "../helper_components/LoaderComponent";
import TabsComponent from "../helper_components/TabsComponent";
import MunicipalityVendorCreationModal from "./MunicipalityVendorCreationModal";

export default function VendorComponent(props) {
  const [vendorModal, setvendorModal] = useState(false);
  const [pageNo, setpageNo] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [vendorsList, setvendorsList] = useState([]);
  const [total, settotal] = useState();
  const [activeTab, setactiveTab] = useState(1);
  const [municipalvendorModal, setmunicipalvendorModal] = useState(false);

  const toggleModal = () => {
    setvendorModal(!vendorModal);
  };
  const toggleMunicipalModal = () => {
    setmunicipalvendorModal(!municipalvendorModal);
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getVendors = (event, pageNo, perPage) => {
    apicall.listVendor(pageNo, perPage).then((response) => {
      setvendorsList(response.results);
      setisLoading(false);
      settotal(response.total);
      setpageNo(pageNo);
    });
  };

  const updateActiveTab = (value) => {
    setactiveTab(value);
  };

  const getMunicipalVendors = (event, pageNo, perPage) => {
    apicall.listMunicipalVendor(pageNo, perPage).then((response) => {
      setvendorsList(response.results);
      setisLoading(false);
      settotal(response.total);
      setpageNo(pageNo);
    });
  };
  let listofTabs = "";

  listofTabs = ["Title Vendors", "Municipality Vendors"];

  let tabsComponentsList = new Array(listofTabs.length).fill(null);
  tabsComponentsList[0] = <TitleVendor />;
  tabsComponentsList[1] = <MunicipalityVendor />;

  return (
    <React.Fragment>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <section className="content">
          <header className="content-title property-upload">
            <div className="page-title">Vendor Management</div>
            <div className="buttons">
              {activeTab === 1 ? (
                <Button onClick={toggleModal} className="createBtn">
                  Create Title Vendor
                </Button>
              ) : (
                <Button onClick={toggleMunicipalModal} className="createBtn">
                  Create Municipality Vendor
                </Button>
              )}
            </div>
          </header>

          <div className="general-component" style={{ width: "95%" }}>
            <TabsComponent
              components={tabsComponentsList}
              listofTabs={listofTabs}
              generateTabURL={false}
              activeTab={activeTab}
              theme={"general-info-tab"}
              updateActiveTab={updateActiveTab}
              isVendor={true}
            />
          </div>
        </section>
      )}
      {vendorModal && (
        <TitleVendorCreationModal
          toggle={toggleModal}
          modal={vendorModal}
          getVendors={getVendors}
        />
      )}
      {municipalvendorModal && (
        <MunicipalityVendorCreationModal
          toggle={toggleMunicipalModal}
          modal={municipalvendorModal}
          getMunicipalVendors={getMunicipalVendors}
        />
      )}
    </React.Fragment>
  );
}
