import React from "react";
import FormComp from "../../crud/FormComp";
import { FormGroup, Label, Col, Input } from "reactstrap";
import TextValidator from "../../helper_components/TextValidator";
import Select from "react-select";
import DocumentUploadComponent from "./AccessDocumentUpload";

export default class AccessDocumentsModal extends FormComp {
  componentDidMount() {
    let row = {
      "documents_attributes[][category]":
        this.props.accessFor === "pool" ? "Leasing" : "Rules and Policy",
      "documents_attributes[][name]":
        this.props.accessFor === "pool"
          ? "Pool Registration Form"
          : "Access Form",
    };
    this.setState({
      row: Object.assign(this.state.row, row),
    });
  }
  handleSelectChange = (selectedType) => {
    let selectedTypeValue = selectedType.value;
    let row = this.state.row;
    row["documents_attributes[][name]"] = selectedTypeValue;
    //row["documents_attributes[][category]"] = null;

    this.setState({
      selectedTypeValue,
      row,
      hideDocumentName: selectedTypeValue === "Others" ? false : true,
    });
  };

  formElements = () => {
    return (
      <div>
        <FormGroup>
          <Label for="">Category</Label>
          {this.props.accessFor === "pool" ? (
            <TextValidator
              type="text"
              name="document_category"
              id="documents_attributes[][category]"
              value={"Leasing"}
              disabled={true}
            />
          ) : (
            <TextValidator
              type="text"
              name="document_category"
              id="documents_attributes[][category]"
              value={"Rules and Policy"}
              disabled={true}
            />
          )}
        </FormGroup>

        <FormGroup>
          <Label for="">Name</Label>
          {this.props.accessFor === "pool" ? (
            <TextValidator
              type="text"
              name="document_name"
              id="documents_attributes[][name]"
              value={"Pool Registration Form"}
              disabled={true}
            />
          ) : (
            <TextValidator
              type="text"
              name="document_name"
              id="documents_attributes[][name]"
              value={"Access Form"}
              disabled={true}
            />
          )}
        </FormGroup>

        {/* <Select
          style={{ marginBottom: "10px" }}
          options={this.props.hoaOptions ? this.props.hoaOptions : []}
          onChange={(hoa) => {
            let row = this.state.row;
            row["documents_attributes[][hoa_id]"] = hoa && hoa.value;
            hoa && this.setState({ row });
          }}
          placeholder="Select Hoa..."
          className="property-hoa-select"
          isClearable
        /> */}
        <FormGroup>
          <Label for="">Document Memo Notes </Label>
          <TextValidator
            type="text"
            id={"documents_attributes[][memo_notes]"}
            name="memo_notes"
            placeholder="Document Memo Notes"
            onChange={this.handleChange}
            // validators={["required"]}
            // errorMessages={["Document Memo Notes is required"]}
            value={
              this.state.row["documents_attributes[][memo_notes]"]
                ? this.state.row["documents_attributes[][memo_notes]"]
                : ""
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Document File Upload</Label>
          <Input
            type="file"
            name="file"
            id={"documents_attributes[][file]"}
            onChange={this.handleChange}
            required
          />
        </FormGroup>
      </div>
    );
  };

  handleSubmit = (event) => {
    this.state.handleFileObjects(this.state.row, this.state.pos);
    event.preventDefault();
  };
}
