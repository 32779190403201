import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var titleLienActionapi = {
  readTitleLien: async function (actionId, propertyId) {
    let url = `${config.baseUrl}/api/v1/title_lien/${actionId}?property_id=${propertyId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  createTitleLien: async function (data, propertyId) {
    let url = `${config.baseUrl}/api/v1/title_lien?property_id=${propertyId}`;
    let headers;
    let response;
    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
          if(key === "title_lien_notes") {
            if(data[key] === "" || !data[key]) {
              data[key] = null
              continue
            }
          }
        if (data[key] === null) {
          data[key] = "";
        }
        form.append("title_lien[" + key + "]", data[key]);
      }
    }
    headers = await helpers.defaultHeaders();
    await Axios.post(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateTitleLien: async function (data, actionId, propertyId) {
    let url = `${config.baseUrl}/api/v1/title_lien/${actionId}?property_id=${propertyId}`;
    let headers;
    let response;
    let form = new FormData();
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if(key === "title_lien_notes") {
          if(data[key] === "" || !data[key]) {
            data[key] = null
            continue
          }
        }
        if (data[key] === null) {
          data[key] = "";
        }
        form.append("title_lien[" + key + "]", data[key]);
      }
    }
    headers = await helpers.defaultHeaders();
    await Axios.put(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listTitleLien: async function (data, propertyId, pageNo, perPage) {
    let url = `${config.baseUrl}/api/v1/title_lien?`;
    let params = {};
    if (propertyId) params["property_id"] = propertyId;
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // if (pageNo) {
    //   url = url + "&page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;
    // let headers;
    // let response;
    // headers = await helpers.defaultHeaders();
    // await Axios.get(url, { headers: headers }).then((resp) => {
    //   response = resp;
    // });
    // return response.data;
  },

  listContactedWith: async function () {
    let url = `${config.baseUrl}/api/v1/title_lien/contacted_with`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  listDepartments: async function () {
    let url = `${config.baseUrl}/api/v1/title_lien/departments_list`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  autoCreatePayment: async function (propertyId, lienID, data) {
    let url = `${config.baseUrl}/api/v1/title_payments/auto_payments_create?property_id=${propertyId}&lien_ids=[${lienID}]`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

 deleteLienUploadDocument: async function (actionId, propertyId, documentId) {
   let url =`${config.baseUrl}/api/v1/title_lien/${actionId}/delete_title_documents?property_id=${propertyId}&document_id=${documentId}`;
   let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
 }
};
export default withRouter(titleLienActionapi);
