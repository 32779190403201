import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent";
import { FaEdit } from "react-icons/fa";
import PaginationComponent from "../helper_components/PaginationComponent";
import _ from "lodash";
import dayjs from "dayjs";
import config from "../../utils/config";
import "../../styles/_notes.scss";
import MunicipalRequirementAction from "../Municipal/MunicipalRequirementAction";
import MunicipalVerification from "../MunicipalVerification/MunicipalVerification";
import CodeViolationModal from "../CodeViolation/CodeViolationModal";
import DeedVerification from "../deed_verification/NewDeedFile";

export default (props) => {
  const [state, set_state] = useState({ notes: [] });
  const [loading, set_loading] = useState(false);
  const [modal_inputs, set_modal_inputs] = useState({
    paymentModal: false,
  });
  const [actionID, setactionID] = useState();
  const [propertyID, setpropertyID] = useState();
  const [codeViolationsData, setcodeViolationsData] = useState({});
  const [propertyInfo, setPropertyInfo] = React.useState();
  const [getPropertyInfo, setGetPropertyInfo] = React.useState();
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    getNotes({}, 1);
  }, [form_inputs]);

  React.useEffect(()=>{
    if(state.notes && state.notes.length > 0 && state.notes[0].property_id)
    {
      getPropertyInformation(state.notes[0].property_id)
    }
  }, [getPropertyInfo])
  
  const getPropertyInformation = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      setPropertyInfo(response)
    });
  };

  const getNotes = (event, pageNo, perPage) => {
    set_loading(true);
    apicall
      .getNotes(props.propertyID, pageNo, perPage,form_inputs)
      .then((response) => {
        set_state({
          ...state,
          notes: response.results.map((x) => {
            return { ...x.user, ...x };
          }),
          total: response.total,
          pageNo,
          perPage,
        });
        setGetPropertyInfo(response);
        set_loading(false);
      })
      .catch((e) => set_loading(false));
  };

  const readCodeViolation = (id) => {
    apicall.readCodeViolation(id, props.propertyID).then((response) => {
      setcodeViolationsData(response);
    });
  };

  const timestampFormatter = (cell, row, rowIndex, formatExtraData) =>
    dayjs(row.created_at).format(config.US_DATE_TIME_FORMAT);

  const descFormatter = (cell, row, rowIndex, formatExtraData) => (
    <div style={{ "white-space": "pre-wrap" }}>{cell}</div>
  );

  const getActionType = (cell) => {
    if (!_.isEmpty(cell)) {
      let lowercase = cell.trim();
      let regEx = /\W+|(?=[A-Z])|_/g;
      let result = lowercase.split(regEx).join(" ");
      return result;
    }
  };

  const actionTypeFormatter = (cell, row) => {
    if (row && row.noteable_type && row.noteable_type === "PropertyAction") {
      if (row && row.noteable_type && row.note_type === "MunicipalityVerification" ||
        row && row.noteable_type && row.note_type === "DeedVerification") {
        cell = row.note_type;
      }
   }
    if (
      !_.isEmpty(cell) &&
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              let modalName = cell.trim().toLowerCase() + "Modal";
              set_modal_inputs({
                ...modal_inputs,
                [modalName]: !modal_inputs[modalName],
              });
              if (cell === "CodeViolation") {
                readCodeViolation(row.noteable_id);
              }
              if(row.noteable_type === "PropertyAction" && cell === "DeedVerification"){
                getPropertyInformation(row.property_id)
              }
              if ((row.noteable_type === "PropertyAction") && (cell === "MunicipalityVerification" || cell === "DeedVerification")) {
                setactionID(row.note_reference_id);
                setpropertyID(row.property_id);
              } else {
                setactionID(row.noteable_id);
                setpropertyID(row.property_id);
              }
            }}
          >
            {getActionType(cell)}
          </div>
        </React.Fragment>
      );
    }
  };

  const editFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            set_state({ ...state, currentNote: row, noteModal: true });
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Timestamp",
      formatter: timestampFormatter,
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
         set_form_inputs({ ...form_inputs, sort: obj });
        },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
    },
    {
      dataField: "user.name",
      text: "User",
      sort: true,
      onSort: (a, b) => {
      let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
        },
      headerClasses: "table-header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
    },
    {
      dataField: "noteable_type",
      text: "Origin",
      sort: true,
      onSort: (a, b) => {
      let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
        },
      formatter: actionTypeFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "note_type",
      text: "Note Type",
      formatter: descFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "description",
      text: "Note",
      formatter: descFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "description",
      text: "",
      formatter: editFormatter,
      headerClasses: "table-header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
  ];

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Button
        onClick={() =>
          set_state({ ...state, currentNote: null, noteModal: true })
        }
      >
        Create Note
      </Button>
      {state.notes.length == 0 ? (
        <h2>No Notes</h2>
      ) : (
        <div className="notes-div">
          <PaginationComponent
            columns={columns}
            rows={state.notes}
            handleSubmit={getNotes}
            pageNo={state.pageNo}
            perPage={state.perPage}
            totalCount={state.total}
            className="notes-table"
          />
        </div>
      )}
      {state.noteModal && (
        <NoteModal
          toggle={() =>
            set_state({ ...state, currentNote: null, noteModal: false })
          }
          set_loading={set_loading}
          set_state={(data) => set_state({ ...state, ...data })}
          propertyID={props.propertyID}
          currentNote={state.currentNote}
        />
      )}
      {modal_inputs.municipalityregistrationModal && (
        <MunicipalRequirementAction
          actionID={actionID}
          propertyID={propertyID}
          open={modal_inputs.municipalityregistrationModal}
          toggle={() => {
            set_modal_inputs({
              ...modal_inputs,
              municipalityregistrationModal: !modal_inputs.municipalityregistrationModal,
            });
          }}
          title="Municipality Registration Action"
          fetchDocuments={getNotes}
          refershActions={getNotes}
        />
      )}

      {modal_inputs.municipalityverificationModal && (
        <MunicipalVerification
          actionID={actionID}
          propertyID={propertyID}
          open={modal_inputs.municipalityverificationModal}
          toggle={() => {
            set_modal_inputs({
              ...modal_inputs,
              municipalityverificationModal: !modal_inputs.municipalityverificationModal,
            });
          }}
          title="Municipality Verification Action"
          refershActions={getNotes}
        />
      )}
      {modal_inputs.codeviolationModal && (
        <CodeViolationModal
          toggle={() => {
            set_modal_inputs({
              ...modal_inputs,
              codeviolationModal: !modal_inputs.codeviolationModal,
            });
          }}
          className=""
          title="CodeViolation"
          open={modal_inputs.codeviolationModal}
          propertyID={propertyID}
          actionID={actionID}
          row={codeViolationsData}
        />
      )}
      {modal_inputs.deedverificationModal && (
            <DeedVerification
            toggle={() => {
              set_modal_inputs({
                ...modal_inputs,
                deedverificationModal: !modal_inputs.deedverificationModal,
              });
            }}
              propertyID={propertyID}
              actionID={actionID}
              open={modal_inputs.deedverificationModal}
              // fetchDocuments={this.toggleModal}
              propertyInfo={propertyInfo}
              // refershActions={this.toggleModal}
            />
          )}
    </React.Fragment>
  );
};

const NoteModal = (props) => {
  const [description, set_description] = useState(
    props.currentNote && props.currentNote.description
  );

  const createNote = () => {
    props.set_loading(true);
    apicall
      .createNote(props.propertyID, description)
      .then((response) => {
        props.set_state({
          notes: response.results,
          total: response.total,
          pageNo: 1,
          noteModal: false,
        });
        props.set_loading(false);
      })
      .catch((e) => {
        props.set_loading(false);
        props.set_state({ noteModal: false });
      });
  };
  return (
    <Modal isOpen={true} toggle={props.toggle}>
      <ModalHeader>Note</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <label>Note: </label>
            <Input
              id="new-note"
              name="new-note"
              type="textarea"
              rows="10"
              value={description}
              onChange={(e) => set_description(e.target.value)}
            />
          </FormGroup>
          <Button onClick={createNote}>Submit</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
