import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import "../../styles/pagelayout.css";
import _ from "lodash";

export default class TabsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      components: null,
      theme: "",
      generateTabURL: true,
      renderLeftTabArrow: false,
      renderRightTabArrow: true,
      triggerAnimation: true,
    };
    this.tabsHeaderView = React.createRef();
    this.navHeaderLink = React.createRef();
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (this.props.isVendor) {
      this.props.updateActiveTab(tab);
    }
  };

  renderTabHeader = (tabindex) => {
    if (
      typeof this.state.components !== "undefined" &&
      this.state.components !== null
    ) {
      var theme = this.state.theme;
      var isArray = Array.isArray(this.state.listofTabs);
      var tabsMap = isArray
        ? this.state.listofTabs
        : Object.keys(this.state.listofTabs);
      let tabHeading = tabsMap.map((tab, index) => {
        const tabClass = _.kebabCase(tab);
        if (typeof tab !== "undefined") {
          var displayValue = tab;
          if (!isArray) tab = this.state.listofTabs[tab].join(",");
          var navlink = null;
          if (this.state.generateTabURL) {
            navlink = (
              <NavLink
                to={this.props.urlList[index]}
                tag={Link}
                ref={(link) => (this.navHeaderLink = link)}
                className={classnames(
                  { active: parseInt(tabindex) === index + 1 },
                  theme + "navlink",
                  "group",
                  tabClass
                )}
                onClick={() => {
                  this.toggle(index + 1);
                }}
              >
                {displayValue}
              </NavLink>
            );
          } else {
            navlink = (
              <NavLink
                ref={(link) => (this.navHeaderLink = link)}
                className={classnames(
                  { active: parseInt(tabindex) === index + 1 },
                  theme + "navlink",
                  "group",
                  tabClass
                )}
                onClick={() => {
                  this.toggle(index + 1);
                }}
              >
                {displayValue}
              </NavLink>
            );
          }
          return (
            <NavItem
              key={"navitem" + index}
              className={classnames(
                { active: parseInt(tabindex) === index + 1 },
                theme + "navitem mal-tabs",
                "group",
                tabClass
              )}
            >
              {navlink}
            </NavItem>
          );
        }
      });

      return tabHeading;
    }
  };
  renderTabComponents = (tabindex) => {
    if (
      typeof this.state.components !== "undefined" &&
      this.state.components !== null
    ) {
      var theme = this.state.theme;
      var isArray = Array.isArray(this.state.listofTabs);
      var tabsMap = isArray
        ? this.state.listofTabs
        : Object.keys(this.state.listofTabs);

      let tabsComponent = tabsMap.map((tab, index) => {
        var component = null;
        if (typeof tabindex === "undefined" || index === tabindex - 1) {
          component = this.state.components[index];
        }
        return (
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={true}
            key={"tabpane" + index + tabindex}
          >
            <TabPane
              key={"tabpane" + index}
              tabId={index + 1}
              className={theme + "tabPane"}
            >
              <div className={theme + "row"}>
                <div className={"tab-item " + theme + "col"}>{component}</div>
              </div>
            </TabPane>
          </Animated>
        );
      });
      return tabsComponent;
    }
  };

  render() {
    // console.log("came here");
    var tabsHeader = this.renderTabHeader(this.state.activeTab);
    var tabsComponent = this.renderTabComponents(this.state.activeTab);
    if (typeof tabsComponent !== "undefined" && tabsComponent !== null) {
      var theme = this.state.theme;
      return (
        <React.Fragment>
          <div ref={this.tabsHeaderView}>
            <Nav tabs className={theme}>
              {tabsHeader}
            </Nav>
          </div>

          <TabContent
            activeTab={this.state.activeTab}
            className={theme + "page-content tabContent"}
          >
            {tabsComponent}
          </TabContent>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
