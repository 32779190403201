import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var clientMunicipalityLoginapi = {
  clientMunicipalityOrders: async function (pageNo, perPage) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/municipal_orders`;
    if (pageNo) {
      url = url + "?page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  municipalityOrdersSearch: async function (id) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/municipal_orders_search?q=${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  municipalityUploadDocuments: async function (data, id, key) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/municipal_order_update?municipal_order_id=${id}`;
    let headers;
    let response;
    headers = await helpers.titleFileHeaders();
    var form = new FormData();
    if (key === "notes") form.append("municipal_order[notes]", data);
    else {
      for (var key in data) {
        form.append("municipal_order[" + key + "]", data[key]);
      }
    }
    await Axios.put(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  municipalityDeleteUploadDocuments: async function (id, file_id) {
    let url = `${config.baseUrl}/api/v1/client_municipal_orders/delete_municipal_order_documents?municipal_order_id=${id}&document_id=${file_id}`
    let headers;
    let response;
    headers = await helpers.titleFileHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  }
};
export default withRouter(clientMunicipalityLoginapi);
