import React, { Component } from "react";
import "../../styles/_general.scss";
import { Table } from "reactstrap";
import propertiesApi from "../../utils/api/Propertyapi";
import helpers from "../../utils/helpers";
import _ from "lodash";
import Loader from "../helper_components/LoaderComponent";
import dayjs from "dayjs";
import config from "../../utils/config";
export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      isLoading: true,
      page: 1,
    };
  }
  componentWillMount() {
    if (window.performance) {
      if (!(window.performance.navigation.type == 1)) {
        this.getHistory();
      } else {
        config.localforage
          .getItem("history")
          .then((data) => {
            console.log(data);
            if (data) {
              let no_of_records = data.length;
              let page = no_of_records / 10;
              this.setState({ page: page });
            }
            this.setState({ history: data, isLoading: false });
          })
          .catch((e) => console.log(e));
        config.localforage
          .getItem("historyCount")
          .then((count) => {
            this.setState({ historyCount: count });
          })
          .catch((e) => console.log(e));
      }
    }
  }
  render() {
    const create_changes = (changes) => {
      return <Expandable changes={changes} />;
    };
    const update_changes = (changes) => {
      let ret = [];
      for (let [key, value] of Object.entries(changes)) {
        ret.push(
          <tr>
            <td>{key}</td>
            <td>{JSON.stringify(value[0])}</td>
            <td>{JSON.stringify(value[1])}</td>
          </tr>
        );
      }
      return (
        <Table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Old value</th>
              <th>New value</th>
            </tr>
          </thead>
          {ret}
        </Table>
      );
    };
    const render_changes = (changes, action) => {
      if (action == "update") {
        return update_changes(changes);
      } else {
        return create_changes(changes);
      }
    };
    const history =
      this.state.history &&
      this.state.history.map((h) => (
        <tr>
          <td>{h.auditable_type}</td>
          <td>{typeof h.user !== "undefined" ? h.user.name : ""}</td>
          <td>{h.action}</td>
          <td>{dayjs(h.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
          <td>{render_changes(h.audited_changes, h.action)}</td>
        </tr>
      ));
    let page = this.state.page;
    console.log("this.state.historyCount", this.state.historyCount);
    console.log(page);
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>User</th>
                  <th>Action</th>
                  <th>Timestamp</th>
                  <th>Changes</th>
                </tr>
                {history}
              </thead>
            </Table>
            {this.state.history &&
              this.state.history.length <= this.state.historyCount && (
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                  onClick={() => {
                    this.setState({ page: page + 1 }, () =>
                      this.getHistory(this.state.page)
                    );
                  }}
                >
                  view more...
                </div>
              )}
          </>
        )}
      </>
    );
  }
  getHistory(page) {
    console.log("params--->", page);
    console.log("inside get history==>", this.state.page);
    console.log("count", this.state.historyCount);
    propertiesApi
      .getHistory(this.props.propertyID, this.state.page)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("errors")) {
          let errorMsg = helpers.getErrorMsg(response.errors);
          if (errorMsg) helpers.notify(errorMsg);
        } else {
          console.log(response);
          this.setState({
            history: response.historyArray,
            isLoading: false,
            historyCount: response.totalCount,
          });
        }
      });
  }
}
class Expandable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  render() {
    const changes = this.props.changes;
    let ret = [];
    let i = 0;
    for (let [key, value] of Object.entries(changes)) {
      if (this.state.collapsed) {
        if (i < 3)
          ret.push(
            <li>
              {key} = {JSON.stringify(value)}
            </li>
          );
      } else
        ret.push(
          <li>
            {key} = {JSON.stringify(value)}
          </li>
        );
      i++;
    }
    if (this.state.collapsed && i > 3) {
      ret.push(
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ collapsed: false });
            }}
          >
            more...
          </a>
        </li>
      );
    }
    return <ul>{ret}</ul>;
  }
}
