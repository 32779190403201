import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import Loader from "../helper_components/LoaderComponent";
import Select from "react-select";
import { ValidatorForm } from "react-form-validator-core";
import config from "../../utils/config";

export default function TitleDocumentsModal(props) {
  const [row, setrow] = useState(props.row ? props.row : {});
  const [isLoading, setisLoading] = useState(false);

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const categoryOptions = () => {
    let categories = ["Deed"];
    return categories.map((c) => {
      return { label: c, value: c };
    });
  };

  const classificationOptions = () => {
    let classification = [
      "Deed in Lieu",
      "Foreclosure / Trustee",
      "Quit Claim Deed",
      "Warranty Deed",
    ];
    return classification.map((c) => {
      return { label: c, value: c };
    });
  };

  var documentTypeOptions = config.deed_document_name;
  var doc_type = documentTypeOptions.map((c) => {
    return { label: c, value: c };
  });

  const formElements = () => {
    return (
      <>
        { isLoading ? (
            <Loader />
          ) : (
            <>
      <div>
        {props.isDeedVerification && (
          <>
            <FormGroup>
              <Label for="">Category</Label>
              <TextValidator
                type="text"
                name="title_documents_attributes[][category]"
                id="title_documents_attributes[][category]"
                value={"Deed"}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Classification</Label>
              <Select
                name="title_documents_attributes[][classification]"
                id="title_documents_attributes[][classification]"
                value={{
                  value: row["title_documents_attributes[][classification]"],
                  label: row["title_documents_attributes[][classification]"],
                }}
                onChange={(selectedType) => {
                  let selectedValue = row;
                  selectedValue[
                    "title_documents_attributes[][classification]"
                  ] = selectedType.value;
                  setrow(Object.assign({ ...row }, { selectedValue }));
                }}
                options={classificationOptions()}
                validators={["required"]}
                errorMessages={["Document Classification is required"]}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Type</Label>

              {/* <TextValidator
                type="text"
                id={"title_documents_attributes[][doc_type]"}
                name="title_documents_attributes[][doc_type]"
                placeholder="Document Memo Notes"
                onChange={handleChange}
                value={row["title_documents_attributes[][doc_type]"]}
              /> */}
              <Select
                id={"title_documents_attributes[][doc_type]"}
                name="title_documents_attributes[][doc_type]"
                value={{
                  value: row["title_documents_attributes[][doc_type]"],
                  label: row["title_documents_attributes[][doc_type]"],
                }}
                onChange={(selectedType) => {
                  let selectedValue = row;
                  selectedValue["title_documents_attributes[][doc_type]"] =
                    selectedType.value;
                  setrow(Object.assign({ ...row }, { selectedValue }));
                }}
                options={doc_type}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label for="">Document File Upload</Label>
          <Input
            type="file"
            name="file"
            id={
              props.isMuniVendorUser
                ? "municipal_documents_attributes[][file]"
                : props.isMuniciReviewAction
                ? "municipal_documents_attributes[][file]"
                : "title_documents_attributes[][file]"
            }
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Button className={""} onSubmit={handleSubmit}>
          Submit
        </Button>
      </div>
            </>
          )}
      </>
    );
  };

  const handleSubmit = (event) => {
    setisLoading(true);
    if (props.isVendorUser) {
      apicall.uploadDocuments(row, props.id).then((response) => {
        if (response) {
          helpers.notify("Document Uploaded Successfully.");
          setisLoading(false);
          setrow(response);
          props.toggle();
          props.fetchDocuments();
        }
      });
    } else if (props.isMuniVendorUser) {
      apicall.municipalityUploadDocuments(row, props.id).then((response) => {
        if (response) {
          helpers.notify("Document Uploaded Successfully.");
          setisLoading(false);
          setrow(response);
          props.toggle();
          props.fetchDocuments();
        }
      });
    } else if (props.isMuniciReviewAction) {
      props.onHandleSubmit();
      apicall
        .municipalityReviewUploadDocuments(
          row,
          props.id,
          props.issueID,
          props.issueType
        )
        .then((response) => {
          if (response) {
            helpers.notify("Document Uploaded Successfully.");
            setisLoading(false);
            setrow(response);
            props.toggle();
            props.fetchDocuments();
          }
        });
    } else if (props.isMunicipalityRequirement) {
      apicall
        .municipalityRequirementUploadDocuments(
          row,
          props.id,
          props.requirementTypeID
        )
        .then((response) => {
          if (response) {
            helpers.notify("Document Uploaded Successfully.");
            setisLoading(false);
            setrow(response);
            props.toggle();
            props.fetchDocuments(props.id);
          }
        });
    } else if (props.isPayment) {
      setisLoading(false);
      props.handleSubmit(row);
    } else if (props.isLienModal) {
      setisLoading(false);
      console.log("The file is", row);
      props.handleSubmit(row);
    } else if (props.isDeedVerification) {
      let data = row;
      delete data.deed_verification_notes
      data["title_documents_attributes[][category]"] = "Deed";
      apicall
        .updateDeedVerification(props.actionID, props.propertyID, data)
        .then((response) => {
          if (response) {
            setisLoading(false);
            helpers.notify("Document Uploaded Successfully");
            setrow(response);
            props.toggle();
            props.fetchDocuments(props.id);
          }
        });
    } else if (props.isMunicipalityRegistrationAction) {
      let data = row;

      apicall
        .updateMunicipalityAction(data, props.id, props.propertyID)
        .then((response) => {
          if (response) {
            setisLoading(false);
            helpers.notify("Document Uploaded Successfully");
            setrow(response);
            props.toggle();
            props.fetchDocuments(props.id);
          }
        });
    } else if (props.isLienReleaseAction) {
      setisLoading(false);
      props.handleSubmit(row);
    }
    //  else {
    //   apicall
    //     .updateDeedVerification(
    //       this.props.actionID,
    //       this.props.propertyID,
    //       this.state.row
    //     )
    //     .then((response) => {
    //       if (response) {
    //         this.notify("Document Uploaded Successfully.");
    //         this.setState({
    //           row: response,
    //           isLoading: false,
    //         });
    //         this.props.toggle();
    //         this.props.fetchDocuments();
    //       }
    //     });
    // }
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">{"Add Document"}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="violationFileUpload-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
