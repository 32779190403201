import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
var dashboardapi = {
  getDashboard: async function (start_date, end_date) {
    let url = config.baseUrl + "/api/v1/dashboard?";
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    let params = {};

    params["start_date"] = start_date;
    params["end_date"] = end_date;

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => "dashboard[" + k + "]=" + encodeURIComponent(params[k]))
      .join("&");

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  getDashboardActions: async function (data) {
    console.log("correct api called");
    console.log("data-->", data);
    let url = "";

    url = config.baseUrl + "/api/v1/dashboard/property_actions?";

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    let params = {};

    params["start_date"] = data.start_date;
    params["end_date"] = data.end_date;

    if (data.action_type) params["action_types"] = data.action_type;

    if (data.analyst) params["analyst_ids"] = data.analyst.value;

    if (data.due_date_check === true)
      params["due_date_check"] = data.due_date_check;

    if (data.page_no) params["page"] = data.page_no;

    if (data.per_page) params["per_page"] = data.per_page;

    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        if (k === "analyst_ids") return "dashboard[analyst_ids][]=" + params[k];
        if (k === "action_types")
          return "dashboard[action_types][]=" + params[k];
        else return "dashboard[" + k + "]=" + encodeURIComponent(params[k]);
      })
      .join("&");

    console.log("data.states", data.states);

    let statesquery =
      data.states &&
      data.states
        .map((x) => {
          return "dashboard[states][]=" + x.value;
        })
        .join("&");

    if (statesquery) query += `&${statesquery}`;
    console.log(query);
    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },

  getActionTypeActions: async function (data, property_actions, page) {
    console.log("wrong api called");
    let url = "";

    if (property_actions)
      url = config.baseUrl + "/api/v1/dashboard/action_type_property_actions?";
    else url = config.baseUrl + "/api/v1/dashboard/action_type_actions?";

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    let params = {};

    params["start_date"] = data.start_date;
    params["end_date"] = data.end_date;
    params["action_type"] = data.action_type;

    if (data.analyst) params["analyst_ids"] = data.analyst.value;

    if (data.due_date_check === true)
      params["due_date_check"] = data.due_date_check;

    if (page) params["page"] = page;

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        if (k === "analyst_ids") return "dashboard[analyst_ids][]=" + params[k];
        else return "dashboard[" + k + "]=" + encodeURIComponent(params[k]);
      })
      .join("&");

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  getActionTypePropertyActions: async function (data, page) {
    return this.getActionTypeActions(data, true, page);
  },

  searchAnalysts: async function (q, role) {
    let url = config.baseUrl + `/api/v1/users/search?q=${q}`;
    if (typeof role !== "undefined") {
      url = url + `&role=${role}`;
    }
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },
  getQuickStats: async function (states) {
    var url;
    if (Array.isArray(states) && !_.isEmpty(states)) {
      let arr = [];
      states.map((x) => arr.push(`states[]=${x.value}`));
      let statesUrl = arr.join("&");
      url = config.baseUrl + `/api/v1/dashboard/quick_stats?${statesUrl}`;
    } else {
      url = config.baseUrl + `/api/v1/dashboard/quick_stats`;
    }
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },
  getStates: async function () {
    let url = config.baseUrl + `/api/v1/dashboard/states`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },

  getQuickStatus: async function (data, zipcodes) {
    let zip;
    if (zipcodes !== null) {
      zip = zipcodes !== undefined && zipcodes.split(",");
    }
    let url = config.baseUrl + `/api/v1/dashboard/quick_statistics?`;
    let params = {};
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return +k + "[]=" + encodeURIComponent(params[k]);
      })
      .join("&");

    let entityquery =
      data.entity_names &&
      data.entity_names
        .map((x) => {
          return "entity_names[]=" + x.value;
        })
        .join("&");

    if (entityquery) {
      _.isEmpty(query)
        ? (query += `${entityquery}`)
        : (query += `&${entityquery}`);
    }

    let propertyquery =
      data.property_groups &&
      data.property_groups
        .map((x) => {
          return "property_groups[]=" + x.value;
        })
        .join("&");

    if (propertyquery) {
      _.isEmpty(query)
        ? (query += `${propertyquery}`)
        : (query += `&${propertyquery}`);
    }

    let statesquery =
      data.states &&
      data.states
        .map((x) => {
          return "states[]=" + x.value;
        })
        .join("&");

    if (statesquery) {
      _.isEmpty(query)
        ? (query += `${statesquery}`)
        : (query += `&${statesquery}`);
    }

    let zipcodesquery =
      zip &&
      zip
        .map((x) => {
          return _.isEmpty(x) ? "" : "zipcodes[]=" + x;
        })
        .join("&");

    if (zipcodesquery) {
      _.isEmpty(query)
        ? (query += `${zipcodesquery}`)
        : (query += `&${zipcodesquery}`);
    }

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },

  getEntity_names: async function () {
    let url = config.baseUrl + `/api/v1/properties/entity_names`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },
  getProperty_groups: async function () {
    let url = config.baseUrl + `/api/v1/properties/property_groups`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },

  getQuickStatusStates: async function () {
    let url = config.baseUrl + `/api/v1/dashboard/all_property_states`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },
  getPendingStats: async function () {
    let url = config.baseUrl + `/api/v1/dashboard/pending_stats`;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },

  getDashboardPendingStats: async function (
    data,
    tierOne,
    tierTwo,
    tierThree,
    tierFour,
    paymentRightSideValue,
    page,
    per_page
  ) {
    let url = config.baseUrl + `/api/v1/dashboard/new_dashboard?`;
    let params = {};

    if (tierOne) params["tier1"] = tierOne;
    if (tierTwo) params["tier2"] = tierTwo;
    if (tierThree) params["tier3"] = tierThree;
    if (tierFour) params["tier4"] = tierFour;
    if (paymentRightSideValue) params["side"] = paymentRightSideValue;
    if (page) params["page"] = page;
    if (per_page) params["per_page"] = per_page;

    if (data.start_date) params["start_date"] = data.start_date;
    if (data.end_date) params["end_date"] = data.end_date;
    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return "dashboard[" + k + "]=" + encodeURIComponent(params[k]);
      })
      .join("&");

    let statesquery =
      data.states &&
      data.states
        .map((x) => {
          return "dashboard[states][]=" + x.value;
        })
        .join("&");

    if (statesquery)
      _.isEmpty(query)
        ? (query += `${statesquery}`)
        : (query += `&${statesquery}`);

    let entityquery =
      data.entity_names &&
      data.entity_names
        .map((x) => {
          return "dashboard[entities][]=" + x.value;
        })
        .join("&");

    if (entityquery) {
      _.isEmpty(query)
        ? (query += `${entityquery}`)
        : (query += `&${entityquery}`);
    }

    let propertyquery =
      data.property_groups &&
      data.property_groups
        .map((x) => {
          return "dashboard[property_groups][]=" + x.value;
        })
        .join("&");

    if (propertyquery) {
      _.isEmpty(query)
        ? (query += `${propertyquery}`)
        : (query += `&${propertyquery}`);
    }

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => {
        return { results: json.json };
      });
  },
};

export default withRouter(dashboardapi);
