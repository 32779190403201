import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_municipalModal.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import _ from "lodash";
import config from "../../utils/config";
import TitleDocumentsModal from "../documents/TitleDocumentsModal";
import SelectOptions from "../helper_components/SelectOptions";
import DocumentsTab from "../documents/DocumentsTab";
import MunicipalRequirementLoginModal from "./MunicipalRequirementLoginModal";

export default function MunicipalRequirementModal(props) {
  const [formdata, setform] = useState(
    props.municipality_requirements ? props.municipality_requirements : []
  );
  const [openFileUpload, setopenFileUpload] = useState(false);
  const [requirementTypeID, setrequirementTypeID] = useState();
  const [documents, setdocuments] = useState();
  const [openRequirementModal, setopenRequirementModal] = useState(false);

  const [isBusinessDisabled, setisBusinessDisabled] = useState(false);
  const [isLandlordDisabled, setisLandlordDisabled] = useState(false);
  const [isVacantDisabled, setisVacantDisabled] = useState(false);
  const [isOccupancyDisabled, setisOccupancyDisabled] = useState(false);
  const [isRentalDisabled, setisRentalDisabled] = useState(false);
  const [isCrimeHousingDisabled, setisCrimeHousingDisabled] = useState(false);
  const [isRentalTaxesDisabled, setisRentalTaxesDisabled] = useState(false);
  const [isPeriodicDisabled, setisPeriodicDisabled] = useState(false);

  const [businessRequirement, setbusinessRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Business License Registration",
  });

  const [landlordRequirement, setlandlordRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Landlord Registration",
  });

  const [vacantRequirement, setvacantRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Vacant Property Registration",
  });

  const [occupancyRequirement, setoccupancyRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Certificate of Occupancy",
  });

  const [rentalRequirement, setrentalRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Rental Registration",
  });

  const [crimeHousingRequirement, setcrimeHousingRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Crime Free Housing",
  });

  const [rentalTaxesRequirement, setrentalTaxesRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Rental Taxes",
  });

  const [periodicRequirement, setperiodicRequirement] = useState({
    payment_method: "",
    periodicity: "",
    registration_amount: "",
    registration_method: "",
    required: "",
    requirement_type: "Periodic Registrations",
  });

  useEffect(() => {
    setmunicipality_requirements();
  }, []);

  const setmunicipality_requirements = () => {
    if (!_.isEmpty(formdata)) {
      // setdocuments(formdata.)
      let businessResult;
      businessResult = formdata.filter((e) => {
        if (e.requirement_type === "Business License Registration") return e;
      });
      setbusinessRequirement(businessResult[0]);
      if (businessResult[0].required === "N/A") setisBusinessDisabled(true);

      let landlordResult;
      landlordResult = formdata.filter((e) => {
        if (e.requirement_type === "Landlord Registration") return e;
      });
      setlandlordRequirement(landlordResult[0]);
      if (landlordResult[0].required === "N/A") setisLandlordDisabled(true);

      let vacantResult;
      vacantResult = formdata.filter((e) => {
        if (e.requirement_type === "Vacant Property Registration") return e;
      });
      setvacantRequirement(vacantResult[0]);
      if (vacantResult[0].required === "N/A") setisVacantDisabled(true);

      let occupancyResult;
      occupancyResult = formdata.filter((e) => {
        if (e.requirement_type === "Certificate of Occupancy") return e;
      });
      setoccupancyRequirement(occupancyResult[0]);
      if (occupancyResult[0].required === "N/A") setisOccupancyDisabled(true);

      let rentalResult;
      rentalResult = formdata.filter((e) => {
        if (e.requirement_type === "Rental Registration") return e;
      });
      setrentalRequirement(rentalResult[0]);
      if (rentalResult[0].required === "N/A") setisRentalDisabled(true);

      let crimeResult;
      crimeResult = formdata.filter((e) => {
        if (e.requirement_type === "Crime Free Housing") return e;
      });
      setcrimeHousingRequirement(crimeResult[0]);
      if (crimeResult[0].required === "N/A") setisCrimeHousingDisabled(true);

      let rentalTaxResult;
      rentalTaxResult = formdata.filter((e) => {
        if (e.requirement_type === "Rental Taxes") return e;
      });
      setrentalTaxesRequirement(rentalTaxResult[0]);
      if (rentalTaxResult[0].required === "N/A") setisRentalTaxesDisabled(true);

      let periodicResult;
      periodicResult = formdata.filter((e) => {
        if (e.requirement_type === "Periodic Registrations") return e;
      });
      setperiodicRequirement(periodicResult[0]);
      if (periodicResult[0].required === "N/A") setisPeriodicDisabled(true);
    }
  };

  const businessLicenseOptions = [
    "Owner",
    "Property",
    "Property Management Co.",
    "Both (Owner & Prop Mgmt Co.)",
    "N/A",
  ];
  const registrationMethodOptions = ["Online", "Offline"];
  const paymentMethodOptions = ["Credit Card", "Check", "eCheck", "N/A"];
  const requiredValueOptions = ["Yes", "N/A"];
  const vacantPropertyOptions = ["All VPR", "Foreclosures Only", "N/A"];
  const rentalRegistrationOptions = [
    "All Rentals",
    "At Time of Leasing",
    "N/A",
  ];
  const occupancyRequirementOptions = [
    "Yes", 
    "At Time of Leasing",
    "N/A",
  ];

  const validateForm = () => {
    let isValid = true;
    if (/^[a-zA-Z]+$/.test(formdata.state)) {
    }
    return isValid;
  };
  const handleSubmit = (event) => {
    if (validateForm) {
      let dataobject = [
        {
          id: businessRequirement.id,
          requirement_type: "Business License Registration",
          required: businessRequirement.required,
          registration_method: businessRequirement.registration_method,
          payment_method: businessRequirement.payment_method,
          periodicity: businessRequirement.periodicity,
          registration_amount: businessRequirement.registration_amount,
          username: businessRequirement.username,
          password: businessRequirement.password,
          muni_req_website: businessRequirement.muni_req_website,
          inspection_required: businessRequirement.inspection_required,
        },
        {
          id: landlordRequirement.id,
          requirement_type: "Landlord Registration",
          required: landlordRequirement.required,
          registration_method: landlordRequirement.registration_method,
          payment_method: landlordRequirement.payment_method,
          periodicity: landlordRequirement.periodicity,
          registration_amount: landlordRequirement.registration_amount,
          username: landlordRequirement.username,
          password: landlordRequirement.password,
          muni_req_website: landlordRequirement.muni_req_website,
          inspection_required: landlordRequirement.inspection_required,
        },
        {
          id: vacantRequirement.id,
          requirement_type: "Vacant Property Registration",
          required: vacantRequirement.required,
          registration_method: vacantRequirement.registration_method,
          payment_method: vacantRequirement.payment_method,
          periodicity: vacantRequirement.periodicity,
          registration_amount: vacantRequirement.registration_amount,
          username: vacantRequirement.username,
          password: vacantRequirement.password,
          muni_req_website: vacantRequirement.muni_req_website,
          inspection_required: vacantRequirement.inspection_required,
        },
        {
          id: occupancyRequirement.id,
          requirement_type: "Certificate of Occupancy",
          required: occupancyRequirement.required,
          registration_method: occupancyRequirement.registration_method,
          payment_method: occupancyRequirement.payment_method,
          periodicity: occupancyRequirement.periodicity,
          registration_amount: occupancyRequirement.registration_amount,
          username: occupancyRequirement.username,
          password: occupancyRequirement.password,
          muni_req_website: occupancyRequirement.muni_req_website,
          inspection_required: occupancyRequirement.inspection_required,
        },
        {
          id: rentalRequirement.id,
          requirement_type: "Rental Registration",
          required: rentalRequirement.required,
          registration_method: rentalRequirement.registration_method,
          payment_method: rentalRequirement.payment_method,
          periodicity: rentalRequirement.periodicity,
          registration_amount: rentalRequirement.registration_amount,
          username: rentalRequirement.username,
          password: rentalRequirement.password,
          muni_req_website: rentalRequirement.muni_req_website,
          inspection_required: rentalRequirement.inspection_required,
        },
        {
          id: crimeHousingRequirement.id,
          requirement_type: "Crime Free Housing",
          required: crimeHousingRequirement.required,
          registration_method: crimeHousingRequirement.registration_method,
          payment_method: crimeHousingRequirement.payment_method,
          periodicity: crimeHousingRequirement.periodicity,
          registration_amount: crimeHousingRequirement.registration_amount,
          username: crimeHousingRequirement.username,
          password: crimeHousingRequirement.password,
          muni_req_website: crimeHousingRequirement.muni_req_website,
          inspection_required: crimeHousingRequirement.inspection_required,
        },
        {
          id: rentalTaxesRequirement.id,
          requirement_type: "Rental Taxes",
          required: rentalTaxesRequirement.required,
          registration_method: rentalTaxesRequirement.registration_method,
          payment_method: rentalTaxesRequirement.payment_method,
          periodicity: rentalTaxesRequirement.periodicity,
          registration_amount: rentalTaxesRequirement.registration_amount,
          username: rentalTaxesRequirement.username,
          password: rentalTaxesRequirement.password,
          muni_req_website: rentalTaxesRequirement.muni_req_website,
          inspection_required: rentalTaxesRequirement.inspection_required,
        },
        {
          id: periodicRequirement.id,
          requirement_type: "Periodic Registrations",
          required: periodicRequirement.required,
          registration_method: periodicRequirement.registration_method,
          payment_method: periodicRequirement.payment_method,
          periodicity: periodicRequirement.periodicity,
          registration_amount: periodicRequirement.registration_amount,
          username: periodicRequirement.username,
          password: periodicRequirement.password,
          muni_req_website: periodicRequirement.muni_req_website,
          inspection_required: periodicRequirement.inspection_required,
        },
      ];
      const data = { municipality: props.formdata };
      data.municipality.municipality_requirements_attributes = dataobject;

      if (
        (isBusinessDisabled &&
          isLandlordDisabled &&
          isVacantDisabled &&
          isOccupancyDisabled &&
          isRentalDisabled &&
          isCrimeHousingDisabled &&
          isRentalTaxesDisabled &&
          isPeriodicDisabled) === false
      ) {
        setopenRequirementModal(true);
        setform(dataobject);
      } else
        apicall.updatemunicipality(data, props.formdata.id).then((response) => {
          helpers.notify("Municipality updated Successfully");
          setTimeout(() => {
            props.readMunicipal(response.id);
            props.toggle();
          }, 600);
        });
    }
  };

  const uploadFile = (id, type, isdisabled) => {
    return (
      <div>
        <Button
          onClick={() => {
            setopenFileUpload(true);
            setrequirementTypeID(id);
          }}
          style={{ marginBottom: "0px" }}
          disabled={isdisabled}
        >
          File Upload
        </Button>
      </div>
    );
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    const value = event.target.value;
    if (targetName === "business_license_registration_amount") {
      setbusinessRequirement({
        ...businessRequirement,
        registration_amount: value,
      });
    } else if (targetName === "landlord_registration_amount") {
      setlandlordRequirement({
        ...landlordRequirement,
        registration_amount: value,
      });
    } else if (targetName === "vacant_property_registration_amount") {
      setvacantRequirement({
        ...vacantRequirement,
        registration_amount: value,
      });
    } else if (targetName === "certificateOfOccupancy_registration_amount") {
      setoccupancyRequirement({
        ...occupancyRequirement,
        registration_amount: value,
      });
    } else if (targetName === "rentalRegistration_registration_amount") {
      setrentalRequirement({
        ...rentalRequirement,
        registration_amount: value,
      });
    } else if (targetName === "crimeFreeHousing_registration_amount") {
      setcrimeHousingRequirement({
        ...crimeHousingRequirement,
        registration_amount: value,
      });
    } else if (targetName === "rentalTaxes_registration_amount") {
      setrentalTaxesRequirement({
        ...rentalTaxesRequirement,
        registration_amount: value,
      });
    } else if (targetName === "periodicRegistrations_registration_amount") {
      setperiodicRequirement({
        ...periodicRequirement,
        registration_amount: value,
      });
    }
  };

  const deleteMuniRequirementDocument = (muni_req_id, documentId) => {
    apicall
      .deleteMuniRequirementDocument(props.formdata.id, documentId, muni_req_id)
      .then((response) => {
        helpers.notify("Document deleted successfully");
        props.readMunicipal(props.formdata.id);
      });
  };

  const renderFormInputElements = (name, value, isdisabled) => {
    return (
      <div className="ip-fields">
        <Input
          type={"text"}
          name={name}
          id={name}
          onChange={(e) => handleChange(e)}
          value={value}
          disabled={isdisabled}
        />
      </div>
    );
  };

  const toggleDocument = () => {
    setopenFileUpload(!openFileUpload);
  };

  const toggleModal = () => {
    setopenRequirementModal(!openRequirementModal);
    if (openRequirementModal) props.toggle();
  };

  const formElements = () => {
    return (
      <>
        <table className="reviewActionTable" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "14%" }}></th>
              <th style={{ width: "14%" }}>Required</th>
              <th style={{ width: "4%" }}>Registration Method</th>
              <th style={{ width: "7%" }}>Payment Method</th>
              <th>Periodicity</th>
              <th style={{ width: "4%" }}>Registration Amount</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style={{ fontSize: "1rem" }}>
                Business License Registration
              </td>{" "}
              <td>
                <Input
                  type="select"
                  name={"required"}
                  id={"required"}
                  onChange={(event) => {
                    setbusinessRequirement({
                      ...businessRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A")
                      setisBusinessDisabled(true);
                    else setisBusinessDisabled(false);
                  }}
                  value={businessRequirement.required}
                >
                  <SelectOptions
                    options={businessLicenseOptions}
                    disabled={isBusinessDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setbusinessRequirement({
                      ...businessRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={businessRequirement.registration_method}
                  disabled={isBusinessDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isBusinessDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setbusinessRequirement({
                      ...businessRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={businessRequirement.payment_method}
                  disabled={isBusinessDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isBusinessDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setbusinessRequirement({
                      ...businessRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={businessRequirement.periodicity}
                  disabled={
                    businessRequirement.payment_method === "N/A"
                      ? true
                      : isBusinessDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isBusinessDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "business_license_registration_amount",
                  businessRequirement.registration_amount,
                  businessRequirement.payment_method === "N/A"
                    ? true
                    : isBusinessDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  businessRequirement.id,
                  "business_license_registration",
                  isBusinessDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Landlord Registration</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"required"}
                  id={"required"}
                  onChange={(event) => {
                    setlandlordRequirement({
                      ...landlordRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A")
                      setisLandlordDisabled(true);
                    else setisLandlordDisabled(false);
                  }}
                  value={landlordRequirement.required}
                >
                  <SelectOptions
                    options={requiredValueOptions}
                    disabled={isLandlordDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setlandlordRequirement({
                      ...landlordRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={landlordRequirement.registration_method}
                  disabled={isLandlordDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isLandlordDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setlandlordRequirement({
                      ...landlordRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={landlordRequirement.payment_method}
                  disabled={isLandlordDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isLandlordDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setlandlordRequirement({
                      ...landlordRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={landlordRequirement.periodicity}
                  disabled={
                    landlordRequirement.payment_method === "N/A"
                      ? true
                      : isLandlordDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isLandlordDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "landlord_registration_amount",
                  landlordRequirement.registration_amount,
                  landlordRequirement.payment_method === "N/A"
                    ? true
                    : isLandlordDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  landlordRequirement.id,
                  "landlord_registration",
                  isLandlordDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Vacant Property Registration</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"vacantPropert_required"}
                  id={"required"}
                  onChange={(event) => {
                    setvacantRequirement({
                      ...vacantRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A") setisVacantDisabled(true);
                    else setisVacantDisabled(false);
                  }}
                  value={vacantRequirement.required}
                >
                  <SelectOptions
                    options={vacantPropertyOptions}
                    disabled={isVacantDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setvacantRequirement({
                      ...vacantRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={vacantRequirement.registration_method}
                  disabled={isVacantDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isVacantDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setvacantRequirement({
                      ...vacantRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={vacantRequirement.payment_method}
                  disabled={isVacantDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isVacantDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setvacantRequirement({
                      ...vacantRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={vacantRequirement.periodicity}
                  disabled={
                    vacantRequirement.payment_method === "N/A"
                      ? true
                      : isVacantDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isVacantDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "vacant_property_registration_amount",
                  vacantRequirement.registration_amount,
                  vacantRequirement.payment_method === "N/A"
                    ? true
                    : isVacantDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  vacantRequirement.id,
                  "vacant_property_registration",
                  isVacantDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Certificate of Occupancy</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"vacantPropert_required"}
                  id={"required"}
                  onChange={(event) => {
                    setoccupancyRequirement({
                      ...occupancyRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A")
                      setisOccupancyDisabled(true);
                    else setisOccupancyDisabled(false);
                  }}
                  value={occupancyRequirement.required}
                >
                  <SelectOptions
                    options={occupancyRequirementOptions}
                    disabled={isOccupancyDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setoccupancyRequirement({
                      ...occupancyRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={occupancyRequirement.registration_method}
                  disabled={isOccupancyDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isOccupancyDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setoccupancyRequirement({
                      ...occupancyRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={occupancyRequirement.payment_method}
                  disabled={isOccupancyDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isOccupancyDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setoccupancyRequirement({
                      ...occupancyRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={occupancyRequirement.periodicity}
                  disabled={
                    occupancyRequirement.payment_method === "N/A"
                      ? true
                      : isOccupancyDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isOccupancyDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "certificateOfOccupancy_registration_amount",
                  occupancyRequirement.registration_amount,
                  occupancyRequirement.payment_method === "N/A"
                    ? true
                    : isOccupancyDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  occupancyRequirement.id,
                  "certificateOfOccupancy_registration",
                  isOccupancyDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Rental Registration</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"rentalRegistration_required"}
                  id={"required"}
                  onChange={(event) => {
                    setrentalRequirement({
                      ...rentalRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A") setisRentalDisabled(true);
                    else setisRentalDisabled(false);
                  }}
                  value={rentalRequirement.required}
                >
                  <SelectOptions
                    options={rentalRegistrationOptions}
                    disabled={isRentalDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setrentalRequirement({
                      ...rentalRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={rentalRequirement.registration_method}
                  disabled={isRentalDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isRentalDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setrentalRequirement({
                      ...rentalRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={rentalRequirement.payment_method}
                  disabled={isRentalDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isRentalDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setrentalRequirement({
                      ...rentalRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={rentalRequirement.periodicity}
                  disabled={
                    rentalRequirement.payment_method === "N/A"
                      ? true
                      : isRentalDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isRentalDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "rentalRegistration_registration_amount",
                  rentalRequirement.registration_amount,
                  rentalRequirement.payment_method === "N/A"
                    ? true
                    : isRentalDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  rentalRequirement.id,
                  "rentalRegistration_registration",
                  isRentalDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Crime Free Housing</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"required"}
                  id={"required"}
                  onChange={(event) => {
                    setcrimeHousingRequirement({
                      ...crimeHousingRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A")
                      setisCrimeHousingDisabled(true);
                    else setisCrimeHousingDisabled(false);
                  }}
                  value={crimeHousingRequirement.required}
                >
                  <SelectOptions
                    options={requiredValueOptions}
                    disabled={isCrimeHousingDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setcrimeHousingRequirement({
                      ...crimeHousingRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={crimeHousingRequirement.registration_method}
                  disabled={isCrimeHousingDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isCrimeHousingDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setcrimeHousingRequirement({
                      ...crimeHousingRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={crimeHousingRequirement.payment_method}
                  disabled={isCrimeHousingDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isCrimeHousingDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setcrimeHousingRequirement({
                      ...crimeHousingRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={crimeHousingRequirement.periodicity}
                  disabled={
                    crimeHousingRequirement.payment_method === "N/A"
                      ? true
                      : isCrimeHousingDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isCrimeHousingDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "crimeFreeHousing_registration_amount",
                  crimeHousingRequirement.registration_amount,
                  crimeHousingRequirement.payment_method === "N/A"
                    ? true
                    : isCrimeHousingDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  crimeHousingRequirement.id,
                  "crimeFreeHousing_registration",
                  isCrimeHousingDisabled
                )}
              </td>
            </tr>

            <tr>
              <td style={{ fontSize: "1rem" }}>Rental Taxes</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"rentalRegistration_required"}
                  id={"required"}
                  onChange={(event) => {
                    setrentalTaxesRequirement({
                      ...rentalTaxesRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A")
                      setisRentalTaxesDisabled(true);
                    else setisRentalTaxesDisabled(false);
                  }}
                  value={rentalTaxesRequirement.required}
                >
                  <SelectOptions
                    options={requiredValueOptions}
                    disabled={isRentalTaxesDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setrentalTaxesRequirement({
                      ...rentalTaxesRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={rentalTaxesRequirement.registration_method}
                  disabled={isRentalTaxesDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isRentalTaxesDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setrentalTaxesRequirement({
                      ...rentalTaxesRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={rentalTaxesRequirement.payment_method}
                  disabled={isRentalTaxesDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isRentalTaxesDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setrentalTaxesRequirement({
                      ...rentalTaxesRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={rentalTaxesRequirement.periodicity}
                  disabled={
                    rentalTaxesRequirement.payment_method === "N/A"
                      ? true
                      : isRentalTaxesDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isRentalTaxesDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "rentalTaxes_registration_amount",
                  rentalTaxesRequirement.registration_amount,
                  rentalTaxesRequirement.payment_method === "N/A"
                    ? true
                    : isRentalTaxesDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  rentalTaxesRequirement.id,
                  "rentalTaxes__registration",
                  isRentalTaxesDisabled
                )}
              </td>
            </tr>

            <tr>
              {/* <td style={{ fontSize: "1rem" }}>Periodic Registrations</td>{" "} */}
              <td style={{ fontSize: "1rem" }}>Property Deregistration</td>{" "}
              <td>
                <Input
                  type="select"
                  name={"rentalRegistration_required"}
                  id={"required"}
                  onChange={(event) => {
                    setperiodicRequirement({
                      ...periodicRequirement,
                      required: event.target.value,
                    });
                    if (event.target.value === "N/A")
                      setisPeriodicDisabled(true);
                    else setisPeriodicDisabled(false);
                  }}
                  value={periodicRequirement.required}
                >
                  <SelectOptions
                    options={requiredValueOptions}
                    disabled={isPeriodicDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"registration_method"}
                  id={"registration_method"}
                  onChange={(event) => {
                    setperiodicRequirement({
                      ...periodicRequirement,
                      registration_method: event.target.value,
                    });
                  }}
                  value={periodicRequirement.registration_method}
                  disabled={isPeriodicDisabled}
                >
                  <SelectOptions
                    options={registrationMethodOptions}
                    disabled={isPeriodicDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"payment_method"}
                  id={"payment_method"}
                  onChange={(event) => {
                    setperiodicRequirement({
                      ...periodicRequirement,
                      payment_method: event.target.value,
                    });
                  }}
                  value={periodicRequirement.payment_method}
                  disabled={isPeriodicDisabled}
                >
                  <SelectOptions
                    options={paymentMethodOptions}
                    disabled={isPeriodicDisabled}
                  />
                </Input>
              </td>
              <td>
                <Input
                  type="select"
                  name={"periodicity"}
                  id={"periodicity"}
                  onChange={(event) => {
                    setperiodicRequirement({
                      ...periodicRequirement,
                      periodicity: event.target.value,
                    });
                  }}
                  value={periodicRequirement.periodicity}
                  disabled={
                    periodicRequirement.payment_method === "N/A"
                      ? true
                      : isPeriodicDisabled
                  }
                >
                  <SelectOptions
                    options={config.municipalPeriodicity}
                    disabled={isPeriodicDisabled}
                  />
                </Input>
              </td>
              <td>
                {renderFormInputElements(
                  "periodicRegistrations_registration_amount",
                  periodicRequirement.registration_amount,
                  periodicRequirement.payment_method === "N/A"
                    ? true
                    : isPeriodicDisabled
                )}
              </td>
              <td>
                {uploadFile(
                  periodicRequirement.id,
                  "periodicRegistrations_registration",
                  isPeriodicDisabled
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <DocumentsTab
          id={props.formdata.id}
          issueID={requirementTypeID}
          documents={props.formdata.title_documents}
          isMuniRequirementDocument={true}
          deleteDocument={deleteMuniRequirementDocument}
          theme={"general-info-tab"}
        />

        <div className="municipal-submit">
          <Button
            className="municipal-submit-button"
            type="button"
            id="submit"
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Submit
          </Button>
        </div>
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Municipality Requirement</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="municipal-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
      {openFileUpload && (
        <TitleDocumentsModal
          toggle={toggleDocument}
          open={openFileUpload}
          title="Document"
          id={props.formdata.id}
          requirementTypeID={requirementTypeID}
          isMunicipalityRequirement={true}
          fetchDocuments={props.readMunicipal}
        />
      )}
      {openRequirementModal && (
        <MunicipalRequirementLoginModal
          open={openRequirementModal}
          toggle={toggleModal}
          title="Municipal Requirement Login Modal"
          id={props.formdata.id}
          readMunicipal={props.readMunicipal}
          formdata={formdata}
          municipality={props.formdata}
        />
      )}
    </div>
  );
}
