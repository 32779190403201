import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_lawFirm.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import "react-multi-email/style.css";
import _ from "lodash";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import config from "../../utils/config";
import { toast } from "react-toastify";

export default function LawFirmModal(props) {
  const [formdata, setform] = useState(props.formdata ? props.formdata : {});
  const [pocArray, setpocArray] = useState(
    props.formdata !== undefined &&
      !_.isEmpty(props.formdata.title_point_of_contacts)
      ? props.formdata.title_point_of_contacts
      : []
  );

  const readLawFirm = (id) => {
    apicall.readLawFirm(id).then((response) => {
      if (response) setpocArray(response.code_officer_details);
    });
  };

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      let res = helpers.formatZip(value);
      setform(Object.assign({ ...formdata }, { [targetID]: res }));
    } else {
      setform(Object.assign({ ...formdata }, { [targetID]: value }));
    }
  };

  const notify = (errorMsg) => toast(errorMsg);
  const handleSubmit = (event) => {
    if (validateForm) {
      let pocdata = pocArray;
      //remove temporary Id'
      for (let index in pocdata) {
        if (_.has(pocdata[index], "status")) {
          delete pocdata[index]["id"];
          delete pocdata[index]["status"];
        }
      }
      formdata.title_point_of_contacts_attributes = pocdata;
      var requestObject = {
        law_firm: {
          name: formdata.name,
          address: formdata.address,
          city: formdata.city,
          state: formdata.state,
          zip: formdata.zip,
          website: formdata.website,
          title_point_of_contacts_attributes: (formdata.title_point_of_contacts_attributes = pocdata),
        },
      };

      if (formdata.id) {
        apicall.updateLawFirm(requestObject, formdata.id).then((response) => {
          if (response !== null && response.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(response.error);
            if (errorMsg) notify(errorMsg);
          } else {
            helpers.notify("Law Firm Created Successfully");
            setTimeout(() => {
              setform(response);
              props.getAllLawyers();
              props.toggle();
            }, 600);
          }
        });
      } else {
        apicall.createLawFirm(requestObject).then((response) => {
          if (response !== null && response.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(response.error);
            if (errorMsg) notify(errorMsg);
          } else {
            helpers.notify("Law Firm Updated Successfully");
            setTimeout(() => {
              setform(response);
              props.getAllLawyers();
              props.toggle();
            }, 600);
          }
        });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (/^[a-zA-Z]+$/.test(formdata.state)) {
    }
    return isValid;
  };

  const handlePocChange = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var newPocArray = pocArray.map((poc, index) => {
      for (var key in poc) {
        if (key == item.name && poc.id == item.id) {
          if (key === "phone") {
            let phone = helpers.formatPhone(item.value);
            poc[key] = phone;
          } else poc[key] = item.value;
        }
      }
      return poc;
    });

    setpocArray(newPocArray);
  };

  const handleDeleteEvent = (event, poc) => {
    formdata.id &&
      apicall.deleteLawFirmPOC(formdata.id, poc.id).then((resp) => {
        helpers.notify("POC deleted");
      });
    setTimeout(() => readLawFirm(formdata.id), 3000);
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    var poc = {
      id: id,
      name: "",
      title: "",
      email: "",
      phone: "",
      status: "temp",
    };

    setpocArray([...pocArray, poc]);
  };

  const pocModel = (poc, index) => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];
    var phoneValidators = [];
    var phoneValidatorMessage = [];
    let pocTitleOptions = [];
    let PocTitles = config.McPocTitles;
    pocTitleOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    let titleIndex = 0;
    for (var key in PocTitles) {
      if (PocTitles.hasOwnProperty(key)) {
        pocTitleOptions.push(
          <option value={key} key={PocTitles[key] + titleIndex}>
            {PocTitles[key]}
          </option>
        );
      }
      titleIndex = titleIndex + 1;
    }

    if (
      (poc["email"] == null || poc["email"] === "") &&
      (poc["phone"] == null || poc["phone"] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID",
      ];
    } else if (poc["phone"] != null && poc["phone"] !== "") {
      phoneValidators = ["isUSAPhoneNumMatch"];
      phoneValidatorMessage = ["Invalid phone number"];
    }
    let pocDiv = (
      <>
        <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
          {"Point Of Contact"}
        </div>
        <div className="poc-style">
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Name
            </Label>
            <Col sm={9}>
              <TextValidator
                type="text"
                name="name"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={poc.name}
              />
            </Col>
            <FaTrashAlt
              style={{ float: "right", cursor: "pointer" }}
              size="1.5em"
              onClick={(event) => handleDeleteEvent(event, poc)}
            />
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                id={poc.id}
                name="title"
                value={_.kebabCase(poc.title)}
                onChange={handlePocChange}
              >
                {pocTitleOptions}
              </Input>
              {/* <span
                className="fa fa-caret-down customDropdownIcon-lawFirmModel"
                style={{
                  float: "right",
                  marginTop: "-29px",
                  paddingRight: "15px",
                }}
              ></span> */}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="email"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={emailValidators}
                errorMessages={emailValidatorMessage}
                value={poc.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "0rem" }}>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Phone
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="phone"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={["required"]}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
                maxLength="14"
              />
            </Col>
          </FormGroup>
        </div>
      </>
    );
    return pocDiv;
  };

  const formElements = () => {
    return (
      <>
        <div className="add-law_firm">
          <div className="add-model-left-portion">
            <FormGroup row>
              <Label for="" sm={2}>
                Name *
              </Label>
              <Col sm={10}>
                <TextValidator
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Lawyer Name"
                  validators={["required"]}
                  errorMessages={["Name is required"]}
                  onChange={handleChange}
                  value={formdata.name}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}>
                Address
              </Label>
              <Col sm={10}>
                <TextValidator
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  validators={["required"]}
                  errorMessages={["Mailing street name is required"]}
                  onChange={handleChange}
                  value={formdata.address}
                />
              </Col>
            </FormGroup>
            <div>
              <Row form>
                <Col md={7}>
                  <FormGroup row>
                    <Label for="" sm={3} style={{ marginLeft: "12px" }}>
                      City
                    </Label>
                    <Col sm={8}>
                      <TextValidator
                        type="text"
                        name="city"
                        id="city"
                        placeholder="Mailing city"
                        validators={["required"]}
                        errorMessages={["Mailing city is required"]}
                        onChange={handleChange}
                        value={formdata.city}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup row>
                    <Label for="" sm={4}>
                      State
                    </Label>
                    <Col sm={8}>
                      <TextValidator
                        type="text"
                        name="state"
                        id="state"
                        maxLength="2"
                        placeholder="Mailing state"
                        validators={["required"]}
                        errorMessages={["Mailing state is required"]}
                        onChange={handleChange}
                        value={formdata.state}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup row>
                    <Label for="" sm={2}>
                      Zip
                    </Label>
                    <Col sm={10}>
                      <TextValidator
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="XXXXX-XXXX"
                        maxLength="10"
                        validators={["required", "isAddressZipcodeMatch"]}
                        errorMessages={["zip is required", "Invalid Zip Code"]}
                        onChange={(event) => handleChange(event, true)}
                        value={formdata.zip}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <FormGroup row>
              <Label for="" sm={2}>
                Website
              </Label>
              <Col sm={10}>
                <TextValidator
                  type="text"
                  name="website"
                  id="website"
                  placeholder="Website"
                  onChange={handleChange}
                  value={formdata.website}
                />
              </Col>
            </FormGroup>
          </div>
          <div className="add-model-right-portion">
            <div>
              {pocArray &&
                pocArray.map((poc, index) => {
                  return <div>{pocModel(poc, index)}</div>;
                })}
            </div>
            <div className="add-poc" onClick={handleAddEvent}>
              <span className="text">Add POC</span>
              <FaPlus size="1.5em" />
            </div>
            <Button onClick={() => handleSubmit()}>Submit</Button>
          </div>
        </div>
      </>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <Modal
          isOpen={props.open}
          toggle={props.toggle}
          className="law-firm-modal"
        >
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className="">
                {" "}
                {`${formdata.id ? "Edit " : "Add "}Lawyer `}
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              {formElements()}
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}
