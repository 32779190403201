import React, { Component, useState, useEffect } from "react";
import "../../styles/navbar.scss";
import resicap_logo from "../../images/RESIAIMS.png";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { toast } from "react-toastify";

export default function ThankYou(props) {
  const propertyID = props.match.params.propertyID;
  const codeViolationID = props.match.params.cv_id;
  const url_token = props.match.params.url_token;
  const notify = (errorMsg) => toast(errorMsg);

  useEffect(() => {
    emailClickValidation();
  }, []);

  const emailClickValidation = () => {
    apicall
      .emailClickValidation(propertyID, codeViolationID, url_token)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("error")) {
          // let errorMsg = helpers.getErrorMsg(response.error);
          // if (errorMsg) notify(errorMsg);
          notify(response.error);
        } else {
          helpers.notify("Thank You");
        }
      });
  };
  return (
    <React.Fragment>
      <header className="header">
        <div
          className="header__logo hidden-sm-down header-logo"
          id="headerleft"
        >
          <img src={resicap_logo} alt="Resicap Logo" />
        </div>
      </header>
      <section className="content" style={{ marginLeft: "0px" }}>
        <h1 style={{ textAlign: "center" }}>
          Thank you for your confirmation.
        </h1>
      </section>
    </React.Fragment>
  );
}
