import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import Select from "react-select";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import config from "../../../utils/config";
import { toast } from "react-toastify";

export default function MunicipalNewOrderModal(props) {
  const [vendor_names, set_vendor_names] = useState([]);

  const [form_inputs, set_form_inputs] = useState({
    vendor_code: "",
    order_type: "",
  });
  const order_type = Object.keys(config.municipal_order_type).reduce(
    (array, key) => {
      return [
        ...array,
        { label: key, value: config.municipal_order_type[key] },
      ];
    },
    []
  );

  useEffect(() => {
    listMunicipalVendorOrder();
  }, []);

  const listMunicipalVendorOrder = () => {
    apicall.listMunicipalVendorOrder().then((response) => {
      let vendorNameArray = [];
      vendorNameArray = response.map((x) => {
        return { value: x.short_code, label: x.name };
      });
      set_vendor_names(vendorNameArray);
    });
  };
  const handleSubmit = () => {
    var requestObject = {
      client_municipal_order: {
        clientid: props.clientId,
        vendor_code: form_inputs.vendor_code,
        municipal_orders_attributes: [{ [form_inputs.order_type]: "YES" }],
      },
    };
    apicall.createMunicipalOrder(requestObject).then((response) => {
      if (response) {
        helpers.notify("Order placed successfully");
        props.getMunicipalOrderList();
        props.toggle();
      }
    });
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Upload Municipal Order</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            <FormGroup row>
              <Label for="" sm={3}>
                Vendor
              </Label>
              <Col sm={8}>
                <Select
                  options={vendor_names ? vendor_names : []}
                  onChange={(value) => {
                    set_form_inputs({
                      ...form_inputs,
                      vendor_code: value.value,
                    });
                  }}
                  className="states-select"
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="" sm={3}>
                Order Type
              </Label>
              <Col sm={8}>
                <Select
                  options={order_type ? order_type : []}
                  onChange={(value) => {
                    set_form_inputs({
                      ...form_inputs,
                      order_type: value.value,
                    });
                  }}
                  className="states-select"
                />
              </Col>
            </FormGroup>
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="municipal-order-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
