import React from "react";
import { Button, FormGroup, Label, CustomInput } from "reactstrap";
import apicall from "../../../utils/apicall";
import accesscodesapi from "../../../utils/api/accesscodesapi";
import { FaEdit, FaLink, FaTrashAlt } from "react-icons/fa";
import "../../../styles/_access-codes.scss";
import AccessCodeModal from "./AccessCodeModal";
import helpers from "../../../utils/helpers";
import _ from "lodash";

export default class AccessCodeTab extends React.Component {
  constructor(props) {
    //const accessCodeField = `${props.accessFor}_access_code`;
    super(props);
    this.state = {
      rows: [],
      openModal: false,
      isUpdate: true,
      otherRows: [],
    };
  }

  componentWillMount() {
    const accessCodeField = `${this.props.accessFor}_access_code`;
    // apicall.getProperty(this.props.propertyid).then((response) => {
    //   this.setState({
    //     separateAccessCodes: response.separate_building_access_codes,
    //     isAccessControlled: response[accessCodeField],
    //   });
    // });

    apicall.getHoa(this.props.hoaId).then((response) => {
      if (accessCodeField === "building_access_code") {
        this.setState({
          isAccessControlled: response.community_building_access_controlled,
          separateAccessCodes: response.separate_owner_building_access_code,
          separateCommunity: response.separate_building_access_codes,
          building_access_uniq: response.building_access_uniq_association,
        });
      } else {
        this.setState({
          isAccessControlled: response[accessCodeField],
        });
      }
    });
  }

  render() {
    const props = this.props;
    let accessCodeField = `${props.accessFor}_access_code`;
    const isAccessControlled = this.state.isAccessControlled;
    const separateAccessCodes = this.state.separateAccessCodes;
    const separateCommunity = this.state.separateCommunity;
    const building_access_uniq = this.state.building_access_uniq;
    const openModal = this.state.openModal;
    const isUpdate = this.state.isUpdate;
    const rows = this.state.rows;
    let currentAccessCodes = _.sortBy(props.accessCodes, ["position"]).filter(
      (x) => x.access_for === props.accessFor
    );
    let type = "four";
    if (currentAccessCodes.length === 4) {
      type = "one";
    } else if (currentAccessCodes.length === 2) {
      if (currentAccessCodes[0].group_name === "otherthan_owner_vendor")
        type = "three";
      else type = "two";
    }
    const nameValueDiv = (name, value) => {
      if (value === null || typeof value === "undefined" || value === "Null")
        return null;

      return (
        <div className="accesss-codes">
          <div className="heading">{_.startCase(name)}</div>
          <div className="accesss-codes-values">{value}</div>
        </div>
      );
    };

    const createBtn = _.isEmpty(currentAccessCodes) &&
      (accessCodeField === "building_access_code"
        ? isAccessControlled &&
          !_.isNull(separateAccessCodes) &&
          !_.isNull(separateCommunity) &&
          !_.isNull(building_access_uniq)
        : isAccessControlled) && (
        <Button
          onClick={() => {
            this.setState({ isUpdate: false, openModal: true });
          }}
        >
          Create
        </Button>
      );

    const editBtn = !_.isEmpty(currentAccessCodes) && (
      <div className="edit-link">
        <FaTrashAlt
          style={{ float: "right", "margin-left": "10px" }}
          size="1.5em"
          onClick={() => {
            this.setState({
              rows: [],
            });
            props.accessCodes
              .filter((x) => x.access_for === props.accessFor)
              .forEach((x, ix) => {
                accesscodesapi.deleteAccessCodes(x.id, props.hoaId).then(() => {
                  helpers.notify("Access Code deleted.");

                  props.refreshList();
                });
              });
          }}
        />
        <FaEdit
          style={{ float: "right" }}
          size="1.5em"
          onClick={() => {
            this.setState({
              rows: currentAccessCodes,
              openModal: true,
              isUpdate: true,
            });
          }}
        />
      </div>
    );

    const viewIsAccessControlled = () => {
      if (isAccessControlled === null || _.isEmpty(currentAccessCodes)) {
        return (
          <div>
            <div className="addMarginBottom">
              Is {props.accessForTxt} controlled?
            </div>
            <div>
              <FormGroup style={{ display: "flex" }}>
                <CustomInput
                  id="leaseyes"
                  type="radio"
                  name="hideAdd"
                  value={true}
                  label="Yes"
                  onClick={() => {
                    this.setState({ isAccessControlled: true });
                    console.log(
                      "  >>{[accessCodeField]: true}: ",
                      { [accessCodeField]: true },
                      accessCodeField
                    );

                    // apicall
                    //   .updateProperty(
                    //     { [accessCodeField]: true },
                    //     props.propertyid
                    //   )
                    //   .then((resp) => {
                    //     if (resp) {
                    //       helpers.notify("Property updated successsully.");
                    //     }
                    //   });
                    // if (props.accessForTxt === "Community / Building access") {
                    //  if(accessCodeField ==="building")
                    if (accessCodeField === "building_access_code") {
                      accessCodeField = "community_building_access_controlled";
                    }

                    apicall
                      .updateAccessCodeHoa(props.hoaId, {
                        [accessCodeField]: true,
                      })
                      .then((resp) => {
                        if (resp) {
                          helpers.notify("Updated successsully.");
                        }
                      });
                  }}
                  //}
                  checked={isAccessControlled === true ? "checked" : ""}
                />
                <CustomInput
                  id="leaseno"
                  type="radio"
                  name="hideAdd"
                  value={false}
                  label="No"
                  onClick={() => {
                    this.setState({ isAccessControlled: false });
                    console.log(
                      "  >>{[accessCodeField]: false}: ",
                      { [accessCodeField]: false },
                      accessCodeField
                    );
                    // apicall
                    //   .updateProperty(
                    //     { [accessCodeField]: false },
                    //     props.propertyid
                    //   )
                    //   .then((resp) => {
                    //     if (resp) {
                    //       helpers.notify("Property updated successsully.");
                    //     }
                    //   });

                    // if (props.accessForTxt === "Community / Building access") {
                    if (accessCodeField === "building_access_code") {
                      accessCodeField = "community_building_access_controlled";
                    }
                    apicall
                      .updateAccessCodeHoa(props.hoaId, {
                        [accessCodeField]: false,
                      })
                      .then((resp) => {
                        if (resp) {
                          helpers.notify("Updated successsully.");
                        }
                      });
                  }}
                  // }
                  checked={isAccessControlled === false ? "checked" : ""}
                />
              </FormGroup>
              {viewSeparateAccessCodes()}
            </div>
          </div>
        );
      }
    };

    const viewSeparateAccessCodes = () => {
      if (props.accessFor !== "building" || !isAccessControlled) return null;

      return (
        <div className="questions">
          <FormGroup>
            <Label>Do Owner and Vendor have separate Access Codes?</Label>
            <div className="radio-buttons">
              <CustomInput
                type="radio"
                name={`separate_access_code`}
                id={`separate_access_code_yes`}
                placeholder=""
                onChange={(e) => {
                  this.setState({ separateAccessCodes: true });
                  apicall
                    .updateAccessCodeHoa(props.hoaId, {
                      separate_owner_building_access_code: true,
                    })
                    .then((resp) => {
                      if (resp) {
                        helpers.notify("Updated successsully.");
                      }
                    });
                }}
                label="Yes"
                checked={separateAccessCodes === true ? "checked" : ""}
              />
              <CustomInput
                type="radio"
                name={`separate_access_code`}
                id={`separate_access_code_no`}
                placeholder=""
                onChange={(e) => {
                  this.setState({ separateAccessCodes: false });
                  apicall
                    .updateAccessCodeHoa(props.hoaId, {
                      separate_owner_building_access_code: false,
                    })
                    .then((resp) => {
                      if (resp) {
                        helpers.notify("Updated successsully.");
                      }
                    });
                }}
                label="No"
                checked={separateAccessCodes === false ? "checked" : ""}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              Is the building access-controlled separate than the
              community/property?
            </Label>
            <div className="radio-buttons">
              <CustomInput
                type="radio"
                name={`separate_community`}
                id={`separate_community_yes`}
                placeholder=""
                onChange={(e) => {
                  this.setState({ separateCommunity: true });
                  apicall
                    .updateAccessCodeHoa(props.hoaId, {
                      separate_building_access_codes: true,
                    })
                    .then((resp) => {
                      if (resp) {
                        helpers.notify("Updated successsully.");
                      }
                    });
                }}
                label="Yes"
                checked={separateCommunity === true ? "checked" : ""}
              />
              <CustomInput
                type="radio"
                name={`separate_community`}
                id={`separate_community_no`}
                placeholder=""
                onChange={(e) => {
                  this.setState({ separateCommunity: false });
                  apicall
                    .updateAccessCodeHoa(props.hoaId, {
                      separate_building_access_codes: false,
                    })
                    .then((resp) => {
                      if (resp) {
                        helpers.notify("Updated successsully.");
                      }
                    });
                }}
                label="No"
                checked={separateCommunity === false ? "checked" : ""}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              Is access unique to the property or generic to the association?
            </Label>
            <div className="radio-buttons">
              <CustomInput
                type="radio"
                name={`association_access_code`}
                id={`association_access_code_yes`}
                placeholder=""
                onChange={(e) => {
                  this.setState({ building_access_uniq: true });
                  apicall
                    .updateAccessCodeHoa(props.hoaId, {
                      building_access_uniq_association: "Property",
                    })
                    .then((resp) => {
                      if (resp) {
                        helpers.notify("Updated successsully.");
                      }
                    });
                }}
                label="Property"
                checked={
                  building_access_uniq === true ||
                  building_access_uniq === "Property"
                    ? "checked"
                    : ""
                }
              />
              <CustomInput
                type="radio"
                name={`association_access_code`}
                id={`association_access_code_no`}
                placeholder=""
                onChange={(e) => {
                  this.setState({ building_access_uniq: false });
                  apicall
                    .updateAccessCodeHoa(props.hoaId, {
                      building_access_uniq_association: "Association",
                    })
                    .then((resp) => {
                      if (resp) {
                        helpers.notify("Updated successsully.");
                      }
                    });
                }}
                label="Association"
                checked={
                  building_access_uniq === false ||
                  building_access_uniq === "Association"
                    ? "checked"
                    : ""
                }
              />
            </div>
          </FormGroup>
        </div>
      );
    };

    const codes = props.accessCodes
      .filter((x) => x.access_for === props.accessFor)
      .map((x, ix) => {
        let documentsLength = x.documents.length - 1;
        return (
          <div className="code" key={ix}>
            {props.accessFor === "building" &&
              nameValueDiv("access_to", _.startCase(x.access_to))}
            {nameValueDiv("access_control", _.startCase(x.access_control))}
            {nameValueDiv("access_contact_email", x.access_contact_email)}
            {nameValueDiv("access_contact_name", x.access_contact_name)}
            {nameValueDiv("access_contact_phone", x.access_contact_phone)}
            {nameValueDiv("access_control_cost", x.access_control_cost)}
            {nameValueDiv("access_description", x.access_description)}
            {nameValueDiv("notes", x.notes)}
            {nameValueDiv("code", x.code)}
            {x.documents[0] &&
              nameValueDiv(
                "access_form",
                <a
                  href={x.documents[documentsLength].file_url}
                  target="_blank"
                  style={{ float: "right", color: "black" }}
                >
                  <FaLink size="1em" />
                </a>
              )}
          </div>
        );
      });
    return (
      <div className="access-codes-container">
        {viewIsAccessControlled()}
        {createBtn}
        {editBtn}
        {codes}
        {openModal && (
          <AccessCodeModal
            toggle={() => this.setState({ openModal: false })}
            modal={true}
            isUpdate={isUpdate}
            accessFor={props.accessFor}
            propertyid={props.propertyid}
            refreshList={props.refreshList}
            separateAccessCodes={this.state.separateAccessCodes}
            separateCommunity={this.state.separateCommunity}
            accessCodesObject={rows}
            type={type}
            hoaId={this.props.hoaId}
          />
        )}
      </div>
    );
  }
}
