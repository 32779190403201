import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
//import BootstrapTable from 'react-bootstrap-table-next';
import apicall from "../../../utils/apicall";
import ListComp from "../../crud/ListComp";
//import Clipboard from 'react-clipboard.js';
import helpers from "../../../utils/helpers";
import { FaCheckSquare } from "react-icons/fa";
import TenantMoveInModal from "./TenantMoveInModal";
export default class TenantMoveInListTable extends ListComp {
  onSuccess = () => {
    helpers.notify("File URL copied to ClipBoard");
  };

  applicableFormatter = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <FaCheckSquare size="2em" />
        {/* <FaTimesCircle size="2em" /> */}
      </div>
    );
  };

  deleteRecord = (row) => {
    apicall
      .deleteTenantMoveIn(row.id, this.props.propertyid)
      .then((response) => {
        if (response) {
          helpers.notify("Deleted successfully");
          this.state.refreshList();
        }
      });
  };

  columns = () => {
    return [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "lease_license_required",
        text: "Lease License Required",
        formatter: this.cellFormatter,
      },
      {
        dataField: "township_name",
        text: "Township Name",
        formatter: this.cellFormatter,
      },
      {
        dataField: "lease_approval_required",
        text: "Lease approval Required",
        formatter: this.cellFormatter,
      },
      {
        dataField: "tenant_days_before_move_in",
        text: "tenant days before mov in",
      },
      {
        dataField: "lease_days_before_move_in",
        text: "lease days before move in",
      },
      {
        dataField: "tenant_approval_required",
        text: "tenant approval required",
        formatter: this.cellFormatter,
      },
      {
        dataField: "tenant_info_required_on_leasing",
        text: "tenant info required on leasing",
        formatter: this.cellFormatter,
      },
      {
        dataField: "lease_addendums_required",
        text: "lease addendums required",
        formatter: this.cellFormatter,
      },
      {
        dataField: "lease_copy_required",
        text: "lease copy required",
        formatter: this.cellFormatter,
      },
      {
        dataField: "rental_fee",
        text: "rental fee",
        formatter: this.cellFormatter,
      },
      {
        dataField: "move_in_deposit",
        text: "move in deposit",
        formatter: this.cellFormatter,
      },
      {
        dataField: "other_fee",
        text: "other fee",
        formatter: this.cellFormatter,
      },
      {
        dataField: "payment_method",
        text: "payment method",
        formatter: this.cellFormatter,
      },
      {
        dataField: "payee",
        text: "payee",
        formatter: this.cellFormatter,
      },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.buttonFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 200, class: "table-actions" },
      },
    ];
  };

  formComp = () => {
    return (
      <TenantMoveInModal
        toggle={this.toggleEditFormModal}
        title="Tenant Move In"
        open={this.state.openFormModal}
        row={this.state.row}
        propertyid={this.props.propertyid}
      />
    );
  };
}
