import React, { Component } from "react";
import AsyncSelect from "react-select/async";
//import { mcall } from "q";
import helpers from "../../utils/helpers";
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
};

const associationCustomStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "350px",
    marginBottom: "5px",
  }),
};
export default class HoaAsyncSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selectedOption: "",
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  filterResults = (inputValue, callback) => {
    if (helpers.isValidString(inputValue)) {
      if (this.props.isassociationVerification) {
        this.props.callapi(inputValue, this.props.propertyID).then((resp) => {
          let options = [];
          options = resp.results.map((x) => {
            return {
              value: x.value,
              label: x.label,
              data: x,
            };
          });
          callback(options);
          return options;
        });
      } else {
        this.props.callapi(inputValue, this.props.role, 1, 100).then((resp) => {
          let options = [];
          options = resp.results.map((mc) => {
            if (this.props.isManagementCompany) {
              return {
                value: mc.id,
                label: mc.name || "",
                point_of_contacts: mc.point_of_contacts,
              };
            } else if (this.props.getEmail) {
              return { value: mc.id, label: mc.email || "" };
            } else {
              return { value: mc.id, label: mc.name || "" };
            }
          });
          callback(options);
          return options;
        });
      }
    }
  };

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      this.filterResults(inputValue, callback);
    }, 1000);
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({
      inputValue: inputValue,
    });
  };

  handleChange = (value) => {
    this.setState({
      selectedOption: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="">
          <div className="select-box">
            <AsyncSelect
              name=""
              id=""
              loadOptions={this.loadOptions}
              onInputChange={this.handleInputChange}
              onChange={(value) => {
                this.props.handleOnChange(this.props.id, value);
              }}
              styles={
                this.props.isassociationVerification
                  ? associationCustomStyles
                  : customStyles
              }
              className="react-select-container"
              value={this.props.valueShown}
              placeholder={this.props.placeholder}
              isDisabled={this.props.isDisabled}
              isRequired={this.props.isRequired}
              isClearable={this.props.isClearable}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
