import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import Axios from "axios";
var underwritingActionsapi = {
    listUnderUtilities: async function (propertyID) {
      
    let url =
      config.baseUrl + "/api/v1/properties/" + propertyID+"/under_utilities_tab";
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  listUnderUtilitiesOnetime_fees: async function (propertyID) {
      
    let url =
      config.baseUrl + "/api/v1/properties/" + propertyID+"/under_utilities_tab_onetime_fees";
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};
export default withRouter(underwritingActionsapi);