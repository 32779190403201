import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var businessunitapi = {
  listOfBusinessUnit: async (data, pageNo, perPage) => {
    let url = `${config.baseUrl}/api/v1/business_units?`;
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },

  listOfBusinessUnitForCodeViolation: async function (id) {
    let url = `${config.baseUrl}/api/v1/business_units?all_bu=true`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  readBusinessUnit: async function (id) {
    let url = `${config.baseUrl}/api/v1/business_units/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  addBusinessUnit: async (businessUnitObj) => {
    let url = `${config.baseUrl}/api/v1/business_units`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.post(url, businessUnitObj, { headers: headers }).then(
      (resp) => {
        response = resp;
      }
    );
    return response.data;
  },

  updateBusinessUnit: async (data, businessUnitId) => {
    let url = `${config.baseUrl}/api/v1/business_units/${businessUnitId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  deleteBusinessUnitPOC: async function (id, poc_id) {
    let url = `${config.baseUrl}/api/v1/business_units/${id}/destroy_title_poc?title_point_of_contact_id=${poc_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  deleteBusinessUnit: async function (id) {
    let url = `${config.baseUrl}/api/v1/business_units/${id}/`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  filterStates: async function (data) {
    let url = `${config.baseUrl}/api/v1/business_units/state_counties?`;
    let params = {};

    if (data !== undefined && !_.isEmpty(data.state))
      params["state"] = data.state;

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => k + "=" + encodeURIComponent(params[k]))
      .join("&");

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  filterZipCode: async function (data) {
    let url = `${config.baseUrl}/api/v1/business_units/state_zips?`;
    let params = {};

    if (data !== undefined && !_.isEmpty(data.state))
      params["state"] = data.state;

    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => k + "=" + encodeURIComponent(params[k]))
      .join("&");

    return await fetch(url + query, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};
export default withRouter(businessunitapi);
