import React, { useState, useEffect } from "react";
import "../../styles/_newDashboard.scss";
import Loader from "../helper_components/LoaderComponent";
import _ from "lodash";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import PaginationComponent from "../helper_components/PaginationComponent";
import PaymentModal from "../payments/paymentModal";
import config from "../../utils/config";
import TitleReviewAction from "../orders/title_order/TitleReviewAction";
import { FaTrashAlt } from "react-icons/fa";
import ConfirmBox from "../helper_components/ConfirmBox";
import TitleClearanceAction from "../orders/title_order/TitleClearance";
import DeedVerification from "../deed_verification/NewDeedFile";
import MunicipalVerification from "../MunicipalVerification/MunicipalVerification";
import TitlePendingStatsDashboard from "./TitlePendingStatsDashboard";
import CodeViolationModal from "../CodeViolation/CodeViolationModal";
import LienActionModal from "../LienActions/LienActionModal";
import MunicipalReviewAction from "../orders/municipality_order/MunicipalityReviewAction";

export default function NewTitleDashboardComponent(props) {
  const [loading, set_loading] = useState(false);
  const [toggleModal, settoggleModal] = useState(false);
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  const [pendingDashboardFilter, setpendingDashboardFilter] = useState();
  const [generalPaymentFields, setgeneralPaymentFields] = useState();
  const [generalPaymentRightFields, setgeneralPaymentRightFields] = useState();
  const [table_data, set_table_data] = useState();
  const [total, set_total] = useState();
  const [tierOnefilter, settierOnefilter] = useState();
  const [tierTwofilter, settierTwofilter] = useState();
  const [tierThreefilter, settierThreefilter] = useState();
  const [modal_inputs, set_modal_inputs] = useState({
    paymentModal: false,
  });
  const [row, setrow] = useState({});
  const [pageNumber, setpageNumber] = useState();
  const [perPage, setperPage] = useState();
  const [dashboardValue, setdashboardValue] = useState();
  const [actionID, setactionID] = useState();
  const [propertyID, setpropertyID] = useState();
  const [deleteModal, setdeleteModal] = useState(false);
  const [title_order_id, settitle_order_id] = useState();
  const [order_id, setorder_id] = useState();
  const [client_vendor_order_id, setclient_vendor_order_id] = useState();
  const [propertyInfo, setpropertyInfo] = useState({});
  const [deleteOrderType, setdeleteOrderType] = useState(false);
  const [client_municipal_order_id, setclient_municipal_order_id] = useState();
  const [dashboardModal, setdashboardModal] = useState(false);
  const orderHistoryKeys = config.title_order_type;
  const municipalorderHistoryKeys = config.municipal_order_type;
  const [openCodeViolationModal, setopenCodeViolationModal] = useState(false);
  const [actionable_id, setactionable_id] = useState();
  const [openLienModal, setopenLienModal] = useState(false);
  const manager_user = props.manager_user;

  useEffect(() => {
    if (tierOnefilter !== "general_payment") getTitleDashboardStats();
    if (
      tierOnefilter !== "deed" &&
      tierOnefilter !== "title" &&
      tierOnefilter !== "municipal" &&
      tierOnefilter !== "Violation" &&
      tierOnefilter !== "lien"
    )
      getDashboardPendingStats();
  }, [
    tierOnefilter,
    tierTwofilter,
    tierThreefilter,
    form_inputs.sort,
    manager_user,
  ]);

  useEffect(() => {
    if (tierOnefilter === "general_payment") getDashboardPendingStats();
  }, [tierOnefilter, tierTwofilter, tierThreefilter]);

  const getTitleDashboardStats = (event, page, perPage) => {
    set_loading(true);
    apicall
      .getTitleDashboardStats(
        form_inputs,
        tierOnefilter,
        tierTwofilter,
        tierThreefilter,
        manager_user,
        page,
        perPage
      )
      .then((response) => {
        setdashboardValue(response.results.data);
        if (!_.isEmpty(response.results.records)) {
          if (!_.isEmpty(response.results.records.results)) {
            set_table_data(response.results.records.results);
            set_total(response.results.records.total);
          } else set_table_data("");
        } else {
          set_table_data("");
          set_total();
        }
        setpageNumber(page);
        setperPage(perPage);
        set_loading(false);
        settoggleModal(true);
        setdashboardModal(true);
      })
      .catch((e) => set_loading(false));
  };

  const getDashboardPendingStats = (event, page, perPage) => {
    set_loading(true);
    apicall
      .getDashboardPendingStats(
        form_inputs,
        tierOnefilter,
        tierTwofilter,
        tierThreefilter,
        "",
        "",
        page,
        perPage
      )
      .then((response) => {
        setpendingDashboardFilter(response.results.data);

        settoggleModal(true);
        set_loading(false);
        if (!_.isEmpty(response.results.data.general_payment.left_fields)) {
          setgeneralPaymentFields(
            response.results.data.general_payment.left_fields
          );
        }
        if (!_.isEmpty(response.results.data.general_payment.right_fields)) {
          setgeneralPaymentRightFields(
            response.results.data.general_payment.right_fields
          );
        }
        setpageNumber(page);
        setperPage(perPage);
        settoggleModal(true);
        set_loading(false);
        if (tierOnefilter === "general_payment") {
          if (!_.isEmpty(response.results.records.results)) {
            let paymentOriginalData = response.results.records.results
            let modifiedPaymentData = paymentOriginalData.map((value) => {
              if (value.payment_charge_type !== null) {
                value.description = value.payment_charge_type;
              }
              return value;
            })
            set_table_data(modifiedPaymentData);
            set_total(response.results.records.total);
          } else {
            set_table_data("");
            set_total();
          }
        }
      })
      .catch((e) => set_loading(false));
  };

  const propertyLinkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/2/" + row.property_id + "/" + null}
          target="_blank"
          key={cell + row.property_id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const lienPropertyLinkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/3/" + row.property_id + "/" + null}
          target="_blank"
          key={cell + row.property_id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  const notesFormatter = (cell, row) => {
    let notesArr = !_.isEmpty(cell) && cell.split(/\n/g);
    let notes =
      _.isArray(notesArr) &&
      notesArr
        .filter((x) => x !== "")
        .map((x, ind) => {
          return <p key={x}>{x}</p>;
        });
    return (
      <>
        <p>{notes}</p>
      </>
    );
  };
  const linkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/3/" + row.property_id + "/" + null}
          target="_blank"
          key={cell + row.id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const propertylinkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/3/" + row.id + "/" + null}
          target="_blank"
          key={cell + row.id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const paymentActionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      !_.isEmpty(cell) &&
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              let modalName = "paymentModal";
              set_modal_inputs({
                ...modal_inputs,
                [modalName]: !modal_inputs[modalName],
              });
              setrow(row);
            }}
          >
            {cell}
          </div>
        </React.Fragment>
      );
    }
    let tabNo =
      !_.isEmpty(cell) && cell.trim().toLowerCase() === "documentinforeport"
        ? 5
        : null;
    tabNo = !_.isEmpty(cell) && cell.trim().toLowerCase() === "pir" ? 1 : tabNo;
    if (tabNo !== null) {
      return (
        <Link
          className="link"
          to={"/property-manage/" + tabNo + "/" + row.property_id}
          target="_blank"
        >
          {cell}
        </Link>
      );
    }
    return cell;
  };

  const getActionType = (cell) => {
    if (!_.isEmpty(cell)) {
      let lowercase = cell.trim();
      let regEx = /\W+|(?=[A-Z])|_/g;
      let result = lowercase.split(regEx).join(" ");
      return result;
    }
  };

  const titleReviewActionTypeFormatter = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    return (
      <React.Fragment>
        <div
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            let modalName = cell.trim().toLowerCase() + "Modal";
            set_modal_inputs({
              ...modal_inputs,
              [modalName]: !modal_inputs[modalName],
            });
            if (cell === "DeedVerification") {
              setpropertyInfo({
                acquisition_type: row.acquisition_type,
                property_group: row.property_group,
              });
            }
            setactionID(row.actionable_id);
            setpropertyID(row.property_id);
          }}
        >
          {getActionType(cell)}
        </div>
      </React.Fragment>
    );
  };

  const paymentColumns = [
    {
      dataField: "clientid",
      text: "Client ID",
      formatter: linkFormatter,
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: paymentActionTypeFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "full_address",
      text: "Address",
      classes: "address-width",
      headerClasses: "table-header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "zip",
      text: "Zip",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "age",
      text: "Age in days",
      headerClasses: "table-header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { age_in_days: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "due_date",
      text: "Due Date",
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "current_status",
      text: "Status",
      //classes: "reduce-width",
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      classes: "notes-width",
      formatter: notesFormatter,
      headerClasses: "table-header-cell-alignment",
    },
  ];

  const orderStatus = (cell, row, rowIndex, formatExtraData) => {
    let statusKey = orderHistoryKeys[row.order_type];
    return `${row[statusKey]} (${helpers.formatDate(row.created_at)})`;
  };

  const cancelDateFormatter = (cell, row, rowIndex) => {
    let statusKey = orderHistoryKeys[row.order_type];
    if (cell != null) return helpers.formatDate(cell);
    else if (row[statusKey] === "YES" && cell == null) {
      return (
        <div style={{ textAlign: "center", cursor: "pointer" }}>
          <FaTrashAlt
            size="1.4em"
            onClick={() => {
              setdeleteModal(true);
              setorder_id(row.orderid);
              setclient_vendor_order_id(row.client_vendor_order_id);
              settitle_order_id(row.id);
              setdeleteOrderType("Title");
            }}
          />
        </div>
      );
    }
  };

  const cancelClientVendorOrder = (title_order_id, order_id) => {
    apicall
      .cancelClientVendorOrder(client_vendor_order_id, title_order_id, order_id)
      .then((resp) => {
        helpers.notify("Order Cancelled Successfully");
        getTitleDashboardStats();
      });
  };

  const cancelClientMunicipalOrder = (municipality_order_id, order_id) => {
    apicall
      .cancelClientMunicipalOrder(
        client_municipal_order_id,
        municipality_order_id,
        order_id
      )
      .then((resp) => {
        helpers.notify("Order Cancelled Successfully");
        getTitleDashboardStats();
      });
  };

  const cancelClientMunicipalDateFormatter = (cell, row, rowIndex) => {
    let statusKey = municipalorderHistoryKeys[row.order_type];
    if (row[statusKey] === "YES" && cell != null)
      return helpers.formatDate(cell);
    else if (row[statusKey] === "YES" && cell == null) {
      return (
        <div style={{ textAlign: "center", cursor: "pointer" }}>
          <FaTrashAlt
            size="1.4em"
            onClick={() => {
              setdeleteModal(true);
              setorder_id(row.orderid);
              settitle_order_id(row.id);
              setclient_municipal_order_id(row.client_municipal_order_id);
              setdeleteOrderType("Municipal");
            }}
          />
        </div>
      );
    }
  };

  const municipalOrderStatus = (cell, row, rowIndex, formatExtraData) => {
    let statusKey = municipalorderHistoryKeys[row.order_type];
    return `${row[statusKey]} (${helpers.formatDate(row.created_at)})`;
  };

  const descriptionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(row.title_documents)) {
      let category = !_.isEmpty(row.title_documents[0].category)
        ? row.title_documents[0].category
        : "";
      let type = !_.isEmpty(row.title_documents[0].doc_type)
        ? row.title_documents[0].doc_type
        : "";
      let case_number = !_.isEmpty(row.title_documents[0].case_number)
        ? row.title_documents[0].case_number
        : "";
      return `${category} - ${type} - ${case_number} `;
    } else return "";
  };
  const associationMuni = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(row.associable)) return row.associable.name;
  };

  const codeViolationHistoryLink = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setopenCodeViolationModal(true);
            setactionable_id(cell);
            setrow(row);
            setpropertyID(row.property_id);
          }}
        >
          {"CodeViolation"}
        </span>
      </div>
    );
  };

  const constructTitleTableWithData = [
    {
      dataField: "clientid",
      text: "Client ID",
      formatter: propertyLinkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "orderid",
      text: "Order ID",
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "vendor_name",
      text: "Vendor Name",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "order_type",
      text: "Order Type",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "client_vendor_order_id",
      text: "Order Status",
      formatter: orderStatus,
      //sort: true,
      // onSort: (a, b) => {
      //   let obj = { [a]: b };
      //   set_form_inputs({ ...form_inputs, sort: obj });
      // },
    },
    {
      dataField: "ordered_at",
      text: "Ordered Date",
      formatter: dateFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "cancelled_at",
      text: "Cancelled Date",
      formatter: cancelDateFormatter,
    },
  ];

  const title_reviews_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      headerClasses: "header-cell-alignment",
      formatter: linkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "actionable_type",
      text: "Action Type",
      formatter: titleReviewActionTypeFormatter,
      headerClasses: "header-cell-alignment",
      // sort: true,
      // onSort: (a, b) => {
      //   let obj = { [a]: b };
      //   set_form_inputs({ ...form_inputs, sort: obj });
      // },
    },

    {
      dataField: "description",
      text: "Description",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "creation_date",
      text: "Creation Date/Age",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "current_status",
      text: "Current Status",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
  ];

  const deed_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      headerClasses: "header-cell-alignment",
      formatter: linkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "actionable_type",
      text: "Action Type",
      formatter: titleReviewActionTypeFormatter,
      headerClasses: "header-cell-alignment",
      // sort: true,
      // onSort: (a, b) => {
      //   let obj = { [a]: b };
      //   set_form_inputs({ ...form_inputs, sort: obj });
      // },
    },
    {
      dataField: "combined_address",
      text: "Address",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "description",
      text: "Description",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "creation_date",
      text: "Creation Date/Age",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "current_status",
      text: "Current Status",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
  ];

  const municipal_order_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      headerClasses: "header-cell-alignment",
      formatter: propertyLinkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "orderid",
      text: "Order ID",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "vendor_name",
      text: "Vendor Name",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "order_type",
      text: "Order Type",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "client_municipal_order_id",
      text: "Order Status",
      headerClasses: "header-cell-alignment",
      formatter: municipalOrderStatus,
      // sort: true,
      // onSort: (a, b) => {
      //   let obj = { [a]: b };
      //   set_form_inputs({ ...form_inputs, sort: obj });
      // },
    },
    {
      dataField: "ordered_at",
      text: "Ordered Date",
      headerClasses: "header-cell-alignment",
      formatter: dateFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "cancelled_at",
      text: "Cancelled Date",
      headerClasses: "header-cell-alignment",
      formatter: cancelClientMunicipalDateFormatter,
    },
  ];

  const violation_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      headerClasses: "header-cell-alignment",
      formatter: linkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "id",
      text: "Action Type",
      formatter: codeViolationHistoryLink,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      headerClasses: "header-cell-alignment",
      // sort: true,
      // onSort: (a, b) => {
      //   let obj = { [a]: b };
      //   set_form_inputs({ ...form_inputs, sort: obj });
      // },
    },
    {
      dataField: "description",
      text: "Description",
      headerClasses: "header-cell-alignment",
      formatter: descriptionTypeFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "associable",
      text: "Association/Muni",
      headerClasses: "header-cell-alignment",
      formatter: associationMuni,
    },
    {
      dataField: "combined_address",
      text: "Address",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "created_at",
      text: "Creation Date/Age",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "due_date",
      text: "Due Date",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "status",
      text: "Current Status",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "total_payment_amount",
      text: "Amount Payable",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      style: { textAlign: "right" },
    },
    {
      dataField: "notes",
      text: "Notes",
      headerClasses: "header-cell-alignment",
      // formatter: buttonFormatter,
      classes: "notes-width",
    },
  ];

  const lienHolderFormatter = (cell, row) => {
    if (
      !_.isEmpty(row) &&
      !_.isEmpty(row.title_review_order_and_lien_documents)
    )
      return row.title_review_order_and_lien_documents[0].lien_holder;
  };

  const addHistoryLink = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setopenLienModal(true);
            setactionable_id(cell);
            setrow(row);
            setpropertyID(row.property_id);
          }}
        >
          {"Lien"}
        </span>
      </div>
    );
  };

  const lienReleaseLink = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {!_.isEmpty(cell) ? (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              // setopenLienReleaseModal(true);
              // setrow(row);
              // if (!_.isEmpty(row.lien_release)) {
              //   setlienReleaseId(row.lien_release.id);
              // }
            }}
          >
            {"Yes"}
          </span>
        ) : (
          <span>{"No"}</span>
        )}
      </div>
    );
  };

  const lien_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      headerClasses: "header-cell-alignment",
      formatter: lienPropertyLinkFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    // {
    //   dataField: "id",
    //   text: "Action Type",
    //   formatter: addHistoryLink,
    //   headerClasses: "header-cell-alignment",
    //   sort: true,
    //   onSort: (a, b) => {
    //     let obj = { [a]: b };
    //     set_form_inputs({ ...form_inputs, sort: obj });
    //   },
    // },
    {
      dataField: "lien_action_type",
      text: "Lien Type",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "description",
      text: "Description",
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: lienHolderFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },

    {
      dataField: "debtor",
      text: "Debtor",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "created_at",
      text: "Creation Date",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "status",
      text: "Current Status",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "payment_status",
      text: "Payment Status",
      headerClasses: "header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "lien_release",
      text: "Lien Release Action",
      headerClasses: "header-cell-alignment",
      formatter: lienReleaseLink,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      headerClasses: "header-cell-alignment",
      classes: "notes-width",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
  ];
  const addressFormatter = (cell, row, rowIndex, formatExtraData) => {
    let full_address = [row.street_address, row.city, row.state].join(", ");
    return <div>{full_address}</div>;
  };

  const title_order_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      headerClasses: "header-cell-alignment",
      formatter: propertylinkFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "street_address",
      text: "Address",
      classes: "address-width",
      headerClasses: "table-header-cell-alignment",
      formatter: addressFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
  ];

  let properties =
    table_data &&
    table_data.map((x) => {
      const full_address = x.property
        ? [
            x.property && x.property.street_address,
            x.property.city,
            x.property.state,
          ].join(", ")
        : [
            x.physical_street_no_and_name,
            x.physical_city,
            x.physical_state,
          ].join(", ");
      let diff;
      if (x.created_at) diff = dayjs().diff(dayjs(x.created_at), "day");
      const age = diff && diff < 0 ? 0 : diff;
      let clientid = x.property ? x.property.clientid : "";
      let zip = x.property ? x.property.zip : x.physical_zip;
      return { ...x, full_address, age, clientid, zip };
    });
  let tableColumns;
  if (tierOnefilter === "general_payment") {
    tableColumns = paymentColumns;
  } else if (
    tierOnefilter === "title" &&
    tierTwofilter === "title_orders" &&
    tierThreefilter !== "required"
  ) {
    tableColumns = constructTitleTableWithData;
  } else if (
    tierOnefilter === "municipal" &&
    tierTwofilter === "municipal_order" &&
    tierThreefilter !== "required"
  ) {
    tableColumns = municipal_order_columns;
  } else if (tierOnefilter === "violation") {
    tableColumns = violation_columns;
  } else if (tierOnefilter === "lien") {
    tableColumns = lien_columns;
  } else if (tierThreefilter === "required") {
    tableColumns = title_order_columns;
  } else if (tierOnefilter === "deed") {
    tableColumns = deed_columns;
  } else {
    tableColumns = title_reviews_columns;
  }

  let rowProperties;
  if (tierOnefilter === "general_payment") rowProperties = properties;
  else {
    rowProperties = table_data;
  }

  const filterTierOne = (response) => {
    settierOnefilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierTwo = (response) => {
    settierTwofilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierThree = (response) => {
    settierThreefilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      {toggleModal && (
        <>
          {dashboardModal && (
            <TitlePendingStatsDashboard
              filterTierOne={filterTierOne}
              filterTierTwo={filterTierTwo}
              filterTierThree={filterTierThree}
              perPage={perPage}
              pageNo={pageNumber}
              dashboardValue={dashboardValue}
              pendingDashboardFilter={pendingDashboardFilter}
              generalPaymentFields={generalPaymentFields}
              generalPaymentRightFields={generalPaymentRightFields}
              dashboard_scope={props.dashboard_scope}
              manager_user= {props.manager_user}
            />
          )}
          {!_.isEmpty(tableColumns) && (
            <PaginationComponent
              columns={tableColumns}
              rows={rowProperties}
              handleSubmit={
                tierOnefilter === "general_payment"
                  ? getDashboardPendingStats
                  : getTitleDashboardStats
              }
              pageNo={pageNumber}
              perPage={perPage}
              totalCount={table_data && total}
            />
          )}
          {modal_inputs.paymentModal && (
            <PaymentModal
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  paymentModal: !modal_inputs.paymentModal,
                });
                getDashboardPendingStats();
              }}
              title="Payment"
              open={true}
              row={row}
              propertyID={row.property_id}
              actionID={row.actionable_id}
              dashboardProperty={true}
              userRole={props.userRole}
            />
          )}
          {modal_inputs.titlereviewModal && (
            <TitleReviewAction
              actionID={actionID}
              propertyID={propertyID}
              open={modal_inputs.titlereviewModal}
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  titlereviewModal: !modal_inputs.titlereviewModal,
                });

                getTitleDashboardStats();
              }}
              formdata={row}
              title="Title Review Action"
            />
          )}
          {modal_inputs.titleclearanceModal && (
            <TitleClearanceAction
              titleActionID={actionID}
              propertyID={propertyID}
              open={modal_inputs.titleclearanceModal}
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  titleclearanceModal: !modal_inputs.titleclearanceModal,
                });

                getTitleDashboardStats();
              }}
              title="Title Clearance Action"
              refershActions={getTitleDashboardStats}
            />
          )}

          {modal_inputs.deedverificationModal && (
            <DeedVerification
              actionID={actionID}
              propertyID={propertyID}
              open={modal_inputs.deedverificationModal}
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  deedverificationModal: !modal_inputs.deedverificationModal,
                });
                getTitleDashboardStats();
              }}
              propertyInfo={propertyInfo}
              refershActions={getTitleDashboardStats}
            />
          )}
          {modal_inputs.municipalityverificationModal && (
            <MunicipalVerification
              actionID={actionID}
              propertyID={propertyID}
              open={modal_inputs.municipalityverificationModal}
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  municipalityverificationModal: !modal_inputs.municipalityverificationModal,
                });
                getTitleDashboardStats();
              }}
              title="Municipality Verification Action"
              refershActions={getTitleDashboardStats}
            />
          )}
          {openCodeViolationModal && (
            <CodeViolationModal
              toggle={() => setopenCodeViolationModal(!openCodeViolationModal)}
              className=""
              title="CodeViolation"
              open={openCodeViolationModal}
              propertyID={propertyID}
              actionID={actionable_id}
              row={row}
            />
          )}
          {openLienModal && (
            <LienActionModal
              toggle={() => setopenLienModal(!openLienModal)}
              className=""
              title="Lien Action "
              open={openLienModal}
              propertyID={propertyID}
              actionable_id={actionable_id}
              row={row}
            />
          )}

          {modal_inputs.municipalreviewModal && (
            <MunicipalReviewAction
              actionID={actionID}
              propertyID={propertyID}
              open={modal_inputs.municipalreviewModal}
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  municipalreviewModal: !modal_inputs.municipalreviewModal,
                });
                getTitleDashboardStats();
              }}
              title="Municipality Review Action"
              fetchDocuments={getTitleDashboardStats}
            />
          )}
          {deleteModal && (
            <ConfirmBox
              onYesClick={() => {
                if (deleteOrderType === "Title")
                  cancelClientVendorOrder(title_order_id, order_id);
                else if (deleteOrderType === "Municipal")
                  cancelClientMunicipalOrder(title_order_id, order_id);
                setdeleteModal(false);
              }}
              onNoClick={() => setdeleteModal(false)}
              heading={
                <span>
                  Are you sure you want to cancel theOrder:
                  {order_id} ?
                </span>
              }
            >
              <div>
                <p>
                  Vendor cannot upload details against this order if you proceed
                  with Yes.
                </p>
              </div>
            </ConfirmBox>
          )}
        </>
      )}
    </React.Fragment>
  );
}
