import React, { Component, useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Col,
} from "reactstrap";
import "../../../styles/_titleReviewAction.scss";
import { ValidatorForm } from "react-form-validator-core";
import _ from "lodash";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import TitleDocumentsModal from "../../documents/TitleDocumentsModal";
import DatePicker from "react-datepicker";
import DocumentsTab from "../../documents/DocumentsTab";
import NotesHistory from "../../CodeViolation/NotesHistory";

export default function MunicipalityReviewAction(props) {
  const [municipalIssueTypeTitles, setmunicipalIssueTypeTitles] = useState();
  const [notes, setnotes] = useState();
  const [followup_date, setfollowup_date] = useState();
  const [documents, setdocuments] = useState();
  const [issueType, setissueType] = useState();
  const [taxIssues, settaxIssues] = useState({
    issue_title: "tax_issues",
    issue_available: false,
    issue_description: "",
    issue_type: "",
    department: "",
  });
  const [permitIssues, setpermitIssues] = useState({
    issue_title: "permit_issues",
    issue_available: false,
    issue_description: "",
    issue_type: "",
    department: "",
  });
  const [codeIssues, setcodeIssues] = useState({
    issue_title: "code_issues",
    issue_available: false,
    issue_description: "",
    issue_type: "",
    department: "",
  });
  const [propertyRegIssues, setpropertyRegIssues] = useState({
    issue_title: "property_registration_issues",
    issue_available: false,
    issue_description: "",
    issue_type: "",
    department: "",
  });
  const [utilityIssues, setutilityIssues] = useState({
    issue_title: "utility_issues",
    issue_available: false,
    issue_description: "",
    issue_type: "",
    department: "",
  });
  const [assessmentIssues, setassessmentIssues] = useState({
    issue_title: "assessment_issues",
    issue_available: false,
    issue_description: "",
    issue_type: "",
    department: "",
  });

  const [openFileUpload, setopenFileUpload] = useState(false);
  const [issueID, setissueID] = useState();
  const [rowID, setrowID] = useState();
  const [department, setdepartment] = useState();
  const issueTypeOptions = ["Lien", "Payment", "Violation"];

  const [orderDocuments, setorderDocuments] = useState();
  const [muni_review_notes, setmuni_review_notes] = useState();

  let typeIssues = [];
  typeIssues.push(<option value="">Select</option>);
  for (let type in issueTypeOptions) {
    typeIssues.push(
      <option key={issueTypeOptions[type]} value={issueTypeOptions[type]}>
        {issueTypeOptions[type]}
      </option>
    );
  }

  useEffect(() => {
    readMunicipalityReview();
    readMunicipalIssueTypeTitles();
    listDepartments();
  }, []);

  const readMunicipalityReview = () => {
    apicall.readMunicipalityReview(props.actionID).then((response) => {
      if (response) {
        setnotes(response.notes);
        setfollowup_date(response.followup_date);
        setrowID(response.id);
        setdocuments(response.municipal_documents);
        setorderDocuments(response.order_documents);
        let taxResult;
        taxResult = response.municipal_review_issues.filter((e) => {
          if (e.issue_title === "tax_issues") return e;
        });
        settaxIssues(taxResult[0]);

        let permitResult;
        permitResult = response.municipal_review_issues.filter((e) => {
          if (e.issue_title === "permit_issues") return e;
        });
        setpermitIssues(permitResult[0]);

        let codeResult;
        codeResult = response.municipal_review_issues.filter((e) => {
          if (e.issue_title === "code_issues") return e;
        });
        setcodeIssues(codeResult[0]);

        let propertyResult;
        propertyResult = response.municipal_review_issues.filter((e) => {
          if (e.issue_title === "property_registration_issues") return e;
        });
        setpropertyRegIssues(propertyResult[0]);

        let utilityResult;
        utilityResult = response.municipal_review_issues.filter((e) => {
          if (e.issue_title === "utility_issues") return e;
        });
        setutilityIssues(utilityResult[0]);

        let assessmentResult;
        assessmentResult = response.municipal_review_issues.filter((e) => {
          if (e.issue_title === "assessment_issues") return e;
        });
        setassessmentIssues(assessmentResult[0]);
      }
    });
  };

  const deleteMunicipalReviewDocument = (issueID, documentId) => {
    apicall
      .deleteMunicipalReviewDocument(props.actionID, issueID, documentId)
      .then((response) => {
        helpers.notify("Document deleted successfully");
        readMunicipalityReview();
      });
  };

  const readMunicipalIssueTypeTitles = () => {
    apicall.readMunicipalIssueTypeTitles(props.actionID).then((response) => {
      if (response) {
        setmunicipalIssueTypeTitles(response);
      }
    });
  };

  const listDepartments = () => {
    apicall.listDepartments().then((response) => {
      setdepartment(response.deppartments_list);
    });
  };

  let typeDepartment = [];
  typeDepartment.push(<option value="">Select</option>);
  for (let type in department) {
    typeDepartment.push(
      <option key={department[type]} value={department[type]}>
        {department[type]}
      </option>
    );
  }

  const handleSubmit = () => {
    var requestObject = {
      muni_review_notes: muni_review_notes,
      followup_date: followup_date,
      municipal_review_issues_attributes: [
        {
          id: taxIssues.id,
          issue_title: "tax_issues",
          issue_available: taxIssues.issue_available,
          issue_description: taxIssues.issue_description,
          issue_type: taxIssues.issue_type,
          department: taxIssues.department,
        },
        {
          id: permitIssues.id,
          issue_title: "permit_issues",
          issue_available: permitIssues.issue_available,
          issue_description: permitIssues.issue_description,
          issue_type: permitIssues.issue_type,
          department: permitIssues.department,
        },
        {
          id: codeIssues.id,
          issue_title: "code_issues",
          issue_available: codeIssues.issue_available,
          issue_description: codeIssues.issue_description,
          issue_type: codeIssues.issue_type,
          department: codeIssues.department,
        },
        {
          id: propertyRegIssues.id,
          issue_title: "property_registration_issues",
          issue_available: propertyRegIssues.issue_available,
          issue_description: propertyRegIssues.issue_description,
          issue_type: propertyRegIssues.issue_type,
          department: propertyRegIssues.department,
        },
        {
          id: utilityIssues.id,
          issue_title: "utility_issues",
          issue_available: utilityIssues.issue_available,
          issue_description: utilityIssues.issue_description,
          issue_type: utilityIssues.issue_type,
          department: utilityIssues.department,
        },
        {
          id: assessmentIssues.id,
          issue_title: "assessment_issues",
          issue_available: assessmentIssues.issue_available,
          issue_description: assessmentIssues.issue_description,
          issue_type: assessmentIssues.issue_type,
          department: assessmentIssues.department,
        },
      ],
    };

    apicall
      .updateMuniciplaityReview(requestObject, props.actionID)
      .then((response) => {
        if (response) {
          helpers.notify("Updated successfully");
          props.toggle();
          props.fetchDocuments();
          readMunicipalityReview();
        }
      });
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (targetName === "tax_issues_description") {
      settaxIssues({ ...taxIssues, issue_description: value });
    } else if (targetName === "permit_issues_description")
      setpermitIssues({ ...permitIssues, issue_description: value });
    else if (targetName === "code_issues_description")
      setcodeIssues({ ...codeIssues, issue_description: value });
    else if (targetName === "property_registration_issues_description")
      setpropertyRegIssues({ ...propertyRegIssues, issue_description: value });
    else if (targetName === "utility_issues_description")
      setutilityIssues({ ...utilityIssues, issue_description: value });
    else if (targetName === "assessment_issues_description")
      setassessmentIssues({ ...assessmentIssues, issue_description: value });
  };

  const renderFormInputElements = (name, value) => {
    return (
      <div className="ip-fields">
        <Input
          type={"textarea"}
          name={name}
          id={name}
          onChange={(e) => handleChange(e)}
          value={value}
        />
      </div>
    );
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;
    console.log("the value", targetName);
    if (targetName === "tax_issues") {
      settaxIssues({ ...taxIssues, issue_available: value });
    } else if (targetName === "permit_issues")
      setpermitIssues({ ...permitIssues, issue_available: value });
    else if (targetName === "code_issues")
      setcodeIssues({ ...codeIssues, issue_available: value });
    else if (targetName === "property_registration_issues")
      setpropertyRegIssues({ ...propertyRegIssues, issue_available: value });
    else if (targetName === "utility_issues")
      setutilityIssues({ ...utilityIssues, issue_available: value });
    else if (targetName === "assessment_issues")
      setassessmentIssues({ ...assessmentIssues, issue_available: value });
  };

  const renderRadioInput = (label, name, inputValue, disabled) => {
    return (
      <div className="question" style={{ width: "100%" }}>
        <div className="text">{label}</div>
        <div className="radio">
          <CustomInput
            id={`${name}yes`}
            type="radio"
            name={name}
            value={true}
            onClick={(e) => handleRadioButton(e, true)}
            checked={inputValue.issue_available === true ? "checked" : ""}
          />
          <label>Yes</label>
          <CustomInput
            id={`${name}no`}
            type="radio"
            name={name}
            value={false}
            onClick={(e) => handleRadioButton(e, false)}
            checked={inputValue.issue_available === false ? "checked" : ""}
          />
          <label>No</label>
        </div>
      </div>
    );
  };

  const handleDateChange = (value) => {
    let date = helpers.restructureDate(value);
    setfollowup_date(date);
  };

  const uploadFile = (id, type) => {
    return (
      <div>
        <Button
          onClick={() => {
            setopenFileUpload(true);
            setissueID(id);
            setissueType(type);
          }}
          style={{ marginBottom: "0px" }}
        >
          File Upload
        </Button>
      </div>
    );
  };

  const toggleDocument = () => {
    setopenFileUpload(!openFileUpload);
  };

  const displayTitleDocuments = (documents) => {
    return (
      <>
        {!_.isEmpty(documents) &&
          documents.map((fileNames) => {
            return (
              <div style={{ marginLeft: "40px" }}>
                <a
                  href={fileNames.file_url}
                  style={{ float: "none", color: "blue" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fileNames.file_name}
                </a>
              </div>
            );
          })}
      </>
    );
  };

  const formElements = () => {
    return (
      <>
        <div className="titleReview-documents-div">
          {displayTitleDocuments(orderDocuments)}
        </div>
        <table className="reviewActionTable" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "16%" }}></th>
              <th style={{ textAlign: "center", width: "5%" }}>Issue Type</th>
              <th style={{ textAlign: "center", width: "16%" }}>
                Issue Description
              </th>
              <th style={{ width: "3%" }}></th>
              <th style={{ textAlign: "center" }}>Department</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div
                  className="titleReview-code-radio"
                  style={{ width: "100%" }}
                >
                  {renderRadioInput(
                    "Are there any Tax Issues?*",
                    "tax_issues",
                    taxIssues
                  )}
                </div>
              </td>{" "}
              {taxIssues.issue_available === true ? (
                <>
                  <td>
                    <Input
                      type="select"
                      name={"tax_issues_type"}
                      id={"tax_issues_type"}
                      onChange={(event) => {
                        settaxIssues({
                          ...taxIssues,
                          issue_type: event.target.value,
                        });
                      }}
                      value={taxIssues.issue_type}
                    >
                      {typeIssues}
                    </Input>
                  </td>
                  <td>
                    {renderFormInputElements(
                      "tax_issues_description",
                      taxIssues.issue_description
                    )}
                  </td>
                  <td>{uploadFile(taxIssues.id, "Tax")}</td>
                  <td>
                    <Input
                      type="select"
                      name={"tax_issues_department"}
                      id={"tax_issues_department"}
                      onChange={(event) => {
                        settaxIssues({
                          ...taxIssues,
                          department: event.target.value,
                        });
                      }}
                      value={taxIssues.department}
                    >
                      {typeDepartment}
                    </Input>
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td>
                {" "}
                <div
                  className="titleReview-code-radio"
                  style={{ width: "100%" }}
                >
                  {renderRadioInput(
                    "Are there any Permit Issues?*",
                    "permit_issues",
                    permitIssues
                  )}
                </div>
              </td>

              {permitIssues.issue_available === true ? (
                <>
                  <td>
                    <Input
                      type="select"
                      name={"permit_issues_type"}
                      id={"permit_issues_type"}
                      onChange={(event) => {
                        setpermitIssues({
                          ...permitIssues,
                          issue_type: event.target.value,
                        });
                      }}
                      value={permitIssues.issue_type}
                    >
                      {typeIssues}
                    </Input>
                  </td>
                  <td>
                    {renderFormInputElements(
                      "permit_issues_description",
                      permitIssues.issue_description
                    )}
                  </td>
                  <td>{uploadFile(permitIssues.id, "Permit")}</td>
                  <td>
                    <Input
                      type="select"
                      name={"permit_issues_department"}
                      id={"permit_issues_department"}
                      onChange={(event) => {
                        setpermitIssues({
                          ...permitIssues,
                          department: event.target.value,
                        });
                      }}
                      value={permitIssues.department}
                    >
                      {typeDepartment}
                    </Input>
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td>
                <div
                  className="titleReview-code-radio"
                  style={{ width: "100%" }}
                >
                  {renderRadioInput(
                    "Are there any Code Issues?*",
                    "code_issues",
                    codeIssues
                  )}
                </div>
              </td>{" "}
              {codeIssues.issue_available === true ? (
                <>
                  <td>
                    <Input
                      type="select"
                      name={"code_issues_type"}
                      id={"code_issues_type"}
                      onChange={(event) => {
                        setcodeIssues({
                          ...codeIssues,
                          issue_type: event.target.value,
                        });
                      }}
                      value={codeIssues.issue_type}
                    >
                      {typeIssues}
                    </Input>
                  </td>
                  <td>
                    {renderFormInputElements(
                      "code_issues_description",
                      codeIssues.issue_description
                    )}
                  </td>
                  <td>{uploadFile(codeIssues.id, "Code")}</td>
                  <td>
                    <Input
                      type="select"
                      name={"code_issues_department"}
                      id={"code_issues_department"}
                      onChange={(event) => {
                        setcodeIssues({
                          ...codeIssues,
                          department: event.target.value,
                        });
                      }}
                      value={codeIssues.department}
                    >
                      {typeDepartment}
                    </Input>
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td>
                {" "}
                <div
                  className="titleReview-code-radio"
                  style={{ width: "100%" }}
                >
                  {renderRadioInput(
                    "Are there any Property Registration Issues?*",
                    "property_registration_issues",
                    propertyRegIssues
                  )}
                </div>
              </td>

              {propertyRegIssues.issue_available === true ? (
                <>
                  <td>
                    <Input
                      type="select"
                      name={"property_registration_issues_type"}
                      id={"property_registration_issues_type"}
                      onChange={(event) => {
                        setpropertyRegIssues({
                          ...propertyRegIssues,
                          issue_type: event.target.value,
                        });
                      }}
                      value={propertyRegIssues.issue_type}
                    >
                      {typeIssues}
                    </Input>
                  </td>
                  <td>
                    {renderFormInputElements(
                      "property_registration_issues_description",
                      propertyRegIssues.issue_description
                    )}
                  </td>
                  <td>
                    {uploadFile(propertyRegIssues.id, "Property Registration")}
                  </td>
                  <td>
                    <Input
                      type="select"
                      name={"property_registration_issues_department"}
                      id={"property_registration_issues_department"}
                      onChange={(event) => {
                        setpropertyRegIssues({
                          ...propertyRegIssues,
                          department: event.target.value,
                        });
                      }}
                      value={propertyRegIssues.department}
                    >
                      {typeDepartment}
                    </Input>
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td>
                <div
                  className="titleReview-code-radio"
                  style={{ width: "100%" }}
                >
                  {renderRadioInput(
                    "Are there any Utility Issues?*",
                    "utility_issues",
                    utilityIssues
                  )}
                </div>
              </td>

              {utilityIssues.issue_available === true ? (
                <>
                  <td>
                    <Input
                      type="select"
                      name={"utility_issues_type"}
                      id={"utility_issues_type"}
                      onChange={(event) => {
                        setutilityIssues({
                          ...utilityIssues,
                          issue_type: event.target.value,
                        });
                      }}
                      value={utilityIssues.issue_type}
                    >
                      {typeIssues}
                    </Input>
                  </td>
                  <td>
                    {renderFormInputElements(
                      "utility_issues_description",
                      utilityIssues.issue_description
                    )}
                  </td>
                  <td>{uploadFile(utilityIssues.id, "Utility")}</td>
                  <td>
                    <Input
                      type="select"
                      name={"utility_issues_department"}
                      id={"utility_issues_department"}
                      onChange={(event) => {
                        setutilityIssues({
                          ...utilityIssues,
                          department: event.target.value,
                        });
                      }}
                      value={utilityIssues.department}
                    >
                      {typeDepartment}
                    </Input>
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
            <tr>
              <td>
                {" "}
                <div
                  className="titleReview-code-radio"
                  style={{ width: "100%" }}
                >
                  {renderRadioInput(
                    "Are there any Assessment Issues?*",
                    "assessment_issues",
                    assessmentIssues
                  )}
                </div>
              </td>

              {assessmentIssues.issue_available === true ? (
                <>
                  <td>
                    <Input
                      type="select"
                      name={"assessment_issues_type"}
                      id={"assessment_issues_type"}
                      onChange={(event) => {
                        setassessmentIssues({
                          ...assessmentIssues,
                          issue_type: event.target.value,
                        });
                      }}
                      value={assessmentIssues.issue_type}
                    >
                      {typeIssues}
                    </Input>
                  </td>
                  <td>
                    {renderFormInputElements(
                      "assessment_issues_description",
                      assessmentIssues.issue_description
                    )}
                  </td>
                  <td>{uploadFile(assessmentIssues.id, "Assessment")}</td>
                  <td>
                    <Input
                      type="select"
                      name={"assessment_issues_department"}
                      id={"assessment_issues_department"}
                      onChange={(event) => {
                        setassessmentIssues({
                          ...assessmentIssues,
                          department: event.target.value,
                        });
                      }}
                      value={assessmentIssues.department}
                    >
                      {typeDepartment}
                    </Input>
                  </td>
                </>
              ) : (
                <></>
              )}
            </tr>
          </tbody>
        </table>
        <DocumentsTab
          id={rowID}
          issueID={issueID}
          documents={documents}
          isMunicipalityReview={true}
          deleteDocument={deleteMunicipalReviewDocument}
          theme={"general-info-tab"}
          fetchDocuments={readMunicipalityReview}
        />
        <div>
          <Col sm={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Input
              type="textarea"
              name="muni_verification_notes"
              id="muni_verification_notes"
              placeholder="Notes"
              onChange={(event) => setmuni_review_notes(event.target.value)}
              // value={notes}
            />
          </Col>
        </div>
        <div class="follow-up-date" style={{ marginTop: "1rem" }}>
          <h6>Follow Up Date</h6>
          <div className="dateField">
            <DatePicker
              id={"follow_up_date"}
              selected={
                followup_date ? helpers.restructureDate(followup_date) : null
              }
              onChange={(value) => {
                handleDateChange(value);
              }}
              value={
                followup_date ? helpers.restructureDate(followup_date) : null
              }
            />
          </div>
        </div>
        <div className="status" style={{ marginTop: "-5rem" }}>
          <Button type="button" id="submit" onClick={() => handleSubmit(true)}>
            Submit
          </Button>
        </div>
        <NotesHistory notes={notes ? notes : []} />
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Municipality Review Action</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal className="title-modal" isOpen={props.open} toggle={props.toggle}>
        {renderModal()}
      </Modal>
      {openFileUpload && (
        <TitleDocumentsModal
          toggle={toggleDocument}
          open={openFileUpload}
          id={rowID}
          issueID={issueID}
          title="Document"
          isMuniciReviewAction={true}
          fetchDocuments={readMunicipalityReview}
          onHandleSubmit={handleSubmit}
          issueType={issueType}
        />
      )}
    </div>
  );
}
