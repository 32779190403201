import React, { Component, useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../styles/propertyupload.css";
import apicall from "../../utils/apicall";
import "../../styles/content.css";
import MunicipalityVendorListTable from "./MunicipalityVendorListTable";
import LoaderComponent from "../helper_components/LoaderComponent";

export default function MunicipalityVendor() {
  const [vendorModal, setvendorModal] = useState(false);
  const [pageNo, setpageNo] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [vendorsList, setvendorsList] = useState([]);
  const [total, settotal] = useState();
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    getMunicipalVendors();
  }, [form_inputs.sort]);

  const toggleModal = () => {
    setvendorModal(!vendorModal);
  };

  const getMunicipalVendors = (event, pageNo, perPage) => {
    setisLoading(true);
    apicall
      .listMunicipalVendor(form_inputs, pageNo, perPage)
      .then((response) => {
        setvendorsList(response.results);
        setisLoading(false);
        settotal(response.total);
        setpageNo(pageNo);
      });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderComponent />}
      <MunicipalityVendorListTable
        vendors={vendorsList}
        getMunicipalVendors={getMunicipalVendors}
        pageNo={pageNo}
        totalCount={total}
        toggle={toggleModal}
        sortColumns={sortColumns}
      />
    </React.Fragment>
  );
}
