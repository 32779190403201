import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var propertyapi = {
  propertyDocumentsFetch: async function (propertyID, hoaID) {
    let url =
      config.baseUrl + "/api/v1/properties/" + propertyID + "/all_documents";
    if (Array.isArray(hoaID)) {
      let arr = [];
      hoaID.map((x) => arr.push(`hoa_ids[]=${x}`));
      let hoas = arr.join("&");
      url = url + `?${hoas}`;
    } else if (hoaID && hoaID !== "0") {
      url = url + `?hoa_ids[]=${hoaID}`;
    }
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  createProperty: async function (requestObject) {
    let url = config.baseUrl + "/api/v1/properties";

    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(requestObject),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  uploadPropertyFile: async function (uploadfile, filetype) {
    let url = null;
    // var form = new FormData();
    // if (filetype === "property-file") {
    //   url = config.baseUrl + "/api/v1/properties_imports";
    //   form.append("properties_import[file]", uploadfile);
    // } else if (filetype === "analyst-file") {
    //   url = config.baseUrl + "/api/v1/import_area_analysts";
    //   form.append("file", uploadfile);
    // } else if (filetype === "association-verification") {
    //   url =
    //     config.baseUrl +
    //     "/api/v1/properties_imports/update_association_verification_status";
    //   form.append("properties_import[file]", uploadfile);
    // }
    var form = new FormData();
    if (filetype === "property-file") {
      url = config.baseUrl + "/api/v1/properties_imports";
      form.append("properties_import[file]", uploadfile);
    } else if (filetype === "analyst-file") {
      url = config.baseUrl + "/api/v1/import_area_analysts";
      form.append("file", uploadfile);
    } else if (filetype === "association-verification") {
      url =
        config.baseUrl +
        "/api/v1/properties_imports/update_association_verification_status";
      form.append("properties_import[file]", uploadfile);
    } else if (filetype === "vendor_prices") {
      url = config.baseUrl + "/api/v1/vendor_prices/import";
      form.append("file", uploadfile);
    } else if (filetype === "title-order") {
      url = config.baseUrl + "/api/v1/client_vendor_orders/import";
      form.append("file", uploadfile);
    } else if (filetype === "muni-order") {
      url = config.baseUrl + "/api/v1/client_municipal_orders/import";
      form.append("file", uploadfile);
    }
    var init = {
      method: "POST",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    if (
      (filetype === "property-file") |
      (filetype === "vendor_prices") |
      (filetype === "title-order") |
      (filetype === "muni-order")
    ) {
      let Response;
      await fetch(url, init)
        .then((response) => {
          return response.json();
        })
        .then((responsejson) => {
          Response = responsejson;
        })
        .catch(function (error) {});
      return Response;
    } else {
      return await fetch(url, init)
        .then(helpers.fetchHandler)
        .then((json) => json.json);
    }
  },
  searchProperties: async function (address, data, pageNo, perPage) {
    let url = config.baseUrl + "/api/v1/properties/search?";
    let params = {};

    if (typeof address !== "undefined" && address !== null) {
      params["address"] = address;
    }

    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  getProperties: async function (data, pageNo, perPage) {
    //var properties = [];
    let url = config.baseUrl + "/api/v1/properties?";
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
    // if (pageNo) {
    //   url = url + "?page=" + pageNo;
    // }
    // if (perPage) url = `${url}&per_page=${perPage}`;

    // var init = {
    //   method: "GET",
    //   headers: await helpers.defaultHeaders(),
    //   "Content-Type": "application/json",
    // };
    // await fetch(url, init)
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(
    //       "Something went wrong, while getting properties" + response
    //     );
    //   })
    //   .then((propertiesList) => {
    //     properties = propertiesList;
    //   })
    //   .catch(function (error) {});
    // return properties;
  },
  getProperty: async function (propertId) {
    var questions = {};
    let url = config.baseUrl + "/api/v1/properties/" + propertId;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
      "Content-Type": "application/json",
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting Property" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },

  deletePropertyDocument: async function (property_id, id, section) {
    console.log("id", id);
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      property_id +
      `/delete_doc?document_id=${id}&section=${section}`;

    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };

    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  updateProperty: async function (property, id) {
    let url = config.baseUrl + "/api/v1/properties/" + id;
    var requestObject = {
      property: property,
    };
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(requestObject),
    };
    var updateAnalystResponse = null;
    await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        updateAnalystResponse = responsejson;
      })
      .catch(function (error) {});
    return updateAnalystResponse;
  },
  propertyDocumentUpload: async function (propertyDoc, propertyID) {
    let res = "";
    let url =
      config.baseUrl + "/api/v1/properties/" + propertyID + "/upload_doc";
    let form = new FormData();
    for (var key in propertyDoc) {
      if (!Array.isArray(propertyDoc[key])) {
        if (key !== "documents_attributes[][type]") {
          //     form.append("property[" + key + "]", 'Property');
          // }else{
          if (key === "documents_attributes[][applicable]") {
            form.append("property[" + key + "]", !propertyDoc[key]);
          } else {
            propertyDoc[key] &&
              form.append("property[" + key + "]", propertyDoc[key]);
          }
        }
      }
    }
    let init = {
      method: "POST",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error(
          "Something went wrong, while Property File Upload" + response
        );
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  getHistory: async function (propertyId, page) {
    let url =
      config.baseUrl +
      "/api/v1/histories?property_id=" +
      propertyId +
      "&page=" +
      page;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    var historyArray = [];
    var totalCount;
    await config.localforage.getItem("history", (err, history) => {
      if (!_.isEmpty(history)) {
        historyArray = history;
      }
    });
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting Property history" + response
        );
      })
      .then((responseJson) => {
        historyArray.push(...responseJson.results);
        totalCount = responseJson.total;
        config.localforage.setItem("history", historyArray, function (err) {});
        config.localforage.setItem("historyCount", totalCount, function (
          err
        ) {});
        console.log("==>", historyArray);
      })
      .catch(function (error) {
        console.log(error);
      });
    return { historyArray: historyArray, totalCount: totalCount };
  },
  getPropertyAssociationDocuments: async function (propertyID, hoaID) {
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      propertyID +
      "/association_documents";
    if (Array.isArray(hoaID)) {
      let arr = [];
      hoaID.map((x) => arr.push(`hoa_ids[]=${x}`));
      let hoas = arr.join("&");
      url = url + `?${hoas}`;
    } else if (hoaID && hoaID !== "0") {
      url = url + `?hoa_ids[]=${hoaID}`;
    }
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  getPropertyDocuments: async function (propertyID) {
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      propertyID +
      "/property_documents";

    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  guestSearch: async function (address, data, pageNo, perPage) {
    let url = `${config.baseUrl}/api/v1/properties/guest_search?`;
    let params = {};
    if (typeof address !== "undefined" && address !== null) {
      params["address"] = address;
    }
    if (pageNo) {
      params["page"] = pageNo;
    }
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }

    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");

    var searchResponse = null;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
      "Content-Type": "application/json",
    };
    await fetch(url + query, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        searchResponse = responsejson;
      })
      .catch(function (error) {});
    return searchResponse;
  },
  guestPropertyInfo: async function (propertyID) {
    let url = `${config.baseUrl}/api/v1/properties/${propertyID}/guest_property_info`;

    var questions = {};
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
      "Content-Type": "application/json",
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting Property" + response
        );
      })
      .then((questionsJson) => {
        questions = questionsJson;
      })
      .catch(function (error) {});
    return questions;
  },
  getPropertyMunicipalityDocuments: async function (propertyID) {
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      propertyID +
      "/municipality_documents";
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};
export default withRouter(propertyapi);
