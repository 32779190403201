import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var municiplaityActionapi = {
  updateMunicipalityAction: async function (data, titleActionId, propertyID) {
    // delete data.created_at;
    // delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/title_actions/${titleActionId}?property_id=${propertyID}`;
    delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  readMunicipalityReview: async function (propertyId) {
    let url = `${config.baseUrl}/api/v1/municipal_reviews/${propertyId}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },

  readMunicipalIssueTypeTitles: async function (propertyId) {
    let url = `${config.baseUrl}/api/v1/municipal_reviews/issue_type_titles`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  updateMuniciplaityReview: async function (data, muniActionId) {
    // delete data.created_at;
    // delete data.updated_at;
    let url = `${config.baseUrl}/api/v1/municipal_reviews/${muniActionId}`;
    // delete data.id;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  municipalityReviewUploadDocuments: async function (
    data,
    id,
    issue_id,
    issueType
  ) {
    let url = `${config.baseUrl}/api/v1/municipal_reviews/${id}/add_documents?municipal_review_issue_id=${issue_id}`;
    let headers;
    let response;
    headers = await helpers.titleFileHeaders();
    var form = new FormData();
    // if (key === "notes") form.append("municipal_order[notes]", data);

    for (var key in data) {
      form.append("municipal_review_issue[" + key + "]", data[key]);
    }
    if (issueType)
      form.append(
        "municipal_review_issue[municipal_documents_attributes[][category]]",
        issueType
      );

    await Axios.post(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteMunicipalReviewDocument: async function (
    actionID,
    municipal_review_issue_id,
    document_id
  ) {
    let url =
      config.baseUrl +
      `/api/v1/municipal_reviews/${actionID}/delete_documents?municipal_review_issue_id=${municipal_review_issue_id}&municipal_document_id=${document_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(municiplaityActionapi);
