import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../styles/sidebar.scss";
import config from "../../utils/config";
import { FaChevronDown } from "react-icons/fa";
export default class SidebarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyDropDown: false,
      userRole: "",
    };
    config.localforage
      .getItem("user_role")
      .then((role) => {
        this.setState({ userRole: role });
      })
      .catch((e) => console.log(e));
  }

  showMore = (event) => {
    const id = event.target.id;

    this.setState({
      [id]: !this.state[id],
    });
  };

  sidebarTapAction = (event) => {};
  render() {
    const render_users_analysts_link = () => {
      if (config["propertyUpload"].includes(this.state.userRole)) {
        return (
          <React.Fragment>
            <Link to="/users" onClick={this.sidebarTapAction}>
              <li className="list-item">
                <img className="" src="" alt="" />
                Users
              </li>
            </Link>
            <Link to="/area-analysts" onClick={this.sidebarTapAction}>
              <li className="list-item">
                <img className="" src="" alt="" />
                Area Analysts
              </li>
            </Link>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };

    return (
      <React.Fragment>
        <div className="sidebar-section">
          <aside className="sidebar">
            <div className="scrollbar-inner">
              <ul className="navigation">
                <div className="sidebar-dropdown-header">
                  <div className="dropbtn">
                    <Link to="/homepage" style={{ color: "white" }}>
                      Dashboard
                    </Link>
                  </div>
                </div>

                <div className="sidebar-dropdown-header">
                  <div className="dropbtn">Admin</div>
                  {!(
                    config["municipalUserDashBoardRead"].includes(
                      this.state.userRole
                    ) ||
                    config["titleUserDashBoardRead"].includes(
                      this.state.userRole
                    )
                  ) && (
                    <div className="dropdown-content">
                      <Link to="/users">Users</Link>
                      <Link to="/area-analysts">Area Analyst</Link>
                      <Link to="/property-upload">Property Upload</Link>
                      <Link to="/payment-upload">Payment Upload</Link>
                    </div>
                  )}
                </div>
                <div className="sidebar-dropdown-header">
                  <div className="dropbtn">Association</div>
                  {!(
                    config["municipalUserDashBoardRead"].includes(
                      this.state.userRole
                    ) ||
                    config["titleUserDashBoardRead"].includes(
                      this.state.userRole
                    )
                  ) && (
                    <div className="dropdown-content">
                      <Link to="/property-search">Properties</Link>
                      <Link to="/hoas">Associations</Link>
                      <Link to="/management-companies">
                        Management Companies
                      </Link>
                    </div>
                  )}
                </div>
                <div className="sidebar-dropdown-header">
                  <div className="dropbtn">
                    {!(
                      config["municipalUserDashBoardRead"].includes(
                        this.state.userRole
                      ) ||
                      config["titleUserDashBoardRead"].includes(
                        this.state.userRole
                      )
                    ) ? (
                      <Link to={"/municipal"} style={{ color: "white" }}>
                        Municipalities
                      </Link>
                    ) : (
                      <div>Municipalities</div>
                    )}
                  </div>
                </div>

                <div className="sidebar-dropdown-header">
                  <div className="dropbtn">Title / Deed</div>
                  {!(
                    config["municipalUserDashBoardRead"].includes(
                      this.state.userRole
                    ) ||
                    config["titleUserDashBoardRead"].includes(
                      this.state.userRole
                    )
                  ) && (
                    <div className="dropdown-content">
                      <Link to={"/business-units"}>Business Units</Link>
                      <Link to={"/law-firm"}>Law Firms</Link>
                      <Link to={"/title-order"}>Orders</Link>
                      <Link to="/vendor-creation">Vendors</Link>
                    </div>
                  )}
                </div>
                <div className="sidebar-dropdown-header">
                  <div className="dropbtn">
                    {!(
                      config["municipalUserDashBoardRead"].includes(
                        this.state.userRole
                      ) ||
                      config["titleUserDashBoardRead"].includes(
                        this.state.userRole
                      )
                    ) ? (
                      <Link to={"/reports"} style={{ color: "white" }}>
                        Reports
                      </Link>
                    ) : (
                      <div>Reports</div>
                    )}
                  </div>
                </div>
              </ul>
            </div>
          </aside>
        </div>
      </React.Fragment>
    );
  }
}
