import React, { Component } from "react";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import apicall from "../../utils/apicall";
import { toast } from "react-toastify";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "95%",
  }),
};

const formatOptionLabel = ({ value, label, status }, { context }) => {
  if (context === "value") {
    return <div>{label}</div>;
  } else if (context === "menu") {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "90%" }}>{label}</div>
        <div
          style={{
            width: "10%",
            color: "white",
            backgroundColor: "#1f77b4",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          {status}
        </div>
      </div>
    );
  }
};

export default class AsyncMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selectedOption: null,
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  filterResults = (inputValue, callback) => {
    console.log(this.props.propertyid);

    if (
      this.props.documentFilter &&
      inputValue !== null &&
      typeof inputValue !== "undefined" &&
      inputValue !== ""
    ) {
      //   apicall
      //     .searchHoa(inputValue, 1, true, null, this.props.propertyid)
      //     .then((resp) => {
      //       callback(resp.results);
      //       return resp.results;
      //     });
      apicall
        .listAssociationName(inputValue, this.props.propertyid)
        .then((resp) => {
          callback(resp.results);
          return resp.results;
        });
    } else if (
      inputValue !== null &&
      typeof inputValue !== "undefined" &&
      inputValue !== ""
    ) {
      //   apicall.searchHoa(inputValue, 1, true, null).then((resp) => {
      //     callback(resp.results);
      //     return resp.results;
      //   });
      apicall
        .listAssociationName(inputValue, this.props.propertyid)
        .then((resp) => {
          callback(resp.results);
          return resp.results;
        });
    }
  };

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      this.filterResults(inputValue, callback);
    }, 1000);
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({
      inputValue: inputValue,
    });
  };

  handleChange = (value) => {
    this.setState({
      selectedOption: value,
    });
  };

  notify = (msg) => toast(msg);

  handleSubmit = (event) => {
    if (
      this.state.selectedOption !== null &&
      typeof this.state.selectedOption !== "undefined"
    ) {
      let seletedOptions = this.state.selectedOption;
      let hoaIDArray = [];
      for (let option of Object.keys(seletedOptions)) {
        let hoaId = seletedOptions[option]["value"];
        hoaIDArray.push(hoaId);
      }
      let propertyObject = {
        hoa_ids: hoaIDArray,
      };
      apicall
        .updateProperty(propertyObject, this.props.propertyid)
        .then((response) => {
          if (_.isEmpty(response) || response.hasOwnProperty("errors")) {
            // let errorMsg = helpers.getErrorMsg(response.error);
            this.notify(response.errors);
          } else {
            this.notify("Hoas added to property Successfully");
            this.props.loadHoaList();
            setTimeout(
              () => this.props.getActions(this.props.propertyid),
              3000
            );
          }
        });
    }
    event.preventDefault();
  };

  render() {
    return (
      <React.Fragment>
        <div className="hoa-multi-select">
          <div className="select-box">
            {this.props.documentFilter ? (
              <AsyncSelect
                isMulti
                cacheOptions
                /* defaultOptions */
                loadOptions={this.loadOptions}
                onInputChange={this.handleInputChange}
                onChange={(value) => this.props.handleOnChange(value)}
                styles={customStyles}
                className="react-select-container"
                value={this.props.hoa}
                placeholder="Search Hoa..."
              />
            ) : (
              <AsyncSelect
                isMulti
                cacheOptions
                /* defaultOptions */
                loadOptions={this.loadOptions}
                onInputChange={this.handleInputChange}
                onChange={this.handleChange}
                styles={customStyles}
                className="react-select-container"
                value={this.state.selectedOption}
                placeholder="Search Hoa..."
                formatOptionLabel={formatOptionLabel}
              />
            )}
          </div>
          {!this.props.documentFilter && (
            <div className="button">
              <form onSubmit={this.handleSubmit}>
                <button>Add</button>
              </form>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
