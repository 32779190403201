import React, { useState, useEffect } from "react";
import "../../styles/_newDashboard.scss";
import Loader from "../helper_components/LoaderComponent";
import apicall from "../../utils/apicall";
import PaginationComponent from "../helper_components/PaginationComponent";
import MunicipalModal from "../Municipal/MunicipalModal";
import _ from "lodash";
import MunicipalRequirementAction from "../Municipal/MunicipalRequirementAction";
import helpers from "../../utils/helpers";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import MunicipalPendingStatsDashboard from "./MunicipalPendingStatsDashboard";

import PaymentModal from "../payments/paymentModal";

export default function MunicipalDashboardComponent(props) {
  const [loading, set_loading] = useState(false);
  const [toggleModal, settoggleModal] = useState(false);
  const [table_data, set_table_data] = useState();
  const [total, set_total] = useState();
  const [pageNumber, setpageNumber] = useState();
  const [perPage, setperPage] = useState();
  const [tierOnefilter, settierOnefilter] = useState();
  const [tierTwofilter, settierTwofilter] = useState();
  const [tierThreefilter, settierThreefilter] = useState();
  const [municipalModal, setmunicipalModal] = useState(false);
  const [states, set_States] = useState();
  const [municipalID, setmunicipalID] = useState();
  const [row, setrow] = useState({});
  const [openmunicipalAction, setopenmunicipalAction] = useState(false);
  const [propertyID, setpropertyID] = useState();
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });
  const [pendingDashboardFilter, setpendingDashboardFilter] = useState();
  const [generalPaymentFields, setgeneralPaymentFields] = useState();
  const [generalPaymentRightFields, setgeneralPaymentRightFields] = useState();
  const [dashboardValue, setdashboardValue] = useState();
  const [dashboardModal, setdashboardModal] = useState(false);

  const [modal_inputs, set_modal_inputs] = useState({
    paymentModal: false,
  });
  const manager_user = props.manager_user;

  useEffect(() => {
    if (tierOnefilter !== "general_payment") getMunicipalDashboardStats();
    if (
      tierOnefilter !== "muni_verifications" &&
      tierOnefilter !== "muni_registrations"
    )
      getDashboardPendingStats();
  }, [
    tierOnefilter,
    tierTwofilter,
    tierThreefilter,
    form_inputs.sort,
    manager_user,
  ]);

  useEffect(() => {
    readStates();
  }, []);

  useEffect(() => {
    if (tierOnefilter === "general_payment") getDashboardPendingStats();
  }, [tierOnefilter, tierTwofilter, tierThreefilter]);

  const getMunicipalDashboardStats = (event, page, perPage) => {
    set_loading(true);
    apicall
      .getMunicipalDashboardStats(
        form_inputs,
        tierOnefilter,
        tierTwofilter,
        tierThreefilter,
        manager_user,
        page,
        perPage
      )
      .then((response) => {
        if (!_.isEmpty(response.results.data.muni_registration))
          setdashboardValue(response.results.data);

        if (!_.isEmpty(response.results.records.results)) {
          set_table_data(response.results.records.results);
          set_total(response.results.records.total);
        } else {
          set_table_data("");
          set_total();
        }

        setpageNumber(page);
        setperPage(perPage);
        set_loading(false);
        settoggleModal(true);
        setdashboardModal(true);
      })
      .catch((e) => set_loading(false));
  };
  const getDashboardPendingStats = (event, page, perPage) => {
    set_loading(true);
    apicall
      .getDashboardPendingStats(
        form_inputs,
        tierOnefilter,
        tierTwofilter,
        tierThreefilter,
        "",
        "",
        page,
        perPage
      )
      .then((response) => {
        setpendingDashboardFilter(response.results.data);
        if (!_.isEmpty(response.results.data.general_payment.left_fields)) {
          setgeneralPaymentFields(
            response.results.data.general_payment.left_fields
          );
        }
        if (!_.isEmpty(response.results.data.general_payment.right_fields)) {
          setgeneralPaymentRightFields(
            response.results.data.general_payment.right_fields
          );
        }
        settoggleModal(true);
        set_loading(false);
        if (!_.isEmpty(response.results.records.results)) {
          let paymentOriginalData = response.results.records.results
          let modifiedPaymentData = paymentOriginalData.map((value) => {
            if (value.payment_charge_type !== null) {
              value.description = value.payment_charge_type;
            }
            return value;
          })
          set_table_data(modifiedPaymentData);
          set_total(response.results.records.total);
        } else {
          set_table_data("");
          set_total();
        }
      })
      .catch((e) => set_loading(false));
  };

  const readStates = () => {
    apicall.filterStates().then((response) => {
      if (response) {
        let statesArray = [];
        if (response.states) {
          statesArray = response.states.map((x) => {
            return { value: x, label: x };
          });

          set_States(statesArray);
        }
      }
    });
  };

  const actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <React.Fragment>
        <div
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            setmunicipalModal(true);
            setmunicipalID(row.id);
            setrow(row);
          }}
        >
          {cell}
        </div>
      </React.Fragment>
    );
  };

  const periodicityCapitalize = (cell, row, rowIndex, formatExtraData) => {
    if (!_.isEmpty(cell)) return cell.replace(/,/g, ", ");
  };

  const muni_verification_columns = [
    {
      dataField: "name",
      text: " Name",
      formatter: actionTypeFormatter,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "state",
      text: "State",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "county",
      text: "County",
      formatter: periodicityCapitalize,
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "muni_type",
      text: "Type",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
  ];

  const muni_reg_actionTypeFormatter = (
    cell,
    row,
    rowIndex,
    formatExtraData
  ) => {
    let data;
    if (cell === "MunicipalityRegistration") data = "Registration Action";
    return (
      <React.Fragment>
        <div
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            setopenmunicipalAction(true);
            setmunicipalID(row.actionable_id);
            setpropertyID(row.property_id);
          }}
        >
          {data}
        </div>
      </React.Fragment>
    );
  };

  const propertyLinkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/3/" + row.property_id + "/" + null}
          target="_blank"
          key={cell + row.property_id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  const notesFormatter = (cell, row) => {
    let notesArr = !_.isEmpty(cell) && cell.split(/\n/g);
    let notes =
      _.isArray(notesArr) &&
      notesArr
        .filter((x) => x !== "")
        .map((x, ind) => {
          return <p key={x}>{x}</p>;
        });
    return (
      <>
        <p>{notes}</p>
      </>
    );
  };
  const linkFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (typeof row !== "undefined") {
      return (
        <Link
          to={"/property-manage/3/" + row.property_id + "/" + row.hoa_id}
          target="_blank"
          key={cell + row.id}
        >
          {cell}
        </Link>
      );
    }
    return null;
  };

  const paymentActionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      !_.isEmpty(cell) &&
      cell.trim().toLowerCase() !== "documentinforeport" &&
      cell.trim().toLowerCase() !== "pir"
      // && cell.trim().toLowerCase() !== "associationverification"
    ) {
      return (
        <React.Fragment>
          <div
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              let modalName = "paymentModal";
              set_modal_inputs({
                ...modal_inputs,
                [modalName]: !modal_inputs[modalName],
              });
              setrow(row);
            }}
          >
            {cell}
          </div>
        </React.Fragment>
      );
    }
    let tabNo =
      !_.isEmpty(cell) && cell.trim().toLowerCase() === "documentinforeport"
        ? 5
        : null;
    tabNo = !_.isEmpty(cell) && cell.trim().toLowerCase() === "pir" ? 1 : tabNo;
    if (tabNo !== null) {
      return (
        <Link
          className="link"
          to={"/property-manage/" + tabNo + "/" + row.property_id}
          target="_blank"
        >
          {cell}
        </Link>
      );
    }
    return cell;
  };

  const muni_reg_columns = [
    {
      dataField: "clientid",
      text: "Client ID",
      formatter: propertyLinkFormatter,
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "actionable_type",
      text: "Action Type",
      formatter: muni_reg_actionTypeFormatter,
      headerClasses: "header-cell-alignment",

      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      // sort: true,
      // onSort: (a, b) => {
      //   let obj = { [a]: b };
      //   set_form_inputs({ ...form_inputs, sort: obj });
      // },
    },
    {
      dataField: "description",
      text: "Description",
      headerClasses: "header-cell-alignment",
      //formatter: this.descriptionTypeFormatter,
      sort: true,

      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      dataField: "municipal_name",
      text: "Municipality",
      headerClasses: "header-cell-alignment",
      // formatter: this.haoMunicipalNameFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "creation_date",
      text: "Creation Date/Age",
      formatter: dateFormatter,
      headerClasses: "header-cell-alignment",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },

    {
      dataField: "current_status",
      text: "Current Status",
      headerClasses: "header-cell-alignment",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
  ];

  const paymentColumns = [
    {
      dataField: "clientid",
      text: "Client ID",
      formatter: linkFormatter,
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: paymentActionTypeFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "full_address",
      text: "Address",
      classes: "address-width",
      headerClasses: "table-header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
    },
    {
      dataField: "zip",
      text: "Zip",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "age",
      text: "Age in days",
      headerClasses: "table-header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      sort: true,
      onSort: (a, b) => {
        let obj = { age_in_days: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "last_updated",
      text: "Last Updated",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "due_date",
      text: "Due Date",
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
      formatter: dateFormatter,
    },
    {
      dataField: "current_status",
      text: "Status",
      //classes: "reduce-width",
      headerClasses: "table-header-cell-alignment",
      sort: true,
      onSort: (a, b) => {
        let obj = { [a]: b };
        set_form_inputs({ ...form_inputs, sort: obj });
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      classes: "notes-width",
      formatter: notesFormatter,
      headerClasses: "table-header-cell-alignment",
    },
  ];

  let tableColumns;
  if (tierOnefilter === "muni_verifications") {
    tableColumns = muni_verification_columns;
  } else if (tierOnefilter === "muni_registrations") {
    tableColumns = muni_reg_columns;
  } else if (tierOnefilter === "general_payment") {
    tableColumns = paymentColumns;
  }

  let properties =
    table_data &&
    table_data.map((x) => {
      const full_address = x.property
        ? [
            x.property && x.property.street_address,
            x.property.city,
            x.property.state,
          ].join(", ")
        : [
            x.physical_street_no_and_name,
            x.physical_city,
            x.physical_state,
          ].join(", ");
      let diff;
      if (x.created_at) diff = dayjs().diff(dayjs(x.created_at), "day");
      const age = diff && diff < 0 ? 0 : diff;
      let clientid = x.property ? x.property.clientid : "";
      let zip = x.property ? x.property.zip : x.physical_zip;
      return { ...x, full_address, age, clientid, zip };
    });

  let rowProperties;
  if (tierOnefilter === "general_payment") rowProperties = properties;
  else rowProperties = table_data;

  const filterTierOne = (response) => {
    settierOnefilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierTwo = (response) => {
    settierTwofilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  const filterTierThree = (response) => {
    settierThreefilter(response);
    setpageNumber();
    setperPage();
    set_form_inputs({ ...form_inputs, sort: {} });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      {toggleModal && (
        <>
          {dashboardModal && (
            <MunicipalPendingStatsDashboard
              filterTierOne={filterTierOne}
              filterTierTwo={filterTierTwo}
              filterTierThree={filterTierThree}
              perPage={perPage}
              pageNo={pageNumber}
              dashboardValue={dashboardValue}
              pendingDashboardFilter={pendingDashboardFilter}
              generalPaymentFields={generalPaymentFields}
              generalPaymentRightFields={generalPaymentRightFields}
            />
          )}
          <PaginationComponent
            columns={tableColumns}
            rows={rowProperties}
            handleSubmit={
              tierOnefilter === "general_payment"
                ? getDashboardPendingStats
                : getMunicipalDashboardStats
            }
            pageNo={pageNumber}
            perPage={perPage}
            totalCount={table_data && total}
          />
          {municipalModal && (
            <MunicipalModal
              title="Municipal"
              toggle={() => {
                setmunicipalModal(!municipalModal);
                getMunicipalDashboardStats();
              }}
              open={municipalModal}
              formdata={row}
              isDashboardMuni={true}
              states={states}
              muniID={municipalID}
            />
          )}
          {openmunicipalAction && (
            <MunicipalRequirementAction
              actionID={municipalID}
              propertyID={propertyID}
              open={openmunicipalAction}
              toggle={() => {
                setopenmunicipalAction(!openmunicipalAction);
                getMunicipalDashboardStats();
              }}
              formdata={row}
              title="Municipality Registration Action"
              isDisplayMunicipal={true}
            />
          )}
          {modal_inputs.paymentModal && (
            <PaymentModal
              toggle={() => {
                set_modal_inputs({
                  ...modal_inputs,
                  paymentModal: !modal_inputs.paymentModal,
                });
                getDashboardPendingStats();
              }}
              title="Payment"
              open={true}
              row={row}
              propertyID={row.property_id}
              actionID={row.actionable_id}
              dashboardProperty={true}
              userRole={props.userRole}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
