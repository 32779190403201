import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import PaginationComponent from "../../helper_components/PaginationComponent";
import { FaTrashAlt } from "react-icons/fa";
import ConfirmBox from "../../helper_components/ConfirmBox";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import _ from "lodash";
import config from "../../../utils/config";

export default (props) => {
  const [deleteModal, setdeleteModal] = useState(false);
  const [municipal_order_id, setmunicipal_order_id] = useState();
  const [order_id, setorder_id] = useState();
  const [client_municipal_order_id, setclient_municipal_order_id] = useState();
  const orderHistoryKeys = config.municipal_order_type;

  const cancelClientMunicipalOrder = (municipality_order_id, order_id) => {
    apicall
      .cancelClientMunicipalOrder(
        client_municipal_order_id,
        municipality_order_id,
        order_id
      )
      .then((resp) => {
        helpers.notify("Order Cancelled Successfully");
        props.getMunicipalOrderList();
      });
  };

  const cancelDateFormatter = (cell, row, rowIndex) => {
    let statusKey = orderHistoryKeys[row.order_type];
    if (row[statusKey] == "YES" && cell != null)
      return <div style={{ color: "red" }}>{helpers.formatDate(cell)}</div>;
    else if (row[statusKey] == "YES" && cell == null) {
      return (
        <div style={{ textAlign: "center", cursor: "pointer" }}>
          <FaTrashAlt
            size="1.4em"
            onClick={() => {
              setdeleteModal(true);
              setorder_id(row.orderid);
              setclient_municipal_order_id(row.client_municipal_order_id);
              setmunicipal_order_id(row.id);
            }}
          />
        </div>
      );
    }
  };
  const orderStatus = (cell, row, rowIndex, formatExtraData) => {
    let statusKey = orderHistoryKeys[cell];
    return `${row[statusKey]} (${helpers.formatDate(row.created_at)})`;
  };

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return helpers.formatDate(cell);
  };

  const dateFormatterReceivedDate = (cell, row ) => {
    if(row && row.file_urls && row.file_urls.length > 0) {
      return helpers.formatDate(cell);
    } else {
      return null;
    }
  };

  const constructTableWithData = () => {
    const columns = [
      {
        dataField: "clientid",
        text: "Client ID",
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          props.sortColumns(obj);
        },
      },
      {
        dataField: "orderid",
        text: "Order ID",
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          props.sortColumns(obj);
        },
      },
      {
        dataField: "vendor_name",
        text: "Vendor Name",
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          props.sortColumns(obj);
        },
      },

      {
        dataField: "order_type",
        text: "Order Type",
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        },
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          props.sortColumns(obj);
        },
      },

      {
        dataField: "order_type",
        text: "Order Status",
        formatter: orderStatus,
      },

      {
        dataField: "ordered_at",
        text: "Ordered Date",
        formatter: dateFormatter,
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          props.sortColumns(obj);
        },
      },
      {
        dataField: "order_received_date",
        text: "Order Received Date",
        formatter: dateFormatterReceivedDate,
        sort: true,
        onSort: (a, b) => {
          let obj = { [a]: b };
          props.sortColumns(obj);
        },
      },
      {
        dataField: "cancelled_at",
        text: "Cancelled Date",
        formatter: cancelDateFormatter,
      },
    ];
    return (
      <React.Fragment>
        <div style={{ width: "90%" }}>
          <PaginationComponent
            columns={columns}
            rows={props.orderList}
            handleSubmit={props.getMunicipalOrderList}
            pageNo={props.pageNo}
            perPage={props.perPage}
            totalCount={props.total}
          />
        </div>
        {deleteModal && (
          <ConfirmBox
            onYesClick={() => {
              cancelClientMunicipalOrder(municipal_order_id, order_id);
              setdeleteModal(false);
            }}
            onNoClick={() => setdeleteModal(false)}
            heading={
              <span>
                Are you sure you want to cancel the Municipal Order:
                {order_id} ?
              </span>
            }
          >
            <div>
              <p>
                Vendor cannot upload details against this order if you proceed
                with Yes.
              </p>
            </div>
          </ConfirmBox>
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {!_.isEmpty(props.orderList) && constructTableWithData()}
    </React.Fragment>
  );
};
