import React, { Component } from "react";
import ActionListTabComponent from "../../actions/ActionListTabComponent";
import LedgerListTable from "../../legder/LedgerListTable";
import ViolationsListTable from "../../violations/ViolationsListTable";
import Documents from "../../documents/Documents";
import "../../../styles/_tab.scss";
import apicall from "../../../utils/apicall";
import GeneralComponent from "../../general_info/GeneralComponent";
import TabsComponent from "../../helper_components/TabsComponent";
import PropertyInfo from "../../general_info/PropertyInfo";
import History from "../../history/History";
import Notes from "../../notes/Notes";
import { Link } from "react-router-dom";
import "../../../styles/_property-manage.scss";
import config from "../../../utils/config";
import Loader from "../../helper_components/LoaderComponent";
import CodeViolationComponent from "../../CodeViolation/CodeViolationComponent";
//import MunicipalOrders from "../../Title-MunicipalOrders/MunicipalitiesComponent";
import TitleMunicipalOrderTabs from "../../Title-MunicipalOrders/TitleMunicipalOrderTabs";
import LienAction from "../../LienActions/LienAction";

export default class PropertyManageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      actionArray: [],
      analystArray: [],
      propertyInfo: {},
      userRole: null,
      hoaID: null,
      loading: true,
    };
  }

  componentWillMount() {
    config.localforage
      .getItem("user_role")
      .then((role) => {
        this.setState({ userRole: role });
      })
      .catch((e) => console.log(e));
    config.localforage
      .getItem("search-text")
      .then((searchText) => {
        this.setState({ searchText: searchText });
      })
      .catch((e) => console.log(e));
    config.localforage
      .getItem("page-number")
      .then((pageNo) => {
        this.setState({ pageNo: pageNo });
      })
      .catch((e) => console.log(e));

    config.localforage
      .getItem("per-page")
      .then((perPage) => {
        this.setState({ perPage: perPage });
      })
      .catch((e) => console.log(e));

    if (this.props.match) {
      var propertyID = this.props.match.params.propertyID;
      if (typeof propertyID !== "undefined" && propertyID !== null) {
        this.setState({
          activeTab: this.props.match.params.activeTab,
          propertyID: propertyID,
        });
        this.getActions(propertyID);
        this.getPropertyInfo(propertyID);
        this.loadHoa(propertyID);
      }
      var hoaID = this.props.match.params.hoaID;
      this.setState({
        hoaID: hoaID,
      });
    }
  }

  getActions = (propertyID) => {
    apicall.listActions(propertyID).then((response) => {
      this.setState({
        actionArray: response,
      });
    });
  };

  getPropertyInfo = (propertyId) => {
    apicall.getProperty(propertyId).then((response) => {
      this.setState({
        propertyInfo: response,
        loading: false,
      });
    });
  };

  loadHoa = (propertyId) => {
    apicall.getHoaByPropertyID(propertyId, 1, 5).then((response) => {
      let masterHOA = null;
      if (response && response.total == 1) {
        masterHOA = response.results[0];
      } else if (response && response.total > 1) {
        masterHOA = response.results.find((x) => x.status === "Master");
      }
      // console.log("masterHOA",masterHOA);
      this.setState({
        masterHOA: masterHOA,
        loading: false,
      });
    });
  };

  updateMasterHoa = (masterHOA) => {
    this.setState({
      masterHOA: masterHOA,
    });
  };

  handleHistoryTab = () => {
    this.setState({ showHistory: true });
  };

  render() {
    if (this.state.activeTab != 7) {
      config.localforage
        .removeItem("history")
        .then(function () {})
        .catch(function (err) {
          console.log(err);
        });
      config.localforage
        .removeItem("historyCount")
        .then(function () {})
        .catch(function (err) {
          console.log(err);
        });
    }
    let tabsComponentsList = null;
    let listofTabs = null;
    if (!config["ViolationHide"].includes(this.state.userRole)) {
      listofTabs = [
        "General Information",
        "Title/Municipal Order",
        "Actions",
        // "Ledger",
        // "Violations",
        // "Lien",
        "Documents",
        "Notes",
      ];
      if (this.state.showHistory || this.state.activeTab == 6) {
        listofTabs.push("History");
      }
      tabsComponentsList = new Array(listofTabs.length).fill(null);
      tabsComponentsList[0] = this.state.userRole && (
        <GeneralComponent
          propertyID={this.state.propertyID}
          userRole={this.state.userRole}
          propertyInfo={this.state.propertyInfo}
          getPropertyInfo={this.getPropertyInfo}
          getActions={this.getActions}
          updateMasterHoa={this.updateMasterHoa}
        />
      );
      tabsComponentsList[1] = (
        <TitleMunicipalOrderTabs clientId={this.state.propertyInfo.clientid} />
      );
      tabsComponentsList[2] = (
        <ActionListTabComponent
          propertyID={this.state.propertyID}
          isHoaExist={this.state.propertyInfo.is_hoa}
          userRole={this.state.userRole}
          propertyInfo={this.state.propertyInfo}
        />
      );

      // tabsComponentsList[3] = (
      //   <LedgerListTable analystArray={this.state.analystArray} />
      // );
      // tabsComponentsList[3] = (
      //   // <ViolationsListTable propertyID={this.state.propertyID} />
      //   <CodeViolationComponent propertyID={this.state.propertyID} />
      // );
      // tabsComponentsList[3] = (
      //   <LienAction
      //     propertyID={this.state.propertyID}
      //     clientId={this.state.propertyInfo.clientid}
      //     getPropertyInfo={this.getPropertyInfo}
      //   />
      // );
      tabsComponentsList[3] = (
        <Documents
          analystArray={this.state.analystArray}
          propertyID={this.state.propertyID}
          userRole={this.state.userRole}
          hoaID={this.state.hoaID}
        />
      );
      tabsComponentsList[4] = <Notes propertyID={this.state.propertyID} />;
      tabsComponentsList[5] = <History propertyID={this.state.propertyID} />;
    } else {
      listofTabs = [
        "General Information",
        "Actions",
        // "Ledger",
        "Documents",
        "Notes",
      ];
      if (this.state.showHistory || this.state.activeTab === 4) {
        listofTabs.push("History");
      }
      tabsComponentsList = new Array(listofTabs.length).fill(null);
      tabsComponentsList[0] = this.state.userRole && (
        <GeneralComponent
          propertyID={this.state.propertyID}
          userRole={this.state.userRole}
          propertyInfo={this.state.propertyInfo}
          getPropertyInfo={this.getPropertyInfo}
          getActions={this.getActions}
          updateMasterHoa={this.updateMasterHoa}
        />
      );
      tabsComponentsList[1] = (
        <ActionListTabComponent
          propertyID={this.state.propertyID}
          isHoaExist={this.state.propertyInfo.is_hoa}
          userRole={this.state.userRole}
        />
      );
      // tabsComponentsList[2] = (
      //   <LedgerListTable analystArray={this.state.analystArray} />
      // );
      tabsComponentsList[2] = (
        <Documents
          analystArray={this.state.analystArray}
          propertyID={this.state.propertyID}
          userRole={this.state.userRole}
          hoaID={this.state.hoaID}
        />
      );
      tabsComponentsList[4] = <Notes propertyID={this.state.propertyID} />;
      tabsComponentsList[3] = <History propertyID={this.state.propertyID} />;
    }
    let urlList = tabsComponentsList.map((tabsComponent, index) => {
      return (
        "/property-manage/" +
        (index + 1) +
        "/" +
        this.state.propertyID +
        "/" +
        0
      );
    });
    // console.log("state----->",this.state.masterHOA)
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <section className="content">
          <header className="content-title property-manage-component">
            <button className="back">
              <Link
                className="button"
                to={
                  "/property-search/" +
                  this.state.searchText +
                  "/" +
                  this.state.pageNo +
                  "/" +
                  this.state.perPage
                }
              >
                Back
              </Link>
            </button>
            {/* <div className="page-title">
                                <h2 className='page-heading'><strong>Property Manage</strong></h2>
                            </div> */}

            <PropertyInfo
              propertyid={this.state.propertyID}
              propertyInfo={this.state.propertyInfo}
              userRole={this.state.userRole}
              className="details"
              actionArray={this.state.actionArray}
              listofTabs={listofTabs}
              handleHistoryTab={this.handleHistoryTab}
              masterHOA={this.state.masterHOA}
            />

            <div>
              <TabsComponent
                theme="tab"
                components={tabsComponentsList}
                listofTabs={listofTabs}
                generateTabURL={true}
                activeTab={this.state.activeTab}
                urlList={urlList}
              />
            </div>
          </header>
        </section>
      </React.Fragment>
    );
  }
}
