import React, { useState, useEffect } from "react";
import "../../styles/navbar.scss";
import "../../styles/content.css";
import ListMunicipalTable from "./ListMunicipalTable";
import apicall from "../../utils/apicall";
import Loader from "../helper_components/LoaderComponent";

export default function MunicipalitySearchComponent(props) {
  const [municipalities, setMunicipalities] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalCount, settotalCount] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [perPage, setperPage] = useState(10);
  const searchText = props.match.params.searchText;
  const user_role = props.match.params.user_role;
  const [form_inputs, set_form_inputs] = useState({
    sort: {},
  });

  useEffect(() => {
    searchMunicipalities();
  }, [form_inputs.sort]);

  const searchMunicipalities = (event, pageNo, perPage) => {
    if (typeof pageNo !== "undefined") {
      setpageNo(pageNo);
    }
    if (typeof perPage !== "undefined") {
      setperPage(perPage);
    }
    setloading(true);
    apicall
      .searchMunicipality(searchText, form_inputs, pageNo, perPage)
      .then((response) => {
        setMunicipalities(response.results);
        setloading(false);
        settotalCount(response.total);
      });
  };
  const sortColumns = (response) => {
    set_form_inputs({ ...form_inputs, sort: response });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <ListMunicipalTable
        municipalities={municipalities}
        refreshList={searchMunicipalities}
        pageNo={pageNo}
        perPage={perPage}
        totalCount={totalCount}
        userRole={user_role}
        sortColumns={sortColumns}
      />
    </React.Fragment>
  );
}
