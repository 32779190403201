import React, { Component } from "react";
import "../../styles/navbar.scss";
import resicap_logo from "../../images/resicap_aims_logo.png";
import avatar from "../../images/avatar.jpg";
import apicall from "../../utils/apicall";
import config from "../../utils/config";
import EditUserModal from "../analysts/EditUserModal";
import Select from "react-select";
import { Form, FormGroup, Col, Input, Button } from "reactstrap";
import helpers from "../../utils/helpers";
import _ from "lodash";
import PropertySearchComponent from "../properties/property-search/PropertySearchComponent";
import PropertySearchTable from "../properties/property-search/PropertySearchTable";
import TabsComponent from "../helper_components/TabsComponent";
import Reports from "../reports/Reports";
import { Link } from "react-router-dom";

export default class GuestUserNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      openModal: false,
      userid: "",
      mobile: "",
      selectedSearchOption: "",
      searchText: this.props.match.params.searchText,
      pageNo: 1,
    };
  }

  componentWillMount() {
    config.localforage.getItem("email").then((resp) => {
      this.setState({
        email: resp,
      });
    });
    config.localforage.getItem("userid").then((resp) => {
      this.setState({
        userid: resp,
      });
    });
    config.localforage.getItem("mobile").then((resp) => {
      this.setState({
        mobile: resp,
      });
    });
  }

  refereshData = (mobile) => {
    this.setState({
      mobile: mobile,
    });
  };

  toggleModal = () => {
    console.log("togllellllll");
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  handleChange = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.handleSubmitForSearch();
    }
  }

  handleSubmitForSearch = (event, pageNo, perPage) => {
    if (!_.isEmpty(this.state.searchText)) {
      this.props.history.push(
        "/guest-property-search/" + this.state.searchText
      );
    }
  };

  render() {
    let searchOptions = [];
    searchOptions.push(
      <option value="" key="defaultValue">
        Select Search Type
      </option>
    );
    for (let searchOption in this.state.option) {
      searchOptions.push(
        <option value={searchOption} key={searchOption}>
          {this.state.option[searchOption]}
        </option>
      );
    }

    let listofTabs = "";
    listofTabs = ["Reports", "Documents"];
    let tabsComponentsList = new Array(listofTabs.length).fill(null);
    tabsComponentsList[0] = (
      <Link className="link" to={"/reports"} target="_blank"></Link>
    );
    // <Link to={"/reports"} />;
    tabsComponentsList[1] = "";

    return (
      <React.Fragment>
        <header className="header" style={{ height: "90px" }}>
          <div
            className="header__logo hidden-sm-down header-logo"
            id="headerleft"
          >
            <img src={resicap_logo} alt="Resicap Logo" />
          </div>

          <div className="searchBar">
            {/* <Form onSubmit={this.handleSubmitForSearch}> */}
            <FormGroup row>
              <Col sm={10}>
                <Input
                  type="address"
                  name="address"
                  id="search"
                  placeholder=" Search"
                  onKeyUp={this.handleKeyPress.bind(this)}
                  onChange={this.handleChange}
                  value={this.state.searchText}
                />
              </Col>
              <Col sm={2}>
                <Button
                  className="search-btn"
                  onClick={this.handleSubmitForSearch}
                >
                  Search
                </Button>
              </Col>
            </FormGroup>
            {/* </Form> */}

            <div
              style={{
                marginTop: "-1rem",
                marginLeft: "4rem",
                display: "flex",
              }}
            >
              {this.props.isGuestUser  || this.props.isGuestUserReports? (
                <>
                
                <a
                    href={"/guest-documents/" + this.props.propertyID}
                    style={{ marginLeft: "10rem" }}
                  >
                    Documents
                  </a>
                  <a style ={{marginLeft : "30px"}} href={"/guest-reports/" + this.props.propertyID}>
                    Reports
                  </a>
                </>
              ) : (
                <> <a style ={{marginLeft : "30px"}} href={"/guest-reports" }>
                Reports
              </a>
                </>
              )}
              
            </div>
          </div>

          <div className="user-div">
            <div className="users-sub-div">
              <p>{this.state.email}</p>
              <p className="logout-btn" onClick={() => apicall.logout()}>
                Logout
              </p>
            </div>
            <div className="userimg">
              <img
                className="user__img"
                src={avatar}
                alt="Avatar "
                onClick={this.toggleModal}
              />
            </div>
          </div>
        </header>
        {this.state.openModal && (
          <EditUserModal
            toggle={this.toggleModal}
            modal={this.state.openModal}
            mobile={this.state.mobile}
            id={this.state.userid}
            refereshData={this.refereshData}
          />
        )}
      </React.Fragment>
    );
  }
}
