import React, { Component, useState, useEffect } from "react";
import "../../styles/navbar.scss";
import resicap_logo from "../../images/RESIAIMS.png";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import { toast } from "react-toastify";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import Select from "react-select";
import LoaderComponent from "../helper_components/LoaderComponent";
import _ from "lodash";

export default function IssueResolvedConfirmationPage(props) {
  const propertyID = props.match.params.propertyID;
  const codeViolationID = props.match.params.cv_id;
  const url_token = props.match.params.url_token;
  const notify = (errorMsg) => toast(errorMsg);
  const [row, setrow] = useState(props.row ? props.row : {});
  const [doc_type, setdoc_type] = useState([{ value: "Close out Photo", label: "Close out Photo" }]);
  const [hideTypeMessage, sethideTypeMessage] = useState(false);
  const [showCompletionNotification, setshowCompletionNotification] =
    useState(false);
  const [allowFileUpload, setallowFileUpload] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [showFirstLinkNotification, setshowFirstLinkNotification] =
    useState(false);

  useEffect(() => {
    readCodeViolation();
  }, []);

  useEffect(() => {
    // listDocumentType();
    let selectedValue = row;
    selectedValue["title_documents_attributes[][doc_type]"] ="Close out Photo";   
    setrow(Object.assign({ ...row }, { selectedValue }))
  }, []);

  const listDocumentType = () => {
    apicall.listConfirmationDocumentType(url_token).then((response) => {
      let arr = [];
      if (response) {
        arr = response.document_types.map((x) => {
          return { value: x, label: x };
        });
      }
      setdoc_type(arr);
    });
  };
  const readCodeViolation = () => {
    setisLoading(true);
    apicall
      .readConfirmationCodeViolation(
        props.match.params.cv_id,
        props.match.params.propertyID,
        url_token
      )
      .then((response) => {
        // if (_.isNull(response.confirmed)) {
        //   setshowFirstLinkNotification(true);
        // } else 
        if (
          !_.isNull(response.completion_notification_date) &&
          response.completion_notification_performed
        ) {
          completionNotificationValidation();
          // setshowCompletionNotification(true);
        } else {
          setallowFileUpload(true);
        }
        setisLoading(false);
      });
  };

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const completionNotificationValidation = () => {
    apicall
      .completionNotificationValidation(propertyID, codeViolationID, url_token)
      .then((response) => {
        if (response !== null && response.hasOwnProperty("error")) {
          notify(response.error);         
           setshowCompletionNotification(true);
           setallowFileUpload(false);
        } else {       
          helpers.notify("Thank You");
          setshowCompletionNotification(true);
          setallowFileUpload(false);
        }
      });
  };
  const handleSubmit = (event) => {
   
    if (_.includes(row, row.selectedValue)) delete row.selectedValue;
    if (_.isEmpty(row["title_documents_attributes[][doc_type]"])) {
      sethideTypeMessage(true);
    } else {
      console.log("came inside apicall");
      apicall
        .updateConfirmationCodeViolation(
          codeViolationID,
          propertyID,
          row,
          url_token
        )
        .then((response) => {
          if (response) {
            helpers.notify("Updated successfully");
            setrow(response);

            completionNotificationValidation();
          }
        });
    }
    event.preventDefault();
  };

  const formElements = () => {
    return (
      <div>
        <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
          <FormGroup>
            <Label for="">Type *</Label>
            <Select
              id={"title_documents_attributes[][doc_type]"}
              name="title_documents_attributes[][doc_type]"
              value={{
                value: doc_type[0].value,
                label: doc_type[0].label,
              }}
              onChange={(selectedType) => {
                sethideTypeMessage(false);
                let selectedValue = row;
                selectedValue["title_documents_attributes[][doc_type]"] =
                  selectedType.value;
                setrow(Object.assign({ ...row }, { selectedValue }));
              }}
              options={doc_type}
              // validators={["required", "isValidSelect"]}
              // errorMessages={["Document Type is required", "Name is required"]}
            />
            {hideTypeMessage ? (
              <b style={{ color: "red" }}>Please select Type</b>
            ) : (
              <></>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="">Document File Upload</Label>
            <Input
              type="file"
              name="file"
              id={"title_documents_attributes[][file]"}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <Button className={""} onSubmit={handleSubmit}>
            Submit
          </Button>
        </ValidatorForm>
      </div>
    );
  };

  return (
    <React.Fragment>
      <header className="header">
        <div
          className="header__logo hidden-sm-down header-logo"
          id="headerleft"
        >
          <img src={resicap_logo} alt="Resicap Logo" />
        </div>
      </header>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          {showCompletionNotification && (
            <section className="content" style={{ marginLeft: "0px" }}>
              <h1 style={{ textAlign: "center" }}>
                The issue is resolved. Thank you for your confirmation.
              </h1>
            </section>
          )}
          {/* {showFirstLinkNotification && (
            <section className="content" style={{ marginLeft: "0px" }}>
              <h1 style={{ textAlign: "center" }}>
                Kindly click on first link in the mail before proceeding with
                the second link
              </h1>
            </section>
          )} */}
          {allowFileUpload && (
            <section
              className="content"
              style={{ marginLeft: "30px", width: "50%" }}
            >
              {formElements()}
            </section>
          )}
        </>
      )}
    </React.Fragment>
  );
}
