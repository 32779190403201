import React, { useState, useEffect } from "react";
import {
  Button,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import "../../../styles/_titleReviewAction.scss";
import { ValidatorForm } from "react-form-validator-core";
import _ from "lodash";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import TextValidator from "../../helper_components/TextValidator";
import DatePicker from "react-datepicker";
import TitleReviewDocument from "../../documents/TitleReviewDocument";
import DocumentsTab from "../../documents/DocumentsTab";
import NotesHistory from "../../CodeViolation/NotesHistory";

export default function TitleReviewAction(props) {
  const [row, setrow] = useState({});
  const [nextQuestion, setnextQuestion] = useState(false);
  const [disableForm, setdisableForm] = useState(false);
  const [orderID, setorderID] = useState();
  const [secondSection, setsecondSection] = useState(false);
  const [titleReviewData, settitleReviewData] = useState({});
  const [openFileUpload, setopenFileUpload] = useState(false);
  const [orderDocuments, setorderDocuments] = useState();
  const [hoaOptions, sethoaOptions] = useState();
  const [isVendorFieldsEdited, setisVendorFieldsEdited] = useState(false);
  const [associationList, setassociationList] = useState([]);
  const [fileSection, setFileSection] = React.useState(false);

  useEffect(() => {
    getTitleReview();
    //fetchHoas();
  }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(titleReviewData) && titleReviewData.deed_available === false)
  //     setnextQuestion(true);
  // }, [titleReviewData]);

  const toggleDocument = () => {
    setopenFileUpload(!openFileUpload);
  };

  const fetchHoas = () => {
    apicall.getHoaByPropertyID(props.propertyID).then((response) => {
      let hoaNames = [];
      hoaNames = response.results.map((x) => {
        return { id: x.id, label: x.name };
        //return x.name;
      });

      sethoaOptions(hoaNames);
      console.log("-------", hoaOptions);
    });
  };

  const getTitleReview = () => {
    apicall.readTitleReview(props.actionID).then((response) => {
      if (response) {
        setrow(response);
        let title_order_id;
        if (!_.isEmpty(response.vendor_fields[0]))
          setorderDocuments(response.vendor_fields[0].title_order_documents);
        title_order_id = response.vendor_fields[0].title_order_id;
        settitleReviewData(
          Object.assign(
            {},
            ...response.title_review_orders.filter(
              (x) => x.title_order_id === title_order_id
            )
          )
        );
        //settitleReviewData(response.title_review_orders[0]);
        sethoaOptions(response.title_review_orders[0].governing_associations);
        if (
          _.isEmpty(
            response.vendor_fields[0].title_order_received &&
              response.vendor_fields[0].title_effective_date &&
              response.vendor_fields[0].transfer_deed_type &&
              response.vendor_fields[0].recording_date &&
              response.vendor_fields[0].number_of_parcels &&
              response.vendor_fields[0].apn_tax_parcel_ids &&
              response.vendor_fields[0].current_vesting_name &&
              response.vendor_fields[0].title_order_documents
          )
        ) {
          setsecondSection(false);
        } else {
          setsecondSection(true);
        }
        if (_.isEmpty(response.vendor_fields[0].title_order_received && response.vendor_fields[0].title_order_documents)) {
          setFileSection(false)
        } else {
          setFileSection(true)
        }
      }
    });
  };

  const handleSubmit = () => {
    if (isVendorFieldsEdited) {
      let data = row.vendor_fields.filter((x) => x.title_order_id === orderID);
      var requestObject = {
        vendor_field: {
          title_effective_date: data[0].title_effective_date,
          title_search_receive_date: data[0].title_search_receive_date,
          transfer_deed_type: data[0].transfer_deed_type,
          recording_date: data[0].recording_date,
          current_vesting_name: data[0].current_vesting_name,
          number_of_parcels: data[0].number_of_parcels,
          apn_tax_parcel_ids: data[0].apn_tax_parcel_ids,
        },
      };

      apicall.updateVendorFields(requestObject, orderID).then((response) => {
        helpers.notify("Title Vendor Fields Updated Successfully ");
        setTimeout(() => {}, 600);
      });
    }

    apicall
      .updateTitleReview(titleReviewData, props.actionID,row)
      .then((response) => {
        if (response) {
          helpers.notify("Updated successfully");
          setrow(response);
          props.toggle();
          props.refershActions();
        }
      });
  };

  const handleChange = (event) => {
    let targetName = event.target.name;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    settitleReviewData(
      Object.assign({ ...titleReviewData }, { [targetName]: value })
    );
  };

  const handleNotesChange= (event) => {
    let targetName = event.target.name;
    setrow(Object.assign({ ...row }, { [targetName]: event.target.value }));
  };

  const renderFormInputElements = (name, label, type, required) => {
    return (
      <div className="ip-fields">
        <label>{label}</label>
        {type === "textarea" ? (
          <Input
            type={type}
            name={name}
            id={name}
            onChange={handleChange}
            value={titleReviewData[name]}
            disabled={disableForm ? true : false}
          />
        ) : (
          <TextValidator
            type={type}
            name={name}
            id={name}
            onChange={handleChange}
            value={titleReviewData[name]}
            disabled={disableForm ? true : false}
            validators={["required"]}
            errorMessages={["Document Name is required"]}
          />
        )}
      </div>
    );
  };

  const handleRadioButton = (event, value) => {
    let targetName = event.target.name;

    settitleReviewData(
      Object.assign({ ...titleReviewData }, { [targetName]: value })
    );
  };

  const renderRadioInput = (label, name, disabled) => {
    return (
      <div className="question">
        <div className="text">{label}</div>
        <div className="radio">
          <CustomInput
            id={`${name}yes`}
            type="radio"
            name={name}
            value={true}
            onClick={(e) => handleRadioButton(e, true)}
            checked={titleReviewData[name] === true ? "checked" : ""}
            disabled={disabled}
          />
          <label>Yes</label>
          <CustomInput
            id={`${name}no`}
            type="radio"
            name={name}
            value={false}
            onClick={(e) => handleRadioButton(e, false)}
            checked={titleReviewData[name] === false ? "checked" : ""}
            disabled={disabled}
          />
          <label>No</label>
        </div>
      </div>
    );
  };

  const vendorFieldsTableRow = (list) => {
    return (
      <>
        {!_.isEmpty(list) &&
          list.map((ele, i) => {
            return (
              <tr>
                <td>
                  <Input
                    type="text"
                    name="title_order_id"
                    id="title_order_id"
                    value={ele.title_orderid}
                    onClick={() => {
                      settitleReviewData(
                        Object.assign(
                          {},
                          ...row.title_review_orders.filter(
                            (x) => x.title_order_id === ele.title_order_id
                          )
                        )
                      );

                      setorderID(ele.title_order_id);
                      let documents = row.vendor_fields.filter(
                        (x) => x.title_order_id === ele.title_order_id
                      );
                      setorderDocuments(documents[0].title_order_documents);

                      if (
                        _.isEmpty(
                          ele.title_order_received &&
                            ele.title_effective_date &&
                            ele.transfer_deed_type &&
                            ele.recording_date &&
                            ele.number_of_parcels &&
                            ele.apn_tax_parcel_ids &&
                            ele.current_vesting_name &&
                            documents[0].title_order_documents
                        )
                      ) {
                        setsecondSection(false);
                      } else {
                        setsecondSection(true);
                      }
                      if (_.isEmpty(ele.title_order_received && documents[0].title_order_documents)) {
                        setFileSection(false)
                      } else {
                        setFileSection(true)
                      }
                      sethoaOptions(titleReviewData.governing_associations);
                    }}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    name="title_order_date"
                    id="title_order_date"
                    value={
                      ele.title_order_date
                        ? helpers.formatDate(ele.title_order_date)
                        : null
                    }
                  />
                </td>

                <td>
                  <Input
                    type="text"
                    name="title_order_type"
                    id="title_order_type"
                    value={ele.title_order_type}
                    disabled={true}
                  />
                </td>

                <td>
                  <Input
                    type="text"
                    name="title_order_received"
                    id="title_order_received"
                    value={
                      ele.title_order_received
                        ? helpers.formatDate(ele.title_order_received)
                        : null
                    }
                    disabled={true}
                  />
                </td>

                <td>
                  <DatePicker
                      id="title_effective_date"
                      selected={
                        ele.title_effective_date
                        ? helpers.restructureDate(ele.title_effective_date)
                        : null
                      }
                      onChange={(event) => {
                        setorderID(ele.title_order_id);
                        setisVendorFieldsEdited(true);
                        let data = row.vendor_fields.filter((x) =>
                          x.title_order_id === ele.title_order_id
                            ? (x.title_effective_date = helpers.restructureDate(event))
                            : ""
                        );
                        setrow(Object.assign({ ...row }, { ...data }));
                      }}
                    />
                </td>

                <td>
                  <Input
                    type="text"
                    name="transfer_deed_type"
                    id="transfer_deed_type"
                    value={ele.transfer_deed_type}
                    onChange={(event) => {
                      setorderID(ele.title_order_id);
                      setisVendorFieldsEdited(true);
                      let data = row.vendor_fields.filter((x) =>
                        x.title_order_id === ele.title_order_id
                          ? (x.transfer_deed_type = event.target.value)
                          : ""
                      );
                      setrow(Object.assign({ ...row }, { ...data }));
                    }}
                  />
                </td>

                <td>
                  <DatePicker
                      id="recording_date"
                      selected={
                        ele.recording_date
                        ? helpers.restructureDate(ele.recording_date)
                        : null
                      }
                      onChange={(event) => {
                        setorderID(ele.title_order_id);
                        setisVendorFieldsEdited(true);
                        let data = row.vendor_fields.filter((x) =>
                          x.title_order_id === ele.title_order_id
                            ? (x.recording_date = helpers.restructureDate(event))
                            : ""
                        );
                        setrow(Object.assign({ ...row }, { ...data }));
                      }}
                    />
                </td>
                <td>
                  <Input
                    type="text"
                    name="number_of_parcels"
                    id="number_of_parcels"
                    value={ele.number_of_parcels}
                    onChange={(event) => {
                      setorderID(ele.title_order_id);
                      setisVendorFieldsEdited(true);
                      let data = row.vendor_fields.filter((x) =>
                        x.title_order_id === ele.title_order_id
                          ? (x.number_of_parcels = event.target.value)
                          : ""
                      );
                      setrow(Object.assign({ ...row }, { ...data }));
                    }}
                  />
                </td>

                <td>
                  <Input
                    type="text"
                    name="apn_tax_parcel_ids"
                    id="apn_tax_parcel_ids"
                    value={ele.apn_tax_parcel_ids}
                    onChange={(event) => {
                      setorderID(ele.title_order_id);
                      setisVendorFieldsEdited(true);
                      let data = row.vendor_fields.filter((x) =>
                        x.title_order_id === ele.title_order_id
                          ? (x.apn_tax_parcel_ids = event.target.value)
                          : ""
                      );
                      setrow(Object.assign({ ...row }, { ...data }));
                    }}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    name="current_vesting_name"
                    id="current_vesting_name"
                    value={ele.current_vesting_name}
                    onChange={(event) => {
                      setorderID(ele.title_order_id);
                      setisVendorFieldsEdited(true);
                      let data = row.vendor_fields.filter((x) =>
                        x.title_order_id === ele.title_order_id
                          ? (x.current_vesting_name = event.target.value)
                          : ""
                      );
                      setrow(Object.assign({ ...row }, { ...data }));
                    }}
                  />
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  const vendorFields = () => {
    return (
      <>
        {!_.isEmpty(row) && (
          <div>
            <table className="reviewActionTable">
              <thead>
                <tr>
                  <th style={{ width: "11%" }}>Order ID</th>
                  <th style={{ width: "8%" }}>Order Date</th>
                  <th style={{ width: "15%" }}>Order Type</th>
                  <th style={{ width: "8%" }}>Order Received</th>
                  <th style={{ width: "8%" }}>Title Effective</th>
                  <th>Transfer Deed Type</th>
                  <th style={{ width: "8%" }}>Transfer Deed Recorded</th>
                  <th># of Parcels</th>
                  <th>Tax Parcel ID</th>
                  <th>Vesting Name</th>
                </tr>
              </thead>
              <tbody>{vendorFieldsTableRow(row.vendor_fields)}</tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  const listAssociationNames = (hoaNames) => {
    return (
      <>
        {!_.isEmpty(hoaNames) &&
          hoaNames.map((name, i) => {
            if (i !== 0) {
              return (
                <div className="titleReview-code-radio">
                  <div></div>
                  {renderRadioInput(
                    `Is the association name {${name.hoa_name}} `,
                    `association_name_available${i}`
                  )}
                </div>
              );
            }
          })}
      </>
    );
  };
  const displayTitleDocuments = (documents) => {
    return (
      <>
        {!_.isEmpty(documents) &&
          documents.map((fileNames) => {
            return (
              <div style={{ marginLeft: "40px" }}>
                <a
                  href={fileNames.file_url}
                  style={{ float: "none", color: "blue" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fileNames.file_name}
                </a>
              </div>
            );
          })}
      </>
    );
  };

  const formElements = () => {
    return (
      <>
        {!_.isEmpty(titleReviewData) && (
          <>
            {/* <div className="titleReview-documents-div">
              {displayTitleDocuments(orderDocuments)}
            </div> */}
            <div className="titleReview-code-radio">
              {" "}
              {renderRadioInput(
                "Do the legal descriptions on chain of title documents match?",
                "legal_description_available",
                nextQuestion
              )}
            </div>
            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Do the vesting chains match?",
                "vesting_chain_available",
                nextQuestion
              )}
            </div>
            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Is the chain for the foreclosed mortgage correct?",
                "foreclosed_mortgage_available",
                nextQuestion
              )}
            </div>
            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Are there multiple mortgages on the title to be cleared?",
                "mortgages_need_clearance",
                nextQuestion
              )}
              {titleReviewData.mortgages_need_clearance === true && (
                <>
                  {" "}
                  {renderRadioInput(
                    "Are the mortgages out by foreclosure?",
                    "mortgages_out_by_foreclosure"
                  )}
                </>
              )}
            </div>
            <div className="titleReview-code-radio">
              <div></div>
              {titleReviewData.mortgages_need_clearance === true && (
                <>
                  {renderRadioInput(
                    "Is there a title policy?",
                    "title_policy_available"
                  )}
                </>
              )}
            </div>

            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Are there delinquent taxes on the title?",
                "delinquent_taxes_available"
              )}
            </div>

            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Does the title reveal a mobile home?",
                "reveal_mobile_home"
              )}
            </div>

            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Does the title reveal a governing association?",
                "governing_association_available",
                nextQuestion
              )}
              {titleReviewData.governing_association_available === true &&
                !_.isEmpty(hoaOptions) && (
                  <>
                    {" "}
                    {renderRadioInput(
                      `Is the association name {${hoaOptions[0].hoa_name}} `,
                      "association_name_available"
                    )}
                  </>
                )}
            </div>
            {titleReviewData.governing_association_available === true && (
              <>{listAssociationNames(hoaOptions)}</>
            )}

            <div className="titleReview-code-radio">
              {renderRadioInput(
                "Are there liens on the property?",
                "property_lien_available"
              )}
              {titleReviewData.property_lien_available === true && (
                <div>
                  {" "}
                  <Button
                    onClick={() => {
                      setopenFileUpload(true);
                    }}
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                  >
                    File Upload
                  </Button>
                </div>
              )}
            </div>

            <div className="titleReview-code-radio">
              {renderRadioInput("Other Issues?", "other_issues_available")}
              {titleReviewData.other_issues_available === true ? (
                <>
                  {renderFormInputElements(
                    "other_issues",
                    "Enter Other Issues",
                    "textarea"
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <div className="status">
              <Button
                type="button"
                id="submit"
                onClick={() => handleSubmit(true)}
                disabled={
                  titleReviewData.municipal_liens_available === true &&
                  _.isEmpty(titleReviewData.municipal_no_of_liens)
                    ? true
                    : false
                }
              >
                Submit
              </Button>
            </div> */}
            <div>
              <DocumentsTab
                // id={props.formdata.id}
                // issueID={requirementTypeID}
                documents={titleReviewData.title_review_order_document}
                isTitleReviewAction={true}
                // deleteDocument={deleteDocument}
                theme={"general-info-tab"}
              />
            </div>
          </>
        )}
      </>
    );
  };
  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Title Review</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            <h6 style={{ fontWeight: "bold" }}>Vendor Fields</h6>{" "}
            {vendorFields()}
            <hr />
            {fileSection &&
              <div className="titleReview-documents-div" >
                {displayTitleDocuments(orderDocuments)}
              </div>
            }
            {secondSection && formElements()}
            <div className="status">
              <Button
                type="button"
                id="submit"
                onClick={() => handleSubmit(true)}
                disabled={
                  titleReviewData.municipal_liens_available === true &&
                  _.isEmpty(titleReviewData.municipal_no_of_liens)
                    ? true
                    : false
                }
              >
                Submit
              </Button>
            </div>
            <FormGroup row>
                <Label for="" sm={2}>Notes</Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="title_review_notes"
                    id="title_review_notes"
                    placeholder="Notes"
                    onChange={handleNotesChange}
                  // value={row.code_violation_notes}
                  />
                </Col>
              </FormGroup>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal className="title-modal" isOpen={props.open} toggle={props.toggle}>
        {renderModal()}
        <NotesHistory notes={row.notes ? row.notes : []} />
      </Modal>
      {openFileUpload && (
        <TitleReviewDocument
          toggle={toggleDocument}
          title="Add Document"
          open={openFileUpload}
          row={titleReviewData}
          handleSubmit={handleSubmit}
          actionID={props.actionID}
          propertyID={props.propertyID}
          fetchDocuments={getTitleReview}
          rowId={titleReviewData.id}
          isTitleReviewAction={true}
        />
      )}
    </div>
  );
}
