import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var addmunicipalityapi = {
  addmunicipality: async (municipalityobj) => {
    let url = `${config.baseUrl}/api/v1/municipalities`;
    let headers;
    let response;
    if (_.isEmpty(municipalityobj.municipality.muni_type))
      municipalityobj.municipality.muni_type = "County";
    headers = await helpers.defaultHeaders();
    await Axios.post(url, municipalityobj, { headers: headers }).then(
      (resp) => {
        response = resp;
      }
    );
    return response.data;
  },

  updatemunicipality: async (data, municipalityId) => {
    let url = `${config.baseUrl}/api/v1/municipalities/` + municipalityId;
    let headers;
    let response;
    if (_.isEmpty(data.municipality.muni_type))
      data.municipality.muni_type = "County";
    headers = await helpers.defaultHeaders();
    await Axios.put(url, data, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  getAllMunicipalities: async (data, pageNo, perPage) => {
    let params = {};
    let url = `${config.baseUrl}/api/v1/municipalities?`;
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

    if (data.sort) params["column_order"] = Object.values(data.sort)[0];

    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");

    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  readMunicipal: async function (id) {
    let url = `${config.baseUrl}/api/v1/municipalities/${id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  deleteMunicipalPOC: async function (id, poc_id, ofc_id) {
    let url = `${config.baseUrl}/api/v1/municipalities/${id}/destroy_title_poc?title_point_of_contact_id=${poc_id}&ofc_id=${ofc_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  deleteMunicipal: async function (id) {
    let url = `${config.baseUrl}/api/v1/municipalities/${id}/`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  listMunicipalityName: async function (searchKeyword, propertyID) {
    let url =
      config.baseUrl +
      `/api/v1/municipalities/state_munis_search?q=${searchKeyword}&property_id=${propertyID}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  getMunicipalByPropertyID: async function (propertyID) {
    let url = config.baseUrl + "/api/v1/properties/" + propertyID + "/munis";

    let municipal = [];
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong, while get hoa" + response);
      })
      .then((responseJson) => {
        municipal = responseJson;
      })
      .catch(function (error) {});
    return municipal;
  },
  deleteMunicipalOffice: async function (id, ofc_id) {
    let url = `${config.baseUrl}/api/v1/municipalities/${id}/destroy_muni_office?ofc_id=${ofc_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.status;
  },
  municipalityRequirementUploadDocuments: async function (
    data,
    municipalID,
    muni_req_id
  ) {
    let url = `${config.baseUrl}/api/v1/municipalities/${municipalID}/add_muni_requirement_doc?muni_req_id=${muni_req_id}`;
    let headers;
    let response;
    headers = await helpers.titleFileHeaders();
    var form = new FormData();

    for (var key in data) {
      form.append("municipality_requirement[" + key + "]", data[key]);
    }
    await Axios.post(url, form, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
  removeMunicipalAtPropertyLevel: async function (municipalID, propertyId) {
    var res = "";
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      propertyId +
      "/delete_muni?muni_id=" +
      municipalID;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while deleting municipal" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  searchMunicipality: async function (searchKeyword, data, pageNo, perPage) {
    let url = config.baseUrl + `/api/v1/municipalities/search?`;
    let params = {};
    if (typeof searchKeyword !== "undefined" && searchKeyword !== null) {
      params["q"] = searchKeyword;
    }
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  deleteMuniRequirementDocument: async function (
    actionID,
    documentId,
    muni_req_id
  ) {
    let url = `${config.baseUrl}/api/v1/municipalities/${actionID}/delete_muni_requirement_document/?document_id=${documentId}&muni_req_id=${muni_req_id}`;
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.delete(url, { headers: headers }).then((resp) => {
      response = resp;
    });
    return response.data;
  },
};
export default withRouter(addmunicipalityapi);
