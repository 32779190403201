import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import apicall from "../../utils/apicall";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import { toast } from "react-toastify";
import "../../styles/_management.scss";
import config from "../../utils/config";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import _ from "lodash";
import helpers from "../../utils/helpers";
import AddressComponent from "./AddressComponent";
import NewAssessment from "./NewAssessment";
class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      analysts: [],
      managementCompany: this.props.managementCompany
        ? this.props.managementCompany
        : {},
      pocArray: this.props.pocArray ? this.props.pocArray : [],
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  getManagementCompany = () => {
    apicall
      .getManagementCompany(this.state.managementCompany.id)
      .then((resp) => {
        this.setState({
          pocArray: resp.point_of_contacts,
        });
      });
  };

  handleDeleteEvent = (event, poc) => {
    this.state.managementCompany.id &&
      apicall
        .deleteManagementPoc(this.state.managementCompany.id, poc.id)
        .then((resp) => {
          this.getManagementCompany();
          helpers.notify("POC deleted");
        });
  };

  handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    var poc = {
      id: id,
      name: "",
      title: "",
      email: "",
      phone: "",
      ext: "",
      status: "temp",
    };
    this.state.pocArray.push(poc);
    this.setState(this.state.pocArray);
  };

  handlePocChange = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var pocArray = this.state.pocArray.slice();
    var newPocArray = pocArray.map((poc, index) => {
      for (var key in poc) {
        if (key == item.name && poc.id == item.id) {
          if (key === "phone") {
            let text = evt.target.value;
            var cleaned = ("" + text).replace(/\D/g, "");
            const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
            if (match) {
              cleaned = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
                match[3] ? "-" : ""
              }${match[3]}`;
            }
            poc["phone"] = cleaned;
          } else {
            poc[key] = item.value;
          }
        }
      }
      return poc;
    });

    this.setState({ pocArray: newPocArray });
  };

  toggleModal = () => {
    this.state.toggle();
  };

  handleChange = (event, format_zip) => {
    let value = event.target.value;
    let targetID = event.target.id;
    // console.log("MC handle change", value, targetID)
    if (format_zip && value.length > 5) {
      var len = value.length;
      var res;
      if (value.substring(5, 6) != "-") {
        res = value.substring(0, 5) + "-" + value.substring(5, len);
      } else {
        res = value;
      }
      this.setState({
        managementCompany: Object.assign(this.state.managementCompany, {
          [targetID]: res,
        }),
      });
    } else {
      this.setState({
        managementCompany: Object.assign(this.state.managementCompany, {
          [targetID]: value,
        }),
      });
    }
  };

  notify = (errorMsg) => toast(errorMsg);

  getSelectionValue = (obj) => {
    if (obj === Object(obj)) return obj.value;
    else return obj;
  };

  handleSubmit = (event) => {
    var managementCompany = this.state.managementCompany;
    let pocArray = this.state.pocArray;
    //remove temporary Id'
    for (let index in pocArray) {
      if (_.has(pocArray[index], "status")) {
        delete pocArray[index]["id"];
        delete pocArray[index]["status"];
      }
    }
    managementCompany.point_of_contacts_attributes = pocArray;
    if (managementCompany.id) {
      apicall.updateManagementCompany(managementCompany).then((response) => {
        if (response) {
          this.notify("Updated successfully.");
          this.toggleModal();
        }
      });
    } else {
      apicall.createManagementCompany(managementCompany).then((response) => {
        if (response) {
          this.notify("Added successfully.");
          this.toggleModal();
        }
      });
    }
    event.preventDefault();
  };
  handleManagementFees = (data) =>{
   console.log("dattat", data)
   this.setState({
    managementCompany: Object.assign(this.state.managementCompany, {
      one_time_fees_attributes: data,
    }),
  });
  }
  managementCompanyForm = () => {
    const addressTypes = config.addressTypes;
    ValidatorForm.addValidationRule("isZipcodeMatch", (value) => {
      var zipCodePattern = /^\d{5}$|^\d{5}-\d{1,4}$/;
      return zipCodePattern.test(value);
    });

    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });
    let pocArray = this.state.pocArray;
    let submitDisabled = false;
    if (_.isEmpty(this.state.pocArray)) {
      submitDisabled = true;
    }
    return (
      <div>
        <ValidatorForm onSubmit={this.handleSubmit} autoComplete="off">
          <div className="add-management-company">
            <div className="add-model-left-portion">
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Name *
                </Label>
                <Col sm={10}>
                  <TextValidator
                    //disabled={this.state.managementCompany.id ? true : false}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Name is required"]}
                    value={this.state.managementCompany.name}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Account
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="text"
                    name="account"
                    id="account"
                    placeholder="Account"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.account}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Fax
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="text"
                    name="contact_fax"
                    id="contact_fax"
                    placeholder="Fax"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.contact_fax}
                  />
                </Col>
              </FormGroup>
              {Object.keys(addressTypes).map((addressType, index) => {
                // console.log('addressTypes', addressTypes)
                return (
                  <AddressComponent
                    key={index + "address"}
                    type={addressType}
                    handleChange={this.handleChange}
                    managementCompany={this.state.managementCompany}
                    heading={addressTypes[addressType]}
                    form="mc"
                  />
                );
              })}

              {/* <FormGroup>
                                <Label for="">Mailing street name</Label>
                                <TextValidator type="text" name="mailing_street_name" id="mailing_street_name" placeholder="Mailing street name" onChange={this.handleChange}
                                    value={this.state.managementCompany.mailing_street_name} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="">Mailing city</Label>
                                <TextValidator type="text" name="mailing_city" id="mailing_city" placeholder="Mailing city" onChange={this.handleChange}
                                    value={this.state.managementCompany.mailing_city} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="">Mailing state</Label>
                                <TextValidator type="text" name="mailing_state" id="mailing_state" placeholder="Mailing state" onChange={this.handleChange}
                                    value={this.state.managementCompany.mailing_state} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="">Mailing zip</Label>
                                <TextValidator type="text" name="mailing_zip" id="mailing_zip" placeholder="Mailing zip" onChange={this.handleChange}
                                    value={this.state.managementCompany.mailing_zip} />
                            </FormGroup> */}
              {/* <FormGroup>
                                <Label for="">Physical street</Label>
                                <TextValidator type="text" name="physical_street_no_and_name" id="physical_street_no_and_name" placeholder="Physical street" onChange={this.handleChange}
                                    value={this.state.managementCompany.physical_street_no_and_name} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="">Physical city</Label>
                                <TextValidator type="text" name="physical_city" id="physical_city" placeholder="Physical city" onChange={this.handleChange}
                                    value={this.state.managementCompany.physical_city} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="">Physical state</Label>
                                <TextValidator type="text" name="physical_state" id="physical_state" placeholder="Physical state" onChange={this.handleChange}
                                    value={this.state.managementCompany.physical_state} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="">Physical zip</Label>
                                <TextValidator type="text" name="physical_zip" id="physical_zip" placeholder="Physical zip" onChange={this.handleChange}
                                    value={this.state.managementCompany.physical_zip} />
                            </FormGroup> */}
              <FormGroup row style={{ marginTop: "1rem" }}>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Notes
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="textarea"
                    name="information"
                    id="information"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.information}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  EIN/TaxID
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="text"
                    name="ein_taxid"
                    id="ein_taxid"
                    placeholder="EIN/TaxID"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.ein_taxid}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Website
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="text"
                    name="website"
                    id="website"
                    placeholder="Website"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.website}
                  />
                </Col>
              </FormGroup>
              <Input
                type="text"
                id="website_password"
                style={{ display: "none" }}
                value={this.state.managementCompany.website_username}
              ></Input>
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Website username
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="text"
                    name="website_username"
                    id="website_username"
                    placeholder="Website username"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.website_username}
                    autoComplete="new-username"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  sm={2}
                  for=""
                  style={{ fontWeight: "bold", textAlign: "end" }}
                >
                  Website password
                </Label>
                <Col sm={10}>
                  {" "}
                  <TextValidator
                    type="text"
                    name="website_password"
                    id="website_password"
                    placeholder="Website password"
                    onChange={this.handleChange}
                    value={this.state.managementCompany.website_password}
                    autoComplete="new-password"
                  />
                </Col>
              </FormGroup>
              <div>
                <p style={{fontWeight: "700"}}>One Time Fee</p>
                <NewAssessment hoaNames ={this.state.managementCompany.hoas} handleManagementFees ={this.handleManagementFees} one_time_fees_rows={this.state.managementCompany.one_time_fees} />
              </div>
            </div>
            <div className="add-model-right-portion">
              <div>
                <div className="note">
                  <span style={{ fontWeight: "bold" }}>Note:</span> Atleat one
                  POC must be present to Create Management Company
                </div>
                {pocArray &&
                  pocArray.map((poc, index) => {
                    return <div>{this.pocModel(poc, index)}</div>;
                  })}
              </div>
              <div className="add-poc" onClick={this.handleAddEvent}>
                <span className="text">Add POC</span>
                <FaPlus size="1.5em" />
              </div>
              <Button disabled={submitDisabled ? true : false}>Submit</Button>
            </div>
          </div>
        </ValidatorForm>
      </div>
    );
  };

  pocModel = (poc, index) => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];
    var phoneValidators = [];
    var phoneValidatorMessage = [];
    let pocTitleOptions = [];
    let PocTitles = config.McPocTitles;
    pocTitleOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    let titleIndex = 0;
    for (var key in PocTitles) {
      if (PocTitles.hasOwnProperty(key)) {
        pocTitleOptions.push(
          <option value={key} key={PocTitles[key] + titleIndex}>
            {PocTitles[key]}
          </option>
        );
      }
      titleIndex = titleIndex + 1;
    }

    if (
      (poc["email"] == null || poc["email"] === "") &&
      (poc["phone"] == null || poc["phone"] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID",
      ];
    } else if (poc["phone"] != null && poc["phone"] !== "") {
      phoneValidators = ["isUSAPhoneNumMatch"];
      phoneValidatorMessage = ["Invalid phone number"];
    }
    let pocDiv = (
      <>
        <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
          {"Point of Contact "}
        </div>
        <div className="poc-style">
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Name
            </Label>
            <Col sm={9}>
              <TextValidator
                type="text"
                name="name"
                id={poc.id}
                placeholder=""
                onChange={this.handlePocChange}
                value={poc.name}
              />
            </Col>
            <FaTrashAlt
              style={{ float: "right", cursor: "pointer" }}
              size="1.5em"
              onClick={(event) => this.handleDeleteEvent(event, poc)}
            />
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                id={poc.id}
                name="title"
                value={_.kebabCase(poc.title)}
                onChange={this.handlePocChange}
              >
                {pocTitleOptions}
              </Input>
              {/* <span className="fa fa-caret-down customDropdownIcon"></span> */}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="email"
                id={poc.id}
                placeholder=""
                onChange={this.handlePocChange}
                validators={emailValidators}
                errorMessages={emailValidatorMessage}
                value={poc.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "0rem" }}>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Phone
            </Label>
            <Col sm={5}>
              <TextValidator
                type="text"
                name="phone"
                id={poc.id}
                placeholder=""
                onChange={this.handlePocChange}
                validators={phoneValidators}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
                maxLength="14"
              />
            </Col>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Ext
            </Label>
            <Col sm={3}>
              <TextValidator
                type="text"
                name="ext"
                id={poc.id}
                placeholder=""
                onChange={this.handlePocChange}
                value={poc.ext}
              />
            </Col>
          </FormGroup>
        </div>
      </>
    );
    return pocDiv;
  };

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">
              {this.state.managementCompany.id
                ? "Update management company"
                : "Add management company"}
            </span>
          </div>
        </ModalHeader>
        <ModalBody>{this.managementCompanyForm()}</ModalBody>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="form-modal"
          isOpen={this.state.open}
          toggle={this.toggleModal}
        >
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}

export default FormComponent;
