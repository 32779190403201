import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Button,
} from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import Select from "react-select";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import config from "../../../utils/config";
import { toast } from "react-toastify";

export default function TitleNewOrderModal(props) {
  const [vendor_names, set_vendor_names] = useState([]);

  const [form_inputs, set_form_inputs] = useState({
    vendor_code: "",
    order_type: "",
  });

  useEffect(() => {
    getTitleVendor_names();
  }, []);

  const order_type = Object.keys(config.title_order_type).reduce(
    (array, key) => {
      return [...array, { label: key, value: config.title_order_type[key] }];
    },
    []
  );

  const getTitleVendor_names = () => {
    apicall.listTitleVendorOrder().then((response) => {
      let vendorNameArray = [];
      vendorNameArray = response.map((x) => {
        return { value: x.short_code, label: x.name };
      });
      set_vendor_names(vendorNameArray);
    });
  };

  const notify = (errorMsg) => toast(errorMsg);

  const handleSubmit = (event) => {
    var requestObject = {
      client_vendor_order: {
        clientid: props.clientId,
        vendor_code: form_inputs.vendor_code,
        title_orders_attributes: [{ [form_inputs.order_type]: "YES" }],
      },
    };
    apicall
      .createVendorOrder(requestObject)
      .then((response) => {
        if (response) {
          helpers.notify("Order placed successfully");
          props.toggle();
          props.getVendorOrderList();
        }
      })
      .catch((e) => {
        let errorMsg = helpers.getErrorMsg(e.response.data.errors);
        if (errorMsg) notify(errorMsg);
      });
    event.preventDefault();
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Upload Title Order</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit}>
            <FormGroup row>
              <Label for="" sm={3}>
                Vendor
              </Label>
              <Col sm={8}>
                <Select
                  options={vendor_names ? vendor_names : []}
                  onChange={(value) => {
                    set_form_inputs({
                      ...form_inputs,
                      vendor_code: value.value,
                    });
                  }}
                  className="states-select"
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="" sm={3}>
                Order Type
              </Label>
              <Col sm={8}>
                <Select
                  options={order_type ? order_type : []}
                  onChange={(value) => {
                    set_form_inputs({
                      ...form_inputs,
                      order_type: value.value,
                    });
                  }}
                  className="states-select"
                />
              </Col>
            </FormGroup>
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };
  return (
    <div>
      <Modal
        className="title-order-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
