import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
var leaseapi = {
  getLeaseInfo: async function (hoa_id) {
    var leaseInfo = [];
    let url =
      config.baseUrl +
      "/api/v1/leasing_informations?hoa_id=" +
      hoa_id;
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error(
          "Something went wrong, while getting access codes" + response
        );
      })
      .then((responseJson) => {
        leaseInfo = responseJson;
        return responseJson.json();
      })
      .catch(function (error) {});

    return leaseInfo;
  },
  createLeaseInfo: async function (leaseInfo, hoa_id) {
    let url =
      config.baseUrl +
      "/api/v1/leasing_informations?hoa_id=" +
      hoa_id;
    let form = new FormData();
    for (var key in leaseInfo) {
      if (!Array.isArray(leaseInfo[key])) {
        if (leaseInfo[key] === null) {
          leaseInfo[key] = "";
        }
        form.append("leasing_information[" + key + "]", leaseInfo[key]);
      }
    }
    let init = {
      method: "POST",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  updateleaseInfo: async function (leaseInfo, hoa_id, leaseInfoId) {
    let url =
      config.baseUrl +
      "/api/v1/leasing_informations/" +
      leaseInfoId +
      "?hoa_id=" +
      hoa_id;
    let form = new FormData();
    for (var key in leaseInfo) {
      if (!Array.isArray(leaseInfo[key])) {
        if (leaseInfo[key] === null) {
          leaseInfo[key] = "";
        }
        form.append("leasing_information[" + key + "]", leaseInfo[key]);
      }
    }
    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  uploadLeaseInfoDoc: async function (leaseInfo, hoa_id, leaseInfoId) {
    // let reqObj = {...leaseInfo,...documents};
    // console.log(reqObj);
    let res = "";
    let url =
      config.baseUrl +
      "/api/v1/leasing_informations/" +
      leaseInfoId +
      "/add_documents?hoa_id=" +
      hoa_id;
    var form = new FormData();
    for (var key in leaseInfo) {
      console.log("the key is", key);
      if (_.has(config.leasingMap, key))
        form.append("leasing_information[" + key + "]", leaseInfo[key]);
    }
    // console.log(form);
    var init = {
      method: "POST",
      headers: await helpers.leaseInfoHeaders(),
      body: form,
    };

    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.clone().json();
        }
        throw new Error(
          "Something went wrong, while Property File Upload" + response
        );
      })
      .then((responseJson) => {
        res = responseJson;
      })
      .catch(function (error) {});
    return res;
  },
  deleteLeaseInfo: async function (leaseInfoId, hoa_id) {
    var res = "";
    let url =
      config.baseUrl +
      "/api/v1/leasing_informations/" +
      leaseInfoId +
      "?hoa_id=" +
      hoa_id;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while deleting lease info" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  deleteLeaseInfoDoc: async function (hoa_id, leaseInfoId, documentId) {
    // console.log(propertyID+" "+leaseInfoId+" "+documentId)
    let url = `${config.baseUrl}/api/v1/leasing_informations/${leaseInfoId}/delete_documents?hoa_id=${hoa_id}&document_id=${documentId}`;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((res) => res.json);
  },
};
export default withRouter(leaseapi);
