import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_municipalModal.scss";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import _ from "lodash";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import config from "../../utils/config";
import "../../styles/_codeViolation.scss";
import SelectOptions from "../helper_components/SelectOptions";
import AddressComponent from "./MunicipalAddressComponent";
import NotesHistory from "../CodeViolation/NotesHistory";

export default function AddOfficeModal(props) {
  const [formdata, setformdata] = useState(
    props.formdata ? props.formdata : {}
  );
  const [officeData, setofficeData] = useState(
    props.officeData ? props.officeData : {}
  );
  const [pocArray, setpocArray] = useState(
    props.officeData !== undefined &&
      !_.isEmpty(props.officeData.title_point_of_contacts)
      ? props.officeData.title_point_of_contacts
      : []
  );
  const [officeOptions, setofficeOptions] = useState({
    Office1: "Office1",
    Office2: "Office2",
  });
  const readMunicipal = (id) => {
    apicall.readMunicipal(id).then((response) => {
      if (response) setpocArray(response.title_point_of_contacts);
    });
  };

  const validateForm = () => {
    let isValid = true;
    if (/^[a-zA-Z]+$/.test(formdata.state)) {
    }
    return isValid;
  };

  const officeType = [];
  officeType.push(
    <option value="" key="defaultValue">
      Select
    </option>
  );
  for (let type in officeOptions) {
    officeType.push(
      <option key={type} value={type}>
        {officeOptions[type]}
      </option>
    );
  }

  const handleNotesChange = (event) => {
    let targetName = event.target.name;
    setofficeData(Object.assign({ ...officeData }, {[targetName]: event.target.value }));
  };

  const handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      let res = helpers.formatZip(value);
      setofficeData(Object.assign({ ...officeData }, { [targetID]: res }));
    } else {
      setofficeData(Object.assign({ ...officeData }, { [targetID]: value }));
    }
  };

  const handleSubmit = () => {
    let pocdata = pocArray;
    for (let index in pocdata) {
      if (_.has(pocdata[index], "status")) {
        delete pocdata[index]["id"];
        delete pocdata[index]["status"];
      }
    }

    const dataObject = Object.assign(
      { ...officeData },
      { title_point_of_contacts_attributes: pocdata }
    );
    let arr = [];
    arr.push(dataObject);
    const data = { municipality: formdata };
    data.municipality.offices_attributes = arr;

    if (validateForm) {
      if (formdata.id) {
        apicall.updatemunicipality(data, formdata.id).then((response) => {
          helpers.notify("Municipality updated Successfully");
          setTimeout(() => {
            props.toggle();
            props.readMunicipal(response.id);
          }, 600);
        });
      } else {
        apicall.addmunicipality(data).then((response) => {
          helpers.notify("Municipality Created Successfully");
          setTimeout(() => {
            props.toggle();
            props.readMunicipal(response.id);
          }, 600);
        });
      }
    }
  };

  const handlePocChange = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    //var pocArray = pocArray.slice();

    var newPocArray = pocArray.map((poc, index) => {
      for (var key in poc) {
        if (key == item.name && poc.id == item.id) {
          if (key === "phone") {
            let phone = helpers.formatPhone(item.value);
            poc[key] = phone;
          } else poc[key] = item.value;
        }
      }
      return poc;
    });

    setpocArray(newPocArray);
  };

  const handleDeleteEvent = (event, poc) => {
    formdata.id &&
      apicall
        .deleteMunicipalPOC(formdata.id, poc.id, officeData.id)
        .then((resp) => {
          helpers.notify("POC deleted");
        });
    setTimeout(() => readMunicipal(formdata.id), 3000);
  };

  const handleAddEvent = (evt) => {
    var id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    var poc = {
      id: id,
      name: "",
      title: "",
      email: "",
      phone: "",
      phn_ext: "",
      status: "temp",
    };

    setpocArray([...pocArray, poc]);
  };
  const pocModel = (poc, index) => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", (value) => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];
    var phoneValidators = [];
    var phoneValidatorMessage = [];
    let pocTitleOptions = [];
    let PocTitles = config.municipalOfficePocTitles;
    pocTitleOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    let titleIndex = 0;
    for (var key in PocTitles) {
      if (PocTitles.hasOwnProperty(key)) {
        pocTitleOptions.push(
          <option value={key} key={PocTitles[key] + titleIndex}>
            {PocTitles[key]}
          </option>
        );
      }
      titleIndex = titleIndex + 1;
    }

    if (
      (poc["email"] == null || poc["email"] === "") &&
      (poc["phone"] == null || poc["phone"] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID",
      ];
    } else if (poc["phone"] != null && poc["phone"] !== "") {
      phoneValidators = ["isUSAPhoneNumMatch"];
      phoneValidatorMessage = ["Invalid phone number"];
    }
    let pocDiv = (
      <>
        <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
          {"Point Of Contact"}
        </div>
        <div className="poc-style">
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Name
            </Label>
            <Col sm={9}>
              <TextValidator
                type="text"
                name="name"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={poc.name}
              />
            </Col>
            <FaTrashAlt
              style={{ float: "right", cursor: "pointer" }}
              size="1.5em"
              onClick={(event) => handleDeleteEvent(event, poc)}
            />
          </FormGroup>
          <FormGroup row>
            <Label sm={2} style={{ fontWeight: "bold", textAlign: "end" }}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                id={poc.id}
                name="title"
                //value={_.kebabCase(poc.title)}
                value={poc.title}
                onChange={handlePocChange}
              >
                {pocTitleOptions}
              </Input>
              {/* <span
                className="fa fa-caret-down customDropdownIcon-addmunicipality"
                style={{
                  float: "right",
                  marginTop: "-29px",
                  paddingRight: "15px",
                }}
              ></span> */}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="email"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={emailValidators}
                errorMessages={emailValidatorMessage}
                value={poc.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginBottom: "0rem" }}>
            {/* <Label
              for=""
              sm={2}
              style={{ fontWeight: "bold", textAlign: "end" }}
            >
              Phone
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name="phone"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={["required"]}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
                maxLength="14"
              />
            </Col> */}
            <Label
              sm={2}
              for=""
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              Phone{" "}
            </Label>
            <Col sm={5}>
              <TextValidator
                type="text"
                name="phone"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                validators={["required"]}
                errorMessages={phoneValidatorMessage}
                value={poc.phone}
                maxLength="14"
              />
            </Col>
            <Label
              sm={2}
              for=""
              style={{ textAlign: "end", fontWeight: "bold" }}
            >
              Ext{" "}
            </Label>
            <Col sm={3}>
              <TextValidator
                type="text"
                name="phn_ext"
                id={poc.id}
                placeholder=""
                onChange={handlePocChange}
                value={poc.phn_ext}
                maxLength="14"
              />
            </Col>
          </FormGroup>
        </div>
      </>
    );
    return pocDiv;
  };

  const formElements = () => {
    return (
      <>
        <div className="add-municipality">
          <div className="add-model-left-portion">
            <FormGroup row>
              <Label for="" sm={2}>
                Office
              </Label>
              <Col sm={10} style={{ paddingRight: "10px" }}>
                <Input
                  type="select"
                  name="name"
                  id="name"
                  onChange={handleChange}
                  value={officeData.name}
                >
                  <SelectOptions options={config.municipal_office} />
                </Input>
                {/* <span className="fa fa-caret-down customDropdownIcon-codeviolation"></span> */}
              </Col>
            </FormGroup>
            {/* <FormGroup row>
              <Label for="" sm={2}>
                C/o Name
              </Label>
              <Col sm={10} style={{ paddingRight: "10px" }}>
                <TextValidator
                  type="text"
                  name="co_name"
                  id="co_name"
                  placeholder="C/o Name"
                  onChange={handleChange}
                  value={officeData.co_name}
                />
              </Col>
            </FormGroup> */}
            <FormGroup row>
              <Label for="" sm={2}>
                Address
              </Label>
              <Col sm={10} style={{ paddingRight: "10px" }}>
                <TextValidator
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  validators={["required"]}
                  errorMessages={["Mailing street name is required"]}
                  onChange={handleChange}
                  value={officeData.address}
                />
              </Col>
            </FormGroup>
            {/* <div>
              <Row form>
                <Col md={7}> */}
            <FormGroup row>
              <Label for="" sm={2}>
                City
              </Label>
              <Col sm={5} style={{ paddingRight: "0px" }}>
                <TextValidator
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  validators={["required"]}
                  errorMessages={["Mailing city is required"]}
                  onChange={handleChange}
                  value={officeData.city}
                />
              </Col>
              {/* </FormGroup>
                </Col> */}
              {/* <Col md={2}>
                  <FormGroup row> */}
              <Label for="" sm={1}>
                State
              </Label>
              <Col sm={1} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                <TextValidator
                  type="text"
                  name="state"
                  id="state"
                  // maxLength="2"
                  placeholder="State"
                  validators={["required"]}
                  errorMessages={["Mailing state is required"]}
                  onChange={handleChange}
                  value={officeData.state}
                />
              </Col>
              {/* </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup row> */}
              <Label for="" sm={1}>
                Zip
              </Label>
              <Col sm={2} style={{ paddingRight: "10px", paddingLeft: "0px" }}>
                <TextValidator
                  type="text"
                  name="zip"
                  id="zip"
                  placeholder="XXXXX-XXXX"
                  maxLength="10"
                  validators={["required", "isAddressZipcodeMatch"]}
                  errorMessages={["zip is required", "Invalid Zip Code"]}
                  onChange={(event) => handleChange(event, true)}
                  value={officeData.zip}
                />
              </Col>
            </FormGroup>
            {/* </Col>
              </Row>
            </div> */}
            <AddressComponent
              handleChange={handleChange}
              heading={"Payee and Payment Mailing Address"}
              row={officeData}
            />
            <FormGroup row style= {{marginTop: "20px"}}>
              <Label for="" sm={2}>Notes</Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="office_notes"
                  id="office_notes"
                  placeholder="Notes"
                  onChange={handleNotesChange}
                />
              </Col>
            </FormGroup>
            <NotesHistory notes={ officeData.notes ? officeData.notes : null } addOffice={true} />
          </div>
          <div className="add-model-right-portion">
            <div>
              {pocArray &&
                pocArray.map((poc, index) => {
                  return <div>{pocModel(poc, index)}</div>;
                })}
            </div>
            <div className="add-poc" onClick={handleAddEvent}>
              <span className="text">Add POC</span>
              <FaPlus size="1.5em" />
            </div>
            <Button onClick={() => handleSubmit()}>Submit</Button>
          </div>
        </div>
      </>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <Modal
          isOpen={props.open}
          toggle={props.toggle}
          className="municipal-add-modal"
        >
          <ModalHeader toggle={props.toggle}>
            <div className="">
              <span className="heading"> Office Details</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
              {formElements()}
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
}
