import React, { useState, useEffect } from "react";
import { FormGroup, Row, Label, CustomInput } from "reactstrap";
import apicall from "../../utils/apicall";
import DatePicker from "react-datepicker";
import HoaAsyncSelect from "../helper_components/HoaAsyncSelect";
import _ from "lodash";
import "../../styles/_dashboard.scss";
import Select from "react-select";
import config from "../../utils/config";
import { FaCaretSquareDown, FaCaretSquareUp } from "react-icons/fa";
import MunicipalDashboardComponent from "./MunicipalDashboardComponent";
import TitleDashboardComponent from "./TitleDashboardComponent";
import AssociationDashboard from "./AssociationDashboard";

export default (props) => {
  const [form_inputs, set_form_inputs] = useState({
    action_type: "",
    start_date: "",
    end_date: "",
    page_no: 1,
    states: [],
    property_groups: [],
    entity_names: [],
    sort: {},
  });
  const [states, set_States] = useState();
  const [modal_inputs, set_modal_inputs] = useState({
    associationverificationModal: false,
    lienModal: false,
    inspectionModal: false,
    violationModal: false,
    paymentModal: false,
    associationinformationModal: false,
  });
  const [entity_names, set_entity_names] = useState([]);
  const [property_groups, set_property_groups] = useState([]);
  const [associationdashboardModal, setassociationdashboardModal] = useState(
    false
  );
  const [expandCollapseFilter, setexpandCollapseFilter] = useState(false);
  const [userRole, setuserRole] = useState("");
  const [municipaldashboardModal, setmunicipaldashboardModal] = useState(false);
  const [titledashboardModal, settitledashboardModal] = useState(false);
  const [dashboardoption, setdashboardoption] = useState([
    { value: "Association", label: "Association" },
    { value: "Municipality", label: "Municipality" },
    { value: "Title", label: "Title" },
  ]);
  const [dashboard_scope, setdashboard_scope] = useState([]);

  const [selectedDashboardOption, setselectedDashboardOption] = useState();
  const [isSelectBoxDisabled, setisSelectBoxDisabled] = useState(true);
  const [managerRadioButton, setmanagerRadioButton] = useState("manager");

  useEffect(() => {
    let userName;
    config.localforage
      .getItem("user_role")
      .then((role) => {
        if (role === "admin") {
          setisSelectBoxDisabled(false);
          setselectedDashboardOption("Association");
          setassociationdashboardModal(true);
        }
        userName = role;
        setuserRole(role);
      })
      .catch((e) => console.log(e));
    config.localforage
      .getItem("dashboard_scope")
      .then((data) => {
        setdashboard_scope(data.split(","));
        savedDashboard(userName, data.split(","));
      })
      .catch((e) => console.log(e));
  }, []);

  const savedDashboard = (role, dashboard_data) => {
    let data = config.title_dashboard_scope;

    if (
      !_.isEmpty(dashboard_data) &&
      _.includes(dashboard_data, "Association")
    ) {
      setselectedDashboardOption("Association");
      setmunicipaldashboardModal(false);
      settitledashboardModal(false);
      setassociationdashboardModal(true);
      if (
        _.includes(dashboard_data, "Municipality") &&
        _.some(dashboard_data, (el) => _.includes(data, el))
      ) {
        setisSelectBoxDisabled(false);
      } else if (_.includes(dashboard_data, "Municipality")) {
        setisSelectBoxDisabled(false);
        setdashboardoption([
          { value: "Association", label: "Association" },
          { value: "Municipality", label: "Municipality" },
        ]);
      } else if (_.some(dashboard_data, (el) => _.includes(data, el))) {
        setisSelectBoxDisabled(false);
        setdashboardoption([
          { value: "Association", label: "Association" },
          { value: "Title", label: "Title" },
        ]);
      }
    } else if (
      !_.isEmpty(dashboard_data) &&
      _.includes(dashboard_data, "Municipality")
    ) {
      setmunicipaldashboardModal(true);
      settitledashboardModal(false);
      setassociationdashboardModal(false);
      setselectedDashboardOption("Municipality");
      if (
        _.some(dashboard_data, (el) => _.includes(data, el)) &&
        _.includes(dashboard_data, "Association")
      ) {
        setisSelectBoxDisabled(false);
      } else if (_.some(dashboard_data, (el) => _.includes(data, el))) {
        setisSelectBoxDisabled(false);
        setdashboardoption([
          { value: "Municipality", label: "Municipality" },
          { value: "Title", label: "Title" },
        ]);
      } else if (_.includes(dashboard_data, "Association")) {
        setisSelectBoxDisabled(false);
        setdashboardoption([
          { value: "Association", label: "Association" },
          { value: "Municipality", label: "Municipality" },
        ]);
      }
    } else if (
      !_.isEmpty(dashboard_data) &&
      _.some(dashboard_data, (el) => _.includes(data, el))
    ) {
      setselectedDashboardOption("Title");
      setmunicipaldashboardModal(false);
      settitledashboardModal(true);
      setassociationdashboardModal(false);
      if (
        _.includes(dashboard_data, "Municipality") &&
        _.includes(dashboard_data, "Association")
      ) {
        setisSelectBoxDisabled(false);
      } else if (_.includes(dashboard_data, "Municipality")) {
        setisSelectBoxDisabled(false);
        setdashboardoption([
          { value: "Municipality", label: "Municipality" },
          { value: "Title", label: "Title" },
        ]);
      } else if (_.includes(dashboard_data, "Association")) {
        setisSelectBoxDisabled(false);
        setdashboardoption([
          { value: "Association", label: "Association" },
          { value: "Title", label: "Title" },
        ]);
      }
    }
  };

  useEffect(() => getEntity_names(), []);
  useEffect(() => getProperty_groups(), []);

  const getEntity_names = () => {
    apicall.getEntity_names().then((response) => {
      let statesArray = [];
      statesArray = response.results.map((x) => {
        return { value: x, label: x };
      });
      set_entity_names(statesArray);
    });
  };

  const getProperty_groups = () => {
    apicall.getProperty_groups().then((response) => {
      let statesArray = [];
      statesArray = response.results.map((x) => {
        return { value: x, label: x };
      });
      set_property_groups(statesArray);
    });
  };

  useEffect(() => {
    getStates();
  }, []);

  const getStates = () => {
    apicall.getStates().then((response) => {
      let statesArray = [];
      statesArray = response.results.states.map((x) => {
        return { value: x, label: x };
      });
      set_States(statesArray);
    });
  };

  const ActionTypes = [
    "AssociationVerification",
    "DocumentInfoReport",
    "Inspection",
    "Lien",
    "Payment",
    "Pir",
    "Violation",
  ];
  let typeActions = [];
  typeActions.push(
    <option value="" key="SelectAction">
      Select Action Type
    </option>
  );
  for (let type in ActionTypes) {
    typeActions.push(
      <option key={ActionTypes[type]} value={ActionTypes[type]}>
        {ActionTypes[type]}
      </option>
    );
  }

  const handleDashboardOptionChange = (event, key) => {
    if (key === true) setselectedDashboardOption(event.label);

    if (event.label === "Association") {
      setmunicipaldashboardModal(false);
      settitledashboardModal(false);
      setassociationdashboardModal(true);
    } else if (event.label === "Municipality") {
      setmunicipaldashboardModal(true);
      settitledashboardModal(false);
      setassociationdashboardModal(false);
    } else if (event.label === "Title") {
      setmunicipaldashboardModal(false);
      settitledashboardModal(true);
      setassociationdashboardModal(false);
    }
  };

  return (
    <React.Fragment>
      {expandCollapseFilter && (
        <Row
          className="form"
          style={{
            borderBottom: "1px solid lightblue",
            width: "100%",
          }}
        >
          <FormGroup
            style={{
              width: "10%",
              marginRight: "1rem",
              marginTop: "1rem",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Start Date</h6>
            <DatePicker
              id="from_date"
              selected={
                form_inputs.start_date && form_inputs.start_date !== ""
                  ? new Date(form_inputs.start_date + "")
                  : null
              }
              onChange={(date) => {
                set_form_inputs({
                  ...form_inputs,
                  start_date: date,
                  page_no: 1,
                });
              }}
            />
          </FormGroup>
          <FormGroup
            style={{
              width: "10%",
              marginRight: "1rem",
              marginTop: "1rem",
            }}
          >
            <h6 style={{ textAlign: "center" }}>End Date</h6>
            <DatePicker
              id="to_date"
              selected={
                form_inputs.end_date && form_inputs.end_date !== ""
                  ? new Date(form_inputs.end_date + "")
                  : null
              }
              minDate={
                form_inputs.start_date && form_inputs.start_date !== ""
                  ? new Date(form_inputs.start_date + "")
                  : null
              }
              onChange={(date) => {
                set_form_inputs({
                  ...form_inputs,
                  end_date: date,
                  page_no: 1,
                });
              }}
            />
          </FormGroup>

          <FormGroup
            style={{
              width: "15%",
              marginRight: "1rem",
              marginTop: "2rem",
            }}
          >
            <HoaAsyncSelect
              callapi={apicall.searchAnalysts}
              handleOnChange={(x, y) => {
                set_form_inputs({ ...form_inputs, analyst: y, page_no: 1 });
              }}
              valueShown={form_inputs.analyst}
              placeholder="Search Analyst..."
              isClearable={true}
            />
          </FormGroup>
          <FormGroup
            style={{
              width: "17%",
              marginRight: "1rem",
              marginTop: "2rem",
            }}
          >
            <Select
              options={states ? states : []}
              isMulti={true}
              onChange={(value) => {
                set_form_inputs({ ...form_inputs, states: value });
              }}
              placeholder="Select States..."
              className="states-select"
            />
          </FormGroup>
          <FormGroup
            style={{
              width: "20%",
              marginRight: "1rem",
              marginTop: "2rem",
            }}
          >
            <Select
              options={entity_names ? entity_names : []}
              isMulti={true}
              onChange={(value) => {
                set_form_inputs({
                  ...form_inputs,
                  entity_names: value,
                });
              }}
              placeholder="Select Entity..."
              className="states-select"
            />
          </FormGroup>

          <FormGroup
            style={{
              width: "20%",
              marginRight: "1rem",
              marginTop: "2rem",
            }}
          >
            <Select
              options={property_groups ? property_groups : []}
              isMulti={true}
              onChange={(value) => {
                set_form_inputs({
                  ...form_inputs,
                  property_groups: value,
                });
              }}
              placeholder="Select Property Group..."
              className="states-select"
            />
          </FormGroup>
        </Row>
      )}

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div
          style={{
            display: "flex",
            width: "88%",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          {"Dashboard"}

          <div
            style={{
              width: "15%",
              marginLeft: "15px",
              fontWeight: "400",
              fontSize: "1rem",
            }}
          >
            <Select
              options={dashboardoption}
              placeholder="Search Type"
              onChange={(event) => handleDashboardOptionChange(event, true)}
              value={{
                value: selectedDashboardOption,
                label: selectedDashboardOption,
              }}
              isDisabled={isSelectBoxDisabled}
            />
          </div>
          {userRole === "manager" && selectedDashboardOption !== "Association" && (
            <div
              style={{
                width: "15%",
                marginLeft: "15px",
                marginTop: "10px",
                fontWeight: "400",
                fontSize: "1rem",
              }}
            >
              <FormGroup style={{ display: "flex" }}>
                <CustomInput
                  id="manager"
                  type="radio"
                  name="manager"
                  checked={managerRadioButton === "manager" ? "checked" : ""}
                  onClick={() => setmanagerRadioButton("manager")}
                  value="manager"
                />
                <Label style={{ marginRight: "2%" }}>Manager</Label>
                <CustomInput
                  id="user"
                  type="radio"
                  name="user"
                  checked={managerRadioButton === "user" ? "checked" : ""}
                  onClick={() => setmanagerRadioButton("user")}
                  value="user"
                />
                <Label style={{ marginRight: "2%" }}>User</Label>
              </FormGroup>
            </div>
          )}
        </div>
        <div
          style={{ textAlign: "end", cursor: "pointer" }}
          onClick={() => setexpandCollapseFilter(!expandCollapseFilter)}
        >
          {expandCollapseFilter ? "Hide Filter" : "Expand Filter"}
          {expandCollapseFilter ? (
            <FaCaretSquareUp
              style={{
                fontSize: "35px",
                float: "right",
                marginLeft: "5px",
              }}
              onClick={() => setexpandCollapseFilter(!expandCollapseFilter)}
            />
          ) : (
            <FaCaretSquareDown
              style={{
                fontSize: "35px",
                float: "right",
                marginLeft: "5px",
              }}
              onClick={() => setexpandCollapseFilter(!expandCollapseFilter)}
            />
          )}
        </div>
      </div>
      {associationdashboardModal && (
        <AssociationDashboard
          userRole={userRole}
          dashboard_scope={dashboard_scope}
        />
      )}
      {municipaldashboardModal && (
        <MunicipalDashboardComponent
          userRole={userRole}
          dashboard_scope={dashboard_scope}
          manager_user={managerRadioButton}
        />
      )}
      {titledashboardModal && (
        <TitleDashboardComponent
          userRole={userRole}
          dashboard_scope={dashboard_scope}
          manager_user={managerRadioButton}
        />
      )}
    </React.Fragment>
  );
};
