import config from "../config";
import { withRouter } from "react-router";
import helpers from "../helpers";
import _ from "lodash";
import Axios from "axios";
var hoaapi = {
  getHoa: async function (id) {
    let url = config.baseUrl + "/api/v1/hoas/" + id;
    let hoa = {};
    var init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong, getting hoa" + response);
      })
      .then((hoaJson) => {
        hoa = hoaJson;
      })
      .catch(function (error) {});
    return hoa;
  },

  updateHoa: async function (hoa) {
    let url = config.baseUrl + "/api/v1/hoas/" + hoa.id;
    let form = new FormData();
    for (var key in hoa) {
      if (!Array.isArray(hoa[key]) && hoa[key] !== null) {
        if (key === "management_company") {
          form.append("hoa[" + key + "]", hoa[key]["id"]);
        } else if (
          key === "management_company_id" &&
          hoa[key] === Object(hoa[key])
        ) {
          if (!_.isEmpty(hoa[key].value)) {
            form.append("hoa[" + key + "]", hoa[key].value);
          }
        } else {
          form.append("hoa[" + key + "]", hoa[key]);
        }
      }
    }
    console.log("The hoa is", form);
    let init = {
      method: "PUT",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
    // var init = {
    //   method: "PUT",
    //   headers: await helpers.defaultHeaders(),
    //   body: JSON.stringify({ hoa: hoa }),
    // };
    // await fetch(url, init)
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(
    //       "Something went wrong, while getting analyst" + response
    //     );
    //   })
    //   .then((a) => {
    //     res = a;
    //   })
    //   .catch(function (error) {});
    // return res;
  },
  createHoa: async function (hoa) {
    var res = "";
    let url = config.baseUrl + "/api/v1/hoas";
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ hoa: hoa }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  deleteHoa: async function (id) {
    var res = "";
    let url = config.baseUrl + "/api/v1/hoas/" + id;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Something went wrong, while getting analyst" + response
        );
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  hoaCheck: async function (hoaCheckArray, clientId, actionId) {
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      clientId +
      "/hoa_check?action_id=" +
      actionId;

    var requestObject = {
      hoa_checks: hoaCheckArray,
    };
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(requestObject),
    };
    var hoaCheckResponse = null;
    await fetch(url, init)
      .then((response) => {
        return response.json();
      })
      .then((responsejson) => {
        hoaCheckResponse = responsejson;
      })
      .catch(function (error) {});
    return hoaCheckResponse;
  },
  getHoas: async function (data, pageNo, perPage) {
    var analysts = [];
    let url = config.baseUrl + "/api/v1/hoas?";
    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  searchHoa: async function (
    searchText,
    pageNo,
    isMultiSelect,
    perPage,
    data,
    propertyID
  ) {
    var url;
    if (propertyID) {
      url =
        config.baseUrl +
        `/api/v1/properties/${propertyID}/hoa_search?q=` +
        searchText;
    } else {
      if (!isMultiSelect) {
        url = config.baseUrl + "/api/v1/hoas/search?q=" + searchText;
      } else {
        url =
          config.baseUrl +
          "/api/v1/hoas/search?select_box=true&q=" +
          searchText;
      }
    }

    let params = {};
    if (pageNo) params["page"] = pageNo;
    if (perPage) params["per_page"] = perPage;
    if (!_.isEmpty(data)) {
      if (data.sort) params["sort_column"] = Object.keys(data.sort)[0];

      if (data.sort) params["column_order"] = Object.values(data.sort)[0];
    }
    let query = Object.keys(params)
      .filter((k) => typeof params[k] !== "undefined")
      .map((k) => {
        return k + "=" + encodeURIComponent(params[k]);
      })
      .join("&");
    let headers;
    let response;
    headers = await helpers.defaultHeaders();
    await Axios.get(url + "&" + query, { headers: headers }).then((resp) => {
      response = resp.data;
    });
    return response;
  },
  generatePaymentActions: async function (hoaID) {
    let url = config.baseUrl + `/api/v1/hoas/${hoaID}/create_payment_action`;
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  removeHOA: async function (hoaID, propertyId) {
    var res = "";
    let url =
      config.baseUrl +
      "/api/v1/properties/" +
      propertyId +
      "/delete_hoa?hoa_id=" +
      hoaID;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong, while deleting hoa" + response);
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  getHoaByPropertyID: async function (propertyID, pageNo, perPage) {
    let url = config.baseUrl + "/api/v1/properties/" + propertyID + "/hoas";
    if (pageNo) {
      url = url + "?page=" + pageNo;
    }
    if (perPage) url = `${url}&per_page=${perPage}`;

    let hoas = [];
    let init = {
      method: "GET",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong, while get hoa" + response);
      })
      .then((responseJson) => {
        hoas = responseJson;
      })
      .catch(function (error) {});
    return hoas;
  },
  deleteFyassessment: async function (hoaID, assessment_id) {
    let url =
      config.baseUrl +
      "/api/v1/hoas/" +
      hoaID +
      "/destroy_assessment?assessment_id=" +
      assessment_id;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  updateAccount: async function (
    propertyID,
    hoaID,
    account_number,
    website_address,
    payment_website,
    username,
    password,
    assessment_type,
    payment_website_username,
    payment_website_password,
    chimney_last_inspection_date,
    dryer_last_inspection_date,
    hvac_last_inspection_date,
    fire_last_inspection_date
  ) {
    //var res = "";
    let url = `${config.baseUrl}/api/v1/properties/${propertyID}/update_account_assessment_details`;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({
        id: propertyID,
        hoa_id: hoaID,
        account_number: account_number,
        website_address: website_address,
        payment_website: payment_website,
        username: username,
        password: password,
        assessment_type_id: assessment_type,
        payment_username: payment_website_username,
        payment_password: payment_website_password,
        chimney_last_inspection_date: helpers.formatApiDate(
          chimney_last_inspection_date
        ),
        dryer_last_inspection_date: helpers.formatApiDate(
          dryer_last_inspection_date
        ),
        hvac_last_inspection_date: helpers.formatApiDate(
          hvac_last_inspection_date
        ),
        fire_last_inspection_date: helpers.formatApiDate(
          fire_last_inspection_date
        ),
      }),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  updatelast_inspection_dates: async function (
    propertyID,
    hoaID,
    chimney_last_inspection_date,
    dryer_last_inspection_date,
    hvac_last_inspection_date,
    fire_last_inspection_date
  ) {
    //var res = "";
    let url = `${config.baseUrl}/api/v1/properties/${propertyID}/update_last_inspection_dates`;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({
        id: propertyID,
        hoa_id: hoaID,
        chimney_last_inspection_date: chimney_last_inspection_date,
        dryer_last_inspection_date: dryer_last_inspection_date,
        hvac_last_inspection_date: hvac_last_inspection_date,
        fire_last_inspection_date: fire_last_inspection_date,
      }),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  // createAssessmentType: async function(hoaID, name) {
  //   let url = `${config.baseUrl}/api/v1/assessment_types`;
  //   var init = {
  //     method: "POST",
  //     headers: await helpers.defaultHeaders(),
  //     body: JSON.stringify({ 'hoa_id': hoaID, 'name': name })
  //   };
  //   await fetch(url, init)
  //     .then(helpers.fetchHandler)
  //     .then(json => json.json);
  // },
  createAssessmentType: async function (hoaID, name) {
    var res = null;
    let url = `${config.baseUrl}/api/v1/assessment_types`;
    var init = {
      method: "POST",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify({ hoa_id: hoaID, assessment_type: { name: name } }),
    };
    await fetch(url, init)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong, while adding type" + response);
      })
      .then((a) => {
        res = a;
      })
      .catch(function (error) {});
    return res;
  },
  updateAssessmentType: async function (
    assessment_id,
    hoaID,
    assessments,
    name,
    fy,
    fy_start,
    generate_fy_assessment
  ) {
    const restructuredAssessments =
      assessments &&
      assessments.slice().map((row, index) => {
        if (row.status === "new") {
          let x = { ...row };
          delete x["status"];
          delete x["id"];
          return x;
        } else return row;
      });

    let assessment_type = {
      assessments_attributes: restructuredAssessments,
      hoa_id: hoaID,
      name: name,
      fy: parseInt(fy),
      fy_start: fy_start,
    };

    if (generate_fy_assessment) {
      Object.assign(assessment_type, {
        generate_fy_assessment: generate_fy_assessment,
      });
    }

    let url = `${config.baseUrl}/api/v1/assessment_types/${assessment_id}`;
    var init = {
      method: "PUT",
      headers: await helpers.defaultHeaders(),
      body: JSON.stringify(assessment_type),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  deleteAssessmentType: async function (assessmentTypeId, hoaID) {
    let url = `${config.baseUrl}/api/v1/assessment_types/${assessmentTypeId}?hoa_id=${hoaID}`;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  deleteAssessment: async function (assessmentTypeId, assessmentId, hoaID) {
    let url = `${config.baseUrl}/api/v1/assessment_types/${assessmentTypeId}/delete_assessments?assessment_id=${assessmentId}&hoa_id=${hoaID}`;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  addDocuments: async function (hoaID, hoaDoc) {
    let url = `${config.baseUrl}/api/v1/hoas/${hoaID}/add_documents`;
    let form = new FormData();
    for (var key in hoaDoc) {
      if (!Array.isArray(hoaDoc[key])) {
        form.append("hoa[" + key + "]", hoaDoc[key]);
      }
    }
    let init = {
      method: "POST",
      headers: await helpers.getSavedToken(),
      body: form,
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
  deleteHoaDoc: async function (hoaID, documentId) {
    let url = `${config.baseUrl}/api/v1/hoas/${hoaID}/delete_documents?document_id=${documentId}`;
    var init = {
      method: "DELETE",
      headers: await helpers.defaultHeaders(),
    };
    return await fetch(url, init)
      .then(helpers.fetchHandler)
      .then((json) => json.json);
  },
};
export default withRouter(hoaapi);
