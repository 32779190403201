/* eslint-disable no-lone-blocks */
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Form,
  Input,
  CustomInput,
  Col,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import accesscodesapi from "../../../utils/api/accesscodesapi";
import helpers from "../../../utils/helpers";
import "../../../styles/_access-codes.scss";
import config from "../../../utils/config";
import _ from "lodash";
import AccessDocumentModal from "./AccessDocumentModal";
//import DocumentsTab from "../../documents/DocumentsTab";

export default class AccessCodeModal extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      ...this.state,
      ...this.props,
    };
    this.state = {
      ...this.state,
      accessCodesObject: props.accessCodesObject,
      accessForObject: null,
      accessToObject: null,
      accessControlsObject: {},
      accessFor: {},
      files: [{}, {}, {}, {}],
      showCreate: false,
      accesstype: props.type,
      openAddDocumentModal: false,
      fileUpload: false,
      pos: -1,
    };
  }

  componentWillMount() {
    const props = this.props;
    let arr;
    let type;
    if (_.isEmpty(props.accessCodesObject)) {
      if (props.separateAccessCodes && props.separateCommunity) {
        arr = [
          { position: "1" },
          { position: "2" },
          { position: "3" },
          { position: "4" },
        ];
        type = "one";
      } else if (props.separateAccessCodes) {
        arr = [{ position: "1" }, { position: "2" }];
        type = "two";
      } else if (props.separateCommunity) {
        arr = [{ position: "1" }, { position: "2" }];
        type = "three";
      } else {
        arr = [{ position: "1" }];
        type = "four";
      }
      this.setState({
        accessCodesObject: arr,
        accesstype: type,
      });
    }
    this.getAccessTo(this.props.hoaId);
    this.getAccessFor(this.props.hoaId);
    this.getAccessControls(this.props.hoaId);
  }

  toggleDocument = () => {
    this.setState({ openAddDocumentModal: !this.state.openAddDocumentModal });
  };

  toggleFileUpload = () => {
    this.setState({
      fileUpload: !this.state.fileUpload,
    });
  };

  getAccessFor = (hoaId) => {
    accesscodesapi.getAccessFor(hoaId).then((resp) => {
      this.setState({
        accessForObject: resp,
      });
    });
  };

  getAccessTo = (hoaId) => {
    accesscodesapi.getAccessTo(hoaId).then((resp) => {
      this.setState({
        accessToObject: resp,
      });
    });
  };

  getAccessControls = (hoaId) => {
    accesscodesapi.getAccessControls(hoaId).then((resp) => {
      this.setState({
        accessControlsObject: resp,
      });
    });
  };

  toggleAccessCodeModal = () => {
    this.state.toggle();
  };

  handlePhoneChange = (event, ix) => {
    let name = helpers.stripLastChar(event.target.name);
    var aco = this.state.accessCodesObject[ix];
    aco[name] = event.target.value;
    let text = event.target.value;
    var cleaned = ("" + text).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      cleaned = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }
    aco["access_contact_phone"] = cleaned;

    this.setState({
      accessCodesObject: this.state.accessCodesObject.map((x, i) =>
        i === ix ? aco : x
      ),
    });

    return;
  };

  handleFileObjects = (obj, ix) => {
    if (!_.isEmpty(obj)) {
      let files = this.state.files;
      files[ix] = obj;
      this.setState({ files });
    }
    this.toggleDocument();
  };

  handleChange = (event, ix) => {
    let name = helpers.stripLastChar(event.target.name);
    let accesstype = this.state.accesstype;
    var aco = this.state.accessCodesObject[ix];
    aco[name] = event.target.value;
    if (
      this.props.accessFor === "building" &&
      this.state.accessCodesObject.length > 1 &&
      (aco["access_to"] === null || typeof aco["access_to"] === "undefined")
    ) {
      let accessto = "";
      let group_name = null;
      switch (accesstype) {
        case "one":
          if (ix <= 1) {
            accessto = "Owner";
            group_name = ix == 1 ? "otherthan_owner_vendor" : null;
          } else if (ix > 1) {
            accessto = "Vendor";
            group_name = ix == 3 ? "otherthan_owner_vendor" : null;
          }
          break;
        case "two":
          if (ix === 0) accessto = "Owner";
          else accessto = "Vendor";
          break;
        case "three":
          accessto = "";
          group_name = "otherthan_owner_vendor";
          break;
      }
      aco["access_to"] = accessto;
      aco["group_name"] = group_name;
    }

    let text = event.target.value;
    var cleaned = ("" + text).replace(/\D/g, "");
    if (name === "access_contact_phone") {
      const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        cleaned = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
          match[3] ? "-" : ""
        }${match[3]}`;
      }
      aco["access_contact_phone"] = cleaned;
    }

    this.setState({
      accessCodesObject: this.state.accessCodesObject.map((x, i) =>
        i === ix ? aco : x
      ),
    });
  };

  handleRadioButton = (event, isBoolean, ix) => {
    var aco = this.state.accessCodesObject[ix];
    let value = event.target.value;
    let name = helpers.stripLastChar(event.target.name);
    let id = helpers.stripLastChar(event.target.id);
    if (typeof value === "string") {
      value = helpers.getBoolean(value);
    }
    {
      !isBoolean ? (aco[name] = id) : (aco[name] = value);
    }
    this.setState({
      accessCodesObject: this.state.accessCodesObject.map((x, i) =>
        i === ix ? aco : x
      ),
    });
  };

  notify = (errorMsg) => toast(errorMsg);

  handleSubmit = (event) => {
    console.log("files===>", this.state.files);
    console.log("The state object is ===>", this.state.accessCodesObject);

    //Make changes in the API calls to submit new file contents
    // THe new file object and access code object will be passed to API in accesscodesapi.js file
    //you need to make the necessary changes there

    let isUpdate = this.state.isUpdate;
    let accessCodeObject = this.state.accessCodesObject;
    // console.log("access==>", accessCodeObject);
    // console.log("files===>", this.state.files);
    if (!isUpdate) {
      accessCodeObject.forEach((x, ix) => {
        console.log(x, ix);
        x.access_for = this.props.accessFor;
        accesscodesapi
          .createAccessCodes(x, this.state.hoaId, this.state.files[ix])
          .then((response) => {
            if (response !== null && response.hasOwnProperty("errors")) {
              let errorMsg = helpers.getErrorMsg(response.errors);
              if (errorMsg) this.notify(errorMsg);
            } else {
              this.notify("Access Codes Created Successfully");
              this.props.toggle();
              this.props.refreshList();
              if (this.props.isAmenties) {
                this.props.handleSubmit();
              }
            }
          });
      });
    } else {
      accessCodeObject.forEach((x, ix) => {
        x.access_for = this.props.accessFor;
        console.log(x, ix);
        accesscodesapi
          .updateAccessCodes(x, this.state.hoaId, x.id, this.state.files[ix])
          .then((response) => {
            if (response !== null && response.hasOwnProperty("errors")) {
              this.notify("Error while updating access code");
            } else {
              this.notify("Access Codes Updated Successfully");
              this.props.toggle();
              this.props.refreshList();
              if (this.props.isAmenties) {
                this.props.handleSubmit();
              }
            }
          });
      });
    }
    this.toggleAccessCodeModal();
    event.preventDefault();
  };

  generateAccessForInput = (accessForObject) => {
    let accessForKeys = Object.keys(accessForObject);
    let inputRadio = accessForKeys.map((accessFor, index) => {
      return (
        <CustomInput
          type="radio"
          id={accessFor}
          name="accessFor"
          onClick={this.handleRadioButton}
          label={accessForObject[accessFor]}
          value={this.state.accessCodesObject.access_for}
        />
      );
    });
    return inputRadio;
  };

  generateRadioButtons = (object, name, label, isBoolean, ix) => {
    let aco = this.state.accessCodesObject[ix];
    let objectkeys = Object.keys(object);
    let inputRadio = null;
    {
      !isBoolean
        ? (inputRadio = objectkeys.map((key, index) => {
            return (
              <CustomInput
                type="radio"
                id={key + ix}
                name={name + ix}
                onClick={(event) =>
                  this.handleRadioButton(event, isBoolean, ix)
                }
                label={object[key]}
                checked={key === aco[name] ? "checked" : ""}
                value={key}
                required
              />
            );
          }))
        : (inputRadio = objectkeys.map((key, index) => {
            return (
              <CustomInput
                type="radio"
                id={key + "-" + name + ix}
                name={name + ix}
                onClick={(event) =>
                  this.handleRadioButton(event, isBoolean, ix)
                }
                label={key}
                checked={object[key] === aco[name] ? "checked" : ""}
                value={object[key]}
                required
              />
            );
          }));
    }

    return (
      <div className="acess-code-questions">
        <div className="access-question">{label}</div>
        <div className="acess-code-radio-buttons">{inputRadio}</div>
      </div>
    );
  };

  scanCardDiv = (ix) => {
    let aco = this.state.accessCodesObject[ix];
    return (
      <div className="control-details">
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="">{`Who needs to be contacted for ${
                this.state.accessControlsObject[aco.access_control]
              }?`}</Label>
              <Input
                type="text"
                name={`access_contact_name${ix}`}
                id={`access_contact_name${ix}`}
                placeholder="Name"
                onChange={(e) => this.handleChange(e, ix)}
                value={aco && aco.access_contact_name}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                type="text"
                name={`access_contact_phone${ix}`}
                id={`access_contact_phone${ix}`}
                placeholder="(000) 000-0000"
                onChange={(e) => this.handlePhoneChange(e, ix)}
                value={aco && aco.access_contact_phone}
                maxLength="14"
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Email</Label>
              <Input
                type="text"
                name={`access_contact_email${ix}`}
                id={`access_contact_email${ix}`}
                placeholder="Email"
                onChange={(e) => this.handleChange(e, ix)}
                value={aco && aco.access_contact_email}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Cost for the Key Card / Fob?</Label>
              <Input
                type="text"
                name={`access_control_cost${ix}`}
                id={`access_control_cost${ix}`}
                placeholder=""
                onChange={(e) => this.handleChange(e, ix)}
                value={aco && aco.access_control_cost}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  codeDiv = (ix) => {
    let aco = this.state.accessCodesObject[ix];
    return (
      <div className="control-details">
        <FormGroup>
          <Label>What is the Code?</Label>
          <Input
            type="text"
            name={`code${ix}`}
            id={`code${ix}`}
            placeholder=""
            onChange={(e) => this.handleChange(e, ix)}
            value={aco.code}
            required
          />
        </FormGroup>
      </div>
    );
  };

  callAheadDiv = (ix) => {
    let aco = this.state.accessCodesObject[ix];
    return (
      <div className="control-details">
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="">Who needs to be called?</Label>
              <Input
                type="text"
                name={`access_contact_name${ix}`}
                id={`access_contact_name${ix}`}
                placeholder="Name / Office"
                onChange={(e) => this.handleChange(e, ix)}
                value={aco && aco.access_contact_name}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                type="text"
                name={`access_contact_phone${ix}`}
                id={`access_contact_phone${ix}`}
                placeholder="(000) 000-0000"
                onChange={(e) => this.handlePhoneChange(e, ix)}
                value={aco && aco.access_contact_phone}
                maxLength="14"
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  otherDiv = (ix) => {
    let aco = this.state.accessCodesObject[ix];
    return (
      <div className="control-details">
        <FormGroup>
          <Label>Briefly describe how to obtain access.</Label>
          <Input
            type="text"
            name={`access_description${ix}`}
            id={`access_description${ix}`}
            placeholder=""
            onChange={(e) => this.handleChange(e, ix)}
            value={aco && aco.access_description}
          />
        </FormGroup>
      </div>
    );
  };

  accessCodeForm = (accessCodesObject, ix) => {
    const booleanMap = config.booleanMap;
    let accessControlsObject = this.state.accessControlsObject;
    let accessControl = accessCodesObject.access_control;
    let access_form_exist = accessCodesObject.access_form_exist;
    const accesstype = this.state.accesstype;
    const heading = () => {
      let head;
      switch (accesstype) {
        case "one":
          if (ix % 2 === 0) head = "Community Access";
          else head = "Building Access";
          break;
        case "two":
          head = "Community / Building Access";
          break;
        case "three":
          if (ix === 0) head = "Community Access";
          else head = "Building Access";
          break;
        case "four":
          head = "Community / Building Accesss";
          break;
      }
      return <h4>{head}</h4>;
    };

    let formhead;

    switch (accesstype) {
      case "one":
        if (ix === 0) formhead = "Owner";
        else if (ix === 2) formhead = "Vendor";
        break;
      case "two":
        if (ix === 0) formhead = "Owner";
        else formhead = "Vendor";
        break;
      case "three":
        formhead = "Owner / Vendor";
        break;
      case "four":
        formhead = "Owner / Vendor";
        break;
    }

    return (
      <>
        <div className="form-heading">{formhead}</div>
        <div className="aco">
          {this.props.accessFor === "building" && heading()}
          {this.props.accessFor !== "building" && (
            <h4>{config.access_for[this.props.accessFor]}</h4>
          )}
          {accessControlsObject &&
            this.generateRadioButtons(
              accessControlsObject,
              "access_control",
              "Access is controlled by",
              false,
              ix
            )}
          {(accessControl === "scan" || accessControl === "key") &&
            this.scanCardDiv(ix)}
          {accessControl === "code" && this.codeDiv(ix)}
          {accessControl === "call" && this.callAheadDiv(ix)}
          {accessControl === "other" && this.otherDiv(ix)}
          {
            //  <div className="control-details">
            <FormGroup className="code-notes">
              <Label>Notes</Label>
              <Input
                type="textarea"
                name={`notes${ix}`}
                id={`notes${ix}`}
                placeholder=""
                onChange={(e) => this.handleChange(e, ix)}
                value={accessCodesObject && accessCodesObject.notes}
              />
            </FormGroup>
            //  </div>
          }
          {this.generateRadioButtons(
            booleanMap,
            "access_form_exist",
            "Is an access form required?",
            true,
            ix
          )}
          {access_form_exist === true && (
            <div className="access-form">
              {/* <div>Access Form Control</div>
              <FormGroup className="import-file">
                <Input
                  type="file"
                  name={`file${ix}`}
                  id={`file${ix}`}
                  onChange={(e) => this.handleChange(e, ix)}
                  required
                />
              </FormGroup> */}
              <Button
                className="access-file-btn"
                onClick={() =>
                  this.setState({ pos: ix, openAddDocumentModal: true })
                }
              >
                Access Form File Upload
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  renderModal = () => {
    let isUpdate = this.state.isUpdate;

    let headerText = "";
    if (isUpdate) {
      headerText = "Update Access Codes";
    } else {
      headerText = "Create Access Codes";
    }
    let accessCodesObject = this.state.accessCodesObject;
    let accessForObject = this.state.accessForObject;
    return (
      <div>
        <ModalHeader toggle={this.toggleAccessCodeModal}>
          <div className="">
            <span className="heading">{headerText}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            {accessCodesObject.map((x, ix) => this.accessCodeForm(x, ix))}
            <Button type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="access-code-modal"
          isOpen={this.state.modal}
          toggle={this.toggleAccessCodeModal}
        >
          {this.renderModal()}
        </Modal>
        {this.state.openAddDocumentModal && this.state.pos >= 0 && (
          <AccessDocumentModal
            isFileUpload={this.toggleFileUpload}
            toggle={this.toggleDocument}
            title="Document"
            open={this.state.openAddDocumentModal}
            isAccessCode={true}
            fetchDocuments={""}
            propertyId={this.state.propertyid}
            hideDocumentName={true}
            pos={this.state.pos}
            handleFileObjects={this.handleFileObjects}
            hoaId={this.state.hoaId}
            accessFor={this.props.accessFor}
          />
        )}
      </div>
    );
  }
}
