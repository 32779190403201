import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import apicall from "../../utils/apicall";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../helper_components/TextValidator";
import "../../styles/_violationFileUpload.scss";
import config from "../../utils/config";
import helpers from "../../utils/helpers";
import _ from "lodash";

export default function ViolationFileUpload(props) {
  const [row, setrow] = useState(props.row ? props.row : {});
  const [actionable_id, setactionable_id] = useState(props.actionID);
  const [doc_type, setdoc_type] = useState();
  const [hideCategoryMessage, sethideCategoryMessage] = useState(false);
  const [hideTypeMessage, sethideTypeMessage] = useState(false);
  const [hideClassificationMessage, sethideClassificationMessage] =
    useState(false);

  useEffect(() => {
    listDocumentType();
  }, []);

  const listDocumentType = () => {
    apicall.listDocumentType().then((response) => {
      let arr = [];
      if (response) {
        arr = response.document_types.map((x) => {
          return { value: x, label: x };
        });
      }
      setdoc_type(arr);
    });
  };
  const handleSubmit = (event) => {
    if (_.includes(row, row.selectedValue)) delete row.selectedValue;
    if (
      row["title_documents_attributes[][category]"] !== "Closeout Photo" &&
      (_.isEmpty(row["title_documents_attributes[][category]"]) ||
        _.isEmpty(row["title_documents_attributes[][doc_type]"]) ||
        _.isEmpty(row["title_documents_attributes[][classification]"]))
    ) {
      console.log("wrong cond 1");
      if (_.isEmpty(row["title_documents_attributes[][category]"]))
        sethideCategoryMessage(true);
      if (_.isEmpty(row["title_documents_attributes[][classification]"]))
        sethideClassificationMessage(true);
      if (_.isEmpty(row["title_documents_attributes[][doc_type]"]))
        sethideTypeMessage(true);
    } else if (
      row["title_documents_attributes[][category]"] == "Closeout Photo" &&
      (_.isEmpty(row["title_documents_attributes[][category]"]) ||
        _.isEmpty(row["title_documents_attributes[][doc_type]"]))
    ) {
      console.log("wrong cond 2");

      if (_.isEmpty(row["title_documents_attributes[][category]"]))
        sethideCategoryMessage(true);

      if (_.isEmpty(row["title_documents_attributes[][doc_type]"]))
        sethideTypeMessage(true);
    } else if (actionable_id) {
      console.log("came inside apicall");
      apicall
        .updateCodeViolation(actionable_id, props.propertyID, row)
        .then((response) => {
          if (response) {
            helpers.notify("Updated successfully");
            setrow(response);
            if (props.open) {
              props.toggle();
              props.fetchDocuments(response.id);
            }
          }
        });
    } else {
      if (
        row["title_documents_attributes[][classification]"] ===
          "Recorded Lien" ||
        row["title_documents_attributes[][classification]"] ===
          "Unrecorded Lien"
      ) {
        apicall.createTitleLien(row, props.propertyID).then((response) => {
          if (response) {
            helpers.notify("Lien Created Successfully");
          }
        });
      }
      apicall.createCodeViolation(props.propertyID, row).then((response) => {
        if (response) {
          helpers.notify("Created successfully");
          setrow(response);
          if (props.open) {
            props.toggle();
            props.fetchDocuments(response.id);
          }
        }
      });
    }
    event.preventDefault();
  };

  const handleChange = (event) => {
    let targetID = event.target.id;
    let value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    setrow(Object.assign({ ...row }, { [targetID]: value }));
  };

  const categoryOptions = () => {
    let categories = [
      "Foreclosure",
      "Receivership",
      "Eminent Domain",
      "Demolition",
      "Condemnation",
      "Court Hearing",
      "Summons",
      "Donation",
      "Notice of Violation / Complaint",
      "Closeout Photo",
      "Utility",
      "Housing Inspection",
    ];
    return categories.map((c) => {
      return { label: c, value: c };
    });
  };

  const classificationOptions = () => {
    let classification = [
      "High Dollar",
      "Recorded Lien",
      "Unrecorded Lien",
      "Intent to Lien / Final Notice",
      "Fines / Fees",
      "Resident Responsibility",
      "Owner Responsibility"
    ];
    return classification.map((c) => {
      return { label: c, value: c };
    });
  };
  var notification_sequenceValues = config.violation_document_name;
  var notificationSequenceOptions = notification_sequenceValues.map((c) => {
    return { label: c, value: c };
  });

  const formElements = () => {
    console.log(
      "the category is",
      row["title_documents_attributes[][category]"]
    );
    return (
      <div>
        <FormGroup>
          <Label for="">Case #</Label>

          <TextValidator
            type="text"
            id={"title_documents_attributes[][case_number]"}
            name="title_documents_attributes[][case_number]"
            placeholder="Case Number"
            onChange={handleChange}
            value={row["title_documents_attributes[][case_number]"]}
            // validators={["required"]}
            // errorMessages={["Please enter case number"]}
          />
        </FormGroup>

        <FormGroup>
          <Label for="">Category *</Label>
          <Select
            name="title_documents_attributes[][category]"
            id="title_documents_attributes[][category]"
            value={{
              value: row["title_documents_attributes[][category]"],
              label: row["title_documents_attributes[][category]"],
            }}
            onChange={(selectedType) => {
              sethideCategoryMessage(false);
              let selectedValue = row;
              selectedValue["title_documents_attributes[][category]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={categoryOptions()}
            validators={["required", "isValidSelect"]}
            errorMessages={[
              "Document Category is required",
              "Name is required",
            ]}
          />
          {hideCategoryMessage ? (
            <b style={{ color: "red" }}>Please select Category</b>
          ) : (
            <></>
          )}
        </FormGroup>

        {row["title_documents_attributes[][category]"] !== "Closeout Photo" && (
          <>
            <FormGroup>
              <Label for="">Classification *</Label>
              <Select
                name="title_documents_attributes[][classification]"
                id="title_documents_attributes[][classification]"
                value={{
                  value: row["title_documents_attributes[][classification]"],
                  label: row["title_documents_attributes[][classification]"],
                }}
                onChange={(selectedType) => {
                  sethideClassificationMessage(false);
                  let selectedValue = row;
                  selectedValue[
                    "title_documents_attributes[][classification]"
                  ] = selectedType.value;
                  setrow(Object.assign({ ...row }, { selectedValue }));
                }}
                options={classificationOptions()}
                validators={["required"]}
                errorMessages={["Document Classification is required"]}
              />
              {hideClassificationMessage ? (
                <b style={{ color: "red" }}>Please select Classification</b>
              ) : (
                <></>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="">Notification Sequence</Label>
              <Select
                id={"title_documents_attributes[][notification_sequence]"}
                name="title_documents_attributes[][notification_sequence]"
                value={{
                  value:
                    row["title_documents_attributes[][notification_sequence]"],
                  label:
                    row["title_documents_attributes[][notification_sequence]"],
                }}
                onChange={(selectedType) => {
                  let selectedValue = row;
                  selectedValue[
                    "title_documents_attributes[][notification_sequence]"
                  ] = selectedType.value;
                  setrow(Object.assign({ ...row }, { selectedValue }));
                }}
                options={notificationSequenceOptions}
                validators={["required", "isValidSelect"]}
                errorMessages={[
                  "Document Sequence is required",
                  "Name is required",
                ]}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label for="">Type *</Label>
          <Select
            id={"title_documents_attributes[][doc_type]"}
            name="title_documents_attributes[][doc_type]"
            value={{
              value: row["title_documents_attributes[][doc_type]"],
              label: row["title_documents_attributes[][doc_type]"],
            }}
            onChange={(selectedType) => {
              sethideTypeMessage(false);
              let selectedValue = row;
              selectedValue["title_documents_attributes[][doc_type]"] =
                selectedType.value;
              setrow(Object.assign({ ...row }, { selectedValue }));
            }}
            options={doc_type}
            validators={["required", "isValidSelect"]}
            errorMessages={["Document Type is required", "Name is required"]}
          />
          {hideTypeMessage ? (
            <b style={{ color: "red" }}>Please select Type</b>
          ) : (
            <></>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="">Document File Upload</Label>
          <Input
            type="file"
            name="file"
            id={"title_documents_attributes[][file]"}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Button className={""} onSubmit={handleSubmit}>
          Submit
        </Button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={props.toggle}>
          <div className="">
            <span className="heading">Code/Permitting Upload</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
            {formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="violationFileUpload-modal"
        isOpen={props.open}
        toggle={props.toggle}
      >
        {renderModal()}
      </Modal>
    </div>
  );
}
