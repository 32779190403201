import React from "react";
import {
  Button,
  FormGroup,
  Label,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Row,
  Col,
} from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import "../../styles/_editAddress.scss";
export default class EditOtherPayeeAddressModal extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      // row: {
      // other_payee_recipient_care_of:
      //   props.payment.other_payee_recipient_care_of,
      // other_payee_recipient_address:
      //   props.payment.other_payee_recipient_address,
      // other_payee_recipient_city: props.payment.other_payee_recipient_city,
      // other_payee_recipient_state: props.payment.other_payee_recipient_state,
      // other_payee_recipient_zip: props.payment.other_payee_recipient_zip,
      // id: props.payment.id,
      // },
      row: props.payment,
      title: props.title,
    };
    console.log(props);
  }

  toggleModal = () => {
    this.props.toggle();
  };

  handleChange = (event, isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if (isZip && value.length > 5) {
      console.log("zip value ---->", value);
      var len = value.length;
      var res;
      if (value.substring(5, 6) != "-") {
        // console.log("indise substrin IF")
        res = value.substring(0, 5) + "-" + value.substring(5, len);
      } else {
        res = value;
      }
      this.setState({
        row: Object.assign(this.state.row, { [targetID]: res }),
      });
    } else {
      this.setState({
        row: Object.assign(this.state.row, { [targetID]: value }),
      });
    }
  };

  handleAddressSubmit = (event) => {
    let row = this.state.row;
    if (this.props.actionTab === "municipalityAction") {
      apicall
        .municipal_paymentUpdate(
          row,
          this.props.municipality_id,
          this.props.actionID
        )
        .then((response) => {
          if (response) {
            helpers.notify("payment Updated Successfully.");
            this.toggleModal();
            this.props.updatePayment(response);
          }
        });
    } else {
      apicall
        .paymentUpdate(row, this.props.propertyID, this.props.actionID)
        .then((response) => {
          if (response) {
            helpers.notify("payment Updated Successfully.");
            this.toggleModal();
            this.props.updatePayment(response);
            // let row = this.createOptions(response);
            // this.setState({
            //   row: row,
            //   isLoading: false,
            // });
            //this.state.refershActions(this.state.propertyID);
          }
        });
    }
    event.preventDefault();
  };

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">{"Update Payment Mailing Address"}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleAddressSubmit} autoComplete="off">
            <FormGroup row>
              <Label for="" sm={2}>
                C/O Name
              </Label>
              <Col sm={10}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ borderRadius: "14px 0px 0px 14px" }}
                    >
                      C/o
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="address-name"
                    name="other_payee_recipient_care_of"
                    id="other_payee_recipient_care_of"
                    placeholder="Mailing contact name"
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Mailing contact name is required"]}
                    value={this.state.row.other_payee_recipient_care_of}
                  />
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="" sm={2}>
                Address *
              </Label>
              <Col sm={10}>
                <TextValidator
                  type="text"
                  name="other_payee_recipient_address"
                  id="other_payee_recipient_address"
                  placeholder="Mailing street name"
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Mailing street name is required"]}
                  value={this.state.row.other_payee_recipient_address}
                />
              </Col>
            </FormGroup>
            <div>
              <Row form>
                <Col md={6}>
                  <FormGroup row>
                    <Label for="" sm={4}>
                      City *
                    </Label>
                    <Col sm={8}>
                      <TextValidator
                        type="text"
                        name="other_payee_recipient_city"
                        id="other_payee_recipient_city"
                        placeholder="Mailing city"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Mailing city is required"]}
                        value={this.state.row.other_payee_recipient_city}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup row>
                    <Label for="" sm={4}>
                      State*
                    </Label>
                    <Col sm={8}>
                      <TextValidator
                        type="text"
                        name="other_payee_recipient_state"
                        id="other_payee_recipient_state"
                        placeholder="Mailing state"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Mailing state is required"]}
                        value={this.state.row.other_payee_recipient_state}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup row>
                    <Label for="" sm={2}>
                      Zip*
                    </Label>
                    <Col sm={10}>
                      <TextValidator
                        type="text"
                        name="other_payee_recipient_zip"
                        id="other_payee_recipient_zip"
                        placeholder="XXXXX-XXXX"
                        maxLength="10"
                        onChange={(event) => this.handleChange(event, true)}
                        validators={["required", "isAddressZipcodeMatch"]}
                        errorMessages={[
                          "Mailing zip is required",
                          "Invalid Zip Code",
                        ]}
                        value={this.state.row.other_payee_recipient_zip}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    console.log(this.state.row);
    return (
      <div>
        <Modal className="edit-address" isOpen={true} toggle={this.toggleModal}>
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}
