import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import LoaderComponent from "../helper_components/LoaderComponent";
import helpers from "../../utils/helpers";
import _ from "lodash";
import { Container, Row, Col } from "reactstrap";
export default (props) => {

  const dateFormatter = (cell) => {
    return helpers.formatDate(cell);
  };

  const descFormatter = (cell, row, rowIndex, formatExtraData) => (
    <div style={{ "white-space": "pre-wrap" }}>{cell}</div>
  );
  const columns = [
    // {
    //   dataField: "note_type",
    //   text: "Type",
    //   headerClasses: "header-cell-alignment",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "6%" };
    //   },
    // },
    {
      dataField: "created_at",
      text: "Created At",
      headerClasses: "header-cell-alignment",
      sort: true,
      formatter: dateFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
    {
      dataField: "user_name",
      text: "User",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: descFormatter,
      headerClasses: "header-cell-alignment",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="general">
        <div className="notes-div" row>
          {props.noteStyle ? 
          <div
          style={{
            position: "relative",
            left: "89px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "baseline"
          }}
        >
        <span sm={3} style={{fontSize:"18px",fontWeight:"600",position:"relative",left: "-18px"}}>Notes List </span>
        <Col sm={8}>
        {!_.isEmpty(props.notes) && (
          <BootstrapTable
            keyField={"id"}
            columns={columns}
            data={props.notes}
          />
        )}
        </Col>
        </div>
        :
         (props.codeviolationStyle)?
              <>
                <div>
                  <span sm={3} style={{ fontSize: "18px", fontWeight: "600", position: "relative", left: "190px" }}>Notes List </span>
                  <Col sm={10}>
                    {!_.isEmpty(props.notes) && (
                      <div style={{ position: "relative", left: "275px", top: "-80px", width: "90%" }}>
                        <BootstrapTable
                          keyField={"id"}
                          columns={columns}
                          data={props.notes}
                        />
                      </div>
                    )}
                  </Col>
                </div>
              </>
          :
          (props.registration) ?
                <>
                  <div>
                    <span sm={3} style={{ fontSize: "18px", fontWeight: "600", position: "relative", left: "190px" }}>Notes List </span>
                    <Col sm={11}>
                      {!_.isEmpty(props.notes) && (
                        <div style={{ position: "relative", left: "275px", top: "-80px", width: "90%" }}>
                          <BootstrapTable
                            keyField={"id"}
                            columns={columns}
                            data={props.notes}
                          />
                        </div>
                      )}
                    </Col>
                  </div>
                </>
           :
          (props.municipalVerification) ?
                  <>
                    <div>
                      <span sm={3} style={{ fontSize: "18px", fontWeight: "600", position: "relative", left: "190px" }}>Notes List </span>
                      <Col sm={10}>
                        {!_.isEmpty(props.notes) && (
                          <div style={{ position: "relative", left: "275px", top: "-80px", width: "90%" }}>
                            <BootstrapTable
                              keyField={"id"}
                              columns={columns}
                              data={props.notes}
                            />
                          </div>
                        )}
                      </Col>
                    </div>
                  </>
           :
           (props.lien_notes) ?
                    <>
                      <div>
                        <span sm={3} style={{ fontSize: "18px", fontWeight: "600", position: "relative", left: "170px" }}>Notes List </span>
                        <Col sm={9}>
                          {!_.isEmpty(props.notes) && (
                            <div style={{ position: "relative", left: "275px", top: "-80px", width: "90%" }}>
                              <BootstrapTable
                                keyField={"id"}
                                columns={columns}
                                data={props.notes}
                              />
                            </div>
                          )}
                        </Col>
                      </div>
                    </> :
              (props.municipalModal) ?
              <>
              <div>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>Notes List </span>
                    <Col sm={11}>
                      {!_.isEmpty(props.notes) && (
                        <div style={{ position: "relative", left: "90px", top: "-80px" }}>
                          <BootstrapTable
                            keyField={"id"}
                            columns={columns}
                            data={props.notes}
                          />
                        </div>
                      )}
                    </Col>
                  </div>
              </> :
                      (props.addOffice) ?
                        <>
                          <div>
                            <span sm={3} style={{ fontSize: "18px", fontWeight: "600", position: "relative", left: "30px" }}>Notes List </span>
                            <Col sm={10}>
                              {!_.isEmpty(props.notes) && (
                                <div style={{ position: "relative", left: "128px", top: "-40px", width: "104%" }}>
                                  <BootstrapTable
                                    keyField={"id"}
                                    columns={columns}
                                    data={props.notes}
                                  />
                                </div>
                              )}
                            </Col>
                          </div>
                        </> :
                  <div>
                    <span sm={3} style={{ fontSize: "18px", fontWeight: "600", position: "relative", left: "190px" }}>Notes List </span>
                    <Col sm={10}>
                      {!_.isEmpty(props.notes) && (
                        <div style={{ position: "relative", left: "275px", top: "-80px", width: "90%" }}>
                          <BootstrapTable
                            keyField={"id"}
                            columns={columns}
                            data={props.notes}
                          />
                        </div>
                      )}
                    </Col>
                  </div>
         }
        </div>
      </div>
    </React.Fragment>
  );
};
